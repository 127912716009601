import { Typography } from '@material-ui/core';
import React from 'react';

const PermissionsInfo = () => (
  <Typography variant="body1" component="span">
    <br />
    <br />
    <b>How to give TeleVet access:</b>
    <br />
    <br />
    Please see the following links for information on granting <b>TeleVet</b> <u>camera and/or microphone</u> access in
    major internet browsers. Refresh this page and join the call again once video and/or camera permissions have been
    granted for <b>TeleVet</b>: <br />
    <ul>
      <li>
        {' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.google.com/chrome/answer/114662?co=GENIE.Platform%3DDesktop&hl=en"
        >
          Google Chrome Desktop &amp; Mobile
        </a>
      </li>
      <li>
        {' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://wccftech.com/how-to/access-website-camera-microphone-location-permissions-in-safari-for-ios-13-ipados/"
        >
          Safari iOS (iPhone &amp; iPad)
        </a>
      </li>
      <li>
        {' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac#:~:text=To%20change%20these%20preferences%2C%20choose,menu%20next%20to%20the%20website."
        >
          Safari MacOS
        </a>
      </li>
      <li>
        {' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#w_changing-camera-permissions"
        >
          Firefox
        </a>
      </li>
      <li>
        {' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.windowscentral.com/how-manage-site-permissions-new-microsoft-edge#set_site_permissions_edge_chromium"
        >
          Microsoft Edge
        </a>
      </li>
    </ul>
  </Typography>
);

export default PermissionsInfo;
