import { Link, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

const UpdateInfo = () => (
  <StyledTypography variant="body1" display="block">
    <Link target="_blank" rel="noopener noreferrer" href="https://support.apple.com/en-us/HT204204">
      How to update your iOS version
    </Link>
  </StyledTypography>
);

export default UpdateInfo;

const StyledTypography = styled(Typography)`
  margin-top: 20px;
`;
