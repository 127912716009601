import React, { useEffect, useState } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import ErrorBoundaryFallback from './components/ErrorBoundary';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import useParentConnectionContext from './hooks/useParentConnectionContext/useParentConnectionContext';

import useLogRocket from './hooks/useLogRocket';
import useSentry from './hooks/useSentry';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
  overflowY: 'hidden',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App() {
  const roomState = useRoomState();
  const [userInfo, setUserInfo] = useState();
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  // const { connect, room } = useVideoContext();
  const { parent } = useParentConnectionContext();
  const [info, setInfo] = useState({
    conversationId: '',
    userName: '',
    userInfo: '',
    clientName: '',
    appName: '',
    clinicName: '',
    hasEnhancedVideoRecording: false,
  });

  useLogRocket(userInfo);
  useSentry(userInfo);

  useEffect(() => {
    parent
      ?.getRoomData()
      .then(
        ({
          userId,
          consultId,
          channelId,
          appName,
          userInfo,
          clientName,
          clinicName,
          conversationUrl,
          hasEnhancedVideoRecording,
        }) => {
          setUserInfo(userInfo);
          setInfo(pastInfo => {
            return {
              ...pastInfo,
              userName: [userInfo?.firstName, userInfo?.lastName].join(' '),
              conversationId: consultId || channelId || '',
              userInfo,
              clientName,
              appName: appName || 'Unknown',
              clinicName: clinicName || userInfo?.vetInfo?.currentClinic?.name || 'Unknown clinic',
              conversationUrl,
              hasEnhancedVideoRecording,
            };
          });

          try {
            parent?.mixpanelSetPeople({
              $email: userInfo.email,
              USER_ID: userId,
              application: appName,
              appName,
              clinicName: clinicName || userInfo?.vetInfo?.currentClinic?.name || 'Unknown clinic',
            });
          } catch (e) {
            console.warn(e);
          }

          parent?.mixpanelIdentify(userId);
        }
      );
  }, [parent]);

  if (!info) return null;

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      <Container style={{ height }}>
        {roomState === 'disconnected' ? (
          <PreJoinScreens info={info} />
        ) : (
          <Main>
            <ReconnectingNotification />
            <MobileTopMenuBar roomName={info.clientName} />
            <Room />
            <MenuBar roomName={info.clientName} hasEnhancedVideoRecording={info.hasEnhancedVideoRecording} />
          </Main>
        )}
      </Container>
    </Sentry.ErrorBoundary>
  );
}
