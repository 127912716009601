import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import * as Sentry from '@sentry/react';
import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { ParentConnectionProvider } from './components/ParentConnectionProvider';
import ErrorBoundaryFallback from './components/ErrorBoundary';
import { ApolloProvider } from '@apollo/client';
import { client } from './apolloClient';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      <ApolloProvider client={client}>
        <VideoProvider options={connectionOptions} onError={setError}>
          <ParentConnectionProvider>
            <UnsupportedBrowserWarning>
              <>
                <ErrorDialog dismissError={() => setError(null)} error={error} />
                <App />
              </>
            </UnsupportedBrowserWarning>
          </ParentConnectionProvider>
        </VideoProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
};

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <VideoApp />
            </PrivateRoute>
            <PrivateRoute path="/room/:URLRoomName">
              <VideoApp />
            </PrivateRoute>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </MuiThemeProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
