import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AvatarIcon from '../../icons/AvatarIcon';
import Typography from '@material-ui/core/Typography';

import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useTrack from '../../hooks/useTrack/useTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import styled from 'styled-components/macro';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    gridArea: '1 / 1 / 2 / 3',
    [theme.breakpoints.down('sm')]: {
      gridArea: '1 / 1 / 3 / 3',
    },
  },
}));

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const isLocal = localParticipant === participant;

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.trackName.includes('camera') || p.kind === 'video');
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={clsx(classes.container, {
        [classes.fullWidth]: !isRemoteParticipantScreenSharing,
      })}
    >
      <InfoContainer>
        <Identity>
          <AudioLevelIndicator audioTrack={audioTrack} />
          <Typography variant="body1" color="inherit">
            {participant.identity}
            {isLocal && ' (You)'}
            {screenSharePublication && ' - Screen'}
          </Typography>
        </Identity>
      </InfoContainer>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <AvatarContainer>
          <AvatarIcon />
        </AvatarContainer>
      )}
      {isParticipantReconnecting && (
        <ReconnectingContainer>
          <Typography variant="body1" style={{ color: 'white' }}>
            Reconnecting...
          </Typography>
        </ReconnectingContainer>
      )}
      {children}
    </div>
  );
}

const InfoContainer = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
`;

// const Container = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
// `;

const Identity = styled.div`
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.1em 0.3em 0.1em 0;
  font-size: 1.2em;
  display: inline-flex;
  svg: {
    margin-left: 0.3em;
  }
`;

const ReconnectingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(40, 42, 43, 0.75);
  z-index: 1;
`;

// const FullWidth = styled.div`
//   grid-area: 1 / 1 / 2 / 3;
//   @media (max-width: 500px) {
//     grid-area: 1 / 1 / 3 / 3;
//   }
// `;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  & svg: {
    transform: scale(2);
  }
`;
