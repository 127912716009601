import React from 'react';
import { SuccessIcon, WarningIcon } from './icons';
import { QualityDisplay } from './interfaces';

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

export const networkQualityMap: Record<number, QualityDisplay> = {
  0: {
    backgroundcolor: 'hsl(0deg 82% 40%)',
    textcolor: 'white',
    description: 'Attempting to reconnect...',
    icon: <WarningIcon />,
  },
  1: {
    backgroundcolor: 'hsl(0deg 82% 40%)',
    textcolor: 'white',
    description: 'Your network quality is very low. Try switching off your camera to ensure a stable connection.',
    icon: <WarningIcon />,
  },
  2: {
    backgroundcolor: 'hsl(0deg 82% 40%)',
    textcolor: 'white',
    description: 'Your network quality is very low.',
    icon: <WarningIcon />,
  },
  3: {
    backgroundcolor: 'hsl(53deg 100% 54%)',
    textcolor: 'black',
    description: 'Your network quality is currently at 60%.',
    icon: <WarningIcon />,
  },
  4: {
    backgroundcolor: 'white',
    textcolor: 'black',
    description: 'Your network quality is currently at 80%.',
    icon: <SuccessIcon />,
  },
  5: {
    backgroundcolor: 'white',
    textcolor: 'black',
    description: 'Your network quality is currently stable.',
    icon: <SuccessIcon />,
  },
  10: {
    backgroundcolor: 'white',
    textcolor: 'black',
    description: 'Establishing network quality rating...',
    icon: <WarningIcon />,
  },
};
