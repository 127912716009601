import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import Swoosh from './swoosh';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(40, 42, 43)',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      height: 'auto',
      maxWidth: '430px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: Swoosh,
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  subContentContainer: {
    position: 'absolute',
    marginTop: '1em',
    width: '100%',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  return (
    <Background className={classes.background}>
      {user && location.pathname !== '/login' && <UserMenu />}
      <Container className={classes.container}>
        <InnerContainer className={classes.innerContainer}>
          <Content className={classes.content}>{props.children}</Content>
        </InnerContainer>
        {props.subContent && <div className={classes.subContentContainer}>{props.subContent}</div>}
      </Container>
    </Background>
  );
};

export default IntroContainer;

const Background = styled.div`
  overflow: hidden;
`;
const Container = styled.div`
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;

  & ::-webkit-scrollbar {
    display: none;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(40, 42, 43, 0.3);
  overflow: hidden;
  position: relative;
  margin: auto;
  max-width: 740px;
  height: 410px;
  @media (max-width: 850px) {
    display: block;
    width: calc(100% - 40px);
    margin: auto;
    height: auto;
    max-width: 430px;
  }
`;

const Content = styled.div`
  background: white;
  width: 100%;
  padding: 2em;
  flex: 1;

  @media (max-width: 400px) {
    padding: 1em;
  }
`;
