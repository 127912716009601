import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import styled from 'styled-components';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

interface IPrejoinProps {
  info: {
    conversationId: string;
    userName: string;
    userInfo: string;
    clientName: string;
    appName: string;
    clinicName: string;
  };
  userName?: string;
}

export default function PreJoinScreens({
  info: { conversationId, userName, userInfo, clientName, appName, clinicName },
}: IPrejoinProps) {
  const { getAudioAndVideoTracks, isRatingCall } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  const handleSubmit = useCallback(
    (event?: FormEvent<HTMLFormElement>) => {
      event?.preventDefault();
      // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
      if (!window.location.origin.includes('twil.io')) {
        window.history.replaceState(
          null,
          '',
          window.encodeURI(`/room/${conversationId}${window.location.search || ''}`)
        );
      }
    },
    [conversationId]
  );

  const SubContent = (
    <>
      {Video.testPreflight && !isRatingCall && <PreflightTest />}
      {conversationId && mediaError && <MediaErrorSnackbar error={mediaError} />}
    </>
  );

  useEffect(() => {
    if (conversationId) handleSubmit();
  }, [conversationId, handleSubmit]);

  return (
    <IntroContainer subContent={SubContent}>
      {conversationId ? (
        <DeviceSelectionScreen
          name={userName}
          roomName={conversationId}
          roomType={''}
          userInfo={userInfo}
          clientName={clientName}
          appName={appName}
          clinicName={clinicName}
        />
      ) : (
        <LoadingIndicatorContainer>
          <CircularProgress style={{ height: '80px', width: '80px' }} />
        </LoadingIndicatorContainer>
      )}
    </IntroContainer>
  );
}

const LoadingIndicatorContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
