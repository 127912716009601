import React, { useCallback, useState } from 'react';
import Exit from './ExitCall';
import Feedback from './Feedback';
import Ratings, { ratingOption } from './Ratings';
import LogRocket from 'logrocket';
import { Room } from 'twilio-video';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useParentConnectionContext from '../../hooks/useParentConnectionContext/useParentConnectionContext';
import { PreflightTestReport } from '../../types';
import { env } from '../../env';

export interface CallQualitySubmission {
  rating: number | null;
  feedback: string;
  selectedIssues: tagCloudOption[];
  ratingDisplayText: string;
  email: string;
  userId: string;
  conversationId: string;
  roomSid: string;
  name: string;
  clinicName: string;
  appName: string;
  networkStats?: PreflightTestReport;
}

export enum RatingSteps {
  selectRatingStep,
  feedbackStep,
  exitStep,
}

export interface tagCloudOption {
  name: string;
  isSelected: boolean;
}

export const tagCloudOptions: tagCloudOption[] = [
  { name: 'Poor audio quality', isSelected: false },
  { name: "Couldn't hear", isSelected: false },
  { name: 'Poor video quality', isSelected: false },
  { name: 'Connection dropping', isSelected: false },
  { name: 'Video "glitching"', isSelected: false },
  { name: 'Other', isSelected: false },
];

interface ICallQualityScreensProps {
  userInfo: any;
  roomName: string;
  userName: string;
  appName: string;
  clinicName: string;
  room: Room | null;
  endCall: () => Promise<void> | undefined;
}

const CallQualityScreens = ({
  userInfo,
  userName,
  roomName,
  room,
  endCall,
  appName,
  clinicName,
}: ICallQualityScreensProps) => {
  const [step, setStep] = useState(RatingSteps.selectRatingStep);
  const { setHasRatedCall, networkStats } = useVideoContext();
  const { parent } = useParentConnectionContext();
  const [feedBackData, setFeedBackData] = useState<CallQualitySubmission>({
    rating: null,
    feedback: '',
    selectedIssues: tagCloudOptions,
    ratingDisplayText: '',
    email: userInfo?.email || '',
    userId: userInfo?.id || '',
    conversationId: roomName,
    roomSid: room?.sid || '',
    name: userName,
    clinicName,
    appName,
  });

  const setSelectedRating = (option: ratingOption): void => {
    setFeedBackData(prev => ({ ...prev, rating: option.rating, ratingDisplayText: option.description }));
  };

  const submitRating = useCallback(
    (rating?: number, label?: string) => {
      const { feedback, name, email, roomSid, conversationId } = feedBackData;
      const selectedIssues = feedBackData.selectedIssues
        .filter(issue => issue.isSelected)
        .map(issue => issue.name)
        .join(', ');

      setHasRatedCall(true);

      const data = {
        name,
        email,
        userId: userInfo?.id,
        environment: env.REACT_APP_PRODUCTION === 'true' ? 'production' : 'development',
        videoCallRating: rating || feedBackData.rating || false,
        ratingDisplayText: label || feedBackData.ratingDisplayText,
        videoCallIssues: selectedIssues,
        videoCallFeedback: feedback || 'None',
        conversationId,
        roomSid,
        appName,
        clinicName,
        averageNetworkQualityReadable: networkStats?.stats.networkQuality?.average
          ? `${networkStats?.stats.networkQuality.average}/5`
          : 'Unknown',
        averageNetworkQuality: networkStats?.stats.networkQuality?.average || '(null)',
        averageIncomingBitrateReadable: networkStats?.stats.incomingBitrate?.average
          ? `${networkStats?.stats.incomingBitrate?.average} kBits/sec`
          : 'Unknown',
        averageIncomingBitrate: networkStats?.stats.incomingBitrate?.average || '(null)',
        averageJitterReadable: networkStats?.stats.jitter?.average
          ? `${networkStats?.stats.jitter?.average} second(s)`
          : 'Unknown',
        averageJitter: networkStats?.stats.jitter?.average || '(null)',
        averageOutgoingBitrateReadable: networkStats?.stats.outgoingBitrate?.average
          ? `${networkStats?.stats.outgoingBitrate?.average} kBits/sec`
          : 'Unknown',
        averageOutgoingBitrate: networkStats?.stats.outgoingBitrate?.average || '(null)',
        averageRoundTripTimeReadable: networkStats?.stats.rtt?.average
          ? `${networkStats?.stats.rtt?.average}ms`
          : 'Unknown',
        averageRoundTripTime: networkStats?.stats.rtt?.average || '(null)',
        averagePacketLossReadable: networkStats?.stats.packetLoss?.average
          ? `${networkStats?.stats.packetLoss.average} packets lost`
          : 'Unknown',
        averagePacketLoss: networkStats?.stats.packetLoss?.average || '(null)',
      };

      console.info('Video call feedback reporting data: ', data);

      try {
        LogRocket.identify(userInfo?.id, data);
        LogRocket.track('rating_' + (rating || feedBackData.rating));
        feedBackData.selectedIssues.forEach(issue => {
          LogRocket.track('Video Call Issue: ' + issue.name);
          parent?.mixpanelTrack('Video Call Issue: ' + issue.name);
        });
        LogRocket.track('Video Call Feedback: ' + (feedback || 'None'));
        parent?.mixpanelTrack('Video Call Quality Rating: ' + (rating || feedBackData.rating));
        parent?.mixpanelTrack('Video Call Quality Feedback', data);
      } catch (e) {
        console.warn(e);
      }
    },
    [userInfo, feedBackData, setHasRatedCall, parent, appName, clinicName, networkStats]
  );

  const sendFeedback = useCallback(() => {
    submitRating();
    setStep(RatingSteps.exitStep);
  }, [submitRating]);

  return (
    <>
      {step !== RatingSteps.exitStep && (
        <Ratings
          setStep={setStep}
          setSelectedRating={setSelectedRating}
          selectedRating={feedBackData.rating}
          submitRating={submitRating}
        />
      )}
      {step === RatingSteps.feedbackStep && (
        <Feedback
          feedback={feedBackData.feedback}
          setFeedbackData={setFeedBackData}
          issueOptions={feedBackData.selectedIssues}
          sendFeedback={sendFeedback}
        />
      )}
      {step === RatingSteps.exitStep && <Exit endCall={endCall} />}
    </>
  );
};

export default CallQualityScreens;
