import { Callback } from '../../../types';
import EventEmitter from 'events';
import { isMobile } from '../../../utils';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    token => {
      setIsConnecting(true);
      return Video.connect(token, {
        tracks: localTracks,
        logLevel: 'debug',
        networkQuality: {
          local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
        },
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        maxAudioBitrate: 16000,
        maxVideoBitrate: 500000,
        video: {
          height: 720,
          frameRate: 24,
          width: 1280,
        },
        bandwidthProfile: {
          video: {
            mode: 'collaboration',
            trackSwitchOffMode: 'predicted',
            maxSubscriptionBitrate: 2400000,
            dominantSpeakerPriority: 'standard',
            maxTracks: 10,
            renderDimensions: {
              high: { width: 1440, height: 1080 },
              standard: { width: 1280, height: 720 },
              low: { width: 320, height: 240 },
            },
          },
        },
        ...optionsRef.current,
      }).then(
        newRoom => {
          setRoom(newRoom);
          console.log('[DEBUG] Room data', newRoom.sid, newRoom);
          const disconnect = () => newRoom.disconnect();

          // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(15);

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room), 60000);
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          newRoom.localParticipant.videoTracks.forEach(publication =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low')
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [localTracks, onError]
  );

  return { room, isConnecting, connect, setRoom };
}
