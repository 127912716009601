import React from 'react';
import styled from 'styled-components/macro';
import { CatAndDog } from '../../assets';

interface IExitRatingProps {
  endCall: () => Promise<void> | undefined;
}

const Exit = ({ endCall }: IExitRatingProps) => {
  return (
    <Container>
      <CatAndDog />
      <ExitText>Thank you for your valuable feedback. See you soon!</ExitText>
      <ExitCallButton
        onClick={(): void => {
          try {
            endCall()?.then(() => console.log('Call successfully ended'));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        Exit Call
      </ExitCallButton>
    </Container>
  );
};

export default Exit;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: -webkit-fill-available;
`;

const ExitText = styled.div`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: hsl(229, 15%, 44%);
  padding: 24px;
`;

const ExitCallButton = styled.div`
  background-color: hsl(190, 48%, 40%);
  color: #ffe;
  cursor: pointer;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }
  transition: 0.2s ease-in-out;
  padding: 8px;
  width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
`;
