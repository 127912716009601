import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import Snackbar from '../../Snackbar/Snackbar';
import UpdateInfo from '../../Snackbar/UpdateInfo';

export interface IOSErrorSnackbarProps {
  isBadOS: boolean;
  message?: string;
}

export default function OSErrorSnackbar({ isBadOS, message }: IOSErrorSnackbarProps) {
  const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

  const SnackbarBody = (): JSX.Element => {
    return (
      <Typography variant="body1" component="span" display="block">
        {message}
        <UpdateInfo />
      </Typography>
    );
  };

  return (
    <Snackbar
      variant="warning"
      headline="OS Version Error"
      open={isBadOS && !isSnackbarDismissed}
      handleClose={() => setIsSnackbarDismissed(true)}
      body={<SnackbarBody />}
      includeRefreshButton={false}
    />
  );
}
