import { useCallback, useEffect, useState } from 'react';
import useLocalTracks from '../../components/VideoProvider/useLocalTracks/useLocalTracks';
import { useAudioOutputDevices } from '../../hooks/deviceHooks/deviceHooks';

export default function useActiveSinkId() {
  const audioOutputDevices = useAudioOutputDevices();
  const [activeSinkId, _setActiveSinkId] = useState('default');
  const { setSelectedAudioOutput, selectedAudioOutput } = useLocalTracks();

  const setActiveSinkId = useCallback(
    (sinkId: string) => {
      setSelectedAudioOutput(sinkId);
      _setActiveSinkId(sinkId);
    },
    [_setActiveSinkId, setSelectedAudioOutput]
  );

  useEffect(() => {
    const selectedSinkId = selectedAudioOutput;
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      device => selectedSinkId && device.deviceId === selectedSinkId
    );
    if (hasSelectedAudioOutputDevice) {
      _setActiveSinkId(selectedSinkId!);
    }
  }, [audioOutputDevices, selectedAudioOutput]);

  return [activeSinkId, setActiveSinkId] as const;
}
