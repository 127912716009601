import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { env } from '../env';

const useSentry = (user?: any): void => {
  useEffect(() => {
    if (!env.REACT_APP_SENTRY_URL && !env.REACT_APP_SENTRY_ENVIRONMENT) return;
    try {
      Sentry.init({
        dsn: env.REACT_APP_SENTRY_URL || '',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: env.REACT_APP_SENTRY_ENVIRONMENT || 'Development',
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (user) {
      try {
        Sentry.setUser({
          id: user?.id,
          email: user?.email,
          clinic: user?.vetInfo?.currentClinic?.name,
          clinicId: user?.vetInfo?.currentClinic?.id,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [user]);
};

export default useSentry;
