import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { AngryFace, DiscontentFace, NeutralFace, HappyFace, HeartEyesFace } from '../../assets';
import { RatingSteps } from './CallQualityScreens';

export interface ratingOption {
  description: string;
  imageComponent: JSX.Element;
  rating: number;
}

export const qualityOptions: ratingOption[] = [
  { description: 'Terrible', imageComponent: <AngryFace />, rating: 1 },
  { description: 'Bad', imageComponent: <DiscontentFace />, rating: 2 },
  { description: 'Neutral', imageComponent: <NeutralFace />, rating: 3 },
  { description: 'Good', imageComponent: <HappyFace />, rating: 4 },
  { description: 'Amazing!', imageComponent: <HeartEyesFace />, rating: 5 },
];

interface IRatingsProps {
  setStep: Dispatch<SetStateAction<RatingSteps>>;
  selectedRating: number | null;
  setSelectedRating: (option: ratingOption) => void;
  submitRating: (rating: number, label: string) => void;
}

const Ratings = ({ setStep, setSelectedRating, selectedRating, submitRating }: IRatingsProps) => {
  const goToStep = (option: ratingOption): void => {
    setSelectedRating(option);

    if (option.rating < 4) {
      setStep(RatingSteps.feedbackStep);
    } else {
      setStep(RatingSteps.exitStep);
      submitRating(option.rating, option.description);
    }
  };

  return (
    <RatingsContainer>
      <RatingPrompt>How would you rate the quality of this call?</RatingPrompt>
      <RatingsDiv>
        {qualityOptions.map(option => {
          return (
            <SingleRating
              isSelectedRating={selectedRating ? selectedRating === option.rating : true}
              key={option.rating}
              onClick={(): void => goToStep(option)}
            >
              <RatingImage>{option.imageComponent}</RatingImage>
              <RatingDescription>{option.description}</RatingDescription>
            </SingleRating>
          );
        })}
      </RatingsDiv>
    </RatingsContainer>
  );
};

export default Ratings;

const RatingsContainer = styled.div`
  color: hsl(229, 15%, 44%);
  font-weight: 600;
  text-align: center;
`;

const RatingPrompt = styled.div``;

const RatingsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 12px;
`;

interface ISingleRating {
  isSelectedRating?: boolean;
}
const SingleRating = styled.div<ISingleRating>`
  cursor: pointer;
  opacity: ${({ isSelectedRating }): number => (isSelectedRating ? 1 : 0.5)};
  &:hover {
    opacity: 0.8;
  }
  transition: 0.2s ease-in-out;
`;

const RatingImage = styled.div`
  svg {
    height: 40px;
    width: 40px;
  }

  @media (max-width: 400px) {
    svg {
      height: 32px;
    }
  }
`;
const RatingDescription = styled.div`
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
