import React, { useEffect } from 'react';
import Video from 'twilio-video';
import { Container, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UAParser from 'ua-parser-js';
import styled from 'styled-components/macro';
import useParentConnectionContext from '../../hooks/useParentConnectionContext/useParentConnectionContext';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
  },
});

export const supportedBrowserMap: Record<string, Record<string, boolean>> = {
  Android: { Chrome: true, 'Chrome WebView': true, Firefox: true },
  iOS: { 'Mobile Safari': true, Chrome: true, WebKit: true },
  Linux: { Chrome: true, Firefox: true },
  'Mac OS': { Chrome: true, Firefox: true, Safari: true, Edge: true },
  Windows: { Chrome: true, Firefox: true, Edge: true },
  'Chromium OS': { Chrome: true, Firefox: true, Edge: true },
};

export default function({ children }: { children: React.ReactElement }) {
  const classes = useStyles();

  const { getBrowser, getOS } = new UAParser();
  const { parent } = useParentConnectionContext();

  const browser: string = getBrowser().name || '';
  const OS: string = getOS().name || '';

  useEffect(() => {
    console.info({ OS, browser });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCopyUrl = async (): Promise<void> => {
    if (!window.parent) return;
    const roomData = await parent?.getRoomData();
    try {
      navigator.clipboard.writeText(roomData?.conversationUrl || '').then(
        () => {
          /* success */
          console.info('Copied to clipboard');
        },
        e => {
          /* failure */
          console.warn(e);
        }
      );
    } catch (e) {
      console.warn(e);
    }
  };

  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" className={classes.heading}>
                Browser or Operating System Not Supported
              </Typography>
              <Typography>
                {Object.keys(supportedBrowserMap[OS] || []).length ? (
                  <div>
                    Unfortunately, video calling is not supported with{' '}
                    {browser ? `the ${browser}` : 'your current browser'} and {OS && `${OS} `} operating system
                    combination. <b>Please copy the link below</b> and paste it into one of the following browsers on
                    your {OS || ''} device to join the video call:
                    <ul>
                      {Object.keys(supportedBrowserMap[OS]).map(browserName => {
                        return (
                          <li key={browserName}>
                            <b>{browserName}</b>
                          </li>
                        );
                      })}
                    </ul>
                    <CopyLink onClick={handleCopyUrl}>Copy Conversation URL</CopyLink>
                  </div>
                ) : (
                  <div>
                    There are no supported browsers for this operating system. The supported operating systems include
                    Windows, Android, Mac OS, iOS, and Linux.
                  </div>
                )}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}

const CopyLink = styled.div`
  color: #228aa4;
  text-decoration: underline;
`;
