import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { CallQualitySubmission, tagCloudOption } from './CallQualityScreens';

interface IFeedbackProps {
  feedback: string;
  setFeedbackData: Dispatch<SetStateAction<CallQualitySubmission>>;
  issueOptions: tagCloudOption[];
  sendFeedback: () => void;
}

const Feedback = ({ feedback, setFeedbackData, issueOptions, sendFeedback }: IFeedbackProps) => {
  const saveFeedback = (data: string) => {
    setFeedbackData(prev => ({ ...prev, feedback: data }));
  };

  const selectTag = (selectedOption: tagCloudOption) => {
    setFeedbackData(prev => ({
      ...prev,
      selectedIssues: prev.selectedIssues.map(option => {
        if (option.name === selectedOption.name) {
          return {
            ...option,
            isSelected: !option.isSelected,
          };
        }
        return option;
      }),
    }));
  };

  return (
    <TagContainer>
      <TagDescription>
        We're sorry to hear that. Please select the issues you experienced, and feel free to tell us more about your
        video call experience below.
      </TagDescription>
      <TagCloud>
        {issueOptions.map(option => {
          const { name, isSelected } = option;

          return (
            <TagItem key={name} isSelected={isSelected} onClick={() => selectTag(option)}>
              {name}
            </TagItem>
          );
        })}
      </TagCloud>
      <div>
        <StyledTextArea
          name="feedback-area"
          value={feedback}
          onChange={e => saveFeedback(e.currentTarget.value)}
          placeholder="Tell us more about your video call experience..."
        />
      </div>
      <FeedbackDiv>
        <SendFeedbackButton aria-label="feedback-button" onClick={sendFeedback}>
          Send Feedback
        </SendFeedbackButton>
      </FeedbackDiv>
    </TagContainer>
  );
};

export default Feedback;

const TagContainer = styled.div`
  color: hsl(229, 15%, 44%);
`;
const TagDescription = styled.div`
  font-size: 12px;
  line-height: 1.2;
`;

const TagCloud = styled.div`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`;

const StyledTextArea = styled.textarea`
  border: 1px solid hsl(10, 0%, 80%);
  width: 100%;
  min-height: 60px;
  margin: 16px 0;
  font-family: LinearSans, Arial, Helvetica, sans-serif;
  padding: 8px;
  border-radius: 7px;
  color: hsl(229, 15%, 44%);
`;

interface ITagItem {
  isSelected: boolean;
}

const TagItem = styled.div<ITagItem>`
  border-radius: 4px;
  border: 1px solid hsl(229, 15%, 44%);
  height: 32px;
  margin: 0 0 8px 0;
  padding: 0 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  background-color: ${({ isSelected }): string => (isSelected ? 'hsl(229, 15%, 88%)' : 'inherit')};
  color: ${({ isSelected }): string => (isSelected ? 'hsl(229, 15%, 24%)' : 'hsl(229, 15%, 34%)')};
`;

const FeedbackDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SendFeedbackButton = styled.div`
  background-color: hsl(190, 48%, 40%);
  color: #ffe;
  cursor: pointer;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }
  transition: 0.2s ease-in-out;
  padding: 8px;
  width: 140px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  margin-bottom: 24px;
`;
