import { useEffect } from 'react';
import LogRocket from 'logrocket';
import { env } from '../env';

const useLogRocket = (user?: any): void => {
  useEffect(() => {
    try {
      // Merge IFrames allows us to have the content of the iframe in log rocket sessions
      // Root Host Name control whether sessions that traverse different subdomains on your site are kept intact or broken up into sessions specific to each subdomain
      LogRocket.init(env.REACT_APP_LOG_ROCKET_APP_ID || '', {
        mergeIframes: true,
        rootHostname: 'televet.com',
      });
    } catch (error) {
      console.warn(error);
    }
  }, []);

  useEffect(() => {
    if (user) {
      const name = [user?.firstName || '', user?.lastName || ''].join(' ').trim();
      LogRocket.identify(user?.userId, {
        name,
        email: user?.email,
        userType: user?.type,
        environment: env.REACT_APP_PRODUCTION === 'true' ? 'production' : 'development',
      });
    }
  }, [user]);
};

export default useLogRocket;
