import { useState, useCallback, useEffect } from 'react';
import { Room } from 'twilio-video';
import {
  useToggleTwilioVideoCallRecordingStatusMutation,
  RecordingControlStatus,
  useSubscribeToTwilioCallRecordingStatusSubscription,
  TwilioRecordingStatus,
  useGetCurrentRecordingStatusQuery,
} from '../../../shared/types/graphql';
import { GraphQLFetchPolicy } from '../../../shared/enums/GraphQLFetchPolicy';

export default function useRecordRoomToggle(room: Room) {
  // The purpose of this variable is to ensure that the record button responds as soon as clinic user clicks
  // This should prevent them from double clicking since it uses local state vs waiting for api response
  const [isRecording, setIsRecording] = useState(false);
  // this will pertain specifically to showing the recording icon for both clinic user and pet parent
  const [isSubscriptionRecording, setIsSubscriptionRecording] = useState(false);
  const [toggleTwilioVideoCallRecordingStatus] = useToggleTwilioVideoCallRecordingStatusMutation();

  const { data: subscriptionData } = useSubscribeToTwilioCallRecordingStatusSubscription({
    variables: {
      where: { node: { sid: { equals: room.sid } } },
    },
    fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
  });

  const { data: currentRecordingStatusData } = useGetCurrentRecordingStatusQuery({
    variables: { where: { sid: room.sid } },
    fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    skip: !room.sid,
  });

  // specifically to handle cases when clinic user is already recording before pet parent joins or before another clinic user joins
  useEffect(() => {
    if (currentRecordingStatusData?.findUniqueTwilioCallRecording?.status) {
      if (currentRecordingStatusData.findUniqueTwilioCallRecording.status === TwilioRecordingStatus.InProgress) {
        setIsSubscriptionRecording(true);
        setIsRecording(true);
      }
    }
  }, [currentRecordingStatusData]);

  useEffect(() => {
    if (subscriptionData) {
      const currentStatus = subscriptionData.recordingStatusChanged.node.status;
      setIsSubscriptionRecording(currentStatus === TwilioRecordingStatus.InProgress);
      // makes sure that record button is correctly set for all clinic users in the call
      setIsRecording(currentStatus === TwilioRecordingStatus.InProgress);
    }
  }, [subscriptionData]);

  const toggleRoomRecording = useCallback(async () => {
    try {
      setIsRecording(!isRecording);
      await toggleTwilioVideoCallRecordingStatus({
        variables: {
          data: {
            roomSid: room.sid,
            status: isRecording ? RecordingControlStatus.Paused : RecordingControlStatus.InProgress,
          },
        },
      });
    } catch (e) {
      setIsRecording(!isRecording);
      console.error(e);
    }
  }, [room, isRecording, toggleTwilioVideoCallRecordingStatus]);

  return { isRecording, isSubscriptionRecording, toggleRoomRecording };
}
