import React, { useCallback } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useParentConnectionContext from '../../../hooks/useParentConnectionContext/useParentConnectionContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room, setIsRatingCall } = useVideoContext();
  const { parent } = useParentConnectionContext();

  const endCall = useCallback((): void => {
    room.disconnect();
    setIsRatingCall(true);
    parent?.mixpanelTrack('Video call end call button clicked');
    parent?.mixpanelTrack('Video call exited');
  }, [room, setIsRatingCall, parent]);

  return (
    <Button onClick={endCall} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
