import React, { createContext, ReactNode, useState, useEffect, useRef } from 'react';
import { connectToParent } from 'penpal';
import * as Sentry from '@sentry/react';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ErrorBoundaryFallback from '../ErrorBoundary';
import { Dict } from 'mixpanel-browser';
import { PenpalError } from 'penpal/lib/types';

interface IParentConnection {
  getRoomData(): Promise<{
    userId: string;
    consultId?: string;
    channelId?: string;
    clientName: string;
    appName?: string;
    userInfo?: any;
    clinicName?: string;
    conversationUrl?: string;
    hasEnhancedVideoRecording: boolean;
    isClinicUser?: boolean;
  }>;
  onCallConnect(roomSid: string): Promise<void>;
  onCallEnd(): Promise<void>;
  mixpanelTrack(name: string, props?: Dict | undefined): Promise<void>;
  mixpanelIdentify(id: string | undefined): Promise<void>;
  mixpanelInit(token: string | undefined): Promise<void>;
  mixpanelSetPeople(props?: any): Promise<void>;
}

interface IParentConnectionContext {
  parent: IParentConnection | null;
}

export const ParentConnectionContext = createContext<IParentConnectionContext>({ parent: null });

interface ParentConnectionProviderProps {
  children: ReactNode;
}

export const ParentConnectionProvider = (props: ParentConnectionProviderProps) => {
  const { children } = props;

  const [parent, setParent] = useState<IParentConnection | null>(null);
  const [connectionPending, setConnectionPending] = useState<boolean>(false);
  const { room, hasRatedCall, isRatingCall, setIsClinicUser } = useVideoContext();
  const parentRef = useRef(parent);
  const roomRef = useRef(room);
  const hasRatedRef = useRef(hasRatedCall);
  const isCurrentlyRatingRef = useRef(isRatingCall);

  useEffect(() => {
    roomRef.current = room;
    parentRef.current = parent;
    hasRatedRef.current = hasRatedCall;
    isCurrentlyRatingRef.current = isRatingCall;
  }, [room, parent, hasRatedCall, isRatingCall]);

  useEffect(() => {
    // only exists once a connection has been made
    if (roomRef.current.sid && typeof parent?.onCallConnect === 'function') {
      parent?.onCallConnect(roomRef.current.sid);
    }
  }, [roomRef.current.sid, parent]);

  useEffect(() => {
    const fetchRoomData = async () => {
      const roomData = await parent?.getRoomData();
      setIsClinicUser(roomData?.isClinicUser || false);
    };
    fetchRoomData();
  }, [parent, setIsClinicUser]);

  useEffect(() => {
    // connectToParent has to happen after connectToChild completes in care/flow, so retry every second until then
    if (parent !== null || connectionPending) return;

    try {
      const connection = connectToParent<IParentConnection>({
        methods: {
          endCall: () => {
            try {
              if (roomRef && typeof roomRef.current?.disconnect === 'function') {
                roomRef.current.disconnect();
              }

              navigator.mediaDevices
                .getUserMedia({ audio: true, video: true })
                .then(mediaStream => mediaStream.getTracks().forEach(track => track.stop()))
                .catch(e => console.error(e));

              return { hasRatedVideoCall: hasRatedRef.current };
            } catch (e) {
              console.error(e);
              return { hasRatedVideoCall: hasRatedRef.current };
            }
          },
        },
        timeout: 1000,
      });

      setConnectionPending(true);

      connection.promise
        .then(connectionParent => {
          setParent(connectionParent);
          setConnectionPending(false);
        })
        .catch((e: PenpalError) => {
          // don't need to log for timeouts, since they're expected
          if (e.code !== 'ConnectionTimeout') {
            console.error(e.code, e);
          }
          setConnectionPending(false);
        });
      return () => {
        if (connectionPending) {
          connection.destroy();
          setConnectionPending(false);
        }
      };
    } catch (e) {
      console.warn(e);
    }
  }, [parent, connectionPending]);

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      <ParentConnectionContext.Provider
        value={{
          parent,
        }}
      >
        {children}
      </ParentConnectionContext.Provider>
    </Sentry.ErrorBoundary>
  );
};
