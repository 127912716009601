import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import React, { useEffect, useMemo, useState } from 'react';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Participant } from 'twilio-video';
import styled from 'styled-components/macro';
import { networkQualityMap } from '../../constants';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import { ArrowIcon } from '../../icons';
import { QualityDisplay } from '../../interfaces';

export const printNetworkQualityStats = (participant: Participant) => {
  console.log(`Network Quality Stats for ${participant.identity}:`);
  const { networkQualityStats, networkQualityLevel } = participant;

  const qualityChart: Record<number, string> = {
    1: '▃',
    2: '▃▄',
    3: '▃▄▅',
    4: '▃▄▅▆',
    5: '▃▄▅▆▇',
  };

  if (networkQualityLevel)
    // Print in console the networkQualityLevel using bars
    console.log({
      networkQualityLevel: `${participant.identity}: ${networkQualityLevel}/5 ${qualityChart[networkQualityLevel]}`,
    });

  if (networkQualityStats) {
    // Print in console the networkQualityStats, which is non-null only if Network Quality
    // verbosity is 2 (moderate) or greater
    console.log(`Network Quality Stats for ${participant.identity}:`, networkQualityStats);
  }
};

export default function MainParticipant() {
  const mainParticipant = useMainParticipant();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const networkQualityLevel: number | null = useParticipantNetworkQualityLevel(localParticipant);
  const [isMinimized, setIsMinimized] = useState<boolean>(true);

  const quality: QualityDisplay = useMemo(() => {
    if (networkQualityLevel) return networkQualityMap[networkQualityLevel];
    return networkQualityMap[10];
  }, [networkQualityLevel]);

  const toggleMinimize = (): void => {
    setIsMinimized(m => !m);
  };

  // Print the initial Network Quality Level and statistics
  printNetworkQualityStats(mainParticipant);

  // Print changes to Network Quality Level and statistics
  useEffect(() => {
    mainParticipant.on('networkQualityLevelChanged', () => printNetworkQualityStats(mainParticipant));
    localParticipant.on('networkQualityLevelChanged', () => printNetworkQualityStats(localParticipant));
  }, []); //eslint-disable-line

  const videoPriority =
    (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
    mainParticipant !== localParticipant
      ? 'high'
      : null;

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={mainParticipant}>
      {networkQualityLevel && (
        <AlertContainer
          isMinimized={isMinimized}
          backgroundcolor={quality.backgroundcolor}
          textcolor={quality.textcolor}
        >
          {quality.icon}
          {!isMinimized && (
            <AlertText
              isMinimized={isMinimized}
              backgroundcolor={quality.backgroundcolor}
              textcolor={quality.textcolor}
            >
              {quality.description}
            </AlertText>
          )}
          <ArrowIcon $onMinimize={toggleMinimize} isMinimized={isMinimized} textcolor={quality.textcolor} />
        </AlertContainer>
      )}
      <ParticipantTracks
        participant={mainParticipant}
        videoOnly
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  );
}

interface IQualityContainer {
  backgroundcolor: string;
  textcolor: string;
  isMinimized: boolean;
}

const AlertContainer = styled.div<IQualityContainer>`
  height: ${({ isMinimized }) => (isMinimized ? '50px' : ' 70px')};
  width: ${({ isMinimized }) => (isMinimized ? '70px' : ' 300px')};
  bottom: 120px;
  left: 12px;
  border-radius: 6px;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: ${({ textcolor }) => textcolor};
  padding: 0 8px;
  transition: 0.4s ease-in-out;

  & svg {
    height: 24px;
    width: 24px;
    & path {
      fill: ${({ textcolor }) => (textcolor === 'white' ? '#fff' : 'default')};
    }
  }
`;

const AlertText = styled.div<IQualityContainer>`
  margin: 0 12px;
  line-height: 1rem;
  max-height: ${({ isMinimized }) => (isMinimized ? '30px' : ' 50px')};
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
`;
