// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
export default function enhanceMessage(message = '', code?: number, name?: string) {
  switch (code) {
    case 20101: // Invalid token error
      return message + '. Please make sure you are using the correct credentials.';
    default:
      switch (name) {
        case 'NotReadableError':
        case 'TrackStartError':
          return (
            message +
            '. Please make sure your camera is not already in use by another application, such as another browser or desktop app (Zoom, Skype, etc.).'
          );
        default:
          return message;
      }
  }
}
