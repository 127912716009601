import { ApolloClient, InMemoryCache, createHttpLink, split, from } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ConnectionParams } from 'subscriptions-transport-ws';
import * as Sentry from '@sentry/react';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { env } from './env';
const wsLink = new WebSocketLink({
  uri: env.REACT_APP_SUBSCRIPTIONS_URI || '',
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async (): Promise<ConnectionParams> => {
      return {
        authorization: `Bearer ${env.REACT_APP_API_KEY}`,
      };
    },
    connectionCallback: (error): void => {
      if (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    },
  },
});

const httpLink = createHttpLink({
  uri: env.REACT_APP_CORE_API_URI,
});

const authLink = setContext(async (_, { headers }: { headers: Record<string, string> }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
  };
});
const additiveLink = from([httpLink]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  additiveLink
);
export const client = new ApolloClient({
  link: authLink.concat(splitLink),
  uri: env.REACT_APP_CORE_API_URI,
  cache: new InMemoryCache(),
});
