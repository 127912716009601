import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    },
  },
}));

interface IMobileTopMenuBarProps {
  roomName: string;
}

export default function MobileTopMenuBar({ roomName }: IMobileTopMenuBarProps) {
  const classes = useStyles();

  return (
    <Grid
      style={{ padding: '4px 0 4px 8px' }}
      container
      alignItems="center"
      justify="space-between"
      className={classes.container}
    >
      {roomName && (
        <Typography style={{ maxWidth: '50%' }} variant="subtitle1">
          Conversation with {roomName}
        </Typography>
      )}
      <div>
        <EndCallButton className={classes.endCallButton} />
        <Menu buttonClassName={classes.settingsButton} />
      </div>
    </Grid>
  );
}
