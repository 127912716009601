import React from 'react';

import Button from '@material-ui/core/Button';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { RecordingOnIcon, RecordingOffIcon } from '../../../icons';

export default function ToggleRecordingButton(props: { disabled?: boolean; className?: string }) {
  const { isRecording, toggleRoomRecording } = useVideoContext();

  return (
    <Button
      className={props.className}
      onClick={() => {
        toggleRoomRecording();
      }}
      startIcon={isRecording ? <RecordingOnIcon /> : <RecordingOffIcon />}
    >
      {isRecording ? 'Stop Recording' : 'Record'}
    </Button>
  );
}
