import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Json: any;
  Placeholder: any;
};

export type AcceptUserInvitationInput = {
  invitationCode: Scalars['String'];
};

export type AcceptUserInvitationResponse = {
  __typename?: 'AcceptUserInvitationResponse';
  token?: Maybe<Scalars['String']>;
};

export type AggregateApiKey = {
  __typename?: 'AggregateApiKey';
  _count?: Maybe<ApiKeyCountAggregateOutputType>;
  _min?: Maybe<ApiKeyMinAggregateOutputType>;
  _max?: Maybe<ApiKeyMaxAggregateOutputType>;
};

export type AggregateAppointment = {
  __typename?: 'AggregateAppointment';
  _count?: Maybe<AppointmentCountAggregateOutputType>;
  _avg?: Maybe<AppointmentAvgAggregateOutputType>;
  _sum?: Maybe<AppointmentSumAggregateOutputType>;
  _min?: Maybe<AppointmentMinAggregateOutputType>;
  _max?: Maybe<AppointmentMaxAggregateOutputType>;
};

export type AggregateAppointmentType = {
  __typename?: 'AggregateAppointmentType';
  _count?: Maybe<AppointmentTypeCountAggregateOutputType>;
  _avg?: Maybe<AppointmentTypeAvgAggregateOutputType>;
  _sum?: Maybe<AppointmentTypeSumAggregateOutputType>;
  _min?: Maybe<AppointmentTypeMinAggregateOutputType>;
  _max?: Maybe<AppointmentTypeMaxAggregateOutputType>;
};

export type AggregateAutomationRun = {
  __typename?: 'AggregateAutomationRun';
  _count?: Maybe<AutomationRunCountAggregateOutputType>;
  _min?: Maybe<AutomationRunMinAggregateOutputType>;
  _max?: Maybe<AutomationRunMaxAggregateOutputType>;
};

export type AggregateAutomationRunAction = {
  __typename?: 'AggregateAutomationRunAction';
  _count?: Maybe<AutomationRunActionCountAggregateOutputType>;
  _avg?: Maybe<AutomationRunActionAvgAggregateOutputType>;
  _sum?: Maybe<AutomationRunActionSumAggregateOutputType>;
  _min?: Maybe<AutomationRunActionMinAggregateOutputType>;
  _max?: Maybe<AutomationRunActionMaxAggregateOutputType>;
};

export type AggregateBitwerxIntegration = {
  __typename?: 'AggregateBitwerxIntegration';
  _count?: Maybe<BitwerxIntegrationCountAggregateOutputType>;
  _min?: Maybe<BitwerxIntegrationMinAggregateOutputType>;
  _max?: Maybe<BitwerxIntegrationMaxAggregateOutputType>;
};

export type AggregateCallHistory = {
  __typename?: 'AggregateCallHistory';
  _count?: Maybe<CallHistoryCountAggregateOutputType>;
  _avg?: Maybe<CallHistoryAvgAggregateOutputType>;
  _sum?: Maybe<CallHistorySumAggregateOutputType>;
  _min?: Maybe<CallHistoryMinAggregateOutputType>;
  _max?: Maybe<CallHistoryMaxAggregateOutputType>;
};

export type AggregateCallParticipant = {
  __typename?: 'AggregateCallParticipant';
  _count?: Maybe<CallParticipantCountAggregateOutputType>;
  _min?: Maybe<CallParticipantMinAggregateOutputType>;
  _max?: Maybe<CallParticipantMaxAggregateOutputType>;
};

export type AggregateCallRecording = {
  __typename?: 'AggregateCallRecording';
  _count?: Maybe<CallRecordingCountAggregateOutputType>;
  _avg?: Maybe<CallRecordingAvgAggregateOutputType>;
  _sum?: Maybe<CallRecordingSumAggregateOutputType>;
  _min?: Maybe<CallRecordingMinAggregateOutputType>;
  _max?: Maybe<CallRecordingMaxAggregateOutputType>;
};

export type AggregateCareAddonEnrollment = {
  __typename?: 'AggregateCareAddonEnrollment';
  _count?: Maybe<CareAddonEnrollmentCountAggregateOutputType>;
  _min?: Maybe<CareAddonEnrollmentMinAggregateOutputType>;
  _max?: Maybe<CareAddonEnrollmentMaxAggregateOutputType>;
};

export type AggregateCareAddonPackage = {
  __typename?: 'AggregateCareAddonPackage';
  _count?: Maybe<CareAddonPackageCountAggregateOutputType>;
  _avg?: Maybe<CareAddonPackageAvgAggregateOutputType>;
  _sum?: Maybe<CareAddonPackageSumAggregateOutputType>;
  _min?: Maybe<CareAddonPackageMinAggregateOutputType>;
  _max?: Maybe<CareAddonPackageMaxAggregateOutputType>;
};

export type AggregateCareAddonPackageBenefit = {
  __typename?: 'AggregateCareAddonPackageBenefit';
  _count?: Maybe<CareAddonPackageBenefitCountAggregateOutputType>;
  _avg?: Maybe<CareAddonPackageBenefitAvgAggregateOutputType>;
  _sum?: Maybe<CareAddonPackageBenefitSumAggregateOutputType>;
  _min?: Maybe<CareAddonPackageBenefitMinAggregateOutputType>;
  _max?: Maybe<CareAddonPackageBenefitMaxAggregateOutputType>;
};

export type AggregateCareAuthorizationRequest = {
  __typename?: 'AggregateCareAuthorizationRequest';
  _count?: Maybe<CareAuthorizationRequestCountAggregateOutputType>;
  _min?: Maybe<CareAuthorizationRequestMinAggregateOutputType>;
  _max?: Maybe<CareAuthorizationRequestMaxAggregateOutputType>;
};

export type AggregateCareBenefit = {
  __typename?: 'AggregateCareBenefit';
  _count?: Maybe<CareBenefitCountAggregateOutputType>;
  _avg?: Maybe<CareBenefitAvgAggregateOutputType>;
  _sum?: Maybe<CareBenefitSumAggregateOutputType>;
  _min?: Maybe<CareBenefitMinAggregateOutputType>;
  _max?: Maybe<CareBenefitMaxAggregateOutputType>;
};

export type AggregateCareBenefitUsage = {
  __typename?: 'AggregateCareBenefitUsage';
  _count?: Maybe<CareBenefitUsageCountAggregateOutputType>;
  _avg?: Maybe<CareBenefitUsageAvgAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageSumAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageMinAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageMaxAggregateOutputType>;
};

export type AggregateCareBenefitUsageToInvoiceLineItem = {
  __typename?: 'AggregateCareBenefitUsageToInvoiceLineItem';
  _count?: Maybe<CareBenefitUsageToInvoiceLineItemCountAggregateOutputType>;
  _avg?: Maybe<CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageToInvoiceLineItemSumAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageToInvoiceLineItemMinAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType>;
};

export type AggregateCareEnrollmentPayment = {
  __typename?: 'AggregateCareEnrollmentPayment';
  _count?: Maybe<CareEnrollmentPaymentCountAggregateOutputType>;
  _avg?: Maybe<CareEnrollmentPaymentAvgAggregateOutputType>;
  _sum?: Maybe<CareEnrollmentPaymentSumAggregateOutputType>;
  _min?: Maybe<CareEnrollmentPaymentMinAggregateOutputType>;
  _max?: Maybe<CareEnrollmentPaymentMaxAggregateOutputType>;
};

export type AggregateCareEnrollmentToStripeInvoiceItem = {
  __typename?: 'AggregateCareEnrollmentToStripeInvoiceItem';
  _count?: Maybe<CareEnrollmentToStripeInvoiceItemCountAggregateOutputType>;
  _min?: Maybe<CareEnrollmentToStripeInvoiceItemMinAggregateOutputType>;
  _max?: Maybe<CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType>;
};

export type AggregateCarePlan = {
  __typename?: 'AggregateCarePlan';
  _count?: Maybe<CarePlanCountAggregateOutputType>;
  _avg?: Maybe<CarePlanAvgAggregateOutputType>;
  _sum?: Maybe<CarePlanSumAggregateOutputType>;
  _min?: Maybe<CarePlanMinAggregateOutputType>;
  _max?: Maybe<CarePlanMaxAggregateOutputType>;
};

export type AggregateCarePlanBenefit = {
  __typename?: 'AggregateCarePlanBenefit';
  _count?: Maybe<CarePlanBenefitCountAggregateOutputType>;
  _avg?: Maybe<CarePlanBenefitAvgAggregateOutputType>;
  _sum?: Maybe<CarePlanBenefitSumAggregateOutputType>;
  _min?: Maybe<CarePlanBenefitMinAggregateOutputType>;
  _max?: Maybe<CarePlanBenefitMaxAggregateOutputType>;
};

export type AggregateCarePlanEnrollment = {
  __typename?: 'AggregateCarePlanEnrollment';
  _count?: Maybe<CarePlanEnrollmentCountAggregateOutputType>;
  _min?: Maybe<CarePlanEnrollmentMinAggregateOutputType>;
  _max?: Maybe<CarePlanEnrollmentMaxAggregateOutputType>;
};

export type AggregateCareStripeSubscription = {
  __typename?: 'AggregateCareStripeSubscription';
  _count?: Maybe<CareStripeSubscriptionCountAggregateOutputType>;
  _avg?: Maybe<CareStripeSubscriptionAvgAggregateOutputType>;
  _sum?: Maybe<CareStripeSubscriptionSumAggregateOutputType>;
  _min?: Maybe<CareStripeSubscriptionMinAggregateOutputType>;
  _max?: Maybe<CareStripeSubscriptionMaxAggregateOutputType>;
};

export type AggregateChannel = {
  __typename?: 'AggregateChannel';
  _count?: Maybe<ChannelCountAggregateOutputType>;
  _min?: Maybe<ChannelMinAggregateOutputType>;
  _max?: Maybe<ChannelMaxAggregateOutputType>;
};

export type AggregateChannelAutomationStatus = {
  __typename?: 'AggregateChannelAutomationStatus';
  _count?: Maybe<ChannelAutomationStatusCountAggregateOutputType>;
  _min?: Maybe<ChannelAutomationStatusMinAggregateOutputType>;
  _max?: Maybe<ChannelAutomationStatusMaxAggregateOutputType>;
};

export type AggregateChannelCreationSource = {
  __typename?: 'AggregateChannelCreationSource';
  _count?: Maybe<ChannelCreationSourceCountAggregateOutputType>;
  _min?: Maybe<ChannelCreationSourceMinAggregateOutputType>;
  _max?: Maybe<ChannelCreationSourceMaxAggregateOutputType>;
};

export type AggregateChannelFilterSelection = {
  __typename?: 'AggregateChannelFilterSelection';
  _count?: Maybe<ChannelFilterSelectionCountAggregateOutputType>;
  _min?: Maybe<ChannelFilterSelectionMinAggregateOutputType>;
  _max?: Maybe<ChannelFilterSelectionMaxAggregateOutputType>;
};

export type AggregateChannelMember = {
  __typename?: 'AggregateChannelMember';
  _count?: Maybe<ChannelMemberCountAggregateOutputType>;
  _avg?: Maybe<ChannelMemberAvgAggregateOutputType>;
  _sum?: Maybe<ChannelMemberSumAggregateOutputType>;
  _min?: Maybe<ChannelMemberMinAggregateOutputType>;
  _max?: Maybe<ChannelMemberMaxAggregateOutputType>;
};

export type AggregateChannelMessage = {
  __typename?: 'AggregateChannelMessage';
  _count?: Maybe<ChannelMessageCountAggregateOutputType>;
  _avg?: Maybe<ChannelMessageAvgAggregateOutputType>;
  _sum?: Maybe<ChannelMessageSumAggregateOutputType>;
  _min?: Maybe<ChannelMessageMinAggregateOutputType>;
  _max?: Maybe<ChannelMessageMaxAggregateOutputType>;
};

export type AggregateChannelMessageAttachment = {
  __typename?: 'AggregateChannelMessageAttachment';
  _count?: Maybe<ChannelMessageAttachmentCountAggregateOutputType>;
  _min?: Maybe<ChannelMessageAttachmentMinAggregateOutputType>;
  _max?: Maybe<ChannelMessageAttachmentMaxAggregateOutputType>;
};

export type AggregateChannelMessageReaction = {
  __typename?: 'AggregateChannelMessageReaction';
  _count?: Maybe<ChannelMessageReactionCountAggregateOutputType>;
  _min?: Maybe<ChannelMessageReactionMinAggregateOutputType>;
  _max?: Maybe<ChannelMessageReactionMaxAggregateOutputType>;
};

export type AggregateChannelSelection = {
  __typename?: 'AggregateChannelSelection';
  _count?: Maybe<ChannelSelectionCountAggregateOutputType>;
  _min?: Maybe<ChannelSelectionMinAggregateOutputType>;
  _max?: Maybe<ChannelSelectionMaxAggregateOutputType>;
};

export type AggregateChannelStatus = {
  __typename?: 'AggregateChannelStatus';
  _count?: Maybe<ChannelStatusCountAggregateOutputType>;
  _avg?: Maybe<ChannelStatusAvgAggregateOutputType>;
  _sum?: Maybe<ChannelStatusSumAggregateOutputType>;
  _min?: Maybe<ChannelStatusMinAggregateOutputType>;
  _max?: Maybe<ChannelStatusMaxAggregateOutputType>;
};

export type AggregateChannelStatusChannelAssignee = {
  __typename?: 'AggregateChannelStatusChannelAssignee';
  _count?: Maybe<ChannelStatusChannelAssigneeCountAggregateOutputType>;
  _min?: Maybe<ChannelStatusChannelAssigneeMinAggregateOutputType>;
  _max?: Maybe<ChannelStatusChannelAssigneeMaxAggregateOutputType>;
};

export type AggregateClinic = {
  __typename?: 'AggregateClinic';
  _count?: Maybe<ClinicCountAggregateOutputType>;
  _avg?: Maybe<ClinicAvgAggregateOutputType>;
  _sum?: Maybe<ClinicSumAggregateOutputType>;
  _min?: Maybe<ClinicMinAggregateOutputType>;
  _max?: Maybe<ClinicMaxAggregateOutputType>;
};

export type AggregateClinicDirectBookingSetting = {
  __typename?: 'AggregateClinicDirectBookingSetting';
  _count?: Maybe<ClinicDirectBookingSettingCountAggregateOutputType>;
  _avg?: Maybe<ClinicDirectBookingSettingAvgAggregateOutputType>;
  _sum?: Maybe<ClinicDirectBookingSettingSumAggregateOutputType>;
  _min?: Maybe<ClinicDirectBookingSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicDirectBookingSettingMaxAggregateOutputType>;
};

export type AggregateClinicEmailCampaignSetting = {
  __typename?: 'AggregateClinicEmailCampaignSetting';
  _count?: Maybe<ClinicEmailCampaignSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicEmailCampaignSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicEmailCampaignSettingMaxAggregateOutputType>;
};

export type AggregateClinicEmployee = {
  __typename?: 'AggregateClinicEmployee';
  _count?: Maybe<ClinicEmployeeCountAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeMinAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeMaxAggregateOutputType>;
};

export type AggregateClinicEmployeeAppointmentTypeSetting = {
  __typename?: 'AggregateClinicEmployeeAppointmentTypeSetting';
  _count?: Maybe<ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType>;
};

export type AggregateClinicEmployeeToWorkflowEventSetting = {
  __typename?: 'AggregateClinicEmployeeToWorkflowEventSetting';
  _count?: Maybe<ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType>;
};

export type AggregateClinicEntityPhoneNumber = {
  __typename?: 'AggregateClinicEntityPhoneNumber';
  _count?: Maybe<ClinicEntityPhoneNumberCountAggregateOutputType>;
  _min?: Maybe<ClinicEntityPhoneNumberMinAggregateOutputType>;
  _max?: Maybe<ClinicEntityPhoneNumberMaxAggregateOutputType>;
};

export type AggregateClinicOfficeHour = {
  __typename?: 'AggregateClinicOfficeHour';
  _count?: Maybe<ClinicOfficeHourCountAggregateOutputType>;
  _avg?: Maybe<ClinicOfficeHourAvgAggregateOutputType>;
  _sum?: Maybe<ClinicOfficeHourSumAggregateOutputType>;
  _min?: Maybe<ClinicOfficeHourMinAggregateOutputType>;
  _max?: Maybe<ClinicOfficeHourMaxAggregateOutputType>;
};

export type AggregateClinicPaymentFeeConfiguration = {
  __typename?: 'AggregateClinicPaymentFeeConfiguration';
  _count?: Maybe<ClinicPaymentFeeConfigurationCountAggregateOutputType>;
  _avg?: Maybe<ClinicPaymentFeeConfigurationAvgAggregateOutputType>;
  _sum?: Maybe<ClinicPaymentFeeConfigurationSumAggregateOutputType>;
  _min?: Maybe<ClinicPaymentFeeConfigurationMinAggregateOutputType>;
  _max?: Maybe<ClinicPaymentFeeConfigurationMaxAggregateOutputType>;
};

export type AggregateClinicPet = {
  __typename?: 'AggregateClinicPet';
  _count?: Maybe<ClinicPetCountAggregateOutputType>;
  _avg?: Maybe<ClinicPetAvgAggregateOutputType>;
  _sum?: Maybe<ClinicPetSumAggregateOutputType>;
  _min?: Maybe<ClinicPetMinAggregateOutputType>;
  _max?: Maybe<ClinicPetMaxAggregateOutputType>;
};

export type AggregateClinicPetAlert = {
  __typename?: 'AggregateClinicPetAlert';
  _count?: Maybe<ClinicPetAlertCountAggregateOutputType>;
  _min?: Maybe<ClinicPetAlertMinAggregateOutputType>;
  _max?: Maybe<ClinicPetAlertMaxAggregateOutputType>;
};

export type AggregateClinicPetParent = {
  __typename?: 'AggregateClinicPetParent';
  _count?: Maybe<ClinicPetParentCountAggregateOutputType>;
  _min?: Maybe<ClinicPetParentMinAggregateOutputType>;
  _max?: Maybe<ClinicPetParentMaxAggregateOutputType>;
};

export type AggregateClinicPetParentAddress = {
  __typename?: 'AggregateClinicPetParentAddress';
  _count?: Maybe<ClinicPetParentAddressCountAggregateOutputType>;
  _min?: Maybe<ClinicPetParentAddressMinAggregateOutputType>;
  _max?: Maybe<ClinicPetParentAddressMaxAggregateOutputType>;
};

export type AggregateClinicPhoneNumber = {
  __typename?: 'AggregateClinicPhoneNumber';
  _count?: Maybe<ClinicPhoneNumberCountAggregateOutputType>;
  _min?: Maybe<ClinicPhoneNumberMinAggregateOutputType>;
  _max?: Maybe<ClinicPhoneNumberMaxAggregateOutputType>;
};

export type AggregateClinicPimsIntegration = {
  __typename?: 'AggregateClinicPimsIntegration';
  _count?: Maybe<ClinicPimsIntegrationCountAggregateOutputType>;
  _min?: Maybe<ClinicPimsIntegrationMinAggregateOutputType>;
  _max?: Maybe<ClinicPimsIntegrationMaxAggregateOutputType>;
};

export type AggregateClinicPimsIntegrationCapability = {
  __typename?: 'AggregateClinicPimsIntegrationCapability';
  _count?: Maybe<ClinicPimsIntegrationCapabilityCountAggregateOutputType>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityMinAggregateOutputType>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityMaxAggregateOutputType>;
};

export type AggregateClinicPostcardSetting = {
  __typename?: 'AggregateClinicPostcardSetting';
  _count?: Maybe<ClinicPostcardSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicPostcardSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicPostcardSettingMaxAggregateOutputType>;
};

export type AggregateClinicRoom = {
  __typename?: 'AggregateClinicRoom';
  _count?: Maybe<ClinicRoomCountAggregateOutputType>;
  _min?: Maybe<ClinicRoomMinAggregateOutputType>;
  _max?: Maybe<ClinicRoomMaxAggregateOutputType>;
};

export type AggregateClinicRoomToWorkflowEventSetting = {
  __typename?: 'AggregateClinicRoomToWorkflowEventSetting';
  _count?: Maybe<ClinicRoomToWorkflowEventSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicRoomToWorkflowEventSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicRoomToWorkflowEventSettingMaxAggregateOutputType>;
};

export type AggregateClinicSetting = {
  __typename?: 'AggregateClinicSetting';
  _count?: Maybe<ClinicSettingCountAggregateOutputType>;
  _avg?: Maybe<ClinicSettingAvgAggregateOutputType>;
  _sum?: Maybe<ClinicSettingSumAggregateOutputType>;
  _min?: Maybe<ClinicSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicSettingMaxAggregateOutputType>;
};

export type AggregateClinicWidgetRequest = {
  __typename?: 'AggregateClinicWidgetRequest';
  _count?: Maybe<ClinicWidgetRequestCountAggregateOutputType>;
  _min?: Maybe<ClinicWidgetRequestMinAggregateOutputType>;
  _max?: Maybe<ClinicWidgetRequestMaxAggregateOutputType>;
};

export type AggregateClinicWidgetRequestType = {
  __typename?: 'AggregateClinicWidgetRequestType';
  _count?: Maybe<ClinicWidgetRequestTypeCountAggregateOutputType>;
  _avg?: Maybe<ClinicWidgetRequestTypeAvgAggregateOutputType>;
  _sum?: Maybe<ClinicWidgetRequestTypeSumAggregateOutputType>;
  _min?: Maybe<ClinicWidgetRequestTypeMinAggregateOutputType>;
  _max?: Maybe<ClinicWidgetRequestTypeMaxAggregateOutputType>;
};

export type AggregateClinicWidgetSetting = {
  __typename?: 'AggregateClinicWidgetSetting';
  _count?: Maybe<ClinicWidgetSettingCountAggregateOutputType>;
  _min?: Maybe<ClinicWidgetSettingMinAggregateOutputType>;
  _max?: Maybe<ClinicWidgetSettingMaxAggregateOutputType>;
};

export type AggregateClinicWorkflowType = {
  __typename?: 'AggregateClinicWorkflowType';
  _count?: Maybe<ClinicWorkflowTypeCountAggregateOutputType>;
  _min?: Maybe<ClinicWorkflowTypeMinAggregateOutputType>;
  _max?: Maybe<ClinicWorkflowTypeMaxAggregateOutputType>;
};

export type AggregateConsultation = {
  __typename?: 'AggregateConsultation';
  _count?: Maybe<ConsultationCountAggregateOutputType>;
  _avg?: Maybe<ConsultationAvgAggregateOutputType>;
  _sum?: Maybe<ConsultationSumAggregateOutputType>;
  _min?: Maybe<ConsultationMinAggregateOutputType>;
  _max?: Maybe<ConsultationMaxAggregateOutputType>;
};

export type AggregateConsultationChat = {
  __typename?: 'AggregateConsultationChat';
  _count?: Maybe<ConsultationChatCountAggregateOutputType>;
  _min?: Maybe<ConsultationChatMinAggregateOutputType>;
  _max?: Maybe<ConsultationChatMaxAggregateOutputType>;
};

export type AggregateConsultationRequest = {
  __typename?: 'AggregateConsultationRequest';
  _count?: Maybe<ConsultationRequestCountAggregateOutputType>;
  _min?: Maybe<ConsultationRequestMinAggregateOutputType>;
  _max?: Maybe<ConsultationRequestMaxAggregateOutputType>;
};

export type AggregateDataPointIntegration = {
  __typename?: 'AggregateDataPointIntegration';
  _count?: Maybe<DataPointIntegrationCountAggregateOutputType>;
  _min?: Maybe<DataPointIntegrationMinAggregateOutputType>;
  _max?: Maybe<DataPointIntegrationMaxAggregateOutputType>;
};

export type AggregateDisbursement = {
  __typename?: 'AggregateDisbursement';
  _count?: Maybe<DisbursementCountAggregateOutputType>;
  _avg?: Maybe<DisbursementAvgAggregateOutputType>;
  _sum?: Maybe<DisbursementSumAggregateOutputType>;
  _min?: Maybe<DisbursementMinAggregateOutputType>;
  _max?: Maybe<DisbursementMaxAggregateOutputType>;
};

export type AggregateEmailProviderLog = {
  __typename?: 'AggregateEmailProviderLog';
  _count?: Maybe<EmailProviderLogCountAggregateOutputType>;
  _min?: Maybe<EmailProviderLogMinAggregateOutputType>;
  _max?: Maybe<EmailProviderLogMaxAggregateOutputType>;
};

export type AggregateEmergencyClinics = {
  __typename?: 'AggregateEmergencyClinics';
  _count?: Maybe<EmergencyClinicsCountAggregateOutputType>;
  _avg?: Maybe<EmergencyClinicsAvgAggregateOutputType>;
  _sum?: Maybe<EmergencyClinicsSumAggregateOutputType>;
  _min?: Maybe<EmergencyClinicsMinAggregateOutputType>;
  _max?: Maybe<EmergencyClinicsMaxAggregateOutputType>;
};

export type AggregateEzyVetIntegration = {
  __typename?: 'AggregateEzyVetIntegration';
  _count?: Maybe<EzyVetIntegrationCountAggregateOutputType>;
  _min?: Maybe<EzyVetIntegrationMinAggregateOutputType>;
  _max?: Maybe<EzyVetIntegrationMaxAggregateOutputType>;
};

export type AggregateFeatureFlag = {
  __typename?: 'AggregateFeatureFlag';
  _count?: Maybe<FeatureFlagCountAggregateOutputType>;
  _min?: Maybe<FeatureFlagMinAggregateOutputType>;
  _max?: Maybe<FeatureFlagMaxAggregateOutputType>;
};

export type AggregateFinancialTransaction = {
  __typename?: 'AggregateFinancialTransaction';
  _count?: Maybe<FinancialTransactionCountAggregateOutputType>;
  _min?: Maybe<FinancialTransactionMinAggregateOutputType>;
  _max?: Maybe<FinancialTransactionMaxAggregateOutputType>;
};

export type AggregateFirebaseStructure = {
  __typename?: 'AggregateFirebaseStructure';
  _count?: Maybe<FirebaseStructureCountAggregateOutputType>;
  _min?: Maybe<FirebaseStructureMinAggregateOutputType>;
  _max?: Maybe<FirebaseStructureMaxAggregateOutputType>;
};

export type AggregateFormSubmission = {
  __typename?: 'AggregateFormSubmission';
  _count?: Maybe<FormSubmissionCountAggregateOutputType>;
  _min?: Maybe<FormSubmissionMinAggregateOutputType>;
  _max?: Maybe<FormSubmissionMaxAggregateOutputType>;
};

export type AggregateFormTemplate = {
  __typename?: 'AggregateFormTemplate';
  _count?: Maybe<FormTemplateCountAggregateOutputType>;
  _min?: Maybe<FormTemplateMinAggregateOutputType>;
  _max?: Maybe<FormTemplateMaxAggregateOutputType>;
};

export type AggregateImage = {
  __typename?: 'AggregateImage';
  _count?: Maybe<ImageCountAggregateOutputType>;
  _min?: Maybe<ImageMinAggregateOutputType>;
  _max?: Maybe<ImageMaxAggregateOutputType>;
};

export type AggregateInstinctIntegration = {
  __typename?: 'AggregateInstinctIntegration';
  _count?: Maybe<InstinctIntegrationCountAggregateOutputType>;
  _min?: Maybe<InstinctIntegrationMinAggregateOutputType>;
  _max?: Maybe<InstinctIntegrationMaxAggregateOutputType>;
};

export type AggregateInvoice = {
  __typename?: 'AggregateInvoice';
  _count?: Maybe<InvoiceCountAggregateOutputType>;
  _avg?: Maybe<InvoiceAvgAggregateOutputType>;
  _sum?: Maybe<InvoiceSumAggregateOutputType>;
  _min?: Maybe<InvoiceMinAggregateOutputType>;
  _max?: Maybe<InvoiceMaxAggregateOutputType>;
};

export type AggregateInvoiceLineItem = {
  __typename?: 'AggregateInvoiceLineItem';
  _count?: Maybe<InvoiceLineItemCountAggregateOutputType>;
  _avg?: Maybe<InvoiceLineItemAvgAggregateOutputType>;
  _sum?: Maybe<InvoiceLineItemSumAggregateOutputType>;
  _min?: Maybe<InvoiceLineItemMinAggregateOutputType>;
  _max?: Maybe<InvoiceLineItemMaxAggregateOutputType>;
};

export type AggregateInvoicePimsWritebackLogEntry = {
  __typename?: 'AggregateInvoicePimsWritebackLogEntry';
  _count?: Maybe<InvoicePimsWritebackLogEntryCountAggregateOutputType>;
  _min?: Maybe<InvoicePimsWritebackLogEntryMinAggregateOutputType>;
  _max?: Maybe<InvoicePimsWritebackLogEntryMaxAggregateOutputType>;
};

export type AggregateMassTextAlert = {
  __typename?: 'AggregateMassTextAlert';
  _count?: Maybe<MassTextAlertCountAggregateOutputType>;
  _min?: Maybe<MassTextAlertMinAggregateOutputType>;
  _max?: Maybe<MassTextAlertMaxAggregateOutputType>;
};

export type AggregateMassTextAlertEntry = {
  __typename?: 'AggregateMassTextAlertEntry';
  _count?: Maybe<MassTextAlertEntryCountAggregateOutputType>;
  _min?: Maybe<MassTextAlertEntryMinAggregateOutputType>;
  _max?: Maybe<MassTextAlertEntryMaxAggregateOutputType>;
};

export type AggregateMassTextAlertEntryAppointment = {
  __typename?: 'AggregateMassTextAlertEntryAppointment';
  _count?: Maybe<MassTextAlertEntryAppointmentCountAggregateOutputType>;
  _min?: Maybe<MassTextAlertEntryAppointmentMinAggregateOutputType>;
  _max?: Maybe<MassTextAlertEntryAppointmentMaxAggregateOutputType>;
};

export type AggregateMessageTemplate = {
  __typename?: 'AggregateMessageTemplate';
  _count?: Maybe<MessageTemplateCountAggregateOutputType>;
  _min?: Maybe<MessageTemplateMinAggregateOutputType>;
  _max?: Maybe<MessageTemplateMaxAggregateOutputType>;
};

export type AggregateNeoIntegration = {
  __typename?: 'AggregateNeoIntegration';
  _count?: Maybe<NeoIntegrationCountAggregateOutputType>;
  _min?: Maybe<NeoIntegrationMinAggregateOutputType>;
  _max?: Maybe<NeoIntegrationMaxAggregateOutputType>;
};

export type AggregateNotificationEventToMessage = {
  __typename?: 'AggregateNotificationEventToMessage';
  _count?: Maybe<NotificationEventToMessageCountAggregateOutputType>;
  _min?: Maybe<NotificationEventToMessageMinAggregateOutputType>;
  _max?: Maybe<NotificationEventToMessageMaxAggregateOutputType>;
};

export type AggregateOrganization = {
  __typename?: 'AggregateOrganization';
  _count?: Maybe<OrganizationCountAggregateOutputType>;
  _min?: Maybe<OrganizationMinAggregateOutputType>;
  _max?: Maybe<OrganizationMaxAggregateOutputType>;
};

export type AggregateOrganizationCareBenefitToClinicService = {
  __typename?: 'AggregateOrganizationCareBenefitToClinicService';
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCountAggregateOutputType>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceMinAggregateOutputType>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceMaxAggregateOutputType>;
};

export type AggregateOrganizationPet = {
  __typename?: 'AggregateOrganizationPet';
  _count?: Maybe<OrganizationPetCountAggregateOutputType>;
  _min?: Maybe<OrganizationPetMinAggregateOutputType>;
  _max?: Maybe<OrganizationPetMaxAggregateOutputType>;
};

export type AggregateOrganizationPetParent = {
  __typename?: 'AggregateOrganizationPetParent';
  _count?: Maybe<OrganizationPetParentCountAggregateOutputType>;
  _min?: Maybe<OrganizationPetParentMinAggregateOutputType>;
  _max?: Maybe<OrganizationPetParentMaxAggregateOutputType>;
};

export type AggregateOrganizationPetToOrganizationPetParent = {
  __typename?: 'AggregateOrganizationPetToOrganizationPetParent';
  _count?: Maybe<OrganizationPetToOrganizationPetParentCountAggregateOutputType>;
  _min?: Maybe<OrganizationPetToOrganizationPetParentMinAggregateOutputType>;
  _max?: Maybe<OrganizationPetToOrganizationPetParentMaxAggregateOutputType>;
};

export type AggregateOwnerInfo = {
  __typename?: 'AggregateOwnerInfo';
  _count?: Maybe<OwnerInfoCountAggregateOutputType>;
  _min?: Maybe<OwnerInfoMinAggregateOutputType>;
  _max?: Maybe<OwnerInfoMaxAggregateOutputType>;
};

export type AggregatePpcIntegration = {
  __typename?: 'AggregatePPCIntegration';
  _count?: Maybe<PpcIntegrationCountAggregateOutputType>;
  _min?: Maybe<PpcIntegrationMinAggregateOutputType>;
  _max?: Maybe<PpcIntegrationMaxAggregateOutputType>;
};

export type AggregatePaymentPimsWritebackLogEntry = {
  __typename?: 'AggregatePaymentPimsWritebackLogEntry';
  _count?: Maybe<PaymentPimsWritebackLogEntryCountAggregateOutputType>;
  _min?: Maybe<PaymentPimsWritebackLogEntryMinAggregateOutputType>;
  _max?: Maybe<PaymentPimsWritebackLogEntryMaxAggregateOutputType>;
};

export type AggregatePermission = {
  __typename?: 'AggregatePermission';
  _count?: Maybe<PermissionCountAggregateOutputType>;
  _min?: Maybe<PermissionMinAggregateOutputType>;
  _max?: Maybe<PermissionMaxAggregateOutputType>;
};

export type AggregatePet = {
  __typename?: 'AggregatePet';
  _count?: Maybe<PetCountAggregateOutputType>;
  _avg?: Maybe<PetAvgAggregateOutputType>;
  _sum?: Maybe<PetSumAggregateOutputType>;
  _min?: Maybe<PetMinAggregateOutputType>;
  _max?: Maybe<PetMaxAggregateOutputType>;
};

export type AggregatePetParentSetting = {
  __typename?: 'AggregatePetParentSetting';
  _count?: Maybe<PetParentSettingCountAggregateOutputType>;
  _min?: Maybe<PetParentSettingMinAggregateOutputType>;
  _max?: Maybe<PetParentSettingMaxAggregateOutputType>;
};

export type AggregatePetPortalSetting = {
  __typename?: 'AggregatePetPortalSetting';
  _count?: Maybe<PetPortalSettingCountAggregateOutputType>;
  _min?: Maybe<PetPortalSettingMinAggregateOutputType>;
  _max?: Maybe<PetPortalSettingMaxAggregateOutputType>;
};

export type AggregatePimsInvoice = {
  __typename?: 'AggregatePimsInvoice';
  _count?: Maybe<PimsInvoiceCountAggregateOutputType>;
  _min?: Maybe<PimsInvoiceMinAggregateOutputType>;
  _max?: Maybe<PimsInvoiceMaxAggregateOutputType>;
};

export type AggregatePimsInvoiceLineItem = {
  __typename?: 'AggregatePimsInvoiceLineItem';
  _count?: Maybe<PimsInvoiceLineItemCountAggregateOutputType>;
  _min?: Maybe<PimsInvoiceLineItemMinAggregateOutputType>;
  _max?: Maybe<PimsInvoiceLineItemMaxAggregateOutputType>;
};

export type AggregatePimsWritebackLogEntry = {
  __typename?: 'AggregatePimsWritebackLogEntry';
  _count?: Maybe<PimsWritebackLogEntryCountAggregateOutputType>;
  _min?: Maybe<PimsWritebackLogEntryMinAggregateOutputType>;
  _max?: Maybe<PimsWritebackLogEntryMaxAggregateOutputType>;
};

export type AggregatePrescription = {
  __typename?: 'AggregatePrescription';
  _count?: Maybe<PrescriptionCountAggregateOutputType>;
  _avg?: Maybe<PrescriptionAvgAggregateOutputType>;
  _sum?: Maybe<PrescriptionSumAggregateOutputType>;
  _min?: Maybe<PrescriptionMinAggregateOutputType>;
  _max?: Maybe<PrescriptionMaxAggregateOutputType>;
};

export type AggregatePromoCode = {
  __typename?: 'AggregatePromoCode';
  _count?: Maybe<PromoCodeCountAggregateOutputType>;
  _avg?: Maybe<PromoCodeAvgAggregateOutputType>;
  _sum?: Maybe<PromoCodeSumAggregateOutputType>;
  _min?: Maybe<PromoCodeMinAggregateOutputType>;
  _max?: Maybe<PromoCodeMaxAggregateOutputType>;
};

export type AggregateReview = {
  __typename?: 'AggregateReview';
  _count?: Maybe<ReviewCountAggregateOutputType>;
  _avg?: Maybe<ReviewAvgAggregateOutputType>;
  _sum?: Maybe<ReviewSumAggregateOutputType>;
  _min?: Maybe<ReviewMinAggregateOutputType>;
  _max?: Maybe<ReviewMaxAggregateOutputType>;
};

export type AggregateReviewTag = {
  __typename?: 'AggregateReviewTag';
  _count?: Maybe<ReviewTagCountAggregateOutputType>;
  _min?: Maybe<ReviewTagMinAggregateOutputType>;
  _max?: Maybe<ReviewTagMaxAggregateOutputType>;
};

export type AggregateRules = {
  __typename?: 'AggregateRules';
  _count?: Maybe<RulesCountAggregateOutputType>;
  _min?: Maybe<RulesMinAggregateOutputType>;
  _max?: Maybe<RulesMaxAggregateOutputType>;
};

export type AggregateService = {
  __typename?: 'AggregateService';
  _count?: Maybe<ServiceCountAggregateOutputType>;
  _min?: Maybe<ServiceMinAggregateOutputType>;
  _max?: Maybe<ServiceMaxAggregateOutputType>;
};

export type AggregateServiceReminder = {
  __typename?: 'AggregateServiceReminder';
  _count?: Maybe<ServiceReminderCountAggregateOutputType>;
  _min?: Maybe<ServiceReminderMinAggregateOutputType>;
  _max?: Maybe<ServiceReminderMaxAggregateOutputType>;
};

export type AggregateServiceReminderNotification = {
  __typename?: 'AggregateServiceReminderNotification';
  _count?: Maybe<ServiceReminderNotificationCountAggregateOutputType>;
  _min?: Maybe<ServiceReminderNotificationMinAggregateOutputType>;
  _max?: Maybe<ServiceReminderNotificationMaxAggregateOutputType>;
};

export type AggregateServiceReminderTiming = {
  __typename?: 'AggregateServiceReminderTiming';
  _count?: Maybe<ServiceReminderTimingCountAggregateOutputType>;
  _avg?: Maybe<ServiceReminderTimingAvgAggregateOutputType>;
  _sum?: Maybe<ServiceReminderTimingSumAggregateOutputType>;
  _min?: Maybe<ServiceReminderTimingMinAggregateOutputType>;
  _max?: Maybe<ServiceReminderTimingMaxAggregateOutputType>;
};

export type AggregateSmsNotificationStatus = {
  __typename?: 'AggregateSmsNotificationStatus';
  _count?: Maybe<SmsNotificationStatusCountAggregateOutputType>;
  _min?: Maybe<SmsNotificationStatusMinAggregateOutputType>;
  _max?: Maybe<SmsNotificationStatusMaxAggregateOutputType>;
};

export type AggregateStaffRole = {
  __typename?: 'AggregateStaffRole';
  _count?: Maybe<StaffRoleCountAggregateOutputType>;
  _min?: Maybe<StaffRoleMinAggregateOutputType>;
  _max?: Maybe<StaffRoleMaxAggregateOutputType>;
};

export type AggregateStripeCustomer = {
  __typename?: 'AggregateStripeCustomer';
  _count?: Maybe<StripeCustomerCountAggregateOutputType>;
  _min?: Maybe<StripeCustomerMinAggregateOutputType>;
  _max?: Maybe<StripeCustomerMaxAggregateOutputType>;
};

export type AggregateStripeDispute = {
  __typename?: 'AggregateStripeDispute';
  _count?: Maybe<StripeDisputeCountAggregateOutputType>;
  _avg?: Maybe<StripeDisputeAvgAggregateOutputType>;
  _sum?: Maybe<StripeDisputeSumAggregateOutputType>;
  _min?: Maybe<StripeDisputeMinAggregateOutputType>;
  _max?: Maybe<StripeDisputeMaxAggregateOutputType>;
};

export type AggregateStripeInvoice = {
  __typename?: 'AggregateStripeInvoice';
  _count?: Maybe<StripeInvoiceCountAggregateOutputType>;
  _avg?: Maybe<StripeInvoiceAvgAggregateOutputType>;
  _sum?: Maybe<StripeInvoiceSumAggregateOutputType>;
  _min?: Maybe<StripeInvoiceMinAggregateOutputType>;
  _max?: Maybe<StripeInvoiceMaxAggregateOutputType>;
};

export type AggregateStripeInvoiceItem = {
  __typename?: 'AggregateStripeInvoiceItem';
  _count?: Maybe<StripeInvoiceItemCountAggregateOutputType>;
  _avg?: Maybe<StripeInvoiceItemAvgAggregateOutputType>;
  _sum?: Maybe<StripeInvoiceItemSumAggregateOutputType>;
  _min?: Maybe<StripeInvoiceItemMinAggregateOutputType>;
  _max?: Maybe<StripeInvoiceItemMaxAggregateOutputType>;
};

export type AggregateStripePaymentIntent = {
  __typename?: 'AggregateStripePaymentIntent';
  _count?: Maybe<StripePaymentIntentCountAggregateOutputType>;
  _avg?: Maybe<StripePaymentIntentAvgAggregateOutputType>;
  _sum?: Maybe<StripePaymentIntentSumAggregateOutputType>;
  _min?: Maybe<StripePaymentIntentMinAggregateOutputType>;
  _max?: Maybe<StripePaymentIntentMaxAggregateOutputType>;
};

export type AggregateStripePaymentMethod = {
  __typename?: 'AggregateStripePaymentMethod';
  _count?: Maybe<StripePaymentMethodCountAggregateOutputType>;
  _avg?: Maybe<StripePaymentMethodAvgAggregateOutputType>;
  _sum?: Maybe<StripePaymentMethodSumAggregateOutputType>;
  _min?: Maybe<StripePaymentMethodMinAggregateOutputType>;
  _max?: Maybe<StripePaymentMethodMaxAggregateOutputType>;
};

export type AggregateStripePayout = {
  __typename?: 'AggregateStripePayout';
  _count?: Maybe<StripePayoutCountAggregateOutputType>;
  _avg?: Maybe<StripePayoutAvgAggregateOutputType>;
  _sum?: Maybe<StripePayoutSumAggregateOutputType>;
  _min?: Maybe<StripePayoutMinAggregateOutputType>;
  _max?: Maybe<StripePayoutMaxAggregateOutputType>;
};

export type AggregateStripePayoutAccount = {
  __typename?: 'AggregateStripePayoutAccount';
  _count?: Maybe<StripePayoutAccountCountAggregateOutputType>;
  _min?: Maybe<StripePayoutAccountMinAggregateOutputType>;
  _max?: Maybe<StripePayoutAccountMaxAggregateOutputType>;
};

export type AggregateStripeReceipt = {
  __typename?: 'AggregateStripeReceipt';
  _count?: Maybe<StripeReceiptCountAggregateOutputType>;
  _min?: Maybe<StripeReceiptMinAggregateOutputType>;
  _max?: Maybe<StripeReceiptMaxAggregateOutputType>;
};

export type AggregateStripeReceiptHistory = {
  __typename?: 'AggregateStripeReceiptHistory';
  _count?: Maybe<StripeReceiptHistoryCountAggregateOutputType>;
  _min?: Maybe<StripeReceiptHistoryMinAggregateOutputType>;
  _max?: Maybe<StripeReceiptHistoryMaxAggregateOutputType>;
};

export type AggregateStripeRefund = {
  __typename?: 'AggregateStripeRefund';
  _count?: Maybe<StripeRefundCountAggregateOutputType>;
  _avg?: Maybe<StripeRefundAvgAggregateOutputType>;
  _sum?: Maybe<StripeRefundSumAggregateOutputType>;
  _min?: Maybe<StripeRefundMinAggregateOutputType>;
  _max?: Maybe<StripeRefundMaxAggregateOutputType>;
};

export type AggregateStripeTerminal = {
  __typename?: 'AggregateStripeTerminal';
  _count?: Maybe<StripeTerminalCountAggregateOutputType>;
  _min?: Maybe<StripeTerminalMinAggregateOutputType>;
  _max?: Maybe<StripeTerminalMaxAggregateOutputType>;
};

export type AggregateStripeTerminalLocation = {
  __typename?: 'AggregateStripeTerminalLocation';
  _count?: Maybe<StripeTerminalLocationCountAggregateOutputType>;
  _min?: Maybe<StripeTerminalLocationMinAggregateOutputType>;
  _max?: Maybe<StripeTerminalLocationMaxAggregateOutputType>;
};

export type AggregateTinyUrl = {
  __typename?: 'AggregateTinyUrl';
  _count?: Maybe<TinyUrlCountAggregateOutputType>;
  _min?: Maybe<TinyUrlMinAggregateOutputType>;
  _max?: Maybe<TinyUrlMaxAggregateOutputType>;
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  _count?: Maybe<TransactionCountAggregateOutputType>;
  _avg?: Maybe<TransactionAvgAggregateOutputType>;
  _sum?: Maybe<TransactionSumAggregateOutputType>;
  _min?: Maybe<TransactionMinAggregateOutputType>;
  _max?: Maybe<TransactionMaxAggregateOutputType>;
};

export type AggregateTwilioCallRecording = {
  __typename?: 'AggregateTwilioCallRecording';
  _count?: Maybe<TwilioCallRecordingCountAggregateOutputType>;
  _avg?: Maybe<TwilioCallRecordingAvgAggregateOutputType>;
  _sum?: Maybe<TwilioCallRecordingSumAggregateOutputType>;
  _min?: Maybe<TwilioCallRecordingMinAggregateOutputType>;
  _max?: Maybe<TwilioCallRecordingMaxAggregateOutputType>;
};

export type AggregateTwilioConfiguration = {
  __typename?: 'AggregateTwilioConfiguration';
  _count?: Maybe<TwilioConfigurationCountAggregateOutputType>;
  _min?: Maybe<TwilioConfigurationMinAggregateOutputType>;
  _max?: Maybe<TwilioConfigurationMaxAggregateOutputType>;
};

export type AggregateTwilioVerification = {
  __typename?: 'AggregateTwilioVerification';
  _count?: Maybe<TwilioVerificationCountAggregateOutputType>;
  _min?: Maybe<TwilioVerificationMinAggregateOutputType>;
  _max?: Maybe<TwilioVerificationMaxAggregateOutputType>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregateOutputType>;
  _min?: Maybe<UserMinAggregateOutputType>;
  _max?: Maybe<UserMaxAggregateOutputType>;
};

export type AggregateUserAppointmentGrouping = {
  __typename?: 'AggregateUserAppointmentGrouping';
  _count?: Maybe<UserAppointmentGroupingCountAggregateOutputType>;
  _min?: Maybe<UserAppointmentGroupingMinAggregateOutputType>;
  _max?: Maybe<UserAppointmentGroupingMaxAggregateOutputType>;
};

export type AggregateUserChannelFilterSelection = {
  __typename?: 'AggregateUserChannelFilterSelection';
  _count?: Maybe<UserChannelFilterSelectionCountAggregateOutputType>;
  _min?: Maybe<UserChannelFilterSelectionMinAggregateOutputType>;
  _max?: Maybe<UserChannelFilterSelectionMaxAggregateOutputType>;
};

export type AggregateUserImpersonation = {
  __typename?: 'AggregateUserImpersonation';
  _count?: Maybe<UserImpersonationCountAggregateOutputType>;
  _min?: Maybe<UserImpersonationMinAggregateOutputType>;
  _max?: Maybe<UserImpersonationMaxAggregateOutputType>;
};

export type AggregateUserNotificationSetting = {
  __typename?: 'AggregateUserNotificationSetting';
  _count?: Maybe<UserNotificationSettingCountAggregateOutputType>;
  _min?: Maybe<UserNotificationSettingMinAggregateOutputType>;
  _max?: Maybe<UserNotificationSettingMaxAggregateOutputType>;
};

export type AggregateUserSetting = {
  __typename?: 'AggregateUserSetting';
  _count?: Maybe<UserSettingCountAggregateOutputType>;
  _min?: Maybe<UserSettingMinAggregateOutputType>;
  _max?: Maybe<UserSettingMaxAggregateOutputType>;
};

export type AggregateVaccination = {
  __typename?: 'AggregateVaccination';
  _count?: Maybe<VaccinationCountAggregateOutputType>;
  _min?: Maybe<VaccinationMinAggregateOutputType>;
  _max?: Maybe<VaccinationMaxAggregateOutputType>;
};

export type AggregateVetAvailability = {
  __typename?: 'AggregateVetAvailability';
  _count?: Maybe<VetAvailabilityCountAggregateOutputType>;
  _min?: Maybe<VetAvailabilityMinAggregateOutputType>;
  _max?: Maybe<VetAvailabilityMaxAggregateOutputType>;
};

export type AggregateVetAvailabilityMap = {
  __typename?: 'AggregateVetAvailabilityMap';
  _count?: Maybe<VetAvailabilityMapCountAggregateOutputType>;
  _min?: Maybe<VetAvailabilityMapMinAggregateOutputType>;
  _max?: Maybe<VetAvailabilityMapMaxAggregateOutputType>;
};

export type AggregateVetDataIntegration = {
  __typename?: 'AggregateVetDataIntegration';
  _count?: Maybe<VetDataIntegrationCountAggregateOutputType>;
  _min?: Maybe<VetDataIntegrationMinAggregateOutputType>;
  _max?: Maybe<VetDataIntegrationMaxAggregateOutputType>;
};

export type AggregateVetInfo = {
  __typename?: 'AggregateVetInfo';
  _count?: Maybe<VetInfoCountAggregateOutputType>;
  _min?: Maybe<VetInfoMinAggregateOutputType>;
  _max?: Maybe<VetInfoMaxAggregateOutputType>;
};

export type AggregateVetLicense = {
  __typename?: 'AggregateVetLicense';
  _count?: Maybe<VetLicenseCountAggregateOutputType>;
  _min?: Maybe<VetLicenseMinAggregateOutputType>;
  _max?: Maybe<VetLicenseMaxAggregateOutputType>;
};

export type AggregateVetterIntegration = {
  __typename?: 'AggregateVetterIntegration';
  _count?: Maybe<VetterIntegrationCountAggregateOutputType>;
  _min?: Maybe<VetterIntegrationMinAggregateOutputType>;
  _max?: Maybe<VetterIntegrationMaxAggregateOutputType>;
};

export type AggregateWorkflowEventAction = {
  __typename?: 'AggregateWorkflowEventAction';
  _count?: Maybe<WorkflowEventActionCountAggregateOutputType>;
  _avg?: Maybe<WorkflowEventActionAvgAggregateOutputType>;
  _sum?: Maybe<WorkflowEventActionSumAggregateOutputType>;
  _min?: Maybe<WorkflowEventActionMinAggregateOutputType>;
  _max?: Maybe<WorkflowEventActionMaxAggregateOutputType>;
};

export type AggregateWorkflowEventSetting = {
  __typename?: 'AggregateWorkflowEventSetting';
  _count?: Maybe<WorkflowEventSettingCountAggregateOutputType>;
  _avg?: Maybe<WorkflowEventSettingAvgAggregateOutputType>;
  _sum?: Maybe<WorkflowEventSettingSumAggregateOutputType>;
  _min?: Maybe<WorkflowEventSettingMinAggregateOutputType>;
  _max?: Maybe<WorkflowEventSettingMaxAggregateOutputType>;
};

export type AggregateWorkflowTrigger = {
  __typename?: 'AggregateWorkflowTrigger';
  _count?: Maybe<WorkflowTriggerCountAggregateOutputType>;
  _min?: Maybe<WorkflowTriggerMinAggregateOutputType>;
  _max?: Maybe<WorkflowTriggerMaxAggregateOutputType>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyCountAggregateOutputType = {
  __typename?: 'ApiKeyCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  integrationType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ApiKeyCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
};

export type ApiKeyCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyMaxAggregateOutputType = {
  __typename?: 'ApiKeyMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
};

export type ApiKeyMinAggregateOutputType = {
  __typename?: 'ApiKeyMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
};

export enum ApiKeyOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
}

export type ApiKeyOrderByRelevanceInput = {
  fields: Array<ApiKeyOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ApiKeyOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  _count?: Maybe<ApiKeyCountOrderByAggregateInput>;
  _max?: Maybe<ApiKeyMaxOrderByAggregateInput>;
  _min?: Maybe<ApiKeyMinOrderByAggregateInput>;
};

export type ApiKeyOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  _relevance?: Maybe<ApiKeyOrderByRelevanceInput>;
};

export enum ApiKeyScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  IntegrationType = 'integrationType',
}

export type ApiKeyScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  integrationType?: Maybe<EnumIntegrationTypeNullableWithAggregatesFilter>;
};

export type ApiKeyUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
};

export type ApiKeyWhereInput = {
  AND?: Maybe<Array<ApiKeyWhereInput>>;
  OR?: Maybe<Array<ApiKeyWhereInput>>;
  NOT?: Maybe<Array<ApiKeyWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  integrationType?: Maybe<EnumIntegrationTypeNullableFilter>;
};

export type ApiKeyWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status: AppointmentStatus;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentType>;
  channel?: Maybe<Channel>;
  clinic?: Maybe<Clinic>;
  clinicPet?: Maybe<ClinicPet>;
  room?: Maybe<ClinicRoom>;
  /** Deprecated: use pimsExportConsultationId for Conversation Exports */
  consultation?: Maybe<Consultation>;
  /** Replacement for consultation_id for Conversation Exports */
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegration>;
  formSubmissions: Array<FormSubmission>;
  clinicEmployees: Array<ClinicEmployee>;
  clinicPetParents: Array<ClinicPetParent>;
  workflows: Array<WorkflowEventSetting>;
  workflowTriggers: Array<WorkflowTrigger>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  automationRuns: Array<AutomationRun>;
  massTextAlertEntryAppointments: Array<MassTextAlertEntryAppointment>;
  _count: AppointmentCountOutputType;
};

export type AppointmentFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};

export type AppointmentClinicEmployeesArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};

export type AppointmentClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type AppointmentWorkflowsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};

export type AppointmentWorkflowTriggersArgs = {
  where?: Maybe<WorkflowTriggerWhereInput>;
  orderBy?: Maybe<WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowTriggerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowTriggerScalarFieldEnum>;
};

export type AppointmentChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};

export type AppointmentAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type AppointmentMassTextAlertEntryAppointmentsArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>;
};

export type AppointmentAvgAggregateOutputType = {
  __typename?: 'AppointmentAvgAggregateOutputType';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

export type AppointmentAvgOrderByAggregateInput = {
  durationInMinutes?: Maybe<SortOrder>;
};

export type AppointmentCountAggregateOutputType = {
  __typename?: 'AppointmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  description: Scalars['Int'];
  durationInMinutes: Scalars['Int'];
  pimsId: Scalars['Int'];
  startAt: Scalars['Int'];
  consultationId: Scalars['Int'];
  integrationId: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  title: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicId: Scalars['Int'];
  roomId: Scalars['Int'];
  status: Scalars['Int'];
  pimsStatus: Scalars['Int'];
  workflow: Scalars['Int'];
  channelId: Scalars['Int'];
  hash: Scalars['Int'];
  pimsExportConsultationId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AppointmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type AppointmentCountOutputType = {
  __typename?: 'AppointmentCountOutputType';
  formSubmissions: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  workflows: Scalars['Int'];
  workflowTriggers: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  automationRuns: Scalars['Int'];
  massTextAlertEntryAppointments: Scalars['Int'];
};

export type AppointmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyAppointmentTypeInputEnvelope = {
  data: Array<AppointmentCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyChannelInputEnvelope = {
  data: Array<AppointmentCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyClinicInputEnvelope = {
  data: Array<AppointmentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyClinicPetInputEnvelope = {
  data: Array<AppointmentCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyConsultationInputEnvelope = {
  data: Array<AppointmentCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyIntegrationInputEnvelope = {
  data: Array<AppointmentCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentCreateManyRoomInputEnvelope = {
  data: Array<AppointmentCreateManyRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicPetParentsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutRoomInput = {
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateNestedOneWithoutWorkflowTriggersInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutWorkflowTriggersInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutWorkflowTriggersInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
};

export type AppointmentCreateOrConnectWithoutAppointmentTypeInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutAppointmentTypeInput;
};

export type AppointmentCreateOrConnectWithoutAutomationRunsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutAutomationRunsInput;
};

export type AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type AppointmentCreateOrConnectWithoutChannelInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutChannelInput;
};

export type AppointmentCreateOrConnectWithoutClinicEmployeesInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutClinicEmployeesInput;
};

export type AppointmentCreateOrConnectWithoutClinicInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutClinicInput;
};

export type AppointmentCreateOrConnectWithoutClinicPetInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutClinicPetInput;
};

export type AppointmentCreateOrConnectWithoutClinicPetParentsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutClinicPetParentsInput;
};

export type AppointmentCreateOrConnectWithoutConsultationInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutConsultationInput;
};

export type AppointmentCreateOrConnectWithoutFormSubmissionsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutFormSubmissionsInput;
};

export type AppointmentCreateOrConnectWithoutIntegrationInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput;
};

export type AppointmentCreateOrConnectWithoutRoomInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutRoomInput;
};

export type AppointmentCreateOrConnectWithoutWorkflowTriggersInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutWorkflowTriggersInput;
};

export type AppointmentCreateOrConnectWithoutWorkflowsInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentUncheckedCreateWithoutWorkflowsInput;
};

export type AppointmentCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
};

export enum AppointmentDateRangeType {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  ThisWeek = 'This_Week',
  ThisMonth = 'This_Month',
  NextWeek = 'Next_Week',
  Custom = 'Custom',
}

export type AppointmentListRelationFilter = {
  every?: Maybe<AppointmentWhereInput>;
  some?: Maybe<AppointmentWhereInput>;
  none?: Maybe<AppointmentWhereInput>;
};

export type AppointmentMaxAggregateOutputType = {
  __typename?: 'AppointmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
};

export type AppointmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
};

export type AppointmentMinAggregateOutputType = {
  __typename?: 'AppointmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
};

export type AppointmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
};

export type AppointmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AppointmentOrderByRelevanceFieldEnum {
  Id = 'id',
  Description = 'description',
  PimsId = 'pimsId',
  ConsultationId = 'consultationId',
  IntegrationId = 'integrationId',
  AppointmentTypeId = 'appointmentTypeId',
  Title = 'title',
  ClinicPetId = 'clinicPetId',
  ClinicId = 'clinicId',
  RoomId = 'roomId',
  PimsStatus = 'pimsStatus',
  ChannelId = 'channelId',
  Hash = 'hash',
  PimsExportConsultationId = 'pimsExportConsultationId',
}

export type AppointmentOrderByRelevanceInput = {
  fields: Array<AppointmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AppointmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<AppointmentCountOrderByAggregateInput>;
  _avg?: Maybe<AppointmentAvgOrderByAggregateInput>;
  _max?: Maybe<AppointmentMaxOrderByAggregateInput>;
  _min?: Maybe<AppointmentMinOrderByAggregateInput>;
  _sum?: Maybe<AppointmentSumOrderByAggregateInput>;
};

export type AppointmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  room?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  workflowTriggers?: Maybe<WorkflowTriggerOrderByRelationAggregateInput>;
  rawPimsValue?: Maybe<SortOrder>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentOrderByRelationAggregateInput>;
  _relevance?: Maybe<AppointmentOrderByRelevanceInput>;
};

export type AppointmentPimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type AppointmentRelationFilter = {
  is?: Maybe<AppointmentWhereInput>;
  isNot?: Maybe<AppointmentWhereInput>;
};

export enum AppointmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Description = 'description',
  DurationInMinutes = 'durationInMinutes',
  PimsId = 'pimsId',
  StartAt = 'startAt',
  ConsultationId = 'consultationId',
  IntegrationId = 'integrationId',
  AppointmentTypeId = 'appointmentTypeId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Title = 'title',
  ClinicPetId = 'clinicPetId',
  ClinicId = 'clinicId',
  RoomId = 'roomId',
  Status = 'status',
  PimsStatus = 'pimsStatus',
  Workflow = 'workflow',
  ChannelId = 'channelId',
  Hash = 'hash',
  PimsExportConsultationId = 'pimsExportConsultationId',
  RawPimsValue = 'rawPimsValue',
}

export type AppointmentScalarWhereInput = {
  AND?: Maybe<Array<AppointmentScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  durationInMinutes?: Maybe<IntFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  startAt?: Maybe<DateTimeFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  appointmentTypeId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  title?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  roomId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumAppointmentStatusFilter>;
  pimsStatus?: Maybe<StringNullableFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  hash?: Maybe<StringNullableFilter>;
  pimsExportConsultationId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type AppointmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  durationInMinutes?: Maybe<IntWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  startAt?: Maybe<DateTimeWithAggregatesFilter>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  title?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  roomId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumAppointmentStatusWithAggregatesFilter>;
  pimsStatus?: Maybe<StringNullableWithAggregatesFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  hash?: Maybe<StringNullableWithAggregatesFilter>;
  pimsExportConsultationId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum AppointmentStatus {
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  ArrivalConfirmed = 'ArrivalConfirmed',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Reschedule = 'Reschedule',
}

export type AppointmentSumAggregateOutputType = {
  __typename?: 'AppointmentSumAggregateOutputType';
  durationInMinutes?: Maybe<Scalars['Int']>;
};

export type AppointmentSumOrderByAggregateInput = {
  durationInMinutes?: Maybe<SortOrder>;
};

export type AppointmentType = {
  __typename?: 'AppointmentType';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking: Scalars['Boolean'];
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  integration?: Maybe<ClinicPimsIntegration>;
  appointments: Array<Appointment>;
  clinicWorkflowTypes: Array<ClinicWorkflowType>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  workflows: Array<WorkflowEventSetting>;
  appointmentTypeVisibility: AppointmentTypeVisibility;
  isVisible: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal: Scalars['Boolean'];
  clinicEmployeeAppointmentTypeSettings: Array<ClinicEmployeeAppointmentTypeSetting>;
  _count: AppointmentTypeCountOutputType;
};

export type AppointmentTypeAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type AppointmentTypeClinicWorkflowTypesArgs = {
  where?: Maybe<ClinicWorkflowTypeWhereInput>;
  orderBy?: Maybe<ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWorkflowTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWorkflowTypeScalarFieldEnum>;
};

export type AppointmentTypeUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};

export type AppointmentTypeWorkflowsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};

export type AppointmentTypeClinicEmployeeAppointmentTypeSettingsArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>;
};

export type AppointmentTypeAppointmentTypePimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type AppointmentTypeAvgAggregateOutputType = {
  __typename?: 'AppointmentTypeAvgAggregateOutputType';
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Float']>;
  defaultDurationInMinutes?: Maybe<Scalars['Float']>;
};

export type AppointmentTypeAvgOrderByAggregateInput = {
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
};

export type AppointmentTypeClinicEmployee = {
  __typename?: 'AppointmentTypeClinicEmployee';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AppointmentTypeClinicEmployeeMapping = {
  __typename?: 'AppointmentTypeClinicEmployeeMapping';
  clinicEmployee: AppointmentTypeClinicEmployee;
};

export type AppointmentTypeCountAggregateOutputType = {
  __typename?: 'AppointmentTypeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  displayName: Scalars['Int'];
  description: Scalars['Int'];
  isAvailableForDirectBooking: Scalars['Int'];
  defaultSoonestAvailableBookingInHours: Scalars['Int'];
  defaultDurationInMinutes: Scalars['Int'];
  pimsId: Scalars['Int'];
  integrationId: Scalars['Int'];
  clinicId: Scalars['Int'];
  appointmentTypeVisibility: Scalars['Int'];
  isVisible: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  showInPetPortal: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AppointmentTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
};

export type AppointmentTypeCountOutputType = {
  __typename?: 'AppointmentTypeCountOutputType';
  appointments: Scalars['Int'];
  clinicWorkflowTypes: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  workflows: Scalars['Int'];
  clinicEmployeeAppointmentTypeSettings: Scalars['Int'];
};

export type AppointmentTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateManyClinicInputEnvelope = {
  data: Array<AppointmentTypeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateManyIntegrationInputEnvelope = {
  data: Array<AppointmentTypeCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
};

export type AppointmentTypeCreateOrConnectWithoutAppointmentsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutAppointmentsInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput;
};

export type AppointmentTypeCreateOrConnectWithoutIntegrationInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type AppointmentTypeCreateOrConnectWithoutWorkflowsInput = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeUncheckedCreateWithoutWorkflowsInput;
};

export type AppointmentTypeCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeCreateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeListRelationFilter = {
  every?: Maybe<AppointmentTypeWhereInput>;
  some?: Maybe<AppointmentTypeWhereInput>;
  none?: Maybe<AppointmentTypeWhereInput>;
};

export type AppointmentTypeMaxAggregateOutputType = {
  __typename?: 'AppointmentTypeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
};

export type AppointmentTypeMinAggregateOutputType = {
  __typename?: 'AppointmentTypeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
};

export type AppointmentTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AppointmentTypeOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  DisplayName = 'displayName',
  Description = 'description',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  ClinicId = 'clinicId',
}

export type AppointmentTypeOrderByRelevanceInput = {
  fields: Array<AppointmentTypeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AppointmentTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  _count?: Maybe<AppointmentTypeCountOrderByAggregateInput>;
  _avg?: Maybe<AppointmentTypeAvgOrderByAggregateInput>;
  _max?: Maybe<AppointmentTypeMaxOrderByAggregateInput>;
  _min?: Maybe<AppointmentTypeMinOrderByAggregateInput>;
  _sum?: Maybe<AppointmentTypeSumOrderByAggregateInput>;
};

export type AppointmentTypeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput>;
  _relevance?: Maybe<AppointmentTypeOrderByRelevanceInput>;
};

export type AppointmentTypeRelationFilter = {
  is?: Maybe<AppointmentTypeWhereInput>;
  isNot?: Maybe<AppointmentTypeWhereInput>;
};

export enum AppointmentTypeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  DisplayName = 'displayName',
  Description = 'description',
  IsAvailableForDirectBooking = 'isAvailableForDirectBooking',
  DefaultSoonestAvailableBookingInHours = 'defaultSoonestAvailableBookingInHours',
  DefaultDurationInMinutes = 'defaultDurationInMinutes',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  ClinicId = 'clinicId',
  AppointmentTypeVisibility = 'appointmentTypeVisibility',
  IsVisible = 'isVisible',
  RawPimsValue = 'rawPimsValue',
  ShowInPetPortal = 'showInPetPortal',
}

export type AppointmentTypeScalarWhereInput = {
  AND?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  isAvailableForDirectBooking?: Maybe<BoolFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableFilter>;
  defaultDurationInMinutes?: Maybe<IntFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityFilter>;
  isVisible?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  showInPetPortal?: Maybe<BoolFilter>;
};

export type AppointmentTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  isAvailableForDirectBooking?: Maybe<BoolWithAggregatesFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableWithAggregatesFilter>;
  defaultDurationInMinutes?: Maybe<IntWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityWithAggregatesFilter>;
  isVisible?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  showInPetPortal?: Maybe<BoolWithAggregatesFilter>;
};

export type AppointmentTypeSumAggregateOutputType = {
  __typename?: 'AppointmentTypeSumAggregateOutputType';
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeSumOrderByAggregateInput = {
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
};

export type AppointmentTypeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
};

export type AppointmentTypeUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeUpdateManyWithWhereWithoutClinicInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppoinmentTypesInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput = {
  where: AppointmentTypeScalarWhereInput;
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
};

export type AppointmentTypeUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
};

export type AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type AppointmentTypeUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutAppointmentsInput>;
};

export type AppointmentTypeUpdateOneWithoutClinicWorkflowTypesInput = {
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutClinicWorkflowTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput>;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutClinicInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutIntegrationInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput = {
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUncheckedUpdateWithoutWorkflowsInput;
};

export type AppointmentTypeUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUpdateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeInput
  >;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutClinicInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutIntegrationInput;
  create: AppointmentTypeUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput = {
  where: AppointmentTypeWhereUniqueInput;
  update: AppointmentTypeUncheckedUpdateWithoutWorkflowsInput;
  create: AppointmentTypeUncheckedCreateWithoutWorkflowsInput;
};

export type AppointmentTypeUpsertWithoutAppointmentsInput = {
  update: AppointmentTypeUncheckedUpdateWithoutAppointmentsInput;
  create: AppointmentTypeUncheckedCreateWithoutAppointmentsInput;
};

export type AppointmentTypeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  update: AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type AppointmentTypeUpsertWithoutClinicWorkflowTypesInput = {
  update: AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput;
  create: AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput;
};

export enum AppointmentTypeVisibility {
  ShowAll = 'ShowAll',
  HideFromCalendarOnly = 'HideFromCalendarOnly',
  HideFromDropDownOnly = 'HideFromDropDownOnly',
  HideAll = 'HideAll',
}

export type AppointmentTypeWhereInput = {
  AND?: Maybe<Array<AppointmentTypeWhereInput>>;
  OR?: Maybe<Array<AppointmentTypeWhereInput>>;
  NOT?: Maybe<Array<AppointmentTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  displayName?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  isAvailableForDirectBooking?: Maybe<BoolFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableFilter>;
  defaultDurationInMinutes?: Maybe<IntFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityFilter>;
  isVisible?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  showInPetPortal?: Maybe<BoolFilter>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingListRelationFilter>;
};

export type AppointmentTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  appointmentTypePimsIdAndIntegrationId?: Maybe<
    AppointmentTypeAppointmentTypePimsIdAndIntegrationIdCompoundUniqueInput
  >;
};

export type AppointmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutRoomInput = {
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  pimsId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentUncheckedUpdateManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentUncheckedUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutConsultationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutRoomInput = {
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutRoomInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  channelId?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutChannelInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicPetInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutConsultationInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutIntegrationInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutRoomInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithWhereWithoutWorkflowsInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
};

export type AppointmentUpdateManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutClinicPetParentsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutConsultationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutRoomInput = {
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutRoomInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateManyWithoutWorkflowsInput = {
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutMassTextAlertEntryAppointmentsInput>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput>;
};

export type AppointmentUpdateOneWithoutAutomationRunsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutAutomationRunsInput>;
};

export type AppointmentUpdateOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<AppointmentUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type AppointmentUpdateOneWithoutFormSubmissionsInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type AppointmentUpdateOneWithoutWorkflowTriggersInput = {
  create?: Maybe<AppointmentUncheckedCreateWithoutWorkflowTriggersInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutWorkflowTriggersInput>;
  upsert?: Maybe<AppointmentUpsertWithoutWorkflowTriggersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentWhereUniqueInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutWorkflowTriggersInput>;
};

export type AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutAppointmentTypeInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutChannelInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutChannelInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutClinicEmployeesInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutClinicInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutClinicPetInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutClinicPetParentsInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutConsultationInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutConsultationInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutIntegrationInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutRoomInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutRoomInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUncheckedUpdateWithoutWorkflowsInput;
};

export type AppointmentUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutRoomInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  status?: Maybe<AppointmentStatus>;
  pimsStatus?: Maybe<Scalars['String']>;
  workflow?: Maybe<WorkflowType>;
  hash?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsInput>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentInput>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput>;
};

export type AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutAppointmentTypeInput;
  create: AppointmentUncheckedCreateWithoutAppointmentTypeInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutChannelInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutChannelInput;
  create: AppointmentUncheckedCreateWithoutChannelInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutClinicEmployeesInput;
  create: AppointmentUncheckedCreateWithoutClinicEmployeesInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutClinicInput;
  create: AppointmentUncheckedCreateWithoutClinicInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutClinicPetInput;
  create: AppointmentUncheckedCreateWithoutClinicPetInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutClinicPetParentsInput;
  create: AppointmentUncheckedCreateWithoutClinicPetParentsInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutConsultationInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutConsultationInput;
  create: AppointmentUncheckedCreateWithoutConsultationInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutIntegrationInput;
  create: AppointmentUncheckedCreateWithoutIntegrationInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutRoomInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutRoomInput;
  create: AppointmentUncheckedCreateWithoutRoomInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUncheckedUpdateWithoutWorkflowsInput;
  create: AppointmentUncheckedCreateWithoutWorkflowsInput;
};

export type AppointmentUpsertWithoutAutomationRunsInput = {
  update: AppointmentUncheckedUpdateWithoutAutomationRunsInput;
  create: AppointmentUncheckedCreateWithoutAutomationRunsInput;
};

export type AppointmentUpsertWithoutChannelAutomationStatusesInput = {
  update: AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type AppointmentUpsertWithoutFormSubmissionsInput = {
  update: AppointmentUncheckedUpdateWithoutFormSubmissionsInput;
  create: AppointmentUncheckedCreateWithoutFormSubmissionsInput;
};

export type AppointmentUpsertWithoutMassTextAlertEntryAppointmentsInput = {
  update: AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput;
  create: AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput;
};

export type AppointmentUpsertWithoutWorkflowTriggersInput = {
  update: AppointmentUncheckedUpdateWithoutWorkflowTriggersInput;
  create: AppointmentUncheckedCreateWithoutWorkflowTriggersInput;
};

export type AppointmentWhereInput = {
  AND?: Maybe<Array<AppointmentWhereInput>>;
  OR?: Maybe<Array<AppointmentWhereInput>>;
  NOT?: Maybe<Array<AppointmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  durationInMinutes?: Maybe<IntFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  startAt?: Maybe<DateTimeFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  appointmentTypeId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  title?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  roomId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumAppointmentStatusFilter>;
  pimsStatus?: Maybe<StringNullableFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  hash?: Maybe<StringNullableFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  channel?: Maybe<ChannelWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  room?: Maybe<ClinicRoomWhereInput>;
  consultation?: Maybe<ConsultationWhereInput>;
  pimsExportConsultationId?: Maybe<StringNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
  workflowTriggers?: Maybe<WorkflowTriggerListRelationFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentListRelationFilter>;
};

export type AppointmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<AppointmentPimsIdIntegrationIdCompoundUniqueInput>;
};

export type AuthLinkEmailMatch = {
  __typename?: 'AuthLinkEmailMatch';
  id: Scalars['String'];
  redacted: Scalars['String'];
};

export type AuthLinkRequestInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  queryParams?: Maybe<Scalars['Json']>;
};

export type AuthLinkRequestResult = {
  __typename?: 'AuthLinkRequestResult';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<AuthLinkEmailMatch>>>;
};

export enum AutomatedTriggerType {
  Before = 'Before',
  After = 'After',
}

export type AutomationRun = {
  __typename?: 'AutomationRun';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType: AutomationRunType;
  manuallySentById?: Maybe<Scalars['String']>;
  status: AutomationRunStatus;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  channelMessage: ChannelMessage;
  clinic: Clinic;
  appointment?: Maybe<Appointment>;
  formSubmission?: Maybe<FormSubmission>;
  serviceReminder?: Maybe<ServiceReminder>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  actions: Array<AutomationRunAction>;
  originAutomation: WorkflowEventSetting;
  manuallySentBy?: Maybe<User>;
  _count: AutomationRunCountOutputType;
};

export type AutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};

export type AutomationRunAction = {
  __typename?: 'AutomationRunAction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments: Scalars['Json'];
  promptType: AutomationRunActionPromptType;
  actionType: AutomationRunActionType;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons: Scalars['Json'];
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  automationRun: AutomationRun;
  petParent?: Maybe<ClinicPetParent>;
  pet?: Maybe<ClinicPet>;
  automationAction?: Maybe<WorkflowEventAction>;
  channelStatusChange?: Maybe<ChannelStatus>;
  formSubmission?: Maybe<FormSubmission>;
  paymentIntent?: Maybe<StripePaymentIntent>;
};

export type AutomationRunActionAvgAggregateOutputType = {
  __typename?: 'AutomationRunActionAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type AutomationRunActionAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionCountAggregateOutputType = {
  __typename?: 'AutomationRunActionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  automationRunId: Scalars['Int'];
  automationActionId: Scalars['Int'];
  body: Scalars['Int'];
  attachments: Scalars['Int'];
  promptType: Scalars['Int'];
  actionType: Scalars['Int'];
  petParentId: Scalars['Int'];
  petId: Scalars['Int'];
  buttons: Scalars['Int'];
  channelStatusChangeId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  paymentIntentId: Scalars['Int'];
  order: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AutomationRunActionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateManyAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyAutomationActionInputEnvelope = {
  data: Array<AutomationRunActionCreateManyAutomationActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyAutomationRunInputEnvelope = {
  data: Array<AutomationRunActionCreateManyAutomationRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyChannelStatusChangeInputEnvelope = {
  data: Array<AutomationRunActionCreateManyChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyPetInputEnvelope = {
  data: Array<AutomationRunActionCreateManyPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionCreateManyPetParentInputEnvelope = {
  data: Array<AutomationRunActionCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateNestedManyWithoutAutomationActionInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedManyWithoutAutomationRunInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedManyWithoutPetInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionCreateNestedOneWithoutFormSubmissionInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
};

export type AutomationRunActionCreateNestedOneWithoutPaymentIntentInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
};

export type AutomationRunActionCreateOrConnectWithoutAutomationActionInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutAutomationActionInput;
};

export type AutomationRunActionCreateOrConnectWithoutAutomationRunInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutAutomationRunInput;
};

export type AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput;
};

export type AutomationRunActionCreateOrConnectWithoutFormSubmissionInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutFormSubmissionInput;
};

export type AutomationRunActionCreateOrConnectWithoutPaymentIntentInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutPaymentIntentInput;
};

export type AutomationRunActionCreateOrConnectWithoutPetInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutPetInput;
};

export type AutomationRunActionCreateOrConnectWithoutPetParentInput = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionUncheckedCreateWithoutPetParentInput;
};

export type AutomationRunActionCreateWithoutAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutPaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionListRelationFilter = {
  every?: Maybe<AutomationRunActionWhereInput>;
  some?: Maybe<AutomationRunActionWhereInput>;
  none?: Maybe<AutomationRunActionWhereInput>;
};

export type AutomationRunActionMaxAggregateOutputType = {
  __typename?: 'AutomationRunActionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionMinAggregateOutputType = {
  __typename?: 'AutomationRunActionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AutomationRunActionOrderByRelevanceFieldEnum {
  Id = 'id',
  AutomationRunId = 'automationRunId',
  AutomationActionId = 'automationActionId',
  Body = 'body',
  PetParentId = 'petParentId',
  PetId = 'petId',
  ChannelStatusChangeId = 'channelStatusChangeId',
  FormSubmissionId = 'formSubmissionId',
  PaymentIntentId = 'paymentIntentId',
}

export type AutomationRunActionOrderByRelevanceInput = {
  fields: Array<AutomationRunActionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AutomationRunActionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  _count?: Maybe<AutomationRunActionCountOrderByAggregateInput>;
  _avg?: Maybe<AutomationRunActionAvgOrderByAggregateInput>;
  _max?: Maybe<AutomationRunActionMaxOrderByAggregateInput>;
  _min?: Maybe<AutomationRunActionMinOrderByAggregateInput>;
  _sum?: Maybe<AutomationRunActionSumOrderByAggregateInput>;
};

export type AutomationRunActionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  petParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  pet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  automationAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  channelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  paymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<AutomationRunActionOrderByRelevanceInput>;
};

export enum AutomationRunActionPromptType {
  Buttons = 'Buttons',
  Form = 'Form',
  Payment = 'Payment',
  Prompt = 'Prompt',
  Statement = 'Statement',
}

export type AutomationRunActionRelationFilter = {
  is?: Maybe<AutomationRunActionWhereInput>;
  isNot?: Maybe<AutomationRunActionWhereInput>;
};

export enum AutomationRunActionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AutomationRunId = 'automationRunId',
  AutomationActionId = 'automationActionId',
  Body = 'body',
  Attachments = 'attachments',
  PromptType = 'promptType',
  ActionType = 'actionType',
  PetParentId = 'petParentId',
  PetId = 'petId',
  Buttons = 'buttons',
  ChannelStatusChangeId = 'channelStatusChangeId',
  FormSubmissionId = 'formSubmissionId',
  PaymentIntentId = 'paymentIntentId',
  Order = 'order',
}

export type AutomationRunActionScalarWhereInput = {
  AND?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  OR?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  NOT?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  automationRunId?: Maybe<StringFilter>;
  automationActionId?: Maybe<StringNullableFilter>;
  body?: Maybe<StringFilter>;
  attachments?: Maybe<JsonFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  actionType?: Maybe<EnumAutomationRunActionTypeFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  buttons?: Maybe<JsonFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  paymentIntentId?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
};

export type AutomationRunActionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  automationRunId?: Maybe<StringWithAggregatesFilter>;
  automationActionId?: Maybe<StringNullableWithAggregatesFilter>;
  body?: Maybe<StringWithAggregatesFilter>;
  attachments?: Maybe<JsonWithAggregatesFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  actionType?: Maybe<EnumAutomationRunActionTypeWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  petId?: Maybe<StringNullableWithAggregatesFilter>;
  buttons?: Maybe<JsonWithAggregatesFilter>;
  channelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
};

export type AutomationRunActionSubscription = {
  __typename?: 'AutomationRunActionSubscription';
  node: AutomationRunAction;
  mutation: MutationType;
};

export type AutomationRunActionSumAggregateOutputType = {
  __typename?: 'AutomationRunActionSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export enum AutomationRunActionType {
  PetParent = 'PetParent',
  Automated = 'Automated',
  System = 'System',
}

export type AutomationRunActionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutPetInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
};

export type AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
};

export type AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
};

export type AutomationRunActionUncheckedCreateWithoutAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutPaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId: Scalars['String'];
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationActionInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutPetInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutPetParentInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutFormSubmissionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput>;
};

export type AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutPaymentIntentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput>;
};

export type AutomationRunActionUncheckedUpdateWithoutAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petParentId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automationRunId?: Maybe<Scalars['String']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  petId?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutActionsInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutPetInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutPetParentInput = {
  where: AutomationRunActionScalarWhereInput;
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
};

export type AutomationRunActionUpdateManyWithoutAutomationActionInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateManyWithoutAutomationRunInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateManyWithoutPetInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateManyWithoutPetParentInput = {
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
};

export type AutomationRunActionUpdateOneWithoutFormSubmissionInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutFormSubmissionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput>;
};

export type AutomationRunActionUpdateOneWithoutPaymentIntentInput = {
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutPaymentIntentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput>;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutAutomationActionInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutAutomationRunInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutPetInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutPetInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput = {
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUncheckedUpdateWithoutPetParentInput;
};

export type AutomationRunActionUpdateWithoutAutomationActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionUpdateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionUpdateWithoutPaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionUpdateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  actionType?: Maybe<AutomationRunActionType>;
  buttons?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsInput>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionInput>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionInput>;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutAutomationActionInput;
  create: AutomationRunActionUncheckedCreateWithoutAutomationActionInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutAutomationRunInput;
  create: AutomationRunActionUncheckedCreateWithoutAutomationRunInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput;
  create: AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutPetInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutPetInput;
  create: AutomationRunActionUncheckedCreateWithoutPetInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput = {
  where: AutomationRunActionWhereUniqueInput;
  update: AutomationRunActionUncheckedUpdateWithoutPetParentInput;
  create: AutomationRunActionUncheckedCreateWithoutPetParentInput;
};

export type AutomationRunActionUpsertWithoutFormSubmissionInput = {
  update: AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput;
  create: AutomationRunActionUncheckedCreateWithoutFormSubmissionInput;
};

export type AutomationRunActionUpsertWithoutPaymentIntentInput = {
  update: AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput;
  create: AutomationRunActionUncheckedCreateWithoutPaymentIntentInput;
};

export type AutomationRunActionWhereInput = {
  AND?: Maybe<Array<AutomationRunActionWhereInput>>;
  OR?: Maybe<Array<AutomationRunActionWhereInput>>;
  NOT?: Maybe<Array<AutomationRunActionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  automationRunId?: Maybe<StringFilter>;
  automationActionId?: Maybe<StringNullableFilter>;
  body?: Maybe<StringFilter>;
  attachments?: Maybe<JsonFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  actionType?: Maybe<EnumAutomationRunActionTypeFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  buttons?: Maybe<JsonFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  paymentIntentId?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  petParent?: Maybe<ClinicPetParentWhereInput>;
  pet?: Maybe<ClinicPetWhereInput>;
  automationAction?: Maybe<WorkflowEventActionWhereInput>;
  channelStatusChange?: Maybe<ChannelStatusWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  paymentIntent?: Maybe<StripePaymentIntentWhereInput>;
};

export type AutomationRunActionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type AutomationRunCountAggregateOutputType = {
  __typename?: 'AutomationRunCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  channelMessageId: Scalars['Int'];
  originAutomationId: Scalars['Int'];
  runType: Scalars['Int'];
  manuallySentById: Scalars['Int'];
  status: Scalars['Int'];
  automationEvent: Scalars['Int'];
  clinicId: Scalars['Int'];
  startedAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  appointmentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AutomationRunCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type AutomationRunCountOutputType = {
  __typename?: 'AutomationRunCountOutputType';
  actions: Scalars['Int'];
};

export type AutomationRunCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunCreateManyAppointmentInputEnvelope = {
  data: Array<AutomationRunCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunCreateManyClinicInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
};

export type AutomationRunCreateManyClinicPetParentInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunCreateManyFormSubmissionInputEnvelope = {
  data: Array<AutomationRunCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunCreateManyManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunCreateManyManuallySentByInputEnvelope = {
  data: Array<AutomationRunCreateManyManuallySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunCreateManyOriginAutomationInputEnvelope = {
  data: Array<AutomationRunCreateManyOriginAutomationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunCreateManyServiceReminderInputEnvelope = {
  data: Array<AutomationRunCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutManuallySentByInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutOriginAutomationInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunCreateNestedOneWithoutActionsInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutActionsInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutActionsInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
};

export type AutomationRunCreateNestedOneWithoutChannelMessageInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
};

export type AutomationRunCreateOrConnectWithoutActionsInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutActionsInput;
};

export type AutomationRunCreateOrConnectWithoutAppointmentInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutAppointmentInput;
};

export type AutomationRunCreateOrConnectWithoutChannelMessageInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutChannelMessageInput;
};

export type AutomationRunCreateOrConnectWithoutClinicInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutClinicInput;
};

export type AutomationRunCreateOrConnectWithoutClinicPetParentInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutClinicPetParentInput;
};

export type AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type AutomationRunCreateOrConnectWithoutFormSubmissionInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutFormSubmissionInput;
};

export type AutomationRunCreateOrConnectWithoutManuallySentByInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutManuallySentByInput;
};

export type AutomationRunCreateOrConnectWithoutOriginAutomationInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutOriginAutomationInput;
};

export type AutomationRunCreateOrConnectWithoutServiceReminderInput = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunUncheckedCreateWithoutServiceReminderInput;
};

export type AutomationRunCreateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateWithoutManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
};

export type AutomationRunCreateWithoutOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunListRelationFilter = {
  every?: Maybe<AutomationRunWhereInput>;
  some?: Maybe<AutomationRunWhereInput>;
  none?: Maybe<AutomationRunWhereInput>;
};

export type AutomationRunMaxAggregateOutputType = {
  __typename?: 'AutomationRunMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type AutomationRunMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type AutomationRunMinAggregateOutputType = {
  __typename?: 'AutomationRunMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type AutomationRunMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
};

export type AutomationRunOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AutomationRunOrderByRelevanceFieldEnum {
  Id = 'id',
  ChannelMessageId = 'channelMessageId',
  OriginAutomationId = 'originAutomationId',
  ManuallySentById = 'manuallySentById',
  ClinicId = 'clinicId',
  AppointmentId = 'appointmentId',
  FormSubmissionId = 'formSubmissionId',
  ServiceReminderId = 'serviceReminderId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicPetParentId = 'clinicPetParentId',
}

export type AutomationRunOrderByRelevanceInput = {
  fields: Array<AutomationRunOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type AutomationRunOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  _count?: Maybe<AutomationRunCountOrderByAggregateInput>;
  _max?: Maybe<AutomationRunMaxOrderByAggregateInput>;
  _min?: Maybe<AutomationRunMinOrderByAggregateInput>;
};

export type AutomationRunOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  channelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  actions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  originAutomation?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  manuallySentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<AutomationRunOrderByRelevanceInput>;
};

export type AutomationRunRelationFilter = {
  is?: Maybe<AutomationRunWhereInput>;
  isNot?: Maybe<AutomationRunWhereInput>;
};

export enum AutomationRunScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ChannelMessageId = 'channelMessageId',
  OriginAutomationId = 'originAutomationId',
  RunType = 'runType',
  ManuallySentById = 'manuallySentById',
  Status = 'status',
  AutomationEvent = 'automationEvent',
  ClinicId = 'clinicId',
  StartedAt = 'startedAt',
  CompletedAt = 'completedAt',
  AppointmentId = 'appointmentId',
  FormSubmissionId = 'formSubmissionId',
  ServiceReminderId = 'serviceReminderId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicPetParentId = 'clinicPetParentId',
}

export type AutomationRunScalarWhereInput = {
  AND?: Maybe<Array<AutomationRunScalarWhereInput>>;
  OR?: Maybe<Array<AutomationRunScalarWhereInput>>;
  NOT?: Maybe<Array<AutomationRunScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  channelMessageId?: Maybe<StringFilter>;
  originAutomationId?: Maybe<StringFilter>;
  runType?: Maybe<EnumAutomationRunTypeFilter>;
  manuallySentById?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumAutomationRunStatusFilter>;
  automationEvent?: Maybe<EnumWorkflowEventFilter>;
  clinicId?: Maybe<StringFilter>;
  startedAt?: Maybe<DateTimeFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
};

export type AutomationRunScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  channelMessageId?: Maybe<StringWithAggregatesFilter>;
  originAutomationId?: Maybe<StringWithAggregatesFilter>;
  runType?: Maybe<EnumAutomationRunTypeWithAggregatesFilter>;
  manuallySentById?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumAutomationRunStatusWithAggregatesFilter>;
  automationEvent?: Maybe<EnumWorkflowEventWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  startedAt?: Maybe<DateTimeWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
};

export enum AutomationRunStatus {
  Sent = 'Sent',
  Started = 'Started',
  Completed = 'Completed',
}

export enum AutomationRunType {
  Manual = 'Manual',
  Automated = 'Automated',
}

export type AutomationRunUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
};

export type AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
};

export type AutomationRunUncheckedCreateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
};

export type AutomationRunUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  startedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateManyWithoutManuallySentByInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutManuallySentByInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutServiceReminderInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUncheckedUpdateOneWithoutChannelMessageInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutChannelMessageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutChannelMessageInput>;
};

export type AutomationRunUncheckedUpdateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type AutomationRunUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateWithoutManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateWithoutOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUncheckedUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  manuallySentById?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunInput>;
};

export type AutomationRunUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateManyWithWhereWithoutAppointmentInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutManuallySentByInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutManuallySentAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithWhereWithoutServiceReminderInput = {
  where: AutomationRunScalarWhereInput;
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
};

export type AutomationRunUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutManuallySentByInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutManuallySentByInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutOriginAutomationInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateManyWithoutServiceReminderInput = {
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
};

export type AutomationRunUpdateOneRequiredWithoutActionsInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutActionsInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutActionsInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutActionsInput>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutActionsInput>;
};

export type AutomationRunUpdateOneWithoutChannelMessageInput = {
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutChannelMessageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutChannelMessageInput>;
};

export type AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutAppointmentInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutClinicInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutClinicPetParentInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutFormSubmissionInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutManuallySentByInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutOriginAutomationInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput = {
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUncheckedUpdateWithoutServiceReminderInput;
};

export type AutomationRunUpdateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutManuallySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutOriginAutomationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  runType?: Maybe<AutomationRunType>;
  status?: Maybe<AutomationRunStatus>;
  automationEvent?: Maybe<WorkflowEvent>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsInput>;
};

export type AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutAppointmentInput;
  create: AutomationRunUncheckedCreateWithoutAppointmentInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutClinicInput;
  create: AutomationRunUncheckedCreateWithoutClinicInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutClinicPetParentInput;
  create: AutomationRunUncheckedCreateWithoutClinicPetParentInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput;
  create: AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutFormSubmissionInput;
  create: AutomationRunUncheckedCreateWithoutFormSubmissionInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutManuallySentByInput;
  create: AutomationRunUncheckedCreateWithoutManuallySentByInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutOriginAutomationInput;
  create: AutomationRunUncheckedCreateWithoutOriginAutomationInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput = {
  where: AutomationRunWhereUniqueInput;
  update: AutomationRunUncheckedUpdateWithoutServiceReminderInput;
  create: AutomationRunUncheckedCreateWithoutServiceReminderInput;
};

export type AutomationRunUpsertWithoutActionsInput = {
  update: AutomationRunUncheckedUpdateWithoutActionsInput;
  create: AutomationRunUncheckedCreateWithoutActionsInput;
};

export type AutomationRunUpsertWithoutChannelMessageInput = {
  update: AutomationRunUncheckedUpdateWithoutChannelMessageInput;
  create: AutomationRunUncheckedCreateWithoutChannelMessageInput;
};

export type AutomationRunWhereInput = {
  AND?: Maybe<Array<AutomationRunWhereInput>>;
  OR?: Maybe<Array<AutomationRunWhereInput>>;
  NOT?: Maybe<Array<AutomationRunWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  channelMessageId?: Maybe<StringFilter>;
  originAutomationId?: Maybe<StringFilter>;
  runType?: Maybe<EnumAutomationRunTypeFilter>;
  manuallySentById?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumAutomationRunStatusFilter>;
  automationEvent?: Maybe<EnumWorkflowEventFilter>;
  clinicId?: Maybe<StringFilter>;
  startedAt?: Maybe<DateTimeFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  channelMessage?: Maybe<ChannelMessageWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  actions?: Maybe<AutomationRunActionListRelationFilter>;
  originAutomation?: Maybe<WorkflowEventSettingWhereInput>;
  manuallySentBy?: Maybe<UserWhereInput>;
};

export type AutomationRunWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
};

export enum AutomationStatus {
  Queued = 'Queued',
  Started = 'Started',
  Paused = 'Paused',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Completed = 'Completed',
  ClientVisitAlreadyInitiated = 'ClientVisitAlreadyInitiated',
}

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Int'];
};

export type BenefitSource = {
  __typename?: 'BenefitSource';
  planIds: Array<Scalars['String']>;
  addonIds: Array<Scalars['String']>;
};

export type BitwerxClientBalance = {
  __typename?: 'BitwerxClientBalance';
  currentBalance?: Maybe<Scalars['Float']>;
  agingCurrent?: Maybe<Scalars['Float']>;
  aging30D?: Maybe<Scalars['Float']>;
  aging60D?: Maybe<Scalars['Float']>;
  aging90D?: Maybe<Scalars['Float']>;
  aging120D?: Maybe<Scalars['Float']>;
};

export type BitwerxIntegration = {
  __typename?: 'BitwerxIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  practiceId: Scalars['String'];
  /** This property is the same as siteId on Vetdata/Datapoint, for multi-location practices */
  locationId?: Maybe<Scalars['String']>;
  system: BitwerxSystem;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  /** This property references the PIMS PaymentType ID for writebacks */
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationCountAggregateOutputType = {
  __typename?: 'BitwerxIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  practiceId: Scalars['Int'];
  locationId: Scalars['Int'];
  system: Scalars['Int'];
  writebackPaymentTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BitwerxIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export type BitwerxIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  system: BitwerxSystem;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutBitwerxInput>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  system: BitwerxSystem;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<BitwerxIntegrationWhereUniqueInput>;
};

export type BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: BitwerxIntegrationWhereUniqueInput;
  create: BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type BitwerxIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  system: BitwerxSystem;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMaxAggregateOutputType = {
  __typename?: 'BitwerxIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export type BitwerxIntegrationMinAggregateOutputType = {
  __typename?: 'BitwerxIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export enum BitwerxIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  PracticeId = 'practiceId',
  LocationId = 'locationId',
  WritebackPaymentTypeId = 'writebackPaymentTypeId',
}

export type BitwerxIntegrationOrderByRelevanceInput = {
  fields: Array<BitwerxIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type BitwerxIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
  _count?: Maybe<BitwerxIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxIntegrationMinOrderByAggregateInput>;
};

export type BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
  _relevance?: Maybe<BitwerxIntegrationOrderByRelevanceInput>;
};

export type BitwerxIntegrationRelationFilter = {
  is?: Maybe<BitwerxIntegrationWhereInput>;
  isNot?: Maybe<BitwerxIntegrationWhereInput>;
};

export enum BitwerxIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PracticeId = 'practiceId',
  LocationId = 'locationId',
  System = 'system',
  WritebackPaymentTypeId = 'writebackPaymentTypeId',
}

export type BitwerxIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  practiceId?: Maybe<StringWithAggregatesFilter>;
  locationId?: Maybe<StringNullableWithAggregatesFilter>;
  system?: Maybe<EnumBitwerxSystemWithAggregatesFilter>;
  writebackPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BitwerxIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  system: BitwerxSystem;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutBitwerxInput>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  system: BitwerxSystem;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutBitwerxInput>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutBitwerxInput>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<BitwerxIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  update?: Maybe<BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type BitwerxIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  practiceId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type BitwerxIntegrationWhereInput = {
  AND?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  OR?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  NOT?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  practiceId?: Maybe<StringFilter>;
  locationId?: Maybe<StringNullableFilter>;
  system?: Maybe<EnumBitwerxSystemFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  writebackPaymentTypeId?: Maybe<StringNullableFilter>;
};

export type BitwerxIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum BitwerxSystem {
  Cornerstone = 'Cornerstone',
  Impromed = 'Impromed',
  Avimark = 'Avimark',
}

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
};

export type BoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type CalculateMultiplePetsBenefitUsageInput = {
  organizationPetIds: Array<Scalars['String']>;
};

export type CalculateMultiplePetsBenefitUsageOutput = {
  __typename?: 'CalculateMultiplePetsBenefitUsageOutput';
  petUsages: Array<PetBenefitUsage>;
};

export type CalculatePetBenefitUsageInput = {
  organizationPetId: Scalars['String'];
};

export type CalculatePetBenefitUsageOutput = {
  __typename?: 'CalculatePetBenefitUsageOutput';
  usages: Array<UsageByBenefit>;
};

export type CallHistory = {
  __typename?: 'CallHistory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status: CallStatus;
  /** duration of call in seconds */
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  clinic: Clinic;
  recordings: Array<CallRecording>;
  type: CallHistoryType;
  participants: Array<CallParticipant>;
  _count: CallHistoryCountOutputType;
};

export type CallHistoryRecordingsArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallRecordingScalarFieldEnum>;
};

export type CallHistoryParticipantsArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
};

export type CallHistoryAvgAggregateOutputType = {
  __typename?: 'CallHistoryAvgAggregateOutputType';
  duration?: Maybe<Scalars['Float']>;
};

export type CallHistoryAvgOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallHistoryCountAggregateOutputType = {
  __typename?: 'CallHistoryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  provider: Scalars['Int'];
  sourceId: Scalars['Int'];
  from: Scalars['Int'];
  status: Scalars['Int'];
  duration: Scalars['Int'];
  clinicId: Scalars['Int'];
  type: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CallHistoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type CallHistoryCountOutputType = {
  __typename?: 'CallHistoryCountOutputType';
  recordings: Scalars['Int'];
  participants: Scalars['Int'];
};

export type CallHistoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
  type: CallHistoryType;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type: CallHistoryType;
};

export type CallHistoryCreateManyClinicInputEnvelope = {
  data: Array<CallHistoryCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallHistoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  type: CallHistoryType;
};

export type CallHistoryCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
};

export type CallHistoryCreateNestedOneWithoutParticipantsInput = {
  create?: Maybe<CallHistoryUncheckedCreateWithoutParticipantsInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutParticipantsInput>;
  connect?: Maybe<CallHistoryWhereUniqueInput>;
};

export type CallHistoryCreateNestedOneWithoutRecordingsInput = {
  create?: Maybe<CallHistoryUncheckedCreateWithoutRecordingsInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutRecordingsInput>;
  connect?: Maybe<CallHistoryWhereUniqueInput>;
};

export type CallHistoryCreateOrConnectWithoutClinicInput = {
  where: CallHistoryWhereUniqueInput;
  create: CallHistoryUncheckedCreateWithoutClinicInput;
};

export type CallHistoryCreateOrConnectWithoutParticipantsInput = {
  where: CallHistoryWhereUniqueInput;
  create: CallHistoryUncheckedCreateWithoutParticipantsInput;
};

export type CallHistoryCreateOrConnectWithoutRecordingsInput = {
  where: CallHistoryWhereUniqueInput;
  create: CallHistoryUncheckedCreateWithoutRecordingsInput;
};

export type CallHistoryCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
  type: CallHistoryType;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryCreateWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
  type: CallHistoryType;
};

export type CallHistoryCreateWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  type: CallHistoryType;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryListRelationFilter = {
  every?: Maybe<CallHistoryWhereInput>;
  some?: Maybe<CallHistoryWhereInput>;
  none?: Maybe<CallHistoryWhereInput>;
};

export type CallHistoryMaxAggregateOutputType = {
  __typename?: 'CallHistoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type CallHistoryMinAggregateOutputType = {
  __typename?: 'CallHistoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type CallHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallHistoryOrderByRelevanceFieldEnum {
  Id = 'id',
  SourceId = 'sourceId',
  From = 'from',
  ClinicId = 'clinicId',
}

export type CallHistoryOrderByRelevanceInput = {
  fields: Array<CallHistoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CallHistoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  _count?: Maybe<CallHistoryCountOrderByAggregateInput>;
  _avg?: Maybe<CallHistoryAvgOrderByAggregateInput>;
  _max?: Maybe<CallHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<CallHistoryMinOrderByAggregateInput>;
  _sum?: Maybe<CallHistorySumOrderByAggregateInput>;
};

export type CallHistoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  recordings?: Maybe<CallRecordingOrderByRelationAggregateInput>;
  type?: Maybe<SortOrder>;
  participants?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  _relevance?: Maybe<CallHistoryOrderByRelevanceInput>;
};

export type CallHistoryRelationFilter = {
  is?: Maybe<CallHistoryWhereInput>;
  isNot?: Maybe<CallHistoryWhereInput>;
};

export enum CallHistoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Provider = 'provider',
  SourceId = 'sourceId',
  From = 'from',
  Status = 'status',
  Duration = 'duration',
  ClinicId = 'clinicId',
  Type = 'type',
}

export type CallHistoryScalarWhereInput = {
  AND?: Maybe<Array<CallHistoryScalarWhereInput>>;
  OR?: Maybe<Array<CallHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<CallHistoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumCallProviderFilter>;
  sourceId?: Maybe<StringFilter>;
  from?: Maybe<StringFilter>;
  status?: Maybe<EnumCallStatusFilter>;
  duration?: Maybe<IntNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  type?: Maybe<EnumCallHistoryTypeFilter>;
};

export type CallHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  provider?: Maybe<EnumCallProviderWithAggregatesFilter>;
  sourceId?: Maybe<StringWithAggregatesFilter>;
  from?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumCallStatusWithAggregatesFilter>;
  duration?: Maybe<IntNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumCallHistoryTypeWithAggregatesFilter>;
};

export type CallHistorySumAggregateOutputType = {
  __typename?: 'CallHistorySumAggregateOutputType';
  duration?: Maybe<Scalars['Int']>;
};

export type CallHistorySumOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export enum CallHistoryType {
  Video = 'Video',
  Voice = 'Voice',
}

export type CallHistoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
  type: CallHistoryType;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
};

export type CallHistoryUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
  type: CallHistoryType;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedCreateWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
  type: CallHistoryType;
};

export type CallHistoryUncheckedCreateWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  from: Scalars['String'];
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId: Scalars['String'];
  type: CallHistoryType;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryInput>;
  type?: Maybe<CallHistoryType>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryUncheckedUpdateManyWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<CallHistoryUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  update?: Maybe<Array<CallHistoryUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<CallHistoryUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<CallHistoryScalarWhereInput>>;
};

export type CallHistoryUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryInput>;
  type?: Maybe<CallHistoryType>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryInput>;
};

export type CallHistoryUncheckedUpdateWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryInput>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryUncheckedUpdateWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  type?: Maybe<CallHistoryType>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryInput>;
};

export type CallHistoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsInput>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryInput>;
  type?: Maybe<CallHistoryType>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryInput>;
};

export type CallHistoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryUpdateManyWithWhereWithoutClinicInput = {
  where: CallHistoryScalarWhereInput;
  data: CallHistoryUncheckedUpdateManyWithoutCallsInput;
};

export type CallHistoryUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<CallHistoryUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  update?: Maybe<Array<CallHistoryUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<CallHistoryUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<CallHistoryScalarWhereInput>>;
};

export type CallHistoryUpdateOneRequiredWithoutParticipantsInput = {
  create?: Maybe<CallHistoryUncheckedCreateWithoutParticipantsInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutParticipantsInput>;
  upsert?: Maybe<CallHistoryUpsertWithoutParticipantsInput>;
  connect?: Maybe<CallHistoryWhereUniqueInput>;
  update?: Maybe<CallHistoryUncheckedUpdateWithoutParticipantsInput>;
};

export type CallHistoryUpdateOneRequiredWithoutRecordingsInput = {
  create?: Maybe<CallHistoryUncheckedCreateWithoutRecordingsInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutRecordingsInput>;
  upsert?: Maybe<CallHistoryUpsertWithoutRecordingsInput>;
  connect?: Maybe<CallHistoryWhereUniqueInput>;
  update?: Maybe<CallHistoryUncheckedUpdateWithoutRecordingsInput>;
};

export type CallHistoryUpdateWithWhereUniqueWithoutClinicInput = {
  where: CallHistoryWhereUniqueInput;
  data: CallHistoryUncheckedUpdateWithoutClinicInput;
};

export type CallHistoryUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryInput>;
  type?: Maybe<CallHistoryType>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryInput>;
};

export type CallHistoryUpdateWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsInput>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryInput>;
  type?: Maybe<CallHistoryType>;
};

export type CallHistoryUpdateWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  duration?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsInput>;
  type?: Maybe<CallHistoryType>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryInput>;
};

export type CallHistoryUpsertWithWhereUniqueWithoutClinicInput = {
  where: CallHistoryWhereUniqueInput;
  update: CallHistoryUncheckedUpdateWithoutClinicInput;
  create: CallHistoryUncheckedCreateWithoutClinicInput;
};

export type CallHistoryUpsertWithoutParticipantsInput = {
  update: CallHistoryUncheckedUpdateWithoutParticipantsInput;
  create: CallHistoryUncheckedCreateWithoutParticipantsInput;
};

export type CallHistoryUpsertWithoutRecordingsInput = {
  update: CallHistoryUncheckedUpdateWithoutRecordingsInput;
  create: CallHistoryUncheckedCreateWithoutRecordingsInput;
};

export type CallHistoryWhereInput = {
  AND?: Maybe<Array<CallHistoryWhereInput>>;
  OR?: Maybe<Array<CallHistoryWhereInput>>;
  NOT?: Maybe<Array<CallHistoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumCallProviderFilter>;
  sourceId?: Maybe<StringFilter>;
  from?: Maybe<StringFilter>;
  status?: Maybe<EnumCallStatusFilter>;
  duration?: Maybe<IntNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  recordings?: Maybe<CallRecordingListRelationFilter>;
  type?: Maybe<EnumCallHistoryTypeFilter>;
  participants?: Maybe<CallParticipantListRelationFilter>;
};

export type CallHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
};

export type CallParticipant = {
  __typename?: 'CallParticipant';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
  callHistory: CallHistory;
  clinicPetParent?: Maybe<ClinicPetParent>;
  user?: Maybe<User>;
};

export type CallParticipantCallHistoryIdClinicPetParentIdCompoundUniqueInput = {
  callHistoryId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type CallParticipantCallHistoryIdUserIdCompoundUniqueInput = {
  callHistoryId: Scalars['String'];
  userId: Scalars['String'];
};

export type CallParticipantCountAggregateOutputType = {
  __typename?: 'CallParticipantCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  number: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  userId: Scalars['Int'];
  callHistoryId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CallParticipantCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
};

export type CallParticipantCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateManyCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantCreateManyCallHistoryInputEnvelope = {
  data: Array<CallParticipantCreateManyCallHistoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantCreateManyClinicPetParentInputEnvelope = {
  data: Array<CallParticipantCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantCreateManyUserInputEnvelope = {
  data: Array<CallParticipantCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateNestedManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantCreateOrConnectWithoutCallHistoryInput = {
  where: CallParticipantWhereUniqueInput;
  create: CallParticipantUncheckedCreateWithoutCallHistoryInput;
};

export type CallParticipantCreateOrConnectWithoutClinicPetParentInput = {
  where: CallParticipantWhereUniqueInput;
  create: CallParticipantUncheckedCreateWithoutClinicPetParentInput;
};

export type CallParticipantCreateOrConnectWithoutUserInput = {
  where: CallParticipantWhereUniqueInput;
  create: CallParticipantUncheckedCreateWithoutUserInput;
};

export type CallParticipantCreateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantListRelationFilter = {
  every?: Maybe<CallParticipantWhereInput>;
  some?: Maybe<CallParticipantWhereInput>;
  none?: Maybe<CallParticipantWhereInput>;
};

export type CallParticipantMaxAggregateOutputType = {
  __typename?: 'CallParticipantMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
};

export type CallParticipantMinAggregateOutputType = {
  __typename?: 'CallParticipantMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
};

export type CallParticipantOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallParticipantOrderByRelevanceFieldEnum {
  Id = 'id',
  Number = 'number',
  ClinicPetParentId = 'clinicPetParentId',
  UserId = 'userId',
  CallHistoryId = 'callHistoryId',
}

export type CallParticipantOrderByRelevanceInput = {
  fields: Array<CallParticipantOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CallParticipantOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  _count?: Maybe<CallParticipantCountOrderByAggregateInput>;
  _max?: Maybe<CallParticipantMaxOrderByAggregateInput>;
  _min?: Maybe<CallParticipantMinOrderByAggregateInput>;
};

export type CallParticipantOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  callHistory?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CallParticipantOrderByRelevanceInput>;
};

export enum CallParticipantScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Number = 'number',
  ClinicPetParentId = 'clinicPetParentId',
  UserId = 'userId',
  CallHistoryId = 'callHistoryId',
}

export type CallParticipantScalarWhereInput = {
  AND?: Maybe<Array<CallParticipantScalarWhereInput>>;
  OR?: Maybe<Array<CallParticipantScalarWhereInput>>;
  NOT?: Maybe<Array<CallParticipantScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  number?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  callHistoryId?: Maybe<StringFilter>;
};

export type CallParticipantScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  number?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  callHistoryId?: Maybe<StringWithAggregatesFilter>;
};

export type CallParticipantUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
};

export type CallParticipantUncheckedCreateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  callHistoryId: Scalars['String'];
};

export type CallParticipantUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutCallHistoryInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUncheckedUpdateManyWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUncheckedUpdateManyWithoutParticipantsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUncheckedUpdateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  callHistoryId?: Maybe<Scalars['String']>;
};

export type CallParticipantUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsInput>;
  user?: Maybe<UserUpdateOneWithoutCallsInput>;
};

export type CallParticipantUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
};

export type CallParticipantUpdateManyWithWhereWithoutCallHistoryInput = {
  where: CallParticipantScalarWhereInput;
  data: CallParticipantUncheckedUpdateManyWithoutParticipantsInput;
};

export type CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: CallParticipantScalarWhereInput;
  data: CallParticipantUncheckedUpdateManyWithoutCallsInput;
};

export type CallParticipantUpdateManyWithWhereWithoutUserInput = {
  where: CallParticipantScalarWhereInput;
  data: CallParticipantUncheckedUpdateManyWithoutCallsInput;
};

export type CallParticipantUpdateManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutCallHistoryInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUpdateManyWithoutUserInput = {
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
};

export type CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput = {
  where: CallParticipantWhereUniqueInput;
  data: CallParticipantUncheckedUpdateWithoutCallHistoryInput;
};

export type CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: CallParticipantWhereUniqueInput;
  data: CallParticipantUncheckedUpdateWithoutClinicPetParentInput;
};

export type CallParticipantUpdateWithWhereUniqueWithoutUserInput = {
  where: CallParticipantWhereUniqueInput;
  data: CallParticipantUncheckedUpdateWithoutUserInput;
};

export type CallParticipantUpdateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsInput>;
  user?: Maybe<UserUpdateOneWithoutCallsInput>;
};

export type CallParticipantUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsInput>;
  user?: Maybe<UserUpdateOneWithoutCallsInput>;
};

export type CallParticipantUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsInput>;
};

export type CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput = {
  where: CallParticipantWhereUniqueInput;
  update: CallParticipantUncheckedUpdateWithoutCallHistoryInput;
  create: CallParticipantUncheckedCreateWithoutCallHistoryInput;
};

export type CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: CallParticipantWhereUniqueInput;
  update: CallParticipantUncheckedUpdateWithoutClinicPetParentInput;
  create: CallParticipantUncheckedCreateWithoutClinicPetParentInput;
};

export type CallParticipantUpsertWithWhereUniqueWithoutUserInput = {
  where: CallParticipantWhereUniqueInput;
  update: CallParticipantUncheckedUpdateWithoutUserInput;
  create: CallParticipantUncheckedCreateWithoutUserInput;
};

export type CallParticipantWhereInput = {
  AND?: Maybe<Array<CallParticipantWhereInput>>;
  OR?: Maybe<Array<CallParticipantWhereInput>>;
  NOT?: Maybe<Array<CallParticipantWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  number?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  callHistoryId?: Maybe<StringFilter>;
  callHistory?: Maybe<CallHistoryWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  user?: Maybe<UserWhereInput>;
};

export type CallParticipantWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  callHistoryId_clinicPetParentId?: Maybe<CallParticipantCallHistoryIdClinicPetParentIdCompoundUniqueInput>;
  callHistoryId_userId?: Maybe<CallParticipantCallHistoryIdUserIdCompoundUniqueInput>;
};

export enum CallProvider {
  Twilio = 'Twilio',
}

export type CallRecording = {
  __typename?: 'CallRecording';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  callHistoryId: Scalars['String'];
  callHistory: CallHistory;
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecording>;
  downloadStatus: RecordingDownloadStatus;
  s3Uri: Scalars['String'];
  isDeleted: Scalars['Boolean'];
};

export type CallRecordingAvgAggregateOutputType = {
  __typename?: 'CallRecordingAvgAggregateOutputType';
  duration?: Maybe<Scalars['Float']>;
};

export type CallRecordingAvgOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallRecordingCountAggregateOutputType = {
  __typename?: 'CallRecordingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  callHistoryId: Scalars['Int'];
  duration: Scalars['Int'];
  downloadStatus: Scalars['Int'];
  s3Uri: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CallRecordingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type CallRecordingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistory: CallHistoryCreateNestedOneWithoutRecordingsInput;
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateManyCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateManyCallHistoryInputEnvelope = {
  data: Array<CallRecordingCreateManyCallHistoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateNestedManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
};

export type CallRecordingCreateNestedOneWithoutTwilioCallRecordingInput = {
  create?: Maybe<CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput>;
  connectOrCreate?: Maybe<CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput>;
  connect?: Maybe<CallRecordingWhereUniqueInput>;
};

export type CallRecordingCreateOrConnectWithoutCallHistoryInput = {
  where: CallRecordingWhereUniqueInput;
  create: CallRecordingUncheckedCreateWithoutCallHistoryInput;
};

export type CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput = {
  where: CallRecordingWhereUniqueInput;
  create: CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput;
};

export type CallRecordingCreateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateWithoutTwilioCallRecordingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistory: CallHistoryCreateNestedOneWithoutRecordingsInput;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingListRelationFilter = {
  every?: Maybe<CallRecordingWhereInput>;
  some?: Maybe<CallRecordingWhereInput>;
  none?: Maybe<CallRecordingWhereInput>;
};

export type CallRecordingMaxAggregateOutputType = {
  __typename?: 'CallRecordingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type CallRecordingMinAggregateOutputType = {
  __typename?: 'CallRecordingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type CallRecordingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallRecordingOrderByRelevanceFieldEnum {
  Id = 'id',
  CallHistoryId = 'callHistoryId',
  S3Uri = 's3Uri',
}

export type CallRecordingOrderByRelevanceInput = {
  fields: Array<CallRecordingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CallRecordingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<CallRecordingCountOrderByAggregateInput>;
  _avg?: Maybe<CallRecordingAvgOrderByAggregateInput>;
  _max?: Maybe<CallRecordingMaxOrderByAggregateInput>;
  _min?: Maybe<CallRecordingMinOrderByAggregateInput>;
  _sum?: Maybe<CallRecordingSumOrderByAggregateInput>;
};

export type CallRecordingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  callHistoryId?: Maybe<SortOrder>;
  callHistory?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  duration?: Maybe<SortOrder>;
  twilioCallRecording?: Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  downloadStatus?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _relevance?: Maybe<CallRecordingOrderByRelevanceInput>;
};

export type CallRecordingRelationFilter = {
  is?: Maybe<CallRecordingWhereInput>;
  isNot?: Maybe<CallRecordingWhereInput>;
};

export enum CallRecordingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CallHistoryId = 'callHistoryId',
  Duration = 'duration',
  DownloadStatus = 'downloadStatus',
  S3Uri = 's3Uri',
  IsDeleted = 'isDeleted',
}

export type CallRecordingScalarWhereInput = {
  AND?: Maybe<Array<CallRecordingScalarWhereInput>>;
  OR?: Maybe<Array<CallRecordingScalarWhereInput>>;
  NOT?: Maybe<Array<CallRecordingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  callHistoryId?: Maybe<StringFilter>;
  duration?: Maybe<IntNullableFilter>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusFilter>;
  s3Uri?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type CallRecordingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  callHistoryId?: Maybe<StringWithAggregatesFilter>;
  duration?: Maybe<IntNullableWithAggregatesFilter>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusWithAggregatesFilter>;
  s3Uri?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type CallRecordingSumAggregateOutputType = {
  __typename?: 'CallRecordingSumAggregateOutputType';
  duration?: Maybe<Scalars['Int']>;
};

export type CallRecordingSumOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallRecordingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
};

export type CallRecordingUncheckedCreateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingInput>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedUpdateManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  set?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  delete?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  update?: Maybe<Array<CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallRecordingUpdateManyWithWhereWithoutCallHistoryInput>>;
  deleteMany?: Maybe<Array<CallRecordingScalarWhereInput>>;
};

export type CallRecordingUncheckedUpdateManyWithoutRecordingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedUpdateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingInput>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistoryId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutRecordingsInput>;
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUpdateOneWithoutCallRecordingInput>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  status: RecordingControlStatus;
};

export type CallRecordingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUpdateManyWithWhereWithoutCallHistoryInput = {
  where: CallRecordingScalarWhereInput;
  data: CallRecordingUncheckedUpdateManyWithoutRecordingsInput;
};

export type CallRecordingUpdateManyWithoutCallHistoryInput = {
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  set?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  disconnect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  delete?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  update?: Maybe<Array<CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallRecordingUpdateManyWithWhereWithoutCallHistoryInput>>;
  deleteMany?: Maybe<Array<CallRecordingScalarWhereInput>>;
};

export type CallRecordingUpdateOneRequiredWithoutTwilioCallRecordingInput = {
  create?: Maybe<CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput>;
  connectOrCreate?: Maybe<CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput>;
  upsert?: Maybe<CallRecordingUpsertWithoutTwilioCallRecordingInput>;
  connect?: Maybe<CallRecordingWhereUniqueInput>;
  update?: Maybe<CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput>;
};

export type CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput = {
  where: CallRecordingWhereUniqueInput;
  data: CallRecordingUncheckedUpdateWithoutCallHistoryInput;
};

export type CallRecordingUpdateWithoutCallHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUpdateOneWithoutCallRecordingInput>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUpdateWithoutTwilioCallRecordingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutRecordingsInput>;
  duration?: Maybe<Scalars['Int']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  s3Uri?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput = {
  where: CallRecordingWhereUniqueInput;
  update: CallRecordingUncheckedUpdateWithoutCallHistoryInput;
  create: CallRecordingUncheckedCreateWithoutCallHistoryInput;
};

export type CallRecordingUpsertWithoutTwilioCallRecordingInput = {
  update: CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput;
  create: CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput;
};

export type CallRecordingWhereInput = {
  AND?: Maybe<Array<CallRecordingWhereInput>>;
  OR?: Maybe<Array<CallRecordingWhereInput>>;
  NOT?: Maybe<Array<CallRecordingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  callHistoryId?: Maybe<StringFilter>;
  callHistory?: Maybe<CallHistoryWhereInput>;
  duration?: Maybe<IntNullableFilter>;
  twilioCallRecording?: Maybe<TwilioCallRecordingWhereInput>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusFilter>;
  s3Uri?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type CallRecordingWhereUniqueInput = {
  id: Scalars['String'];
};

export enum CallStatus {
  Queued = 'Queued',
  Ringing = 'Ringing',
  InProgress = 'InProgress',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Failed = 'Failed',
  Busy = 'Busy',
  NoAnswer = 'NoAnswer',
}

export type CancelCareSubscriptionInput = {
  careSubscriptionId: Scalars['String'];
};

export type CancelCareSubscriptionResult = {
  __typename?: 'CancelCareSubscriptionResult';
  success: Scalars['Boolean'];
};

export enum CardBrand {
  Visa = 'Visa',
  Mastercard = 'Mastercard',
  Amex = 'Amex',
  Discover = 'Discover',
  Diners = 'Diners',
  Jcb = 'JCB',
  UnionPay = 'UnionPay',
  Unknown = 'Unknown',
}

export enum CardFunding {
  Credit = 'credit',
  Debit = 'debit',
  Prepaid = 'prepaid',
  Unknown = 'unknown',
}

export type CareAddonEnrollment = {
  __typename?: 'CareAddonEnrollment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
  organizationPetId: Scalars['String'];
  organizationPet: OrganizationPet;
  addOnPackageId: Scalars['String'];
  addOnPackage: CareAddonPackage;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careStripeSubscription?: Maybe<CareStripeSubscription>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  enrollmentPayments: Array<CareEnrollmentPayment>;
  associatedUsages: Array<CareBenefitUsage>;
  _count: CareAddonEnrollmentCountOutputType;
};

export type CareAddonEnrollmentEnrollmentToStripeInvoiceItemsArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
};

export type CareAddonEnrollmentEnrollmentPaymentsArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
};

export type CareAddonEnrollmentAssociatedUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};

export type CareAddonEnrollmentCountAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  startDate: Scalars['Int'];
  expirationDate: Scalars['Int'];
  status: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  addOnPackageId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAddonEnrollmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentCountOutputType = {
  __typename?: 'CareAddonEnrollmentCountOutputType';
  enrollmentToStripeInvoiceItems: Scalars['Int'];
  enrollmentPayments: Scalars['Int'];
  associatedUsages: Scalars['Int'];
};

export type CareAddonEnrollmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateManyAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyAddOnPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateManyCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentCreateManyOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
};

export type CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput;
};

export type CareAddonEnrollmentCreateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentListRelationFilter = {
  every?: Maybe<CareAddonEnrollmentWhereInput>;
  some?: Maybe<CareAddonEnrollmentWhereInput>;
  none?: Maybe<CareAddonEnrollmentWhereInput>;
};

export type CareAddonEnrollmentMaxAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentMinAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonEnrollmentOrderByRelevanceFieldEnum {
  Id = 'id',
  OrganizationPetId = 'organizationPetId',
  AddOnPackageId = 'addOnPackageId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
}

export type CareAddonEnrollmentOrderByRelevanceInput = {
  fields: Array<CareAddonEnrollmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAddonEnrollmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  _count?: Maybe<CareAddonEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<CareAddonEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonEnrollmentMinOrderByAggregateInput>;
};

export type CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  addOnPackageId?: Maybe<SortOrder>;
  addOnPackage?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  associatedUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareAddonEnrollmentOrderByRelevanceInput>;
};

export type CareAddonEnrollmentRelationFilter = {
  is?: Maybe<CareAddonEnrollmentWhereInput>;
  isNot?: Maybe<CareAddonEnrollmentWhereInput>;
};

export enum CareAddonEnrollmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate',
  Status = 'status',
  OrganizationPetId = 'organizationPetId',
  AddOnPackageId = 'addOnPackageId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
}

export type CareAddonEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  organizationPetId?: Maybe<StringFilter>;
  addOnPackageId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
};

export type CareAddonEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumEnrollmentStatusWithAggregatesFilter>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  addOnPackageId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareAddonEnrollmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentInput
  >;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput = {
  where: CareAddonEnrollmentScalarWhereInput;
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  where: CareAddonEnrollmentScalarWhereInput;
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput = {
  where: CareAddonEnrollmentScalarWhereInput;
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput;
};

export type CareAddonEnrollmentUpdateManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUpdateManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
};

export type CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutAssociatedUsagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput>;
};

export type CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutEnrollmentPaymentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput>;
};

export type CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  data: CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  data: CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  data: CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
};

export type CareAddonEnrollmentUpdateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUpdateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUpdateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentInput>;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  where: CareAddonEnrollmentWhereUniqueInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput;
};

export type CareAddonEnrollmentUpsertWithoutAssociatedUsagesInput = {
  update: CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
};

export type CareAddonEnrollmentUpsertWithoutEnrollmentPaymentsInput = {
  update: CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
};

export type CareAddonEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  update: CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareAddonEnrollmentWhereInput = {
  AND?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  organizationPetId?: Maybe<StringFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  addOnPackageId?: Maybe<StringFilter>;
  addOnPackage?: Maybe<CareAddonPackageWhereInput>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentListRelationFilter>;
  associatedUsages?: Maybe<CareBenefitUsageListRelationFilter>;
};

export type CareAddonEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareAddonPackage = {
  __typename?: 'CareAddonPackage';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  addonPackageBenefit: Array<CareAddonPackageBenefit>;
  enrollments: Array<CareAddonEnrollment>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  pricePerRenewal: Scalars['Int'];
  billingCycle: CarePlanCycleType;
  renewalCycle: CarePlanCycleType;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes: Scalars['String'];
  _count: CareAddonPackageCountOutputType;
};

export type CareAddonPackageAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonPackageBenefitScalarFieldEnum>;
};

export type CareAddonPackageEnrollmentsArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
};

export type CareAddonPackageAvgAggregateOutputType = {
  __typename?: 'CareAddonPackageAvgAggregateOutputType';
  pricePerRenewal?: Maybe<Scalars['Float']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
};

export type CareAddonPackageAvgOrderByAggregateInput = {
  pricePerRenewal?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefit = {
  __typename?: 'CareAddonPackageBenefit';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  benefit: CareBenefit;
  addOnPackageId: Scalars['String'];
  addOnPackage: CareAddonPackage;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitAddOnPackageIdDisplayOrderCompoundUniqueInput = {
  addOnPackageId: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export type CareAddonPackageBenefitAvgAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitAvgAggregateOutputType';
  includedUses?: Maybe<Scalars['Float']>;
  displayOrder?: Maybe<Scalars['Float']>;
};

export type CareAddonPackageBenefitAvgOrderByAggregateInput = {
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitCountAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  includedUses: Scalars['Int'];
  displayOrder: Scalars['Int'];
  benefitId: Scalars['Int'];
  addOnPackageId: Scalars['Int'];
  projectedSavings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAddonPackageBenefitCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitCreateManyAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope = {
  data: Array<CareAddonPackageBenefitCreateManyAddOnPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageBenefitCreateManyBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitCreateManyBenefitInputEnvelope = {
  data: Array<CareAddonPackageBenefitCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageBenefitCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
};

export type CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
};

export type CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  create: CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput;
};

export type CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  create: CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput;
};

export type CareAddonPackageBenefitCreateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackage: CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitListRelationFilter = {
  every?: Maybe<CareAddonPackageBenefitWhereInput>;
  some?: Maybe<CareAddonPackageBenefitWhereInput>;
  none?: Maybe<CareAddonPackageBenefitWhereInput>;
};

export type CareAddonPackageBenefitMaxAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitMinAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonPackageBenefitOrderByRelevanceFieldEnum {
  Id = 'id',
  BenefitId = 'benefitId',
  AddOnPackageId = 'addOnPackageId',
  ProjectedSavings = 'projectedSavings',
}

export type CareAddonPackageBenefitOrderByRelevanceInput = {
  fields: Array<CareAddonPackageBenefitOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAddonPackageBenefitOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  addOnPackageId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  _count?: Maybe<CareAddonPackageBenefitCountOrderByAggregateInput>;
  _avg?: Maybe<CareAddonPackageBenefitAvgOrderByAggregateInput>;
  _max?: Maybe<CareAddonPackageBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonPackageBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CareAddonPackageBenefitSumOrderByAggregateInput>;
};

export type CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  addOnPackageId?: Maybe<SortOrder>;
  addOnPackage?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  projectedSavings?: Maybe<SortOrder>;
  _relevance?: Maybe<CareAddonPackageBenefitOrderByRelevanceInput>;
};

export enum CareAddonPackageBenefitScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IncludedUses = 'includedUses',
  DisplayOrder = 'displayOrder',
  BenefitId = 'benefitId',
  AddOnPackageId = 'addOnPackageId',
  ProjectedSavings = 'projectedSavings',
}

export type CareAddonPackageBenefitScalarWhereInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  addOnPackageId?: Maybe<StringFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
};

export type CareAddonPackageBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  includedUses?: Maybe<IntNullableWithAggregatesFilter>;
  displayOrder?: Maybe<IntNullableWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  addOnPackageId?: Maybe<StringWithAggregatesFilter>;
  projectedSavings?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareAddonPackageBenefitSumAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitSumAggregateOutputType';
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageBenefitSumOrderByAggregateInput = {
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
};

export type CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
};

export type CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackageId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
};

export type CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackageId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsInput>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitInput>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput = {
  where: CareAddonPackageBenefitScalarWhereInput;
  data: CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput = {
  where: CareAddonPackageBenefitScalarWhereInput;
  data: CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitsInput;
};

export type CareAddonPackageBenefitUpdateManyWithoutAddOnPackageInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
};

export type CareAddonPackageBenefitUpdateManyWithoutBenefitInput = {
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
};

export type CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  data: CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput;
};

export type CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  data: CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput;
};

export type CareAddonPackageBenefitUpdateWithoutAddOnPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsInput>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitInput>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  update: CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput;
  create: CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput;
};

export type CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  update: CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput;
  create: CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput;
};

export type CareAddonPackageBenefitWhereInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  addOnPackageId?: Maybe<StringFilter>;
  addOnPackage?: Maybe<CareAddonPackageWhereInput>;
  projectedSavings?: Maybe<StringNullableFilter>;
};

export type CareAddonPackageBenefitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  addOnPackageId_displayOrder?: Maybe<CareAddonPackageBenefitAddOnPackageIdDisplayOrderCompoundUniqueInput>;
};

export type CareAddonPackageCountAggregateOutputType = {
  __typename?: 'CareAddonPackageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  title: Scalars['Int'];
  description: Scalars['Int'];
  published: Scalars['Int'];
  themeColor: Scalars['Int'];
  organizationId: Scalars['Int'];
  pricePerRenewal: Scalars['Int'];
  billingCycle: Scalars['Int'];
  renewalCycle: Scalars['Int'];
  addonCancelsAt: Scalars['Int'];
  stripePriceId: Scalars['Int'];
  targetSpecies: Scalars['Int'];
  earliestTargetPetAgeInMonths: Scalars['Int'];
  latestTargetPetAgeInMonths: Scalars['Int'];
  editorNotes: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAddonPackageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
};

export type CareAddonPackageCountOutputType = {
  __typename?: 'CareAddonPackageCountOutputType';
  addonPackageBenefit: Scalars['Int'];
  enrollments: Scalars['Int'];
};

export type CareAddonPackageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageCreateManyOrganizationInputEnvelope = {
  data: Array<CareAddonPackageCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
};

export type CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput = {
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput>;
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
};

export type CareAddonPackageCreateNestedOneWithoutEnrollmentsInput = {
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutEnrollmentsInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutEnrollmentsInput>;
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
};

export type CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageCreateOrConnectWithoutEnrollmentsInput = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageUncheckedCreateWithoutEnrollmentsInput;
};

export type CareAddonPackageCreateOrConnectWithoutOrganizationInput = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageUncheckedCreateWithoutOrganizationInput;
};

export type CareAddonPackageCreateWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageCreateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageListRelationFilter = {
  every?: Maybe<CareAddonPackageWhereInput>;
  some?: Maybe<CareAddonPackageWhereInput>;
  none?: Maybe<CareAddonPackageWhereInput>;
};

export type CareAddonPackageMaxAggregateOutputType = {
  __typename?: 'CareAddonPackageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
};

export type CareAddonPackageMinAggregateOutputType = {
  __typename?: 'CareAddonPackageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
};

export type CareAddonPackageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonPackageOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  ThemeColor = 'themeColor',
  OrganizationId = 'organizationId',
  StripePriceId = 'stripePriceId',
  EditorNotes = 'editorNotes',
}

export type CareAddonPackageOrderByRelevanceInput = {
  fields: Array<CareAddonPackageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAddonPackageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  _count?: Maybe<CareAddonPackageCountOrderByAggregateInput>;
  _avg?: Maybe<CareAddonPackageAvgOrderByAggregateInput>;
  _max?: Maybe<CareAddonPackageMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonPackageMinOrderByAggregateInput>;
  _sum?: Maybe<CareAddonPackageSumOrderByAggregateInput>;
};

export type CareAddonPackageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitOrderByRelationAggregateInput>;
  enrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  addonCancelsAt?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  _relevance?: Maybe<CareAddonPackageOrderByRelevanceInput>;
};

export type CareAddonPackageRelationFilter = {
  is?: Maybe<CareAddonPackageWhereInput>;
  isNot?: Maybe<CareAddonPackageWhereInput>;
};

export enum CareAddonPackageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Description = 'description',
  Published = 'published',
  ThemeColor = 'themeColor',
  OrganizationId = 'organizationId',
  PricePerRenewal = 'pricePerRenewal',
  BillingCycle = 'billingCycle',
  RenewalCycle = 'renewalCycle',
  AddonCancelsAt = 'addonCancelsAt',
  StripePriceId = 'stripePriceId',
  TargetSpecies = 'targetSpecies',
  EarliestTargetPetAgeInMonths = 'earliestTargetPetAgeInMonths',
  LatestTargetPetAgeInMonths = 'latestTargetPetAgeInMonths',
  EditorNotes = 'editorNotes',
}

export type CareAddonPackageScalarWhereInput = {
  AND?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  published?: Maybe<BoolFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  addonCancelsAt?: Maybe<DateTimeNullableFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
};

export type CareAddonPackageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  published?: Maybe<BoolWithAggregatesFilter>;
  themeColor?: Maybe<StringNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  pricePerRenewal?: Maybe<IntWithAggregatesFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  addonCancelsAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripePriceId?: Maybe<StringNullableWithAggregatesFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableWithAggregatesFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
};

export type CareAddonPackageSumAggregateOutputType = {
  __typename?: 'CareAddonPackageSumAggregateOutputType';
  pricePerRenewal?: Maybe<Scalars['Int']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageSumOrderByAggregateInput = {
  pricePerRenewal?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
};

export type CareAddonPackageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
};

export type CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedCreateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
};

export type CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput = {
  where: CareAddonPackageScalarWhereInput;
  data: CareAddonPackageUncheckedUpdateManyWithoutCareAddonPackageInput;
};

export type CareAddonPackageUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
};

export type CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitInput = {
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput>;
  upsert?: Maybe<CareAddonPackageUpsertWithoutAddonPackageBenefitInput>;
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
  update?: Maybe<CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput>;
};

export type CareAddonPackageUpdateOneRequiredWithoutEnrollmentsInput = {
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutEnrollmentsInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutEnrollmentsInput>;
  upsert?: Maybe<CareAddonPackageUpsertWithoutEnrollmentsInput>;
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
  update?: Maybe<CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput>;
};

export type CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: CareAddonPackageWhereUniqueInput;
  data: CareAddonPackageUncheckedUpdateWithoutOrganizationInput;
};

export type CareAddonPackageUpdateWithoutAddonPackageBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUpdateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageInput>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageInput>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
};

export type CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: CareAddonPackageWhereUniqueInput;
  update: CareAddonPackageUncheckedUpdateWithoutOrganizationInput;
  create: CareAddonPackageUncheckedCreateWithoutOrganizationInput;
};

export type CareAddonPackageUpsertWithoutAddonPackageBenefitInput = {
  update: CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput;
  create: CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageUpsertWithoutEnrollmentsInput = {
  update: CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput;
  create: CareAddonPackageUncheckedCreateWithoutEnrollmentsInput;
};

export type CareAddonPackageWhereInput = {
  AND?: Maybe<Array<CareAddonPackageWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  published?: Maybe<BoolFilter>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitListRelationFilter>;
  enrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  pricePerRenewal?: Maybe<IntFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  addonCancelsAt?: Maybe<DateTimeNullableFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
};

export type CareAddonPackageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareAuthorizationRequest = {
  __typename?: 'CareAuthorizationRequest';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  clinicPetParentId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestCountAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  expiresAt: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  email: Scalars['Int'];
  authCode: Scalars['Int'];
  claimed: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareAuthorizationRequestCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAuthorizationRequestsInput;
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope = {
  data: Array<CareAuthorizationRequestCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
};

export type CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput = {
  where: CareAuthorizationRequestWhereUniqueInput;
  create: CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput;
};

export type CareAuthorizationRequestCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestListRelationFilter = {
  every?: Maybe<CareAuthorizationRequestWhereInput>;
  some?: Maybe<CareAuthorizationRequestWhereInput>;
  none?: Maybe<CareAuthorizationRequestWhereInput>;
};

export type CareAuthorizationRequestMaxAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestMinAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAuthorizationRequestOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  AuthCode = 'authCode',
}

export type CareAuthorizationRequestOrderByRelevanceInput = {
  fields: Array<CareAuthorizationRequestOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareAuthorizationRequestOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  _count?: Maybe<CareAuthorizationRequestCountOrderByAggregateInput>;
  _max?: Maybe<CareAuthorizationRequestMaxOrderByAggregateInput>;
  _min?: Maybe<CareAuthorizationRequestMinOrderByAggregateInput>;
};

export type CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  _relevance?: Maybe<CareAuthorizationRequestOrderByRelevanceInput>;
};

export enum CareAuthorizationRequestScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ExpiresAt = 'expiresAt',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  AuthCode = 'authCode',
  Claimed = 'claimed',
}

export type CareAuthorizationRequestScalarWhereInput = {
  AND?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  authCode?: Maybe<StringFilter>;
  claimed?: Maybe<BoolFilter>;
};

export type CareAuthorizationRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  expiresAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  authCode?: Maybe<StringWithAggregatesFilter>;
  claimed?: Maybe<BoolWithAggregatesFilter>;
};

export type CareAuthorizationRequestUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
};

export type CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
};

export type CareAuthorizationRequestUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  delete?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  update?: Maybe<Array<CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
};

export type CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAuthorizationRequestsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: CareAuthorizationRequestScalarWhereInput;
  data: CareAuthorizationRequestUncheckedUpdateManyWithoutCareAuthorizationRequestsInput;
};

export type CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  delete?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  update?: Maybe<Array<CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
};

export type CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: CareAuthorizationRequestWhereUniqueInput;
  data: CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput;
};

export type CareAuthorizationRequestUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: CareAuthorizationRequestWhereUniqueInput;
  update: CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput;
  create: CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput;
};

export type CareAuthorizationRequestWhereInput = {
  AND?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  phoneNumber?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  authCode?: Maybe<StringFilter>;
  claimed?: Maybe<BoolFilter>;
};

export type CareAuthorizationRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefit = {
  __typename?: 'CareBenefit';
  id: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  organization: Organization;
  planBenefits: Array<CarePlanBenefit>;
  addonPackageBenefits: Array<CareAddonPackageBenefit>;
  usages: Array<CareBenefitUsage>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService: Array<OrganizationCareBenefitToClinicService>;
  editorNotes: Scalars['String'];
  exhaustible: Scalars['Boolean'];
  _count: CareBenefitCountOutputType;
};

export type CareBenefitPlanBenefitsArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanBenefitScalarFieldEnum>;
};

export type CareBenefitAddonPackageBenefitsArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonPackageBenefitScalarFieldEnum>;
};

export type CareBenefitUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};

export type CareBenefitOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>;
};

export type CareBenefitAvgAggregateOutputType = {
  __typename?: 'CareBenefitAvgAggregateOutputType';
  providedDiscountPercentage?: Maybe<Scalars['Float']>;
};

export type CareBenefitAvgOrderByAggregateInput = {
  providedDiscountPercentage?: Maybe<SortOrder>;
};

export type CareBenefitCountAggregateOutputType = {
  __typename?: 'CareBenefitCountAggregateOutputType';
  id: Scalars['Int'];
  title: Scalars['Int'];
  description: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  tangible: Scalars['Int'];
  providedDiscountPercentage: Scalars['Int'];
  organizationId: Scalars['Int'];
  usageUnitOfMeasure: Scalars['Int'];
  editorNotes: Scalars['Int'];
  exhaustible: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareBenefitCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
};

export type CareBenefitCountOutputType = {
  __typename?: 'CareBenefitCountOutputType';
  planBenefits: Scalars['Int'];
  addonPackageBenefits: Scalars['Int'];
  usages: Scalars['Int'];
  organizationCareBenefitToClinicService: Scalars['Int'];
};

export type CareBenefitCreateInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateManyOrganizationInputEnvelope = {
  data: Array<CareBenefitCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
};

export type CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateNestedOneWithoutPlanBenefitsInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutPlanBenefitsInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutPlanBenefitsInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateNestedOneWithoutUsagesInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutUsagesInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutUsagesInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
};

export type CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput;
};

export type CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type CareBenefitCreateOrConnectWithoutOrganizationInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutOrganizationInput;
};

export type CareBenefitCreateOrConnectWithoutPlanBenefitsInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutPlanBenefitsInput;
};

export type CareBenefitCreateOrConnectWithoutUsagesInput = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitUncheckedCreateWithoutUsagesInput;
};

export type CareBenefitCreateWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitListRelationFilter = {
  every?: Maybe<CareBenefitWhereInput>;
  some?: Maybe<CareBenefitWhereInput>;
  none?: Maybe<CareBenefitWhereInput>;
};

export type CareBenefitMaxAggregateOutputType = {
  __typename?: 'CareBenefitMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
};

export type CareBenefitMinAggregateOutputType = {
  __typename?: 'CareBenefitMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
};

export type CareBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  OrganizationId = 'organizationId',
  EditorNotes = 'editorNotes',
}

export type CareBenefitOrderByRelevanceInput = {
  fields: Array<CareBenefitOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareBenefitOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  _count?: Maybe<CareBenefitCountOrderByAggregateInput>;
  _avg?: Maybe<CareBenefitAvgOrderByAggregateInput>;
  _max?: Maybe<CareBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitSumOrderByAggregateInput>;
};

export type CareBenefitOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  planBenefits?: Maybe<CarePlanBenefitOrderByRelationAggregateInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitOrderByRelationAggregateInput>;
  usages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  _relevance?: Maybe<CareBenefitOrderByRelevanceInput>;
};

export type CareBenefitRelationFilter = {
  is?: Maybe<CareBenefitWhereInput>;
  isNot?: Maybe<CareBenefitWhereInput>;
};

export enum CareBenefitScalarFieldEnum {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Tangible = 'tangible',
  ProvidedDiscountPercentage = 'providedDiscountPercentage',
  OrganizationId = 'organizationId',
  UsageUnitOfMeasure = 'usageUnitOfMeasure',
  EditorNotes = 'editorNotes',
  Exhaustible = 'exhaustible',
}

export type CareBenefitScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  tangible?: Maybe<BoolFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  exhaustible?: Maybe<BoolFilter>;
};

export type CareBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  tangible?: Maybe<BoolWithAggregatesFilter>;
  providedDiscountPercentage?: Maybe<IntNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
  exhaustible?: Maybe<BoolWithAggregatesFilter>;
};

export type CareBenefitSumAggregateOutputType = {
  __typename?: 'CareBenefitSumAggregateOutputType';
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
};

export type CareBenefitSumOrderByAggregateInput = {
  providedDiscountPercentage?: Maybe<SortOrder>;
};

export type CareBenefitUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
};

export type CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedCreateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedCreateWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible: Scalars['Boolean'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedUpdateManyWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
};

export type CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedUpdateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUncheckedUpdateWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitInput
  >;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export enum CareBenefitUnitOfMeasure {
  Occurence = 'OCCURENCE',
  Cents = 'CENTS',
}

export type CareBenefitUpdateInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitInput>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUpdateManyWithWhereWithoutOrganizationInput = {
  where: CareBenefitScalarWhereInput;
  data: CareBenefitUncheckedUpdateManyWithoutCareBenefitsInput;
};

export type CareBenefitUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
};

export type CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutAddonPackageBenefitsInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput>;
};

export type CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutOrganizationCareBenefitToClinicServiceInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type CareBenefitUpdateOneRequiredWithoutPlanBenefitsInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutPlanBenefitsInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutPlanBenefitsInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutPlanBenefitsInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutPlanBenefitsInput>;
};

export type CareBenefitUpdateOneRequiredWithoutUsagesInput = {
  create?: Maybe<CareBenefitUncheckedCreateWithoutUsagesInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutUsagesInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutUsagesInput>;
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutUsagesInput>;
};

export type CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: CareBenefitWhereUniqueInput;
  data: CareBenefitUncheckedUpdateWithoutOrganizationInput;
};

export type CareBenefitUpdateWithoutAddonPackageBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitInput>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitInput>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUpdateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitInput>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitInput>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUpdateWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tangible?: Maybe<Scalars['Boolean']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitInput>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitInput>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: CareBenefitWhereUniqueInput;
  update: CareBenefitUncheckedUpdateWithoutOrganizationInput;
  create: CareBenefitUncheckedCreateWithoutOrganizationInput;
};

export type CareBenefitUpsertWithoutAddonPackageBenefitsInput = {
  update: CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput;
  create: CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput;
};

export type CareBenefitUpsertWithoutOrganizationCareBenefitToClinicServiceInput = {
  update: CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput;
  create: CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type CareBenefitUpsertWithoutPlanBenefitsInput = {
  update: CareBenefitUncheckedUpdateWithoutPlanBenefitsInput;
  create: CareBenefitUncheckedCreateWithoutPlanBenefitsInput;
};

export type CareBenefitUpsertWithoutUsagesInput = {
  update: CareBenefitUncheckedUpdateWithoutUsagesInput;
  create: CareBenefitUncheckedCreateWithoutUsagesInput;
};

export type CareBenefitUsage = {
  __typename?: 'CareBenefitUsage';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinic: Clinic;
  usageClinicPetId: Scalars['String'];
  usageClinicPet: ClinicPet;
  recordedByUserId?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<User>;
  benefitId: Scalars['String'];
  benefit: CareBenefit;
  invoiceLineItemConnections: Array<CareBenefitUsageToInvoiceLineItem>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollment>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollment>;
  _count: CareBenefitUsageCountOutputType;
};

export type CareBenefitUsageInvoiceLineItemConnectionsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};

export type CareBenefitUsageAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageAvgAggregateOutputType';
  quantityUsed?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageAvgOrderByAggregateInput = {
  quantityUsed?: Maybe<SortOrder>;
};

export type CareBenefitUsageCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  usedAt: Scalars['Int'];
  quantityUsed: Scalars['Int'];
  reason: Scalars['Int'];
  usageClinicId: Scalars['Int'];
  usageClinicPetId: Scalars['Int'];
  recordedByUserId: Scalars['Int'];
  benefitId: Scalars['Int'];
  exhaustsBenefit: Scalars['Int'];
  connectedPlanEnrollmentId: Scalars['Int'];
  connectedAddonEnrollmentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareBenefitUsageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
};

export type CareBenefitUsageCountOutputType = {
  __typename?: 'CareBenefitUsageCountOutputType';
  invoiceLineItemConnections: Scalars['Int'];
};

export type CareBenefitUsageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageCreateManyBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyBenefitInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyConnectedAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyConnectedPlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyRecordedByUserInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyRecordedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyUsageClinicInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyUsageClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageCreateManyUsageClinicPetInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyUsageClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutUsageClinicInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput = {
  create?: Maybe<CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput>;
  connectOrCreate?: Maybe<CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput>;
  connect?: Maybe<CareBenefitUsageWhereUniqueInput>;
};

export type CareBenefitUsageCreateOrConnectWithoutBenefitInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutBenefitInput;
};

export type CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput;
};

export type CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput;
};

export type CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput;
};

export type CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput;
};

export type CareBenefitUsageCreateOrConnectWithoutUsageClinicInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicInput;
};

export type CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput;
};

export type CareBenefitUsageCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageCreateWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageCreateWithoutRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageCreateWithoutUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageCreateWithoutUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageListRelationFilter = {
  every?: Maybe<CareBenefitUsageWhereInput>;
  some?: Maybe<CareBenefitUsageWhereInput>;
  none?: Maybe<CareBenefitUsageWhereInput>;
};

export type CareBenefitUsageMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
};

export type CareBenefitUsageMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
};

export type CareBenefitUsageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageOrderByRelevanceFieldEnum {
  Id = 'id',
  Reason = 'reason',
  UsageClinicId = 'usageClinicId',
  UsageClinicPetId = 'usageClinicPetId',
  RecordedByUserId = 'recordedByUserId',
  BenefitId = 'benefitId',
  ConnectedPlanEnrollmentId = 'connectedPlanEnrollmentId',
  ConnectedAddonEnrollmentId = 'connectedAddonEnrollmentId',
}

export type CareBenefitUsageOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareBenefitUsageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  _count?: Maybe<CareBenefitUsageCountOrderByAggregateInput>;
  _avg?: Maybe<CareBenefitUsageAvgOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageSumOrderByAggregateInput>;
};

export type CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  usageClinicPetId?: Maybe<SortOrder>;
  usageClinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  recordedByUserId?: Maybe<SortOrder>;
  recordedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  benefitId?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  exhaustsBenefit?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareBenefitUsageOrderByRelevanceInput>;
};

export type CareBenefitUsageRelationFilter = {
  is?: Maybe<CareBenefitUsageWhereInput>;
  isNot?: Maybe<CareBenefitUsageWhereInput>;
};

export enum CareBenefitUsageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UsedAt = 'usedAt',
  QuantityUsed = 'quantityUsed',
  Reason = 'reason',
  UsageClinicId = 'usageClinicId',
  UsageClinicPetId = 'usageClinicPetId',
  RecordedByUserId = 'recordedByUserId',
  BenefitId = 'benefitId',
  ExhaustsBenefit = 'exhaustsBenefit',
  ConnectedPlanEnrollmentId = 'connectedPlanEnrollmentId',
  ConnectedAddonEnrollmentId = 'connectedAddonEnrollmentId',
}

export type CareBenefitUsageScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usedAt?: Maybe<DateTimeFilter>;
  quantityUsed?: Maybe<IntNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  usageClinicId?: Maybe<StringFilter>;
  usageClinicPetId?: Maybe<StringFilter>;
  recordedByUserId?: Maybe<StringNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  exhaustsBenefit?: Maybe<BoolNullableFilter>;
  connectedPlanEnrollmentId?: Maybe<StringNullableFilter>;
  connectedAddonEnrollmentId?: Maybe<StringNullableFilter>;
};

export type CareBenefitUsageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  usedAt?: Maybe<DateTimeWithAggregatesFilter>;
  quantityUsed?: Maybe<IntNullableWithAggregatesFilter>;
  reason?: Maybe<StringNullableWithAggregatesFilter>;
  usageClinicId?: Maybe<StringWithAggregatesFilter>;
  usageClinicPetId?: Maybe<StringWithAggregatesFilter>;
  recordedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  exhaustsBenefit?: Maybe<BoolNullableWithAggregatesFilter>;
  connectedPlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  connectedAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareBenefitUsageSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageSumAggregateOutputType';
  quantityUsed?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageSumOrderByAggregateInput = {
  quantityUsed?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItem = {
  __typename?: 'CareBenefitUsageToInvoiceLineItem';
  id: Scalars['String'];
  careBenefitUsageId: Scalars['String'];
  careBenefitUsage: CareBenefitUsage;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItem>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  associatedInvoice?: Maybe<Invoice>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  confirmedBy?: Maybe<User>;
  draftServiceId?: Maybe<Scalars['String']>;
  draftService?: Maybe<Service>;
};

export type CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType';
  adjustedAmount?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageToInvoiceLineItemAvgOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemCareBenefitUsageIdInvoiceLineItemIdCompoundUniqueInput = {
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId: Scalars['String'];
};

export type CareBenefitUsageToInvoiceLineItemCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemCountAggregateOutputType';
  id: Scalars['Int'];
  careBenefitUsageId: Scalars['Int'];
  invoiceLineItemId: Scalars['Int'];
  associatedInvoiceId: Scalars['Int'];
  adjustedAmount: Scalars['Int'];
  confirmedByUserId: Scalars['Int'];
  draftServiceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareBenefitUsageToInvoiceLineItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount: Scalars['Int'];
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  adjustedAmount: Scalars['Int'];
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount: Scalars['Int'];
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount: Scalars['Int'];
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount: Scalars['Int'];
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount: Scalars['Int'];
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemListRelationFilter = {
  every?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  some?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  none?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};

export type CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageToInvoiceLineItemOrderByRelevanceFieldEnum {
  Id = 'id',
  CareBenefitUsageId = 'careBenefitUsageId',
  InvoiceLineItemId = 'invoiceLineItemId',
  AssociatedInvoiceId = 'associatedInvoiceId',
  ConfirmedByUserId = 'confirmedByUserId',
  DraftServiceId = 'draftServiceId',
}

export type CareBenefitUsageToInvoiceLineItemOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageToInvoiceLineItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareBenefitUsageToInvoiceLineItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
  _count?: Maybe<CareBenefitUsageToInvoiceLineItemCountOrderByAggregateInput>;
  _avg?: Maybe<CareBenefitUsageToInvoiceLineItemAvgOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageToInvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageToInvoiceLineItemMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageToInvoiceLineItemSumOrderByAggregateInput>;
};

export type CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  careBenefitUsage?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLineItemId?: Maybe<SortOrder>;
  invoiceLineItem?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  associatedInvoiceId?: Maybe<SortOrder>;
  associatedInvoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  adjustedAmount?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  confirmedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  draftServiceId?: Maybe<SortOrder>;
  draftService?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelevanceInput>;
};

export enum CareBenefitUsageToInvoiceLineItemScalarFieldEnum {
  Id = 'id',
  CareBenefitUsageId = 'careBenefitUsageId',
  InvoiceLineItemId = 'invoiceLineItemId',
  AssociatedInvoiceId = 'associatedInvoiceId',
  AdjustedAmount = 'adjustedAmount',
  ConfirmedByUserId = 'confirmedByUserId',
  DraftServiceId = 'draftServiceId',
}

export type CareBenefitUsageToInvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  careBenefitUsageId?: Maybe<StringFilter>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
  associatedInvoiceId?: Maybe<StringNullableFilter>;
  adjustedAmount?: Maybe<IntFilter>;
  confirmedByUserId?: Maybe<StringNullableFilter>;
  draftServiceId?: Maybe<StringNullableFilter>;
};

export type CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  careBenefitUsageId?: Maybe<StringWithAggregatesFilter>;
  invoiceLineItemId?: Maybe<StringNullableWithAggregatesFilter>;
  associatedInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  adjustedAmount?: Maybe<IntWithAggregatesFilter>;
  confirmedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  draftServiceId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareBenefitUsageToInvoiceLineItemSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemSumAggregateOutputType';
  adjustedAmount?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageToInvoiceLineItemSumOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount: Scalars['Int'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  adjustedAmount?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedCareBenefitCreditsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemConnectionsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageToInvoiceLineItemsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput = {
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConnectedCareBenefitsInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemInput = {
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutAssociatedInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsInput>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutCareBenefitUsageInput = {
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutConfirmedByInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutDraftServiceInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsInput>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsInput>;
  adjustedAmount?: Maybe<Scalars['Int']>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
};

export type CareBenefitUsageToInvoiceLineItemWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  id?: Maybe<StringFilter>;
  careBenefitUsageId?: Maybe<StringFilter>;
  careBenefitUsage?: Maybe<CareBenefitUsageWhereInput>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
  invoiceLineItem?: Maybe<InvoiceLineItemWhereInput>;
  associatedInvoiceId?: Maybe<StringNullableFilter>;
  associatedInvoice?: Maybe<InvoiceWhereInput>;
  adjustedAmount?: Maybe<IntFilter>;
  confirmedByUserId?: Maybe<StringNullableFilter>;
  confirmedBy?: Maybe<UserWhereInput>;
  draftServiceId?: Maybe<StringNullableFilter>;
  draftService?: Maybe<ServiceWhereInput>;
};

export type CareBenefitUsageToInvoiceLineItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  careBenefitUsageId_invoiceLineItemId?: Maybe<
    CareBenefitUsageToInvoiceLineItemCareBenefitUsageIdInvoiceLineItemIdCompoundUniqueInput
  >;
};

export type CareBenefitUsageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  invoiceLineItemConnections?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput
  >;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
};

export type CareBenefitUsageUncheckedCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput
  >;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  invoiceLineItemConnections?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput
  >;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  invoiceLineItemConnections?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput
  >;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  benefitId: Scalars['String'];
  invoiceLineItemConnections?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput
  >;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateWithoutUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPetId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  invoiceLineItemConnections?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput
  >;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt: Scalars['DateTime'];
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId: Scalars['String'];
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId: Scalars['String'];
  invoiceLineItemConnections?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput
  >;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutBenefitInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicId?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutUsagesInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutUsagesRecordedInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutBenefitsProvidedInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput = {
  where: CareBenefitUsageScalarWhereInput;
  data: CareBenefitUsageUncheckedUpdateManyWithoutCareBenefitUsagesInput;
};

export type CareBenefitUsageUpdateManyWithoutBenefitInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutRecordedByUserInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutUsageClinicInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateManyWithoutUsageClinicPetInput = {
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
};

export type CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsInput = {
  create?: Maybe<CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput>;
  connectOrCreate?: Maybe<CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput>;
  upsert?: Maybe<CareBenefitUsageUpsertWithoutInvoiceLineItemConnectionsInput>;
  connect?: Maybe<CareBenefitUsageWhereUniqueInput>;
  update?: Maybe<CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput>;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutBenefitInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput = {
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput;
};

export type CareBenefitUsageUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageUpdateWithoutConnectedAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageUpdateWithoutConnectedPlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageUpdateWithoutInvoiceLineItemConnectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageUpdateWithoutRecordedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageUpdateWithoutUsageClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageUpdateWithoutUsageClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedInput>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageInput>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesInput>;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutBenefitInput;
  create: CareBenefitUsageUncheckedCreateWithoutBenefitInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput;
  create: CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput;
  create: CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput;
  create: CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput;
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput = {
  where: CareBenefitUsageWhereUniqueInput;
  update: CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput;
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput;
};

export type CareBenefitUsageUpsertWithoutInvoiceLineItemConnectionsInput = {
  update: CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput;
  create: CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput;
};

export type CareBenefitUsageWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usedAt?: Maybe<DateTimeFilter>;
  quantityUsed?: Maybe<IntNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  usageClinicId?: Maybe<StringFilter>;
  usageClinic?: Maybe<ClinicWhereInput>;
  usageClinicPetId?: Maybe<StringFilter>;
  usageClinicPet?: Maybe<ClinicPetWhereInput>;
  recordedByUserId?: Maybe<StringNullableFilter>;
  recordedByUser?: Maybe<UserWhereInput>;
  benefitId?: Maybe<StringFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  exhaustsBenefit?: Maybe<BoolNullableFilter>;
  connectedPlanEnrollmentId?: Maybe<StringNullableFilter>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  connectedAddonEnrollmentId?: Maybe<StringNullableFilter>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
};

export type CareBenefitUsageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitWhereInput = {
  AND?: Maybe<Array<CareBenefitWhereInput>>;
  OR?: Maybe<Array<CareBenefitWhereInput>>;
  NOT?: Maybe<Array<CareBenefitWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  tangible?: Maybe<BoolFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  planBenefits?: Maybe<CarePlanBenefitListRelationFilter>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitListRelationFilter>;
  usages?: Maybe<CareBenefitUsageListRelationFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableFilter>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceListRelationFilter>;
  editorNotes?: Maybe<StringFilter>;
  exhaustible?: Maybe<BoolFilter>;
};

export type CareBenefitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareClinicInfo = {
  __typename?: 'CareClinicInfo';
  id: Scalars['String'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<CareOrganizationInfo>;
  enabledFeatureFlags: Array<Scalars['String']>;
};

export type CareEnrollmentPayment = {
  __typename?: 'CareEnrollmentPayment';
  id: Scalars['String'];
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollment>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollment>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoice>;
};

export type CareEnrollmentPaymentAvgAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type CareEnrollmentPaymentAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentCareAddonEnrollmentIdStripeInvoiceIdCompoundUniqueInput = {
  careAddonEnrollmentId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
};

export type CareEnrollmentPaymentCarePlanEnrollmentIdStripeInvoiceIdCompoundUniqueInput = {
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
};

export type CareEnrollmentPaymentCountAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentCountAggregateOutputType';
  id: Scalars['Int'];
  amount: Scalars['Int'];
  paidAt: Scalars['Int'];
  careAddonEnrollmentId: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareEnrollmentPaymentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateManyCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateManyCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyStripeInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput;
};

export type CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
};

export type CareEnrollmentPaymentListRelationFilter = {
  every?: Maybe<CareEnrollmentPaymentWhereInput>;
  some?: Maybe<CareEnrollmentPaymentWhereInput>;
  none?: Maybe<CareEnrollmentPaymentWhereInput>;
};

export type CareEnrollmentPaymentMaxAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentMinAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareEnrollmentPaymentOrderByRelevanceFieldEnum {
  Id = 'id',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  StripeInvoiceId = 'stripeInvoiceId',
}

export type CareEnrollmentPaymentOrderByRelevanceInput = {
  fields: Array<CareEnrollmentPaymentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareEnrollmentPaymentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  _count?: Maybe<CareEnrollmentPaymentCountOrderByAggregateInput>;
  _avg?: Maybe<CareEnrollmentPaymentAvgOrderByAggregateInput>;
  _max?: Maybe<CareEnrollmentPaymentMaxOrderByAggregateInput>;
  _min?: Maybe<CareEnrollmentPaymentMinOrderByAggregateInput>;
  _sum?: Maybe<CareEnrollmentPaymentSumOrderByAggregateInput>;
};

export type CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareEnrollmentPaymentOrderByRelevanceInput>;
};

export enum CareEnrollmentPaymentScalarFieldEnum {
  Id = 'id',
  Amount = 'amount',
  PaidAt = 'paidAt',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  StripeInvoiceId = 'stripeInvoiceId',
}

export type CareEnrollmentPaymentScalarWhereInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  paidAt?: Maybe<DateTimeFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
};

export type CareEnrollmentPaymentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeWithAggregatesFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareEnrollmentPaymentSumAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type CareEnrollmentPaymentSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paidAt: Scalars['DateTime'];
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentPaymentScalarWhereInput;
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentPaymentScalarWhereInput;
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput = {
  where: CareEnrollmentPaymentScalarWhereInput;
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentInput;
};

export type CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  data: CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  data: CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  data: CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput;
};

export type CareEnrollmentPaymentUpdateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsInput>;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput;
  create: CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput;
};

export type CareEnrollmentPaymentWhereInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  paidAt?: Maybe<DateTimeFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
};

export type CareEnrollmentPaymentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId_stripeInvoiceId?: Maybe<
    CareEnrollmentPaymentCareAddonEnrollmentIdStripeInvoiceIdCompoundUniqueInput
  >;
  carePlanEnrollmentId_stripeInvoiceId?: Maybe<
    CareEnrollmentPaymentCarePlanEnrollmentIdStripeInvoiceIdCompoundUniqueInput
  >;
};

export type CareEnrollmentToStripeInvoiceItem = {
  __typename?: 'CareEnrollmentToStripeInvoiceItem';
  id: Scalars['String'];
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollment>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollment>;
  stripeInvoiceItemId: Scalars['String'];
  stripeInvoiceItem: StripeInvoiceItem;
};

export type CareEnrollmentToStripeInvoiceItemCountAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemCountAggregateOutputType';
  id: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  careAddonEnrollmentId: Scalars['Int'];
  stripeInvoiceItemId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareEnrollmentToStripeInvoiceItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareEnrollmentToStripeInvoiceItemListRelationFilter = {
  every?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  some?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  none?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};

export type CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemMinAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareEnrollmentToStripeInvoiceItemOrderByRelevanceFieldEnum {
  Id = 'id',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  StripeInvoiceItemId = 'stripeInvoiceItemId',
}

export type CareEnrollmentToStripeInvoiceItemOrderByRelevanceInput = {
  fields: Array<CareEnrollmentToStripeInvoiceItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
  _count?: Maybe<CareEnrollmentToStripeInvoiceItemCountOrderByAggregateInput>;
  _max?: Maybe<CareEnrollmentToStripeInvoiceItemMaxOrderByAggregateInput>;
  _min?: Maybe<CareEnrollmentToStripeInvoiceItemMinOrderByAggregateInput>;
};

export type CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelevanceInput>;
};

export enum CareEnrollmentToStripeInvoiceItemScalarFieldEnum {
  Id = 'id',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  StripeInvoiceItemId = 'stripeInvoiceItemId',
}

export type CareEnrollmentToStripeInvoiceItemScalarWhereInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  stripeInvoiceItemId?: Maybe<StringFilter>;
};

export type CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceItemId?: Maybe<StringWithAggregatesFilter>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput = {
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemInput = {
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutCareAddonEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutCarePlanEnrollmentInput = {
  id?: Maybe<Scalars['String']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutStripeInvoiceItemInput = {
  id?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput;
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput;
};

export type CareEnrollmentToStripeInvoiceItemWhereInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  id?: Maybe<StringFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
  stripeInvoiceItemId?: Maybe<StringFilter>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemWhereInput>;
};

export type CareEnrollmentToStripeInvoiceItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareOrganizationInfo = {
  __typename?: 'CareOrganizationInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CarePlan = {
  __typename?: 'CarePlan';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organization: Organization;
  enrollments: Array<CarePlanEnrollment>;
  planBenefits: Array<CarePlanBenefit>;
  pricePerRenewal: Scalars['Int'];
  billingCycle: CarePlanCycleType;
  renewalCycle: CarePlanCycleType;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  /** Note: we will eventually transform this into proper models once the marketing information gets more nailed down */
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes: Scalars['String'];
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  _count: CarePlanCountOutputType;
};

export type CarePlanEnrollmentsArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
};

export type CarePlanPlanBenefitsArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanBenefitScalarFieldEnum>;
};

export type CarePlanAvgAggregateOutputType = {
  __typename?: 'CarePlanAvgAggregateOutputType';
  pricePerRenewal?: Maybe<Scalars['Float']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  signupFeePricing?: Maybe<Scalars['Float']>;
};

export type CarePlanAvgOrderByAggregateInput = {
  pricePerRenewal?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
};

export type CarePlanBenefit = {
  __typename?: 'CarePlanBenefit';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  benefit: CareBenefit;
  planId: Scalars['String'];
  plan: CarePlan;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitAvgAggregateOutputType = {
  __typename?: 'CarePlanBenefitAvgAggregateOutputType';
  includedUses?: Maybe<Scalars['Float']>;
  displayOrder?: Maybe<Scalars['Float']>;
};

export type CarePlanBenefitAvgOrderByAggregateInput = {
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type CarePlanBenefitCountAggregateOutputType = {
  __typename?: 'CarePlanBenefitCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  includedUses: Scalars['Int'];
  displayOrder: Scalars['Int'];
  benefitId: Scalars['Int'];
  planId: Scalars['Int'];
  projectedSavings: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CarePlanBenefitCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CarePlanBenefitCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutPlanBenefitsInput;
  plan: CarePlanCreateNestedOneWithoutPlanBenefitsInput;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitCreateManyBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitCreateManyBenefitInputEnvelope = {
  data: Array<CarePlanBenefitCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitCreateManyPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitCreateManyPlanInputEnvelope = {
  data: Array<CarePlanBenefitCreateManyPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
};

export type CarePlanBenefitCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
};

export type CarePlanBenefitCreateOrConnectWithoutBenefitInput = {
  where: CarePlanBenefitWhereUniqueInput;
  create: CarePlanBenefitUncheckedCreateWithoutBenefitInput;
};

export type CarePlanBenefitCreateOrConnectWithoutPlanInput = {
  where: CarePlanBenefitWhereUniqueInput;
  create: CarePlanBenefitUncheckedCreateWithoutPlanInput;
};

export type CarePlanBenefitCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  plan: CarePlanCreateNestedOneWithoutPlanBenefitsInput;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutPlanBenefitsInput;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitListRelationFilter = {
  every?: Maybe<CarePlanBenefitWhereInput>;
  some?: Maybe<CarePlanBenefitWhereInput>;
  none?: Maybe<CarePlanBenefitWhereInput>;
};

export type CarePlanBenefitMaxAggregateOutputType = {
  __typename?: 'CarePlanBenefitMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CarePlanBenefitMinAggregateOutputType = {
  __typename?: 'CarePlanBenefitMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
};

export type CarePlanBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanBenefitOrderByRelevanceFieldEnum {
  Id = 'id',
  BenefitId = 'benefitId',
  PlanId = 'planId',
  ProjectedSavings = 'projectedSavings',
}

export type CarePlanBenefitOrderByRelevanceInput = {
  fields: Array<CarePlanBenefitOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CarePlanBenefitOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  _count?: Maybe<CarePlanBenefitCountOrderByAggregateInput>;
  _avg?: Maybe<CarePlanBenefitAvgOrderByAggregateInput>;
  _max?: Maybe<CarePlanBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CarePlanBenefitSumOrderByAggregateInput>;
};

export type CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  planId?: Maybe<SortOrder>;
  plan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  projectedSavings?: Maybe<SortOrder>;
  _relevance?: Maybe<CarePlanBenefitOrderByRelevanceInput>;
};

export type CarePlanBenefitPlanIdDisplayOrderCompoundUniqueInput = {
  planId: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export enum CarePlanBenefitScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IncludedUses = 'includedUses',
  DisplayOrder = 'displayOrder',
  BenefitId = 'benefitId',
  PlanId = 'planId',
  ProjectedSavings = 'projectedSavings',
}

export type CarePlanBenefitScalarWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  planId?: Maybe<StringFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
};

export type CarePlanBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  includedUses?: Maybe<IntNullableWithAggregatesFilter>;
  displayOrder?: Maybe<IntNullableWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  planId?: Maybe<StringWithAggregatesFilter>;
  projectedSavings?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CarePlanBenefitSumAggregateOutputType = {
  __typename?: 'CarePlanBenefitSumAggregateOutputType';
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitSumOrderByAggregateInput = {
  includedUses?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type CarePlanBenefitUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
};

export type CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
};

export type CarePlanBenefitUncheckedCreateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutBenefitInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
};

export type CarePlanBenefitUncheckedUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUncheckedUpdateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutPlanBenefitsInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutPlanBenefitsInput>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput = {
  where: CarePlanBenefitScalarWhereInput;
  data: CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput;
};

export type CarePlanBenefitUpdateManyWithWhereWithoutPlanInput = {
  where: CarePlanBenefitScalarWhereInput;
  data: CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput;
};

export type CarePlanBenefitUpdateManyWithoutBenefitInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
};

export type CarePlanBenefitUpdateManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
};

export type CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput = {
  where: CarePlanBenefitWhereUniqueInput;
  data: CarePlanBenefitUncheckedUpdateWithoutBenefitInput;
};

export type CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput = {
  where: CarePlanBenefitWhereUniqueInput;
  data: CarePlanBenefitUncheckedUpdateWithoutPlanInput;
};

export type CarePlanBenefitUpdateWithoutBenefitInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutPlanBenefitsInput>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUpdateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includedUses?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutPlanBenefitsInput>;
  projectedSavings?: Maybe<Scalars['String']>;
};

export type CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput = {
  where: CarePlanBenefitWhereUniqueInput;
  update: CarePlanBenefitUncheckedUpdateWithoutBenefitInput;
  create: CarePlanBenefitUncheckedCreateWithoutBenefitInput;
};

export type CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput = {
  where: CarePlanBenefitWhereUniqueInput;
  update: CarePlanBenefitUncheckedUpdateWithoutPlanInput;
  create: CarePlanBenefitUncheckedCreateWithoutPlanInput;
};

export type CarePlanBenefitWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  benefitId?: Maybe<StringFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  planId?: Maybe<StringFilter>;
  plan?: Maybe<CarePlanWhereInput>;
  projectedSavings?: Maybe<StringNullableFilter>;
};

export type CarePlanBenefitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  planId_displayOrder?: Maybe<CarePlanBenefitPlanIdDisplayOrderCompoundUniqueInput>;
};

export type CarePlanCountAggregateOutputType = {
  __typename?: 'CarePlanCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  title: Scalars['Int'];
  description: Scalars['Int'];
  published: Scalars['Int'];
  themeColor: Scalars['Int'];
  organizationId: Scalars['Int'];
  pricePerRenewal: Scalars['Int'];
  billingCycle: Scalars['Int'];
  renewalCycle: Scalars['Int'];
  stripePriceId: Scalars['Int'];
  targetSpecies: Scalars['Int'];
  earliestTargetPetAgeInMonths: Scalars['Int'];
  latestTargetPetAgeInMonths: Scalars['Int'];
  marketingInformation: Scalars['Int'];
  editorNotes: Scalars['Int'];
  signupFeePricing: Scalars['Int'];
  signupFeeStripeProductId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CarePlanCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
};

export type CarePlanCountOutputType = {
  __typename?: 'CarePlanCountOutputType';
  enrollments: Scalars['Int'];
  planBenefits: Scalars['Int'];
};

export type CarePlanCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanCreateManyOrganizationInputEnvelope = {
  data: Array<CarePlanCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
};

export type CarePlanCreateNestedOneWithoutEnrollmentsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutEnrollmentsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutEnrollmentsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateNestedOneWithoutPlanBenefitsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutPlanBenefitsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutPlanBenefitsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
};

export type CarePlanCreateOrConnectWithoutEnrollmentsInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutEnrollmentsInput;
};

export type CarePlanCreateOrConnectWithoutOrganizationInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutOrganizationInput;
};

export type CarePlanCreateOrConnectWithoutPlanBenefitsInput = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanUncheckedCreateWithoutPlanBenefitsInput;
};

export type CarePlanCreateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanCreateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export enum CarePlanCycleType {
  Monthly = 'Monthly',
  Semianually = 'Semianually',
  Annually = 'Annually',
}

export type CarePlanEnrollment = {
  __typename?: 'CarePlanEnrollment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
  organizationPetId: Scalars['String'];
  organizationPet: OrganizationPet;
  planId: Scalars['String'];
  plan: CarePlan;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careStripeSubscription?: Maybe<CareStripeSubscription>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  enrollmentPayments: Array<CareEnrollmentPayment>;
  associatedUsages: Array<CareBenefitUsage>;
  _count: CarePlanEnrollmentCountOutputType;
};

export type CarePlanEnrollmentEnrollmentToStripeInvoiceItemsArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
};

export type CarePlanEnrollmentEnrollmentPaymentsArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
};

export type CarePlanEnrollmentAssociatedUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};

export type CarePlanEnrollmentCountAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  startDate: Scalars['Int'];
  expirationDate: Scalars['Int'];
  status: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  planId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CarePlanEnrollmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentCountOutputType = {
  __typename?: 'CarePlanEnrollmentCountOutputType';
  enrollmentToStripeInvoiceItems: Scalars['Int'];
  enrollmentPayments: Scalars['Int'];
  associatedUsages: Scalars['Int'];
};

export type CarePlanEnrollmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateManyCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentCreateManyOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentCreateManyPlanInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
};

export type CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutPlanInput;
};

export type CarePlanEnrollmentCreateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentListRelationFilter = {
  every?: Maybe<CarePlanEnrollmentWhereInput>;
  some?: Maybe<CarePlanEnrollmentWhereInput>;
  none?: Maybe<CarePlanEnrollmentWhereInput>;
};

export type CarePlanEnrollmentMaxAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentMinAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanEnrollmentOrderByRelevanceFieldEnum {
  Id = 'id',
  OrganizationPetId = 'organizationPetId',
  PlanId = 'planId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
}

export type CarePlanEnrollmentOrderByRelevanceInput = {
  fields: Array<CarePlanEnrollmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CarePlanEnrollmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  _count?: Maybe<CarePlanEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<CarePlanEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanEnrollmentMinOrderByAggregateInput>;
};

export type CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  planId?: Maybe<SortOrder>;
  plan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  associatedUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  _relevance?: Maybe<CarePlanEnrollmentOrderByRelevanceInput>;
};

export type CarePlanEnrollmentRelationFilter = {
  is?: Maybe<CarePlanEnrollmentWhereInput>;
  isNot?: Maybe<CarePlanEnrollmentWhereInput>;
};

export enum CarePlanEnrollmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StartDate = 'startDate',
  ExpirationDate = 'expirationDate',
  Status = 'status',
  OrganizationPetId = 'organizationPetId',
  PlanId = 'planId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
}

export type CarePlanEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  organizationPetId?: Maybe<StringFilter>;
  planId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
};

export type CarePlanEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumEnrollmentStatusWithAggregatesFilter>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  planId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CarePlanEnrollmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  planId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentInput
  >;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  planId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPetId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentInput
  >;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  where: CarePlanEnrollmentScalarWhereInput;
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput = {
  where: CarePlanEnrollmentScalarWhereInput;
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput = {
  where: CarePlanEnrollmentScalarWhereInput;
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput;
};

export type CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutPlanInput = {
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
};

export type CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutAssociatedUsagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutEnrollmentPaymentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUncheckedUpdateWithoutPlanInput;
};

export type CarePlanEnrollmentUpdateWithoutAssociatedUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateWithoutEnrollmentPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentInput>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentInput>;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput = {
  where: CarePlanEnrollmentWhereUniqueInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutPlanInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutPlanInput;
};

export type CarePlanEnrollmentUpsertWithoutAssociatedUsagesInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
};

export type CarePlanEnrollmentUpsertWithoutEnrollmentPaymentsInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
};

export type CarePlanEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  update: CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CarePlanEnrollmentWhereInput = {
  AND?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  organizationPetId?: Maybe<StringFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  planId?: Maybe<StringFilter>;
  plan?: Maybe<CarePlanWhereInput>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentListRelationFilter>;
  associatedUsages?: Maybe<CareBenefitUsageListRelationFilter>;
};

export type CarePlanEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CarePlanListRelationFilter = {
  every?: Maybe<CarePlanWhereInput>;
  some?: Maybe<CarePlanWhereInput>;
  none?: Maybe<CarePlanWhereInput>;
};

export type CarePlanMaxAggregateOutputType = {
  __typename?: 'CarePlanMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
};

export type CarePlanMinAggregateOutputType = {
  __typename?: 'CarePlanMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
};

export type CarePlanOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Description = 'description',
  ThemeColor = 'themeColor',
  OrganizationId = 'organizationId',
  StripePriceId = 'stripePriceId',
  EditorNotes = 'editorNotes',
  SignupFeeStripeProductId = 'signupFeeStripeProductId',
}

export type CarePlanOrderByRelevanceInput = {
  fields: Array<CarePlanOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CarePlanOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  _count?: Maybe<CarePlanCountOrderByAggregateInput>;
  _avg?: Maybe<CarePlanAvgOrderByAggregateInput>;
  _max?: Maybe<CarePlanMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanMinOrderByAggregateInput>;
  _sum?: Maybe<CarePlanSumOrderByAggregateInput>;
};

export type CarePlanOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  enrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  planBenefits?: Maybe<CarePlanBenefitOrderByRelationAggregateInput>;
  pricePerRenewal?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  _relevance?: Maybe<CarePlanOrderByRelevanceInput>;
};

export type CarePlanRelationFilter = {
  is?: Maybe<CarePlanWhereInput>;
  isNot?: Maybe<CarePlanWhereInput>;
};

export enum CarePlanScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Description = 'description',
  Published = 'published',
  ThemeColor = 'themeColor',
  OrganizationId = 'organizationId',
  PricePerRenewal = 'pricePerRenewal',
  BillingCycle = 'billingCycle',
  RenewalCycle = 'renewalCycle',
  StripePriceId = 'stripePriceId',
  TargetSpecies = 'targetSpecies',
  EarliestTargetPetAgeInMonths = 'earliestTargetPetAgeInMonths',
  LatestTargetPetAgeInMonths = 'latestTargetPetAgeInMonths',
  MarketingInformation = 'marketingInformation',
  EditorNotes = 'editorNotes',
  SignupFeePricing = 'signupFeePricing',
  SignupFeeStripeProductId = 'signupFeeStripeProductId',
}

export type CarePlanScalarWhereInput = {
  AND?: Maybe<Array<CarePlanScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  published?: Maybe<BoolFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  marketingInformation?: Maybe<JsonNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  signupFeePricing?: Maybe<IntNullableFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableFilter>;
};

export type CarePlanScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  published?: Maybe<BoolWithAggregatesFilter>;
  themeColor?: Maybe<StringNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  pricePerRenewal?: Maybe<IntWithAggregatesFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  stripePriceId?: Maybe<StringNullableWithAggregatesFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableWithAggregatesFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  marketingInformation?: Maybe<JsonNullableWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
  signupFeePricing?: Maybe<IntNullableWithAggregatesFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CarePlanSumAggregateOutputType = {
  __typename?: 'CarePlanSumAggregateOutputType';
  pricePerRenewal?: Maybe<Scalars['Int']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
};

export type CarePlanSumOrderByAggregateInput = {
  pricePerRenewal?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
};

export type CarePlanUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
};

export type CarePlanUncheckedCreateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedCreateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  published: Scalars['Boolean'];
  themeColor?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedUpdateManyWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
};

export type CarePlanUncheckedUpdateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUncheckedUpdateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUpdateManyWithWhereWithoutOrganizationInput = {
  where: CarePlanScalarWhereInput;
  data: CarePlanUncheckedUpdateManyWithoutCarePlansInput;
};

export type CarePlanUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
};

export type CarePlanUpdateOneRequiredWithoutEnrollmentsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutEnrollmentsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutEnrollmentsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutEnrollmentsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutEnrollmentsInput>;
};

export type CarePlanUpdateOneRequiredWithoutPlanBenefitsInput = {
  create?: Maybe<CarePlanUncheckedCreateWithoutPlanBenefitsInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutPlanBenefitsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutPlanBenefitsInput>;
  connect?: Maybe<CarePlanWhereUniqueInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutPlanBenefitsInput>;
};

export type CarePlanUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: CarePlanWhereUniqueInput;
  data: CarePlanUncheckedUpdateWithoutOrganizationInput;
};

export type CarePlanUpdateWithoutEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUpdateWithoutPlanBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansInput>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  editorNotes?: Maybe<Scalars['String']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
};

export type CarePlanUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: CarePlanWhereUniqueInput;
  update: CarePlanUncheckedUpdateWithoutOrganizationInput;
  create: CarePlanUncheckedCreateWithoutOrganizationInput;
};

export type CarePlanUpsertWithoutEnrollmentsInput = {
  update: CarePlanUncheckedUpdateWithoutEnrollmentsInput;
  create: CarePlanUncheckedCreateWithoutEnrollmentsInput;
};

export type CarePlanUpsertWithoutPlanBenefitsInput = {
  update: CarePlanUncheckedUpdateWithoutPlanBenefitsInput;
  create: CarePlanUncheckedCreateWithoutPlanBenefitsInput;
};

export type CarePlanWhereInput = {
  AND?: Maybe<Array<CarePlanWhereInput>>;
  OR?: Maybe<Array<CarePlanWhereInput>>;
  NOT?: Maybe<Array<CarePlanWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  published?: Maybe<BoolFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  enrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  planBenefits?: Maybe<CarePlanBenefitListRelationFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  marketingInformation?: Maybe<JsonNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  signupFeePricing?: Maybe<IntNullableFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableFilter>;
};

export type CarePlanWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareStripeSubscription = {
  __typename?: 'CareStripeSubscription';
  id: Scalars['String'];
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments: Array<CareAddonEnrollment>;
  carePlanEnrollments: Array<CarePlanEnrollment>;
  activeSubscriber?: Maybe<OrganizationPetParent>;
  subscriptionOwnerId: Scalars['String'];
  subscriptionOwner: OrganizationPetParent;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  invoices: Array<StripeInvoice>;
  _count: CareStripeSubscriptionCountOutputType;
};

export type CareStripeSubscriptionCareAddonEnrollmentsArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
};

export type CareStripeSubscriptionCarePlanEnrollmentsArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
};

export type CareStripeSubscriptionInvoicesArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
};

export type CareStripeSubscriptionAvgAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionAvgAggregateOutputType';
  upcomingTotalPrice?: Maybe<Scalars['Float']>;
};

export type CareStripeSubscriptionAvgOrderByAggregateInput = {
  upcomingTotalPrice?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionCountAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionCountAggregateOutputType';
  id: Scalars['Int'];
  paymentStatus: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  stripeSubscriptionId: Scalars['Int'];
  subscriptionOwnerId: Scalars['Int'];
  nextBillingDate: Scalars['Int'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CareStripeSubscriptionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionCountOutputType = {
  __typename?: 'CareStripeSubscriptionCountOutputType';
  careAddonEnrollments: Scalars['Int'];
  carePlanEnrollments: Scalars['Int'];
  invoices: Scalars['Int'];
};

export type CareStripeSubscriptionCreateInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionCreateManyStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
};

export type CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateManySubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManySubscriptionOwnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
};

export type CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput;
};

export type CareStripeSubscriptionCreateWithoutActiveSubscriberInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
};

export type CareStripeSubscriptionCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionListRelationFilter = {
  every?: Maybe<CareStripeSubscriptionWhereInput>;
  some?: Maybe<CareStripeSubscriptionWhereInput>;
  none?: Maybe<CareStripeSubscriptionWhereInput>;
};

export type CareStripeSubscriptionMaxAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionMinAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareStripeSubscriptionOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeCustomerId = 'stripeCustomerId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  SubscriptionOwnerId = 'subscriptionOwnerId',
  StripePaymentMethodId = 'stripePaymentMethodId',
}

export type CareStripeSubscriptionOrderByRelevanceInput = {
  fields: Array<CareStripeSubscriptionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type CareStripeSubscriptionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  _count?: Maybe<CareStripeSubscriptionCountOrderByAggregateInput>;
  _avg?: Maybe<CareStripeSubscriptionAvgOrderByAggregateInput>;
  _max?: Maybe<CareStripeSubscriptionMaxOrderByAggregateInput>;
  _min?: Maybe<CareStripeSubscriptionMinOrderByAggregateInput>;
  _sum?: Maybe<CareStripeSubscriptionSumOrderByAggregateInput>;
};

export type CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  activeSubscriber?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  subscriptionOwner?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  nextBillingDate?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  invoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<CareStripeSubscriptionOrderByRelevanceInput>;
};

export type CareStripeSubscriptionRelationFilter = {
  is?: Maybe<CareStripeSubscriptionWhereInput>;
  isNot?: Maybe<CareStripeSubscriptionWhereInput>;
};

export enum CareStripeSubscriptionScalarFieldEnum {
  Id = 'id',
  PaymentStatus = 'paymentStatus',
  StripeCustomerId = 'stripeCustomerId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  SubscriptionOwnerId = 'subscriptionOwnerId',
  NextBillingDate = 'nextBillingDate',
  UpcomingTotalPrice = 'upcomingTotalPrice',
  StripePaymentMethodId = 'stripePaymentMethodId',
}

export type CareStripeSubscriptionScalarWhereInput = {
  AND?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripeSubscriptionId?: Maybe<StringFilter>;
  subscriptionOwnerId?: Maybe<StringFilter>;
  nextBillingDate?: Maybe<DateTimeFilter>;
  upcomingTotalPrice?: Maybe<IntFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
};

export type CareStripeSubscriptionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringWithAggregatesFilter>;
  stripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  subscriptionOwnerId?: Maybe<StringWithAggregatesFilter>;
  nextBillingDate?: Maybe<DateTimeWithAggregatesFilter>;
  upcomingTotalPrice?: Maybe<IntWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareStripeSubscriptionSumAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionSumAggregateOutputType';
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
};

export type CareStripeSubscriptionSumOrderByAggregateInput = {
  upcomingTotalPrice?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId: Scalars['String'];
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  nextBillingDate: Scalars['DateTime'];
  upcomingTotalPrice: Scalars['Int'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsInput>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  where: CareStripeSubscriptionScalarWhereInput;
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutCareStripeSubscriptionsInput;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput = {
  where: CareStripeSubscriptionScalarWhereInput;
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutAllCareStripeSubscriptionsInput;
};

export type CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerInput = {
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
};

export type CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutInvoicesInput>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutActiveSubscriberInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutActiveSubscriberInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCareAddonEnrollmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsInput = {
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCarePlanEnrollmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput>;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  data: CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  data: CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput;
};

export type CareStripeSubscriptionUpdateWithoutActiveSubscriberInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsInput>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUpdateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsInput>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUpdateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsInput>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsInput>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsInput>;
};

export type CareStripeSubscriptionUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsInput>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUpdateWithoutSubscriptionOwnerInput = {
  id?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionInput>;
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionInput>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionInput>;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput = {
  where: CareStripeSubscriptionWhereUniqueInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput;
};

export type CareStripeSubscriptionUpsertWithoutActiveSubscriberInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput;
};

export type CareStripeSubscriptionUpsertWithoutCareAddonEnrollmentsInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput;
};

export type CareStripeSubscriptionUpsertWithoutCarePlanEnrollmentsInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput;
};

export type CareStripeSubscriptionUpsertWithoutInvoicesInput = {
  update: CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput;
  create: CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput;
};

export type CareStripeSubscriptionWhereInput = {
  AND?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  id?: Maybe<StringFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripeSubscriptionId?: Maybe<StringFilter>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  activeSubscriber?: Maybe<OrganizationPetParentWhereInput>;
  subscriptionOwnerId?: Maybe<StringFilter>;
  subscriptionOwner?: Maybe<OrganizationPetParentWhereInput>;
  nextBillingDate?: Maybe<DateTimeFilter>;
  upcomingTotalPrice?: Maybe<IntFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  invoices?: Maybe<StripeInvoiceListRelationFilter>;
};

export type CareStripeSubscriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
};

export enum CareSubscriptionPaymentStatus {
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  PastDue = 'PastDue',
}

export type ChangeCareSubscriptionPaymentMethodInput = {
  careStripeSubscriptionId: Scalars['String'];
  careStripePaymentMethodId: Scalars['String'];
};

export type ChangeCareSubscriptionPaymentMethodOutput = {
  __typename?: 'ChangeCareSubscriptionPaymentMethodOutput';
  success: Scalars['Boolean'];
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  currentWorkflowAction?: Maybe<WorkflowEventAction>;
  lastMessage?: Maybe<ChannelMessage>;
  channelStatus?: Maybe<ChannelStatus>;
  clinic?: Maybe<Clinic>;
  appointments: Array<Appointment>;
  channelMembers: Array<ChannelMember>;
  pets: Array<ClinicPet>;
  messages: Array<ChannelMessage>;
  assignees: Array<User>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions: Array<FinancialTransaction>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses: Array<ChannelAutomationStatus>;
  creationSource?: Maybe<ChannelCreationSource>;
  _count: ChannelCountOutputType;
};

export type ChannelAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type ChannelChannelMembersArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
};

export type ChannelPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type ChannelMessagesArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
};

export type ChannelAssigneesArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};

export type ChannelFinancialTransactionsArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
};

export type ChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};

export type ChannelAutomationStatus = {
  __typename?: 'ChannelAutomationStatus';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status: AutomationStatus;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  channel: Channel;
  workflowEventSetting: WorkflowEventSetting;
  clinicPet?: Maybe<ClinicPet>;
  currentWorkflowEventAction?: Maybe<WorkflowEventAction>;
  appointment?: Maybe<Appointment>;
  formSubmission?: Maybe<FormSubmission>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  serviceReminder?: Maybe<ServiceReminder>;
};

export type ChannelAutomationStatusCountAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  cancelledAt: Scalars['Int'];
  startedAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  expiredAt: Scalars['Int'];
  channelId: Scalars['Int'];
  status: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
  currentWorkflowEventActionId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  clinicWidgetRequestTypeId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelAutomationStatusCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyAppointmentInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyChannelInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyClinicPetInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyServiceReminderInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutChannelInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutChannelInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type ChannelAutomationStatusCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusListRelationFilter = {
  every?: Maybe<ChannelAutomationStatusWhereInput>;
  some?: Maybe<ChannelAutomationStatusWhereInput>;
  none?: Maybe<ChannelAutomationStatusWhereInput>;
};

export type ChannelAutomationStatusMaxAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusMinAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelAutomationStatusOrderByRelevanceFieldEnum {
  Id = 'id',
  ChannelId = 'channelId',
  WorkflowEventSettingId = 'workflowEventSettingId',
  CurrentWorkflowEventActionId = 'currentWorkflowEventActionId',
  AppointmentId = 'appointmentId',
  FormSubmissionId = 'formSubmissionId',
  ServiceReminderId = 'serviceReminderId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicPetId = 'clinicPetId',
}

export type ChannelAutomationStatusOrderByRelevanceInput = {
  fields: Array<ChannelAutomationStatusOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelAutomationStatusOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  _count?: Maybe<ChannelAutomationStatusCountOrderByAggregateInput>;
  _max?: Maybe<ChannelAutomationStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelAutomationStatusMinOrderByAggregateInput>;
};

export type ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelAutomationStatusOrderByRelevanceInput>;
};

export enum ChannelAutomationStatusScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CancelledAt = 'cancelledAt',
  StartedAt = 'startedAt',
  CompletedAt = 'completedAt',
  ExpiredAt = 'expiredAt',
  ChannelId = 'channelId',
  Status = 'status',
  WorkflowEventSettingId = 'workflowEventSettingId',
  CurrentWorkflowEventActionId = 'currentWorkflowEventActionId',
  AppointmentId = 'appointmentId',
  FormSubmissionId = 'formSubmissionId',
  ServiceReminderId = 'serviceReminderId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicPetId = 'clinicPetId',
}

export type ChannelAutomationStatusScalarWhereInput = {
  AND?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  channelId?: Maybe<StringFilter>;
  status?: Maybe<EnumAutomationStatusFilter>;
  workflowEventSettingId?: Maybe<StringFilter>;
  currentWorkflowEventActionId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
};

export type ChannelAutomationStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  cancelledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  startedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumAutomationStatusWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringWithAggregatesFilter>;
  currentWorkflowEventActionId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChannelAutomationStatusUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId: Scalars['String'];
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  status?: Maybe<AutomationStatus>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  status?: Maybe<AutomationStatus>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  where: ChannelAutomationStatusScalarWhereInput;
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutChannelInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  data: ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput;
};

export type ChannelAutomationStatusUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpdateWithoutCurrentWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput>;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutChannelInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutChannelInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: ChannelAutomationStatusWhereUniqueInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput;
  create: ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type ChannelAutomationStatusWhereInput = {
  AND?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  channelId?: Maybe<StringFilter>;
  status?: Maybe<EnumAutomationStatusFilter>;
  workflowEventSettingId?: Maybe<StringFilter>;
  currentWorkflowEventActionId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
};

export type ChannelAutomationStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelCountAggregateOutputType = {
  __typename?: 'ChannelCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  twilioChannelSid: Scalars['Int'];
  uniqueName: Scalars['Int'];
  friendlyName: Scalars['Int'];
  attributes: Scalars['Int'];
  clinicId: Scalars['Int'];
  lastMessageId: Scalars['Int'];
  isActive: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  currentWorkflowActionId: Scalars['Int'];
  channelType: Scalars['Int'];
  channelVisibility: Scalars['Int'];
  tags: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
};

export type ChannelCountOutputType = {
  __typename?: 'ChannelCountOutputType';
  appointments: Scalars['Int'];
  channelMembers: Scalars['Int'];
  pets: Scalars['Int'];
  messages: Scalars['Int'];
  assignees: Scalars['Int'];
  financialTransactions: Scalars['Int'];
  automationStatuses: Scalars['Int'];
};

export type ChannelCountResult = {
  __typename?: 'ChannelCountResult';
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
};

export type ChannelCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateManyChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelCreateManyChannelStatusInputEnvelope = {
  data: Array<ChannelCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelCreateManyClinicInputEnvelope = {
  data: Array<ChannelCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelCreateManyCurrentWorkflowActionInputEnvelope = {
  data: Array<ChannelCreateManyCurrentWorkflowActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelCreateManyLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelCreateManyLastMessageInputEnvelope = {
  data: Array<ChannelCreateManyLastMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutCurrentWorkflowActionInput = {
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutLastMessageInput = {
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutAutomationStatusesInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutAutomationStatusesInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAutomationStatusesInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutChannelMembersInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelMembersInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutCreationSourceInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutCreationSourceInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutCreationSourceInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutFinancialTransactionsInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutFinancialTransactionsInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateNestedOneWithoutMessagesInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutMessagesInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutMessagesInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
};

export type ChannelCreateOrConnectWithoutAppointmentsInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutAppointmentsInput;
};

export type ChannelCreateOrConnectWithoutAssigneesInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutAssigneesInput;
};

export type ChannelCreateOrConnectWithoutAutomationStatusesInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutAutomationStatusesInput;
};

export type ChannelCreateOrConnectWithoutChannelMembersInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutChannelMembersInput;
};

export type ChannelCreateOrConnectWithoutChannelStatusInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutChannelStatusInput;
};

export type ChannelCreateOrConnectWithoutClinicInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutClinicInput;
};

export type ChannelCreateOrConnectWithoutCreationSourceInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutCreationSourceInput;
};

export type ChannelCreateOrConnectWithoutCurrentWorkflowActionInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutCurrentWorkflowActionInput;
};

export type ChannelCreateOrConnectWithoutFinancialTransactionsInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutFinancialTransactionsInput;
};

export type ChannelCreateOrConnectWithoutLastMessageInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutLastMessageInput;
};

export type ChannelCreateOrConnectWithoutMessagesInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutMessagesInput;
};

export type ChannelCreateOrConnectWithoutPetsInput = {
  where: ChannelWhereUniqueInput;
  create: ChannelUncheckedCreateWithoutPetsInput;
};

export type ChannelCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutCreationSourceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
};

export type ChannelCreationSource = {
  __typename?: 'ChannelCreationSource';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creationSource: ChannelCreationSourceType;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  workflowEventSetting?: Maybe<WorkflowEventSetting>;
  user?: Maybe<User>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  channel: Channel;
  serviceReminder?: Maybe<ServiceReminder>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  formSubmission?: Maybe<FormSubmission>;
};

export type ChannelCreationSourceCountAggregateOutputType = {
  __typename?: 'ChannelCreationSourceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  creationSource: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
  userId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  channelId: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelCreationSourceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyClinicPetParentInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyFormSubmissionInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyServiceReminderInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyUserInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceCreateNestedOneWithoutChannelInput = {
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
};

export type ChannelCreationSourceCreateOrConnectWithoutChannelInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutChannelInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutUserInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutUserInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type ChannelCreationSourceCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceInput = {
  creationSource: ChannelCreationSourceType;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceListRelationFilter = {
  every?: Maybe<ChannelCreationSourceWhereInput>;
  some?: Maybe<ChannelCreationSourceWhereInput>;
  none?: Maybe<ChannelCreationSourceWhereInput>;
};

export type ChannelCreationSourceMaxAggregateOutputType = {
  __typename?: 'ChannelCreationSourceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceMinAggregateOutputType = {
  __typename?: 'ChannelCreationSourceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelCreationSourceOrderByRelevanceFieldEnum {
  Id = 'id',
  WorkflowEventSettingId = 'workflowEventSettingId',
  UserId = 'userId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ChannelId = 'channelId',
  ServiceReminderId = 'serviceReminderId',
  ClinicPetParentId = 'clinicPetParentId',
  FormSubmissionId = 'formSubmissionId',
}

export type ChannelCreationSourceOrderByRelevanceInput = {
  fields: Array<ChannelCreationSourceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelCreationSourceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  _count?: Maybe<ChannelCreationSourceCountOrderByAggregateInput>;
  _max?: Maybe<ChannelCreationSourceMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelCreationSourceMinOrderByAggregateInput>;
};

export type ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelCreationSourceOrderByRelevanceInput>;
};

export type ChannelCreationSourceRelationFilter = {
  is?: Maybe<ChannelCreationSourceWhereInput>;
  isNot?: Maybe<ChannelCreationSourceWhereInput>;
};

export enum ChannelCreationSourceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  CreationSource = 'creationSource',
  WorkflowEventSettingId = 'workflowEventSettingId',
  UserId = 'userId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ChannelId = 'channelId',
  ServiceReminderId = 'serviceReminderId',
  ClinicPetParentId = 'clinicPetParentId',
  FormSubmissionId = 'formSubmissionId',
}

export type ChannelCreationSourceScalarWhereInput = {
  AND?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  OR?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
};

export type ChannelCreationSourceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum ChannelCreationSourceType {
  Automation = 'Automation',
  ClinicInitiated = 'ClinicInitiated',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  ServiceReminder = 'ServiceReminder',
  ClientInitiated = 'ClientInitiated',
  SystemMessage = 'SystemMessage',
  CallDeflection = 'CallDeflection',
}

export type ChannelCreationSourceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
};

export type ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput = {
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
};

export type ChannelCreationSourceUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput = {
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  upsert?: Maybe<ChannelCreationSourceUpsertWithoutChannelInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  update?: Maybe<ChannelCreationSourceUncheckedUpdateWithoutChannelInput>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  userId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutUserInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  where: ChannelCreationSourceScalarWhereInput;
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
};

export type ChannelCreationSourceUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
};

export type ChannelCreationSourceUpdateOneWithoutChannelInput = {
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  upsert?: Maybe<ChannelCreationSourceUpsertWithoutChannelInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  update?: Maybe<ChannelCreationSourceUncheckedUpdateWithoutChannelInput>;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutUserInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  data: ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput;
};

export type ChannelCreationSourceUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceUpdateWithoutClinicWidgetRequestInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesInput>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceInput>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput;
  create: ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput;
  create: ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput;
  create: ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput;
  create: ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutUserInput;
  create: ChannelCreationSourceUncheckedCreateWithoutUserInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: ChannelCreationSourceWhereUniqueInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput;
  create: ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type ChannelCreationSourceUpsertWithoutChannelInput = {
  update: ChannelCreationSourceUncheckedUpdateWithoutChannelInput;
  create: ChannelCreationSourceUncheckedCreateWithoutChannelInput;
};

export type ChannelCreationSourceWhereInput = {
  AND?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  OR?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  user?: Maybe<UserWhereInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  channel?: Maybe<ChannelWhereInput>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
};

export type ChannelCreationSourceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type ChannelExportWhereInput = {
  id: Scalars['String'];
  includeNotes?: Maybe<Scalars['Boolean']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ChannelFilterSelection = {
  __typename?: 'ChannelFilterSelection';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  clinicId: Scalars['String'];
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  clinic: Clinic;
  userChannelFilterSelections: Array<UserChannelFilterSelection>;
  _count: ChannelFilterSelectionCountOutputType;
};

export type ChannelFilterSelectionUserChannelFilterSelectionsArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserChannelFilterSelectionScalarFieldEnum>;
};

export type ChannelFilterSelectionCountAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  clinicId: Scalars['Int'];
  name: Scalars['Int'];
  filters: Scalars['Int'];
  filterSelectionType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelFilterSelectionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionCountOutputType = {
  __typename?: 'ChannelFilterSelectionCountOutputType';
  userChannelFilterSelections: Scalars['Int'];
};

export type ChannelFilterSelectionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  clinic: ClinicCreateNestedOneWithoutChannelFilterSelectionsInput;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
};

export type ChannelFilterSelectionCreateManyClinicInputEnvelope = {
  data: Array<ChannelFilterSelectionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelFilterSelectionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
};

export type ChannelFilterSelectionCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
};

export type ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput = {
  create?: Maybe<ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  connect?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
};

export type ChannelFilterSelectionCreateOrConnectWithoutClinicInput = {
  where: ChannelFilterSelectionWhereUniqueInput;
  create: ChannelFilterSelectionUncheckedCreateWithoutClinicInput;
};

export type ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput = {
  where: ChannelFilterSelectionWhereUniqueInput;
  create: ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionCreateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  clinic: ClinicCreateNestedOneWithoutChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionListRelationFilter = {
  every?: Maybe<ChannelFilterSelectionWhereInput>;
  some?: Maybe<ChannelFilterSelectionWhereInput>;
  none?: Maybe<ChannelFilterSelectionWhereInput>;
};

export type ChannelFilterSelectionMaxAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionMinAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelFilterSelectionOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  Name = 'name',
}

export type ChannelFilterSelectionOrderByRelevanceInput = {
  fields: Array<ChannelFilterSelectionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelFilterSelectionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  _count?: Maybe<ChannelFilterSelectionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelFilterSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelFilterSelectionMinOrderByAggregateInput>;
};

export type ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelFilterSelectionOrderByRelevanceInput>;
};

export type ChannelFilterSelectionRelationFilter = {
  is?: Maybe<ChannelFilterSelectionWhereInput>;
  isNot?: Maybe<ChannelFilterSelectionWhereInput>;
};

export enum ChannelFilterSelectionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  ClinicId = 'clinicId',
  Name = 'name',
  Filters = 'filters',
  FilterSelectionType = 'filterSelectionType',
}

export type ChannelFilterSelectionScalarWhereInput = {
  AND?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  filters?: Maybe<JsonFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeFilter>;
};

export type ChannelFilterSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  filters?: Maybe<JsonWithAggregatesFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeWithAggregatesFilter>;
};

export type ChannelFilterSelectionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
};

export type ChannelFilterSelectionUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
};

export type ChannelFilterSelectionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionUncheckedUpdateManyWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
};

export type ChannelFilterSelectionUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelFilterSelectionsInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput = {
  where: ChannelFilterSelectionScalarWhereInput;
  data: ChannelFilterSelectionUncheckedUpdateManyWithoutChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
};

export type ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsInput = {
  create?: Maybe<ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  upsert?: Maybe<ChannelFilterSelectionUpsertWithoutUserChannelFilterSelectionsInput>;
  connect?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  update?: Maybe<ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput>;
};

export type ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput = {
  where: ChannelFilterSelectionWhereUniqueInput;
  data: ChannelFilterSelectionUncheckedUpdateWithoutClinicInput;
};

export type ChannelFilterSelectionUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUpdateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelFilterSelectionsInput>;
};

export type ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput = {
  where: ChannelFilterSelectionWhereUniqueInput;
  update: ChannelFilterSelectionUncheckedUpdateWithoutClinicInput;
  create: ChannelFilterSelectionUncheckedCreateWithoutClinicInput;
};

export type ChannelFilterSelectionUpsertWithoutUserChannelFilterSelectionsInput = {
  update: ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput;
  create: ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionWhereInput = {
  AND?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  filters?: Maybe<JsonFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionListRelationFilter>;
};

export type ChannelFilterSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelListRelationFilter = {
  every?: Maybe<ChannelWhereInput>;
  some?: Maybe<ChannelWhereInput>;
  none?: Maybe<ChannelWhereInput>;
};

export type ChannelMaxAggregateOutputType = {
  __typename?: 'ChannelMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
};

export type ChannelMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
};

export type ChannelMember = {
  __typename?: 'ChannelMember';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification: Scalars['Boolean'];
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<Channel>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  user?: Maybe<User>;
  channelMessages: Array<ChannelMessage>;
  _count: ChannelMemberCountOutputType;
};

export type ChannelMemberChannelMessagesArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
};

export type ChannelMemberAvgAggregateOutputType = {
  __typename?: 'ChannelMemberAvgAggregateOutputType';
  lastConsumedMessageIndex?: Maybe<Scalars['Float']>;
};

export type ChannelMemberAvgOrderByAggregateInput = {
  lastConsumedMessageIndex?: Maybe<SortOrder>;
};

export type ChannelMemberCountAggregateOutputType = {
  __typename?: 'ChannelMemberCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  twilioIdentity: Scalars['Int'];
  attributes: Scalars['Int'];
  lastConsumedMessageIndex: Scalars['Int'];
  lastConsumptionTimestamp: Scalars['Int'];
  twilioMemberSid: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  userId: Scalars['Int'];
  channelId: Scalars['Int'];
  sendMessageNotification: Scalars['Int'];
  removedAt: Scalars['Int'];
  draft: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMemberCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
};

export type ChannelMemberCountOutputType = {
  __typename?: 'ChannelMemberCountOutputType';
  channelMessages: Scalars['Int'];
};

export type ChannelMemberCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberCreateManyChannelInputEnvelope = {
  data: Array<ChannelMemberCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberCreateManyClinicPetParentInputEnvelope = {
  data: Array<ChannelMemberCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberCreateManyUserInputEnvelope = {
  data: Array<ChannelMemberCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberCreateNestedOneWithoutChannelMessagesInput = {
  create?: Maybe<ChannelMemberUncheckedCreateWithoutChannelMessagesInput>;
  connectOrCreate?: Maybe<ChannelMemberCreateOrConnectWithoutChannelMessagesInput>;
  connect?: Maybe<ChannelMemberWhereUniqueInput>;
};

export type ChannelMemberCreateOrConnectWithoutChannelInput = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberUncheckedCreateWithoutChannelInput;
};

export type ChannelMemberCreateOrConnectWithoutChannelMessagesInput = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberUncheckedCreateWithoutChannelMessagesInput;
};

export type ChannelMemberCreateOrConnectWithoutClinicPetParentInput = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberUncheckedCreateWithoutClinicPetParentInput;
};

export type ChannelMemberCreateOrConnectWithoutUserInput = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberUncheckedCreateWithoutUserInput;
};

export type ChannelMemberCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberCreateWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
};

export type ChannelMemberCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberListRelationFilter = {
  every?: Maybe<ChannelMemberWhereInput>;
  some?: Maybe<ChannelMemberWhereInput>;
  none?: Maybe<ChannelMemberWhereInput>;
};

export type ChannelMemberMaxAggregateOutputType = {
  __typename?: 'ChannelMemberMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
};

export type ChannelMemberMinAggregateOutputType = {
  __typename?: 'ChannelMemberMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
};

export type ChannelMemberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMemberOrderByRelevanceFieldEnum {
  Id = 'id',
  TwilioIdentity = 'twilioIdentity',
  TwilioMemberSid = 'twilioMemberSid',
  ClinicPetParentId = 'clinicPetParentId',
  UserId = 'userId',
  ChannelId = 'channelId',
}

export type ChannelMemberOrderByRelevanceInput = {
  fields: Array<ChannelMemberOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMemberOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMemberCountOrderByAggregateInput>;
  _avg?: Maybe<ChannelMemberAvgOrderByAggregateInput>;
  _max?: Maybe<ChannelMemberMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMemberMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelMemberSumOrderByAggregateInput>;
};

export type ChannelMemberOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  channelMessages?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelMemberOrderByRelevanceInput>;
};

export type ChannelMemberRelationFilter = {
  is?: Maybe<ChannelMemberWhereInput>;
  isNot?: Maybe<ChannelMemberWhereInput>;
};

export enum ChannelMemberScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TwilioIdentity = 'twilioIdentity',
  Attributes = 'attributes',
  LastConsumedMessageIndex = 'lastConsumedMessageIndex',
  LastConsumptionTimestamp = 'lastConsumptionTimestamp',
  TwilioMemberSid = 'twilioMemberSid',
  ClinicPetParentId = 'clinicPetParentId',
  UserId = 'userId',
  ChannelId = 'channelId',
  SendMessageNotification = 'sendMessageNotification',
  RemovedAt = 'removedAt',
  Draft = 'draft',
}

export type ChannelMemberScalarWhereInput = {
  AND?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  twilioIdentity?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableFilter>;
  twilioMemberSid?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  sendMessageNotification?: Maybe<BoolFilter>;
  removedAt?: Maybe<DateTimeNullableFilter>;
  draft?: Maybe<JsonNullableFilter>;
};

export type ChannelMemberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  twilioIdentity?: Maybe<StringNullableWithAggregatesFilter>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableWithAggregatesFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableWithAggregatesFilter>;
  twilioMemberSid?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  sendMessageNotification?: Maybe<BoolWithAggregatesFilter>;
  removedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  draft?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ChannelMemberSubscription = {
  __typename?: 'ChannelMemberSubscription';
  node: ChannelMember;
  mutation: MutationType;
};

export type ChannelMemberSumAggregateOutputType = {
  __typename?: 'ChannelMemberSumAggregateOutputType';
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
};

export type ChannelMemberSumOrderByAggregateInput = {
  lastConsumedMessageIndex?: Maybe<SortOrder>;
};

export type ChannelMemberUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
};

export type ChannelMemberUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedCreateWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUncheckedUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedUpdateWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorInput>;
};

export type ChannelMemberUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorInput>;
};

export type ChannelMemberUniqueChannelMemberForPetParentAndChannelCompoundUniqueInput = {
  channelId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type ChannelMemberUniqueChannelMemberForUserAndChannelCompoundUniqueInput = {
  channelId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChannelMemberUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersInput>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorInput>;
};

export type ChannelMemberUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
};

export type ChannelMemberUpdateManyWithWhereWithoutChannelInput = {
  where: ChannelMemberScalarWhereInput;
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
};

export type ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ChannelMemberScalarWhereInput;
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
};

export type ChannelMemberUpdateManyWithWhereWithoutUserInput = {
  where: ChannelMemberScalarWhereInput;
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
};

export type ChannelMemberUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
};

export type ChannelMemberUpdateOneWithoutChannelMessagesInput = {
  create?: Maybe<ChannelMemberUncheckedCreateWithoutChannelMessagesInput>;
  connectOrCreate?: Maybe<ChannelMemberCreateOrConnectWithoutChannelMessagesInput>;
  upsert?: Maybe<ChannelMemberUpsertWithoutChannelMessagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelMemberWhereUniqueInput>;
  update?: Maybe<ChannelMemberUncheckedUpdateWithoutChannelMessagesInput>;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutChannelInput = {
  where: ChannelMemberWhereUniqueInput;
  data: ChannelMemberUncheckedUpdateWithoutChannelInput;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ChannelMemberWhereUniqueInput;
  data: ChannelMemberUncheckedUpdateWithoutClinicPetParentInput;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelMemberWhereUniqueInput;
  data: ChannelMemberUncheckedUpdateWithoutUserInput;
};

export type ChannelMemberUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersInput>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorInput>;
};

export type ChannelMemberUpdateWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersInput>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersInput>;
};

export type ChannelMemberUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersInput>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorInput>;
};

export type ChannelMemberUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorInput>;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutChannelInput = {
  where: ChannelMemberWhereUniqueInput;
  update: ChannelMemberUncheckedUpdateWithoutChannelInput;
  create: ChannelMemberUncheckedCreateWithoutChannelInput;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ChannelMemberWhereUniqueInput;
  update: ChannelMemberUncheckedUpdateWithoutClinicPetParentInput;
  create: ChannelMemberUncheckedCreateWithoutClinicPetParentInput;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelMemberWhereUniqueInput;
  update: ChannelMemberUncheckedUpdateWithoutUserInput;
  create: ChannelMemberUncheckedCreateWithoutUserInput;
};

export type ChannelMemberUpsertWithoutChannelMessagesInput = {
  update: ChannelMemberUncheckedUpdateWithoutChannelMessagesInput;
  create: ChannelMemberUncheckedCreateWithoutChannelMessagesInput;
};

export type ChannelMemberWhereInput = {
  AND?: Maybe<Array<ChannelMemberWhereInput>>;
  OR?: Maybe<Array<ChannelMemberWhereInput>>;
  NOT?: Maybe<Array<ChannelMemberWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  twilioIdentity?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableFilter>;
  twilioMemberSid?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  sendMessageNotification?: Maybe<BoolFilter>;
  removedAt?: Maybe<DateTimeNullableFilter>;
  draft?: Maybe<JsonNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  user?: Maybe<UserWhereInput>;
  channelMessages?: Maybe<ChannelMessageListRelationFilter>;
};

export type ChannelMemberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uniqueChannelMemberForPetParentAndChannel?: Maybe<
    ChannelMemberUniqueChannelMemberForPetParentAndChannelCompoundUniqueInput
  >;
  uniqueChannelMemberForUserAndChannel?: Maybe<ChannelMemberUniqueChannelMemberForUserAndChannelCompoundUniqueInput>;
};

export type ChannelMessage = {
  __typename?: 'ChannelMessage';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited: Scalars['Boolean'];
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source: MessageSource;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  author?: Maybe<ChannelMember>;
  lastMessageChannel: Array<Channel>;
  attachments: Array<ChannelMessageAttachment>;
  forceReadReceipt: Scalars['Boolean'];
  isUnreadEligible: Scalars['Boolean'];
  mentionedUsers: Array<User>;
  automationRun?: Maybe<AutomationRun>;
  smsNotifificationStatuses: Array<SmsNotificationStatus>;
  reactions: Array<ChannelMessageReaction>;
  _count: ChannelMessageCountOutputType;
};

export type ChannelMessageLastMessageChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};

export type ChannelMessageAttachmentsArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageAttachmentScalarFieldEnum>;
};

export type ChannelMessageMentionedUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};

export type ChannelMessageSmsNotifificationStatusesArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SmsNotificationStatusScalarFieldEnum>;
};

export type ChannelMessageReactionsArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageReactionScalarFieldEnum>;
};

export type ChannelMessageAttachment = {
  __typename?: 'ChannelMessageAttachment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage: Array<ChannelMessage>;
  _count: ChannelMessageAttachmentCountOutputType;
};

export type ChannelMessageAttachmentChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
};

export type ChannelMessageAttachmentCountAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  attachmentType: Scalars['Int'];
  entityType: Scalars['Int'];
  entityId: Scalars['Int'];
  filename: Scalars['Int'];
  url: Scalars['Int'];
  uploadStatus: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMessageAttachmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentCountOutputType = {
  __typename?: 'ChannelMessageAttachmentCountOutputType';
  channelMessage: Scalars['Int'];
};

export type ChannelMessageAttachmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageCreateNestedManyWithoutAttachmentsInput>;
};

export type ChannelMessageAttachmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
};

export type ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  create: ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageAttachmentCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export enum ChannelMessageAttachmentEntityType {
  Form = 'Form',
  Appointment = 'Appointment',
  Transaction = 'Transaction',
  StripePaymentIntent = 'StripePaymentIntent',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  AutomationRun = 'AutomationRun',
}

export type ChannelMessageAttachmentListRelationFilter = {
  every?: Maybe<ChannelMessageAttachmentWhereInput>;
  some?: Maybe<ChannelMessageAttachmentWhereInput>;
  none?: Maybe<ChannelMessageAttachmentWhereInput>;
};

export type ChannelMessageAttachmentMaxAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentMinAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageAttachmentOrderByRelevanceFieldEnum {
  Id = 'id',
  EntityId = 'entityId',
  Filename = 'filename',
  Url = 'url',
}

export type ChannelMessageAttachmentOrderByRelevanceInput = {
  fields: Array<ChannelMessageAttachmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMessageAttachmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMessageAttachmentCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageAttachmentMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageAttachmentMinOrderByAggregateInput>;
};

export type ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  attachmentType?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  channelMessage?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelMessageAttachmentOrderByRelevanceInput>;
};

export enum ChannelMessageAttachmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AttachmentType = 'attachmentType',
  EntityType = 'entityType',
  EntityId = 'entityId',
  Filename = 'filename',
  Url = 'url',
  UploadStatus = 'uploadStatus',
}

export type ChannelMessageAttachmentScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableFilter>;
  entityId?: Maybe<StringNullableFilter>;
  filename?: Maybe<StringNullableFilter>;
  url?: Maybe<StringNullableFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type ChannelMessageAttachmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  entityId?: Maybe<StringNullableWithAggregatesFilter>;
  filename?: Maybe<StringNullableWithAggregatesFilter>;
  url?: Maybe<StringNullableWithAggregatesFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
};

export type ChannelMessageAttachmentSubscription = {
  __typename?: 'ChannelMessageAttachmentSubscription';
  node: ChannelMessageAttachment;
  mutation: MutationType;
};

export enum ChannelMessageAttachmentType {
  Image = 'Image',
  Video = 'Video',
  File = 'File',
  FormRequest = 'FormRequest',
  ServiceReminderFormSubmission = 'ServiceReminderFormSubmission',
  PetParentRequest = 'PetParentRequest',
  AppointmentConfirmation = 'AppointmentConfirmation',
  AppointmentReminder = 'AppointmentReminder',
  AppointmentArrival = 'AppointmentArrival',
  Invoice = 'Invoice',
  CallRequest = 'CallRequest',
  WidgetRequest = 'WidgetRequest',
  CallHistory = 'CallHistory',
  AutomationRun = 'AutomationRun',
}

export type ChannelMessageAttachmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAttachmentsInput>;
};

export type ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
};

export type ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType: ChannelMessageAttachmentType;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAttachmentsInput>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
};

export type ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  channelMessage?: Maybe<ChannelMessageUpdateManyWithoutAttachmentsInput>;
};

export type ChannelMessageAttachmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput = {
  where: ChannelMessageAttachmentScalarWhereInput;
  data: ChannelMessageAttachmentUncheckedUpdateManyWithoutAttachmentsInput;
};

export type ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
};

export type ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  data: ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput;
};

export type ChannelMessageAttachmentUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  entityId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export enum ChannelMessageAttachmentUploadStatus {
  Success = 'Success',
  Error = 'Error',
  InProgress = 'InProgress',
  Pending = 'Pending',
}

export type ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  update: ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput;
  create: ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageAttachmentWhereInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableFilter>;
  entityId?: Maybe<StringNullableFilter>;
  filename?: Maybe<StringNullableFilter>;
  url?: Maybe<StringNullableFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableFilter>;
  channelMessage?: Maybe<ChannelMessageListRelationFilter>;
};

export type ChannelMessageAttachmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMessageAvgAggregateOutputType = {
  __typename?: 'ChannelMessageAvgAggregateOutputType';
  index?: Maybe<Scalars['Float']>;
};

export type ChannelMessageAvgOrderByAggregateInput = {
  index?: Maybe<SortOrder>;
};

export type ChannelMessageCountAggregateOutputType = {
  __typename?: 'ChannelMessageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  body: Scalars['Int'];
  attributes: Scalars['Int'];
  twilioMessageSid: Scalars['Int'];
  index: Scalars['Int'];
  lastUpdatedBy: Scalars['Int'];
  media: Scalars['Int'];
  wasEdited: Scalars['Int'];
  authorId: Scalars['Int'];
  channelId: Scalars['Int'];
  messageType: Scalars['Int'];
  source: Scalars['Int'];
  sourcePhoneNumber: Scalars['Int'];
  forceReadReceipt: Scalars['Int'];
  isUnreadEligible: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMessageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
};

export type ChannelMessageCountOutputType = {
  __typename?: 'ChannelMessageCountOutputType';
  lastMessageChannel: Scalars['Int'];
  attachments: Scalars['Int'];
  mentionedUsers: Scalars['Int'];
  smsNotifificationStatuses: Scalars['Int'];
  reactions: Scalars['Int'];
};

export type ChannelMessageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageCreateManyAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyAuthorInputEnvelope = {
  data: Array<ChannelMessageCreateManyAuthorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyChannelInputEnvelope = {
  data: Array<ChannelMessageCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateNestedManyWithoutAttachmentsInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageCreateNestedManyWithoutAuthorInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageCreateNestedManyWithoutMentionedUsersInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageCreateNestedOneWithoutAutomationRunInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutomationRunInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutomationRunInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateNestedOneWithoutLastMessageChannelInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutLastMessageChannelInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutLastMessageChannelInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateNestedOneWithoutReactionsInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutReactionsInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutReactionsInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
};

export type ChannelMessageCreateOrConnectWithoutAttachmentsInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutAttachmentsInput;
};

export type ChannelMessageCreateOrConnectWithoutAuthorInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutAuthorInput;
};

export type ChannelMessageCreateOrConnectWithoutAutomationRunInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutAutomationRunInput;
};

export type ChannelMessageCreateOrConnectWithoutChannelInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutChannelInput;
};

export type ChannelMessageCreateOrConnectWithoutLastMessageChannelInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutLastMessageChannelInput;
};

export type ChannelMessageCreateOrConnectWithoutMentionedUsersInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutMentionedUsersInput;
};

export type ChannelMessageCreateOrConnectWithoutReactionsInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutReactionsInput;
};

export type ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput;
};

export type ChannelMessageCreateWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageCreateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageCreateWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageCreateWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageCreateWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageCreateWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageListRelationFilter = {
  every?: Maybe<ChannelMessageWhereInput>;
  some?: Maybe<ChannelMessageWhereInput>;
  none?: Maybe<ChannelMessageWhereInput>;
};

export type ChannelMessageMaxAggregateOutputType = {
  __typename?: 'ChannelMessageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
};

export type ChannelMessageMinAggregateOutputType = {
  __typename?: 'ChannelMessageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
};

export type ChannelMessageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageOrderByRelevanceFieldEnum {
  Id = 'id',
  Body = 'body',
  TwilioMessageSid = 'twilioMessageSid',
  LastUpdatedBy = 'lastUpdatedBy',
  AuthorId = 'authorId',
  ChannelId = 'channelId',
  SourcePhoneNumber = 'sourcePhoneNumber',
}

export type ChannelMessageOrderByRelevanceInput = {
  fields: Array<ChannelMessageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMessageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMessageCountOrderByAggregateInput>;
  _avg?: Maybe<ChannelMessageAvgOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelMessageSumOrderByAggregateInput>;
};

export type ChannelMessageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  author?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  lastMessageChannel?: Maybe<ChannelOrderByRelationAggregateInput>;
  attachments?: Maybe<ChannelMessageAttachmentOrderByRelationAggregateInput>;
  forceReadReceipt?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  mentionedUsers?: Maybe<UserOrderByRelationAggregateInput>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusOrderByRelationAggregateInput>;
  reactions?: Maybe<ChannelMessageReactionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelMessageOrderByRelevanceInput>;
};

export type ChannelMessageReaction = {
  __typename?: 'ChannelMessageReaction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  userId: Scalars['String'];
  reaction: Scalars['String'];
  channelMessage: ChannelMessage;
  user: User;
};

export type ChannelMessageReactionCountAggregateOutputType = {
  __typename?: 'ChannelMessageReactionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  channelMessageId: Scalars['Int'];
  userId: Scalars['Int'];
  reaction: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelMessageReactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
};

export type ChannelMessageReactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction: Scalars['String'];
  channelMessage: ChannelMessageCreateNestedOneWithoutReactionsInput;
  user: UserCreateNestedOneWithoutChannelMessageReactionsInput;
};

export type ChannelMessageReactionCreateManyChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionCreateManyChannelMessageInputEnvelope = {
  data: Array<ChannelMessageReactionCreateManyChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageReactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  userId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionCreateManyUserInputEnvelope = {
  data: Array<ChannelMessageReactionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
};

export type ChannelMessageReactionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
};

export type ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  create: ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageReactionCreateOrConnectWithoutUserInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  create: ChannelMessageReactionUncheckedCreateWithoutUserInput;
};

export type ChannelMessageReactionCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction: Scalars['String'];
  user: UserCreateNestedOneWithoutChannelMessageReactionsInput;
};

export type ChannelMessageReactionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction: Scalars['String'];
  channelMessage: ChannelMessageCreateNestedOneWithoutReactionsInput;
};

export type ChannelMessageReactionListRelationFilter = {
  every?: Maybe<ChannelMessageReactionWhereInput>;
  some?: Maybe<ChannelMessageReactionWhereInput>;
  none?: Maybe<ChannelMessageReactionWhereInput>;
};

export type ChannelMessageReactionMaxAggregateOutputType = {
  __typename?: 'ChannelMessageReactionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
};

export type ChannelMessageReactionMinAggregateOutputType = {
  __typename?: 'ChannelMessageReactionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
};

export type ChannelMessageReactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageReactionOrderByRelevanceFieldEnum {
  Id = 'id',
  ChannelMessageId = 'channelMessageId',
  UserId = 'userId',
  Reaction = 'reaction',
}

export type ChannelMessageReactionOrderByRelevanceInput = {
  fields: Array<ChannelMessageReactionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelMessageReactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  _count?: Maybe<ChannelMessageReactionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageReactionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageReactionMinOrderByAggregateInput>;
};

export type ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  channelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelMessageReactionOrderByRelevanceInput>;
};

export enum ChannelMessageReactionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  ChannelMessageId = 'channelMessageId',
  UserId = 'userId',
  Reaction = 'reaction',
}

export type ChannelMessageReactionScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  channelMessageId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  reaction?: Maybe<StringFilter>;
};

export type ChannelMessageReactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  channelMessageId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  reaction?: Maybe<StringWithAggregatesFilter>;
};

export type ChannelMessageReactionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  userId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
};

export type ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
};

export type ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId: Scalars['String'];
  reaction: Scalars['String'];
};

export type ChannelMessageReactionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
};

export type ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  channelMessageId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction?: Maybe<Scalars['String']>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutReactionsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelMessageReactionsInput>;
};

export type ChannelMessageReactionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput = {
  where: ChannelMessageReactionScalarWhereInput;
  data: ChannelMessageReactionUncheckedUpdateManyWithoutReactionsInput;
};

export type ChannelMessageReactionUpdateManyWithWhereWithoutUserInput = {
  where: ChannelMessageReactionScalarWhereInput;
  data: ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageReactionsInput;
};

export type ChannelMessageReactionUpdateManyWithoutChannelMessageInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
};

export type ChannelMessageReactionUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
};

export type ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  data: ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput;
};

export type ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  data: ChannelMessageReactionUncheckedUpdateWithoutUserInput;
};

export type ChannelMessageReactionUpdateWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelMessageReactionsInput>;
};

export type ChannelMessageReactionUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reaction?: Maybe<Scalars['String']>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutReactionsInput>;
};

export type ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  update: ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput;
  create: ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput;
};

export type ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelMessageReactionWhereUniqueInput;
  update: ChannelMessageReactionUncheckedUpdateWithoutUserInput;
  create: ChannelMessageReactionUncheckedCreateWithoutUserInput;
};

export type ChannelMessageReactionWhereInput = {
  AND?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  OR?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  channelMessageId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  reaction?: Maybe<StringFilter>;
  channelMessage?: Maybe<ChannelMessageWhereInput>;
  user?: Maybe<UserWhereInput>;
};

export type ChannelMessageReactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMessageRelationFilter = {
  is?: Maybe<ChannelMessageWhereInput>;
  isNot?: Maybe<ChannelMessageWhereInput>;
};

export enum ChannelMessageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Body = 'body',
  Attributes = 'attributes',
  TwilioMessageSid = 'twilioMessageSid',
  Index = 'index',
  LastUpdatedBy = 'lastUpdatedBy',
  Media = 'media',
  WasEdited = 'wasEdited',
  AuthorId = 'authorId',
  ChannelId = 'channelId',
  MessageType = 'messageType',
  Source = 'source',
  SourcePhoneNumber = 'sourcePhoneNumber',
  ForceReadReceipt = 'forceReadReceipt',
  IsUnreadEligible = 'isUnreadEligible',
}

export type ChannelMessageScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  body?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  twilioMessageSid?: Maybe<StringNullableFilter>;
  index?: Maybe<IntFilter>;
  lastUpdatedBy?: Maybe<StringNullableFilter>;
  media?: Maybe<JsonNullableFilter>;
  wasEdited?: Maybe<BoolFilter>;
  authorId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  messageType?: Maybe<EnumMessageTypeFilter>;
  source?: Maybe<EnumMessageSourceFilter>;
  sourcePhoneNumber?: Maybe<StringNullableFilter>;
  forceReadReceipt?: Maybe<BoolFilter>;
  isUnreadEligible?: Maybe<BoolFilter>;
};

export type ChannelMessageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  body?: Maybe<StringNullableWithAggregatesFilter>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  twilioMessageSid?: Maybe<StringNullableWithAggregatesFilter>;
  index?: Maybe<IntWithAggregatesFilter>;
  lastUpdatedBy?: Maybe<StringNullableWithAggregatesFilter>;
  media?: Maybe<JsonNullableWithAggregatesFilter>;
  wasEdited?: Maybe<BoolWithAggregatesFilter>;
  authorId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  messageType?: Maybe<EnumMessageTypeWithAggregatesFilter>;
  source?: Maybe<EnumMessageSourceWithAggregatesFilter>;
  sourcePhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  forceReadReceipt?: Maybe<BoolWithAggregatesFilter>;
  isUnreadEligible?: Maybe<BoolWithAggregatesFilter>;
};

export type ChannelMessageSubscription = {
  __typename?: 'ChannelMessageSubscription';
  node: ChannelMessage;
  mutation: MutationType;
};

export type ChannelMessageSumAggregateOutputType = {
  __typename?: 'ChannelMessageSumAggregateOutputType';
  index?: Maybe<Scalars['Int']>;
};

export type ChannelMessageSumOrderByAggregateInput = {
  index?: Maybe<SortOrder>;
};

export type ChannelMessageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutAttachmentsInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
};

export type ChannelMessageUncheckedCreateWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutAttachmentsInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutAuthorInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAuthorInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUnreadCountResult = {
  __typename?: 'ChannelMessageUnreadCountResult';
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
};

export type ChannelMessageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput = {
  where: ChannelMessageScalarWhereInput;
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessageInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutAuthorInput = {
  where: ChannelMessageScalarWhereInput;
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessagesInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutChannelInput = {
  where: ChannelMessageScalarWhereInput;
  data: ChannelMessageUncheckedUpdateManyWithoutMessagesInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput = {
  where: ChannelMessageScalarWhereInput;
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput;
};

export type ChannelMessageUpdateManyWithoutAttachmentsInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUpdateManyWithoutAuthorInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAuthorInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUpdateManyWithoutMentionedUsersInput = {
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
};

export type ChannelMessageUpdateOneRequiredWithoutAutomationRunInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutomationRunInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutomationRunInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutAutomationRunInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutAutomationRunInput>;
};

export type ChannelMessageUpdateOneRequiredWithoutReactionsInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutReactionsInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutReactionsInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutReactionsInput>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutReactionsInput>;
};

export type ChannelMessageUpdateOneWithoutLastMessageChannelInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutLastMessageChannelInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutLastMessageChannelInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutLastMessageChannelInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput>;
};

export type ChannelMessageUpdateOneWithoutSmsNotifificationStatusesInput = {
  create?: Maybe<ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutSmsNotifificationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput>;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput = {
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUncheckedUpdateWithoutAttachmentsInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput = {
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUncheckedUpdateWithoutAuthorInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutChannelInput = {
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUncheckedUpdateWithoutChannelInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput = {
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUncheckedUpdateWithoutMentionedUsersInput;
};

export type ChannelMessageUpdateWithoutAttachmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUpdateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUpdateWithoutAutomationRunInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUpdateWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUpdateWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUpdateWithoutReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput>;
};

export type ChannelMessageUpdateWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesInput>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesInput>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageInput>;
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageInput>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageInput>;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput = {
  where: ChannelMessageWhereUniqueInput;
  update: ChannelMessageUncheckedUpdateWithoutAttachmentsInput;
  create: ChannelMessageUncheckedCreateWithoutAttachmentsInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput = {
  where: ChannelMessageWhereUniqueInput;
  update: ChannelMessageUncheckedUpdateWithoutAuthorInput;
  create: ChannelMessageUncheckedCreateWithoutAuthorInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutChannelInput = {
  where: ChannelMessageWhereUniqueInput;
  update: ChannelMessageUncheckedUpdateWithoutChannelInput;
  create: ChannelMessageUncheckedCreateWithoutChannelInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput = {
  where: ChannelMessageWhereUniqueInput;
  update: ChannelMessageUncheckedUpdateWithoutMentionedUsersInput;
  create: ChannelMessageUncheckedCreateWithoutMentionedUsersInput;
};

export type ChannelMessageUpsertWithoutAutomationRunInput = {
  update: ChannelMessageUncheckedUpdateWithoutAutomationRunInput;
  create: ChannelMessageUncheckedCreateWithoutAutomationRunInput;
};

export type ChannelMessageUpsertWithoutLastMessageChannelInput = {
  update: ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput;
  create: ChannelMessageUncheckedCreateWithoutLastMessageChannelInput;
};

export type ChannelMessageUpsertWithoutReactionsInput = {
  update: ChannelMessageUncheckedUpdateWithoutReactionsInput;
  create: ChannelMessageUncheckedCreateWithoutReactionsInput;
};

export type ChannelMessageUpsertWithoutSmsNotifificationStatusesInput = {
  update: ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput;
  create: ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput;
};

export type ChannelMessageWhereInput = {
  AND?: Maybe<Array<ChannelMessageWhereInput>>;
  OR?: Maybe<Array<ChannelMessageWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  body?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  twilioMessageSid?: Maybe<StringNullableFilter>;
  index?: Maybe<IntFilter>;
  lastUpdatedBy?: Maybe<StringNullableFilter>;
  media?: Maybe<JsonNullableFilter>;
  wasEdited?: Maybe<BoolFilter>;
  authorId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  messageType?: Maybe<EnumMessageTypeFilter>;
  source?: Maybe<EnumMessageSourceFilter>;
  sourcePhoneNumber?: Maybe<StringNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  author?: Maybe<ChannelMemberWhereInput>;
  lastMessageChannel?: Maybe<ChannelListRelationFilter>;
  attachments?: Maybe<ChannelMessageAttachmentListRelationFilter>;
  forceReadReceipt?: Maybe<BoolFilter>;
  isUnreadEligible?: Maybe<BoolFilter>;
  mentionedUsers?: Maybe<UserListRelationFilter>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusListRelationFilter>;
  reactions?: Maybe<ChannelMessageReactionListRelationFilter>;
};

export type ChannelMessageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMinAggregateOutputType = {
  __typename?: 'ChannelMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
};

export type ChannelMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
};

export type ChannelOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelOrderByRelevanceFieldEnum {
  Id = 'id',
  TwilioChannelSid = 'twilioChannelSid',
  UniqueName = 'uniqueName',
  FriendlyName = 'friendlyName',
  ClinicId = 'clinicId',
  LastMessageId = 'lastMessageId',
  ChannelStatusId = 'channelStatusId',
  CurrentWorkflowActionId = 'currentWorkflowActionId',
}

export type ChannelOrderByRelevanceInput = {
  fields: Array<ChannelOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  _count?: Maybe<ChannelCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMinOrderByAggregateInput>;
};

export type ChannelOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  attributes?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  currentWorkflowAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  lastMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  messages?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  tags?: Maybe<SortOrder>;
  automationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  creationSource?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelOrderByRelevanceInput>;
};

export type ChannelRelationFilter = {
  is?: Maybe<ChannelWhereInput>;
  isNot?: Maybe<ChannelWhereInput>;
};

export enum ChannelScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TwilioChannelSid = 'twilioChannelSid',
  UniqueName = 'uniqueName',
  FriendlyName = 'friendlyName',
  Attributes = 'attributes',
  ClinicId = 'clinicId',
  LastMessageId = 'lastMessageId',
  IsActive = 'isActive',
  ChannelStatusId = 'channelStatusId',
  CurrentWorkflowActionId = 'currentWorkflowActionId',
  ChannelType = 'channelType',
  ChannelVisibility = 'channelVisibility',
  Tags = 'tags',
}

export type ChannelScalarWhereInput = {
  AND?: Maybe<Array<ChannelScalarWhereInput>>;
  OR?: Maybe<Array<ChannelScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  twilioChannelSid?: Maybe<StringNullableFilter>;
  uniqueName?: Maybe<StringNullableFilter>;
  friendlyName?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  lastMessageId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  currentWorkflowActionId?: Maybe<StringNullableFilter>;
  channelType?: Maybe<EnumChannelTypeNullableFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableFilter>;
  tags?: Maybe<JsonNullableFilter>;
};

export type ChannelScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  twilioChannelSid?: Maybe<StringNullableWithAggregatesFilter>;
  uniqueName?: Maybe<StringNullableWithAggregatesFilter>;
  friendlyName?: Maybe<StringNullableWithAggregatesFilter>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  lastMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  channelStatusId?: Maybe<StringNullableWithAggregatesFilter>;
  currentWorkflowActionId?: Maybe<StringNullableWithAggregatesFilter>;
  channelType?: Maybe<EnumChannelTypeNullableWithAggregatesFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableWithAggregatesFilter>;
  tags?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ChannelSearchOutput = {
  __typename?: 'ChannelSearchOutput';
  channels: Array<Channel>;
  total: Scalars['Int'];
};

export type ChannelSelection = {
  __typename?: 'ChannelSelection';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  assignees: Array<User>;
  includeUnassigned: Scalars['Boolean'];
  statuses: Array<ChannelStatus>;
  userSetting?: Maybe<UserSetting>;
  _count: ChannelSelectionCountOutputType;
};

export type ChannelSelectionAssigneesArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};

export type ChannelSelectionStatusesArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
};

export type ChannelSelectionCountAggregateOutputType = {
  __typename?: 'ChannelSelectionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  includeUnassigned: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelSelectionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
};

export type ChannelSelectionCountOutputType = {
  __typename?: 'ChannelSelectionCountOutputType';
  assignees: Scalars['Int'];
  statuses: Scalars['Int'];
};

export type ChannelSelectionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
};

export type ChannelSelectionCreateNestedManyWithoutStatusesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
};

export type ChannelSelectionCreateNestedOneWithoutUserSettingInput = {
  create?: Maybe<ChannelSelectionUncheckedCreateWithoutUserSettingInput>;
  connectOrCreate?: Maybe<ChannelSelectionCreateOrConnectWithoutUserSettingInput>;
  connect?: Maybe<ChannelSelectionWhereUniqueInput>;
};

export type ChannelSelectionCreateOrConnectWithoutAssigneesInput = {
  where: ChannelSelectionWhereUniqueInput;
  create: ChannelSelectionUncheckedCreateWithoutAssigneesInput;
};

export type ChannelSelectionCreateOrConnectWithoutStatusesInput = {
  where: ChannelSelectionWhereUniqueInput;
  create: ChannelSelectionUncheckedCreateWithoutStatusesInput;
};

export type ChannelSelectionCreateOrConnectWithoutUserSettingInput = {
  where: ChannelSelectionWhereUniqueInput;
  create: ChannelSelectionUncheckedCreateWithoutUserSettingInput;
};

export type ChannelSelectionCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
};

export type ChannelSelectionListRelationFilter = {
  every?: Maybe<ChannelSelectionWhereInput>;
  some?: Maybe<ChannelSelectionWhereInput>;
  none?: Maybe<ChannelSelectionWhereInput>;
};

export type ChannelSelectionMaxAggregateOutputType = {
  __typename?: 'ChannelSelectionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
};

export type ChannelSelectionMinAggregateOutputType = {
  __typename?: 'ChannelSelectionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
};

export type ChannelSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelSelectionOrderByRelevanceFieldEnum {
  Id = 'id',
}

export type ChannelSelectionOrderByRelevanceInput = {
  fields: Array<ChannelSelectionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelSelectionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
  _count?: Maybe<ChannelSelectionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelSelectionMinOrderByAggregateInput>;
};

export type ChannelSelectionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  includeUnassigned?: Maybe<SortOrder>;
  statuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelSelectionOrderByRelevanceInput>;
};

export type ChannelSelectionRelationFilter = {
  is?: Maybe<ChannelSelectionWhereInput>;
  isNot?: Maybe<ChannelSelectionWhereInput>;
};

export enum ChannelSelectionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IncludeUnassigned = 'includeUnassigned',
}

export type ChannelSelectionScalarWhereInput = {
  AND?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  includeUnassigned?: Maybe<BoolFilter>;
};

export type ChannelSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  includeUnassigned?: Maybe<BoolWithAggregatesFilter>;
};

export type ChannelSelectionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
};

export type ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
};

export type ChannelSelectionUncheckedCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
};

export type ChannelSelectionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutStatusesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutStatusesInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
};

export type ChannelSelectionUncheckedUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedUpdateWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsInput>;
};

export type ChannelSelectionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
};

export type ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput = {
  where: ChannelSelectionScalarWhereInput;
  data: ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput;
};

export type ChannelSelectionUpdateManyWithWhereWithoutStatusesInput = {
  where: ChannelSelectionScalarWhereInput;
  data: ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput;
};

export type ChannelSelectionUpdateManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
};

export type ChannelSelectionUpdateManyWithoutStatusesInput = {
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutStatusesInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
};

export type ChannelSelectionUpdateOneWithoutUserSettingInput = {
  create?: Maybe<ChannelSelectionUncheckedCreateWithoutUserSettingInput>;
  connectOrCreate?: Maybe<ChannelSelectionCreateOrConnectWithoutUserSettingInput>;
  upsert?: Maybe<ChannelSelectionUpsertWithoutUserSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelSelectionWhereUniqueInput>;
  update?: Maybe<ChannelSelectionUncheckedUpdateWithoutUserSettingInput>;
};

export type ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput = {
  where: ChannelSelectionWhereUniqueInput;
  data: ChannelSelectionUncheckedUpdateWithoutAssigneesInput;
};

export type ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput = {
  where: ChannelSelectionWhereUniqueInput;
  data: ChannelSelectionUncheckedUpdateWithoutStatusesInput;
};

export type ChannelSelectionUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUpdateWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsInput>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsInput>;
};

export type ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput = {
  where: ChannelSelectionWhereUniqueInput;
  update: ChannelSelectionUncheckedUpdateWithoutAssigneesInput;
  create: ChannelSelectionUncheckedCreateWithoutAssigneesInput;
};

export type ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput = {
  where: ChannelSelectionWhereUniqueInput;
  update: ChannelSelectionUncheckedUpdateWithoutStatusesInput;
  create: ChannelSelectionUncheckedCreateWithoutStatusesInput;
};

export type ChannelSelectionUpsertWithoutUserSettingInput = {
  update: ChannelSelectionUncheckedUpdateWithoutUserSettingInput;
  create: ChannelSelectionUncheckedCreateWithoutUserSettingInput;
};

export type ChannelSelectionWhereInput = {
  AND?: Maybe<Array<ChannelSelectionWhereInput>>;
  OR?: Maybe<Array<ChannelSelectionWhereInput>>;
  NOT?: Maybe<Array<ChannelSelectionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  assignees?: Maybe<UserListRelationFilter>;
  includeUnassigned?: Maybe<BoolFilter>;
  statuses?: Maybe<ChannelStatusListRelationFilter>;
  userSetting?: Maybe<UserSettingWhereInput>;
};

export type ChannelSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatus = {
  __typename?: 'ChannelStatus';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  channelStatusAction: ChannelStatusAction;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSetting>;
  deletedBy?: Maybe<User>;
  channels: Array<Channel>;
  clinicWidgetRequestType: Array<ClinicWidgetRequestType>;
  workflowEventAction: Array<WorkflowEventAction>;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  automationRunActions: Array<AutomationRunAction>;
  channelSelections: Array<ChannelSelection>;
  _count: ChannelStatusCountOutputType;
};

export type ChannelStatusChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};

export type ChannelStatusClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};

export type ChannelStatusWorkflowEventActionArgs = {
  where?: Maybe<WorkflowEventActionWhereInput>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
};

export type ChannelStatusChannelStatusChannelAssigneesArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
};

export type ChannelStatusAutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};

export type ChannelStatusChannelSelectionsArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelSelectionScalarFieldEnum>;
};

export enum ChannelStatusAction {
  Active = 'Active',
  Inactive = 'Inactive',
  InactivePermanently = 'InactivePermanently',
  Migrated = 'Migrated',
}

export type ChannelStatusAvgAggregateOutputType = {
  __typename?: 'ChannelStatusAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type ChannelStatusAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssignee = {
  __typename?: 'ChannelStatusChannelAssignee';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
  clinic: Clinic;
  user: User;
  channelStatus: ChannelStatus;
};

export type ChannelStatusChannelAssigneeChannelStatusChannelAssigneeChannelStatusIdAndUserIdCompoundUniqueInput = {
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCountAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  userId: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelStatusChannelAssigneeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateManyChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyUserInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeListRelationFilter = {
  every?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  some?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  none?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};

export type ChannelStatusChannelAssigneeMaxAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeMinAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelStatusChannelAssigneeOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  UserId = 'userId',
  ChannelStatusId = 'channelStatusId',
}

export type ChannelStatusChannelAssigneeOrderByRelevanceInput = {
  fields: Array<ChannelStatusChannelAssigneeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelStatusChannelAssigneeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  _count?: Maybe<ChannelStatusChannelAssigneeCountOrderByAggregateInput>;
  _max?: Maybe<ChannelStatusChannelAssigneeMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelStatusChannelAssigneeMinOrderByAggregateInput>;
};

export type ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ChannelStatusChannelAssigneeOrderByRelevanceInput>;
};

export enum ChannelStatusChannelAssigneeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ClinicId = 'clinicId',
  UserId = 'userId',
  ChannelStatusId = 'channelStatusId',
}

export type ChannelStatusChannelAssigneeScalarWhereInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  channelStatusId?: Maybe<StringFilter>;
};

export type ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  channelStatusId?: Maybe<StringWithAggregatesFilter>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  channelStatusId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
};

export type ChannelStatusChannelAssigneeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput = {
  where: ChannelStatusChannelAssigneeScalarWhereInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput = {
  where: ChannelStatusChannelAssigneeScalarWhereInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput = {
  where: ChannelStatusChannelAssigneeScalarWhereInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput;
};

export type ChannelStatusChannelAssigneeUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
};

export type ChannelStatusChannelAssigneeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
};

export type ChannelStatusChannelAssigneeUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput>;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput;
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput;
};

export type ChannelStatusChannelAssigneeWhereInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  channelStatusId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  user?: Maybe<UserWhereInput>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
};

export type ChannelStatusChannelAssigneeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  channelStatusChannelAssigneeChannelStatusIdAndUserId?: Maybe<
    ChannelStatusChannelAssigneeChannelStatusChannelAssigneeChannelStatusIdAndUserIdCompoundUniqueInput
  >;
};

export type ChannelStatusCountAggregateOutputType = {
  __typename?: 'ChannelStatusCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  channelStatusAction: Scalars['Int'];
  color: Scalars['Int'];
  deletedOn: Scalars['Int'];
  isDefault: Scalars['Int'];
  name: Scalars['Int'];
  order: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  deletedById: Scalars['Int'];
  isExportedToPims: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChannelStatusCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
};

export type ChannelStatusCountOutputType = {
  __typename?: 'ChannelStatusCountOutputType';
  channels: Scalars['Int'];
  clinicWidgetRequestType: Scalars['Int'];
  workflowEventAction: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  channelSelections: Scalars['Int'];
};

export type ChannelStatusCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateManyClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyClinicSettingInputEnvelope = {
  data: Array<ChannelStatusCreateManyClinicSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyDeletedByInputEnvelope = {
  data: Array<ChannelStatusCreateManyDeletedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateNestedManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusCreateNestedManyWithoutDeletedByInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutChannelsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelsInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
};

export type ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelSelectionsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutChannelSelectionsInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelsInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutChannelsInput;
};

export type ChannelStatusCreateOrConnectWithoutClinicSettingInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutClinicSettingInput;
};

export type ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput;
};

export type ChannelStatusCreateOrConnectWithoutDeletedByInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutDeletedByInput;
};

export type ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput;
};

export type ChannelStatusCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusCreateWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusListRelationFilter = {
  every?: Maybe<ChannelStatusWhereInput>;
  some?: Maybe<ChannelStatusWhereInput>;
  none?: Maybe<ChannelStatusWhereInput>;
};

export type ChannelStatusMaxAggregateOutputType = {
  __typename?: 'ChannelStatusMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
};

export type ChannelStatusMinAggregateOutputType = {
  __typename?: 'ChannelStatusMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
};

export type ChannelStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelStatusOrderByRelevanceFieldEnum {
  Id = 'id',
  Color = 'color',
  Name = 'name',
  ClinicSettingId = 'clinicSettingId',
  DeletedById = 'deletedById',
}

export type ChannelStatusOrderByRelevanceInput = {
  fields: Array<ChannelStatusOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ChannelStatusOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  _count?: Maybe<ChannelStatusCountOrderByAggregateInput>;
  _avg?: Maybe<ChannelStatusAvgOrderByAggregateInput>;
  _max?: Maybe<ChannelStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelStatusMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelStatusSumOrderByAggregateInput>;
};

export type ChannelStatusOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  channelStatusAction?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  deletedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  workflowEventAction?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelSelections?: Maybe<ChannelSelectionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelStatusOrderByRelevanceInput>;
};

export type ChannelStatusRelationFilter = {
  is?: Maybe<ChannelStatusWhereInput>;
  isNot?: Maybe<ChannelStatusWhereInput>;
};

export enum ChannelStatusScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ChannelStatusAction = 'channelStatusAction',
  Color = 'color',
  DeletedOn = 'deletedOn',
  IsDefault = 'isDefault',
  Name = 'name',
  Order = 'order',
  ClinicSettingId = 'clinicSettingId',
  DeletedById = 'deletedById',
  IsExportedToPims = 'isExportedToPims',
}

export type ChannelStatusScalarWhereInput = {
  AND?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  OR?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  channelStatusAction?: Maybe<EnumChannelStatusActionFilter>;
  color?: Maybe<StringFilter>;
  deletedOn?: Maybe<DateTimeNullableFilter>;
  isDefault?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  deletedById?: Maybe<StringNullableFilter>;
  isExportedToPims?: Maybe<BoolNullableFilter>;
};

export type ChannelStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  channelStatusAction?: Maybe<EnumChannelStatusActionWithAggregatesFilter>;
  color?: Maybe<StringWithAggregatesFilter>;
  deletedOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isDefault?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  order?: Maybe<IntNullableWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  deletedById?: Maybe<StringNullableWithAggregatesFilter>;
  isExportedToPims?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type ChannelStatusSumAggregateOutputType = {
  __typename?: 'ChannelStatusSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type ChannelStatusSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ChannelStatusUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
};

export type ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusUncheckedUpdateManyWithoutClinicSettingInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutDeletedByInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutDeletedByInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateWithoutDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput = {
  where: ChannelStatusScalarWhereInput;
  data: ChannelStatusUncheckedUpdateManyWithoutStatusesInput;
};

export type ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput = {
  where: ChannelStatusScalarWhereInput;
  data: ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput;
};

export type ChannelStatusUpdateManyWithWhereWithoutDeletedByInput = {
  where: ChannelStatusScalarWhereInput;
  data: ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput;
};

export type ChannelStatusUpdateManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUpdateManyWithoutClinicSettingInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUpdateManyWithoutDeletedByInput = {
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutDeletedByInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
};

export type ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
};

export type ChannelStatusUpdateOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutAutomationRunActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput>;
};

export type ChannelStatusUpdateOneWithoutChannelsInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutChannelsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutChannelsInput>;
};

export type ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutClinicWidgetRequestTypeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput>;
};

export type ChannelStatusUpdateOneWithoutWorkflowEventActionInput = {
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutWorkflowEventActionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput>;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput = {
  where: ChannelStatusWhereUniqueInput;
  data: ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput = {
  where: ChannelStatusWhereUniqueInput;
  data: ChannelStatusUncheckedUpdateWithoutClinicSettingInput;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput = {
  where: ChannelStatusWhereUniqueInput;
  data: ChannelStatusUncheckedUpdateWithoutDeletedByInput;
};

export type ChannelStatusUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpdateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeInput>;
};

export type ChannelStatusUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpdateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpdateWithoutDeletedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpdateWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesInput>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesInput>;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput = {
  where: ChannelStatusWhereUniqueInput;
  update: ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput;
  create: ChannelStatusUncheckedCreateWithoutChannelSelectionsInput;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput = {
  where: ChannelStatusWhereUniqueInput;
  update: ChannelStatusUncheckedUpdateWithoutClinicSettingInput;
  create: ChannelStatusUncheckedCreateWithoutClinicSettingInput;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput = {
  where: ChannelStatusWhereUniqueInput;
  update: ChannelStatusUncheckedUpdateWithoutDeletedByInput;
  create: ChannelStatusUncheckedCreateWithoutDeletedByInput;
};

export type ChannelStatusUpsertWithoutAutomationRunActionsInput = {
  update: ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput;
  create: ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ChannelStatusUpsertWithoutChannelStatusChannelAssigneesInput = {
  update: ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
  create: ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusUpsertWithoutChannelsInput = {
  update: ChannelStatusUncheckedUpdateWithoutChannelsInput;
  create: ChannelStatusUncheckedCreateWithoutChannelsInput;
};

export type ChannelStatusUpsertWithoutClinicWidgetRequestTypeInput = {
  update: ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
  create: ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput;
};

export type ChannelStatusUpsertWithoutWorkflowEventActionInput = {
  update: ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput;
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput;
};

export type ChannelStatusWhereInput = {
  AND?: Maybe<Array<ChannelStatusWhereInput>>;
  OR?: Maybe<Array<ChannelStatusWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  channelStatusAction?: Maybe<EnumChannelStatusActionFilter>;
  color?: Maybe<StringFilter>;
  deletedOn?: Maybe<DateTimeNullableFilter>;
  isDefault?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  deletedById?: Maybe<StringNullableFilter>;
  isExportedToPims?: Maybe<BoolNullableFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  deletedBy?: Maybe<UserWhereInput>;
  channels?: Maybe<ChannelListRelationFilter>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  workflowEventAction?: Maybe<WorkflowEventActionListRelationFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelSelections?: Maybe<ChannelSelectionListRelationFilter>;
};

export type ChannelStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelSubscription = {
  __typename?: 'ChannelSubscription';
  node: Channel;
  mutation: MutationType;
};

export enum ChannelType {
  Client = 'Client',
  Team = 'Team',
}

export type ChannelUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput = {
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutLastMessageInput = {
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
};

export type ChannelUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutCreationSourceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelUncheckedUpdateManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionInput = {
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutLastMessageChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelUncheckedUpdateManyWithoutLastMessageInput = {
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutLastMessageInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutLastMessageInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateManyWithoutPetsInput = {
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutCreationSourceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  lastMessageId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelStatusId?: Maybe<Scalars['String']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelInput>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelInput>;
};

export type ChannelUnreadMessageCountResult = {
  __typename?: 'ChannelUnreadMessageCountResult';
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
  client?: Maybe<ChannelCountResult>;
  team?: Maybe<ChannelCountResult>;
};

export type ChannelUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
};

export type ChannelUpdateManyWithWhereWithoutAssigneesInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithWhereWithoutChannelStatusInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithWhereWithoutClinicInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithWhereWithoutLastMessageInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutLastMessageChannelInput;
};

export type ChannelUpdateManyWithWhereWithoutPetsInput = {
  where: ChannelScalarWhereInput;
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
};

export type ChannelUpdateManyWithoutAssigneesInput = {
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutCurrentWorkflowActionInput = {
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutLastMessageInput = {
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutLastMessageInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutLastMessageInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateManyWithoutPetsInput = {
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
};

export type ChannelUpdateOneRequiredWithoutAutomationStatusesInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutAutomationStatusesInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAutomationStatusesInput>;
  upsert?: Maybe<ChannelUpsertWithoutAutomationStatusesInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutAutomationStatusesInput>;
};

export type ChannelUpdateOneRequiredWithoutCreationSourceInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutCreationSourceInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutCreationSourceInput>;
  upsert?: Maybe<ChannelUpsertWithoutCreationSourceInput>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutCreationSourceInput>;
};

export type ChannelUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ChannelUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutAppointmentsInput>;
};

export type ChannelUpdateOneWithoutChannelMembersInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelMembersInput>;
  upsert?: Maybe<ChannelUpsertWithoutChannelMembersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutChannelMembersInput>;
};

export type ChannelUpdateOneWithoutFinancialTransactionsInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutFinancialTransactionsInput>;
  upsert?: Maybe<ChannelUpsertWithoutFinancialTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutFinancialTransactionsInput>;
};

export type ChannelUpdateOneWithoutMessagesInput = {
  create?: Maybe<ChannelUncheckedCreateWithoutMessagesInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutMessagesInput>;
  upsert?: Maybe<ChannelUpsertWithoutMessagesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChannelWhereUniqueInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutMessagesInput>;
};

export type ChannelUpdateWithWhereUniqueWithoutAssigneesInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutAssigneesInput;
};

export type ChannelUpdateWithWhereUniqueWithoutChannelStatusInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutChannelStatusInput;
};

export type ChannelUpdateWithWhereUniqueWithoutClinicInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutClinicInput;
};

export type ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput;
};

export type ChannelUpdateWithWhereUniqueWithoutLastMessageInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutLastMessageInput;
};

export type ChannelUpdateWithWhereUniqueWithoutPetsInput = {
  where: ChannelWhereUniqueInput;
  data: ChannelUncheckedUpdateWithoutPetsInput;
};

export type ChannelUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutCreationSourceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutCurrentWorkflowActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutLastMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  isActive?: Maybe<Scalars['Boolean']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsInput>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelInput>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelInput>;
};

export type ChannelUpsertWithWhereUniqueWithoutAssigneesInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutAssigneesInput;
  create: ChannelUncheckedCreateWithoutAssigneesInput;
};

export type ChannelUpsertWithWhereUniqueWithoutChannelStatusInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutChannelStatusInput;
  create: ChannelUncheckedCreateWithoutChannelStatusInput;
};

export type ChannelUpsertWithWhereUniqueWithoutClinicInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutClinicInput;
  create: ChannelUncheckedCreateWithoutClinicInput;
};

export type ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput;
  create: ChannelUncheckedCreateWithoutCurrentWorkflowActionInput;
};

export type ChannelUpsertWithWhereUniqueWithoutLastMessageInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutLastMessageInput;
  create: ChannelUncheckedCreateWithoutLastMessageInput;
};

export type ChannelUpsertWithWhereUniqueWithoutPetsInput = {
  where: ChannelWhereUniqueInput;
  update: ChannelUncheckedUpdateWithoutPetsInput;
  create: ChannelUncheckedCreateWithoutPetsInput;
};

export type ChannelUpsertWithoutAppointmentsInput = {
  update: ChannelUncheckedUpdateWithoutAppointmentsInput;
  create: ChannelUncheckedCreateWithoutAppointmentsInput;
};

export type ChannelUpsertWithoutAutomationStatusesInput = {
  update: ChannelUncheckedUpdateWithoutAutomationStatusesInput;
  create: ChannelUncheckedCreateWithoutAutomationStatusesInput;
};

export type ChannelUpsertWithoutChannelMembersInput = {
  update: ChannelUncheckedUpdateWithoutChannelMembersInput;
  create: ChannelUncheckedCreateWithoutChannelMembersInput;
};

export type ChannelUpsertWithoutCreationSourceInput = {
  update: ChannelUncheckedUpdateWithoutCreationSourceInput;
  create: ChannelUncheckedCreateWithoutCreationSourceInput;
};

export type ChannelUpsertWithoutFinancialTransactionsInput = {
  update: ChannelUncheckedUpdateWithoutFinancialTransactionsInput;
  create: ChannelUncheckedCreateWithoutFinancialTransactionsInput;
};

export type ChannelUpsertWithoutMessagesInput = {
  update: ChannelUncheckedUpdateWithoutMessagesInput;
  create: ChannelUncheckedCreateWithoutMessagesInput;
};

export enum ChannelVisibility {
  Public = 'Public',
  Private = 'Private',
  DirectMessage = 'DirectMessage',
}

export type ChannelWhereInput = {
  AND?: Maybe<Array<ChannelWhereInput>>;
  OR?: Maybe<Array<ChannelWhereInput>>;
  NOT?: Maybe<Array<ChannelWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  twilioChannelSid?: Maybe<StringNullableFilter>;
  uniqueName?: Maybe<StringNullableFilter>;
  friendlyName?: Maybe<StringNullableFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  lastMessageId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  currentWorkflowActionId?: Maybe<StringNullableFilter>;
  currentWorkflowAction?: Maybe<WorkflowEventActionWhereInput>;
  lastMessage?: Maybe<ChannelMessageWhereInput>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  messages?: Maybe<ChannelMessageListRelationFilter>;
  assignees?: Maybe<UserListRelationFilter>;
  channelType?: Maybe<EnumChannelTypeNullableFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableFilter>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
  tags?: Maybe<JsonNullableFilter>;
  automationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  creationSource?: Maybe<ChannelCreationSourceWhereInput>;
};

export type ChannelWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ChargeCaptureType {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export type CheckClinicCareInput = {
  token?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type CheckClinicCareStatusResult = {
  __typename?: 'CheckClinicCareStatusResult';
  tokenClinic?: Maybe<CareClinicInfo>;
  idClinic?: Maybe<CareClinicInfo>;
};

export type CheckPhoneVerificationInput = {
  id: Scalars['String'];
};

export type ClearTerminalDisplayResponse = {
  __typename?: 'ClearTerminalDisplayResponse';
  terminal: StripeTerminal;
  success: Scalars['Boolean'];
};

export type ClearUnreadMessagesInput = {
  userId: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
};

export type Clinic = {
  __typename?: 'Clinic';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  subscribedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  consultationCost?: Maybe<Scalars['Float']>;
  /** @deprecated - 1.0 */
  followUpCost?: Maybe<Scalars['Float']>;
  /** @deprecated - 1.0 */
  incomeMethod?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentName?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentAddress?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentCity?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentState?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentZip?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  lat?: Maybe<Scalars['Float']>;
  /** @deprecated - 1.0 */
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  integrationType?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  integrationInfo?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  corporateId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  customerId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  subscriptionId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  subscriptionPlan?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive: Scalars['Boolean'];
  /** @deprecated - 1.0 */
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  cancellationReason?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  cancellationNote?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  canceledAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  firebaseVets?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  integrationSystem?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  marketEmail?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  marketFacebookPageId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  printLastOrder?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  submerchantId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  submerchantStatus?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  crmUrlParams?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  paymentPhone?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  automaticSubscriptionReceipts: Scalars['Boolean'];
  /** @deprecated - 1.0 */
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSetting>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  usersId?: Maybe<User>;
  organization?: Maybe<Organization>;
  appointments: Array<Appointment>;
  appoinmentTypes: Array<AppointmentType>;
  channels: Array<Channel>;
  clinicEmployees: Array<ClinicEmployee>;
  clinicOfficeHours: Array<ClinicOfficeHour>;
  clinicPets: Array<ClinicPet>;
  clinicPetParents: Array<ClinicPetParent>;
  integrations: Array<ClinicPimsIntegration>;
  clinicRooms: Array<ClinicRoom>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  clinicWorkflowTypes: Array<ClinicWorkflowType>;
  /** @deprecated - 1.0 */
  consultations: Array<Consultation>;
  /** @deprecated - 1.0 */
  consultationRequests: Array<ConsultationRequest>;
  formSubmissions: Array<FormSubmission>;
  formTemplates: Array<FormTemplate>;
  /** @deprecated - 1.0 */
  petsId: Array<Pet>;
  /** @deprecated - 1.0 */
  promoCodes: Array<PromoCode>;
  roles: Array<StaffRole>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  staffId: Array<VetInfo>;
  workflowEventSettings: Array<WorkflowEventSetting>;
  stripePayoutAccounts: Array<StripePayoutAccount>;
  /** @deprecated - 1.0 */
  pets: Array<Pet>;
  staff: Array<VetInfo>;
  users: Array<User>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications: Array<TwilioVerification>;
  stripeTerminalLocations: Array<StripeTerminalLocation>;
  twilioConfiguration?: Maybe<TwilioConfiguration>;
  financialTransactions: Array<FinancialTransaction>;
  featureFlags: Array<FeatureFlag>;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  clinicEmailCampaignSettings: Array<ClinicEmailCampaignSetting>;
  calls: Array<CallHistory>;
  benefitsProvided: Array<CareBenefitUsage>;
  carePayoutOrganization?: Maybe<Organization>;
  vetAvailabilities: Array<VetAvailability>;
  clinicDirectBookingSettings: Array<ClinicDirectBookingSetting>;
  channelFilterSelections: Array<ChannelFilterSelection>;
  messageTemplates: Array<MessageTemplate>;
  Rules: Array<Rules>;
  postcardSettings: Array<ClinicPostcardSetting>;
  automationRuns: Array<AutomationRun>;
  vaccinations: Array<Vaccination>;
  massTextAlerts: Array<MassTextAlert>;
  prescriptions: Array<Prescription>;
  clinicPhoneNumber: Array<ClinicPhoneNumber>;
  _count: ClinicCountOutputType;
};

export type ClinicAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type ClinicAppoinmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};

export type ClinicChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};

export type ClinicClinicEmployeesArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};

export type ClinicClinicOfficeHoursArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicOfficeHourScalarFieldEnum>;
};

export type ClinicClinicPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type ClinicClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type ClinicIntegrationsArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPimsIntegrationScalarFieldEnum>;
};

export type ClinicClinicRoomsArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
};

export type ClinicClinicWidgetRequestsArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
};

export type ClinicClinicWorkflowTypesArgs = {
  where?: Maybe<ClinicWorkflowTypeWhereInput>;
  orderBy?: Maybe<ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWorkflowTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWorkflowTypeScalarFieldEnum>;
};

export type ClinicConsultationsArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};

export type ClinicConsultationRequestsArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationRequestScalarFieldEnum>;
};

export type ClinicFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};

export type ClinicFormTemplatesArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormTemplateScalarFieldEnum>;
};

export type ClinicPetsIdArgs = {
  where?: Maybe<PetWhereInput>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PetScalarFieldEnum>;
};

export type ClinicPromoCodesArgs = {
  where?: Maybe<PromoCodeWhereInput>;
  orderBy?: Maybe<PromoCodeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PromoCodeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PromoCodeScalarFieldEnum>;
};

export type ClinicRolesArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
};

export type ClinicStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};

export type ClinicUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};

export type ClinicStaffIdArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
};

export type ClinicWorkflowEventSettingsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};

export type ClinicStripePayoutAccountsArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePayoutAccountScalarFieldEnum>;
};

export type ClinicPetsArgs = {
  where?: Maybe<PetWhereInput>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PetScalarFieldEnum>;
};

export type ClinicStaffArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
};

export type ClinicUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};

export type ClinicTwilioVerificationsArgs = {
  where?: Maybe<TwilioVerificationWhereInput>;
  orderBy?: Maybe<TwilioVerificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<TwilioVerificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<TwilioVerificationScalarFieldEnum>;
};

export type ClinicStripeTerminalLocationsArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeTerminalLocationScalarFieldEnum>;
};

export type ClinicFinancialTransactionsArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
};

export type ClinicFeatureFlagsArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FeatureFlagScalarFieldEnum>;
};

export type ClinicChannelStatusChannelAssigneesArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
};

export type ClinicClinicEmailCampaignSettingsArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmailCampaignSettingScalarFieldEnum>;
};

export type ClinicCallsArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallHistoryScalarFieldEnum>;
};

export type ClinicBenefitsProvidedArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};

export type ClinicVetAvailabilitiesArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityScalarFieldEnum>;
};

export type ClinicClinicDirectBookingSettingsArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicDirectBookingSettingScalarFieldEnum>;
};

export type ClinicChannelFilterSelectionsArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelFilterSelectionScalarFieldEnum>;
};

export type ClinicMessageTemplatesArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MessageTemplateScalarFieldEnum>;
};

export type ClinicRulesArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<RulesScalarFieldEnum>;
};

export type ClinicPostcardSettingsArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPostcardSettingScalarFieldEnum>;
};

export type ClinicAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type ClinicVaccinationsArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
};

export type ClinicMassTextAlertsArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertScalarFieldEnum>;
};

export type ClinicPrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};

export type ClinicClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPhoneNumberScalarFieldEnum>;
};

export type ClinicAvgAggregateOutputType = {
  __typename?: 'ClinicAvgAggregateOutputType';
  lastPrintOrderedQuantity?: Maybe<Scalars['Float']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Float']>;
  marketFacebookNumber?: Maybe<Scalars['Float']>;
  printReceivedQuantity?: Maybe<Scalars['Float']>;
};

export type ClinicAvgOrderByAggregateInput = {
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
};

export type ClinicCancellationInput = {
  cancellationReason: Scalars['String'];
  cancellationNote: Scalars['String'];
};

export type ClinicContactInfo = {
  __typename?: 'ClinicContactInfo';
  name?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  emergencyClinics?: Maybe<Array<Maybe<EmergencyClinicContactInfo>>>;
};

export type ClinicCountAggregateOutputType = {
  __typename?: 'ClinicCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  city: Scalars['Int'];
  state: Scalars['Int'];
  country: Scalars['Int'];
  addressLine1: Scalars['Int'];
  addressLine2: Scalars['Int'];
  postalCode: Scalars['Int'];
  websiteUrl: Scalars['Int'];
  hours: Scalars['Int'];
  logo: Scalars['Int'];
  lastPrintOrderedOn: Scalars['Int'];
  lastPrintOrderedQuantity: Scalars['Int'];
  subscribedAt: Scalars['Int'];
  consultationCost: Scalars['Int'];
  followUpCost: Scalars['Int'];
  incomeMethod: Scalars['Int'];
  paymentName: Scalars['Int'];
  paymentAddress: Scalars['Int'];
  paymentCity: Scalars['Int'];
  paymentState: Scalars['Int'];
  paymentZip: Scalars['Int'];
  paymentBankRoutingNumber: Scalars['Int'];
  paymentBankAccountNumber: Scalars['Int'];
  firebaseId: Scalars['Int'];
  timezone: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  lat: Scalars['Int'];
  long: Scalars['Int'];
  fullAddress: Scalars['Int'];
  stripeSubscriptionId: Scalars['Int'];
  stripeSubscriptionPlan: Scalars['Int'];
  token: Scalars['Int'];
  phone: Scalars['Int'];
  widgetSettings: Scalars['Int'];
  responseTime: Scalars['Int'];
  organizationId: Scalars['Int'];
  ppcLocationId: Scalars['Int'];
  integrationType: Scalars['Int'];
  integrationInfo: Scalars['Int'];
  corporateId: Scalars['Int'];
  customerId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  subscriptionPlan: Scalars['Int'];
  isIntegrationActive: Scalars['Int'];
  isClinicActive: Scalars['Int'];
  unsubscribedAt: Scalars['Int'];
  isPaymentUsBank: Scalars['Int'];
  isConsultationsActive: Scalars['Int'];
  cancellationReason: Scalars['Int'];
  cancellationNote: Scalars['Int'];
  canceledAt: Scalars['Int'];
  allowAllUsersToCloseConsultations: Scalars['Int'];
  clinicEmail: Scalars['Int'];
  firebaseVets: Scalars['Int'];
  integrationSystem: Scalars['Int'];
  marketEmail: Scalars['Int'];
  marketFacebookNumber: Scalars['Int'];
  marketFacebookPageId: Scalars['Int'];
  marketFacebookPageToken: Scalars['Int'];
  printLastOrder: Scalars['Int'];
  printReceivedQuantity: Scalars['Int'];
  submerchantApprovedAt: Scalars['Int'];
  submerchantId: Scalars['Int'];
  headUserId: Scalars['Int'];
  crmId: Scalars['Int'];
  submerchantStatus: Scalars['Int'];
  submerchantIsActive: Scalars['Int'];
  crmUrlParams: Scalars['Int'];
  paymentPhone: Scalars['Int'];
  automaticSubscriptionReceipts: Scalars['Int'];
  autoSubscriptionReceiptEmails: Scalars['Int'];
  submerchantStripeId: Scalars['Int'];
  submerchantStripeStatus: Scalars['Int'];
  submerchantStripeChargesEnabled: Scalars['Int'];
  submerchantStripePayoutsEnabled: Scalars['Int'];
  submerchantStripeHasCustomPayouts: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  timezoneName: Scalars['Int'];
  clinicWidgetSettingId: Scalars['Int'];
  activationDate: Scalars['Int'];
  isAddAppointmentDisabled: Scalars['Int'];
  hasServiceReminders: Scalars['Int'];
  hasSmsConversations: Scalars['Int'];
  callerIdPhoneNumber: Scalars['Int'];
  smsPhoneNumber: Scalars['Int'];
  isCallerIdVerified: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  integrationInfo?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
};

export type ClinicCountOutputType = {
  __typename?: 'ClinicCountOutputType';
  appointments: Scalars['Int'];
  appoinmentTypes: Scalars['Int'];
  channels: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  clinicOfficeHours: Scalars['Int'];
  clinicPets: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  integrations: Scalars['Int'];
  clinicRooms: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  clinicWorkflowTypes: Scalars['Int'];
  consultations: Scalars['Int'];
  consultationRequests: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  formTemplates: Scalars['Int'];
  petsId: Scalars['Int'];
  promoCodes: Scalars['Int'];
  roles: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  staffId: Scalars['Int'];
  workflowEventSettings: Scalars['Int'];
  stripePayoutAccounts: Scalars['Int'];
  pets: Scalars['Int'];
  staff: Scalars['Int'];
  users: Scalars['Int'];
  twilioVerifications: Scalars['Int'];
  stripeTerminalLocations: Scalars['Int'];
  financialTransactions: Scalars['Int'];
  featureFlags: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  clinicEmailCampaignSettings: Scalars['Int'];
  calls: Scalars['Int'];
  benefitsProvided: Scalars['Int'];
  vetAvailabilities: Scalars['Int'];
  clinicDirectBookingSettings: Scalars['Int'];
  channelFilterSelections: Scalars['Int'];
  messageTemplates: Scalars['Int'];
  Rules: Scalars['Int'];
  postcardSettings: Scalars['Int'];
  automationRuns: Scalars['Int'];
  vaccinations: Scalars['Int'];
  massTextAlerts: Scalars['Int'];
  prescriptions: Scalars['Int'];
  clinicPhoneNumber: Scalars['Int'];
};

export type ClinicCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateManyClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyClinicWidgetSettingInputEnvelope = {
  data: Array<ClinicCreateManyClinicWidgetSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyOrganizationInputEnvelope = {
  data: Array<ClinicCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyUsersIdInputEnvelope = {
  data: Array<ClinicCreateManyUsersIdInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateNestedManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutFeatureFlagsInput = {
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutStaffInput = {
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutStripePayoutAccountsInput = {
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutUsersIdInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicCreateNestedOneWithoutAppoinmentTypesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppoinmentTypesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppoinmentTypesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutBenefitsProvidedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutBenefitsProvidedInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBenefitsProvidedInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutCallsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCallsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutCarePayoutOrganizationInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutCarePayoutOrganizationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCarePayoutOrganizationInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutChannelFilterSelectionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelFilterSelectionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutChannelsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicDirectBookingSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicEmailCampaignSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicEmployeesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmployeesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmployeesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicOfficeHoursInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOfficeHoursInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOfficeHoursInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicPetParentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetParentsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicPetsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicPhoneNumberInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPhoneNumberInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPhoneNumberInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicRoomsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicRoomsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicRoomsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWidgetRequestsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutClinicWorkflowTypesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWorkflowTypesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWorkflowTypesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutConsultationRequestsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationRequestsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationRequestsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutConsultationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutFinancialTransactionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFinancialTransactionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutFormTemplatesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFormTemplatesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormTemplatesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutIntegrationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutIntegrationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutIntegrationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutMassTextAlertsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMassTextAlertsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMassTextAlertsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutMessageTemplatesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMessageTemplatesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMessageTemplatesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPetsIdInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPetsIdInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPetsIdInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPostcardSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPostcardSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPostcardSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutPromoCodesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPromoCodesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPromoCodesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutRolesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutRolesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRolesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutRulesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutRulesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRulesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutStaffIdInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStaffIdInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStaffIdInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutStripeTerminalLocationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStripeTerminalLocationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripeTerminalLocationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutTwilioConfigurationInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioConfigurationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioConfigurationInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutTwilioVerificationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioVerificationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioVerificationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutVaccinationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVaccinationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutVetAvailabilitiesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVetAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetAvailabilitiesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateNestedOneWithoutWorkflowEventSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutWorkflowEventSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicCreateOrConnectWithoutAppoinmentTypesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutAppoinmentTypesInput;
};

export type ClinicCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicCreateOrConnectWithoutAutomationRunsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicCreateOrConnectWithoutBenefitsProvidedInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutBenefitsProvidedInput;
};

export type ClinicCreateOrConnectWithoutCallsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutCallsInput;
};

export type ClinicCreateOrConnectWithoutCarePayoutOrganizationInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutCarePayoutOrganizationInput;
};

export type ClinicCreateOrConnectWithoutChannelFilterSelectionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutChannelFilterSelectionsInput;
};

export type ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type ClinicCreateOrConnectWithoutChannelsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutChannelsInput;
};

export type ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput;
};

export type ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicCreateOrConnectWithoutClinicEmployeesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicEmployeesInput;
};

export type ClinicCreateOrConnectWithoutClinicOfficeHoursInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicOfficeHoursInput;
};

export type ClinicCreateOrConnectWithoutClinicPetParentsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicPetParentsInput;
};

export type ClinicCreateOrConnectWithoutClinicPetsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicPetsInput;
};

export type ClinicCreateOrConnectWithoutClinicPhoneNumberInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicPhoneNumberInput;
};

export type ClinicCreateOrConnectWithoutClinicRoomsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicRoomsInput;
};

export type ClinicCreateOrConnectWithoutClinicSettingInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicSettingInput;
};

export type ClinicCreateOrConnectWithoutClinicWidgetRequestsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicCreateOrConnectWithoutClinicWidgetSettingInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicWidgetSettingInput;
};

export type ClinicCreateOrConnectWithoutClinicWorkflowTypesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutClinicWorkflowTypesInput;
};

export type ClinicCreateOrConnectWithoutConsultationRequestsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutConsultationRequestsInput;
};

export type ClinicCreateOrConnectWithoutConsultationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutConsultationsInput;
};

export type ClinicCreateOrConnectWithoutFeatureFlagsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutFeatureFlagsInput;
};

export type ClinicCreateOrConnectWithoutFinancialTransactionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutFinancialTransactionsInput;
};

export type ClinicCreateOrConnectWithoutFormSubmissionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicCreateOrConnectWithoutFormTemplatesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutFormTemplatesInput;
};

export type ClinicCreateOrConnectWithoutIntegrationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutIntegrationsInput;
};

export type ClinicCreateOrConnectWithoutMassTextAlertsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutMassTextAlertsInput;
};

export type ClinicCreateOrConnectWithoutMessageTemplatesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutMessageTemplatesInput;
};

export type ClinicCreateOrConnectWithoutOrganizationInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutOrganizationInput;
};

export type ClinicCreateOrConnectWithoutPetsIdInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPetsIdInput;
};

export type ClinicCreateOrConnectWithoutPetsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPetsInput;
};

export type ClinicCreateOrConnectWithoutPostcardSettingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPostcardSettingsInput;
};

export type ClinicCreateOrConnectWithoutPrescriptionsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicCreateOrConnectWithoutPromoCodesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutPromoCodesInput;
};

export type ClinicCreateOrConnectWithoutRolesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutRolesInput;
};

export type ClinicCreateOrConnectWithoutRulesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutRulesInput;
};

export type ClinicCreateOrConnectWithoutStaffIdInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStaffIdInput;
};

export type ClinicCreateOrConnectWithoutStaffInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStaffInput;
};

export type ClinicCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type ClinicCreateOrConnectWithoutStripePayoutAccountsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStripePayoutAccountsInput;
};

export type ClinicCreateOrConnectWithoutStripeTerminalLocationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutStripeTerminalLocationsInput;
};

export type ClinicCreateOrConnectWithoutTwilioConfigurationInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutTwilioConfigurationInput;
};

export type ClinicCreateOrConnectWithoutTwilioVerificationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutTwilioVerificationsInput;
};

export type ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicCreateOrConnectWithoutUsersIdInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutUsersIdInput;
};

export type ClinicCreateOrConnectWithoutUsersInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutUsersInput;
};

export type ClinicCreateOrConnectWithoutVaccinationsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicCreateOrConnectWithoutVetAvailabilitiesInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutVetAvailabilitiesInput;
};

export type ClinicCreateOrConnectWithoutWorkflowEventSettingsInput = {
  where: ClinicWhereUniqueInput;
  create: ClinicUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type ClinicCreateWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutCarePayoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutTwilioConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
};

export type ClinicDirectBookingSetting = {
  __typename?: 'ClinicDirectBookingSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation: Scalars['Boolean'];
  isOneTimePasswordEnabled: Scalars['Boolean'];
  clinic: Clinic;
};

export type ClinicDirectBookingSettingAvgAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingAvgAggregateOutputType';
  maxSchedulingRangeInMonths?: Maybe<Scalars['Float']>;
  bookingDeadlineInHours?: Maybe<Scalars['Float']>;
};

export type ClinicDirectBookingSettingAvgOrderByAggregateInput = {
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingCountAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  maxSchedulingRangeInMonths: Scalars['Int'];
  bookingDeadlineInHours: Scalars['Int'];
  bookingNeedsConfirmation: Scalars['Int'];
  isOneTimePasswordEnabled: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicDirectBookingSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  clinic: ClinicCreateNestedOneWithoutClinicDirectBookingSettingsInput;
};

export type ClinicDirectBookingSettingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicDirectBookingSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
};

export type ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
  create: ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicDirectBookingSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingListRelationFilter = {
  every?: Maybe<ClinicDirectBookingSettingWhereInput>;
  some?: Maybe<ClinicDirectBookingSettingWhereInput>;
  none?: Maybe<ClinicDirectBookingSettingWhereInput>;
};

export type ClinicDirectBookingSettingMaxAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingMinAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicDirectBookingSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
}

export type ClinicDirectBookingSettingOrderByRelevanceInput = {
  fields: Array<ClinicDirectBookingSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicDirectBookingSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  _count?: Maybe<ClinicDirectBookingSettingCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicDirectBookingSettingAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicDirectBookingSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicDirectBookingSettingMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicDirectBookingSettingSumOrderByAggregateInput>;
};

export type ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicDirectBookingSettingOrderByRelevanceInput>;
};

export enum ClinicDirectBookingSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  MaxSchedulingRangeInMonths = 'maxSchedulingRangeInMonths',
  BookingDeadlineInHours = 'bookingDeadlineInHours',
  BookingNeedsConfirmation = 'bookingNeedsConfirmation',
  IsOneTimePasswordEnabled = 'isOneTimePasswordEnabled',
}

export type ClinicDirectBookingSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableFilter>;
  bookingNeedsConfirmation?: Maybe<BoolFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolFilter>;
};

export type ClinicDirectBookingSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableWithAggregatesFilter>;
  bookingNeedsConfirmation?: Maybe<BoolWithAggregatesFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolWithAggregatesFilter>;
};

export type ClinicDirectBookingSettingSumAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingSumAggregateOutputType';
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingSumOrderByAggregateInput = {
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
};

export type ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
};

export type ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicDirectBookingSettingsInput>;
};

export type ClinicDirectBookingSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicDirectBookingSettingScalarWhereInput;
  data: ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicDirectBookingSettingsInput;
};

export type ClinicDirectBookingSettingUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
};

export type ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
  data: ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput;
};

export type ClinicDirectBookingSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
  update: ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicDirectBookingSettingWhereInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableFilter>;
  bookingNeedsConfirmation?: Maybe<BoolFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicDirectBookingSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicDirectBookingSettingsInput = {
  clinicId: Scalars['String'];
};

export type ClinicDirectBookingSettingsResponse = {
  __typename?: 'ClinicDirectBookingSettingsResponse';
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmailCampaignSetting = {
  __typename?: 'ClinicEmailCampaignSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic: Clinic;
};

export type ClinicEmailCampaignSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  accountId: Scalars['Int'];
  userGroupId: Scalars['Int'];
  contactListId: Scalars['Int'];
  petSpeciesTypeFieldId: Scalars['Int'];
  careUrlFieldId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEmailCampaignSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicEmailCampaignSettingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicEmailCampaignSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmailCampaignSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
};

export type ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
  create: ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicEmailCampaignSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingListRelationFilter = {
  every?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  some?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  none?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};

export type ClinicEmailCampaignSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmailCampaignSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  AccountId = 'accountId',
  UserGroupId = 'userGroupId',
  ContactListId = 'contactListId',
  PetSpeciesTypeFieldId = 'petSpeciesTypeFieldId',
  CareUrlFieldId = 'careUrlFieldId',
}

export type ClinicEmailCampaignSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmailCampaignSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEmailCampaignSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEmailCampaignSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmailCampaignSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmailCampaignSettingMinOrderByAggregateInput>;
};

export type ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicEmailCampaignSettingOrderByRelevanceInput>;
};

export enum ClinicEmailCampaignSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  AccountId = 'accountId',
  UserGroupId = 'userGroupId',
  ContactListId = 'contactListId',
  PetSpeciesTypeFieldId = 'petSpeciesTypeFieldId',
  CareUrlFieldId = 'careUrlFieldId',
}

export type ClinicEmailCampaignSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  accountId?: Maybe<StringFilter>;
  userGroupId?: Maybe<StringFilter>;
  contactListId?: Maybe<StringFilter>;
  petSpeciesTypeFieldId?: Maybe<StringFilter>;
  careUrlFieldId?: Maybe<StringNullableFilter>;
};

export type ClinicEmailCampaignSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  accountId?: Maybe<StringWithAggregatesFilter>;
  userGroupId?: Maybe<StringWithAggregatesFilter>;
  contactListId?: Maybe<StringWithAggregatesFilter>;
  petSpeciesTypeFieldId?: Maybe<StringWithAggregatesFilter>;
  careUrlFieldId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicEmailCampaignSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
};

export type ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId: Scalars['String'];
  userGroupId: Scalars['String'];
  contactListId: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicEmailCampaignSettingsInput>;
};

export type ClinicEmailCampaignSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicEmailCampaignSettingScalarWhereInput;
  data: ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicEmailCampaignSettingUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
};

export type ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
  data: ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput;
};

export type ClinicEmailCampaignSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
  update: ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicEmailCampaignSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  accountId?: Maybe<StringFilter>;
  userGroupId?: Maybe<StringFilter>;
  contactListId?: Maybe<StringFilter>;
  petSpeciesTypeFieldId?: Maybe<StringFilter>;
  careUrlFieldId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicEmailCampaignSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicEmployee = {
  __typename?: 'ClinicEmployee';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<Clinic>;
  integration?: Maybe<ClinicPimsIntegration>;
  appointments: Array<Appointment>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows: Array<ClinicEmployeeToWorkflowEventSetting>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems: Array<InvoiceLineItem>;
  enteredByInvoiceLineItems: Array<InvoiceLineItem>;
  vetAvailabilityMaps: Array<VetAvailabilityMap>;
  clinicEmployeeAppointmentTypeSettings: Array<ClinicEmployeeAppointmentTypeSetting>;
  prescriptions: Array<Prescription>;
  _count: ClinicEmployeeCountOutputType;
};

export type ClinicEmployeeAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type ClinicEmployeeUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};

export type ClinicEmployeeWorkflowsArgs = {
  where?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  orderBy?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeToWorkflowEventSettingScalarFieldEnum>;
};

export type ClinicEmployeeProviderInvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};

export type ClinicEmployeeEnteredByInvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};

export type ClinicEmployeeVetAvailabilityMapsArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityMapScalarFieldEnum>;
};

export type ClinicEmployeeClinicEmployeeAppointmentTypeSettingsArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>;
};

export type ClinicEmployeePrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};

export type ClinicEmployeeAppointmentTypeSetting = {
  __typename?: 'ClinicEmployeeAppointmentTypeSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicEmployeeId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  clinicEmployee: ClinicEmployee;
  appointmentType: AppointmentType;
};

export type ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEmployeeAppointmentTypeSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
  appointmentType: AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope = {
  data: Array<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope = {
  data: Array<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingListRelationFilter = {
  every?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  some?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  none?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};

export type ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeAppointmentTypeSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicEmployeeId = 'clinicEmployeeId',
  AppointmentTypeId = 'appointmentTypeId',
}

export type ClinicEmployeeAppointmentTypeSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeAppointmentTypeSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEmployeeAppointmentTypeSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeAppointmentTypeSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeAppointmentTypeSettingMinOrderByAggregateInput>;
};

export type ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelevanceInput>;
};

export enum ClinicEmployeeAppointmentTypeSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicEmployeeId = 'clinicEmployeeId',
  AppointmentTypeId = 'appointmentTypeId',
}

export type ClinicEmployeeAppointmentTypeSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
};

export type ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: ClinicEmployeeAppointmentTypeSettingScalarWhereInput;
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput = {
  where: ClinicEmployeeAppointmentTypeSettingScalarWhereInput;
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithoutClinicEmployeeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  update: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  update: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput;
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeAppointmentTypeSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
};

export type ClinicEmployeeAppointmentTypeSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  type: Scalars['Int'];
  pimsId: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  integrationId: Scalars['Int'];
  clinicId: Scalars['Int'];
  isSchedulable: Scalars['Int'];
  isDefaultExporter: Scalars['Int'];
  isVisible: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEmployeeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicEmployeeCountOutputType = {
  __typename?: 'ClinicEmployeeCountOutputType';
  appointments: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  workflows: Scalars['Int'];
  providerInvoiceLineItems: Scalars['Int'];
  enteredByInvoiceLineItems: Scalars['Int'];
  vetAvailabilityMaps: Scalars['Int'];
  clinicEmployeeAppointmentTypeSettings: Scalars['Int'];
  prescriptions: Scalars['Int'];
};

export type ClinicEmployeeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeCreateManyClinicInputEnvelope = {
  data: Array<ClinicEmployeeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicEmployeeCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutWorkflowsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutWorkflowsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutWorkflowsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
};

export type ClinicEmployeeCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutClinicInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutClinicInput;
};

export type ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutIntegrationInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutIntegrationInput;
};

export type ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput;
};

export type ClinicEmployeeCreateOrConnectWithoutWorkflowsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicEmployeeCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
};

export type ClinicEmployeeCreateWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeListRelationFilter = {
  every?: Maybe<ClinicEmployeeWhereInput>;
  some?: Maybe<ClinicEmployeeWhereInput>;
  none?: Maybe<ClinicEmployeeWhereInput>;
};

export type ClinicEmployeeMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
};

export type ClinicEmployeeMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
};

export type ClinicEmployeeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeOrderByRelevanceFieldEnum {
  Id = 'id',
  Type = 'type',
  PimsId = 'pimsId',
  FirstName = 'firstName',
  LastName = 'lastName',
  IntegrationId = 'integrationId',
  ClinicId = 'clinicId',
}

export type ClinicEmployeeOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEmployeeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEmployeeCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeMinOrderByAggregateInput>;
};

export type ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  isDefaultExporter?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput>;
  rawPimsValue?: Maybe<SortOrder>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapOrderByRelationAggregateInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicEmployeeOrderByRelevanceInput>;
};

export type ClinicEmployeePimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ClinicEmployeeRelationFilter = {
  is?: Maybe<ClinicEmployeeWhereInput>;
  isNot?: Maybe<ClinicEmployeeWhereInput>;
};

export enum ClinicEmployeeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Type = 'type',
  PimsId = 'pimsId',
  FirstName = 'firstName',
  LastName = 'lastName',
  IntegrationId = 'integrationId',
  ClinicId = 'clinicId',
  IsSchedulable = 'isSchedulable',
  IsDefaultExporter = 'isDefaultExporter',
  IsVisible = 'isVisible',
  RawPimsValue = 'rawPimsValue',
}

export type ClinicEmployeeScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  isDefaultExporter?: Maybe<BoolNullableFilter>;
  isVisible?: Maybe<BoolNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicEmployeeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  type?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  isSchedulable?: Maybe<BoolNullableWithAggregatesFilter>;
  isDefaultExporter?: Maybe<BoolNullableWithAggregatesFilter>;
  isVisible?: Maybe<BoolNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicEmployeeToWorkflowEventSetting = {
  __typename?: 'ClinicEmployeeToWorkflowEventSetting';
  createdAt: Scalars['DateTime'];
  workflowId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
  workflow: WorkflowEventSetting;
  clinicEmployee: ClinicEmployee;
};

export type ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType';
  createdAt: Scalars['Int'];
  workflowId: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEmployeeToWorkflowEventSettingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutWorkflowsInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope = {
  data: Array<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope = {
  data: Array<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutWorkflowsInput;
};

export type ClinicEmployeeToWorkflowEventSettingListRelationFilter = {
  every?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  some?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  none?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
};

export type ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeToWorkflowEventSettingOrderByRelevanceFieldEnum {
  WorkflowId = 'workflowId',
  ClinicEmployeeId = 'clinicEmployeeId',
}

export type ClinicEmployeeToWorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeToWorkflowEventSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEmployeeToWorkflowEventSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeToWorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeToWorkflowEventSettingMinOrderByAggregateInput>;
};

export type ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelevanceInput>;
};

export enum ClinicEmployeeToWorkflowEventSettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  WorkflowId = 'workflowId',
  ClinicEmployeeId = 'clinicEmployeeId',
}

export type ClinicEmployeeToWorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
};

export type ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutWorkflowsInput>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput = {
  where: ClinicEmployeeToWorkflowEventSettingScalarWhereInput;
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput = {
  where: ClinicEmployeeToWorkflowEventSettingScalarWhereInput;
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeesInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesInput>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutWorkflowsInput>;
};

export type ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  update: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput;
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput = {
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
  update: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
};

export type ClinicEmployeeToWorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
};

export type ClinicEmployeeToWorkflowEventSettingWhereUniqueInput = {
  workflowId_clinicEmployeeId?: Maybe<
    ClinicEmployeeToWorkflowEventSettingWorkflowIdClinicEmployeeIdCompoundUniqueInput
  >;
};

export type ClinicEmployeeToWorkflowEventSettingWorkflowIdClinicEmployeeIdCompoundUniqueInput = {
  workflowId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type ClinicEmployeeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
};

export type ClinicEmployeeUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
};

export type ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
};

export type ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutEmployeesInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  where: ClinicEmployeeScalarWhereInput;
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
};

export type ClinicEmployeeUpdateManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutVetAvailabilityMapsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutWorkflowsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutWorkflowsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutWorkflowsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutWorkflowsInput>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput>;
};

export type ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutEnteredByInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput>;
};

export type ClinicEmployeeUpdateOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutPrescriptionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsInput = {
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutProviderInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput>;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutClinicInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  data: ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
};

export type ClinicEmployeeUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
};

export type ClinicEmployeeUpdateWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<
    ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeInput
  >;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorInput>;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutClinicInput;
  create: ClinicEmployeeUncheckedCreateWithoutClinicInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutIntegrationInput;
  create: ClinicEmployeeUncheckedCreateWithoutIntegrationInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: ClinicEmployeeWhereUniqueInput;
  update: ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicEmployeeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput;
  create: ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeUpsertWithoutEnteredByInvoiceLineItemsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput;
  create: ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput;
};

export type ClinicEmployeeUpsertWithoutPrescriptionsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput;
  create: ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicEmployeeUpsertWithoutProviderInvoiceLineItemsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput;
  create: ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput;
};

export type ClinicEmployeeUpsertWithoutVetAvailabilityMapsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput;
  create: ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput;
};

export type ClinicEmployeeUpsertWithoutWorkflowsInput = {
  update: ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput;
  create: ClinicEmployeeUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicEmployeeWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  isDefaultExporter?: Maybe<BoolNullableFilter>;
  isVisible?: Maybe<BoolNullableFilter>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingListRelationFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapListRelationFilter>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
};

export type ClinicEmployeeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<ClinicEmployeePimsIdIntegrationIdCompoundUniqueInput>;
};

export enum ClinicEntityCreationSource {
  Pims = 'Pims',
  ClinicApp = 'ClinicApp',
  WidgetRequest = 'WidgetRequest',
  InboundSms = 'InboundSms',
}

export type ClinicEntityPhoneNumber = {
  __typename?: 'ClinicEntityPhoneNumber';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  /** This is the result from the last time we tried to send */
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  /** The last time we tried to send an sms to this number */
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  /** the last time opt out messaging was sent to this number */
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  /** whether this number has opted out of messaging */
  isOptedOut: Scalars['Boolean'];
  clinicPetParent?: Maybe<ClinicPetParent>;
};

export type ClinicEntityPhoneNumberCountAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  pimsId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPrimary: Scalars['Int'];
  isPims: Scalars['Int'];
  isMobile: Scalars['Int'];
  number: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  smsStatus: Scalars['Int'];
  lastSmsSendDate: Scalars['Int'];
  lastOptOutSendDate: Scalars['Int'];
  isOptedOut: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicEntityPhoneNumberCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput>;
};

export type ClinicEntityPhoneNumberCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicEntityPhoneNumberCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
};

export type ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberListRelationFilter = {
  every?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  some?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  none?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};

export type ClinicEntityPhoneNumberMaxAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberMinAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEntityPhoneNumberOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsId = 'pimsId',
  Number = 'number',
  ClinicPetParentId = 'clinicPetParentId',
}

export type ClinicEntityPhoneNumberOrderByRelevanceInput = {
  fields: Array<ClinicEntityPhoneNumberOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicEntityPhoneNumberOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  _count?: Maybe<ClinicEntityPhoneNumberCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEntityPhoneNumberMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEntityPhoneNumberMinOrderByAggregateInput>;
};

export type ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicEntityPhoneNumberOrderByRelevanceInput>;
};

export enum ClinicEntityPhoneNumberScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PimsId = 'pimsId',
  IsDeleted = 'isDeleted',
  IsPrimary = 'isPrimary',
  IsPims = 'isPims',
  IsMobile = 'isMobile',
  Number = 'number',
  ClinicPetParentId = 'clinicPetParentId',
  SmsStatus = 'smsStatus',
  LastSmsSendDate = 'lastSmsSendDate',
  LastOptOutSendDate = 'lastOptOutSendDate',
  IsOptedOut = 'isOptedOut',
}

export type ClinicEntityPhoneNumberScalarWhereInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  isPims?: Maybe<BoolNullableFilter>;
  isMobile?: Maybe<BoolNullableFilter>;
  number?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableFilter>;
  isOptedOut?: Maybe<BoolFilter>;
};

export type ClinicEntityPhoneNumberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrimary?: Maybe<BoolNullableWithAggregatesFilter>;
  isPims?: Maybe<BoolNullableWithAggregatesFilter>;
  isMobile?: Maybe<BoolNullableWithAggregatesFilter>;
  number?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isOptedOut?: Maybe<BoolWithAggregatesFilter>;
};

export enum ClinicEntityPhoneNumberSmsStatus {
  None = 'None',
  PreValidated = 'PreValidated',
  Delivered = 'Delivered',
  RejectedBecauseLandline = 'RejectedBecauseLandline',
  RejectedBecauseInvalidPhoneNumber = 'RejectedBecauseInvalidPhoneNumber',
  RejectedNotInService = 'RejectedNotInService',
  RejectedUnknown = 'RejectedUnknown',
  RejectedFilteredByCarrier = 'RejectedFilteredByCarrier',
  RejectedOptedOut = 'RejectedOptedOut',
}

export type ClinicEntityPhoneNumberUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
};

export type ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutPhoneNumbersInput>;
};

export type ClinicEntityPhoneNumberUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicEntityPhoneNumberScalarWhereInput;
  data: ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumbersInput;
};

export type ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
};

export type ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  data: ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicEntityPhoneNumberUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  update: ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicEntityPhoneNumberWhereInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  isPims?: Maybe<BoolNullableFilter>;
  isMobile?: Maybe<BoolNullableFilter>;
  number?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableFilter>;
  isOptedOut?: Maybe<BoolFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
};

export type ClinicEntityPhoneNumberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicListRelationFilter = {
  every?: Maybe<ClinicWhereInput>;
  some?: Maybe<ClinicWhereInput>;
  none?: Maybe<ClinicWhereInput>;
};

export type ClinicMaxAggregateOutputType = {
  __typename?: 'ClinicMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
};

export type ClinicMinAggregateOutputType = {
  __typename?: 'ClinicMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
};

export type ClinicOfficeHour = {
  __typename?: 'ClinicOfficeHour';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours: Scalars['Boolean'];
  type: ClinicOfficeHoursType;
  clinicId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
};

export type ClinicOfficeHourAvgAggregateOutputType = {
  __typename?: 'ClinicOfficeHourAvgAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Float']>;
};

export type ClinicOfficeHourAvgOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type ClinicOfficeHourCountAggregateOutputType = {
  __typename?: 'ClinicOfficeHourCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['Int'];
  closeAt: Scalars['Int'];
  isEnabled: Scalars['Int'];
  isOpenTwentyFourHours: Scalars['Int'];
  type: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicOfficeHourCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOfficeHourCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicOfficeHoursInput>;
};

export type ClinicOfficeHourCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourCreateManyClinicInputEnvelope = {
  data: Array<ClinicOfficeHourCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicOfficeHourCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
};

export type ClinicOfficeHourCreateOrConnectWithoutClinicInput = {
  where: ClinicOfficeHourWhereUniqueInput;
  create: ClinicOfficeHourUncheckedCreateWithoutClinicInput;
};

export type ClinicOfficeHourCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourListRelationFilter = {
  every?: Maybe<ClinicOfficeHourWhereInput>;
  some?: Maybe<ClinicOfficeHourWhereInput>;
  none?: Maybe<ClinicOfficeHourWhereInput>;
};

export type ClinicOfficeHourMaxAggregateOutputType = {
  __typename?: 'ClinicOfficeHourMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOfficeHourMinAggregateOutputType = {
  __typename?: 'ClinicOfficeHourMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicOfficeHourOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicOfficeHourOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
}

export type ClinicOfficeHourOrderByRelevanceInput = {
  fields: Array<ClinicOfficeHourOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicOfficeHourOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicOfficeHourCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicOfficeHourAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicOfficeHourMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicOfficeHourMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicOfficeHourSumOrderByAggregateInput>;
};

export type ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicOfficeHourOrderByRelevanceInput>;
};

export enum ClinicOfficeHourScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DayOfWeek = 'dayOfWeek',
  OpenAt = 'openAt',
  CloseAt = 'closeAt',
  IsEnabled = 'isEnabled',
  IsOpenTwentyFourHours = 'isOpenTwentyFourHours',
  Type = 'type',
  ClinicId = 'clinicId',
}

export type ClinicOfficeHourScalarWhereInput = {
  AND?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  OR?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  openAt?: Maybe<DateTimeFilter>;
  closeAt?: Maybe<DateTimeFilter>;
  isEnabled?: Maybe<BoolFilter>;
  isOpenTwentyFourHours?: Maybe<BoolFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
};

export type ClinicOfficeHourScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  dayOfWeek?: Maybe<IntWithAggregatesFilter>;
  openAt?: Maybe<DateTimeWithAggregatesFilter>;
  closeAt?: Maybe<DateTimeWithAggregatesFilter>;
  isEnabled?: Maybe<BoolWithAggregatesFilter>;
  isOpenTwentyFourHours?: Maybe<BoolWithAggregatesFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicOfficeHourSumAggregateOutputType = {
  __typename?: 'ClinicOfficeHourSumAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Int']>;
};

export type ClinicOfficeHourSumOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type ClinicOfficeHourUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
};

export type ClinicOfficeHourUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  openAt: Scalars['DateTime'];
  closeAt: Scalars['DateTime'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
};

export type ClinicOfficeHourUncheckedUpdateManyWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicOfficeHoursInput>;
};

export type ClinicOfficeHourUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicOfficeHourScalarWhereInput;
  data: ClinicOfficeHourUncheckedUpdateManyWithoutClinicOfficeHoursInput;
};

export type ClinicOfficeHourUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
};

export type ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicOfficeHourWhereUniqueInput;
  data: ClinicOfficeHourUncheckedUpdateWithoutClinicInput;
};

export type ClinicOfficeHourUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openAt?: Maybe<Scalars['DateTime']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ClinicOfficeHoursType>;
};

export type ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicOfficeHourWhereUniqueInput;
  update: ClinicOfficeHourUncheckedUpdateWithoutClinicInput;
  create: ClinicOfficeHourUncheckedCreateWithoutClinicInput;
};

export type ClinicOfficeHourWhereInput = {
  AND?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  OR?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  openAt?: Maybe<DateTimeFilter>;
  closeAt?: Maybe<DateTimeFilter>;
  isEnabled?: Maybe<BoolFilter>;
  isOpenTwentyFourHours?: Maybe<BoolFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicOfficeHourWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ClinicOfficeHoursType {
  AutomationHours = 'AutomationHours',
  BusinessHours = 'BusinessHours',
  DirectBookingHours = 'DirectBookingHours',
}

export type ClinicOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  City = 'city',
  State = 'state',
  Country = 'country',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  PostalCode = 'postalCode',
  WebsiteUrl = 'websiteUrl',
  Hours = 'hours',
  Logo = 'logo',
  IncomeMethod = 'incomeMethod',
  PaymentName = 'paymentName',
  PaymentAddress = 'paymentAddress',
  PaymentCity = 'paymentCity',
  PaymentState = 'paymentState',
  PaymentZip = 'paymentZip',
  PaymentBankRoutingNumber = 'paymentBankRoutingNumber',
  PaymentBankAccountNumber = 'paymentBankAccountNumber',
  FirebaseId = 'firebaseId',
  StripeCustomerId = 'stripeCustomerId',
  FullAddress = 'fullAddress',
  StripeSubscriptionId = 'stripeSubscriptionId',
  StripeSubscriptionPlan = 'stripeSubscriptionPlan',
  Token = 'token',
  Phone = 'phone',
  OrganizationId = 'organizationId',
  IntegrationType = 'integrationType',
  CorporateId = 'corporateId',
  CustomerId = 'customerId',
  SubscriptionId = 'subscriptionId',
  SubscriptionPlan = 'subscriptionPlan',
  CancellationReason = 'cancellationReason',
  CancellationNote = 'cancellationNote',
  ClinicEmail = 'clinicEmail',
  IntegrationSystem = 'integrationSystem',
  MarketEmail = 'marketEmail',
  MarketFacebookPageId = 'marketFacebookPageId',
  MarketFacebookPageToken = 'marketFacebookPageToken',
  SubmerchantId = 'submerchantId',
  HeadUserId = 'headUserId',
  CrmId = 'crmId',
  SubmerchantStatus = 'submerchantStatus',
  PaymentPhone = 'paymentPhone',
  SubmerchantStripeId = 'submerchantStripeId',
  SubmerchantStripeStatus = 'submerchantStripeStatus',
  ClinicSettingId = 'clinicSettingId',
  TimezoneName = 'timezoneName',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  CallerIdPhoneNumber = 'callerIdPhoneNumber',
  SmsPhoneNumber = 'smsPhoneNumber',
}

export type ClinicOrderByRelevanceInput = {
  fields: Array<ClinicOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  integrationInfo?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  _count?: Maybe<ClinicCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicSumOrderByAggregateInput>;
};

export type ClinicOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  integrationInfo?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  activationDate?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>;
  usersId?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  appoinmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourOrderByRelationAggregateInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  integrations?: Maybe<ClinicPimsIntegrationOrderByRelationAggregateInput>;
  clinicRooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeOrderByRelationAggregateInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  consultationRequests?: Maybe<ConsultationRequestOrderByRelationAggregateInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  formTemplates?: Maybe<FormTemplateOrderByRelationAggregateInput>;
  petsId?: Maybe<PetOrderByRelationAggregateInput>;
  promoCodes?: Maybe<PromoCodeOrderByRelationAggregateInput>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  staffId?: Maybe<VetInfoOrderByRelationAggregateInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountOrderByRelationAggregateInput>;
  pets?: Maybe<PetOrderByRelationAggregateInput>;
  staff?: Maybe<VetInfoOrderByRelationAggregateInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  twilioVerifications?: Maybe<TwilioVerificationOrderByRelationAggregateInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationOrderByRelationAggregateInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  featureFlags?: Maybe<FeatureFlagOrderByRelationAggregateInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingOrderByRelationAggregateInput>;
  calls?: Maybe<CallHistoryOrderByRelationAggregateInput>;
  benefitsProvided?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  carePayoutOrganization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailabilities?: Maybe<VetAvailabilityOrderByRelationAggregateInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingOrderByRelationAggregateInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionOrderByRelationAggregateInput>;
  messageTemplates?: Maybe<MessageTemplateOrderByRelationAggregateInput>;
  Rules?: Maybe<RulesOrderByRelationAggregateInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  massTextAlerts?: Maybe<MassTextAlertOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicOrderByRelevanceInput>;
};

export type ClinicPaymentFeeConfiguration = {
  __typename?: 'ClinicPaymentFeeConfiguration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicSettingId: Scalars['String'];
  clinicSetting: ClinicSetting;
  onlineProcessingFeePercent: Scalars['Float'];
  onlineProcessingFeeFlat: Scalars['Int'];
  onlineClientServiceFeePercent: Scalars['Float'];
  terminalProcessingFeePercent: Scalars['Float'];
  terminalProcessingFeeFlat: Scalars['Int'];
  terminalClientServiceFeePercent: Scalars['Float'];
};

export type ClinicPaymentFeeConfigurationAvgAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationAvgAggregateOutputType';
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Float']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Float']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationAvgOrderByAggregateInput = {
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationCountAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  onlineProcessingFeePercent: Scalars['Int'];
  onlineProcessingFeeFlat: Scalars['Int'];
  onlineClientServiceFeePercent: Scalars['Int'];
  terminalProcessingFeePercent: Scalars['Int'];
  terminalProcessingFeeFlat: Scalars['Int'];
  terminalClientServiceFeePercent: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPaymentFeeConfigurationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutPaymentFeeConfigInput;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId: Scalars['String'];
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
};

export type ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput;
};

export type ClinicPaymentFeeConfigurationCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationMaxAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationMinAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
};

export enum ClinicPaymentFeeConfigurationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicSettingId = 'clinicSettingId',
}

export type ClinicPaymentFeeConfigurationOrderByRelevanceInput = {
  fields: Array<ClinicPaymentFeeConfigurationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPaymentFeeConfigurationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPaymentFeeConfigurationCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicPaymentFeeConfigurationAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicPaymentFeeConfigurationMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPaymentFeeConfigurationMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPaymentFeeConfigurationSumOrderByAggregateInput>;
};

export type ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  _relevance?: Maybe<ClinicPaymentFeeConfigurationOrderByRelevanceInput>;
};

export type ClinicPaymentFeeConfigurationRelationFilter = {
  is?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  isNot?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};

export enum ClinicPaymentFeeConfigurationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicSettingId = 'clinicSettingId',
  OnlineProcessingFeePercent = 'onlineProcessingFeePercent',
  OnlineProcessingFeeFlat = 'onlineProcessingFeeFlat',
  OnlineClientServiceFeePercent = 'onlineClientServiceFeePercent',
  TerminalProcessingFeePercent = 'terminalProcessingFeePercent',
  TerminalProcessingFeeFlat = 'terminalProcessingFeeFlat',
  TerminalClientServiceFeePercent = 'terminalClientServiceFeePercent',
}

export type ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  onlineProcessingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  onlineProcessingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  onlineClientServiceFeePercent?: Maybe<FloatWithAggregatesFilter>;
  terminalProcessingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  terminalProcessingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  terminalClientServiceFeePercent?: Maybe<FloatWithAggregatesFilter>;
};

export type ClinicPaymentFeeConfigurationSumAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationSumAggregateOutputType';
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationSumOrderByAggregateInput = {
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId: Scalars['String'];
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  update?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutPaymentFeeConfigInput>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  update?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput>;
};

export type ClinicPaymentFeeConfigurationUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput = {
  update: ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput;
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput;
};

export type ClinicPaymentFeeConfigurationWhereInput = {
  AND?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  OR?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  NOT?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  onlineProcessingFeePercent?: Maybe<FloatFilter>;
  onlineProcessingFeeFlat?: Maybe<IntFilter>;
  onlineClientServiceFeePercent?: Maybe<FloatFilter>;
  terminalProcessingFeePercent?: Maybe<FloatFilter>;
  terminalProcessingFeeFlat?: Maybe<IntFilter>;
  terminalClientServiceFeePercent?: Maybe<FloatFilter>;
};

export type ClinicPaymentFeeConfigurationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type ClinicPet = {
  __typename?: 'ClinicPet';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType: SpeciesType;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  integrationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegration>;
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPet>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments: Array<Appointment>;
  alerts: Array<ClinicPetAlert>;
  formSubmissions: Array<FormSubmission>;
  petParents: Array<ClinicPetParent>;
  serviceReminders: Array<ServiceReminder>;
  channels: Array<Channel>;
  invoiceLineItems: Array<InvoiceLineItem>;
  careBenefitUsages: Array<CareBenefitUsage>;
  automationRunActions: Array<AutomationRunAction>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  vaccinations: Array<Vaccination>;
  prescriptions: Array<Prescription>;
  _count: ClinicPetCountOutputType;
};

export type ClinicPetAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type ClinicPetAlertsArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetAlertScalarFieldEnum>;
};

export type ClinicPetFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};

export type ClinicPetPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type ClinicPetServiceRemindersArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
};

export type ClinicPetChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};

export type ClinicPetInvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};

export type ClinicPetCareBenefitUsagesArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};

export type ClinicPetAutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};

export type ClinicPetChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};

export type ClinicPetVaccinationsArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
};

export type ClinicPetPrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};

export type ClinicPetAlert = {
  __typename?: 'ClinicPetAlert';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPet>;
};

export type ClinicPetAlertCountAggregateOutputType = {
  __typename?: 'ClinicPetAlertCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  value: Scalars['Int'];
  pimsId: Scalars['Int'];
  pimsDate: Scalars['Int'];
  isMedical: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetAlertCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicPetAlertCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAlertsInput>;
};

export type ClinicPetAlertCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertCreateManyClinicPetInputEnvelope = {
  data: Array<ClinicPetAlertCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetAlertCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
};

export type ClinicPetAlertCreateOrConnectWithoutClinicPetInput = {
  where: ClinicPetAlertWhereUniqueInput;
  create: ClinicPetAlertUncheckedCreateWithoutClinicPetInput;
};

export type ClinicPetAlertCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertListRelationFilter = {
  every?: Maybe<ClinicPetAlertWhereInput>;
  some?: Maybe<ClinicPetAlertWhereInput>;
  none?: Maybe<ClinicPetAlertWhereInput>;
};

export type ClinicPetAlertMaxAggregateOutputType = {
  __typename?: 'ClinicPetAlertMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetAlertMinAggregateOutputType = {
  __typename?: 'ClinicPetAlertMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
};

export type ClinicPetAlertOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetAlertOrderByRelevanceFieldEnum {
  Id = 'id',
  Value = 'value',
  PimsId = 'pimsId',
  ClinicPetId = 'clinicPetId',
}

export type ClinicPetAlertOrderByRelevanceInput = {
  fields: Array<ClinicPetAlertOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetAlertOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetAlertCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetAlertMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetAlertMinOrderByAggregateInput>;
};

export type ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPetAlertOrderByRelevanceInput>;
};

export enum ClinicPetAlertScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  PimsId = 'pimsId',
  PimsDate = 'pimsDate',
  IsMedical = 'isMedical',
  ClinicPetId = 'clinicPetId',
  RawPimsValue = 'rawPimsValue',
}

export type ClinicPetAlertScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsDate?: Maybe<DateTimeNullableFilter>;
  isMedical?: Maybe<BoolNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetAlertScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  value?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isMedical?: Maybe<BoolNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicPetAlertUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
};

export type ClinicPetAlertUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedUpdateManyWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
};

export type ClinicPetAlertUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAlertsInput>;
};

export type ClinicPetAlertUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput = {
  where: ClinicPetAlertScalarWhereInput;
  data: ClinicPetAlertUncheckedUpdateManyWithoutAlertsInput;
};

export type ClinicPetAlertUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
};

export type ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: ClinicPetAlertWhereUniqueInput;
  data: ClinicPetAlertUncheckedUpdateWithoutClinicPetInput;
};

export type ClinicPetAlertUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: ClinicPetAlertWhereUniqueInput;
  update: ClinicPetAlertUncheckedUpdateWithoutClinicPetInput;
  create: ClinicPetAlertUncheckedCreateWithoutClinicPetInput;
};

export type ClinicPetAlertWhereInput = {
  AND?: Maybe<Array<ClinicPetAlertWhereInput>>;
  OR?: Maybe<Array<ClinicPetAlertWhereInput>>;
  NOT?: Maybe<Array<ClinicPetAlertWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsDate?: Maybe<DateTimeNullableFilter>;
  isMedical?: Maybe<BoolNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
};

export type ClinicPetAlertWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetAvgAggregateOutputType = {
  __typename?: 'ClinicPetAvgAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
};

export type ClinicPetAvgOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
};

export type ClinicPetCountAggregateOutputType = {
  __typename?: 'ClinicPetCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  breed: Scalars['Int'];
  color: Scalars['Int'];
  dateOfBirth: Scalars['Int'];
  dateOfDeath: Scalars['Int'];
  gender: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeceased: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  species: Scalars['Int'];
  speciesType: Scalars['Int'];
  weight: Scalars['Int'];
  weightUnit: Scalars['Int'];
  photoUrl: Scalars['Int'];
  clinicId: Scalars['Int'];
  integrationId: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicPetCountOutputType = {
  __typename?: 'ClinicPetCountOutputType';
  appointments: Scalars['Int'];
  alerts: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  petParents: Scalars['Int'];
  serviceReminders: Scalars['Int'];
  channels: Scalars['Int'];
  invoiceLineItems: Scalars['Int'];
  careBenefitUsages: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  vaccinations: Scalars['Int'];
  prescriptions: Scalars['Int'];
};

export type ClinicPetCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyClinicInputEnvelope = {
  data: Array<ClinicPetCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPetCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetCreateManyOrganizationPetInputEnvelope = {
  data: Array<ClinicPetCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateNestedManyWithoutChannelsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedManyWithoutPetParentsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetCreateNestedOneWithoutAlertsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAlertsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAlertsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAutomationRunActionsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutServiceRemindersInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutServiceRemindersInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateNestedOneWithoutVaccinationsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutVaccinationsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
};

export type ClinicPetCreateOrConnectWithoutAlertsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutAlertsInput;
};

export type ClinicPetCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPetCreateOrConnectWithoutAutomationRunActionsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput;
};

export type ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ClinicPetCreateOrConnectWithoutChannelsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutChannelsInput;
};

export type ClinicPetCreateOrConnectWithoutClinicInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutClinicInput;
};

export type ClinicPetCreateOrConnectWithoutFormSubmissionsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicPetCreateOrConnectWithoutIntegrationInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput;
};

export type ClinicPetCreateOrConnectWithoutOrganizationPetInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutOrganizationPetInput;
};

export type ClinicPetCreateOrConnectWithoutPetParentsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutPetParentsInput;
};

export type ClinicPetCreateOrConnectWithoutPrescriptionsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicPetCreateOrConnectWithoutServiceRemindersInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPetCreateOrConnectWithoutVaccinationsInput = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicPetCreateWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetListRelationFilter = {
  every?: Maybe<ClinicPetWhereInput>;
  some?: Maybe<ClinicPetWhereInput>;
  none?: Maybe<ClinicPetWhereInput>;
};

export type ClinicPetMaxAggregateOutputType = {
  __typename?: 'ClinicPetMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
};

export type ClinicPetMinAggregateOutputType = {
  __typename?: 'ClinicPetMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
};

export type ClinicPetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
};

export type ClinicPetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetOrderByRelevanceFieldEnum {
  Id = 'id',
  Breed = 'breed',
  Color = 'color',
  Gender = 'gender',
  Name = 'name',
  PimsId = 'pimsId',
  Species = 'species',
  WeightUnit = 'weightUnit',
  PhotoUrl = 'photoUrl',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  OrganizationPetId = 'organizationPetId',
}

export type ClinicPetOrderByRelevanceInput = {
  fields: Array<ClinicPetOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicPetAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicPetMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPetSumOrderByAggregateInput>;
};

export type ClinicPetOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetId?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  rawPimsValue?: Maybe<SortOrder>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  alerts?: Maybe<ClinicPetAlertOrderByRelationAggregateInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  petParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  serviceReminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPetOrderByRelevanceInput>;
};

export type ClinicPetParent = {
  __typename?: 'ClinicPetParent';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  /** This is the user-readable ID from the PIMS if one exists */
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  integrationId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegration>;
  petParentSettingId?: Maybe<Scalars['String']>;
  petParentSetting?: Maybe<PetParentSetting>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  organizationPetParent?: Maybe<OrganizationPetParent>;
  /** StripeCustomer can be shared between multiple petParent records - not unique */
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomer>;
  channelMembers: Array<ChannelMember>;
  phoneNumbers: Array<ClinicEntityPhoneNumber>;
  addresses: Array<ClinicPetParentAddress>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  formSubmissions: Array<FormSubmission>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  stripePaymentMethods: Array<StripePaymentMethod>;
  appointments: Array<Appointment>;
  pets: Array<ClinicPet>;
  serviceReminders: Array<ServiceReminder>;
  invoices: Array<Invoice>;
  calls: Array<CallParticipant>;
  emailProviderLogs: Array<EmailProviderLog>;
  careAuthorizationRequests: Array<CareAuthorizationRequest>;
  automationRunActions: Array<AutomationRunAction>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  massTextAlertEntries: Array<MassTextAlertEntry>;
  _count: ClinicPetParentCountOutputType;
};

export type ClinicPetParentChannelMembersArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
};

export type ClinicPetParentPhoneNumbersArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEntityPhoneNumberScalarFieldEnum>;
};

export type ClinicPetParentAddressesArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentAddressScalarFieldEnum>;
};

export type ClinicPetParentClinicWidgetRequestsArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
};

export type ClinicPetParentFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};

export type ClinicPetParentStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};

export type ClinicPetParentStripePaymentMethodsArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentMethodScalarFieldEnum>;
};

export type ClinicPetParentAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type ClinicPetParentPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type ClinicPetParentServiceRemindersArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
};

export type ClinicPetParentInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
};

export type ClinicPetParentCallsArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
};

export type ClinicPetParentEmailProviderLogsArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmailProviderLogScalarFieldEnum>;
};

export type ClinicPetParentCareAuthorizationRequestsArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAuthorizationRequestScalarFieldEnum>;
};

export type ClinicPetParentAutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};

export type ClinicPetParentChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};

export type ClinicPetParentAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type ClinicPetParentMassTextAlertEntriesArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertEntryScalarFieldEnum>;
};

export type ClinicPetParentAddress = {
  __typename?: 'ClinicPetParentAddress';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressClinicPetParentIdPimsIdCompoundUniqueInput = {
  clinicPetParentId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ClinicPetParentAddressCountAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  pimsId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPrimary: Scalars['Int'];
  addressType: Scalars['Int'];
  line1: Scalars['Int'];
  line2: Scalars['Int'];
  city: Scalars['Int'];
  stateProvince: Scalars['Int'];
  postalCode: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  creationSource: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetParentAddressCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAddressesInput;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentAddressCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentAddressCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
};

export type ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicPetParentAddressWhereUniqueInput;
  create: ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentAddressCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressListRelationFilter = {
  every?: Maybe<ClinicPetParentAddressWhereInput>;
  some?: Maybe<ClinicPetParentAddressWhereInput>;
  none?: Maybe<ClinicPetParentAddressWhereInput>;
};

export type ClinicPetParentAddressMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
};

export type ClinicPetParentAddressMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressMinAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
};

export type ClinicPetParentAddressMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentAddressOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsId = 'pimsId',
  Line1 = 'line1',
  Line2 = 'line2',
  City = 'city',
  StateProvince = 'stateProvince',
  PostalCode = 'postalCode',
  ClinicPetParentId = 'clinicPetParentId',
}

export type ClinicPetParentAddressOrderByRelevanceInput = {
  fields: Array<ClinicPetParentAddressOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetParentAddressOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetParentAddressCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentAddressMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentAddressMinOrderByAggregateInput>;
};

export type ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  addressType?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  creationSource?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _relevance?: Maybe<ClinicPetParentAddressOrderByRelevanceInput>;
};

export enum ClinicPetParentAddressScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PimsId = 'pimsId',
  IsDeleted = 'isDeleted',
  IsPrimary = 'isPrimary',
  AddressType = 'addressType',
  Line1 = 'line1',
  Line2 = 'line2',
  City = 'city',
  StateProvince = 'stateProvince',
  PostalCode = 'postalCode',
  ClinicPetParentId = 'clinicPetParentId',
  CreationSource = 'creationSource',
  RawPimsValue = 'rawPimsValue',
}

export type ClinicPetParentAddressScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableFilter>;
  line1?: Maybe<StringFilter>;
  line2?: Maybe<StringNullableFilter>;
  city?: Maybe<StringFilter>;
  stateProvince?: Maybe<StringFilter>;
  postalCode?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetParentAddressScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrimary?: Maybe<BoolNullableWithAggregatesFilter>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  line1?: Maybe<StringWithAggregatesFilter>;
  line2?: Maybe<StringNullableWithAggregatesFilter>;
  city?: Maybe<StringWithAggregatesFilter>;
  stateProvince?: Maybe<StringWithAggregatesFilter>;
  postalCode?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum ClinicPetParentAddressType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Billing = 'Billing',
  Work = 'Work',
  Unknown = 'Unknown',
}

export type ClinicPetParentAddressUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
};

export type ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvince: Scalars['String'];
  postalCode: Scalars['String'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
};

export type ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAddressesInput>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicPetParentAddressScalarWhereInput;
  data: ClinicPetParentAddressUncheckedUpdateManyWithoutAddressesInput;
};

export type ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
};

export type ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentAddressWhereUniqueInput;
  data: ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicPetParentAddressUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<ClinicPetParentAddressType>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicPetParentAddressWhereUniqueInput;
  update: ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicPetParentAddressWhereInput = {
  AND?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableFilter>;
  line1?: Maybe<StringFilter>;
  line2?: Maybe<StringNullableFilter>;
  city?: Maybe<StringFilter>;
  stateProvince?: Maybe<StringFilter>;
  postalCode?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetParentAddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicPetParentId_pimsId?: Maybe<ClinicPetParentAddressClinicPetParentIdPimsIdCompoundUniqueInput>;
};

export type ClinicPetParentAuthLinkResponse = {
  __typename?: 'ClinicPetParentAuthLinkResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type ClinicPetParentCountAggregateOutputType = {
  __typename?: 'ClinicPetParentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  email: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  pimsId: Scalars['Int'];
  sourcePimsId: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  isVerified: Scalars['Int'];
  creationSource: Scalars['Int'];
  pimsPhoneNumber: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  clinicId: Scalars['Int'];
  integrationId: Scalars['Int'];
  petParentSettingId: Scalars['Int'];
  organizationPetParentId: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPetParentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
};

export type ClinicPetParentCountOutputType = {
  __typename?: 'ClinicPetParentCountOutputType';
  channelMembers: Scalars['Int'];
  phoneNumbers: Scalars['Int'];
  addresses: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  stripePaymentMethods: Scalars['Int'];
  appointments: Scalars['Int'];
  pets: Scalars['Int'];
  serviceReminders: Scalars['Int'];
  invoices: Scalars['Int'];
  calls: Scalars['Int'];
  emailProviderLogs: Scalars['Int'];
  careAuthorizationRequests: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
  massTextAlertEntries: Scalars['Int'];
};

export type ClinicPetParentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyClinicInputEnvelope = {
  data: Array<ClinicPetParentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPetParentCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyOrganizationPetParentInputEnvelope = {
  data: Array<ClinicPetParentCreateManyOrganizationPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyPetParentSettingInputEnvelope = {
  data: Array<ClinicPetParentCreateManyPetParentSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyStripeCustomerInputEnvelope = {
  data: Array<ClinicPetParentCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutPetParentSettingInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentCreateNestedOneWithoutAddressesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAddressesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAddressesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCallsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCallsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCareAuthorizationRequestsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutChannelMembersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelMembersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailProviderLogsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutServiceRemindersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutServiceRemindersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type ClinicPetParentCreateOrConnectWithoutAddressesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutAddressesInput;
};

export type ClinicPetParentCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ClinicPetParentCreateOrConnectWithoutAutomationRunsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicPetParentCreateOrConnectWithoutCallsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutCallsInput;
};

export type ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput;
};

export type ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ClinicPetParentCreateOrConnectWithoutChannelMembersInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutChannelMembersInput;
};

export type ClinicPetParentCreateOrConnectWithoutClinicInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicInput;
};

export type ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput;
};

export type ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicPetParentCreateOrConnectWithoutIntegrationInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPetParentCreateOrConnectWithoutInvoicesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutInvoicesInput;
};

export type ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput;
};

export type ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput;
};

export type ClinicPetParentCreateOrConnectWithoutPetParentSettingInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutPetParentSettingInput;
};

export type ClinicPetParentCreateOrConnectWithoutPetsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutPetsInput;
};

export type ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput;
};

export type ClinicPetParentCreateOrConnectWithoutServiceRemindersInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripeCustomerInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutStripeCustomerInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput;
};

export type ClinicPetParentCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentCreateWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentListRelationFilter = {
  every?: Maybe<ClinicPetParentWhereInput>;
  some?: Maybe<ClinicPetParentWhereInput>;
  none?: Maybe<ClinicPetParentWhereInput>;
};

export type ClinicPetParentMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
};

export type ClinicPetParentMinAggregateOutputType = {
  __typename?: 'ClinicPetParentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
};

export type ClinicPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentOrderByRelevanceFieldEnum {
  Id = 'id',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  PimsId = 'pimsId',
  SourcePimsId = 'sourcePimsId',
  FirstName = 'firstName',
  LastName = 'lastName',
  PimsPhoneNumber = 'pimsPhoneNumber',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  PetParentSettingId = 'petParentSettingId',
  OrganizationPetParentId = 'organizationPetParentId',
  StripeCustomerId = 'stripeCustomerId',
}

export type ClinicPetParentOrderByRelevanceInput = {
  fields: Array<ClinicPetParentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPetParentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPetParentCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentMinOrderByAggregateInput>;
};

export type ClinicPetParentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  petParentSettingId?: Maybe<SortOrder>;
  petParentSetting?: Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetParentId?: Maybe<SortOrder>;
  organizationPetParent?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberOrderByRelationAggregateInput>;
  addresses?: Maybe<ClinicPetParentAddressOrderByRelationAggregateInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  serviceReminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  invoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  calls?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  emailProviderLogs?: Maybe<EmailProviderLogOrderByRelationAggregateInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPetParentOrderByRelevanceInput>;
};

export type ClinicPetParentPimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ClinicPetParentRelationFilter = {
  is?: Maybe<ClinicPetParentWhereInput>;
  isNot?: Maybe<ClinicPetParentWhereInput>;
};

export enum ClinicPetParentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Email = 'email',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  PhoneNumber = 'phoneNumber',
  PimsId = 'pimsId',
  SourcePimsId = 'sourcePimsId',
  FirstName = 'firstName',
  LastName = 'lastName',
  IsVerified = 'isVerified',
  CreationSource = 'creationSource',
  PimsPhoneNumber = 'pimsPhoneNumber',
  RawPimsValue = 'rawPimsValue',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  PetParentSettingId = 'petParentSettingId',
  OrganizationPetParentId = 'organizationPetParentId',
  StripeCustomerId = 'stripeCustomerId',
}

export type ClinicPetParentScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  sourcePimsId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  pimsPhoneNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  petParentSettingId?: Maybe<StringNullableFilter>;
  organizationPetParentId?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
};

export type ClinicPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  sourcePimsId?: Maybe<StringNullableWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  isVerified?: Maybe<BoolNullableWithAggregatesFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  pimsPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  organizationPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicPetParentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutPetParentSettingInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
};

export type ClinicPetParentUncheckedCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentSettingInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUncheckedUpdateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutPetsInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput = {
  where: ClinicPetParentScalarWhereInput;
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
};

export type ClinicPetParentUpdateManyWithoutAppointmentsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutPetParentSettingInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
};

export type ClinicPetParentUpdateOneRequiredWithoutAddressesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAddressesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAddressesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAddressesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAddressesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutAutomationRunsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutCareAuthorizationRequestsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCareAuthorizationRequestsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutEmailProviderLogsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailProviderLogsInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutInvoicesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutInvoicesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutInvoicesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutMassTextAlertEntriesInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutServiceRemindersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutServiceRemindersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutServiceRemindersInput>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput>;
};

export type ClinicPetParentUpdateOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAutomationRunActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput>;
};

export type ClinicPetParentUpdateOneWithoutCallsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCallsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCallsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCallsInput>;
};

export type ClinicPetParentUpdateOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type ClinicPetParentUpdateOneWithoutChannelMembersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelMembersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutChannelMembersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutChannelMembersInput>;
};

export type ClinicPetParentUpdateOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutClinicWidgetRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput>;
};

export type ClinicPetParentUpdateOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type ClinicPetParentUpdateOneWithoutPhoneNumbersInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutPhoneNumbersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput>;
};

export type ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type ClinicPetParentUpdateOneWithoutStripePaymentMethodsInput = {
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutStripePaymentMethodsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput>;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutAppointmentsInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutClinicInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutPetsInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput;
};

export type ClinicPetParentUpdateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutCareAuthorizationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutOrganizationPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutPetParentSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutPhoneNumbersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpdateWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentInput>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentInput>;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicPetParentUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutClinicInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutIntegrationInput;
  create: ClinicPetParentUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
  create: ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput;
  create: ClinicPetParentUncheckedCreateWithoutPetParentSettingInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutPetsInput;
  create: ClinicPetParentUncheckedCreateWithoutPetsInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  where: ClinicPetParentWhereUniqueInput;
  update: ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput;
  create: ClinicPetParentUncheckedCreateWithoutStripeCustomerInput;
};

export type ClinicPetParentUpsertWithoutAddressesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutAddressesInput;
  create: ClinicPetParentUncheckedCreateWithoutAddressesInput;
};

export type ClinicPetParentUpsertWithoutAutomationRunActionsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput;
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ClinicPetParentUpsertWithoutAutomationRunsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput;
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicPetParentUpsertWithoutCallsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutCallsInput;
  create: ClinicPetParentUncheckedCreateWithoutCallsInput;
};

export type ClinicPetParentUpsertWithoutCareAuthorizationRequestsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput;
  create: ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput;
};

export type ClinicPetParentUpsertWithoutChannelCreationSourcesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ClinicPetParentUpsertWithoutChannelMembersInput = {
  update: ClinicPetParentUncheckedUpdateWithoutChannelMembersInput;
  create: ClinicPetParentUncheckedCreateWithoutChannelMembersInput;
};

export type ClinicPetParentUpsertWithoutClinicWidgetRequestsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput;
  create: ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicPetParentUpsertWithoutEmailProviderLogsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput;
  create: ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput;
};

export type ClinicPetParentUpsertWithoutFormSubmissionsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput;
  create: ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicPetParentUpsertWithoutInvoicesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutInvoicesInput;
  create: ClinicPetParentUncheckedCreateWithoutInvoicesInput;
};

export type ClinicPetParentUpsertWithoutMassTextAlertEntriesInput = {
  update: ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput;
  create: ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput;
};

export type ClinicPetParentUpsertWithoutPhoneNumbersInput = {
  update: ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput;
  create: ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput;
};

export type ClinicPetParentUpsertWithoutServiceRemindersInput = {
  update: ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput;
  create: ClinicPetParentUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPetParentUpsertWithoutStripePaymentIntentsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type ClinicPetParentUpsertWithoutStripePaymentMethodsInput = {
  update: ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput;
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput;
};

export type ClinicPetParentWhereInput = {
  AND?: Maybe<Array<ClinicPetParentWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  sourcePimsId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  pimsPhoneNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  petParentSettingId?: Maybe<StringNullableFilter>;
  petParentSetting?: Maybe<PetParentSettingWhereInput>;
  organizationPetParentId?: Maybe<StringNullableFilter>;
  organizationPetParent?: Maybe<OrganizationPetParentWhereInput>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberListRelationFilter>;
  addresses?: Maybe<ClinicPetParentAddressListRelationFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  stripePaymentMethods?: Maybe<StripePaymentMethodListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  serviceReminders?: Maybe<ServiceReminderListRelationFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  calls?: Maybe<CallParticipantListRelationFilter>;
  emailProviderLogs?: Maybe<EmailProviderLogListRelationFilter>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryListRelationFilter>;
};

export type ClinicPetParentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<ClinicPetParentPimsIdIntegrationIdCompoundUniqueInput>;
};

export type ClinicPetPimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ClinicPetRelationFilter = {
  is?: Maybe<ClinicPetWhereInput>;
  isNot?: Maybe<ClinicPetWhereInput>;
};

export enum ClinicPetScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Breed = 'breed',
  Color = 'color',
  DateOfBirth = 'dateOfBirth',
  DateOfDeath = 'dateOfDeath',
  Gender = 'gender',
  IsActive = 'isActive',
  IsDeceased = 'isDeceased',
  IsDeleted = 'isDeleted',
  Name = 'name',
  PimsId = 'pimsId',
  Species = 'species',
  SpeciesType = 'speciesType',
  Weight = 'weight',
  WeightUnit = 'weightUnit',
  PhotoUrl = 'photoUrl',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  OrganizationPetId = 'organizationPetId',
  RawPimsValue = 'rawPimsValue',
}

export type ClinicPetScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  breed?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  dateOfBirth?: Maybe<DateTimeNullableFilter>;
  dateOfDeath?: Maybe<DateTimeNullableFilter>;
  gender?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeceased?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  species?: Maybe<StringNullableFilter>;
  speciesType?: Maybe<EnumSpeciesTypeFilter>;
  weight?: Maybe<FloatNullableFilter>;
  weightUnit?: Maybe<StringNullableFilter>;
  photoUrl?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  organizationPetId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicPetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  breed?: Maybe<StringNullableWithAggregatesFilter>;
  color?: Maybe<StringNullableWithAggregatesFilter>;
  dateOfBirth?: Maybe<DateTimeNullableWithAggregatesFilter>;
  dateOfDeath?: Maybe<DateTimeNullableWithAggregatesFilter>;
  gender?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeceased?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  species?: Maybe<StringNullableWithAggregatesFilter>;
  speciesType?: Maybe<EnumSpeciesTypeWithAggregatesFilter>;
  weight?: Maybe<FloatNullableWithAggregatesFilter>;
  weightUnit?: Maybe<StringNullableWithAggregatesFilter>;
  photoUrl?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  organizationPetId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicPetSumAggregateOutputType = {
  __typename?: 'ClinicPetSumAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
};

export type ClinicPetSumOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
};

export type ClinicPetUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutChannelsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
};

export type ClinicPetUncheckedCreateWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetUncheckedUpdateManyWithoutChannelsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutChannelsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutChannelsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutPetParentsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutPetParentsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetUncheckedUpdateWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUncheckedUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicPetUpdateManyWithWhereWithoutChannelsInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
};

export type ClinicPetUpdateManyWithWhereWithoutPetParentsInput = {
  where: ClinicPetScalarWhereInput;
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
};

export type ClinicPetUpdateManyWithoutChannelsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutChannelsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutChannelsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutInvoiceLineItemsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateManyWithoutPetParentsInput = {
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutPetParentsInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
};

export type ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutCareBenefitUsagesInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput>;
};

export type ClinicPetUpdateOneRequiredWithoutPrescriptionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ClinicPetUpdateOneRequiredWithoutServiceRemindersInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutServiceRemindersInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutServiceRemindersInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutServiceRemindersInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutServiceRemindersInput>;
};

export type ClinicPetUpdateOneRequiredWithoutVaccinationsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutVaccinationsInput>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutVaccinationsInput>;
};

export type ClinicPetUpdateOneWithoutAlertsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAlertsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAlertsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAlertsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAlertsInput>;
};

export type ClinicPetUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAppointmentsInput>;
};

export type ClinicPetUpdateOneWithoutAutomationRunActionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAutomationRunActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput>;
};

export type ClinicPetUpdateOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type ClinicPetUpdateOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicPetUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type ClinicPetUpdateWithWhereUniqueWithoutChannelsInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutChannelsInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutClinicInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutOrganizationPetInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput = {
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUncheckedUpdateWithoutPetParentsInput;
};

export type ClinicPetUpdateWithoutAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutCareBenefitUsagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutOrganizationPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsInput>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetInput>;
};

export type ClinicPetUpsertWithWhereUniqueWithoutChannelsInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutChannelsInput;
  create: ClinicPetUncheckedCreateWithoutChannelsInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutClinicInput;
  create: ClinicPetUncheckedCreateWithoutClinicInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutIntegrationInput;
  create: ClinicPetUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput;
  create: ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutOrganizationPetInput;
  create: ClinicPetUncheckedCreateWithoutOrganizationPetInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput = {
  where: ClinicPetWhereUniqueInput;
  update: ClinicPetUncheckedUpdateWithoutPetParentsInput;
  create: ClinicPetUncheckedCreateWithoutPetParentsInput;
};

export type ClinicPetUpsertWithoutAlertsInput = {
  update: ClinicPetUncheckedUpdateWithoutAlertsInput;
  create: ClinicPetUncheckedCreateWithoutAlertsInput;
};

export type ClinicPetUpsertWithoutAppointmentsInput = {
  update: ClinicPetUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicPetUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPetUpsertWithoutAutomationRunActionsInput = {
  update: ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput;
  create: ClinicPetUncheckedCreateWithoutAutomationRunActionsInput;
};

export type ClinicPetUpsertWithoutCareBenefitUsagesInput = {
  update: ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput;
  create: ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput;
};

export type ClinicPetUpsertWithoutChannelAutomationStatusesInput = {
  update: ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ClinicPetUpsertWithoutFormSubmissionsInput = {
  update: ClinicPetUncheckedUpdateWithoutFormSubmissionsInput;
  create: ClinicPetUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicPetUpsertWithoutPrescriptionsInput = {
  update: ClinicPetUncheckedUpdateWithoutPrescriptionsInput;
  create: ClinicPetUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicPetUpsertWithoutServiceRemindersInput = {
  update: ClinicPetUncheckedUpdateWithoutServiceRemindersInput;
  create: ClinicPetUncheckedCreateWithoutServiceRemindersInput;
};

export type ClinicPetUpsertWithoutVaccinationsInput = {
  update: ClinicPetUncheckedUpdateWithoutVaccinationsInput;
  create: ClinicPetUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicPetWhereInput = {
  AND?: Maybe<Array<ClinicPetWhereInput>>;
  OR?: Maybe<Array<ClinicPetWhereInput>>;
  NOT?: Maybe<Array<ClinicPetWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  breed?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  dateOfBirth?: Maybe<DateTimeNullableFilter>;
  dateOfDeath?: Maybe<DateTimeNullableFilter>;
  gender?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeceased?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  species?: Maybe<StringNullableFilter>;
  speciesType?: Maybe<EnumSpeciesTypeFilter>;
  weight?: Maybe<FloatNullableFilter>;
  weightUnit?: Maybe<StringNullableFilter>;
  photoUrl?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  organizationPetId?: Maybe<StringNullableFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  alerts?: Maybe<ClinicPetAlertListRelationFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  petParents?: Maybe<ClinicPetParentListRelationFilter>;
  serviceReminders?: Maybe<ServiceReminderListRelationFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  invoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  careBenefitUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
};

export type ClinicPetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<ClinicPetPimsIdIntegrationIdCompoundUniqueInput>;
};

export type ClinicPhoneNumber = {
  __typename?: 'ClinicPhoneNumber';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  clinic: Clinic;
  smsNotificationStatuses: Array<SmsNotificationStatus>;
  _count: ClinicPhoneNumberCountOutputType;
};

export type ClinicPhoneNumberSmsNotificationStatusesArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<SmsNotificationStatusScalarFieldEnum>;
};

export type ClinicPhoneNumberCapabilityAndNumberCompoundUniqueInput = {
  capability: ServiceCapability;
  number: Scalars['String'];
};

export type ClinicPhoneNumberCountAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  capability: Scalars['Int'];
  provider: Scalars['Int'];
  isPrimary: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  number: Scalars['Int'];
  status: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPhoneNumberCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberCountOutputType = {
  __typename?: 'ClinicPhoneNumberCountOutputType';
  smsNotificationStatuses: Scalars['Int'];
};

export type ClinicPhoneNumberCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
};

export type ClinicPhoneNumberCreateManyClinicInputEnvelope = {
  data: Array<ClinicPhoneNumberCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
};

export type ClinicPhoneNumberCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
};

export type ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput = {
  create?: Maybe<ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput>;
  connectOrCreate?: Maybe<ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput>;
  connect?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
};

export type ClinicPhoneNumberCreateOrConnectWithoutClinicInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutClinicInput;
};

export type ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput;
};

export type ClinicPhoneNumberCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberCreateWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
};

export type ClinicPhoneNumberListRelationFilter = {
  every?: Maybe<ClinicPhoneNumberWhereInput>;
  some?: Maybe<ClinicPhoneNumberWhereInput>;
  none?: Maybe<ClinicPhoneNumberWhereInput>;
};

export type ClinicPhoneNumberMaxAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberMinAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPhoneNumberOrderByRelevanceFieldEnum {
  Id = 'id',
  Number = 'number',
  ClinicId = 'clinicId',
}

export type ClinicPhoneNumberOrderByRelevanceInput = {
  fields: Array<ClinicPhoneNumberOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPhoneNumberOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPhoneNumberCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPhoneNumberMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPhoneNumberMinOrderByAggregateInput>;
};

export type ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPhoneNumberOrderByRelevanceInput>;
};

export type ClinicPhoneNumberRelationFilter = {
  is?: Maybe<ClinicPhoneNumberWhereInput>;
  isNot?: Maybe<ClinicPhoneNumberWhereInput>;
};

export enum ClinicPhoneNumberScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Capability = 'capability',
  Provider = 'provider',
  IsPrimary = 'isPrimary',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Number = 'number',
  Status = 'status',
  ClinicId = 'clinicId',
}

export type ClinicPhoneNumberScalarWhereInput = {
  AND?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  capability?: Maybe<EnumServiceCapabilityFilter>;
  provider?: Maybe<EnumServiceProviderFilter>;
  isPrimary?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  number?: Maybe<StringFilter>;
  status?: Maybe<EnumPhoneNumberStatusFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type ClinicPhoneNumberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  capability?: Maybe<EnumServiceCapabilityWithAggregatesFilter>;
  provider?: Maybe<EnumServiceProviderWithAggregatesFilter>;
  isPrimary?: Maybe<BoolWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  number?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumPhoneNumberStatusWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicPhoneNumberUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability: ServiceCapability;
  provider: ServiceProvider;
  isPrimary: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  status: PhoneNumberStatus;
  clinicId: Scalars['String'];
};

export type ClinicPhoneNumberUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumberUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
};

export type ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPhoneNumberScalarWhereInput;
  data: ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumberInput;
};

export type ClinicPhoneNumberUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
};

export type ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesInput = {
  create?: Maybe<ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput>;
  connectOrCreate?: Maybe<ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput>;
  upsert?: Maybe<ClinicPhoneNumberUpsertWithoutSmsNotificationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  update?: Maybe<ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput>;
};

export type ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  data: ClinicPhoneNumberUncheckedUpdateWithoutClinicInput;
};

export type ClinicPhoneNumberUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUpdateWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  capability?: Maybe<ServiceCapability>;
  provider?: Maybe<ServiceProvider>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberStatus>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberInput>;
};

export type ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPhoneNumberWhereUniqueInput;
  update: ClinicPhoneNumberUncheckedUpdateWithoutClinicInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutClinicInput;
};

export type ClinicPhoneNumberUpsertWithoutSmsNotificationStatusesInput = {
  update: ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput;
  create: ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput;
};

export type ClinicPhoneNumberWhereInput = {
  AND?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  capability?: Maybe<EnumServiceCapabilityFilter>;
  provider?: Maybe<EnumServiceProviderFilter>;
  isPrimary?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  number?: Maybe<StringFilter>;
  status?: Maybe<EnumPhoneNumberStatusFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusListRelationFilter>;
};

export type ClinicPhoneNumberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  capabilityAndNumber?: Maybe<ClinicPhoneNumberCapabilityAndNumberCompoundUniqueInput>;
};

export type ClinicPimsIntegration = {
  __typename?: 'ClinicPimsIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency: PimsSyncFrequency;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  datapoint?: Maybe<DataPointIntegration>;
  ezyvet?: Maybe<EzyVetIntegration>;
  instinct?: Maybe<InstinctIntegration>;
  neo?: Maybe<NeoIntegration>;
  vetdata?: Maybe<VetDataIntegration>;
  vetter?: Maybe<VetterIntegration>;
  bitwerx?: Maybe<BitwerxIntegration>;
  ppc?: Maybe<PpcIntegration>;
  appointments: Array<Appointment>;
  appointmentTypes: Array<AppointmentType>;
  employees: Array<ClinicEmployee>;
  pets: Array<ClinicPet>;
  petParents: Array<ClinicPetParent>;
  rooms: Array<ClinicRoom>;
  services: Array<Service>;
  pimsInvoices: Array<PimsInvoice>;
  pimsInvoiceLineItems: Array<PimsInvoiceLineItem>;
  capabilities: Array<ClinicPimsIntegrationCapability>;
  writebackConfiguration: Scalars['Json'];
  writebackLogEntries: Array<PimsWritebackLogEntry>;
  vetAvailabilities: Array<VetAvailability>;
  vaccinations: Array<Vaccination>;
  prescriptions: Array<Prescription>;
  _count: ClinicPimsIntegrationCountOutputType;
};

export type ClinicPimsIntegrationAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type ClinicPimsIntegrationAppointmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};

export type ClinicPimsIntegrationEmployeesArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};

export type ClinicPimsIntegrationPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type ClinicPimsIntegrationPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type ClinicPimsIntegrationRoomsArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
};

export type ClinicPimsIntegrationServicesArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
};

export type ClinicPimsIntegrationPimsInvoicesArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsInvoiceScalarFieldEnum>;
};

export type ClinicPimsIntegrationPimsInvoiceLineItemsArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsInvoiceLineItemScalarFieldEnum>;
};

export type ClinicPimsIntegrationCapabilitiesArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>;
};

export type ClinicPimsIntegrationWritebackLogEntriesArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsWritebackLogEntryScalarFieldEnum>;
};

export type ClinicPimsIntegrationVetAvailabilitiesArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityScalarFieldEnum>;
};

export type ClinicPimsIntegrationVaccinationsArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
};

export type ClinicPimsIntegrationPrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};

export type ClinicPimsIntegrationCapability = {
  __typename?: 'ClinicPimsIntegrationCapability';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability: PimsIntegrationCapability;
  integrationId: Scalars['String'];
  integration: ClinicPimsIntegration;
};

export type ClinicPimsIntegrationCapabilityCountAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  status: Scalars['Int'];
  statusChangedAt: Scalars['Int'];
  statusErrorMessage: Scalars['Int'];
  capability: Scalars['Int'];
  integrationId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPimsIntegrationCapabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability: PimsIntegrationCapability;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationCapabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability: PimsIntegrationCapability;
  integrationId: Scalars['String'];
};

export type ClinicPimsIntegrationCapabilityCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability: PimsIntegrationCapability;
};

export type ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPimsIntegrationCapabilityCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
};

export type ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
  create: ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability: PimsIntegrationCapability;
};

export type ClinicPimsIntegrationCapabilityIntegrationIdCapabilityCompoundUniqueInput = {
  integrationId: Scalars['String'];
  capability: PimsIntegrationCapability;
};

export type ClinicPimsIntegrationCapabilityListRelationFilter = {
  every?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  some?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  none?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};

export type ClinicPimsIntegrationCapabilityMaxAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integrationId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityMinAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integrationId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationCapabilityOrderByRelevanceFieldEnum {
  Id = 'id',
  StatusErrorMessage = 'statusErrorMessage',
  IntegrationId = 'integrationId',
}

export type ClinicPimsIntegrationCapabilityOrderByRelevanceInput = {
  fields: Array<ClinicPimsIntegrationCapabilityOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPimsIntegrationCapabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPimsIntegrationCapabilityCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityMinOrderByAggregateInput>;
};

export type ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPimsIntegrationCapabilityOrderByRelevanceInput>;
};

export enum ClinicPimsIntegrationCapabilityScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LastSyncedAt = 'lastSyncedAt',
  Status = 'status',
  StatusChangedAt = 'statusChangedAt',
  StatusErrorMessage = 'statusErrorMessage',
  Capability = 'capability',
  IntegrationId = 'integrationId',
}

export type ClinicPimsIntegrationCapabilityScalarWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  integrationId?: Maybe<StringFilter>;
};

export type ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableWithAggregatesFilter>;
  statusChangedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  statusErrorMessage?: Maybe<StringNullableWithAggregatesFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability: PimsIntegrationCapability;
  integrationId: Scalars['String'];
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability: PimsIntegrationCapability;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integrationId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integrationId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
};

export type ClinicPimsIntegrationCapabilityUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilitiesInput>;
};

export type ClinicPimsIntegrationCapabilityUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
};

export type ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityScalarWhereInput;
  data: ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
};

export type ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
  data: ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  capability?: Maybe<PimsIntegrationCapability>;
};

export type ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
  update: ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput;
  create: ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput;
};

export type ClinicPimsIntegrationCapabilityWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  integrationId?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type ClinicPimsIntegrationCapabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_capability?: Maybe<ClinicPimsIntegrationCapabilityIntegrationIdCapabilityCompoundUniqueInput>;
};

export type ClinicPimsIntegrationCountAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  lastBatchSyncedAt: Scalars['Int'];
  syncFrequency: Scalars['Int'];
  type: Scalars['Int'];
  bitwerxId: Scalars['Int'];
  clinicId: Scalars['Int'];
  datapointId: Scalars['Int'];
  ezyvetId: Scalars['Int'];
  instinctId: Scalars['Int'];
  neoId: Scalars['Int'];
  vetterId: Scalars['Int'];
  vetdataId: Scalars['Int'];
  ppcId: Scalars['Int'];
  status: Scalars['Int'];
  statusChangedAt: Scalars['Int'];
  statusErrorMessage: Scalars['Int'];
  writebackConfiguration: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPimsIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  writebackConfiguration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCountOutputType = {
  __typename?: 'ClinicPimsIntegrationCountOutputType';
  appointments: Scalars['Int'];
  appointmentTypes: Scalars['Int'];
  employees: Scalars['Int'];
  pets: Scalars['Int'];
  petParents: Scalars['Int'];
  rooms: Scalars['Int'];
  services: Scalars['Int'];
  pimsInvoices: Scalars['Int'];
  pimsInvoiceLineItems: Scalars['Int'];
  capabilities: Scalars['Int'];
  writebackLogEntries: Scalars['Int'];
  vetAvailabilities: Scalars['Int'];
  vaccinations: Scalars['Int'];
  prescriptions: Scalars['Int'];
};

export type ClinicPimsIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationCreateManyClinicInputEnvelope = {
  data: Array<ClinicPimsIntegrationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPimsIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutBitwerxInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutCapabilitiesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutDatapointInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutEzyvetInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutInstinctInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutNeoInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPetsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPpcInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutServicesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServicesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetdataInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetterInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutClinicInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutClinicInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutNeoInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutNeoInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPetsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPpcInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPpcInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutServicesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutServicesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetterInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetterInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput;
};

export type ClinicPimsIntegrationCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutBitwerxInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutDatapointInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutEzyvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutInstinctInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutNeoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPimsInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPimsInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPpcInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetdataInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationListRelationFilter = {
  every?: Maybe<ClinicPimsIntegrationWhereInput>;
  some?: Maybe<ClinicPimsIntegrationWhereInput>;
  none?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type ClinicPimsIntegrationMaxAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationMinAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  BitwerxId = 'bitwerxId',
  ClinicId = 'clinicId',
  DatapointId = 'datapointId',
  EzyvetId = 'ezyvetId',
  InstinctId = 'instinctId',
  NeoId = 'neoId',
  VetterId = 'vetterId',
  VetdataId = 'vetdataId',
  PpcId = 'ppcId',
  StatusErrorMessage = 'statusErrorMessage',
}

export type ClinicPimsIntegrationOrderByRelevanceInput = {
  fields: Array<ClinicPimsIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPimsIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  writebackConfiguration?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPimsIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPimsIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPimsIntegrationMinOrderByAggregateInput>;
};

export type ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  bitwerxId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  datapoint?: Maybe<DataPointIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  ezyvet?: Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  instinct?: Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  neo?: Maybe<NeoIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  vetdata?: Maybe<VetDataIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  vetter?: Maybe<VetterIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  bitwerx?: Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  ppc?: Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  employees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  petParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  rooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  services?: Maybe<ServiceOrderByRelationAggregateInput>;
  pimsInvoices?: Maybe<PimsInvoiceOrderByRelationAggregateInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemOrderByRelationAggregateInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityOrderByRelationAggregateInput>;
  writebackConfiguration?: Maybe<SortOrder>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryOrderByRelationAggregateInput>;
  vetAvailabilities?: Maybe<VetAvailabilityOrderByRelationAggregateInput>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPimsIntegrationOrderByRelevanceInput>;
};

export type ClinicPimsIntegrationRelationFilter = {
  is?: Maybe<ClinicPimsIntegrationWhereInput>;
  isNot?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export enum ClinicPimsIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
  LastSyncedAt = 'lastSyncedAt',
  LastBatchSyncedAt = 'lastBatchSyncedAt',
  SyncFrequency = 'syncFrequency',
  Type = 'type',
  BitwerxId = 'bitwerxId',
  ClinicId = 'clinicId',
  DatapointId = 'datapointId',
  EzyvetId = 'ezyvetId',
  InstinctId = 'instinctId',
  NeoId = 'neoId',
  VetterId = 'vetterId',
  VetdataId = 'vetdataId',
  PpcId = 'ppcId',
  Status = 'status',
  StatusChangedAt = 'statusChangedAt',
  StatusErrorMessage = 'statusErrorMessage',
  WritebackConfiguration = 'writebackConfiguration',
}

export type ClinicPimsIntegrationScalarWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeFilter>;
  bitwerxId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  datapointId?: Maybe<StringNullableFilter>;
  ezyvetId?: Maybe<StringNullableFilter>;
  instinctId?: Maybe<StringNullableFilter>;
  neoId?: Maybe<StringNullableFilter>;
  vetterId?: Maybe<StringNullableFilter>;
  vetdataId?: Maybe<StringNullableFilter>;
  ppcId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  writebackConfiguration?: Maybe<JsonFilter>;
};

export type ClinicPimsIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyWithAggregatesFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  bitwerxId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  datapointId?: Maybe<StringNullableWithAggregatesFilter>;
  ezyvetId?: Maybe<StringNullableWithAggregatesFilter>;
  instinctId?: Maybe<StringNullableWithAggregatesFilter>;
  neoId?: Maybe<StringNullableWithAggregatesFilter>;
  vetterId?: Maybe<StringNullableWithAggregatesFilter>;
  vetdataId?: Maybe<StringNullableWithAggregatesFilter>;
  ppcId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableWithAggregatesFilter>;
  statusChangedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  statusErrorMessage?: Maybe<StringNullableWithAggregatesFilter>;
  writebackConfiguration?: Maybe<JsonWithAggregatesFilter>;
};

export enum ClinicPimsIntegrationType {
  Bitwerx = 'Bitwerx',
  Datapoint = 'Datapoint',
  Ezyvet = 'Ezyvet',
  Instinct = 'Instinct',
  Neo = 'Neo',
  Vetdata = 'Vetdata',
  Vetter = 'Vetter',
  Televet = 'Televet',
  Ppc = 'PPC',
}

export type ClinicPimsIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutBitwerxInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutDatapointInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutEzyvetInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutInstinctInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutNeoInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutPpcInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetdataInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetterInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutNeoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPpcInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutBitwerxInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBitwerxInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutDatapointInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutDatapointInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutEzyvetInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEzyvetInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutInstinctInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutInstinctInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutNeoInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutNeoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutPpcInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPpcInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutVetdataInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetdataInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutVetterInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  bitwerxId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPimsIntegrationScalarWhereInput;
  data: ClinicPimsIntegrationUncheckedUpdateManyWithoutIntegrationsInput;
};

export type ClinicPimsIntegrationUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilitiesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutCapabilitiesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPimsInvoiceLineItemsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPimsInvoicesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServicesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutServicesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutWritebackLogEntriesInput>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutAppointmentTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutBitwerxInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBitwerxInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutDatapointInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutDatapointInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutEmployeesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEmployeesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutEzyvetInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEzyvetInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutInstinctInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutInstinctInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutNeoInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutNeoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPetParentsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPetsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPpcInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPpcInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutRoomsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutRoomsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVaccinationsInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVaccinationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetAvailabilitiesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetdataInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetdataInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetterInput = {
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput>;
};

export type ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  data: ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput;
};

export type ClinicPimsIntegrationUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutBitwerxInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutCapabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutDatapointInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutEzyvetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutInstinctInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutNeoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPimsInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPimsInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPpcInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetdataInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpdateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutClinicInput;
};

export type ClinicPimsIntegrationUpsertWithoutAppointmentTypesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput;
};

export type ClinicPimsIntegrationUpsertWithoutAppointmentsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicPimsIntegrationUpsertWithoutBitwerxInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput;
};

export type ClinicPimsIntegrationUpsertWithoutCapabilitiesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationUpsertWithoutDatapointInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput;
};

export type ClinicPimsIntegrationUpsertWithoutEmployeesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput;
};

export type ClinicPimsIntegrationUpsertWithoutEzyvetInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput;
};

export type ClinicPimsIntegrationUpsertWithoutInstinctInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput;
};

export type ClinicPimsIntegrationUpsertWithoutNeoInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutNeoInput;
};

export type ClinicPimsIntegrationUpsertWithoutPetParentsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPetsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPimsInvoiceLineItemsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPimsInvoicesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput;
};

export type ClinicPimsIntegrationUpsertWithoutPpcInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPpcInput;
};

export type ClinicPimsIntegrationUpsertWithoutPrescriptionsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicPimsIntegrationUpsertWithoutRoomsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput;
};

export type ClinicPimsIntegrationUpsertWithoutServicesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutServicesInput;
};

export type ClinicPimsIntegrationUpsertWithoutVaccinationsInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetAvailabilitiesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetdataInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetterInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetterInput;
};

export type ClinicPimsIntegrationUpsertWithoutWritebackLogEntriesInput = {
  update: ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput;
  create: ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput;
};

export type ClinicPimsIntegrationWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeFilter>;
  bitwerxId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  datapointId?: Maybe<StringNullableFilter>;
  ezyvetId?: Maybe<StringNullableFilter>;
  instinctId?: Maybe<StringNullableFilter>;
  neoId?: Maybe<StringNullableFilter>;
  vetterId?: Maybe<StringNullableFilter>;
  vetdataId?: Maybe<StringNullableFilter>;
  ppcId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  datapoint?: Maybe<DataPointIntegrationWhereInput>;
  ezyvet?: Maybe<EzyVetIntegrationWhereInput>;
  instinct?: Maybe<InstinctIntegrationWhereInput>;
  neo?: Maybe<NeoIntegrationWhereInput>;
  vetdata?: Maybe<VetDataIntegrationWhereInput>;
  vetter?: Maybe<VetterIntegrationWhereInput>;
  bitwerx?: Maybe<BitwerxIntegrationWhereInput>;
  ppc?: Maybe<PpcIntegrationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  employees?: Maybe<ClinicEmployeeListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  petParents?: Maybe<ClinicPetParentListRelationFilter>;
  rooms?: Maybe<ClinicRoomListRelationFilter>;
  services?: Maybe<ServiceListRelationFilter>;
  pimsInvoices?: Maybe<PimsInvoiceListRelationFilter>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemListRelationFilter>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityListRelationFilter>;
  writebackConfiguration?: Maybe<JsonFilter>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryListRelationFilter>;
  vetAvailabilities?: Maybe<VetAvailabilityListRelationFilter>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
};

export type ClinicPimsIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  bitwerxId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSetting = {
  __typename?: 'ClinicPostcardSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  isRemindersEnabled: Scalars['Boolean'];
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
  clinic: Clinic;
};

export type ClinicPostcardSettingCountAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  isRemindersEnabled: Scalars['Int'];
  apiKey: Scalars['Int'];
  apiSecret: Scalars['Int'];
  reminderTemplate: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicPostcardSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
  clinic: ClinicCreateNestedOneWithoutPostcardSettingsInput;
};

export type ClinicPostcardSettingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicPostcardSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPostcardSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
};

export type ClinicPostcardSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicPostcardSettingWhereUniqueInput;
  create: ClinicPostcardSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicPostcardSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingListRelationFilter = {
  every?: Maybe<ClinicPostcardSettingWhereInput>;
  some?: Maybe<ClinicPostcardSettingWhereInput>;
  none?: Maybe<ClinicPostcardSettingWhereInput>;
};

export type ClinicPostcardSettingMaxAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingMinAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPostcardSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  ApiKey = 'apiKey',
  ApiSecret = 'apiSecret',
  ReminderTemplate = 'reminderTemplate',
}

export type ClinicPostcardSettingOrderByRelevanceInput = {
  fields: Array<ClinicPostcardSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicPostcardSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  _count?: Maybe<ClinicPostcardSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPostcardSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPostcardSettingMinOrderByAggregateInput>;
};

export type ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicPostcardSettingOrderByRelevanceInput>;
};

export enum ClinicPostcardSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  IsRemindersEnabled = 'isRemindersEnabled',
  ApiKey = 'apiKey',
  ApiSecret = 'apiSecret',
  ReminderTemplate = 'reminderTemplate',
}

export type ClinicPostcardSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isRemindersEnabled?: Maybe<BoolFilter>;
  apiKey?: Maybe<StringFilter>;
  apiSecret?: Maybe<StringFilter>;
  reminderTemplate?: Maybe<StringFilter>;
};

export type ClinicPostcardSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  isRemindersEnabled?: Maybe<BoolWithAggregatesFilter>;
  apiKey?: Maybe<StringWithAggregatesFilter>;
  apiSecret?: Maybe<StringWithAggregatesFilter>;
  reminderTemplate?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicPostcardSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
};

export type ClinicPostcardSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  reminderTemplate: Scalars['String'];
};

export type ClinicPostcardSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
};

export type ClinicPostcardSettingUncheckedUpdateManyWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPostcardSettingsInput>;
};

export type ClinicPostcardSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicPostcardSettingScalarWhereInput;
  data: ClinicPostcardSettingUncheckedUpdateManyWithoutPostcardSettingsInput;
};

export type ClinicPostcardSettingUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
};

export type ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicPostcardSettingWhereUniqueInput;
  data: ClinicPostcardSettingUncheckedUpdateWithoutClinicInput;
};

export type ClinicPostcardSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  reminderTemplate?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicPostcardSettingWhereUniqueInput;
  update: ClinicPostcardSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicPostcardSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicPostcardSettingWhereInput = {
  AND?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isRemindersEnabled?: Maybe<BoolFilter>;
  apiKey?: Maybe<StringFilter>;
  apiSecret?: Maybe<StringFilter>;
  reminderTemplate?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicPostcardSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type ClinicRelationFilter = {
  is?: Maybe<ClinicWhereInput>;
  isNot?: Maybe<ClinicWhereInput>;
};

export type ClinicRoom = {
  __typename?: 'ClinicRoom';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  integration?: Maybe<ClinicPimsIntegration>;
  appointments: Array<Appointment>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  isVisible: Scalars['Boolean'];
  workflows: Array<ClinicRoomToWorkflowEventSetting>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  _count: ClinicRoomCountOutputType;
};

export type ClinicRoomAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type ClinicRoomUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};

export type ClinicRoomWorkflowsArgs = {
  where?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  orderBy?: Maybe<ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomToWorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomToWorkflowEventSettingScalarFieldEnum>;
};

export type ClinicRoomCountAggregateOutputType = {
  __typename?: 'ClinicRoomCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  clinicId: Scalars['Int'];
  integrationId: Scalars['Int'];
  isSchedulable: Scalars['Int'];
  type: Scalars['Int'];
  isVisible: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicRoomCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ClinicRoomCountOutputType = {
  __typename?: 'ClinicRoomCountOutputType';
  appointments: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  workflows: Scalars['Int'];
};

export type ClinicRoomCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateManyClinicInputEnvelope = {
  data: Array<ClinicRoomCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicRoomCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
};

export type ClinicRoomCreateNestedOneWithoutWorkflowsInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutWorkflowsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutWorkflowsInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
};

export type ClinicRoomCreateOrConnectWithoutAppointmentsInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicRoomCreateOrConnectWithoutClinicInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutClinicInput;
};

export type ClinicRoomCreateOrConnectWithoutIntegrationInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutIntegrationInput;
};

export type ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicRoomCreateOrConnectWithoutWorkflowsInput = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicRoomCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomListRelationFilter = {
  every?: Maybe<ClinicRoomWhereInput>;
  some?: Maybe<ClinicRoomWhereInput>;
  none?: Maybe<ClinicRoomWhereInput>;
};

export type ClinicRoomMaxAggregateOutputType = {
  __typename?: 'ClinicRoomMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
};

export type ClinicRoomMinAggregateOutputType = {
  __typename?: 'ClinicRoomMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
};

export type ClinicRoomOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicRoomOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  PimsId = 'pimsId',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  Type = 'type',
}

export type ClinicRoomOrderByRelevanceInput = {
  fields: Array<ClinicRoomOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicRoomOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ClinicRoomCountOrderByAggregateInput>;
  _max?: Maybe<ClinicRoomMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicRoomMinOrderByAggregateInput>;
};

export type ClinicRoomOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  isVisible?: Maybe<SortOrder>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput>;
  rawPimsValue?: Maybe<SortOrder>;
  _relevance?: Maybe<ClinicRoomOrderByRelevanceInput>;
};

export type ClinicRoomPimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ClinicRoomRelationFilter = {
  is?: Maybe<ClinicRoomWhereInput>;
  isNot?: Maybe<ClinicRoomWhereInput>;
};

export enum ClinicRoomScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Name = 'name',
  PimsId = 'pimsId',
  ClinicId = 'clinicId',
  IntegrationId = 'integrationId',
  IsSchedulable = 'isSchedulable',
  Type = 'type',
  IsVisible = 'isVisible',
  RawPimsValue = 'rawPimsValue',
}

export type ClinicRoomScalarWhereInput = {
  AND?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  OR?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  isVisible?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicRoomScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isSchedulable?: Maybe<BoolNullableWithAggregatesFilter>;
  type?: Maybe<StringNullableWithAggregatesFilter>;
  isVisible?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicRoomToWorkflowEventSetting = {
  __typename?: 'ClinicRoomToWorkflowEventSetting';
  createdAt: Scalars['DateTime'];
  workflowId: Scalars['String'];
  clinicRoomId: Scalars['String'];
  workflow: WorkflowEventSetting;
  clinicRoom: ClinicRoom;
};

export type ClinicRoomToWorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingCountAggregateOutputType';
  createdAt: Scalars['Int'];
  workflowId: Scalars['Int'];
  clinicRoomId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicRoomToWorkflowEventSettingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput;
  clinicRoom: ClinicRoomCreateNestedOneWithoutWorkflowsInput;
};

export type ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope = {
  data: Array<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomToWorkflowEventSettingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCreateManyWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope = {
  data: Array<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput;
};

export type ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
};

export type ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput;
};

export type ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoom: ClinicRoomCreateNestedOneWithoutWorkflowsInput;
};

export type ClinicRoomToWorkflowEventSettingListRelationFilter = {
  every?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  some?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  none?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
};

export type ClinicRoomToWorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicRoomToWorkflowEventSettingOrderByRelevanceFieldEnum {
  WorkflowId = 'workflowId',
  ClinicRoomId = 'clinicRoomId',
}

export type ClinicRoomToWorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<ClinicRoomToWorkflowEventSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicRoomToWorkflowEventSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicRoomToWorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicRoomToWorkflowEventSettingMinOrderByAggregateInput>;
};

export type ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  clinicRoomId?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicRoom?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelevanceInput>;
};

export enum ClinicRoomToWorkflowEventSettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  WorkflowId = 'workflowId',
  ClinicRoomId = 'clinicRoomId',
}

export type ClinicRoomToWorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  clinicRoomId?: Maybe<StringFilter>;
};

export type ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
  clinicRoomId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoomId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsInput>;
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutWorkflowsInput>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput = {
  where: ClinicRoomToWorkflowEventSettingScalarWhereInput;
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput = {
  where: ClinicRoomToWorkflowEventSettingScalarWhereInput;
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomsInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput = {
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsInput>;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithoutWorkflowInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutWorkflowsInput>;
};

export type ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  update: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput;
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput;
};

export type ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput = {
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
  update: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
};

export type ClinicRoomToWorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  clinicRoomId?: Maybe<StringFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  clinicRoom?: Maybe<ClinicRoomWhereInput>;
};

export type ClinicRoomToWorkflowEventSettingWhereUniqueInput = {
  workflowId_clinicRoomId?: Maybe<ClinicRoomToWorkflowEventSettingWorkflowIdClinicRoomIdCompoundUniqueInput>;
};

export type ClinicRoomToWorkflowEventSettingWorkflowIdClinicRoomIdCompoundUniqueInput = {
  workflowId: Scalars['String'];
  clinicRoomId: Scalars['String'];
};

export type ClinicRoomUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
};

export type ClinicRoomUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUncheckedUpdateManyWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUncheckedUpdateManyWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicRoomScalarWhereInput;
  data: ClinicRoomUncheckedUpdateManyWithoutClinicRoomsInput;
};

export type ClinicRoomUpdateManyWithWhereWithoutIntegrationInput = {
  where: ClinicRoomScalarWhereInput;
  data: ClinicRoomUncheckedUpdateManyWithoutRoomsInput;
};

export type ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  where: ClinicRoomScalarWhereInput;
  data: ClinicRoomUncheckedUpdateManyWithoutRoomsInput;
};

export type ClinicRoomUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUpdateManyWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
};

export type ClinicRoomUpdateOneRequiredWithoutWorkflowsInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutWorkflowsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutWorkflowsInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutWorkflowsInput>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutWorkflowsInput>;
};

export type ClinicRoomUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutAppointmentsInput>;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicRoomWhereUniqueInput;
  data: ClinicRoomUncheckedUpdateWithoutClinicInput;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicRoomWhereUniqueInput;
  data: ClinicRoomUncheckedUpdateWithoutIntegrationInput;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: ClinicRoomWhereUniqueInput;
  data: ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput;
};

export type ClinicRoomUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsInput>;
  isVisible?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicRoomWhereUniqueInput;
  update: ClinicRoomUncheckedUpdateWithoutClinicInput;
  create: ClinicRoomUncheckedCreateWithoutClinicInput;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ClinicRoomWhereUniqueInput;
  update: ClinicRoomUncheckedUpdateWithoutIntegrationInput;
  create: ClinicRoomUncheckedCreateWithoutIntegrationInput;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  where: ClinicRoomWhereUniqueInput;
  update: ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicRoomUpsertWithoutAppointmentsInput = {
  update: ClinicRoomUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicRoomUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicRoomUpsertWithoutWorkflowsInput = {
  update: ClinicRoomUncheckedUpdateWithoutWorkflowsInput;
  create: ClinicRoomUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicRoomWhereInput = {
  AND?: Maybe<Array<ClinicRoomWhereInput>>;
  OR?: Maybe<Array<ClinicRoomWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  isVisible?: Maybe<BoolFilter>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingListRelationFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ClinicRoomWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<ClinicRoomPimsIdIntegrationIdCompoundUniqueInput>;
};

export enum ClinicScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  City = 'city',
  State = 'state',
  Country = 'country',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  PostalCode = 'postalCode',
  WebsiteUrl = 'websiteUrl',
  Hours = 'hours',
  Logo = 'logo',
  LastPrintOrderedOn = 'lastPrintOrderedOn',
  LastPrintOrderedQuantity = 'lastPrintOrderedQuantity',
  SubscribedAt = 'subscribedAt',
  ConsultationCost = 'consultationCost',
  FollowUpCost = 'followUpCost',
  IncomeMethod = 'incomeMethod',
  PaymentName = 'paymentName',
  PaymentAddress = 'paymentAddress',
  PaymentCity = 'paymentCity',
  PaymentState = 'paymentState',
  PaymentZip = 'paymentZip',
  PaymentBankRoutingNumber = 'paymentBankRoutingNumber',
  PaymentBankAccountNumber = 'paymentBankAccountNumber',
  FirebaseId = 'firebaseId',
  Timezone = 'timezone',
  StripeCustomerId = 'stripeCustomerId',
  Lat = 'lat',
  Long = 'long',
  FullAddress = 'fullAddress',
  StripeSubscriptionId = 'stripeSubscriptionId',
  StripeSubscriptionPlan = 'stripeSubscriptionPlan',
  Token = 'token',
  Phone = 'phone',
  WidgetSettings = 'widgetSettings',
  ResponseTime = 'responseTime',
  OrganizationId = 'organizationId',
  PpcLocationId = 'ppcLocationId',
  IntegrationType = 'integrationType',
  IntegrationInfo = 'integrationInfo',
  CorporateId = 'corporateId',
  CustomerId = 'customerId',
  SubscriptionId = 'subscriptionId',
  SubscriptionPlan = 'subscriptionPlan',
  IsIntegrationActive = 'isIntegrationActive',
  IsClinicActive = 'isClinicActive',
  UnsubscribedAt = 'unsubscribedAt',
  IsPaymentUsBank = 'isPaymentUsBank',
  IsConsultationsActive = 'isConsultationsActive',
  CancellationReason = 'cancellationReason',
  CancellationNote = 'cancellationNote',
  CanceledAt = 'canceledAt',
  AllowAllUsersToCloseConsultations = 'allowAllUsersToCloseConsultations',
  ClinicEmail = 'clinicEmail',
  FirebaseVets = 'firebaseVets',
  IntegrationSystem = 'integrationSystem',
  MarketEmail = 'marketEmail',
  MarketFacebookNumber = 'marketFacebookNumber',
  MarketFacebookPageId = 'marketFacebookPageId',
  MarketFacebookPageToken = 'marketFacebookPageToken',
  PrintLastOrder = 'printLastOrder',
  PrintReceivedQuantity = 'printReceivedQuantity',
  SubmerchantApprovedAt = 'submerchantApprovedAt',
  SubmerchantId = 'submerchantId',
  HeadUserId = 'headUserId',
  CrmId = 'crmId',
  SubmerchantStatus = 'submerchantStatus',
  SubmerchantIsActive = 'submerchantIsActive',
  CrmUrlParams = 'crmUrlParams',
  PaymentPhone = 'paymentPhone',
  AutomaticSubscriptionReceipts = 'automaticSubscriptionReceipts',
  AutoSubscriptionReceiptEmails = 'autoSubscriptionReceiptEmails',
  SubmerchantStripeId = 'submerchantStripeId',
  SubmerchantStripeStatus = 'submerchantStripeStatus',
  SubmerchantStripeChargesEnabled = 'submerchantStripeChargesEnabled',
  SubmerchantStripePayoutsEnabled = 'submerchantStripePayoutsEnabled',
  SubmerchantStripeHasCustomPayouts = 'submerchantStripeHasCustomPayouts',
  ClinicSettingId = 'clinicSettingId',
  TimezoneName = 'timezoneName',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  ActivationDate = 'activationDate',
  IsAddAppointmentDisabled = 'isAddAppointmentDisabled',
  HasServiceReminders = 'hasServiceReminders',
  HasSmsConversations = 'hasSmsConversations',
  CallerIdPhoneNumber = 'callerIdPhoneNumber',
  SmsPhoneNumber = 'smsPhoneNumber',
  IsCallerIdVerified = 'isCallerIdVerified',
}

export type ClinicScalarWhereInput = {
  AND?: Maybe<Array<ClinicScalarWhereInput>>;
  OR?: Maybe<Array<ClinicScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  websiteUrl?: Maybe<StringNullableFilter>;
  hours?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableFilter>;
  subscribedAt?: Maybe<DateTimeNullableFilter>;
  consultationCost?: Maybe<FloatNullableFilter>;
  followUpCost?: Maybe<FloatNullableFilter>;
  incomeMethod?: Maybe<StringNullableFilter>;
  paymentName?: Maybe<StringNullableFilter>;
  paymentAddress?: Maybe<StringNullableFilter>;
  paymentCity?: Maybe<StringNullableFilter>;
  paymentState?: Maybe<StringNullableFilter>;
  paymentZip?: Maybe<StringNullableFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  timezone?: Maybe<IntNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  lat?: Maybe<FloatNullableFilter>;
  long?: Maybe<FloatNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  stripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  widgetSettings?: Maybe<JsonNullableFilter>;
  responseTime?: Maybe<FloatNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  ppcLocationId?: Maybe<IntNullableFilter>;
  integrationType?: Maybe<StringNullableFilter>;
  integrationInfo?: Maybe<JsonNullableFilter>;
  corporateId?: Maybe<StringNullableFilter>;
  customerId?: Maybe<StringNullableFilter>;
  subscriptionId?: Maybe<StringNullableFilter>;
  subscriptionPlan?: Maybe<StringNullableFilter>;
  isIntegrationActive?: Maybe<BoolNullableFilter>;
  isClinicActive?: Maybe<BoolFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableFilter>;
  isPaymentUsBank?: Maybe<BoolNullableFilter>;
  isConsultationsActive?: Maybe<BoolNullableFilter>;
  cancellationReason?: Maybe<StringNullableFilter>;
  cancellationNote?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableFilter>;
  clinicEmail?: Maybe<StringNullableFilter>;
  firebaseVets?: Maybe<JsonNullableFilter>;
  integrationSystem?: Maybe<StringNullableFilter>;
  marketEmail?: Maybe<StringNullableFilter>;
  marketFacebookNumber?: Maybe<IntNullableFilter>;
  marketFacebookPageId?: Maybe<StringNullableFilter>;
  marketFacebookPageToken?: Maybe<StringNullableFilter>;
  printLastOrder?: Maybe<DateTimeNullableFilter>;
  printReceivedQuantity?: Maybe<IntNullableFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableFilter>;
  submerchantId?: Maybe<StringNullableFilter>;
  headUserId?: Maybe<StringNullableFilter>;
  crmId?: Maybe<StringNullableFilter>;
  submerchantStatus?: Maybe<StringNullableFilter>;
  submerchantIsActive?: Maybe<BoolNullableFilter>;
  crmUrlParams?: Maybe<JsonNullableFilter>;
  paymentPhone?: Maybe<StringNullableFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableFilter>;
  submerchantStripeId?: Maybe<StringNullableFilter>;
  submerchantStripeStatus?: Maybe<StringNullableFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  timezoneName?: Maybe<StringNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableFilter>;
  hasServiceReminders?: Maybe<BoolNullableFilter>;
  hasSmsConversations?: Maybe<BoolNullableFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableFilter>;
  smsPhoneNumber?: Maybe<StringNullableFilter>;
  isCallerIdVerified?: Maybe<BoolNullableFilter>;
};

export type ClinicScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  state?: Maybe<StringNullableWithAggregatesFilter>;
  country?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine1?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine2?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  websiteUrl?: Maybe<StringNullableWithAggregatesFilter>;
  hours?: Maybe<StringNullableWithAggregatesFilter>;
  logo?: Maybe<StringNullableWithAggregatesFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableWithAggregatesFilter>;
  subscribedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  consultationCost?: Maybe<FloatNullableWithAggregatesFilter>;
  followUpCost?: Maybe<FloatNullableWithAggregatesFilter>;
  incomeMethod?: Maybe<StringNullableWithAggregatesFilter>;
  paymentName?: Maybe<StringNullableWithAggregatesFilter>;
  paymentAddress?: Maybe<StringNullableWithAggregatesFilter>;
  paymentCity?: Maybe<StringNullableWithAggregatesFilter>;
  paymentState?: Maybe<StringNullableWithAggregatesFilter>;
  paymentZip?: Maybe<StringNullableWithAggregatesFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableWithAggregatesFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  timezone?: Maybe<IntNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  lat?: Maybe<FloatNullableWithAggregatesFilter>;
  long?: Maybe<FloatNullableWithAggregatesFilter>;
  fullAddress?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableWithAggregatesFilter>;
  token?: Maybe<StringNullableWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  widgetSettings?: Maybe<JsonNullableWithAggregatesFilter>;
  responseTime?: Maybe<FloatNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  ppcLocationId?: Maybe<IntNullableWithAggregatesFilter>;
  integrationType?: Maybe<StringNullableWithAggregatesFilter>;
  integrationInfo?: Maybe<JsonNullableWithAggregatesFilter>;
  corporateId?: Maybe<StringNullableWithAggregatesFilter>;
  customerId?: Maybe<StringNullableWithAggregatesFilter>;
  subscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  subscriptionPlan?: Maybe<StringNullableWithAggregatesFilter>;
  isIntegrationActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isClinicActive?: Maybe<BoolWithAggregatesFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isPaymentUsBank?: Maybe<BoolNullableWithAggregatesFilter>;
  isConsultationsActive?: Maybe<BoolNullableWithAggregatesFilter>;
  cancellationReason?: Maybe<StringNullableWithAggregatesFilter>;
  cancellationNote?: Maybe<StringNullableWithAggregatesFilter>;
  canceledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableWithAggregatesFilter>;
  clinicEmail?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseVets?: Maybe<JsonNullableWithAggregatesFilter>;
  integrationSystem?: Maybe<StringNullableWithAggregatesFilter>;
  marketEmail?: Maybe<StringNullableWithAggregatesFilter>;
  marketFacebookNumber?: Maybe<IntNullableWithAggregatesFilter>;
  marketFacebookPageId?: Maybe<StringNullableWithAggregatesFilter>;
  marketFacebookPageToken?: Maybe<StringNullableWithAggregatesFilter>;
  printLastOrder?: Maybe<DateTimeNullableWithAggregatesFilter>;
  printReceivedQuantity?: Maybe<IntNullableWithAggregatesFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  submerchantId?: Maybe<StringNullableWithAggregatesFilter>;
  headUserId?: Maybe<StringNullableWithAggregatesFilter>;
  crmId?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantStatus?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantIsActive?: Maybe<BoolNullableWithAggregatesFilter>;
  crmUrlParams?: Maybe<JsonNullableWithAggregatesFilter>;
  paymentPhone?: Maybe<StringNullableWithAggregatesFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolWithAggregatesFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableWithAggregatesFilter>;
  submerchantStripeId?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantStripeStatus?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  timezoneName?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  activationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableWithAggregatesFilter>;
  hasServiceReminders?: Maybe<BoolNullableWithAggregatesFilter>;
  hasSmsConversations?: Maybe<BoolNullableWithAggregatesFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  smsPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  isCallerIdVerified?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type ClinicSetting = {
  __typename?: 'ClinicSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  processingFeePercent: Scalars['Float'];
  processingFeeFlat: Scalars['Int'];
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses: Array<ChannelStatus>;
  clinic?: Maybe<Clinic>;
  hideAppointmentsWithoutPetOwner: Scalars['Boolean'];
  workflowsEnabled: Scalars['Boolean'];
  outOfOfficeEnabled: Scalars['Boolean'];
  outOfOfficeHeadline: Scalars['String'];
  outOfOfficeShowScheduleEnabled: Scalars['Boolean'];
  savePaymentInfo: Scalars['Boolean'];
  emergencyContactsEnabled: Scalars['Boolean'];
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfiguration>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics: Array<EmergencyClinics>;
  petPortalSetting?: Maybe<PetPortalSetting>;
  _count: ClinicSettingCountOutputType;
};

export type ClinicSettingChannelStatusesArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
};

export type ClinicSettingEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmergencyClinicsScalarFieldEnum>;
};

export type ClinicSettingAvgAggregateOutputType = {
  __typename?: 'ClinicSettingAvgAggregateOutputType';
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Float']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Float']>;
};

export type ClinicSettingAvgOrderByAggregateInput = {
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
};

export type ClinicSettingCountAggregateOutputType = {
  __typename?: 'ClinicSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  processingFeePercent: Scalars['Int'];
  processingFeeFlat: Scalars['Int'];
  appointmentTimeSlotIncrementInMinutes: Scalars['Int'];
  hasCustomizableFees: Scalars['Int'];
  hideAppointmentsWithoutPetOwner: Scalars['Int'];
  workflowsEnabled: Scalars['Int'];
  outOfOfficeEnabled: Scalars['Int'];
  outOfOfficeHeadline: Scalars['Int'];
  outOfOfficeShowScheduleEnabled: Scalars['Int'];
  savePaymentInfo: Scalars['Int'];
  emergencyContactsEnabled: Scalars['Int'];
  financialReportingConfig: Scalars['Int'];
  smsAutoResponseWhenOpen: Scalars['Int'];
  smsAutoResponseWhenClosed: Scalars['Int'];
  tags: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  financialReportingConfig?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
};

export type ClinicSettingCountOutputType = {
  __typename?: 'ClinicSettingCountOutputType';
  channelStatuses: Scalars['Int'];
  emergencyClinics: Scalars['Int'];
};

export type ClinicSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
};

export type ClinicSettingCreateNestedOneWithoutChannelStatusesInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutChannelStatusesInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutChannelStatusesInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutClinicInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutEmergencyClinicsInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutPaymentFeeConfigInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateNestedOneWithoutPetPortalSettingInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPetPortalSettingInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPetPortalSettingInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
};

export type ClinicSettingCreateOrConnectWithoutChannelStatusesInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutChannelStatusesInput;
};

export type ClinicSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput;
};

export type ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput;
};

export type ClinicSettingCreateOrConnectWithoutPetPortalSettingInput = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingUncheckedCreateWithoutPetPortalSettingInput;
};

export type ClinicSettingCreateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutPaymentFeeConfigInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingCreateWithoutPetPortalSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingMaxAggregateOutputType = {
  __typename?: 'ClinicSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
};

export type ClinicSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
};

export type ClinicSettingMinAggregateOutputType = {
  __typename?: 'ClinicSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
};

export type ClinicSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
};

export enum ClinicSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  OutOfOfficeHeadline = 'outOfOfficeHeadline',
  SmsAutoResponseWhenOpen = 'smsAutoResponseWhenOpen',
  SmsAutoResponseWhenClosed = 'smsAutoResponseWhenClosed',
}

export type ClinicSettingOrderByRelevanceInput = {
  fields: Array<ClinicSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  financialReportingConfig?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  _count?: Maybe<ClinicSettingCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicSettingAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicSettingMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicSettingSumOrderByAggregateInput>;
};

export type ClinicSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  channelStatuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  financialReportingConfig?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  emergencyClinics?: Maybe<EmergencyClinicsOrderByRelationAggregateInput>;
  petPortalSetting?: Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicSettingOrderByRelevanceInput>;
};

export type ClinicSettingRelationFilter = {
  is?: Maybe<ClinicSettingWhereInput>;
  isNot?: Maybe<ClinicSettingWhereInput>;
};

export enum ClinicSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ProcessingFeePercent = 'processingFeePercent',
  ProcessingFeeFlat = 'processingFeeFlat',
  AppointmentTimeSlotIncrementInMinutes = 'appointmentTimeSlotIncrementInMinutes',
  HasCustomizableFees = 'hasCustomizableFees',
  HideAppointmentsWithoutPetOwner = 'hideAppointmentsWithoutPetOwner',
  WorkflowsEnabled = 'workflowsEnabled',
  OutOfOfficeEnabled = 'outOfOfficeEnabled',
  OutOfOfficeHeadline = 'outOfOfficeHeadline',
  OutOfOfficeShowScheduleEnabled = 'outOfOfficeShowScheduleEnabled',
  SavePaymentInfo = 'savePaymentInfo',
  EmergencyContactsEnabled = 'emergencyContactsEnabled',
  FinancialReportingConfig = 'financialReportingConfig',
  SmsAutoResponseWhenOpen = 'smsAutoResponseWhenOpen',
  SmsAutoResponseWhenClosed = 'smsAutoResponseWhenClosed',
  Tags = 'tags',
}

export type ClinicSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  processingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  processingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  hasCustomizableFees?: Maybe<BoolNullableWithAggregatesFilter>;
  hideAppointmentsWithoutPetOwner?: Maybe<BoolWithAggregatesFilter>;
  workflowsEnabled?: Maybe<BoolWithAggregatesFilter>;
  outOfOfficeEnabled?: Maybe<BoolWithAggregatesFilter>;
  outOfOfficeHeadline?: Maybe<StringWithAggregatesFilter>;
  outOfOfficeShowScheduleEnabled?: Maybe<BoolWithAggregatesFilter>;
  savePaymentInfo?: Maybe<BoolWithAggregatesFilter>;
  emergencyContactsEnabled?: Maybe<BoolWithAggregatesFilter>;
  financialReportingConfig?: Maybe<JsonNullableWithAggregatesFilter>;
  smsAutoResponseWhenOpen?: Maybe<StringNullableWithAggregatesFilter>;
  smsAutoResponseWhenClosed?: Maybe<StringNullableWithAggregatesFilter>;
  tags?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicSettingSumAggregateOutputType = {
  __typename?: 'ClinicSettingSumAggregateOutputType';
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
};

export type ClinicSettingSumOrderByAggregateInput = {
  processingFeePercent?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
};

export type ClinicSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedCreateWithoutPetPortalSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
};

export type ClinicSettingUncheckedUpdateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingInput>;
};

export type ClinicSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
};

export type ClinicSettingUpdateOneRequiredWithoutEmergencyClinicsInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutEmergencyClinicsInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput>;
};

export type ClinicSettingUpdateOneRequiredWithoutPaymentFeeConfigInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutPaymentFeeConfigInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput>;
};

export type ClinicSettingUpdateOneRequiredWithoutPetPortalSettingInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPetPortalSettingInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPetPortalSettingInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutPetPortalSettingInput>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput>;
};

export type ClinicSettingUpdateOneWithoutChannelStatusesInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutChannelStatusesInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutChannelStatusesInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutChannelStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutChannelStatusesInput>;
};

export type ClinicSettingUpdateOneWithoutClinicInput = {
  create?: Maybe<ClinicSettingUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutClinicInput>;
};

export type ClinicSettingUpdateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUpdateWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUpdateWithoutPaymentFeeConfigInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingInput>;
};

export type ClinicSettingUpdateWithoutPetPortalSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingInput>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingInput>;
};

export type ClinicSettingUpsertWithoutChannelStatusesInput = {
  update: ClinicSettingUncheckedUpdateWithoutChannelStatusesInput;
  create: ClinicSettingUncheckedCreateWithoutChannelStatusesInput;
};

export type ClinicSettingUpsertWithoutClinicInput = {
  update: ClinicSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicSettingUpsertWithoutEmergencyClinicsInput = {
  update: ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput;
  create: ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput;
};

export type ClinicSettingUpsertWithoutPaymentFeeConfigInput = {
  update: ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput;
  create: ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput;
};

export type ClinicSettingUpsertWithoutPetPortalSettingInput = {
  update: ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput;
  create: ClinicSettingUncheckedCreateWithoutPetPortalSettingInput;
};

export type ClinicSettingWhereInput = {
  AND?: Maybe<Array<ClinicSettingWhereInput>>;
  OR?: Maybe<Array<ClinicSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  processingFeePercent?: Maybe<FloatFilter>;
  processingFeeFlat?: Maybe<IntFilter>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<IntNullableFilter>;
  hasCustomizableFees?: Maybe<BoolNullableFilter>;
  channelStatuses?: Maybe<ChannelStatusListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  hideAppointmentsWithoutPetOwner?: Maybe<BoolFilter>;
  workflowsEnabled?: Maybe<BoolFilter>;
  outOfOfficeEnabled?: Maybe<BoolFilter>;
  outOfOfficeHeadline?: Maybe<StringFilter>;
  outOfOfficeShowScheduleEnabled?: Maybe<BoolFilter>;
  savePaymentInfo?: Maybe<BoolFilter>;
  emergencyContactsEnabled?: Maybe<BoolFilter>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  financialReportingConfig?: Maybe<JsonNullableFilter>;
  smsAutoResponseWhenOpen?: Maybe<StringNullableFilter>;
  smsAutoResponseWhenClosed?: Maybe<StringNullableFilter>;
  tags?: Maybe<JsonNullableFilter>;
  emergencyClinics?: Maybe<EmergencyClinicsListRelationFilter>;
  petPortalSetting?: Maybe<PetPortalSettingWhereInput>;
};

export type ClinicSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicSubscription = {
  __typename?: 'ClinicSubscription';
  node: Clinic;
  mutation: MutationType;
};

export type ClinicSumAggregateOutputType = {
  __typename?: 'ClinicSumAggregateOutputType';
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
};

export type ClinicSumOrderByAggregateInput = {
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
};

export type ClinicUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutFeatureFlagsInput = {
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutStaffInput = {
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput = {
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutUsersIdInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
};

export type ClinicUncheckedCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
};

export type ClinicUncheckedCreateWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutCarePayoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutTwilioConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicUncheckedUpdateManyWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicUncheckedUpdateManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicUncheckedUpdateManyWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicUncheckedUpdateManyWithoutFeatureFlagsInput = {
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutFeatureFlagsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicUncheckedUpdateManyWithoutStaffInput = {
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStaffInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStaffInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStaffInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutStripePayoutAccountsInput = {
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutUsersIdInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersIdInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersIdInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUncheckedUpdateOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicSettingInput>;
};

export type ClinicUncheckedUpdateWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutTwilioConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  headUserId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
};

export type ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicInput;
};

export type ClinicUpdateManyWithWhereWithoutFeatureFlagsInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutOrganizationInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutPetsInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutPreviousClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutStaffInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithWhereWithoutUsersIdInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsIdInput;
};

export type ClinicUpdateManyWithWhereWithoutUsersInput = {
  where: ClinicScalarWhereInput;
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
};

export type ClinicUpdateManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutFeatureFlagsInput = {
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutFeatureFlagsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutPetsInput = {
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutPetsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutPetsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutStaffInput = {
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStaffInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStaffInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStaffInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutStripePayoutAccountsInput = {
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutUsersIdInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersIdInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersIdInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
};

export type ClinicUpdateOneRequiredWithoutAutomationRunsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicUpsertWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAutomationRunsInput>;
};

export type ClinicUpdateOneRequiredWithoutBenefitsProvidedInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutBenefitsProvidedInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBenefitsProvidedInput>;
  upsert?: Maybe<ClinicUpsertWithoutBenefitsProvidedInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutBenefitsProvidedInput>;
};

export type ClinicUpdateOneRequiredWithoutCallsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCallsInput>;
  upsert?: Maybe<ClinicUpsertWithoutCallsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutCallsInput>;
};

export type ClinicUpdateOneRequiredWithoutChannelFilterSelectionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelFilterSelectionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelFilterSelectionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput>;
};

export type ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicDirectBookingSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicDirectBookingSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicEmailCampaignSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicEmailCampaignSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicPhoneNumberInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPhoneNumberInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPhoneNumberInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPhoneNumberInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPhoneNumberInput>;
};

export type ClinicUpdateOneRequiredWithoutFinancialTransactionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFinancialTransactionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutFinancialTransactionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFinancialTransactionsInput>;
};

export type ClinicUpdateOneRequiredWithoutMassTextAlertsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMassTextAlertsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMassTextAlertsInput>;
  upsert?: Maybe<ClinicUpsertWithoutMassTextAlertsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutMassTextAlertsInput>;
};

export type ClinicUpdateOneRequiredWithoutMessageTemplatesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutMessageTemplatesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMessageTemplatesInput>;
  upsert?: Maybe<ClinicUpsertWithoutMessageTemplatesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutMessageTemplatesInput>;
};

export type ClinicUpdateOneRequiredWithoutPostcardSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPostcardSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPostcardSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutPostcardSettingsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPostcardSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutPrescriptionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutPrescriptionsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ClinicUpdateOneRequiredWithoutRulesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutRulesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRulesInput>;
  upsert?: Maybe<ClinicUpsertWithoutRulesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutRulesInput>;
};

export type ClinicUpdateOneRequiredWithoutStripeTerminalLocationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStripeTerminalLocationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripeTerminalLocationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutStripeTerminalLocationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput>;
};

export type ClinicUpdateOneRequiredWithoutTwilioConfigurationInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioConfigurationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioConfigurationInput>;
  upsert?: Maybe<ClinicUpsertWithoutTwilioConfigurationInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutTwilioConfigurationInput>;
};

export type ClinicUpdateOneRequiredWithoutTwilioVerificationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioVerificationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioVerificationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutTwilioVerificationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutTwilioVerificationsInput>;
};

export type ClinicUpdateOneRequiredWithoutVaccinationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVaccinationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutVaccinationsInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutVaccinationsInput>;
};

export type ClinicUpdateOneRequiredWithoutVetAvailabilitiesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutVetAvailabilitiesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetAvailabilitiesInput>;
  upsert?: Maybe<ClinicUpsertWithoutVetAvailabilitiesInput>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutVetAvailabilitiesInput>;
};

export type ClinicUpdateOneWithoutAppoinmentTypesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppoinmentTypesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppoinmentTypesInput>;
  upsert?: Maybe<ClinicUpsertWithoutAppoinmentTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAppoinmentTypesInput>;
};

export type ClinicUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAppointmentsInput>;
};

export type ClinicUpdateOneWithoutCarePayoutOrganizationInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutCarePayoutOrganizationInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCarePayoutOrganizationInput>;
  upsert?: Maybe<ClinicUpsertWithoutCarePayoutOrganizationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput>;
};

export type ClinicUpdateOneWithoutChannelsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelsInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelsInput>;
};

export type ClinicUpdateOneWithoutClinicEmployeesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmployeesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmployeesInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicEmployeesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicEmployeesInput>;
};

export type ClinicUpdateOneWithoutClinicOfficeHoursInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOfficeHoursInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOfficeHoursInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicOfficeHoursInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicOfficeHoursInput>;
};

export type ClinicUpdateOneWithoutClinicPetParentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetParentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPetParentsInput>;
};

export type ClinicUpdateOneWithoutClinicPetsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPetsInput>;
};

export type ClinicUpdateOneWithoutClinicRoomsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicRoomsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicRoomsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicRoomsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicRoomsInput>;
};

export type ClinicUpdateOneWithoutClinicSettingInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicSettingInput>;
};

export type ClinicUpdateOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicWidgetRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput>;
};

export type ClinicUpdateOneWithoutClinicWorkflowTypesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWorkflowTypesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWorkflowTypesInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicWorkflowTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput>;
};

export type ClinicUpdateOneWithoutConsultationRequestsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationRequestsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationRequestsInput>;
  upsert?: Maybe<ClinicUpsertWithoutConsultationRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutConsultationRequestsInput>;
};

export type ClinicUpdateOneWithoutConsultationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutConsultationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutConsultationsInput>;
};

export type ClinicUpdateOneWithoutFormSubmissionsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type ClinicUpdateOneWithoutFormTemplatesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutFormTemplatesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormTemplatesInput>;
  upsert?: Maybe<ClinicUpsertWithoutFormTemplatesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFormTemplatesInput>;
};

export type ClinicUpdateOneWithoutIntegrationsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutIntegrationsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutIntegrationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutIntegrationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutIntegrationsInput>;
};

export type ClinicUpdateOneWithoutPetsIdInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPetsIdInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPetsIdInput>;
  upsert?: Maybe<ClinicUpsertWithoutPetsIdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPetsIdInput>;
};

export type ClinicUpdateOneWithoutPromoCodesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutPromoCodesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPromoCodesInput>;
  upsert?: Maybe<ClinicUpsertWithoutPromoCodesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPromoCodesInput>;
};

export type ClinicUpdateOneWithoutRolesInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutRolesInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRolesInput>;
  upsert?: Maybe<ClinicUpsertWithoutRolesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutRolesInput>;
};

export type ClinicUpdateOneWithoutStaffIdInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStaffIdInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStaffIdInput>;
  upsert?: Maybe<ClinicUpsertWithoutStaffIdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStaffIdInput>;
};

export type ClinicUpdateOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type ClinicUpdateOneWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutUserAppointmentGroupingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput>;
};

export type ClinicUpdateOneWithoutWorkflowEventSettingsInput = {
  create?: Maybe<ClinicUncheckedCreateWithoutWorkflowEventSettingsInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutWorkflowEventSettingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWhereUniqueInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput>;
};

export type ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutClinicWidgetSettingInput;
};

export type ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutFeatureFlagsInput;
};

export type ClinicUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutOrganizationInput;
};

export type ClinicUpdateWithWhereUniqueWithoutPetsInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutPetsInput;
};

export type ClinicUpdateWithWhereUniqueWithoutStaffInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutStaffInput;
};

export type ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutStripePayoutAccountsInput;
};

export type ClinicUpdateWithWhereUniqueWithoutUsersIdInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutUsersIdInput;
};

export type ClinicUpdateWithWhereUniqueWithoutUsersInput = {
  where: ClinicWhereUniqueInput;
  data: ClinicUncheckedUpdateWithoutUsersInput;
};

export type ClinicUpdateWithoutAppoinmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutBenefitsProvidedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutCarePayoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicDirectBookingSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicEmailCampaignSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicOfficeHoursInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutIntegrationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutPostcardSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutTwilioConfigurationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutUsersIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpdateWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  incomeMethod?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  responseTime?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  corporateId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationNote?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  clinicEmail?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  paymentPhone?: Maybe<Scalars['String']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  timezoneName?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicInput>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicInput>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicInput>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicInput>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsInput>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicInput>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicInput>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicInput>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicInput>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicInput>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicInput>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicInput>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicInput>;
};

export type ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutClinicWidgetSettingInput;
  create: ClinicUncheckedCreateWithoutClinicWidgetSettingInput;
};

export type ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutFeatureFlagsInput;
  create: ClinicUncheckedCreateWithoutFeatureFlagsInput;
};

export type ClinicUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutOrganizationInput;
  create: ClinicUncheckedCreateWithoutOrganizationInput;
};

export type ClinicUpsertWithWhereUniqueWithoutPetsInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutPetsInput;
  create: ClinicUncheckedCreateWithoutPetsInput;
};

export type ClinicUpsertWithWhereUniqueWithoutStaffInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutStaffInput;
  create: ClinicUncheckedCreateWithoutStaffInput;
};

export type ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutStripePayoutAccountsInput;
  create: ClinicUncheckedCreateWithoutStripePayoutAccountsInput;
};

export type ClinicUpsertWithWhereUniqueWithoutUsersIdInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutUsersIdInput;
  create: ClinicUncheckedCreateWithoutUsersIdInput;
};

export type ClinicUpsertWithWhereUniqueWithoutUsersInput = {
  where: ClinicWhereUniqueInput;
  update: ClinicUncheckedUpdateWithoutUsersInput;
  create: ClinicUncheckedCreateWithoutUsersInput;
};

export type ClinicUpsertWithoutAppoinmentTypesInput = {
  update: ClinicUncheckedUpdateWithoutAppoinmentTypesInput;
  create: ClinicUncheckedCreateWithoutAppoinmentTypesInput;
};

export type ClinicUpsertWithoutAppointmentsInput = {
  update: ClinicUncheckedUpdateWithoutAppointmentsInput;
  create: ClinicUncheckedCreateWithoutAppointmentsInput;
};

export type ClinicUpsertWithoutAutomationRunsInput = {
  update: ClinicUncheckedUpdateWithoutAutomationRunsInput;
  create: ClinicUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicUpsertWithoutBenefitsProvidedInput = {
  update: ClinicUncheckedUpdateWithoutBenefitsProvidedInput;
  create: ClinicUncheckedCreateWithoutBenefitsProvidedInput;
};

export type ClinicUpsertWithoutCallsInput = {
  update: ClinicUncheckedUpdateWithoutCallsInput;
  create: ClinicUncheckedCreateWithoutCallsInput;
};

export type ClinicUpsertWithoutCarePayoutOrganizationInput = {
  update: ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput;
  create: ClinicUncheckedCreateWithoutCarePayoutOrganizationInput;
};

export type ClinicUpsertWithoutChannelFilterSelectionsInput = {
  update: ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput;
  create: ClinicUncheckedCreateWithoutChannelFilterSelectionsInput;
};

export type ClinicUpsertWithoutChannelStatusChannelAssigneesInput = {
  update: ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
  create: ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type ClinicUpsertWithoutChannelsInput = {
  update: ClinicUncheckedUpdateWithoutChannelsInput;
  create: ClinicUncheckedCreateWithoutChannelsInput;
};

export type ClinicUpsertWithoutClinicDirectBookingSettingsInput = {
  update: ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput;
  create: ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput;
};

export type ClinicUpsertWithoutClinicEmailCampaignSettingsInput = {
  update: ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput;
  create: ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicUpsertWithoutClinicEmployeesInput = {
  update: ClinicUncheckedUpdateWithoutClinicEmployeesInput;
  create: ClinicUncheckedCreateWithoutClinicEmployeesInput;
};

export type ClinicUpsertWithoutClinicOfficeHoursInput = {
  update: ClinicUncheckedUpdateWithoutClinicOfficeHoursInput;
  create: ClinicUncheckedCreateWithoutClinicOfficeHoursInput;
};

export type ClinicUpsertWithoutClinicPetParentsInput = {
  update: ClinicUncheckedUpdateWithoutClinicPetParentsInput;
  create: ClinicUncheckedCreateWithoutClinicPetParentsInput;
};

export type ClinicUpsertWithoutClinicPetsInput = {
  update: ClinicUncheckedUpdateWithoutClinicPetsInput;
  create: ClinicUncheckedCreateWithoutClinicPetsInput;
};

export type ClinicUpsertWithoutClinicPhoneNumberInput = {
  update: ClinicUncheckedUpdateWithoutClinicPhoneNumberInput;
  create: ClinicUncheckedCreateWithoutClinicPhoneNumberInput;
};

export type ClinicUpsertWithoutClinicRoomsInput = {
  update: ClinicUncheckedUpdateWithoutClinicRoomsInput;
  create: ClinicUncheckedCreateWithoutClinicRoomsInput;
};

export type ClinicUpsertWithoutClinicSettingInput = {
  update: ClinicUncheckedUpdateWithoutClinicSettingInput;
  create: ClinicUncheckedCreateWithoutClinicSettingInput;
};

export type ClinicUpsertWithoutClinicWidgetRequestsInput = {
  update: ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput;
  create: ClinicUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicUpsertWithoutClinicWorkflowTypesInput = {
  update: ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput;
  create: ClinicUncheckedCreateWithoutClinicWorkflowTypesInput;
};

export type ClinicUpsertWithoutConsultationRequestsInput = {
  update: ClinicUncheckedUpdateWithoutConsultationRequestsInput;
  create: ClinicUncheckedCreateWithoutConsultationRequestsInput;
};

export type ClinicUpsertWithoutConsultationsInput = {
  update: ClinicUncheckedUpdateWithoutConsultationsInput;
  create: ClinicUncheckedCreateWithoutConsultationsInput;
};

export type ClinicUpsertWithoutFinancialTransactionsInput = {
  update: ClinicUncheckedUpdateWithoutFinancialTransactionsInput;
  create: ClinicUncheckedCreateWithoutFinancialTransactionsInput;
};

export type ClinicUpsertWithoutFormSubmissionsInput = {
  update: ClinicUncheckedUpdateWithoutFormSubmissionsInput;
  create: ClinicUncheckedCreateWithoutFormSubmissionsInput;
};

export type ClinicUpsertWithoutFormTemplatesInput = {
  update: ClinicUncheckedUpdateWithoutFormTemplatesInput;
  create: ClinicUncheckedCreateWithoutFormTemplatesInput;
};

export type ClinicUpsertWithoutIntegrationsInput = {
  update: ClinicUncheckedUpdateWithoutIntegrationsInput;
  create: ClinicUncheckedCreateWithoutIntegrationsInput;
};

export type ClinicUpsertWithoutMassTextAlertsInput = {
  update: ClinicUncheckedUpdateWithoutMassTextAlertsInput;
  create: ClinicUncheckedCreateWithoutMassTextAlertsInput;
};

export type ClinicUpsertWithoutMessageTemplatesInput = {
  update: ClinicUncheckedUpdateWithoutMessageTemplatesInput;
  create: ClinicUncheckedCreateWithoutMessageTemplatesInput;
};

export type ClinicUpsertWithoutPetsIdInput = {
  update: ClinicUncheckedUpdateWithoutPetsIdInput;
  create: ClinicUncheckedCreateWithoutPetsIdInput;
};

export type ClinicUpsertWithoutPostcardSettingsInput = {
  update: ClinicUncheckedUpdateWithoutPostcardSettingsInput;
  create: ClinicUncheckedCreateWithoutPostcardSettingsInput;
};

export type ClinicUpsertWithoutPrescriptionsInput = {
  update: ClinicUncheckedUpdateWithoutPrescriptionsInput;
  create: ClinicUncheckedCreateWithoutPrescriptionsInput;
};

export type ClinicUpsertWithoutPromoCodesInput = {
  update: ClinicUncheckedUpdateWithoutPromoCodesInput;
  create: ClinicUncheckedCreateWithoutPromoCodesInput;
};

export type ClinicUpsertWithoutRolesInput = {
  update: ClinicUncheckedUpdateWithoutRolesInput;
  create: ClinicUncheckedCreateWithoutRolesInput;
};

export type ClinicUpsertWithoutRulesInput = {
  update: ClinicUncheckedUpdateWithoutRulesInput;
  create: ClinicUncheckedCreateWithoutRulesInput;
};

export type ClinicUpsertWithoutStaffIdInput = {
  update: ClinicUncheckedUpdateWithoutStaffIdInput;
  create: ClinicUncheckedCreateWithoutStaffIdInput;
};

export type ClinicUpsertWithoutStripePaymentIntentsInput = {
  update: ClinicUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: ClinicUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type ClinicUpsertWithoutStripeTerminalLocationsInput = {
  update: ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput;
  create: ClinicUncheckedCreateWithoutStripeTerminalLocationsInput;
};

export type ClinicUpsertWithoutTwilioConfigurationInput = {
  update: ClinicUncheckedUpdateWithoutTwilioConfigurationInput;
  create: ClinicUncheckedCreateWithoutTwilioConfigurationInput;
};

export type ClinicUpsertWithoutTwilioVerificationsInput = {
  update: ClinicUncheckedUpdateWithoutTwilioVerificationsInput;
  create: ClinicUncheckedCreateWithoutTwilioVerificationsInput;
};

export type ClinicUpsertWithoutUserAppointmentGroupingsInput = {
  update: ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type ClinicUpsertWithoutVaccinationsInput = {
  update: ClinicUncheckedUpdateWithoutVaccinationsInput;
  create: ClinicUncheckedCreateWithoutVaccinationsInput;
};

export type ClinicUpsertWithoutVetAvailabilitiesInput = {
  update: ClinicUncheckedUpdateWithoutVetAvailabilitiesInput;
  create: ClinicUncheckedCreateWithoutVetAvailabilitiesInput;
};

export type ClinicUpsertWithoutWorkflowEventSettingsInput = {
  update: ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput;
  create: ClinicUncheckedCreateWithoutWorkflowEventSettingsInput;
};

export type ClinicWhereInput = {
  AND?: Maybe<Array<ClinicWhereInput>>;
  OR?: Maybe<Array<ClinicWhereInput>>;
  NOT?: Maybe<Array<ClinicWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  websiteUrl?: Maybe<StringNullableFilter>;
  hours?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableFilter>;
  subscribedAt?: Maybe<DateTimeNullableFilter>;
  consultationCost?: Maybe<FloatNullableFilter>;
  followUpCost?: Maybe<FloatNullableFilter>;
  incomeMethod?: Maybe<StringNullableFilter>;
  paymentName?: Maybe<StringNullableFilter>;
  paymentAddress?: Maybe<StringNullableFilter>;
  paymentCity?: Maybe<StringNullableFilter>;
  paymentState?: Maybe<StringNullableFilter>;
  paymentZip?: Maybe<StringNullableFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  timezone?: Maybe<IntNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  lat?: Maybe<FloatNullableFilter>;
  long?: Maybe<FloatNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  stripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  widgetSettings?: Maybe<JsonNullableFilter>;
  responseTime?: Maybe<FloatNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  ppcLocationId?: Maybe<IntNullableFilter>;
  integrationType?: Maybe<StringNullableFilter>;
  integrationInfo?: Maybe<JsonNullableFilter>;
  corporateId?: Maybe<StringNullableFilter>;
  customerId?: Maybe<StringNullableFilter>;
  subscriptionId?: Maybe<StringNullableFilter>;
  subscriptionPlan?: Maybe<StringNullableFilter>;
  isIntegrationActive?: Maybe<BoolNullableFilter>;
  isClinicActive?: Maybe<BoolFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableFilter>;
  isPaymentUsBank?: Maybe<BoolNullableFilter>;
  isConsultationsActive?: Maybe<BoolNullableFilter>;
  cancellationReason?: Maybe<StringNullableFilter>;
  cancellationNote?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableFilter>;
  clinicEmail?: Maybe<StringNullableFilter>;
  firebaseVets?: Maybe<JsonNullableFilter>;
  integrationSystem?: Maybe<StringNullableFilter>;
  marketEmail?: Maybe<StringNullableFilter>;
  marketFacebookNumber?: Maybe<IntNullableFilter>;
  marketFacebookPageId?: Maybe<StringNullableFilter>;
  marketFacebookPageToken?: Maybe<StringNullableFilter>;
  printLastOrder?: Maybe<DateTimeNullableFilter>;
  printReceivedQuantity?: Maybe<IntNullableFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableFilter>;
  submerchantId?: Maybe<StringNullableFilter>;
  headUserId?: Maybe<StringNullableFilter>;
  crmId?: Maybe<StringNullableFilter>;
  submerchantStatus?: Maybe<StringNullableFilter>;
  submerchantIsActive?: Maybe<BoolNullableFilter>;
  crmUrlParams?: Maybe<JsonNullableFilter>;
  paymentPhone?: Maybe<StringNullableFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableFilter>;
  submerchantStripeId?: Maybe<StringNullableFilter>;
  submerchantStripeStatus?: Maybe<StringNullableFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  timezoneName?: Maybe<StringNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingWhereInput>;
  usersId?: Maybe<UserWhereInput>;
  organization?: Maybe<OrganizationWhereInput>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  appoinmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourListRelationFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  integrations?: Maybe<ClinicPimsIntegrationListRelationFilter>;
  clinicRooms?: Maybe<ClinicRoomListRelationFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeListRelationFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  consultationRequests?: Maybe<ConsultationRequestListRelationFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  formTemplates?: Maybe<FormTemplateListRelationFilter>;
  petsId?: Maybe<PetListRelationFilter>;
  promoCodes?: Maybe<PromoCodeListRelationFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  staffId?: Maybe<VetInfoListRelationFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountListRelationFilter>;
  pets?: Maybe<PetListRelationFilter>;
  staff?: Maybe<VetInfoListRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableFilter>;
  hasServiceReminders?: Maybe<BoolNullableFilter>;
  hasSmsConversations?: Maybe<BoolNullableFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableFilter>;
  smsPhoneNumber?: Maybe<StringNullableFilter>;
  isCallerIdVerified?: Maybe<BoolNullableFilter>;
  twilioVerifications?: Maybe<TwilioVerificationListRelationFilter>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationListRelationFilter>;
  twilioConfiguration?: Maybe<TwilioConfigurationWhereInput>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
  featureFlags?: Maybe<FeatureFlagListRelationFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingListRelationFilter>;
  calls?: Maybe<CallHistoryListRelationFilter>;
  benefitsProvided?: Maybe<CareBenefitUsageListRelationFilter>;
  carePayoutOrganization?: Maybe<OrganizationWhereInput>;
  vetAvailabilities?: Maybe<VetAvailabilityListRelationFilter>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingListRelationFilter>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionListRelationFilter>;
  messageTemplates?: Maybe<MessageTemplateListRelationFilter>;
  Rules?: Maybe<RulesListRelationFilter>;
  postcardSettings?: Maybe<ClinicPostcardSettingListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  massTextAlerts?: Maybe<MassTextAlertListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberListRelationFilter>;
};

export type ClinicWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequest = {
  __typename?: 'ClinicWidgetRequest';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  clinic?: Maybe<Clinic>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  formSubmission?: Maybe<FormSubmission>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  _count: ClinicWidgetRequestCountOutputType;
};

export type ClinicWidgetRequestChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};

export type ClinicWidgetRequestChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};

export type ClinicWidgetRequestAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type ClinicWidgetRequestCountAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  url: Scalars['Int'];
  requestFormSubmission: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicWidgetRequestTypeId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  isVerified: Scalars['Int'];
  verificationMethod: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicWidgetRequestCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  requestFormSubmission?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestCountOutputType = {
  __typename?: 'ClinicWidgetRequestCountOutputType';
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
};

export type ClinicWidgetRequestCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
};

export type ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput;
};

export type ClinicWidgetRequestCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestListRelationFilter = {
  every?: Maybe<ClinicWidgetRequestWhereInput>;
  some?: Maybe<ClinicWidgetRequestWhereInput>;
  none?: Maybe<ClinicWidgetRequestWhereInput>;
};

export type ClinicWidgetRequestMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestMinAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWidgetRequestOrderByRelevanceFieldEnum {
  Id = 'id',
  Url = 'url',
  ClinicId = 'clinicId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  ClinicPetParentId = 'clinicPetParentId',
  FormSubmissionId = 'formSubmissionId',
}

export type ClinicWidgetRequestOrderByRelevanceInput = {
  fields: Array<ClinicWidgetRequestOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicWidgetRequestOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  requestFormSubmission?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
  _count?: Maybe<ClinicWidgetRequestCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetRequestMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetRequestMinOrderByAggregateInput>;
};

export type ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  requestFormSubmission?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicWidgetRequestOrderByRelevanceInput>;
};

export type ClinicWidgetRequestRelationFilter = {
  is?: Maybe<ClinicWidgetRequestWhereInput>;
  isNot?: Maybe<ClinicWidgetRequestWhereInput>;
};

export enum ClinicWidgetRequestScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  RequestFormSubmission = 'requestFormSubmission',
  ClinicId = 'clinicId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  ClinicPetParentId = 'clinicPetParentId',
  FormSubmissionId = 'formSubmissionId',
  IsVerified = 'isVerified',
  VerificationMethod = 'verificationMethod',
}

export type ClinicWidgetRequestScalarWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  requestFormSubmission?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableFilter>;
};

export type ClinicWidgetRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
  requestFormSubmission?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  isVerified?: Maybe<BoolNullableWithAggregatesFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableWithAggregatesFilter>;
};

export type ClinicWidgetRequestSubscription = {
  __typename?: 'ClinicWidgetRequestSubscription';
  node: ClinicWidgetRequest;
  mutation: MutationType;
};

export type ClinicWidgetRequestType = {
  __typename?: 'ClinicWidgetRequestType';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  channelStatus?: Maybe<ChannelStatus>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  assignees: Array<User>;
  workflows: Array<WorkflowEventSetting>;
  formTemplate?: Maybe<FormTemplate>;
  _count: ClinicWidgetRequestTypeCountOutputType;
};

export type ClinicWidgetRequestTypeClinicWidgetRequestsArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
};

export type ClinicWidgetRequestTypeAssigneesArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};

export type ClinicWidgetRequestTypeWorkflowsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};

export type ClinicWidgetRequestTypeAvgAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type ClinicWidgetRequestTypeAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeCountAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  displayName: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isActive: Scalars['Int'];
  order: Scalars['Int'];
  requestType: Scalars['Int'];
  requestFormTemplate: Scalars['Int'];
  clinicWidgetSettingId: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicWidgetRequestTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeCountOutputType = {
  __typename?: 'ClinicWidgetRequestTypeCountOutputType';
  clinicWidgetRequests: Scalars['Int'];
  assignees: Scalars['Int'];
  workflows: Scalars['Int'];
};

export type ClinicWidgetRequestTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateManyChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput>;
  connect?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicWidgetRequestTypeCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeListRelationFilter = {
  every?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  some?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  none?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export type ClinicWidgetRequestTypeMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeMinAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWidgetRequestTypeOrderByRelevanceFieldEnum {
  Id = 'id',
  DisplayName = 'displayName',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  ChannelStatusId = 'channelStatusId',
  FormTemplateId = 'formTemplateId',
}

export type ClinicWidgetRequestTypeOrderByRelevanceInput = {
  fields: Array<ClinicWidgetRequestTypeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicWidgetRequestTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  _count?: Maybe<ClinicWidgetRequestTypeCountOrderByAggregateInput>;
  _avg?: Maybe<ClinicWidgetRequestTypeAvgOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetRequestTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetRequestTypeMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicWidgetRequestTypeSumOrderByAggregateInput>;
};

export type ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicWidgetRequestTypeOrderByRelevanceInput>;
};

export type ClinicWidgetRequestTypeRelationFilter = {
  is?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  isNot?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export enum ClinicWidgetRequestTypeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DisplayName = 'displayName',
  IsDeleted = 'isDeleted',
  IsActive = 'isActive',
  Order = 'order',
  RequestType = 'requestType',
  RequestFormTemplate = 'requestFormTemplate',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  ChannelStatusId = 'channelStatusId',
  FormTemplateId = 'formTemplateId',
}

export type ClinicWidgetRequestTypeScalarWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  order?: Maybe<IntFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeFilter>;
  requestFormTemplate?: Maybe<JsonNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
};

export type ClinicWidgetRequestTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeWithAggregatesFilter>;
  requestFormTemplate?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  channelStatusId?: Maybe<StringNullableWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicWidgetRequestTypeSumAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type ClinicWidgetRequestTypeSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestType: WidgetRequestType;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  channelStatusId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput = {
  where: ClinicWidgetRequestTypeScalarWhereInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWidgetRequestTypesInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput = {
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
};

export type ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicWidgetRequestTypeUpsertWithoutClinicWidgetRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput>;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput;
};

export type ClinicWidgetRequestTypeUpdateWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutChannelStatusInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutClinicWidgetSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeInput>;
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput;
};

export type ClinicWidgetRequestTypeUpsertWithoutClinicWidgetRequestsInput = {
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput;
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestTypeWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  order?: Maybe<IntFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeFilter>;
  requestFormTemplate?: Maybe<JsonNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingWhereInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  assignees?: Maybe<UserListRelationFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
};

export type ClinicWidgetRequestTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicWidgetRequestScalarWhereInput;
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ClinicWidgetRequestScalarWhereInput;
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput = {
  where: ClinicWidgetRequestScalarWhereInput;
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: ClinicWidgetRequestScalarWhereInput;
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
};

export type ClinicWidgetRequestUpdateOneWithoutAutomationRunsInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  data: ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput;
};

export type ClinicWidgetRequestUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpdateWithoutClinicWidgetRequestTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestInput>;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: ClinicWidgetRequestWhereUniqueInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput;
};

export type ClinicWidgetRequestUpsertWithoutAutomationRunsInput = {
  update: ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput;
};

export type ClinicWidgetRequestUpsertWithoutChannelAutomationStatusesInput = {
  update: ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ClinicWidgetRequestUpsertWithoutChannelCreationSourcesInput = {
  update: ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ClinicWidgetRequestWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  requestFormSubmission?: Maybe<JsonNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableFilter>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
};

export type ClinicWidgetRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSetting = {
  __typename?: 'ClinicWidgetSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic: Array<Clinic>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  _count: ClinicWidgetSettingCountOutputType;
};

export type ClinicWidgetSettingClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type ClinicWidgetSettingClinicWidgetRequestTypesArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};

export type ClinicWidgetSettingCountAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isEnabled: Scalars['Int'];
  welcomeMessage: Scalars['Int'];
  primaryColor: Scalars['Int'];
  secondaryColor: Scalars['Int'];
  whiteLabelUrls: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicWidgetSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
};

export type ClinicWidgetSettingCountOutputType = {
  __typename?: 'ClinicWidgetSettingCountOutputType';
  clinic: Scalars['Int'];
  clinicWidgetRequestTypes: Scalars['Int'];
};

export type ClinicWidgetSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedManyWithoutClinicWidgetSettingInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingCreateNestedOneWithoutClinicInput = {
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
};

export type ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
};

export type ClinicWidgetSettingCreateOrConnectWithoutClinicInput = {
  where: ClinicWidgetSettingWhereUniqueInput;
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  where: ClinicWidgetSettingWhereUniqueInput;
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
};

export type ClinicWidgetSettingMinAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
};

export enum ClinicWidgetSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  WelcomeMessage = 'welcomeMessage',
  PrimaryColor = 'primaryColor',
  SecondaryColor = 'secondaryColor',
}

export type ClinicWidgetSettingOrderByRelevanceInput = {
  fields: Array<ClinicWidgetSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicWidgetSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
  _count?: Maybe<ClinicWidgetSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetSettingMinOrderByAggregateInput>;
};

export type ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByRelationAggregateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicWidgetSettingOrderByRelevanceInput>;
};

export type ClinicWidgetSettingRelationFilter = {
  is?: Maybe<ClinicWidgetSettingWhereInput>;
  isNot?: Maybe<ClinicWidgetSettingWhereInput>;
};

export enum ClinicWidgetSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsEnabled = 'isEnabled',
  WelcomeMessage = 'welcomeMessage',
  PrimaryColor = 'primaryColor',
  SecondaryColor = 'secondaryColor',
  WhiteLabelUrls = 'whiteLabelUrls',
}

export type ClinicWidgetSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  welcomeMessage?: Maybe<StringNullableWithAggregatesFilter>;
  primaryColor?: Maybe<StringNullableWithAggregatesFilter>;
  secondaryColor?: Maybe<StringNullableWithAggregatesFilter>;
  whiteLabelUrls?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicWidgetSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUncheckedUpdateManyWithoutClinicWidgetSettingInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUncheckedUpdateManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateManyWithoutClinicWidgetSettingInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUpdateOneWithoutClinicInput = {
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<ClinicWidgetSettingUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  update?: Maybe<ClinicWidgetSettingUncheckedUpdateWithoutClinicInput>;
};

export type ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  upsert?: Maybe<ClinicWidgetSettingUpsertWithoutClinicWidgetRequestTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  update?: Maybe<ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateManyWithoutClinicWidgetSettingInput>;
};

export type ClinicWidgetSettingUpsertWithoutClinicInput = {
  update: ClinicWidgetSettingUncheckedUpdateWithoutClinicInput;
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicInput;
};

export type ClinicWidgetSettingUpsertWithoutClinicWidgetRequestTypesInput = {
  update: ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetSettingWhereInput = {
  AND?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isEnabled?: Maybe<BoolNullableFilter>;
  welcomeMessage?: Maybe<StringNullableFilter>;
  primaryColor?: Maybe<StringNullableFilter>;
  secondaryColor?: Maybe<StringNullableFilter>;
  whiteLabelUrls?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicListRelationFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
};

export type ClinicWidgetSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowType = {
  __typename?: 'ClinicWorkflowType';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  appointmentType?: Maybe<AppointmentType>;
  clinic?: Maybe<Clinic>;
};

export type ClinicWorkflowTypeCountAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  defaultWorkflowType: Scalars['Int'];
  clinicId: Scalars['Int'];
  appoinmentTypeId: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ClinicWorkflowTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  name: Scalars['String'];
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeCreateManyAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope = {
  data: Array<ClinicWorkflowTypeCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWorkflowTypeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeCreateManyClinicInputEnvelope = {
  data: Array<ClinicWorkflowTypeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWorkflowTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
};

export type ClinicWorkflowTypeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
};

export type ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  create: ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput;
};

export type ClinicWorkflowTypeCreateOrConnectWithoutClinicInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  create: ClinicWorkflowTypeUncheckedCreateWithoutClinicInput;
};

export type ClinicWorkflowTypeCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  name: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  name: Scalars['String'];
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeListRelationFilter = {
  every?: Maybe<ClinicWorkflowTypeWhereInput>;
  some?: Maybe<ClinicWorkflowTypeWhereInput>;
  none?: Maybe<ClinicWorkflowTypeWhereInput>;
};

export type ClinicWorkflowTypeMaxAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeMinAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWorkflowTypeOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  AppoinmentTypeId = 'appoinmentTypeId',
  Name = 'name',
}

export type ClinicWorkflowTypeOrderByRelevanceInput = {
  fields: Array<ClinicWorkflowTypeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ClinicWorkflowTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<ClinicWorkflowTypeCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWorkflowTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWorkflowTypeMinOrderByAggregateInput>;
};

export type ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appoinmentTypeId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ClinicWorkflowTypeOrderByRelevanceInput>;
};

export enum ClinicWorkflowTypeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DefaultWorkflowType = 'defaultWorkflowType',
  ClinicId = 'clinicId',
  AppoinmentTypeId = 'appoinmentTypeId',
  Name = 'name',
}

export type ClinicWorkflowTypeScalarWhereInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
};

export type ClinicWorkflowTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  appoinmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicWorkflowTypeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
};

export type ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
};

export type ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  clinicId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClinicWorkflowTypeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  appoinmentTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  name?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutClinicWorkflowTypesInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput = {
  where: ClinicWorkflowTypeScalarWhereInput;
  data: ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput;
};

export type ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput = {
  where: ClinicWorkflowTypeScalarWhereInput;
  data: ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput;
};

export type ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
};

export type ClinicWorkflowTypeUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
};

export type ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  data: ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput;
};

export type ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  data: ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput;
};

export type ClinicWorkflowTypeUpdateWithoutAppointmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  name?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  name?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutClinicWorkflowTypesInput>;
};

export type ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  update: ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput;
  create: ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput;
};

export type ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput = {
  where: ClinicWorkflowTypeWhereUniqueInput;
  update: ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput;
  create: ClinicWorkflowTypeUncheckedCreateWithoutClinicInput;
};

export type ClinicWorkflowTypeWhereInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ClinicWorkflowTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConfigureEmailCampaignsInput = {
  clinicId: Scalars['String'];
  publicIpAddress: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Consultation = {
  __typename?: 'Consultation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  consultationType: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments: Array<Appointment>;
  chats: Array<ConsultationChat>;
  clinic?: Maybe<Clinic>;
  nurse?: Maybe<VetInfo>;
  pet?: Maybe<Pet>;
  petParent?: Maybe<OwnerInfo>;
  pics: Array<Image>;
  transactions: Array<Transaction>;
  vet?: Maybe<VetInfo>;
  _count: ConsultationCountOutputType;
};

/** @deprecated - 1.0 */
export type ConsultationAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

/** @deprecated - 1.0 */
export type ConsultationChatsArgs = {
  where?: Maybe<ConsultationChatWhereInput>;
  orderBy?: Maybe<ConsultationChatOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationChatWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationChatScalarFieldEnum>;
};

/** @deprecated - 1.0 */
export type ConsultationPicsArgs = {
  where?: Maybe<ImageWhereInput>;
  orderBy?: Maybe<ImageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ImageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ImageScalarFieldEnum>;
};

/** @deprecated - 1.0 */
export type ConsultationTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
};

export type ConsultationAvgAggregateOutputType = {
  __typename?: 'ConsultationAvgAggregateOutputType';
  cost?: Maybe<Scalars['Float']>;
  clientCost?: Maybe<Scalars['Float']>;
};

export type ConsultationAvgOrderByAggregateInput = {
  cost?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
};

/** @deprecated - 1.0 */
export type ConsultationChat = {
  __typename?: 'ConsultationChat';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<Consultation>;
  creator?: Maybe<User>;
};

export type ConsultationChatCountAggregateOutputType = {
  __typename?: 'ConsultationChatCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  text: Scalars['Int'];
  createdAtCopy: Scalars['Int'];
  creatorId: Scalars['Int'];
  consultationId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  image: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isRead: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConsultationChatCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
};

export type ConsultationChatCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutChatsInput>;
  creator?: Maybe<UserCreateNestedOneWithoutConsultationChatsInput>;
};

export type ConsultationChatCreateManyConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyConsultationInputEnvelope = {
  data: Array<ConsultationChatCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyCreatorInputEnvelope = {
  data: Array<ConsultationChatCreateManyCreatorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
};

export type ConsultationChatCreateNestedManyWithoutCreatorInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
};

export type ConsultationChatCreateOrConnectWithoutConsultationInput = {
  where: ConsultationChatWhereUniqueInput;
  create: ConsultationChatUncheckedCreateWithoutConsultationInput;
};

export type ConsultationChatCreateOrConnectWithoutCreatorInput = {
  where: ConsultationChatWhereUniqueInput;
  create: ConsultationChatUncheckedCreateWithoutCreatorInput;
};

export type ConsultationChatCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<UserCreateNestedOneWithoutConsultationChatsInput>;
};

export type ConsultationChatCreateWithoutCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutChatsInput>;
};

export type ConsultationChatListRelationFilter = {
  every?: Maybe<ConsultationChatWhereInput>;
  some?: Maybe<ConsultationChatWhereInput>;
  none?: Maybe<ConsultationChatWhereInput>;
};

export type ConsultationChatMaxAggregateOutputType = {
  __typename?: 'ConsultationChatMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
};

export type ConsultationChatMinAggregateOutputType = {
  __typename?: 'ConsultationChatMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
};

export type ConsultationChatOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationChatOrderByRelevanceFieldEnum {
  Id = 'id',
  Text = 'text',
  CreatorId = 'creatorId',
  ConsultationId = 'consultationId',
  FirebaseId = 'firebaseId',
  Image = 'image',
}

export type ConsultationChatOrderByRelevanceInput = {
  fields: Array<ConsultationChatOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConsultationChatOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  _count?: Maybe<ConsultationChatCountOrderByAggregateInput>;
  _max?: Maybe<ConsultationChatMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationChatMinOrderByAggregateInput>;
};

export type ConsultationChatOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  creator?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ConsultationChatOrderByRelevanceInput>;
};

export enum ConsultationChatScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Text = 'text',
  CreatedAtCopy = 'createdAtCopy',
  CreatorId = 'creatorId',
  ConsultationId = 'consultationId',
  FirebaseId = 'firebaseId',
  Image = 'image',
  UpdatedAt = 'updatedAt',
  IsRead = 'isRead',
}

export type ConsultationChatScalarWhereInput = {
  AND?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  text?: Maybe<StringFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  creatorId?: Maybe<StringNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  image?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isRead?: Maybe<BoolNullableFilter>;
};

export type ConsultationChatScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  text?: Maybe<StringWithAggregatesFilter>;
  createdAtCopy?: Maybe<DateTimeNullableWithAggregatesFilter>;
  creatorId?: Maybe<StringNullableWithAggregatesFilter>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  image?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isRead?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type ConsultationChatUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
};

export type ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
};

export type ConsultationChatUncheckedCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedCreateWithoutCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutConsultationInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
};

export type ConsultationChatUncheckedUpdateManyWithoutCreatorInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutCreatorInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
};

export type ConsultationChatUncheckedUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUncheckedUpdateWithoutCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  consultationId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<ConsultationUpdateOneWithoutChatsInput>;
  creator?: Maybe<UserUpdateOneWithoutConsultationChatsInput>;
};

export type ConsultationChatUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatUpdateManyWithWhereWithoutConsultationInput = {
  where: ConsultationChatScalarWhereInput;
  data: ConsultationChatUncheckedUpdateManyWithoutChatsInput;
};

export type ConsultationChatUpdateManyWithWhereWithoutCreatorInput = {
  where: ConsultationChatScalarWhereInput;
  data: ConsultationChatUncheckedUpdateManyWithoutConsultationChatsInput;
};

export type ConsultationChatUpdateManyWithoutConsultationInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
};

export type ConsultationChatUpdateManyWithoutCreatorInput = {
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutCreatorInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
};

export type ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput = {
  where: ConsultationChatWhereUniqueInput;
  data: ConsultationChatUncheckedUpdateWithoutConsultationInput;
};

export type ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput = {
  where: ConsultationChatWhereUniqueInput;
  data: ConsultationChatUncheckedUpdateWithoutCreatorInput;
};

export type ConsultationChatUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<UserUpdateOneWithoutConsultationChatsInput>;
};

export type ConsultationChatUpdateWithoutCreatorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isRead?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<ConsultationUpdateOneWithoutChatsInput>;
};

export type ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput = {
  where: ConsultationChatWhereUniqueInput;
  update: ConsultationChatUncheckedUpdateWithoutConsultationInput;
  create: ConsultationChatUncheckedCreateWithoutConsultationInput;
};

export type ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput = {
  where: ConsultationChatWhereUniqueInput;
  update: ConsultationChatUncheckedUpdateWithoutCreatorInput;
  create: ConsultationChatUncheckedCreateWithoutCreatorInput;
};

export type ConsultationChatWhereInput = {
  AND?: Maybe<Array<ConsultationChatWhereInput>>;
  OR?: Maybe<Array<ConsultationChatWhereInput>>;
  NOT?: Maybe<Array<ConsultationChatWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  text?: Maybe<StringFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  creatorId?: Maybe<StringNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  image?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isRead?: Maybe<BoolNullableFilter>;
  consultation?: Maybe<ConsultationWhereInput>;
  creator?: Maybe<UserWhereInput>;
};

export type ConsultationChatWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConsultationCountAggregateOutputType = {
  __typename?: 'ConsultationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  consultationType: Scalars['Int'];
  cost: Scalars['Int'];
  consultationState: Scalars['Int'];
  summary: Scalars['Int'];
  details: Scalars['Int'];
  treat: Scalars['Int'];
  callType: Scalars['Int'];
  lastMessageAt: Scalars['Int'];
  firebaseId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAtCopy: Scalars['Int'];
  openedAt: Scalars['Int'];
  resolvedAt: Scalars['Int'];
  petId: Scalars['Int'];
  transactionId: Scalars['Int'];
  petParentId: Scalars['Int'];
  veterinarianId: Scalars['Int'];
  scheduledAt: Scalars['Int'];
  cancelledAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  nurseId: Scalars['Int'];
  clientCost: Scalars['Int'];
  isPhoneAllowed: Scalars['Int'];
  isVideoAllowed: Scalars['Int'];
  isVetEmailed: Scalars['Int'];
  isVideoChat: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConsultationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
};

export type ConsultationCountOutputType = {
  __typename?: 'ConsultationCountOutputType';
  appointments: Scalars['Int'];
  chats: Scalars['Int'];
  pics: Scalars['Int'];
  transactions: Scalars['Int'];
};

export type ConsultationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyClinicInputEnvelope = {
  data: Array<ConsultationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyNurseInputEnvelope = {
  data: Array<ConsultationCreateManyNurseInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetInputEnvelope = {
  data: Array<ConsultationCreateManyPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetParentInputEnvelope = {
  data: Array<ConsultationCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyVetInputEnvelope = {
  data: Array<ConsultationCreateManyVetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutNurseInput = {
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutPetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutPicsInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedManyWithoutVetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
};

export type ConsultationCreateNestedOneWithoutChatsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutChatsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutChatsInput>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
};

export type ConsultationCreateNestedOneWithoutTransactionsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutTransactionsInput>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
};

export type ConsultationCreateOrConnectWithoutAppointmentsInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutAppointmentsInput;
};

export type ConsultationCreateOrConnectWithoutChatsInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutChatsInput;
};

export type ConsultationCreateOrConnectWithoutClinicInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutClinicInput;
};

export type ConsultationCreateOrConnectWithoutNurseInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutNurseInput;
};

export type ConsultationCreateOrConnectWithoutPetInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutPetInput;
};

export type ConsultationCreateOrConnectWithoutPetParentInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutPetParentInput;
};

export type ConsultationCreateOrConnectWithoutPicsInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutPicsInput;
};

export type ConsultationCreateOrConnectWithoutTransactionsInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutTransactionsInput;
};

export type ConsultationCreateOrConnectWithoutVetInput = {
  where: ConsultationWhereUniqueInput;
  create: ConsultationUncheckedCreateWithoutVetInput;
};

export type ConsultationCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationListRelationFilter = {
  every?: Maybe<ConsultationWhereInput>;
  some?: Maybe<ConsultationWhereInput>;
  none?: Maybe<ConsultationWhereInput>;
};

export type ConsultationMaxAggregateOutputType = {
  __typename?: 'ConsultationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
};

export type ConsultationMinAggregateOutputType = {
  __typename?: 'ConsultationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
};

export type ConsultationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationOrderByRelevanceFieldEnum {
  Id = 'id',
  ConsultationType = 'consultationType',
  ConsultationState = 'consultationState',
  Summary = 'summary',
  Details = 'details',
  Treat = 'treat',
  CallType = 'callType',
  FirebaseId = 'firebaseId',
  ClinicId = 'clinicId',
  PetId = 'petId',
  TransactionId = 'transactionId',
  PetParentId = 'petParentId',
  VeterinarianId = 'veterinarianId',
  NurseId = 'nurseId',
}

export type ConsultationOrderByRelevanceInput = {
  fields: Array<ConsultationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConsultationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  _count?: Maybe<ConsultationCountOrderByAggregateInput>;
  _avg?: Maybe<ConsultationAvgOrderByAggregateInput>;
  _max?: Maybe<ConsultationMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationMinOrderByAggregateInput>;
  _sum?: Maybe<ConsultationSumOrderByAggregateInput>;
};

export type ConsultationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  callType?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  chats?: Maybe<ConsultationChatOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  nurse?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  pet?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  petParent?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  pics?: Maybe<ImageOrderByRelationAggregateInput>;
  transactions?: Maybe<TransactionOrderByRelationAggregateInput>;
  vet?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ConsultationOrderByRelevanceInput>;
};

export type ConsultationRelationFilter = {
  is?: Maybe<ConsultationWhereInput>;
  isNot?: Maybe<ConsultationWhereInput>;
};

/** @deprecated - 1.0 */
export type ConsultationRequest = {
  __typename?: 'ConsultationRequest';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  clinic?: Maybe<Clinic>;
};

export type ConsultationRequestCountAggregateOutputType = {
  __typename?: 'ConsultationRequestCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  petParentEmail: Scalars['Int'];
  petParentFirstname: Scalars['Int'];
  petParentLastname: Scalars['Int'];
  petParentPhone: Scalars['Int'];
  petName: Scalars['Int'];
  petSpecies: Scalars['Int'];
  summary: Scalars['Int'];
  clinicId: Scalars['Int'];
  intent: Scalars['Int'];
  isExistingClient: Scalars['Int'];
  consultationFirebaseId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConsultationRequestCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ConsultationRequestCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationRequestsInput>;
};

export type ConsultationRequestCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestCreateManyClinicInputEnvelope = {
  data: Array<ConsultationRequestCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
};

export type ConsultationRequestCreateOrConnectWithoutClinicInput = {
  where: ConsultationRequestWhereUniqueInput;
  create: ConsultationRequestUncheckedCreateWithoutClinicInput;
};

export type ConsultationRequestCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestListRelationFilter = {
  every?: Maybe<ConsultationRequestWhereInput>;
  some?: Maybe<ConsultationRequestWhereInput>;
  none?: Maybe<ConsultationRequestWhereInput>;
};

export type ConsultationRequestMaxAggregateOutputType = {
  __typename?: 'ConsultationRequestMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ConsultationRequestMinAggregateOutputType = {
  __typename?: 'ConsultationRequestMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ConsultationRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationRequestOrderByRelevanceFieldEnum {
  Id = 'id',
  PetParentEmail = 'petParentEmail',
  PetParentFirstname = 'petParentFirstname',
  PetParentLastname = 'petParentLastname',
  PetParentPhone = 'petParentPhone',
  PetName = 'petName',
  PetSpecies = 'petSpecies',
  Summary = 'summary',
  ClinicId = 'clinicId',
  Intent = 'intent',
  ConsultationFirebaseId = 'consultationFirebaseId',
}

export type ConsultationRequestOrderByRelevanceInput = {
  fields: Array<ConsultationRequestOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ConsultationRequestOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<ConsultationRequestCountOrderByAggregateInput>;
  _max?: Maybe<ConsultationRequestMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationRequestMinOrderByAggregateInput>;
};

export type ConsultationRequestOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ConsultationRequestOrderByRelevanceInput>;
};

export enum ConsultationRequestScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PetParentEmail = 'petParentEmail',
  PetParentFirstname = 'petParentFirstname',
  PetParentLastname = 'petParentLastname',
  PetParentPhone = 'petParentPhone',
  PetName = 'petName',
  PetSpecies = 'petSpecies',
  Summary = 'summary',
  ClinicId = 'clinicId',
  Intent = 'intent',
  IsExistingClient = 'isExistingClient',
  ConsultationFirebaseId = 'consultationFirebaseId',
  IsDeleted = 'isDeleted',
}

export type ConsultationRequestScalarWhereInput = {
  AND?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  petParentEmail?: Maybe<StringNullableFilter>;
  petParentFirstname?: Maybe<StringFilter>;
  petParentLastname?: Maybe<StringFilter>;
  petParentPhone?: Maybe<StringFilter>;
  petName?: Maybe<StringFilter>;
  petSpecies?: Maybe<StringNullableFilter>;
  summary?: Maybe<StringFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  intent?: Maybe<StringFilter>;
  isExistingClient?: Maybe<BoolNullableFilter>;
  consultationFirebaseId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type ConsultationRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  petParentEmail?: Maybe<StringNullableWithAggregatesFilter>;
  petParentFirstname?: Maybe<StringWithAggregatesFilter>;
  petParentLastname?: Maybe<StringWithAggregatesFilter>;
  petParentPhone?: Maybe<StringWithAggregatesFilter>;
  petName?: Maybe<StringWithAggregatesFilter>;
  petSpecies?: Maybe<StringNullableWithAggregatesFilter>;
  summary?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  intent?: Maybe<StringWithAggregatesFilter>;
  isExistingClient?: Maybe<BoolNullableWithAggregatesFilter>;
  consultationFirebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type ConsultationRequestUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
};

export type ConsultationRequestUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petName: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  intent: Scalars['String'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationRequestUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
};

export type ConsultationRequestUncheckedUpdateManyWithoutConsultationRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationRequestsInput>;
};

export type ConsultationRequestUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUpdateManyWithWhereWithoutClinicInput = {
  where: ConsultationRequestScalarWhereInput;
  data: ConsultationRequestUncheckedUpdateManyWithoutConsultationRequestsInput;
};

export type ConsultationRequestUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationRequestUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
};

export type ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput = {
  where: ConsultationRequestWhereUniqueInput;
  data: ConsultationRequestUncheckedUpdateWithoutClinicInput;
};

export type ConsultationRequestUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput = {
  where: ConsultationRequestWhereUniqueInput;
  update: ConsultationRequestUncheckedUpdateWithoutClinicInput;
  create: ConsultationRequestUncheckedCreateWithoutClinicInput;
};

export type ConsultationRequestWhereInput = {
  AND?: Maybe<Array<ConsultationRequestWhereInput>>;
  OR?: Maybe<Array<ConsultationRequestWhereInput>>;
  NOT?: Maybe<Array<ConsultationRequestWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  petParentEmail?: Maybe<StringNullableFilter>;
  petParentFirstname?: Maybe<StringFilter>;
  petParentLastname?: Maybe<StringFilter>;
  petParentPhone?: Maybe<StringFilter>;
  petName?: Maybe<StringFilter>;
  petSpecies?: Maybe<StringNullableFilter>;
  summary?: Maybe<StringFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  intent?: Maybe<StringFilter>;
  isExistingClient?: Maybe<BoolNullableFilter>;
  consultationFirebaseId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type ConsultationRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ConsultationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  ConsultationType = 'consultationType',
  Cost = 'cost',
  ConsultationState = 'consultationState',
  Summary = 'summary',
  Details = 'details',
  Treat = 'treat',
  CallType = 'callType',
  LastMessageAt = 'lastMessageAt',
  FirebaseId = 'firebaseId',
  ClinicId = 'clinicId',
  CreatedAtCopy = 'createdAtCopy',
  OpenedAt = 'openedAt',
  ResolvedAt = 'resolvedAt',
  PetId = 'petId',
  TransactionId = 'transactionId',
  PetParentId = 'petParentId',
  VeterinarianId = 'veterinarianId',
  ScheduledAt = 'scheduledAt',
  CancelledAt = 'cancelledAt',
  UpdatedAt = 'updatedAt',
  NurseId = 'nurseId',
  ClientCost = 'clientCost',
  IsPhoneAllowed = 'isPhoneAllowed',
  IsVideoAllowed = 'isVideoAllowed',
  IsVetEmailed = 'isVetEmailed',
  IsVideoChat = 'isVideoChat',
}

export type ConsultationScalarWhereInput = {
  AND?: Maybe<Array<ConsultationScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  consultationType?: Maybe<StringFilter>;
  cost?: Maybe<FloatNullableFilter>;
  consultationState?: Maybe<StringFilter>;
  summary?: Maybe<StringNullableFilter>;
  details?: Maybe<StringNullableFilter>;
  treat?: Maybe<StringNullableFilter>;
  callType?: Maybe<StringNullableFilter>;
  lastMessageAt?: Maybe<DateTimeNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  openedAt?: Maybe<DateTimeNullableFilter>;
  resolvedAt?: Maybe<DateTimeNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  transactionId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  veterinarianId?: Maybe<StringNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  nurseId?: Maybe<StringNullableFilter>;
  clientCost?: Maybe<FloatNullableFilter>;
  isPhoneAllowed?: Maybe<BoolNullableFilter>;
  isVideoAllowed?: Maybe<BoolNullableFilter>;
  isVetEmailed?: Maybe<BoolNullableFilter>;
  isVideoChat?: Maybe<BoolNullableFilter>;
};

export type ConsultationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  consultationType?: Maybe<StringWithAggregatesFilter>;
  cost?: Maybe<FloatNullableWithAggregatesFilter>;
  consultationState?: Maybe<StringWithAggregatesFilter>;
  summary?: Maybe<StringNullableWithAggregatesFilter>;
  details?: Maybe<StringNullableWithAggregatesFilter>;
  treat?: Maybe<StringNullableWithAggregatesFilter>;
  callType?: Maybe<StringNullableWithAggregatesFilter>;
  lastMessageAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAtCopy?: Maybe<DateTimeNullableWithAggregatesFilter>;
  openedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  resolvedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  petId?: Maybe<StringNullableWithAggregatesFilter>;
  transactionId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  veterinarianId?: Maybe<StringNullableWithAggregatesFilter>;
  scheduledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  cancelledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  nurseId?: Maybe<StringNullableWithAggregatesFilter>;
  clientCost?: Maybe<FloatNullableWithAggregatesFilter>;
  isPhoneAllowed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVideoAllowed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVetEmailed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVideoChat?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type ConsultationSumAggregateOutputType = {
  __typename?: 'ConsultationSumAggregateOutputType';
  cost?: Maybe<Scalars['Float']>;
  clientCost?: Maybe<Scalars['Float']>;
};

export type ConsultationSumOrderByAggregateInput = {
  cost?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
};

export type ConsultationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutNurseInput = {
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPicsInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutVetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
};

export type ConsultationUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedCreateWithoutVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUncheckedUpdateManyWithoutNurseInput = {
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutNurseInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutNurseInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPetParentInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPicsInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPicsInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPicsInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPicsInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutVetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutVetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutVetInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateWithoutNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  veterinarianId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUncheckedUpdateWithoutVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationInput>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationInput>;
};

export type ConsultationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
};

export type ConsultationUpdateManyWithWhereWithoutClinicInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
};

export type ConsultationUpdateManyWithWhereWithoutNurseInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsIdInput;
};

export type ConsultationUpdateManyWithWhereWithoutPetInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
};

export type ConsultationUpdateManyWithWhereWithoutPetParentInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
};

export type ConsultationUpdateManyWithWhereWithoutPicsInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationInput;
};

export type ConsultationUpdateManyWithWhereWithoutVetInput = {
  where: ConsultationScalarWhereInput;
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
};

export type ConsultationUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutNurseInput = {
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutNurseInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutNurseInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutPetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutPetParentInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutPicsInput = {
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPicsInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPicsInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPicsInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateManyWithoutVetInput = {
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutVetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutVetInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
};

export type ConsultationUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutAppointmentsInput>;
};

export type ConsultationUpdateOneWithoutChatsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutChatsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutChatsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutChatsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutChatsInput>;
};

export type ConsultationUpdateOneWithoutTransactionsInput = {
  create?: Maybe<ConsultationUncheckedCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutTransactionsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ConsultationWhereUniqueInput>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutTransactionsInput>;
};

export type ConsultationUpdateWithWhereUniqueWithoutClinicInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutClinicInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutNurseInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutNurseInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPetInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutPetInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPetParentInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutPetParentInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPicsInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutPicsInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutVetInput = {
  where: ConsultationWhereUniqueInput;
  data: ConsultationUncheckedUpdateWithoutVetInput;
};

export type ConsultationUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateWithoutChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateWithoutNurseInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateWithoutPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsInput>;
};

export type ConsultationUpdateWithoutVetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  callType?: Maybe<Scalars['String']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationInput>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsInput>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdInput>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationInput>;
};

export type ConsultationUpsertWithWhereUniqueWithoutClinicInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutClinicInput;
  create: ConsultationUncheckedCreateWithoutClinicInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutNurseInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutNurseInput;
  create: ConsultationUncheckedCreateWithoutNurseInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPetInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutPetInput;
  create: ConsultationUncheckedCreateWithoutPetInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPetParentInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutPetParentInput;
  create: ConsultationUncheckedCreateWithoutPetParentInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPicsInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutPicsInput;
  create: ConsultationUncheckedCreateWithoutPicsInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutVetInput = {
  where: ConsultationWhereUniqueInput;
  update: ConsultationUncheckedUpdateWithoutVetInput;
  create: ConsultationUncheckedCreateWithoutVetInput;
};

export type ConsultationUpsertWithoutAppointmentsInput = {
  update: ConsultationUncheckedUpdateWithoutAppointmentsInput;
  create: ConsultationUncheckedCreateWithoutAppointmentsInput;
};

export type ConsultationUpsertWithoutChatsInput = {
  update: ConsultationUncheckedUpdateWithoutChatsInput;
  create: ConsultationUncheckedCreateWithoutChatsInput;
};

export type ConsultationUpsertWithoutTransactionsInput = {
  update: ConsultationUncheckedUpdateWithoutTransactionsInput;
  create: ConsultationUncheckedCreateWithoutTransactionsInput;
};

export type ConsultationWhereInput = {
  AND?: Maybe<Array<ConsultationWhereInput>>;
  OR?: Maybe<Array<ConsultationWhereInput>>;
  NOT?: Maybe<Array<ConsultationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  consultationType?: Maybe<StringFilter>;
  cost?: Maybe<FloatNullableFilter>;
  consultationState?: Maybe<StringFilter>;
  summary?: Maybe<StringNullableFilter>;
  details?: Maybe<StringNullableFilter>;
  treat?: Maybe<StringNullableFilter>;
  callType?: Maybe<StringNullableFilter>;
  lastMessageAt?: Maybe<DateTimeNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  openedAt?: Maybe<DateTimeNullableFilter>;
  resolvedAt?: Maybe<DateTimeNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  transactionId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  veterinarianId?: Maybe<StringNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  nurseId?: Maybe<StringNullableFilter>;
  clientCost?: Maybe<FloatNullableFilter>;
  isPhoneAllowed?: Maybe<BoolNullableFilter>;
  isVideoAllowed?: Maybe<BoolNullableFilter>;
  isVetEmailed?: Maybe<BoolNullableFilter>;
  isVideoChat?: Maybe<BoolNullableFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  chats?: Maybe<ConsultationChatListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  nurse?: Maybe<VetInfoWhereInput>;
  pet?: Maybe<PetWhereInput>;
  petParent?: Maybe<OwnerInfoWhereInput>;
  pics?: Maybe<ImageListRelationFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
  vet?: Maybe<VetInfoWhereInput>;
};

export type ConsultationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export enum ContactEntityType {
  ClinicPetParent = 'ClinicPetParent',
  User = 'User',
}

export enum ContactVerificationMethod {
  Email = 'Email',
  Text = 'Text',
}

export type CreateCareSubscriptionInput = {
  petEnrollments: Array<OrganizationPetEnrollmentInput>;
};

export type CreateCareSubscriptionResult = {
  __typename?: 'CreateCareSubscriptionResult';
  success: Scalars['Boolean'];
  careSubscriptionId?: Maybe<Scalars['String']>;
  paymentIntentSecret?: Maybe<Scalars['String']>;
};

export type CreateChannelFilterSelectionInput = {
  name: Scalars['String'];
  filters: Scalars['Json'];
  filterSelectionType: FilterSelectionType;
};

export type CreatePaymentMethodSetupInputResult = {
  __typename?: 'CreatePaymentMethodSetupInputResult';
  setupIntentSecret: Scalars['String'];
};

export type CreateTeamChannelInput = {
  friendlyName: Scalars['String'];
  assigneeIds: Array<Scalars['ID']>;
  channelVisibility?: Maybe<ChannelVisibility>;
};

export type CreateTwilioTokenInput = {
  identity: Scalars['String'];
};

export type CreateTwilioTokenResponse = {
  __typename?: 'CreateTwilioTokenResponse';
  token: Scalars['String'];
};

export enum Currency {
  Usd = 'usd',
}

export type DataPointIntegration = {
  __typename?: 'DataPointIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation: Scalars['Boolean'];
};

export type DataPointIntegrationCountAggregateOutputType = {
  __typename?: 'DataPointIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
  password: Scalars['Int'];
  siteId: Scalars['Int'];
  fixAppointmentTime: Scalars['Int'];
  isMultiLocation: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DataPointIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
};

export type DataPointIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutDatapointInput>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<DataPointIntegrationWhereUniqueInput>;
};

export type DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: DataPointIntegrationWhereUniqueInput;
  create: DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type DataPointIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationMaxAggregateOutputType = {
  __typename?: 'DataPointIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
};

export type DataPointIntegrationMinAggregateOutputType = {
  __typename?: 'DataPointIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
};

export enum DataPointIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  Username = 'username',
  Password = 'password',
  SiteId = 'siteId',
}

export type DataPointIntegrationOrderByRelevanceInput = {
  fields: Array<DataPointIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type DataPointIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  _count?: Maybe<DataPointIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<DataPointIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<DataPointIntegrationMinOrderByAggregateInput>;
};

export type DataPointIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  fixAppointmentTime?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  _relevance?: Maybe<DataPointIntegrationOrderByRelevanceInput>;
};

export type DataPointIntegrationRelationFilter = {
  is?: Maybe<DataPointIntegrationWhereInput>;
  isNot?: Maybe<DataPointIntegrationWhereInput>;
};

export enum DataPointIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Username = 'username',
  Password = 'password',
  SiteId = 'siteId',
  FixAppointmentTime = 'fixAppointmentTime',
  IsMultiLocation = 'isMultiLocation',
}

export type DataPointIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  username?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  siteId?: Maybe<StringWithAggregatesFilter>;
  fixAppointmentTime?: Maybe<BoolNullableWithAggregatesFilter>;
  isMultiLocation?: Maybe<BoolWithAggregatesFilter>;
};

export type DataPointIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutDatapointInput>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutDatapointInput>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutDatapointInput>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<DataPointIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DataPointIntegrationWhereUniqueInput>;
  update?: Maybe<DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type DataPointIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
};

export type DataPointIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type DataPointIntegrationWhereInput = {
  AND?: Maybe<Array<DataPointIntegrationWhereInput>>;
  OR?: Maybe<Array<DataPointIntegrationWhereInput>>;
  NOT?: Maybe<Array<DataPointIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  siteId?: Maybe<StringFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  fixAppointmentTime?: Maybe<BoolNullableFilter>;
  isMultiLocation?: Maybe<BoolFilter>;
};

export type DataPointIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type DirectBookingAppointmentResponse = {
  __typename?: 'DirectBookingAppointmentResponse';
  title?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
};

export type DirectBookingAppointmentTypeInput = {
  clinicId: Scalars['String'];
};

export type DirectBookingAppointmentTypes = {
  __typename?: 'DirectBookingAppointmentTypes';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  clinicEmployeeAppointmentTypeSettings: Array<AppointmentTypeClinicEmployeeMapping>;
};

export type DirectBookingAvailability = {
  __typename?: 'DirectBookingAvailability';
  bookingDate?: Maybe<Scalars['DateTime']>;
  hasAvailability?: Maybe<Scalars['Boolean']>;
  openTimeSlots?: Maybe<Scalars['Json']>;
};

export type DirectBookingOpenTimeSlotInput = {
  clinicId: Scalars['String'];
  clinicEmployeeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type DirectBookingOpenTimeSlotResponse = {
  __typename?: 'DirectBookingOpenTimeSlotResponse';
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicEmployeeFirstName?: Maybe<Scalars['String']>;
  clinicEmployeeLastName?: Maybe<Scalars['String']>;
  availability?: Maybe<Array<DirectBookingAvailability>>;
};

export type DirectedAuthLinkInput = {
  clinicPetParentId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  organizationPlanId?: Maybe<Scalars['String']>;
};

export type DirectedAuthLinkResult = {
  __typename?: 'DirectedAuthLinkResult';
  success: Scalars['Boolean'];
};

/** @deprecated - 1.0 */
export type Disbursement = {
  __typename?: 'Disbursement';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
  transactions: Array<Transaction>;
  _count: DisbursementCountOutputType;
};

/** @deprecated - 1.0 */
export type DisbursementTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
};

export type DisbursementAvgAggregateOutputType = {
  __typename?: 'DisbursementAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type DisbursementAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type DisbursementCountAggregateOutputType = {
  __typename?: 'DisbursementCountAggregateOutputType';
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  amount: Scalars['Int'];
  disbursementId: Scalars['Int'];
  disbursementDate: Scalars['Int'];
  source: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DisbursementCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type DisbursementCountOutputType = {
  __typename?: 'DisbursementCountOutputType';
  transactions: Scalars['Int'];
};

export type DisbursementCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutDisbursementInput>;
};

export type DisbursementCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementCreateNestedOneWithoutTransactionsInput = {
  create?: Maybe<DisbursementUncheckedCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<DisbursementCreateOrConnectWithoutTransactionsInput>;
  connect?: Maybe<DisbursementWhereUniqueInput>;
};

export type DisbursementCreateOrConnectWithoutTransactionsInput = {
  where: DisbursementWhereUniqueInput;
  create: DisbursementUncheckedCreateWithoutTransactionsInput;
};

export type DisbursementCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementMaxAggregateOutputType = {
  __typename?: 'DisbursementMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type DisbursementMinAggregateOutputType = {
  __typename?: 'DisbursementMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export enum DisbursementOrderByRelevanceFieldEnum {
  Id = 'id',
  DisbursementId = 'disbursementId',
  Source = 'source',
}

export type DisbursementOrderByRelevanceInput = {
  fields: Array<DisbursementOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type DisbursementOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  _count?: Maybe<DisbursementCountOrderByAggregateInput>;
  _avg?: Maybe<DisbursementAvgOrderByAggregateInput>;
  _max?: Maybe<DisbursementMaxOrderByAggregateInput>;
  _min?: Maybe<DisbursementMinOrderByAggregateInput>;
  _sum?: Maybe<DisbursementSumOrderByAggregateInput>;
};

export type DisbursementOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  transactions?: Maybe<TransactionOrderByRelationAggregateInput>;
  _relevance?: Maybe<DisbursementOrderByRelevanceInput>;
};

export type DisbursementRelationFilter = {
  is?: Maybe<DisbursementWhereInput>;
  isNot?: Maybe<DisbursementWhereInput>;
};

export enum DisbursementScalarFieldEnum {
  Id = 'id',
  UpdatedAt = 'updatedAt',
  Amount = 'amount',
  DisbursementId = 'disbursementId',
  DisbursementDate = 'disbursementDate',
  Source = 'source',
}

export type DisbursementScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  disbursementId?: Maybe<StringWithAggregatesFilter>;
  disbursementDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  source?: Maybe<StringWithAggregatesFilter>;
};

export type DisbursementSumAggregateOutputType = {
  __typename?: 'DisbursementSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type DisbursementSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type DisbursementUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutDisbursementInput>;
};

export type DisbursementUncheckedCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  disbursementId: Scalars['String'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutDisbursementInput>;
};

export type DisbursementUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUncheckedUpdateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUpdateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutDisbursementInput>;
};

export type DisbursementUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUpdateOneWithoutTransactionsInput = {
  create?: Maybe<DisbursementUncheckedCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<DisbursementCreateOrConnectWithoutTransactionsInput>;
  upsert?: Maybe<DisbursementUpsertWithoutTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DisbursementWhereUniqueInput>;
  update?: Maybe<DisbursementUncheckedUpdateWithoutTransactionsInput>;
};

export type DisbursementUpdateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type DisbursementUpsertWithoutTransactionsInput = {
  update: DisbursementUncheckedUpdateWithoutTransactionsInput;
  create: DisbursementUncheckedCreateWithoutTransactionsInput;
};

export type DisbursementWhereInput = {
  AND?: Maybe<Array<DisbursementWhereInput>>;
  OR?: Maybe<Array<DisbursementWhereInput>>;
  NOT?: Maybe<Array<DisbursementWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  amount?: Maybe<FloatFilter>;
  disbursementId?: Maybe<StringFilter>;
  disbursementDate?: Maybe<DateTimeNullableFilter>;
  source?: Maybe<StringFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
};

export type DisbursementWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
};

export enum EmailProvider {
  Sendgrid = 'Sendgrid',
}

export type EmailProviderLog = {
  __typename?: 'EmailProviderLog';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  provider: EmailProvider;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogCountAggregateOutputType = {
  __typename?: 'EmailProviderLogCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  provider: Scalars['Int'];
  providerEmailTemplateId: Scalars['Int'];
  providerMessageId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  entityType: Scalars['Int'];
  entityId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmailProviderLogCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type EmailProviderLogCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailProviderLogsInput;
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailProviderLogCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailProviderLogCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
};

export type EmailProviderLogCreateOrConnectWithoutClinicPetParentInput = {
  where: EmailProviderLogWhereUniqueInput;
  create: EmailProviderLogUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailProviderLogCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export enum EmailProviderLogEntityType {
  Appointment = 'Appointment',
  Channel = 'Channel',
  ChannelMessage = 'ChannelMessage',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  ServiceReminderNotification = 'ServiceReminderNotification',
  StripeRefund = 'StripeRefund',
}

export type EmailProviderLogListRelationFilter = {
  every?: Maybe<EmailProviderLogWhereInput>;
  some?: Maybe<EmailProviderLogWhereInput>;
  none?: Maybe<EmailProviderLogWhereInput>;
};

export type EmailProviderLogMaxAggregateOutputType = {
  __typename?: 'EmailProviderLogMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type EmailProviderLogMinAggregateOutputType = {
  __typename?: 'EmailProviderLogMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type EmailProviderLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailProviderLogOrderByRelevanceFieldEnum {
  Id = 'id',
  ProviderEmailTemplateId = 'providerEmailTemplateId',
  ProviderMessageId = 'providerMessageId',
  ClinicPetParentId = 'clinicPetParentId',
  EntityId = 'entityId',
}

export type EmailProviderLogOrderByRelevanceInput = {
  fields: Array<EmailProviderLogOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmailProviderLogOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  _count?: Maybe<EmailProviderLogCountOrderByAggregateInput>;
  _max?: Maybe<EmailProviderLogMaxOrderByAggregateInput>;
  _min?: Maybe<EmailProviderLogMinOrderByAggregateInput>;
};

export type EmailProviderLogOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  _relevance?: Maybe<EmailProviderLogOrderByRelevanceInput>;
};

export enum EmailProviderLogScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Provider = 'provider',
  ProviderEmailTemplateId = 'providerEmailTemplateId',
  ProviderMessageId = 'providerMessageId',
  ClinicPetParentId = 'clinicPetParentId',
  EntityType = 'entityType',
  EntityId = 'entityId',
}

export type EmailProviderLogScalarWhereInput = {
  AND?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  OR?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  NOT?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  providerEmailTemplateId?: Maybe<StringFilter>;
  providerMessageId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeFilter>;
  entityId?: Maybe<StringFilter>;
};

export type EmailProviderLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  provider?: Maybe<EnumEmailProviderWithAggregatesFilter>;
  providerEmailTemplateId?: Maybe<StringWithAggregatesFilter>;
  providerMessageId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  entityId?: Maybe<StringWithAggregatesFilter>;
};

export type EmailProviderLogUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
};

export type EmailProviderLogUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  entityId: Scalars['String'];
};

export type EmailProviderLogUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
};

export type EmailProviderLogUncheckedUpdateManyWithoutEmailProviderLogsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailProviderLogsInput>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: EmailProviderLogScalarWhereInput;
  data: EmailProviderLogUncheckedUpdateManyWithoutEmailProviderLogsInput;
};

export type EmailProviderLogUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  delete?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
};

export type EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailProviderLogWhereUniqueInput;
  data: EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput;
};

export type EmailProviderLogUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: EmailProviderLogWhereUniqueInput;
  update: EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput;
  create: EmailProviderLogUncheckedCreateWithoutClinicPetParentInput;
};

export type EmailProviderLogWhereInput = {
  AND?: Maybe<Array<EmailProviderLogWhereInput>>;
  OR?: Maybe<Array<EmailProviderLogWhereInput>>;
  NOT?: Maybe<Array<EmailProviderLogWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  providerEmailTemplateId?: Maybe<StringFilter>;
  providerMessageId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeFilter>;
  entityId?: Maybe<StringFilter>;
};

export type EmailProviderLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
};

export type EmergencyClinicContactInfo = {
  __typename?: 'EmergencyClinicContactInfo';
  name?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['String']>;
};

export type EmergencyClinics = {
  __typename?: 'EmergencyClinics';
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  clinicSettingId: Scalars['String'];
  clinicSetting: ClinicSetting;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type EmergencyClinicsAvgAggregateOutputType = {
  __typename?: 'EmergencyClinicsAvgAggregateOutputType';
  displayOrder?: Maybe<Scalars['Float']>;
};

export type EmergencyClinicsAvgOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type EmergencyClinicsClinicSettingIdDisplayOrderCompoundUniqueInput = {
  clinicSettingId: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export type EmergencyClinicsCountAggregateOutputType = {
  __typename?: 'EmergencyClinicsCountAggregateOutputType';
  id: Scalars['Int'];
  name: Scalars['Int'];
  phone: Scalars['Int'];
  city: Scalars['Int'];
  state: Scalars['Int'];
  addressLine1: Scalars['Int'];
  addressLine2: Scalars['Int'];
  postalCode: Scalars['Int'];
  fullAddress: Scalars['Int'];
  displayOrder: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmergencyClinicsCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type EmergencyClinicsCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  clinicSetting: ClinicSettingCreateNestedOneWithoutEmergencyClinicsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsCreateManyClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsCreateManyClinicSettingInputEnvelope = {
  data: Array<EmergencyClinicsCreateManyClinicSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
};

export type EmergencyClinicsCreateOrConnectWithoutClinicSettingInput = {
  where: EmergencyClinicsWhereUniqueInput;
  create: EmergencyClinicsUncheckedCreateWithoutClinicSettingInput;
};

export type EmergencyClinicsCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsListRelationFilter = {
  every?: Maybe<EmergencyClinicsWhereInput>;
  some?: Maybe<EmergencyClinicsWhereInput>;
  none?: Maybe<EmergencyClinicsWhereInput>;
};

export type EmergencyClinicsMaxAggregateOutputType = {
  __typename?: 'EmergencyClinicsMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type EmergencyClinicsMinAggregateOutputType = {
  __typename?: 'EmergencyClinicsMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type EmergencyClinicsOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmergencyClinicsOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Phone = 'phone',
  City = 'city',
  State = 'state',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  PostalCode = 'postalCode',
  FullAddress = 'fullAddress',
  ClinicSettingId = 'clinicSettingId',
}

export type EmergencyClinicsOrderByRelevanceInput = {
  fields: Array<EmergencyClinicsOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EmergencyClinicsOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<EmergencyClinicsCountOrderByAggregateInput>;
  _avg?: Maybe<EmergencyClinicsAvgOrderByAggregateInput>;
  _max?: Maybe<EmergencyClinicsMaxOrderByAggregateInput>;
  _min?: Maybe<EmergencyClinicsMinOrderByAggregateInput>;
  _sum?: Maybe<EmergencyClinicsSumOrderByAggregateInput>;
};

export type EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _relevance?: Maybe<EmergencyClinicsOrderByRelevanceInput>;
};

export enum EmergencyClinicsScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Phone = 'phone',
  City = 'city',
  State = 'state',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  PostalCode = 'postalCode',
  FullAddress = 'fullAddress',
  DisplayOrder = 'displayOrder',
  ClinicSettingId = 'clinicSettingId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
}

export type EmergencyClinicsScalarWhereInput = {
  AND?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  OR?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  NOT?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  displayOrder?: Maybe<IntFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type EmergencyClinicsScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  phone?: Maybe<StringWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  state?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine1?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine2?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  fullAddress?: Maybe<StringNullableWithAggregatesFilter>;
  displayOrder?: Maybe<IntWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type EmergencyClinicsSumAggregateOutputType = {
  __typename?: 'EmergencyClinicsSumAggregateOutputType';
  displayOrder?: Maybe<Scalars['Int']>;
};

export type EmergencyClinicsSumOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type EmergencyClinicsUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput = {
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
};

export type EmergencyClinicsUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingInput = {
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  delete?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  update?: Maybe<Array<EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
};

export type EmergencyClinicsUncheckedUpdateManyWithoutEmergencyClinicsInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutEmergencyClinicsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput = {
  where: EmergencyClinicsScalarWhereInput;
  data: EmergencyClinicsUncheckedUpdateManyWithoutEmergencyClinicsInput;
};

export type EmergencyClinicsUpdateManyWithoutClinicSettingInput = {
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  set?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  disconnect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  delete?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  update?: Maybe<Array<EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput>>;
  deleteMany?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
};

export type EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput = {
  where: EmergencyClinicsWhereUniqueInput;
  data: EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput;
};

export type EmergencyClinicsUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput = {
  where: EmergencyClinicsWhereUniqueInput;
  update: EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput;
  create: EmergencyClinicsUncheckedCreateWithoutClinicSettingInput;
};

export type EmergencyClinicsWhereInput = {
  AND?: Maybe<Array<EmergencyClinicsWhereInput>>;
  OR?: Maybe<Array<EmergencyClinicsWhereInput>>;
  NOT?: Maybe<Array<EmergencyClinicsWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  displayOrder?: Maybe<IntFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type EmergencyClinicsWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicSettingId_displayOrder?: Maybe<EmergencyClinicsClinicSettingIdDisplayOrderCompoundUniqueInput>;
};

export type EnhancedStripeRefundPaymentInput = {
  amount: Scalars['Int'];
  reason: StripeRefundReason;
  reasonOther?: Maybe<Scalars['String']>;
  issuerInitials?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export enum EnrollmentStatus {
  Pending = 'Pending',
  Active = 'Active',
  PaymentFailed = 'PaymentFailed',
  Cancelling = 'Cancelling',
  Canceled = 'Canceled',
  Expired = 'Expired',
  NeverStarted = 'NeverStarted',
}

export type EnsurePetsPromotedResult = {
  __typename?: 'EnsurePetsPromotedResult';
  success: Scalars['Boolean'];
};

export type EnumAppointmentDateRangeTypeFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentDateRangeType>;
};

export type EnumAppointmentDateRangeTypeFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
};

export type EnumAppointmentDateRangeTypeWithAggregatesFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  _max?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
};

export type EnumAppointmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentStatus>;
};

export type EnumAppointmentStatusFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  notIn?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusFilter>;
};

export type EnumAppointmentStatusWithAggregatesFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  notIn?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentStatusFilter>;
  _max?: Maybe<NestedEnumAppointmentStatusFilter>;
};

export type EnumAppointmentTypeVisibilityFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentTypeVisibility>;
};

export type EnumAppointmentTypeVisibilityFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
};

export type EnumAppointmentTypeVisibilityWithAggregatesFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  _max?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
};

export type EnumAutomatedTriggerTypeNullableFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
};

export type EnumAutomatedTriggerTypeNullableWithAggregatesFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  _max?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
};

export type EnumAutomationRunActionPromptTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunActionPromptType>;
};

export type EnumAutomationRunActionPromptTypeFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
};

export type EnumAutomationRunActionPromptTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
};

export type EnumAutomationRunActionTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunActionType>;
};

export type EnumAutomationRunActionTypeFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
};

export type EnumAutomationRunActionTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
};

export type EnumAutomationRunStatusFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunStatus>;
};

export type EnumAutomationRunStatusFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusFilter>;
};

export type EnumAutomationRunStatusWithAggregatesFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunStatusFilter>;
  _max?: Maybe<NestedEnumAutomationRunStatusFilter>;
};

export type EnumAutomationRunTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunType>;
};

export type EnumAutomationRunTypeFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  notIn?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeFilter>;
};

export type EnumAutomationRunTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  notIn?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunTypeFilter>;
};

export type EnumAutomationStatusFieldUpdateOperationsInput = {
  set?: Maybe<AutomationStatus>;
};

export type EnumAutomationStatusFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  notIn?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusFilter>;
};

export type EnumAutomationStatusWithAggregatesFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  notIn?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationStatusFilter>;
  _max?: Maybe<NestedEnumAutomationStatusFilter>;
};

export type EnumBitwerxSystemFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxSystem>;
};

export type EnumBitwerxSystemFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  notIn?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemFilter>;
};

export type EnumBitwerxSystemWithAggregatesFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  notIn?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBitwerxSystemFilter>;
  _max?: Maybe<NestedEnumBitwerxSystemFilter>;
};

export type EnumCallHistoryTypeFieldUpdateOperationsInput = {
  set?: Maybe<CallHistoryType>;
};

export type EnumCallHistoryTypeFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  notIn?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeFilter>;
};

export type EnumCallHistoryTypeWithAggregatesFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  notIn?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallHistoryTypeFilter>;
  _max?: Maybe<NestedEnumCallHistoryTypeFilter>;
};

export type EnumCallProviderFieldUpdateOperationsInput = {
  set?: Maybe<CallProvider>;
};

export type EnumCallProviderFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  notIn?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderFilter>;
};

export type EnumCallProviderWithAggregatesFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  notIn?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallProviderFilter>;
  _max?: Maybe<NestedEnumCallProviderFilter>;
};

export type EnumCallStatusFieldUpdateOperationsInput = {
  set?: Maybe<CallStatus>;
};

export type EnumCallStatusFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  notIn?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusFilter>;
};

export type EnumCallStatusWithAggregatesFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  notIn?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallStatusFilter>;
  _max?: Maybe<NestedEnumCallStatusFilter>;
};

export type EnumCardBrandFieldUpdateOperationsInput = {
  set?: Maybe<CardBrand>;
};

export type EnumCardBrandFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  notIn?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandFilter>;
};

export type EnumCardBrandWithAggregatesFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  notIn?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCardBrandFilter>;
  _max?: Maybe<NestedEnumCardBrandFilter>;
};

export type EnumCardFundingNullableFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  notIn?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableFilter>;
};

export type EnumCardFundingNullableWithAggregatesFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  notIn?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCardFundingNullableFilter>;
  _max?: Maybe<NestedEnumCardFundingNullableFilter>;
};

export type EnumCareBenefitUnitOfMeasureNullableFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type EnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  _max?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type EnumCarePlanCycleTypeFieldUpdateOperationsInput = {
  set?: Maybe<CarePlanCycleType>;
};

export type EnumCarePlanCycleTypeFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
};

export type EnumCarePlanCycleTypeWithAggregatesFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  _max?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
};

export type EnumCareSubscriptionPaymentStatusNullableFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
};

export type EnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
};

export type EnumChannelCreationSourceTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelCreationSourceType>;
};

export type EnumChannelCreationSourceTypeFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
};

export type EnumChannelCreationSourceTypeWithAggregatesFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  _max?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
};

export type EnumChannelMessageAttachmentEntityTypeNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
};

export type EnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
};

export type EnumChannelMessageAttachmentTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentType>;
};

export type EnumChannelMessageAttachmentTypeFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
};

export type EnumChannelMessageAttachmentTypeWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
};

export type EnumChannelMessageAttachmentUploadStatusNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type EnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type EnumChannelStatusActionFieldUpdateOperationsInput = {
  set?: Maybe<ChannelStatusAction>;
};

export type EnumChannelStatusActionFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionFilter>;
};

export type EnumChannelStatusActionWithAggregatesFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelStatusActionFilter>;
  _max?: Maybe<NestedEnumChannelStatusActionFilter>;
};

export type EnumChannelTypeNullableFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  notIn?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableFilter>;
};

export type EnumChannelTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  notIn?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelTypeNullableFilter>;
  _max?: Maybe<NestedEnumChannelTypeNullableFilter>;
};

export type EnumChannelVisibilityNullableFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  notIn?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
};

export type EnumChannelVisibilityNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  notIn?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  _max?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
};

export type EnumChargeCaptureTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChargeCaptureType>;
};

export type EnumChargeCaptureTypeFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeFilter>;
};

export type EnumChargeCaptureTypeWithAggregatesFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  _max?: Maybe<NestedEnumChargeCaptureTypeFilter>;
};

export type EnumClinicEntityCreationSourceNullableFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
};

export type EnumClinicEntityCreationSourceNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
};

export type EnumClinicEntityPhoneNumberSmsStatusNullableFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
};

export type EnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
};

export type EnumClinicOfficeHoursTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicOfficeHoursType>;
};

export type EnumClinicOfficeHoursTypeFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
};

export type EnumClinicOfficeHoursTypeWithAggregatesFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  _max?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
};

export type EnumClinicPetParentAddressTypeNullableFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
};

export type EnumClinicPetParentAddressTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  _max?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
};

export type EnumClinicPimsIntegrationTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicPimsIntegrationType>;
};

export type EnumClinicPimsIntegrationTypeFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
};

export type EnumClinicPimsIntegrationTypeWithAggregatesFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  _max?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
};

export type EnumContactEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ContactEntityType>;
};

export type EnumContactEntityTypeFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  notIn?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeFilter>;
};

export type EnumContactEntityTypeWithAggregatesFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  notIn?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumContactEntityTypeFilter>;
  _max?: Maybe<NestedEnumContactEntityTypeFilter>;
};

export type EnumContactVerificationMethodNullableFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
};

export type EnumContactVerificationMethodNullableWithAggregatesFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  _max?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
};

export type EnumCurrencyFieldUpdateOperationsInput = {
  set?: Maybe<Currency>;
};

export type EnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
};

export type EnumCurrencyWithAggregatesFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCurrencyFilter>;
  _max?: Maybe<NestedEnumCurrencyFilter>;
};

export type EnumEmailProviderFieldUpdateOperationsInput = {
  set?: Maybe<EmailProvider>;
};

export type EnumEmailProviderFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  notIn?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderFilter>;
};

export type EnumEmailProviderLogEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailProviderLogEntityType>;
};

export type EnumEmailProviderLogEntityTypeFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
};

export type EnumEmailProviderLogEntityTypeWithAggregatesFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  _max?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
};

export type EnumEmailProviderWithAggregatesFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  notIn?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailProviderFilter>;
  _max?: Maybe<NestedEnumEmailProviderFilter>;
};

export type EnumEnrollmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<EnrollmentStatus>;
};

export type EnumEnrollmentStatusFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusFilter>;
};

export type EnumEnrollmentStatusWithAggregatesFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEnrollmentStatusFilter>;
  _max?: Maybe<NestedEnumEnrollmentStatusFilter>;
};

export type EnumFeatureFlagStateFieldUpdateOperationsInput = {
  set?: Maybe<FeatureFlagState>;
};

export type EnumFeatureFlagStateFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  notIn?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateFilter>;
};

export type EnumFeatureFlagStateWithAggregatesFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  notIn?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeatureFlagStateFilter>;
  _max?: Maybe<NestedEnumFeatureFlagStateFilter>;
};

export type EnumFeatureFlagTypeFieldUpdateOperationsInput = {
  set?: Maybe<FeatureFlagType>;
};

export type EnumFeatureFlagTypeFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  notIn?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeFilter>;
};

export type EnumFeatureFlagTypeWithAggregatesFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  notIn?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  _max?: Maybe<NestedEnumFeatureFlagTypeFilter>;
};

export type EnumFilterSelectionTypeFieldUpdateOperationsInput = {
  set?: Maybe<FilterSelectionType>;
};

export type EnumFilterSelectionTypeFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  notIn?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeFilter>;
};

export type EnumFilterSelectionTypeWithAggregatesFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  notIn?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  _max?: Maybe<NestedEnumFilterSelectionTypeFilter>;
};

export type EnumFinancialTransactionTypeFieldUpdateOperationsInput = {
  set?: Maybe<FinancialTransactionType>;
};

export type EnumFinancialTransactionTypeFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
};

export type EnumFinancialTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  _max?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
};

export type EnumFormTemplateTypeFieldUpdateOperationsInput = {
  set?: Maybe<FormTemplateType>;
};

export type EnumFormTemplateTypeFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  notIn?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeFilter>;
};

export type EnumFormTemplateTypeWithAggregatesFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  notIn?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFormTemplateTypeFilter>;
  _max?: Maybe<NestedEnumFormTemplateTypeFilter>;
};

export type EnumIntegrationTypeNullableFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  notIn?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
};

export type EnumIntegrationTypeNullableWithAggregatesFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  notIn?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
};

export type EnumInvoiceLineItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<InvoiceLineItemType>;
};

export type EnumInvoiceLineItemTypeFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
};

export type EnumInvoiceLineItemTypeWithAggregatesFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  _max?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
};

export type EnumInvoicePimsWritebackActionFieldUpdateOperationsInput = {
  set?: Maybe<InvoicePimsWritebackAction>;
};

export type EnumInvoicePimsWritebackActionFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
};

export type EnumInvoicePimsWritebackActionWithAggregatesFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  _max?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
};

export type EnumInvoiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<InvoiceStatus>;
};

export type EnumInvoiceStatusFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type EnumInvoiceStatusWithAggregatesFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoiceStatusFilter>;
  _max?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type EnumMassTextAlertStatusFieldUpdateOperationsInput = {
  set?: Maybe<MassTextAlertStatus>;
};

export type EnumMassTextAlertStatusFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusFilter>;
};

export type EnumMassTextAlertStatusWithAggregatesFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  _max?: Maybe<NestedEnumMassTextAlertStatusFilter>;
};

export type EnumMessageSourceFieldUpdateOperationsInput = {
  set?: Maybe<MessageSource>;
};

export type EnumMessageSourceFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  notIn?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceFilter>;
};

export type EnumMessageSourceWithAggregatesFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  notIn?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageSourceFilter>;
  _max?: Maybe<NestedEnumMessageSourceFilter>;
};

export type EnumMessageTypeFieldUpdateOperationsInput = {
  set?: Maybe<MessageType>;
};

export type EnumMessageTypeFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeFilter>;
};

export type EnumMessageTypeWithAggregatesFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageTypeFilter>;
  _max?: Maybe<NestedEnumMessageTypeFilter>;
};

export type EnumNotificationEventFieldUpdateOperationsInput = {
  set?: Maybe<NotificationEvent>;
};

export type EnumNotificationEventFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  notIn?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventFilter>;
};

export type EnumNotificationEventWithAggregatesFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  notIn?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumNotificationEventFilter>;
  _max?: Maybe<NestedEnumNotificationEventFilter>;
};

export type EnumNotificationSettingTypeFieldUpdateOperationsInput = {
  set?: Maybe<NotificationSettingType>;
};

export type EnumNotificationSettingTypeFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  notIn?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeFilter>;
};

export type EnumNotificationSettingTypeWithAggregatesFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  notIn?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  _max?: Maybe<NestedEnumNotificationSettingTypeFilter>;
};

export type EnumPaymentMediumNullableFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  notIn?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableFilter>;
};

export type EnumPaymentMediumNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  notIn?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  _max?: Maybe<NestedEnumPaymentMediumNullableFilter>;
};

export type EnumPaymentPimsWritebackActionFieldUpdateOperationsInput = {
  set?: Maybe<PaymentPimsWritebackAction>;
};

export type EnumPaymentPimsWritebackActionFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
};

export type EnumPaymentPimsWritebackActionWithAggregatesFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  _max?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
};

export type EnumPaymentRelationshipNullableFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  notIn?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
};

export type EnumPaymentRelationshipNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  notIn?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  _max?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
};

export type EnumPaymentSourceFieldUpdateOperationsInput = {
  set?: Maybe<PaymentSource>;
};

export type EnumPaymentSourceFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  notIn?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceFilter>;
};

export type EnumPaymentSourceWithAggregatesFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  notIn?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentSourceFilter>;
  _max?: Maybe<NestedEnumPaymentSourceFilter>;
};

export type EnumPermissionTypeFieldUpdateOperationsInput = {
  set?: Maybe<PermissionType>;
};

export type EnumPermissionTypeFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  notIn?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeFilter>;
};

export type EnumPermissionTypeWithAggregatesFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  notIn?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPermissionTypeFilter>;
  _max?: Maybe<NestedEnumPermissionTypeFilter>;
};

export type EnumPhoneNumberStatusFieldUpdateOperationsInput = {
  set?: Maybe<PhoneNumberStatus>;
};

export type EnumPhoneNumberStatusFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusFilter>;
};

export type EnumPhoneNumberStatusWithAggregatesFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  _max?: Maybe<NestedEnumPhoneNumberStatusFilter>;
};

export type EnumPimsIntegrationCapabilityFieldUpdateOperationsInput = {
  set?: Maybe<PimsIntegrationCapability>;
};

export type EnumPimsIntegrationCapabilityFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
};

export type EnumPimsIntegrationCapabilityWithAggregatesFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  _max?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
};

export type EnumPimsInvoiceLineItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<PimsInvoiceLineItemType>;
};

export type EnumPimsInvoiceLineItemTypeFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
};

export type EnumPimsInvoiceLineItemTypeWithAggregatesFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  _max?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
};

export type EnumPimsSyncFrequencyFieldUpdateOperationsInput = {
  set?: Maybe<PimsSyncFrequency>;
};

export type EnumPimsSyncFrequencyFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
};

export type EnumPimsSyncFrequencyWithAggregatesFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  _max?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
};

export type EnumPimsSyncStatusNullableFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
};

export type EnumPimsSyncStatusNullableWithAggregatesFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  _max?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
};

export type EnumPimsWritebackStatusFieldUpdateOperationsInput = {
  set?: Maybe<PimsWritebackStatus>;
};

export type EnumPimsWritebackStatusFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusFilter>;
};

export type EnumPimsWritebackStatusWithAggregatesFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  _max?: Maybe<NestedEnumPimsWritebackStatusFilter>;
};

export type EnumPimsWritebackTypeFieldUpdateOperationsInput = {
  set?: Maybe<PimsWritebackType>;
};

export type EnumPimsWritebackTypeFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  notIn?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeFilter>;
};

export type EnumPimsWritebackTypeWithAggregatesFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  notIn?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  _max?: Maybe<NestedEnumPimsWritebackTypeFilter>;
};

export type EnumRecordingDownloadStatusFieldUpdateOperationsInput = {
  set?: Maybe<RecordingDownloadStatus>;
};

export type EnumRecordingDownloadStatusFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
};

export type EnumRecordingDownloadStatusWithAggregatesFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  _max?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
};

export type EnumRefundReasonFieldUpdateOperationsInput = {
  set?: Maybe<RefundReason>;
};

export type EnumRefundReasonFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  notIn?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonFilter>;
};

export type EnumRefundReasonWithAggregatesFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  notIn?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRefundReasonFilter>;
  _max?: Maybe<NestedEnumRefundReasonFilter>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: Maybe<Role>;
};

export type EnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type EnumRoleWithAggregatesFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRoleFilter>;
  _max?: Maybe<NestedEnumRoleFilter>;
};

export type EnumRuleActionsFieldUpdateOperationsInput = {
  set?: Maybe<RuleActions>;
};

export type EnumRuleActionsFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  notIn?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsFilter>;
};

export type EnumRuleActionsWithAggregatesFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  notIn?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRuleActionsFilter>;
  _max?: Maybe<NestedEnumRuleActionsFilter>;
};

export type EnumServiceCapabilityFieldUpdateOperationsInput = {
  set?: Maybe<ServiceCapability>;
};

export type EnumServiceCapabilityFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  notIn?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityFilter>;
};

export type EnumServiceCapabilityWithAggregatesFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  notIn?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceCapabilityFilter>;
  _max?: Maybe<NestedEnumServiceCapabilityFilter>;
};

export type EnumServiceProviderFieldUpdateOperationsInput = {
  set?: Maybe<ServiceProvider>;
};

export type EnumServiceProviderFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  notIn?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderFilter>;
};

export type EnumServiceProviderWithAggregatesFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  notIn?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceProviderFilter>;
  _max?: Maybe<NestedEnumServiceProviderFilter>;
};

export type EnumServiceReminderNotificationTypeFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderNotificationType>;
};

export type EnumServiceReminderNotificationTypeFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
};

export type EnumServiceReminderNotificationTypeWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  _max?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
};

export type EnumServiceReminderTimingOffsetUnitFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderTimingOffsetUnit>;
};

export type EnumServiceReminderTimingOffsetUnitFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
};

export type EnumServiceReminderTimingOffsetUnitWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
};

export type EnumServiceReminderTimingTypeFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderTimingType>;
};

export type EnumServiceReminderTimingTypeFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
};

export type EnumServiceReminderTimingTypeWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
};

export type EnumSmsEntityTypeNullableFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  notIn?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
};

export type EnumSmsEntityTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  notIn?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  _max?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
};

export type EnumSmsNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
};

export type EnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  _max?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
};

export type EnumSmsNotificationProviderFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationProvider>;
};

export type EnumSmsNotificationProviderFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderFilter>;
};

export type EnumSmsNotificationProviderWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  _max?: Maybe<NestedEnumSmsNotificationProviderFilter>;
};

export type EnumSmsNotificationStatusTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationStatusType>;
};

export type EnumSmsNotificationStatusTypeFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
};

export type EnumSmsNotificationStatusTypeWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  _max?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
};

export type EnumSpeciesTypeFieldUpdateOperationsInput = {
  set?: Maybe<SpeciesType>;
};

export type EnumSpeciesTypeFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeFilter>;
};

export type EnumSpeciesTypeNullableFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
};

export type EnumSpeciesTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
};

export type EnumSpeciesTypeWithAggregatesFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeFilter>;
};

export type EnumStateFieldUpdateOperationsInput = {
  set?: Maybe<State>;
};

export type EnumStateFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  notIn?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateFilter>;
};

export type EnumStateWithAggregatesFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  notIn?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStateFilter>;
  _max?: Maybe<NestedEnumStateFilter>;
};

export type EnumStripeCollectionMethodFieldUpdateOperationsInput = {
  set?: Maybe<StripeCollectionMethod>;
};

export type EnumStripeCollectionMethodFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodFilter>;
};

export type EnumStripeCollectionMethodWithAggregatesFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  _max?: Maybe<NestedEnumStripeCollectionMethodFilter>;
};

export type EnumStripeDisputeStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripeDisputeStatus>;
};

export type EnumStripeDisputeStatusFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusFilter>;
};

export type EnumStripeDisputeStatusWithAggregatesFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  _max?: Maybe<NestedEnumStripeDisputeStatusFilter>;
};

export type EnumStripeInvoiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripeInvoiceStatus>;
};

export type EnumStripeInvoiceStatusFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
};

export type EnumStripeInvoiceStatusWithAggregatesFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  _max?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
};

export type EnumStripePaymentErrorNullableFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  notIn?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
};

export type EnumStripePaymentErrorNullableWithAggregatesFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  notIn?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  _max?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
};

export type EnumStripePaymentIntentStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentIntentStatus>;
};

export type EnumStripePaymentIntentStatusFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
};

export type EnumStripePaymentIntentStatusWithAggregatesFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  _max?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
};

export type EnumStripePaymentMethodTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentMethodType>;
};

export type EnumStripePaymentMethodTypeFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
};

export type EnumStripePaymentMethodTypeWithAggregatesFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  _max?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
};

export type EnumStripePaymentTransferTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentTransferType>;
};

export type EnumStripePaymentTransferTypeFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
};

export type EnumStripePaymentTransferTypeWithAggregatesFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  _max?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
};

export type EnumStripePayoutStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripePayoutStatus>;
};

export type EnumStripePayoutStatusFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusFilter>;
};

export type EnumStripePayoutStatusWithAggregatesFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePayoutStatusFilter>;
  _max?: Maybe<NestedEnumStripePayoutStatusFilter>;
};

export type EnumStripeReceiptMediumFieldUpdateOperationsInput = {
  set?: Maybe<StripeReceiptMedium>;
};

export type EnumStripeReceiptMediumFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumFilter>;
};

export type EnumStripeReceiptMediumWithAggregatesFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  _max?: Maybe<NestedEnumStripeReceiptMediumFilter>;
};

export type EnumStripeTerminalModelFieldUpdateOperationsInput = {
  set?: Maybe<StripeTerminalModel>;
};

export type EnumStripeTerminalModelFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelFilter>;
};

export type EnumStripeTerminalModelWithAggregatesFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeTerminalModelFilter>;
  _max?: Maybe<NestedEnumStripeTerminalModelFilter>;
};

export type EnumTwilioRecordingStatusFieldUpdateOperationsInput = {
  set?: Maybe<TwilioRecordingStatus>;
};

export type EnumTwilioRecordingStatusFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
};

export type EnumTwilioRecordingStatusWithAggregatesFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  _max?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
};

export type EnumTwilioVerificationStatusFieldUpdateOperationsInput = {
  set?: Maybe<TwilioVerificationStatus>;
};

export type EnumTwilioVerificationStatusFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
};

export type EnumTwilioVerificationStatusWithAggregatesFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  _max?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
};

export type EnumUserTypeFieldUpdateOperationsInput = {
  set?: Maybe<UserType>;
};

export type EnumUserTypeFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeFilter>;
};

export type EnumUserTypeWithAggregatesFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumUserTypeFilter>;
  _max?: Maybe<NestedEnumUserTypeFilter>;
};

export type EnumVetDataIntegrationSystemFieldUpdateOperationsInput = {
  set?: Maybe<VetDataIntegrationSystem>;
};

export type EnumVetDataIntegrationSystemFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
};

export type EnumVetDataIntegrationSystemWithAggregatesFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  _max?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
};

export type EnumVetLicenseTypeFieldUpdateOperationsInput = {
  set?: Maybe<VetLicenseType>;
};

export type EnumVetLicenseTypeFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  notIn?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeFilter>;
};

export type EnumVetLicenseTypeWithAggregatesFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  notIn?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetLicenseTypeFilter>;
  _max?: Maybe<NestedEnumVetLicenseTypeFilter>;
};

export type EnumWidgetRequestTypeFieldUpdateOperationsInput = {
  set?: Maybe<WidgetRequestType>;
};

export type EnumWidgetRequestTypeFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  notIn?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeFilter>;
};

export type EnumWidgetRequestTypeWithAggregatesFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  notIn?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  _max?: Maybe<NestedEnumWidgetRequestTypeFilter>;
};

export type EnumWorkflowActionTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowActionType>;
};

export type EnumWorkflowActionTypeFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  notIn?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeFilter>;
};

export type EnumWorkflowActionTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  notIn?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowActionTypeFilter>;
};

export type EnumWorkflowEventFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowEvent>;
};

export type EnumWorkflowEventFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  notIn?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventFilter>;
};

export type EnumWorkflowEventTriggerTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowEventTriggerType>;
};

export type EnumWorkflowEventTriggerTypeFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
};

export type EnumWorkflowEventTriggerTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
};

export type EnumWorkflowEventWithAggregatesFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  notIn?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowEventFilter>;
  _max?: Maybe<NestedEnumWorkflowEventFilter>;
};

export type EnumWorkflowSendingHoursFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowSendingHours>;
};

export type EnumWorkflowSendingHoursFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
};

export type EnumWorkflowSendingHoursWithAggregatesFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  _max?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
};

export type EnumWorkflowTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowType>;
};

export type EnumWorkflowTypeFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeFilter>;
};

export type EnumWorkflowTypeNullableFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
};

export type EnumWorkflowTypeNullableWithAggregatesFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
};

export type EnumWorkflowTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeFilter>;
};

export type EnumWorkflowVisitTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowVisitType>;
};

export type EnumWorkflowVisitTypeFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
};

export type EnumWorkflowVisitTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
};

export type ExportPatientVaccinesToPdfInput = {
  petId: Scalars['String'];
  petParentId: Scalars['String'];
};

export type ExportPatientVaccinesToPdfResponse = {
  __typename?: 'ExportPatientVaccinesToPDFResponse';
  success: Scalars['Boolean'];
  base64PDF?: Maybe<Scalars['String']>;
};

export type EzyVetIntegration = {
  __typename?: 'EzyVetIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type EzyVetIntegrationCountAggregateOutputType = {
  __typename?: 'EzyVetIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  client_id: Scalars['Int'];
  client_secret: Scalars['Int'];
  ownership_id: Scalars['Int'];
  defaultAppointmentTypeId: Scalars['Int'];
  scopes: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EzyVetIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
};

export type EzyVetIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEzyvetInput>;
};

export type EzyVetIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<EzyVetIntegrationWhereUniqueInput>;
};

export type EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: EzyVetIntegrationWhereUniqueInput;
  create: EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type EzyVetIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationMaxAggregateOutputType = {
  __typename?: 'EzyVetIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
};

export type EzyVetIntegrationMinAggregateOutputType = {
  __typename?: 'EzyVetIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
};

export enum EzyVetIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClientId = 'client_id',
  ClientSecret = 'client_secret',
  OwnershipId = 'ownership_id',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
  Scopes = 'scopes',
}

export type EzyVetIntegrationOrderByRelevanceInput = {
  fields: Array<EzyVetIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type EzyVetIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  _count?: Maybe<EzyVetIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<EzyVetIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<EzyVetIntegrationMinOrderByAggregateInput>;
};

export type EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EzyVetIntegrationOrderByRelevanceInput>;
};

export type EzyVetIntegrationRelationFilter = {
  is?: Maybe<EzyVetIntegrationWhereInput>;
  isNot?: Maybe<EzyVetIntegrationWhereInput>;
};

export enum EzyVetIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClientId = 'client_id',
  ClientSecret = 'client_secret',
  OwnershipId = 'ownership_id',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
  Scopes = 'scopes',
}

export type EzyVetIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  client_id?: Maybe<StringNullableWithAggregatesFilter>;
  client_secret?: Maybe<StringNullableWithAggregatesFilter>;
  ownership_id?: Maybe<StringNullableWithAggregatesFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  scopes?: Maybe<StringNullableWithAggregatesFilter>;
};

export type EzyVetIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutEzyvetInput>;
};

export type EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutEzyvetInput>;
};

export type EzyVetIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEzyvetInput>;
};

export type EzyVetIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<EzyVetIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  update?: Maybe<EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type EzyVetIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
};

export type EzyVetIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type EzyVetIntegrationWhereInput = {
  AND?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  OR?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  NOT?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  client_id?: Maybe<StringNullableFilter>;
  client_secret?: Maybe<StringNullableFilter>;
  ownership_id?: Maybe<StringNullableFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableFilter>;
  scopes?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type EzyVetIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state: FeatureFlagState;
  clinics: Array<Clinic>;
  _count: FeatureFlagCountOutputType;
};

export type FeatureFlagClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type FeatureFlagCountAggregateOutputType = {
  __typename?: 'FeatureFlagCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  key: Scalars['Int'];
  description: Scalars['Int'];
  type: Scalars['Int'];
  state: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FeatureFlagCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type FeatureFlagCountOutputType = {
  __typename?: 'FeatureFlagCountOutputType';
  clinics: Scalars['Int'];
};

export type FeatureFlagCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutFeatureFlagsInput>;
};

export type FeatureFlagCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
};

export type FeatureFlagCreateOrConnectWithoutClinicsInput = {
  where: FeatureFlagWhereUniqueInput;
  create: FeatureFlagUncheckedCreateWithoutClinicsInput;
};

export type FeatureFlagCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagListRelationFilter = {
  every?: Maybe<FeatureFlagWhereInput>;
  some?: Maybe<FeatureFlagWhereInput>;
  none?: Maybe<FeatureFlagWhereInput>;
};

export type FeatureFlagMaxAggregateOutputType = {
  __typename?: 'FeatureFlagMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type FeatureFlagMinAggregateOutputType = {
  __typename?: 'FeatureFlagMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type FeatureFlagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FeatureFlagOrderByRelevanceFieldEnum {
  Id = 'id',
  Key = 'key',
  Description = 'description',
}

export type FeatureFlagOrderByRelevanceInput = {
  fields: Array<FeatureFlagOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FeatureFlagOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  _count?: Maybe<FeatureFlagCountOrderByAggregateInput>;
  _max?: Maybe<FeatureFlagMaxOrderByAggregateInput>;
  _min?: Maybe<FeatureFlagMinOrderByAggregateInput>;
};

export type FeatureFlagOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  _relevance?: Maybe<FeatureFlagOrderByRelevanceInput>;
};

export enum FeatureFlagScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Key = 'key',
  Description = 'description',
  Type = 'type',
  State = 'state',
}

export type FeatureFlagScalarWhereInput = {
  AND?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  OR?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  NOT?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  key?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumFeatureFlagTypeFilter>;
  state?: Maybe<EnumFeatureFlagStateFilter>;
};

export type FeatureFlagScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  key?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumFeatureFlagTypeWithAggregatesFilter>;
  state?: Maybe<EnumFeatureFlagStateWithAggregatesFilter>;
};

export enum FeatureFlagState {
  On = 'On',
  Off = 'Off',
}

export enum FeatureFlagType {
  Global = 'Global',
  Clinic = 'Clinic',
}

export type FeatureFlagUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutFeatureFlagsInput>;
};

export type FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
};

export type FeatureFlagUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: FeatureFlagType;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutFeatureFlagsInput>;
};

export type FeatureFlagUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUncheckedUpdateManyWithoutClinicsInput = {
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  disconnect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  delete?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  update?: Maybe<Array<FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<FeatureFlagUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<FeatureFlagScalarWhereInput>>;
};

export type FeatureFlagUncheckedUpdateManyWithoutFeatureFlagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
  clinics?: Maybe<ClinicUpdateManyWithoutFeatureFlagsInput>;
};

export type FeatureFlagUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUpdateManyWithWhereWithoutClinicsInput = {
  where: FeatureFlagScalarWhereInput;
  data: FeatureFlagUncheckedUpdateManyWithoutFeatureFlagsInput;
};

export type FeatureFlagUpdateManyWithoutClinicsInput = {
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  disconnect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  delete?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  update?: Maybe<Array<FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<FeatureFlagUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<FeatureFlagScalarWhereInput>>;
};

export type FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput = {
  where: FeatureFlagWhereUniqueInput;
  data: FeatureFlagUncheckedUpdateWithoutClinicsInput;
};

export type FeatureFlagUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<FeatureFlagType>;
  state?: Maybe<FeatureFlagState>;
};

export type FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput = {
  where: FeatureFlagWhereUniqueInput;
  update: FeatureFlagUncheckedUpdateWithoutClinicsInput;
  create: FeatureFlagUncheckedCreateWithoutClinicsInput;
};

export type FeatureFlagWhereInput = {
  AND?: Maybe<Array<FeatureFlagWhereInput>>;
  OR?: Maybe<Array<FeatureFlagWhereInput>>;
  NOT?: Maybe<Array<FeatureFlagWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  key?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumFeatureFlagTypeFilter>;
  state?: Maybe<EnumFeatureFlagStateFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
};

export type FeatureFlagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type FileExportData = {
  __typename?: 'FileExportData';
  fileType: Scalars['String'];
  base64Encoding: Scalars['String'];
};

export enum FilterSelectionType {
  Private = 'Private',
  PublicReadonly = 'PublicReadonly',
}

export type FinancialTransaction = {
  __typename?: 'FinancialTransaction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  clinic: Clinic;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripeInvoice?: Maybe<StripeInvoice>;
  stripeRefund?: Maybe<StripeRefund>;
  stripeDispute?: Maybe<StripeDispute>;
  stripePayout?: Maybe<StripePayout>;
  channel?: Maybe<Channel>;
};

export type FinancialTransactionCountAggregateOutputType = {
  __typename?: 'FinancialTransactionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  timestamp: Scalars['Int'];
  type: Scalars['Int'];
  clinicId: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  stripeRefundId: Scalars['Int'];
  stripeDisputeId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  channelId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FinancialTransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type FinancialTransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateManyChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionCreateManyChannelInputEnvelope = {
  data: Array<FinancialTransactionCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionCreateManyClinicInputEnvelope = {
  data: Array<FinancialTransactionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionCreateManyStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionCreateManyStripePayoutInputEnvelope = {
  data: Array<FinancialTransactionCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeDisputeInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeRefundInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionCreateOrConnectWithoutChannelInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutChannelInput;
};

export type FinancialTransactionCreateOrConnectWithoutClinicInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutClinicInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeDisputeInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeDisputeInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripePayoutInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripePayoutInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeRefundInput = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeRefundInput;
};

export type FinancialTransactionCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionCreateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionListRelationFilter = {
  every?: Maybe<FinancialTransactionWhereInput>;
  some?: Maybe<FinancialTransactionWhereInput>;
  none?: Maybe<FinancialTransactionWhereInput>;
};

export type FinancialTransactionMaxAggregateOutputType = {
  __typename?: 'FinancialTransactionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type FinancialTransactionMinAggregateOutputType = {
  __typename?: 'FinancialTransactionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
};

export type FinancialTransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FinancialTransactionOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripeRefundId = 'stripeRefundId',
  StripeDisputeId = 'stripeDisputeId',
  StripePayoutId = 'stripePayoutId',
  ChannelId = 'channelId',
}

export type FinancialTransactionOrderByRelevanceInput = {
  fields: Array<FinancialTransactionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FinancialTransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  _count?: Maybe<FinancialTransactionCountOrderByAggregateInput>;
  _max?: Maybe<FinancialTransactionMaxOrderByAggregateInput>;
  _min?: Maybe<FinancialTransactionMinOrderByAggregateInput>;
};

export type FinancialTransactionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripeRefund?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  stripeDispute?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<FinancialTransactionOrderByRelevanceInput>;
};

export type FinancialTransactionRelationFilter = {
  is?: Maybe<FinancialTransactionWhereInput>;
  isNot?: Maybe<FinancialTransactionWhereInput>;
};

export enum FinancialTransactionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Timestamp = 'timestamp',
  Type = 'type',
  ClinicId = 'clinicId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripeRefundId = 'stripeRefundId',
  StripeDisputeId = 'stripeDisputeId',
  StripePayoutId = 'stripePayoutId',
  ChannelId = 'channelId',
}

export type FinancialTransactionScalarWhereInput = {
  AND?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  OR?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  timestamp?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumFinancialTransactionTypeFilter>;
  clinicId?: Maybe<StringFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  stripeDisputeId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
};

export type FinancialTransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  timestamp?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumFinancialTransactionTypeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeRefundId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeDisputeId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum FinancialTransactionType {
  Sale = 'Sale',
  Subscription = 'Subscription',
  Refund = 'Refund',
  Dispute = 'Dispute',
}

export type FinancialTransactionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
};

export type FinancialTransactionUncheckedCreateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  clinicId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutStripePayoutInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeDisputeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripePaymentIntentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeRefundInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeRefundInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeRefundInput>;
};

export type FinancialTransactionUncheckedUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type FinancialTransactionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
};

export type FinancialTransactionUpdateManyWithWhereWithoutChannelInput = {
  where: FinancialTransactionScalarWhereInput;
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
};

export type FinancialTransactionUpdateManyWithWhereWithoutClinicInput = {
  where: FinancialTransactionScalarWhereInput;
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
};

export type FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput = {
  where: FinancialTransactionScalarWhereInput;
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
};

export type FinancialTransactionUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutChannelInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUpdateManyWithoutStripePayoutInput = {
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
};

export type FinancialTransactionUpdateOneWithoutStripeDisputeInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeDisputeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput>;
};

export type FinancialTransactionUpdateOneWithoutStripeInvoiceInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput>;
};

export type FinancialTransactionUpdateOneWithoutStripePaymentIntentInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripePaymentIntentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput>;
};

export type FinancialTransactionUpdateOneWithoutStripeRefundInput = {
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeRefundInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeRefundInput>;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput = {
  where: FinancialTransactionWhereUniqueInput;
  data: FinancialTransactionUncheckedUpdateWithoutChannelInput;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput = {
  where: FinancialTransactionWhereUniqueInput;
  data: FinancialTransactionUncheckedUpdateWithoutClinicInput;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput = {
  where: FinancialTransactionWhereUniqueInput;
  data: FinancialTransactionUncheckedUpdateWithoutStripePayoutInput;
};

export type FinancialTransactionUpdateWithoutChannelInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionUpdateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionUpdateWithoutStripeRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionInput>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsInput>;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput = {
  where: FinancialTransactionWhereUniqueInput;
  update: FinancialTransactionUncheckedUpdateWithoutChannelInput;
  create: FinancialTransactionUncheckedCreateWithoutChannelInput;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput = {
  where: FinancialTransactionWhereUniqueInput;
  update: FinancialTransactionUncheckedUpdateWithoutClinicInput;
  create: FinancialTransactionUncheckedCreateWithoutClinicInput;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput = {
  where: FinancialTransactionWhereUniqueInput;
  update: FinancialTransactionUncheckedUpdateWithoutStripePayoutInput;
  create: FinancialTransactionUncheckedCreateWithoutStripePayoutInput;
};

export type FinancialTransactionUpsertWithoutStripeDisputeInput = {
  update: FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeDisputeInput;
};

export type FinancialTransactionUpsertWithoutStripeInvoiceInput = {
  update: FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput;
};

export type FinancialTransactionUpsertWithoutStripePaymentIntentInput = {
  update: FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput;
  create: FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput;
};

export type FinancialTransactionUpsertWithoutStripeRefundInput = {
  update: FinancialTransactionUncheckedUpdateWithoutStripeRefundInput;
  create: FinancialTransactionUncheckedCreateWithoutStripeRefundInput;
};

export type FinancialTransactionWhereInput = {
  AND?: Maybe<Array<FinancialTransactionWhereInput>>;
  OR?: Maybe<Array<FinancialTransactionWhereInput>>;
  NOT?: Maybe<Array<FinancialTransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  timestamp?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumFinancialTransactionTypeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  stripeDisputeId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  stripeRefund?: Maybe<StripeRefundWhereInput>;
  stripeDispute?: Maybe<StripeDisputeWhereInput>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  channel?: Maybe<ChannelWhereInput>;
};

export type FinancialTransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
};

/** @deprecated - 1.0 */
export type FirebaseStructure = {
  __typename?: 'FirebaseStructure';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  table: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureCountAggregateOutputType = {
  __typename?: 'FirebaseStructureCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  table: Scalars['Int'];
  structure: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FirebaseStructureCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
};

export type FirebaseStructureCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureMaxAggregateOutputType = {
  __typename?: 'FirebaseStructureMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
};

export type FirebaseStructureMinAggregateOutputType = {
  __typename?: 'FirebaseStructureMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
};

export enum FirebaseStructureOrderByRelevanceFieldEnum {
  Id = 'id',
  Table = 'table',
  Structure = 'structure',
}

export type FirebaseStructureOrderByRelevanceInput = {
  fields: Array<FirebaseStructureOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FirebaseStructureOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  _count?: Maybe<FirebaseStructureCountOrderByAggregateInput>;
  _max?: Maybe<FirebaseStructureMaxOrderByAggregateInput>;
  _min?: Maybe<FirebaseStructureMinOrderByAggregateInput>;
};

export type FirebaseStructureOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  _relevance?: Maybe<FirebaseStructureOrderByRelevanceInput>;
};

export enum FirebaseStructureScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Table = 'table',
  Structure = 'structure',
}

export type FirebaseStructureScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  table?: Maybe<StringWithAggregatesFilter>;
  structure?: Maybe<StringNullableWithAggregatesFilter>;
};

export type FirebaseStructureUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  table?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type FirebaseStructureWhereInput = {
  AND?: Maybe<Array<FirebaseStructureWhereInput>>;
  OR?: Maybe<Array<FirebaseStructureWhereInput>>;
  NOT?: Maybe<Array<FirebaseStructureWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  table?: Maybe<StringFilter>;
  structure?: Maybe<StringNullableFilter>;
};

export type FirebaseStructureWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type FloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
};

export type FloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export enum FormExportErrorType {
  GeneratingForm = 'GeneratingForm',
  InvalidPet = 'InvalidPet',
  InvalidParent = 'InvalidParent',
  PimsConfiguration = 'PimsConfiguration',
  Unauthorized = 'Unauthorized',
  Throttled = 'Throttled',
  PimsServer = 'PimsServer',
  Connection = 'Connection',
  Unknown = 'Unknown',
}

export type FormSubmission = {
  __typename?: 'FormSubmission';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<Appointment>;
  clinic?: Maybe<Clinic>;
  clinicPet?: Maybe<ClinicPet>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  formTemplate?: Maybe<FormTemplate>;
  users: Array<User>;
  serviceReminderNotifications: Array<ServiceReminderNotification>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  automationRunAction?: Maybe<AutomationRunAction>;
  _count: FormSubmissionCountOutputType;
};

export type FormSubmissionUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};

export type FormSubmissionServiceReminderNotificationsArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
};

export type FormSubmissionClinicWidgetRequestsArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
};

export type FormSubmissionChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};

export type FormSubmissionChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};

export type FormSubmissionAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type FormSubmissionCountAggregateOutputType = {
  __typename?: 'FormSubmissionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  completedContent: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  clinicId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  draftContent: Scalars['Int'];
  submittedAt: Scalars['Int'];
  isCallDeflectionForm: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FormSubmissionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completedContent?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
};

export type FormSubmissionCountOutputType = {
  __typename?: 'FormSubmissionCountOutputType';
  users: Scalars['Int'];
  serviceReminderNotifications: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
};

export type FormSubmissionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyAppointmentInputEnvelope = {
  data: Array<FormSubmissionCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetParentInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyFormTemplateInputEnvelope = {
  data: Array<FormSubmissionCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionCreateNestedOneWithoutAutomationRunActionInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunActionInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunActionInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
};

export type FormSubmissionCreateOrConnectWithoutAppointmentInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutAppointmentInput;
};

export type FormSubmissionCreateOrConnectWithoutAutomationRunActionInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutAutomationRunActionInput;
};

export type FormSubmissionCreateOrConnectWithoutAutomationRunsInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutAutomationRunsInput;
};

export type FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutClinicInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicPetInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutClinicPetInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicPetParentInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutClinicPetParentInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type FormSubmissionCreateOrConnectWithoutFormTemplateInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutFormTemplateInput;
};

export type FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput;
};

export type FormSubmissionCreateOrConnectWithoutUsersInput = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionUncheckedCreateWithoutUsersInput;
};

export type FormSubmissionCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionExportInput = {
  id: Scalars['String'];
  petIds: Array<Maybe<Scalars['String']>>;
};

export type FormSubmissionExportResponse = {
  __typename?: 'FormSubmissionExportResponse';
  results: Array<Maybe<FormSubmissionExportResult>>;
};

export type FormSubmissionExportResult = {
  __typename?: 'FormSubmissionExportResult';
  success: Scalars['Boolean'];
  petId: Scalars['String'];
  formId: Scalars['String'];
  errorType?: Maybe<FormExportErrorType>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type FormSubmissionListRelationFilter = {
  every?: Maybe<FormSubmissionWhereInput>;
  some?: Maybe<FormSubmissionWhereInput>;
  none?: Maybe<FormSubmissionWhereInput>;
};

export type FormSubmissionMaxAggregateOutputType = {
  __typename?: 'FormSubmissionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
};

export type FormSubmissionMinAggregateOutputType = {
  __typename?: 'FormSubmissionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
};

export type FormSubmissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FormSubmissionOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicId = 'clinicId',
  AppointmentId = 'appointmentId',
  FormTemplateId = 'formTemplateId',
}

export type FormSubmissionOrderByRelevanceInput = {
  fields: Array<FormSubmissionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FormSubmissionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completedContent?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  _count?: Maybe<FormSubmissionCountOrderByAggregateInput>;
  _max?: Maybe<FormSubmissionMaxOrderByAggregateInput>;
  _min?: Maybe<FormSubmissionMinOrderByAggregateInput>;
};

export type FormSubmissionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  completedContent?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  automationRunAction?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<FormSubmissionOrderByRelevanceInput>;
};

export type FormSubmissionRelationFilter = {
  is?: Maybe<FormSubmissionWhereInput>;
  isNot?: Maybe<FormSubmissionWhereInput>;
};

export enum FormSubmissionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CompletedContent = 'completedContent',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicId = 'clinicId',
  AppointmentId = 'appointmentId',
  FormTemplateId = 'formTemplateId',
  DraftContent = 'draftContent',
  SubmittedAt = 'submittedAt',
  IsCallDeflectionForm = 'isCallDeflectionForm',
}

export type FormSubmissionScalarWhereInput = {
  AND?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  OR?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  NOT?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  completedContent?: Maybe<JsonNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  submittedAt?: Maybe<DateTimeNullableFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableFilter>;
};

export type FormSubmissionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  completedContent?: Maybe<JsonNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  draftContent?: Maybe<JsonNullableWithAggregatesFilter>;
  submittedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type FormSubmissionSubscription = {
  __typename?: 'FormSubmissionSubscription';
  node: FormSubmission;
  mutation: MutationType;
};

export type FormSubmissionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
};

export type FormSubmissionUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionUncheckedUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionUncheckedUpdateManyWithoutFormTemplateInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUncheckedUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionUpdateManyWithWhereWithoutAppointmentInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicPetInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutUsersInput = {
  where: FormSubmissionScalarWhereInput;
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
};

export type FormSubmissionUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutFormTemplateInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
};

export type FormSubmissionUpdateOneWithoutAutomationRunActionInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunActionInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunActionInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutAutomationRunActionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput>;
};

export type FormSubmissionUpdateOneWithoutAutomationRunsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutAutomationRunsInput>;
};

export type FormSubmissionUpdateOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type FormSubmissionUpdateOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type FormSubmissionUpdateOneWithoutClinicWidgetRequestsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutClinicWidgetRequestsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput>;
};

export type FormSubmissionUpdateOneWithoutServiceReminderNotificationsInput = {
  create?: Maybe<FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutServiceReminderNotificationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput>;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutAppointmentInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutClinicInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutClinicPetInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutClinicPetParentInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutFormTemplateInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutUsersInput = {
  where: FormSubmissionWhereUniqueInput;
  data: FormSubmissionUncheckedUpdateWithoutUsersInput;
};

export type FormSubmissionUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutClinicWidgetRequestsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  completedContent?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsInput>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionInput>;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutAppointmentInput;
  create: FormSubmissionUncheckedCreateWithoutAppointmentInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicInput;
  create: FormSubmissionUncheckedCreateWithoutClinicInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicPetInput;
  create: FormSubmissionUncheckedCreateWithoutClinicPetInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicPetParentInput;
  create: FormSubmissionUncheckedCreateWithoutClinicPetParentInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutFormTemplateInput;
  create: FormSubmissionUncheckedCreateWithoutFormTemplateInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutUsersInput = {
  where: FormSubmissionWhereUniqueInput;
  update: FormSubmissionUncheckedUpdateWithoutUsersInput;
  create: FormSubmissionUncheckedCreateWithoutUsersInput;
};

export type FormSubmissionUpsertWithoutAutomationRunActionInput = {
  update: FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput;
  create: FormSubmissionUncheckedCreateWithoutAutomationRunActionInput;
};

export type FormSubmissionUpsertWithoutAutomationRunsInput = {
  update: FormSubmissionUncheckedUpdateWithoutAutomationRunsInput;
  create: FormSubmissionUncheckedCreateWithoutAutomationRunsInput;
};

export type FormSubmissionUpsertWithoutChannelAutomationStatusesInput = {
  update: FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type FormSubmissionUpsertWithoutChannelCreationSourcesInput = {
  update: FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type FormSubmissionUpsertWithoutClinicWidgetRequestsInput = {
  update: FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput;
  create: FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput;
};

export type FormSubmissionUpsertWithoutServiceReminderNotificationsInput = {
  update: FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput;
  create: FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput;
};

export type FormSubmissionWhereInput = {
  AND?: Maybe<Array<FormSubmissionWhereInput>>;
  OR?: Maybe<Array<FormSubmissionWhereInput>>;
  NOT?: Maybe<Array<FormSubmissionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  completedContent?: Maybe<JsonNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  submittedAt?: Maybe<DateTimeNullableFilter>;
  appointment?: Maybe<AppointmentWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
  users?: Maybe<UserListRelationFilter>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  automationRunAction?: Maybe<AutomationRunActionWhereInput>;
};

export type FormSubmissionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  autoExportEnabled: Scalars['Boolean'];
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<Clinic>;
  formTemplateType: FormTemplateType;
  formSubmissions: Array<FormSubmission>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  services: Array<Service>;
  triggeredWorkflows: Array<WorkflowEventSetting>;
  _count: FormTemplateCountOutputType;
};

export type FormTemplateFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};

export type FormTemplateClinicWidgetRequestTypesArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};

export type FormTemplateServicesArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
};

export type FormTemplateTriggeredWorkflowsArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
};

export type FormTemplateCountAggregateOutputType = {
  __typename?: 'FormTemplateCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  title: Scalars['Int'];
  autoExportEnabled: Scalars['Int'];
  content: Scalars['Int'];
  draftContent: Scalars['Int'];
  isDeleted: Scalars['Int'];
  clinicId: Scalars['Int'];
  isActive: Scalars['Int'];
  formTemplateType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FormTemplateCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
};

export type FormTemplateCountOutputType = {
  __typename?: 'FormTemplateCountOutputType';
  formSubmissions: Scalars['Int'];
  clinicWidgetRequestTypes: Scalars['Int'];
  services: Scalars['Int'];
  triggeredWorkflows: Scalars['Int'];
};

export type FormTemplateCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateCreateManyClinicInputEnvelope = {
  data: Array<FormTemplateCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormTemplateCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
};

export type FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
};

export type FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
};

export type FormTemplateCreateNestedOneWithoutFormSubmissionsInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutFormSubmissionsInput>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
};

export type FormTemplateCreateNestedOneWithoutServicesInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutServicesInput>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
};

export type FormTemplateCreateOrConnectWithoutClinicInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutClinicInput;
};

export type FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type FormTemplateCreateOrConnectWithoutFormSubmissionsInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutFormSubmissionsInput;
};

export type FormTemplateCreateOrConnectWithoutServicesInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutServicesInput;
};

export type FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput;
};

export type FormTemplateCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateCreateWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
};

export type FormTemplateListRelationFilter = {
  every?: Maybe<FormTemplateWhereInput>;
  some?: Maybe<FormTemplateWhereInput>;
  none?: Maybe<FormTemplateWhereInput>;
};

export type FormTemplateMaxAggregateOutputType = {
  __typename?: 'FormTemplateMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
};

export type FormTemplateMinAggregateOutputType = {
  __typename?: 'FormTemplateMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
};

export type FormTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FormTemplateOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  ClinicId = 'clinicId',
}

export type FormTemplateOrderByRelevanceInput = {
  fields: Array<FormTemplateOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type FormTemplateOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
  _count?: Maybe<FormTemplateCountOrderByAggregateInput>;
  _max?: Maybe<FormTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<FormTemplateMinOrderByAggregateInput>;
};

export type FormTemplateOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  autoExportEnabled?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  formTemplateType?: Maybe<SortOrder>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  services?: Maybe<ServiceOrderByRelationAggregateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  _relevance?: Maybe<FormTemplateOrderByRelevanceInput>;
};

export type FormTemplateRelationFilter = {
  is?: Maybe<FormTemplateWhereInput>;
  isNot?: Maybe<FormTemplateWhereInput>;
};

export enum FormTemplateScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
  AutoExportEnabled = 'autoExportEnabled',
  Content = 'content',
  DraftContent = 'draftContent',
  IsDeleted = 'isDeleted',
  ClinicId = 'clinicId',
  IsActive = 'isActive',
  FormTemplateType = 'formTemplateType',
}

export type FormTemplateScalarWhereInput = {
  AND?: Maybe<Array<FormTemplateScalarWhereInput>>;
  OR?: Maybe<Array<FormTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<FormTemplateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  autoExportEnabled?: Maybe<BoolFilter>;
  content?: Maybe<JsonNullableFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  formTemplateType?: Maybe<EnumFormTemplateTypeFilter>;
};

export type FormTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  autoExportEnabled?: Maybe<BoolWithAggregatesFilter>;
  content?: Maybe<JsonNullableWithAggregatesFilter>;
  draftContent?: Maybe<JsonNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  formTemplateType?: Maybe<EnumFormTemplateTypeWithAggregatesFilter>;
};

export enum FormTemplateType {
  General = 'General',
  ServiceReminder = 'ServiceReminder',
  WidgetRequest = 'WidgetRequest',
  FillInTheBlank = 'FillInTheBlank',
}

export type FormTemplateUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
};

export type FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
};

export type FormTemplateUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
};

export type FormTemplateUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
};

export type FormTemplateUncheckedUpdateManyWithoutFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
};

export type FormTemplateUncheckedUpdateManyWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateInput>;
};

export type FormTemplateUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
};

export type FormTemplateUpdateManyWithWhereWithoutClinicInput = {
  where: FormTemplateScalarWhereInput;
  data: FormTemplateUncheckedUpdateManyWithoutFormTemplatesInput;
};

export type FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput = {
  where: FormTemplateScalarWhereInput;
  data: FormTemplateUncheckedUpdateManyWithoutTriggeringFormTemplatesInput;
};

export type FormTemplateUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
};

export type FormTemplateUpdateManyWithoutTriggeredWorkflowsInput = {
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
};

export type FormTemplateUpdateOneWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutClinicWidgetRequestTypesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput>;
};

export type FormTemplateUpdateOneWithoutFormSubmissionsInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutFormSubmissionsInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutFormSubmissionsInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutFormSubmissionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutFormSubmissionsInput>;
};

export type FormTemplateUpdateOneWithoutServicesInput = {
  create?: Maybe<FormTemplateUncheckedCreateWithoutServicesInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutServicesInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutServicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutServicesInput>;
};

export type FormTemplateUpdateWithWhereUniqueWithoutClinicInput = {
  where: FormTemplateWhereUniqueInput;
  data: FormTemplateUncheckedUpdateWithoutClinicInput;
};

export type FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput = {
  where: FormTemplateWhereUniqueInput;
  data: FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput;
};

export type FormTemplateUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUpdateWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateInput>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesInput>;
};

export type FormTemplateUpdateWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  draftContent?: Maybe<Scalars['Json']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateInput>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateInput>;
};

export type FormTemplateUpsertWithWhereUniqueWithoutClinicInput = {
  where: FormTemplateWhereUniqueInput;
  update: FormTemplateUncheckedUpdateWithoutClinicInput;
  create: FormTemplateUncheckedCreateWithoutClinicInput;
};

export type FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput = {
  where: FormTemplateWhereUniqueInput;
  update: FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput;
  create: FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput;
};

export type FormTemplateUpsertWithoutClinicWidgetRequestTypesInput = {
  update: FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
  create: FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type FormTemplateUpsertWithoutFormSubmissionsInput = {
  update: FormTemplateUncheckedUpdateWithoutFormSubmissionsInput;
  create: FormTemplateUncheckedCreateWithoutFormSubmissionsInput;
};

export type FormTemplateUpsertWithoutServicesInput = {
  update: FormTemplateUncheckedUpdateWithoutServicesInput;
  create: FormTemplateUncheckedCreateWithoutServicesInput;
};

export type FormTemplateWhereInput = {
  AND?: Maybe<Array<FormTemplateWhereInput>>;
  OR?: Maybe<Array<FormTemplateWhereInput>>;
  NOT?: Maybe<Array<FormTemplateWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  autoExportEnabled?: Maybe<BoolFilter>;
  content?: Maybe<JsonNullableFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  formTemplateType?: Maybe<EnumFormTemplateTypeFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  services?: Maybe<ServiceListRelationFilter>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type FormTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GenerateCustomTokenResponse = {
  __typename?: 'GenerateCustomTokenResponse';
  token?: Maybe<Scalars['String']>;
};

export type GenerateTinyUrlAndTokenInput = {
  clinicPetParentId: Scalars['String'];
};

export type GenerateWidgetAuthenticationRequestResponse = {
  __typename?: 'GenerateWidgetAuthenticationRequestResponse';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  success: Scalars['Boolean'];
  payload?: Maybe<Scalars['Json']>;
};

export type GetBalanceForClientInput = {
  clinicPetParentId: Scalars['String'];
};

export type GetDirectedAuthLinkResult = {
  __typename?: 'GetDirectedAuthLinkResult';
  link: Scalars['String'];
};

export type GetLatestActiveSubscriptionInvoiceOutput = {
  __typename?: 'GetLatestActiveSubscriptionInvoiceOutput';
  subscriptionStatus: Scalars['String'];
  paymentAmountNeeded: Scalars['Int'];
  paymentAmountReceived: Scalars['Int'];
  paymentIntentSecret: Scalars['String'];
};

export type GetOrCreateActiveChannelInput = {
  clinicPetParentIds: Array<Scalars['String']>;
};

export type GetOrCreateActiveClientChannelInput = {
  clinicPetParentIds: Array<Scalars['String']>;
};

export type GetSignedUploadInput = {
  key: Scalars['String'];
  contentType: Scalars['String'];
};

export type GetSignedUploadUrlOutput = {
  __typename?: 'GetSignedUploadUrlOutput';
  key: Scalars['String'];
  uploadUrl: Scalars['String'];
  finalUrl: Scalars['String'];
};

export type GetTwilioNumbersInput = {
  clinicId: Scalars['ID'];
};

export type IdInput = {
  id: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Image = {
  __typename?: 'Image';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  source: Scalars['String'];
  consultation: Array<Consultation>;
  _count: ImageCountOutputType;
};

/** @deprecated - 1.0 */
export type ImageConsultationArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};

export type ImageCountAggregateOutputType = {
  __typename?: 'ImageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  source: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ImageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type ImageCountOutputType = {
  __typename?: 'ImageCountOutputType';
  consultation: Scalars['Int'];
};

export type ImageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
  consultation?: Maybe<ConsultationCreateNestedManyWithoutPicsInput>;
};

export type ImageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
};

export type ImageCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
};

export type ImageCreateOrConnectWithoutConsultationInput = {
  where: ImageWhereUniqueInput;
  create: ImageUncheckedCreateWithoutConsultationInput;
};

export type ImageCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
};

export type ImageListRelationFilter = {
  every?: Maybe<ImageWhereInput>;
  some?: Maybe<ImageWhereInput>;
  none?: Maybe<ImageWhereInput>;
};

export type ImageMaxAggregateOutputType = {
  __typename?: 'ImageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type ImageMinAggregateOutputType = {
  __typename?: 'ImageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type ImageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ImageOrderByRelevanceFieldEnum {
  Id = 'id',
  Source = 'source',
}

export type ImageOrderByRelevanceInput = {
  fields: Array<ImageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ImageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  _count?: Maybe<ImageCountOrderByAggregateInput>;
  _max?: Maybe<ImageMaxOrderByAggregateInput>;
  _min?: Maybe<ImageMinOrderByAggregateInput>;
};

export type ImageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  consultation?: Maybe<ConsultationOrderByRelationAggregateInput>;
  _relevance?: Maybe<ImageOrderByRelevanceInput>;
};

export enum ImageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Source = 'source',
}

export type ImageScalarWhereInput = {
  AND?: Maybe<Array<ImageScalarWhereInput>>;
  OR?: Maybe<Array<ImageScalarWhereInput>>;
  NOT?: Maybe<Array<ImageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  source?: Maybe<StringFilter>;
};

export type ImageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  source?: Maybe<StringWithAggregatesFilter>;
};

export type ImageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
  consultation?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPicsInput>;
};

export type ImageUncheckedCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
};

export type ImageUncheckedCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source: Scalars['String'];
};

export type ImageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  consultation?: Maybe<ConsultationUncheckedUpdateManyWithoutPicsInput>;
};

export type ImageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUncheckedUpdateManyWithoutConsultationInput = {
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutConsultationInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
};

export type ImageUncheckedUpdateManyWithoutPicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUncheckedUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  consultation?: Maybe<ConsultationUpdateManyWithoutPicsInput>;
};

export type ImageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUpdateManyWithWhereWithoutConsultationInput = {
  where: ImageScalarWhereInput;
  data: ImageUncheckedUpdateManyWithoutPicsInput;
};

export type ImageUpdateManyWithoutConsultationInput = {
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutConsultationInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
};

export type ImageUpdateWithWhereUniqueWithoutConsultationInput = {
  where: ImageWhereUniqueInput;
  data: ImageUncheckedUpdateWithoutConsultationInput;
};

export type ImageUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
};

export type ImageUpsertWithWhereUniqueWithoutConsultationInput = {
  where: ImageWhereUniqueInput;
  update: ImageUncheckedUpdateWithoutConsultationInput;
  create: ImageUncheckedCreateWithoutConsultationInput;
};

export type ImageWhereInput = {
  AND?: Maybe<Array<ImageWhereInput>>;
  OR?: Maybe<Array<ImageWhereInput>>;
  NOT?: Maybe<Array<ImageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  source?: Maybe<StringFilter>;
  consultation?: Maybe<ConsultationListRelationFilter>;
};

export type ImageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type InstinctIntegration = {
  __typename?: 'InstinctIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
  password: Scalars['String'];
  /** each "Market" (a clinic or collection of clinics) has a subdomain */
  subdomain: Scalars['String'];
  /** The specific clinic location, if clinic has multiple locations i.e. is part of a Market */
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type InstinctIntegrationCountAggregateOutputType = {
  __typename?: 'InstinctIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
  password: Scalars['Int'];
  subdomain: Scalars['Int'];
  locationId: Scalars['Int'];
  defaultAppointmentTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InstinctIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
};

export type InstinctIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutInstinctInput>;
};

export type InstinctIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<InstinctIntegrationWhereUniqueInput>;
};

export type InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: InstinctIntegrationWhereUniqueInput;
  create: InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type InstinctIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationMaxAggregateOutputType = {
  __typename?: 'InstinctIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
};

export type InstinctIntegrationMinAggregateOutputType = {
  __typename?: 'InstinctIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
};

export enum InstinctIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  Username = 'username',
  Password = 'password',
  Subdomain = 'subdomain',
  LocationId = 'locationId',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
}

export type InstinctIntegrationOrderByRelevanceInput = {
  fields: Array<InstinctIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InstinctIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  _count?: Maybe<InstinctIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<InstinctIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<InstinctIntegrationMinOrderByAggregateInput>;
};

export type InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<InstinctIntegrationOrderByRelevanceInput>;
};

export type InstinctIntegrationRelationFilter = {
  is?: Maybe<InstinctIntegrationWhereInput>;
  isNot?: Maybe<InstinctIntegrationWhereInput>;
};

export enum InstinctIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Username = 'username',
  Password = 'password',
  Subdomain = 'subdomain',
  LocationId = 'locationId',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
}

export type InstinctIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  username?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  subdomain?: Maybe<StringWithAggregatesFilter>;
  locationId?: Maybe<StringNullableWithAggregatesFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type InstinctIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutInstinctInput>;
};

export type InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutInstinctInput>;
};

export type InstinctIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutInstinctInput>;
};

export type InstinctIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<InstinctIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InstinctIntegrationWhereUniqueInput>;
  update?: Maybe<InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type InstinctIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type InstinctIntegrationWhereInput = {
  AND?: Maybe<Array<InstinctIntegrationWhereInput>>;
  OR?: Maybe<Array<InstinctIntegrationWhereInput>>;
  NOT?: Maybe<Array<InstinctIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  subdomain?: Maybe<StringFilter>;
  locationId?: Maybe<StringNullableFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type InstinctIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type IntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type IntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export enum IntegrationType {
  Neo = 'NEO',
  Ezyvet = 'EZYVET',
  Vetdata = 'VETDATA',
  Instinct = 'INSTINCT',
  Vetter = 'VETTER',
  PetWidget = 'PET_WIDGET',
  ClinicWebApp = 'CLINIC_WEB_APP',
  Internal = 'INTERNAL',
  SalesforceWebhook = 'SALESFORCE_WEBHOOK',
  DocupostWebhook = 'DOCUPOST_WEBHOOK',
  VideoPluginWebApp = 'VIDEO_PLUGIN_WEB_APP',
}

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes: Scalars['Int'];
  total: Scalars['Int'];
  status: InvoiceStatus;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance: Scalars['Int'];
  currency: Currency;
  /** The clinic readable identifier for this invoice, e.g. number */
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParent;
  sentBy?: Maybe<User>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  lineItems: Array<InvoiceLineItem>;
  pimsInvoice?: Maybe<PimsInvoice>;
  relatedInvoiceWritebacks: Array<InvoicePimsWritebackLogEntry>;
  associatedCareBenefitCredits: Array<CareBenefitUsageToInvoiceLineItem>;
  _count: InvoiceCountOutputType;
};

export type InvoiceStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};

export type InvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};

export type InvoiceRelatedInvoiceWritebacksArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>;
};

export type InvoiceAssociatedCareBenefitCreditsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};

export type InvoiceAvgAggregateOutputType = {
  __typename?: 'InvoiceAvgAggregateOutputType';
  subtotal?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  outstandingBalance?: Maybe<Scalars['Float']>;
};

export type InvoiceAvgOrderByAggregateInput = {
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
};

export type InvoiceCountAggregateOutputType = {
  __typename?: 'InvoiceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  subtotal: Scalars['Int'];
  taxes: Scalars['Int'];
  total: Scalars['Int'];
  status: Scalars['Int'];
  date: Scalars['Int'];
  outstandingBalance: Scalars['Int'];
  currency: Scalars['Int'];
  identifier: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  sentByUserId: Scalars['Int'];
  description: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InvoiceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InvoiceCountOutputType = {
  __typename?: 'InvoiceCountOutputType';
  stripePaymentIntents: Scalars['Int'];
  lineItems: Scalars['Int'];
  relatedInvoiceWritebacks: Scalars['Int'];
  associatedCareBenefitCredits: Scalars['Int'];
};

export type InvoiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceCreateManyClinicPetParentInputEnvelope = {
  data: Array<InvoiceCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceCreateManySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type InvoiceCreateManySentByInputEnvelope = {
  data: Array<InvoiceCreateManySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceCreateNestedManyWithoutSentByInput = {
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceCreateNestedManyWithoutStripePaymentIntentsInput = {
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutLineItemsInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutLineItemsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLineItemsInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutPimsInvoiceInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutPimsInvoiceInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutPimsInvoiceInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput;
};

export type InvoiceCreateOrConnectWithoutClinicPetParentInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutClinicPetParentInput;
};

export type InvoiceCreateOrConnectWithoutLineItemsInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutLineItemsInput;
};

export type InvoiceCreateOrConnectWithoutPimsInvoiceInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutPimsInvoiceInput;
};

export type InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput;
};

export type InvoiceCreateOrConnectWithoutSentByInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutSentByInput;
};

export type InvoiceCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type InvoiceCreateWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceCreateWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceCreateWithoutPimsInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceCreateWithoutRelatedInvoiceWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceCreateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invoiceId: Scalars['String'];
  invoice: Invoice;
  serviceId?: Maybe<Scalars['String']>;
  service?: Maybe<Service>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isVoided: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  /** The time field is the time the item was added (or service was rendered for PIMS-managed invoices) */
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  clinicPets: Array<ClinicPet>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  provider?: Maybe<ClinicEmployee>;
  enteredBy?: Maybe<ClinicEmployee>;
  connectedCareBenefits: Array<CareBenefitUsageToInvoiceLineItem>;
  _count: InvoiceLineItemCountOutputType;
};

export type InvoiceLineItemClinicPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type InvoiceLineItemConnectedCareBenefitsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};

export type InvoiceLineItemAvgAggregateOutputType = {
  __typename?: 'InvoiceLineItemAvgAggregateOutputType';
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  lineNumber?: Maybe<Scalars['Float']>;
};

export type InvoiceLineItemAvgOrderByAggregateInput = {
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
};

export type InvoiceLineItemCountAggregateOutputType = {
  __typename?: 'InvoiceLineItemCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  invoiceId: Scalars['Int'];
  serviceId: Scalars['Int'];
  description: Scalars['Int'];
  type: Scalars['Int'];
  quantity: Scalars['Int'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable: Scalars['Int'];
  isHidden: Scalars['Int'];
  isVoided: Scalars['Int'];
  isDeleted: Scalars['Int'];
  time: Scalars['Int'];
  providerId: Scalars['Int'];
  enteredById: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InvoiceLineItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
};

export type InvoiceLineItemCountOutputType = {
  __typename?: 'InvoiceLineItemCountOutputType';
  clinicPets: Scalars['Int'];
  connectedCareBenefits: Scalars['Int'];
};

export type InvoiceLineItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemCreateManyEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyEnteredByInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyEnteredByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyInvoiceInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyProviderInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyProviderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemCreateManyServiceInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateNestedManyWithoutClinicPetsInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutEnteredByInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutProviderInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
};

export type InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
};

export type InvoiceLineItemCreateOrConnectWithoutClinicPetsInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutClinicPetsInput;
};

export type InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput;
};

export type InvoiceLineItemCreateOrConnectWithoutEnteredByInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutEnteredByInput;
};

export type InvoiceLineItemCreateOrConnectWithoutInvoiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutInvoiceInput;
};

export type InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput;
};

export type InvoiceLineItemCreateOrConnectWithoutProviderInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutProviderInput;
};

export type InvoiceLineItemCreateOrConnectWithoutServiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemUncheckedCreateWithoutServiceInput;
};

export type InvoiceLineItemCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
};

export type InvoiceLineItemCreateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutPimsInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemListRelationFilter = {
  every?: Maybe<InvoiceLineItemWhereInput>;
  some?: Maybe<InvoiceLineItemWhereInput>;
  none?: Maybe<InvoiceLineItemWhereInput>;
};

export type InvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'InvoiceLineItemMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
};

export type InvoiceLineItemMinAggregateOutputType = {
  __typename?: 'InvoiceLineItemMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
};

export type InvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoiceLineItemOrderByRelevanceFieldEnum {
  Id = 'id',
  InvoiceId = 'invoiceId',
  ServiceId = 'serviceId',
  Description = 'description',
  ProviderId = 'providerId',
  EnteredById = 'enteredById',
}

export type InvoiceLineItemOrderByRelevanceInput = {
  fields: Array<InvoiceLineItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InvoiceLineItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  _count?: Maybe<InvoiceLineItemCountOrderByAggregateInput>;
  _avg?: Maybe<InvoiceLineItemAvgOrderByAggregateInput>;
  _max?: Maybe<InvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<InvoiceLineItemMinOrderByAggregateInput>;
  _sum?: Maybe<InvoiceLineItemSumOrderByAggregateInput>;
};

export type InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  serviceId?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  providerId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  provider?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  enteredBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  _relevance?: Maybe<InvoiceLineItemOrderByRelevanceInput>;
};

export type InvoiceLineItemRelationFilter = {
  is?: Maybe<InvoiceLineItemWhereInput>;
  isNot?: Maybe<InvoiceLineItemWhereInput>;
};

export enum InvoiceLineItemScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InvoiceId = 'invoiceId',
  ServiceId = 'serviceId',
  Description = 'description',
  Type = 'type',
  Quantity = 'quantity',
  Amount = 'amount',
  Discount = 'discount',
  Fee = 'fee',
  LineNumber = 'lineNumber',
  IsTaxable = 'isTaxable',
  IsHidden = 'isHidden',
  IsVoided = 'isVoided',
  IsDeleted = 'isDeleted',
  Time = 'time',
  ProviderId = 'providerId',
  EnteredById = 'enteredById',
}

export type InvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceId?: Maybe<StringFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeFilter>;
  quantity?: Maybe<FloatFilter>;
  amount?: Maybe<IntFilter>;
  discount?: Maybe<IntFilter>;
  fee?: Maybe<IntFilter>;
  lineNumber?: Maybe<IntFilter>;
  isTaxable?: Maybe<BoolFilter>;
  isHidden?: Maybe<BoolFilter>;
  isVoided?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  time?: Maybe<DateTimeNullableFilter>;
  providerId?: Maybe<StringNullableFilter>;
  enteredById?: Maybe<StringNullableFilter>;
};

export type InvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  serviceId?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeWithAggregatesFilter>;
  quantity?: Maybe<FloatWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  discount?: Maybe<IntWithAggregatesFilter>;
  fee?: Maybe<IntWithAggregatesFilter>;
  lineNumber?: Maybe<IntWithAggregatesFilter>;
  isTaxable?: Maybe<BoolWithAggregatesFilter>;
  isHidden?: Maybe<BoolWithAggregatesFilter>;
  isVoided?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  time?: Maybe<DateTimeNullableWithAggregatesFilter>;
  providerId?: Maybe<StringNullableWithAggregatesFilter>;
  enteredById?: Maybe<StringNullableWithAggregatesFilter>;
};

export type InvoiceLineItemSumAggregateOutputType = {
  __typename?: 'InvoiceLineItemSumAggregateOutputType';
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
};

export type InvoiceLineItemSumOrderByAggregateInput = {
  quantity?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
};

export enum InvoiceLineItemType {
  Adjustment = 'adjustment',
  Discount = 'discount',
  Code = 'code',
  Return = 'return',
  Payment = 'payment',
  Note = 'note',
  Fee = 'fee',
  Estimate = 'estimate',
  Writeoff = 'writeoff',
  Tax = 'tax',
}

export type InvoiceLineItemUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
};

export type InvoiceLineItemUncheckedCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedCreateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  description: Scalars['String'];
  type: InvoiceLineItemType;
  quantity: Scalars['Float'];
  amount: Scalars['Int'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  lineNumber: Scalars['Int'];
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutProviderInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutProviderInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutProviderInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemUncheckedUpdateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsInput>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsInput>;
  providerId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInvoiceLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutProviderInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutProviderInvoiceLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutServiceInput = {
  where: InvoiceLineItemScalarWhereInput;
  data: InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput;
};

export type InvoiceLineItemUpdateManyWithoutClinicPetsInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateManyWithoutEnteredByInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateManyWithoutProviderInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutProviderInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
};

export type InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput>;
  upsert?: Maybe<InvoiceLineItemUpsertWithoutPimsInvoiceLineItemInput>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  update?: Maybe<InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsInput = {
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput>;
  upsert?: Maybe<InvoiceLineItemUpsertWithoutConnectedCareBenefitsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  update?: Maybe<InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput>;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutEnteredByInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutInvoiceInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutProviderInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUncheckedUpdateWithoutServiceInput;
};

export type InvoiceLineItemUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateWithoutConnectedCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsInput>;
};

export type InvoiceLineItemUpdateWithoutEnteredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateWithoutPimsInvoiceLineItemInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateWithoutProviderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsInput>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceLineItemType>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemInput>;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput;
  create: InvoiceLineItemUncheckedCreateWithoutClinicPetsInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutEnteredByInput;
  create: InvoiceLineItemUncheckedCreateWithoutEnteredByInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutInvoiceInput;
  create: InvoiceLineItemUncheckedCreateWithoutInvoiceInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutProviderInput;
  create: InvoiceLineItemUncheckedCreateWithoutProviderInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput = {
  where: InvoiceLineItemWhereUniqueInput;
  update: InvoiceLineItemUncheckedUpdateWithoutServiceInput;
  create: InvoiceLineItemUncheckedCreateWithoutServiceInput;
};

export type InvoiceLineItemUpsertWithoutConnectedCareBenefitsInput = {
  update: InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput;
  create: InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput;
};

export type InvoiceLineItemUpsertWithoutPimsInvoiceLineItemInput = {
  update: InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput;
  create: InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput;
};

export type InvoiceLineItemWhereInput = {
  AND?: Maybe<Array<InvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<InvoiceLineItemWhereInput>>;
  NOT?: Maybe<Array<InvoiceLineItemWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceId?: Maybe<StringFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  serviceId?: Maybe<StringNullableFilter>;
  service?: Maybe<ServiceWhereInput>;
  description?: Maybe<StringFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeFilter>;
  quantity?: Maybe<FloatFilter>;
  amount?: Maybe<IntFilter>;
  discount?: Maybe<IntFilter>;
  fee?: Maybe<IntFilter>;
  lineNumber?: Maybe<IntFilter>;
  isTaxable?: Maybe<BoolFilter>;
  isHidden?: Maybe<BoolFilter>;
  isVoided?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  time?: Maybe<DateTimeNullableFilter>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemWhereInput>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  providerId?: Maybe<StringNullableFilter>;
  enteredById?: Maybe<StringNullableFilter>;
  provider?: Maybe<ClinicEmployeeWhereInput>;
  enteredBy?: Maybe<ClinicEmployeeWhereInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
};

export type InvoiceLineItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvoiceListRelationFilter = {
  every?: Maybe<InvoiceWhereInput>;
  some?: Maybe<InvoiceWhereInput>;
  none?: Maybe<InvoiceWhereInput>;
};

export type InvoiceMaxAggregateOutputType = {
  __typename?: 'InvoiceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InvoiceMinAggregateOutputType = {
  __typename?: 'InvoiceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
};

export type InvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoiceOrderByRelevanceFieldEnum {
  Id = 'id',
  Identifier = 'identifier',
  ClinicPetParentId = 'clinicPetParentId',
  SentByUserId = 'sentByUserId',
  Description = 'description',
}

export type InvoiceOrderByRelevanceInput = {
  fields: Array<InvoiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InvoiceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  _count?: Maybe<InvoiceCountOrderByAggregateInput>;
  _avg?: Maybe<InvoiceAvgOrderByAggregateInput>;
  _max?: Maybe<InvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<InvoiceMinOrderByAggregateInput>;
  _sum?: Maybe<InvoiceSumOrderByAggregateInput>;
};

export type InvoiceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  sentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  lineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  pimsInvoice?: Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryOrderByRelationAggregateInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  _relevance?: Maybe<InvoiceOrderByRelevanceInput>;
};

export enum InvoicePimsWritebackAction {
  PaymentCollected = 'Payment_Collected',
  CareDiscountApplied = 'Care_Discount_Applied',
}

export type InvoicePimsWritebackLogEntry = {
  __typename?: 'InvoicePimsWritebackLogEntry';
  writebackLogId: Scalars['String'];
  writebackLog: PimsWritebackLogEntry;
  invoiceId: Scalars['String'];
  invoice: Invoice;
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryCountAggregateOutputType';
  writebackLogId: Scalars['Int'];
  invoiceId: Scalars['Int'];
  action: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InvoicePimsWritebackLogEntryCountOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryCreateInput = {
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput;
  invoice: InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput;
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryCreateManyInput = {
  writebackLogId: Scalars['String'];
  invoiceId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryCreateManyInvoiceInput = {
  writebackLogId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope = {
  data: Array<InvoicePimsWritebackLogEntryCreateManyInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
};

export type InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
};

export type InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput;
};

export type InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput = {
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput;
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  invoice: InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput;
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  some?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  none?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export type InvoicePimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryMaxAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryMaxOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryMinAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryMinOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoicePimsWritebackLogEntryOrderByRelevanceFieldEnum {
  WritebackLogId = 'writebackLogId',
  InvoiceId = 'invoiceId',
}

export type InvoicePimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<InvoicePimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type InvoicePimsWritebackLogEntryOrderByWithAggregationInput = {
  writebackLogId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  _count?: Maybe<InvoicePimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<InvoicePimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<InvoicePimsWritebackLogEntryMinOrderByAggregateInput>;
};

export type InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  writebackLogId?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  invoiceId?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  action?: Maybe<SortOrder>;
  _relevance?: Maybe<InvoicePimsWritebackLogEntryOrderByRelevanceInput>;
};

export type InvoicePimsWritebackLogEntryRelationFilter = {
  is?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export enum InvoicePimsWritebackLogEntryScalarFieldEnum {
  WritebackLogId = 'writebackLogId',
  InvoiceId = 'invoiceId',
  Action = 'action',
}

export type InvoicePimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  invoiceId?: Maybe<StringFilter>;
  action?: Maybe<EnumInvoicePimsWritebackActionFilter>;
};

export type InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  action?: Maybe<EnumInvoicePimsWritebackActionWithAggregatesFilter>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateInput = {
  writebackLogId: Scalars['String'];
  invoiceId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput = {
  writebackLogId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  invoiceId: Scalars['String'];
  action: InvoicePimsWritebackAction;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutRelatedInvoiceWritebacksInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput = {
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  invoiceId?: Maybe<Scalars['String']>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUpdateInput = {
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogInput>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksInput>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUpdateManyMutationInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput = {
  where: InvoicePimsWritebackLogEntryScalarWhereInput;
  data: InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutRelatedInvoiceWritebacksInput;
};

export type InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceInput = {
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
};

export type InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogInput = {
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  data: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput;
};

export type InvoicePimsWritebackLogEntryUpdateWithoutInvoiceInput = {
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogInput>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksInput>;
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  update: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput;
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput;
};

export type InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  update: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type InvoicePimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  invoiceId?: Maybe<StringFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  action?: Maybe<EnumInvoicePimsWritebackActionFilter>;
};

export type InvoicePimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_invoiceId?: Maybe<InvoicePimsWritebackLogEntryWritebackLogIdInvoiceIdCompoundUniqueInput>;
};

export type InvoicePimsWritebackLogEntryWritebackLogIdInvoiceIdCompoundUniqueInput = {
  writebackLogId: Scalars['String'];
  invoiceId: Scalars['String'];
};

export type InvoiceRelationFilter = {
  is?: Maybe<InvoiceWhereInput>;
  isNot?: Maybe<InvoiceWhereInput>;
};

export enum InvoiceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  Subtotal = 'subtotal',
  Taxes = 'taxes',
  Total = 'total',
  Status = 'status',
  Date = 'date',
  OutstandingBalance = 'outstandingBalance',
  Currency = 'currency',
  Identifier = 'identifier',
  ClinicPetParentId = 'clinicPetParentId',
  SentByUserId = 'sentByUserId',
  Description = 'description',
}

export type InvoiceScalarWhereInput = {
  AND?: Maybe<Array<InvoiceScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<InvoiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  subtotal?: Maybe<IntFilter>;
  taxes?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  status?: Maybe<EnumInvoiceStatusFilter>;
  date?: Maybe<DateTimeNullableFilter>;
  outstandingBalance?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  identifier?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
};

export type InvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  subtotal?: Maybe<IntWithAggregatesFilter>;
  taxes?: Maybe<IntWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumInvoiceStatusWithAggregatesFilter>;
  date?: Maybe<DateTimeNullableWithAggregatesFilter>;
  outstandingBalance?: Maybe<IntWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  identifier?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  sentByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum InvoiceStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export type InvoiceSumAggregateOutputType = {
  __typename?: 'InvoiceSumAggregateOutputType';
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
};

export type InvoiceSumOrderByAggregateInput = {
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
};

export type InvoiceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceUncheckedCreateNestedManyWithoutSentByInput = {
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput = {
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedCreateWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedCreateWithoutPimsInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedCreateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUncheckedUpdateManyWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceUncheckedUpdateManyWithoutSentByInput = {
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutSentByInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUncheckedUpdateManyWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput = {
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceInput>;
};

export type InvoiceUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedUpdateWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedUpdateWithoutPimsInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedUpdateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  sentByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceInput
  >;
};

export type InvoiceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InvoiceUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type InvoiceUpdateManyWithWhereWithoutSentByInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutSentInvoicesInput;
};

export type InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type InvoiceUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUpdateManyWithoutSentByInput = {
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutSentByInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUpdateManyWithoutStripePaymentIntentsInput = {
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type InvoiceUpdateOneRequiredWithoutLineItemsInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutLineItemsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLineItemsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutLineItemsInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutLineItemsInput>;
};

export type InvoiceUpdateOneRequiredWithoutPimsInvoiceInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutPimsInvoiceInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutPimsInvoiceInput>;
  upsert?: Maybe<InvoiceUpsertWithoutPimsInvoiceInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutPimsInvoiceInput>;
};

export type InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput>;
  upsert?: Maybe<InvoiceUpsertWithoutRelatedInvoiceWritebacksInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput>;
};

export type InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsInput = {
  create?: Maybe<InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutAssociatedCareBenefitCreditsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput>;
};

export type InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutClinicPetParentInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutSentByInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutSentByInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput;
};

export type InvoiceUpdateWithoutAssociatedCareBenefitCreditsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceInput>;
};

export type InvoiceUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceUpdateWithoutLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceUpdateWithoutPimsInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceUpdateWithoutRelatedInvoiceWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceUpdateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  date?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesInput>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceInput>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceInput>;
};

export type InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutClinicPetParentInput;
  create: InvoiceUncheckedCreateWithoutClinicPetParentInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutSentByInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutSentByInput;
  create: InvoiceUncheckedCreateWithoutSentByInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: InvoiceUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type InvoiceUpsertWithoutAssociatedCareBenefitCreditsInput = {
  update: InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput;
  create: InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput;
};

export type InvoiceUpsertWithoutLineItemsInput = {
  update: InvoiceUncheckedUpdateWithoutLineItemsInput;
  create: InvoiceUncheckedCreateWithoutLineItemsInput;
};

export type InvoiceUpsertWithoutPimsInvoiceInput = {
  update: InvoiceUncheckedUpdateWithoutPimsInvoiceInput;
  create: InvoiceUncheckedCreateWithoutPimsInvoiceInput;
};

export type InvoiceUpsertWithoutRelatedInvoiceWritebacksInput = {
  update: InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput;
  create: InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput;
};

export type InvoiceWhereInput = {
  AND?: Maybe<Array<InvoiceWhereInput>>;
  OR?: Maybe<Array<InvoiceWhereInput>>;
  NOT?: Maybe<Array<InvoiceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  subtotal?: Maybe<IntFilter>;
  taxes?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  status?: Maybe<EnumInvoiceStatusFilter>;
  date?: Maybe<DateTimeNullableFilter>;
  outstandingBalance?: Maybe<IntFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  identifier?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  sentBy?: Maybe<UserWhereInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  lineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  pimsInvoice?: Maybe<PimsInvoiceWhereInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryListRelationFilter>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type JsonFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export enum JsonNullValueFilter {
  DbNull = 'DbNull',
  JsonNull = 'JsonNull',
  AnyNull = 'AnyNull',
}

export enum JsonNullValueInput {
  JsonNull = 'JsonNull',
}

export type JsonNullableFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type JsonNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedJsonNullableFilter>;
  _max?: Maybe<NestedJsonNullableFilter>;
};

export type JsonWithAggregatesFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedJsonFilter>;
  _max?: Maybe<NestedJsonFilter>;
};

export type LoginLinkOutput = {
  __typename?: 'LoginLinkOutput';
  url: Scalars['String'];
};

export type MassTextAlert = {
  __typename?: 'MassTextAlert';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  createdByUser: User;
  clinicId: Scalars['String'];
  clinic: Clinic;
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries: Array<MassTextAlertEntry>;
  _count: MassTextAlertCountOutputType;
};

export type MassTextAlertMassTextAlertEntriesArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertEntryScalarFieldEnum>;
};

export type MassTextAlertCountAggregateOutputType = {
  __typename?: 'MassTextAlertCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  appointmentDateTimeFrom: Scalars['Int'];
  appointmentDateTimeTo: Scalars['Int'];
  createdByUserId: Scalars['Int'];
  clinicId: Scalars['Int'];
  alertBody: Scalars['Int'];
  status: Scalars['Int'];
  appointmentDateRangeType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MassTextAlertCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
};

export type MassTextAlertCountOutputType = {
  __typename?: 'MassTextAlertCountOutputType';
  massTextAlertEntries: Scalars['Int'];
};

export type MassTextAlertCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertCreateManyClinicInputEnvelope = {
  data: Array<MassTextAlertCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertCreateManyCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertCreateManyCreatedByUserInputEnvelope = {
  data: Array<MassTextAlertCreateManyCreatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
};

export type MassTextAlertCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
};

export type MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput = {
  create?: Maybe<MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput>;
  connectOrCreate?: Maybe<MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput>;
  connect?: Maybe<MassTextAlertWhereUniqueInput>;
};

export type MassTextAlertCreateOrConnectWithoutClinicInput = {
  where: MassTextAlertWhereUniqueInput;
  create: MassTextAlertUncheckedCreateWithoutClinicInput;
};

export type MassTextAlertCreateOrConnectWithoutCreatedByUserInput = {
  where: MassTextAlertWhereUniqueInput;
  create: MassTextAlertUncheckedCreateWithoutCreatedByUserInput;
};

export type MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput = {
  where: MassTextAlertWhereUniqueInput;
  create: MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertCreateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertEntry = {
  __typename?: 'MassTextAlertEntry';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  massTextAlertId: Scalars['String'];
  massTextAlert: MassTextAlert;
  clinicPetParentId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments: Array<MassTextAlertEntryAppointment>;
  _count: MassTextAlertEntryCountOutputType;
};

export type MassTextAlertEntryMassAlertEntryAppointmentsArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>;
};

export type MassTextAlertEntryAppointment = {
  __typename?: 'MassTextAlertEntryAppointment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  massTextAlertEntryId: Scalars['String'];
  massTextAlertEntry: MassTextAlertEntry;
  appointmentId: Scalars['String'];
  appointment: Appointment;
};

export type MassTextAlertEntryAppointmentCountAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  massTextAlertEntryId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MassTextAlertEntryAppointmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntry: MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput;
  appointment: AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope = {
  data: Array<MassTextAlertEntryAppointmentCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryAppointmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId: Scalars['String'];
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope = {
  data: Array<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
};

export type MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
};

export type MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput;
};

export type MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput;
};

export type MassTextAlertEntryAppointmentCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntry: MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointment: AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentListRelationFilter = {
  every?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  some?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  none?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};

export type MassTextAlertEntryAppointmentMassTextAlertEntryIdAppointmentIdCompoundUniqueInput = {
  massTextAlertEntryId: Scalars['String'];
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentMaxAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentMinAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertEntryAppointmentOrderByRelevanceFieldEnum {
  Id = 'id',
  MassTextAlertEntryId = 'massTextAlertEntryId',
  AppointmentId = 'appointmentId',
}

export type MassTextAlertEntryAppointmentOrderByRelevanceInput = {
  fields: Array<MassTextAlertEntryAppointmentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MassTextAlertEntryAppointmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  _count?: Maybe<MassTextAlertEntryAppointmentCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertEntryAppointmentMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertEntryAppointmentMinOrderByAggregateInput>;
};

export type MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  appointmentId?: Maybe<SortOrder>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<MassTextAlertEntryAppointmentOrderByRelevanceInput>;
};

export enum MassTextAlertEntryAppointmentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MassTextAlertEntryId = 'massTextAlertEntryId',
  AppointmentId = 'appointmentId',
}

export type MassTextAlertEntryAppointmentScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  massTextAlertEntryId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringFilter>;
};

export type MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  massTextAlertEntryId?: Maybe<StringWithAggregatesFilter>;
  appointmentId?: Maybe<StringWithAggregatesFilter>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId: Scalars['String'];
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryAppointmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsInput>;
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsInput>;
};

export type MassTextAlertEntryAppointmentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput = {
  where: MassTextAlertEntryAppointmentScalarWhereInput;
  data: MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput = {
  where: MassTextAlertEntryAppointmentScalarWhereInput;
  data: MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
};

export type MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryInput = {
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
};

export type MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  data: MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput;
};

export type MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  data: MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput;
};

export type MassTextAlertEntryAppointmentUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsInput>;
};

export type MassTextAlertEntryAppointmentUpdateWithoutMassTextAlertEntryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsInput>;
};

export type MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  update: MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput;
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput;
};

export type MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  update: MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput;
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput;
};

export type MassTextAlertEntryAppointmentWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  massTextAlertEntryId?: Maybe<StringFilter>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryWhereInput>;
  appointmentId?: Maybe<StringFilter>;
  appointment?: Maybe<AppointmentWhereInput>;
};

export type MassTextAlertEntryAppointmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId_appointmentId?: Maybe<
    MassTextAlertEntryAppointmentMassTextAlertEntryIdAppointmentIdCompoundUniqueInput
  >;
};

export type MassTextAlertEntryCountAggregateOutputType = {
  __typename?: 'MassTextAlertEntryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  massTextAlertId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MassTextAlertEntryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
};

export type MassTextAlertEntryCountOutputType = {
  __typename?: 'MassTextAlertEntryCountOutputType';
  massAlertEntryAppointments: Scalars['Int'];
};

export type MassTextAlertEntryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryCreateManyClinicPetParentInputEnvelope = {
  data: Array<MassTextAlertEntryCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryCreateManyMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryCreateManyMassTextAlertInputEnvelope = {
  data: Array<MassTextAlertEntryCreateManyMassTextAlertInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
};

export type MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
};

export type MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput = {
  create?: Maybe<MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput>;
  connectOrCreate?: Maybe<MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput>;
  connect?: Maybe<MassTextAlertEntryWhereUniqueInput>;
};

export type MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  create: MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput;
};

export type MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  create: MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  create: MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput;
};

export type MassTextAlertEntryCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryCreateWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryCreateWithoutMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryListRelationFilter = {
  every?: Maybe<MassTextAlertEntryWhereInput>;
  some?: Maybe<MassTextAlertEntryWhereInput>;
  none?: Maybe<MassTextAlertEntryWhereInput>;
};

export type MassTextAlertEntryMaxAggregateOutputType = {
  __typename?: 'MassTextAlertEntryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
};

export type MassTextAlertEntryMinAggregateOutputType = {
  __typename?: 'MassTextAlertEntryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
};

export type MassTextAlertEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  MassTextAlertId = 'massTextAlertId',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumber = 'phoneNumber',
}

export type MassTextAlertEntryOrderByRelevanceInput = {
  fields: Array<MassTextAlertEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MassTextAlertEntryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  _count?: Maybe<MassTextAlertEntryCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertEntryMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertEntryMinOrderByAggregateInput>;
};

export type MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  massTextAlert?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  phoneNumber?: Maybe<SortOrder>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentOrderByRelationAggregateInput>;
  _relevance?: Maybe<MassTextAlertEntryOrderByRelevanceInput>;
};

export type MassTextAlertEntryRelationFilter = {
  is?: Maybe<MassTextAlertEntryWhereInput>;
  isNot?: Maybe<MassTextAlertEntryWhereInput>;
};

export enum MassTextAlertEntryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  MassTextAlertId = 'massTextAlertId',
  ClinicPetParentId = 'clinicPetParentId',
  PhoneNumber = 'phoneNumber',
}

export type MassTextAlertEntryScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  massTextAlertId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
};

export type MassTextAlertEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  massTextAlertId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
};

export type MassTextAlertEntryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<
    MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput
  >;
};

export type MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
};

export type MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
};

export type MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<
    MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput
  >;
};

export type MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId: Scalars['String'];
  phoneNumber: Scalars['String'];
  massAlertEntryAppointments?: Maybe<
    MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput
  >;
};

export type MassTextAlertEntryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: MassTextAlertEntryScalarWhereInput;
  data: MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput = {
  where: MassTextAlertEntryScalarWhereInput;
  data: MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertEntryUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
};

export type MassTextAlertEntryUpdateManyWithoutMassTextAlertInput = {
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
};

export type MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsInput = {
  create?: Maybe<MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput>;
  connectOrCreate?: Maybe<MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput>;
  upsert?: Maybe<MassTextAlertEntryUpsertWithoutMassAlertEntryAppointmentsInput>;
  connect?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  update?: Maybe<MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput>;
};

export type MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  data: MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput;
};

export type MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  data: MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput;
};

export type MassTextAlertEntryUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUpdateWithoutMassAlertEntryAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesInput>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MassTextAlertEntryUpdateWithoutMassTextAlertInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryInput>;
};

export type MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  update: MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput;
  create: MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput;
};

export type MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput = {
  where: MassTextAlertEntryWhereUniqueInput;
  update: MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput;
  create: MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput;
};

export type MassTextAlertEntryUpsertWithoutMassAlertEntryAppointmentsInput = {
  update: MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput;
  create: MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  massTextAlertId?: Maybe<StringFilter>;
  massTextAlert?: Maybe<MassTextAlertWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  phoneNumber?: Maybe<StringFilter>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentListRelationFilter>;
};

export type MassTextAlertEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MassTextAlertListRelationFilter = {
  every?: Maybe<MassTextAlertWhereInput>;
  some?: Maybe<MassTextAlertWhereInput>;
  none?: Maybe<MassTextAlertWhereInput>;
};

export type MassTextAlertMaxAggregateOutputType = {
  __typename?: 'MassTextAlertMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
};

export type MassTextAlertMinAggregateOutputType = {
  __typename?: 'MassTextAlertMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
};

export type MassTextAlertOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertOrderByRelevanceFieldEnum {
  Id = 'id',
  CreatedByUserId = 'createdByUserId',
  ClinicId = 'clinicId',
  AlertBody = 'alertBody',
}

export type MassTextAlertOrderByRelevanceInput = {
  fields: Array<MassTextAlertOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MassTextAlertOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  _count?: Maybe<MassTextAlertCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertMinOrderByAggregateInput>;
};

export type MassTextAlertOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  createdByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  alertBody?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryOrderByRelationAggregateInput>;
  _relevance?: Maybe<MassTextAlertOrderByRelevanceInput>;
};

export type MassTextAlertRelationFilter = {
  is?: Maybe<MassTextAlertWhereInput>;
  isNot?: Maybe<MassTextAlertWhereInput>;
};

export type MassTextAlertReportEntry = {
  __typename?: 'MassTextAlertReportEntry';
  phoneNumber: Scalars['String'];
  petParentFullName: Scalars['String'];
  smsStatus?: Maybe<SmsNotificationStatusType>;
  errorDetails?: Maybe<Scalars['String']>;
};

export enum MassTextAlertScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AppointmentDateTimeFrom = 'appointmentDateTimeFrom',
  AppointmentDateTimeTo = 'appointmentDateTimeTo',
  CreatedByUserId = 'createdByUserId',
  ClinicId = 'clinicId',
  AlertBody = 'alertBody',
  Status = 'status',
  AppointmentDateRangeType = 'appointmentDateRangeType',
}

export type MassTextAlertScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  alertBody?: Maybe<StringFilter>;
  status?: Maybe<EnumMassTextAlertStatusFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeFilter>;
};

export type MassTextAlertScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeWithAggregatesFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeWithAggregatesFilter>;
  createdByUserId?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  alertBody?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumMassTextAlertStatusWithAggregatesFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeWithAggregatesFilter>;
};

export enum MassTextAlertStatus {
  Processing = 'Processing',
  Sent = 'Sent',
}

export type MassTextAlertSubscription = {
  __typename?: 'MassTextAlertSubscription';
  node: MassTextAlert;
  mutation: MutationType;
};

export type MassTextAlertUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
};

export type MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
};

export type MassTextAlertUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  clinicId: Scalars['String'];
  alertBody: Scalars['String'];
  status: MassTextAlertStatus;
  appointmentDateRangeType: AppointmentDateRangeType;
};

export type MassTextAlertUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
};

export type MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
};

export type MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedUpdateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsInput>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUpdateManyWithWhereWithoutClinicInput = {
  where: MassTextAlertScalarWhereInput;
  data: MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput;
};

export type MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput = {
  where: MassTextAlertScalarWhereInput;
  data: MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput;
};

export type MassTextAlertUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
};

export type MassTextAlertUpdateManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
};

export type MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesInput = {
  create?: Maybe<MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput>;
  connectOrCreate?: Maybe<MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput>;
  upsert?: Maybe<MassTextAlertUpsertWithoutMassTextAlertEntriesInput>;
  connect?: Maybe<MassTextAlertWhereUniqueInput>;
  update?: Maybe<MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput>;
};

export type MassTextAlertUpdateWithWhereUniqueWithoutClinicInput = {
  where: MassTextAlertWhereUniqueInput;
  data: MassTextAlertUncheckedUpdateWithoutClinicInput;
};

export type MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  where: MassTextAlertWhereUniqueInput;
  data: MassTextAlertUncheckedUpdateWithoutCreatedByUserInput;
};

export type MassTextAlertUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsInput>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUpdateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsInput>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertInput>;
};

export type MassTextAlertUpdateWithoutMassTextAlertEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsInput>;
  alertBody?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
};

export type MassTextAlertUpsertWithWhereUniqueWithoutClinicInput = {
  where: MassTextAlertWhereUniqueInput;
  update: MassTextAlertUncheckedUpdateWithoutClinicInput;
  create: MassTextAlertUncheckedCreateWithoutClinicInput;
};

export type MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  where: MassTextAlertWhereUniqueInput;
  update: MassTextAlertUncheckedUpdateWithoutCreatedByUserInput;
  create: MassTextAlertUncheckedCreateWithoutCreatedByUserInput;
};

export type MassTextAlertUpsertWithoutMassTextAlertEntriesInput = {
  update: MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput;
  create: MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertWhereInput = {
  AND?: Maybe<Array<MassTextAlertWhereInput>>;
  OR?: Maybe<Array<MassTextAlertWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringFilter>;
  createdByUser?: Maybe<UserWhereInput>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  alertBody?: Maybe<StringFilter>;
  status?: Maybe<EnumMassTextAlertStatusFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeFilter>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryListRelationFilter>;
};

export type MassTextAlertWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MergePetParentInput = {
  clinicId: Scalars['String'];
  keepId: Scalars['String'];
  deleteId: Scalars['String'];
};

export type MergePetParentResult = {
  __typename?: 'MergePetParentResult';
  success: Scalars['Boolean'];
  messages?: Maybe<Array<Scalars['String']>>;
};

export enum MessageSource {
  Web = 'Web',
  Sms = 'Sms',
}

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  updatedById: Scalars['String'];
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments: Scalars['Json'];
  isArchived: Scalars['Boolean'];
  updatedBy: User;
  clinic: Clinic;
};

export type MessageTemplateCountAggregateOutputType = {
  __typename?: 'MessageTemplateCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  updatedById: Scalars['Int'];
  clinicId: Scalars['Int'];
  name: Scalars['Int'];
  body: Scalars['Int'];
  attachments: Scalars['Int'];
  isArchived: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MessageTemplateCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
};

export type MessageTemplateCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  updatedBy: UserCreateNestedOneWithoutMessageTemplatesInput;
  clinic: ClinicCreateNestedOneWithoutMessageTemplatesInput;
};

export type MessageTemplateCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyClinicInputEnvelope = {
  data: Array<MessageTemplateCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById: Scalars['String'];
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyUpdatedByInputEnvelope = {
  data: Array<MessageTemplateCreateManyUpdatedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
};

export type MessageTemplateCreateNestedManyWithoutUpdatedByInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
};

export type MessageTemplateCreateOrConnectWithoutClinicInput = {
  where: MessageTemplateWhereUniqueInput;
  create: MessageTemplateUncheckedCreateWithoutClinicInput;
};

export type MessageTemplateCreateOrConnectWithoutUpdatedByInput = {
  where: MessageTemplateWhereUniqueInput;
  create: MessageTemplateUncheckedCreateWithoutUpdatedByInput;
};

export type MessageTemplateCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  updatedBy: UserCreateNestedOneWithoutMessageTemplatesInput;
};

export type MessageTemplateCreateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  clinic: ClinicCreateNestedOneWithoutMessageTemplatesInput;
};

export type MessageTemplateListRelationFilter = {
  every?: Maybe<MessageTemplateWhereInput>;
  some?: Maybe<MessageTemplateWhereInput>;
  none?: Maybe<MessageTemplateWhereInput>;
};

export type MessageTemplateMaxAggregateOutputType = {
  __typename?: 'MessageTemplateMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
};

export type MessageTemplateMinAggregateOutputType = {
  __typename?: 'MessageTemplateMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
};

export type MessageTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MessageTemplateOrderByRelevanceFieldEnum {
  Id = 'id',
  UpdatedById = 'updatedById',
  ClinicId = 'clinicId',
  Name = 'name',
  Body = 'body',
}

export type MessageTemplateOrderByRelevanceInput = {
  fields: Array<MessageTemplateOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type MessageTemplateOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  _count?: Maybe<MessageTemplateCountOrderByAggregateInput>;
  _max?: Maybe<MessageTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<MessageTemplateMinOrderByAggregateInput>;
};

export type MessageTemplateOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  updatedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<MessageTemplateOrderByRelevanceInput>;
};

export enum MessageTemplateScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UpdatedById = 'updatedById',
  ClinicId = 'clinicId',
  Name = 'name',
  Body = 'body',
  Attachments = 'attachments',
  IsArchived = 'isArchived',
}

export type MessageTemplateScalarWhereInput = {
  AND?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  OR?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  updatedById?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  attachments?: Maybe<JsonFilter>;
  isArchived?: Maybe<BoolFilter>;
};

export type MessageTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedById?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  body?: Maybe<StringWithAggregatesFilter>;
  attachments?: Maybe<JsonWithAggregatesFilter>;
  isArchived?: Maybe<BoolWithAggregatesFilter>;
};

export type MessageTemplateUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById: Scalars['String'];
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
};

export type MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
};

export type MessageTemplateUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedCreateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  name: Scalars['String'];
  body: Scalars['String'];
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
};

export type MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
};

export type MessageTemplateUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUncheckedUpdateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserUpdateOneRequiredWithoutMessageTemplatesInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMessageTemplatesInput>;
};

export type MessageTemplateUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateUpdateManyWithWhereWithoutClinicInput = {
  where: MessageTemplateScalarWhereInput;
  data: MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput;
};

export type MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput = {
  where: MessageTemplateScalarWhereInput;
  data: MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput;
};

export type MessageTemplateUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
};

export type MessageTemplateUpdateManyWithoutUpdatedByInput = {
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
};

export type MessageTemplateUpdateWithWhereUniqueWithoutClinicInput = {
  where: MessageTemplateWhereUniqueInput;
  data: MessageTemplateUncheckedUpdateWithoutClinicInput;
};

export type MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput = {
  where: MessageTemplateWhereUniqueInput;
  data: MessageTemplateUncheckedUpdateWithoutUpdatedByInput;
};

export type MessageTemplateUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserUpdateOneRequiredWithoutMessageTemplatesInput>;
};

export type MessageTemplateUpdateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['Json']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMessageTemplatesInput>;
};

export type MessageTemplateUpsertWithWhereUniqueWithoutClinicInput = {
  where: MessageTemplateWhereUniqueInput;
  update: MessageTemplateUncheckedUpdateWithoutClinicInput;
  create: MessageTemplateUncheckedCreateWithoutClinicInput;
};

export type MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput = {
  where: MessageTemplateWhereUniqueInput;
  update: MessageTemplateUncheckedUpdateWithoutUpdatedByInput;
  create: MessageTemplateUncheckedCreateWithoutUpdatedByInput;
};

export type MessageTemplateWhereInput = {
  AND?: Maybe<Array<MessageTemplateWhereInput>>;
  OR?: Maybe<Array<MessageTemplateWhereInput>>;
  NOT?: Maybe<Array<MessageTemplateWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  updatedById?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  attachments?: Maybe<JsonFilter>;
  isArchived?: Maybe<BoolFilter>;
  updatedBy?: Maybe<UserWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type MessageTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum MessageType {
  Message = 'Message',
  Note = 'Note',
}

export type Mutation = {
  __typename?: 'Mutation';
  createOneAppointment: Appointment;
  updateOneAppointment: Appointment;
  deleteOneAppointment?: Maybe<Appointment>;
  upsertOneAppointment?: Maybe<Appointment>;
  deleteManyAppointment?: Maybe<BatchPayload>;
  updateManyAppointment?: Maybe<BatchPayload>;
  createOneAppointmentType: AppointmentType;
  updateOneAppointmentType: AppointmentType;
  deleteOneAppointmentType?: Maybe<AppointmentType>;
  upsertOneAppointmentType?: Maybe<AppointmentType>;
  deleteManyAppointmentType?: Maybe<BatchPayload>;
  updateManyAppointmentType?: Maybe<BatchPayload>;
  createOneBitwerxIntegration: BitwerxIntegration;
  updateOneBitwerxIntegration: BitwerxIntegration;
  deleteOneBitwerxIntegration?: Maybe<BitwerxIntegration>;
  upsertOneBitwerxIntegration?: Maybe<BitwerxIntegration>;
  deleteManyBitwerxIntegration?: Maybe<BatchPayload>;
  updateManyBitwerxIntegration?: Maybe<BatchPayload>;
  createOneChannel: Channel;
  updateOneChannel: Channel;
  deleteOneChannel?: Maybe<Channel>;
  upsertOneChannel?: Maybe<Channel>;
  deleteManyChannel?: Maybe<BatchPayload>;
  updateManyChannel?: Maybe<BatchPayload>;
  createOneChannelMember: ChannelMember;
  updateOneChannelMember: ChannelMember;
  deleteOneChannelMember?: Maybe<ChannelMember>;
  upsertOneChannelMember?: Maybe<ChannelMember>;
  deleteManyChannelMember?: Maybe<BatchPayload>;
  updateManyChannelMember?: Maybe<BatchPayload>;
  createOneChannelMessage: ChannelMessage;
  updateOneChannelMessage: ChannelMessage;
  deleteOneChannelMessage?: Maybe<ChannelMessage>;
  upsertOneChannelMessage?: Maybe<ChannelMessage>;
  deleteManyChannelMessage?: Maybe<BatchPayload>;
  updateManyChannelMessage?: Maybe<BatchPayload>;
  createOneChannelMessageAttachment: ChannelMessageAttachment;
  updateOneChannelMessageAttachment: ChannelMessageAttachment;
  deleteOneChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  upsertOneChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  deleteManyChannelMessageAttachment?: Maybe<BatchPayload>;
  updateManyChannelMessageAttachment?: Maybe<BatchPayload>;
  createOneChannelStatus: ChannelStatus;
  updateOneChannelStatus: ChannelStatus;
  deleteOneChannelStatus?: Maybe<ChannelStatus>;
  upsertOneChannelStatus?: Maybe<ChannelStatus>;
  deleteManyChannelStatus?: Maybe<BatchPayload>;
  updateManyChannelStatus?: Maybe<BatchPayload>;
  createOneChannelStatusChannelAssignee: ChannelStatusChannelAssignee;
  updateOneChannelStatusChannelAssignee: ChannelStatusChannelAssignee;
  deleteOneChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  upsertOneChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  deleteManyChannelStatusChannelAssignee?: Maybe<BatchPayload>;
  updateManyChannelStatusChannelAssignee?: Maybe<BatchPayload>;
  createOneClinic: Clinic;
  updateOneClinic: Clinic;
  deleteOneClinic?: Maybe<Clinic>;
  upsertOneClinic?: Maybe<Clinic>;
  deleteManyClinic?: Maybe<BatchPayload>;
  updateManyClinic?: Maybe<BatchPayload>;
  createOneClinicEmployee: ClinicEmployee;
  updateOneClinicEmployee: ClinicEmployee;
  deleteOneClinicEmployee?: Maybe<ClinicEmployee>;
  upsertOneClinicEmployee?: Maybe<ClinicEmployee>;
  deleteManyClinicEmployee?: Maybe<BatchPayload>;
  updateManyClinicEmployee?: Maybe<BatchPayload>;
  createOneClinicPaymentFeeConfiguration: ClinicPaymentFeeConfiguration;
  updateOneClinicPaymentFeeConfiguration: ClinicPaymentFeeConfiguration;
  deleteOneClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  upsertOneClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  deleteManyClinicPaymentFeeConfiguration?: Maybe<BatchPayload>;
  updateManyClinicPaymentFeeConfiguration?: Maybe<BatchPayload>;
  createOneClinicPet: ClinicPet;
  updateOneClinicPet: ClinicPet;
  deleteOneClinicPet?: Maybe<ClinicPet>;
  upsertOneClinicPet?: Maybe<ClinicPet>;
  deleteManyClinicPet?: Maybe<BatchPayload>;
  updateManyClinicPet?: Maybe<BatchPayload>;
  createOneClinicPetAlert: ClinicPetAlert;
  updateOneClinicPetAlert: ClinicPetAlert;
  deleteOneClinicPetAlert?: Maybe<ClinicPetAlert>;
  upsertOneClinicPetAlert?: Maybe<ClinicPetAlert>;
  deleteManyClinicPetAlert?: Maybe<BatchPayload>;
  updateManyClinicPetAlert?: Maybe<BatchPayload>;
  createOneClinicPetParent: ClinicPetParent;
  updateOneClinicPetParent: ClinicPetParent;
  deleteOneClinicPetParent?: Maybe<ClinicPetParent>;
  upsertOneClinicPetParent?: Maybe<ClinicPetParent>;
  deleteManyClinicPetParent?: Maybe<BatchPayload>;
  updateManyClinicPetParent?: Maybe<BatchPayload>;
  createOneClinicPimsIntegration: ClinicPimsIntegration;
  updateOneClinicPimsIntegration: ClinicPimsIntegration;
  deleteOneClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  upsertOneClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  deleteManyClinicPimsIntegration?: Maybe<BatchPayload>;
  updateManyClinicPimsIntegration?: Maybe<BatchPayload>;
  createOneClinicPimsIntegrationCapability: ClinicPimsIntegrationCapability;
  updateOneClinicPimsIntegrationCapability: ClinicPimsIntegrationCapability;
  deleteOneClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  upsertOneClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  deleteManyClinicPimsIntegrationCapability?: Maybe<BatchPayload>;
  updateManyClinicPimsIntegrationCapability?: Maybe<BatchPayload>;
  createOneClinicRoom: ClinicRoom;
  updateOneClinicRoom: ClinicRoom;
  deleteOneClinicRoom?: Maybe<ClinicRoom>;
  upsertOneClinicRoom?: Maybe<ClinicRoom>;
  deleteManyClinicRoom?: Maybe<BatchPayload>;
  updateManyClinicRoom?: Maybe<BatchPayload>;
  createOneClinicSetting: ClinicSetting;
  updateOneClinicSetting: ClinicSetting;
  deleteOneClinicSetting?: Maybe<ClinicSetting>;
  upsertOneClinicSetting?: Maybe<ClinicSetting>;
  deleteManyClinicSetting?: Maybe<BatchPayload>;
  updateManyClinicSetting?: Maybe<BatchPayload>;
  createOneClinicWidgetRequest: ClinicWidgetRequest;
  updateOneClinicWidgetRequest: ClinicWidgetRequest;
  deleteOneClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  upsertOneClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  deleteManyClinicWidgetRequest?: Maybe<BatchPayload>;
  updateManyClinicWidgetRequest?: Maybe<BatchPayload>;
  createOneClinicWidgetRequestType: ClinicWidgetRequestType;
  updateOneClinicWidgetRequestType: ClinicWidgetRequestType;
  deleteOneClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  upsertOneClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  deleteManyClinicWidgetRequestType?: Maybe<BatchPayload>;
  updateManyClinicWidgetRequestType?: Maybe<BatchPayload>;
  createOneClinicWidgetSetting: ClinicWidgetSetting;
  updateOneClinicWidgetSetting: ClinicWidgetSetting;
  deleteOneClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  upsertOneClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  deleteManyClinicWidgetSetting?: Maybe<BatchPayload>;
  updateManyClinicWidgetSetting?: Maybe<BatchPayload>;
  createOneConsultationRequest: ConsultationRequest;
  updateOneConsultationRequest: ConsultationRequest;
  deleteOneConsultationRequest?: Maybe<ConsultationRequest>;
  upsertOneConsultationRequest?: Maybe<ConsultationRequest>;
  deleteManyConsultationRequest?: Maybe<BatchPayload>;
  updateManyConsultationRequest?: Maybe<BatchPayload>;
  createOneDisbursement: Disbursement;
  updateOneDisbursement: Disbursement;
  deleteOneDisbursement?: Maybe<Disbursement>;
  upsertOneDisbursement?: Maybe<Disbursement>;
  deleteManyDisbursement?: Maybe<BatchPayload>;
  updateManyDisbursement?: Maybe<BatchPayload>;
  createOneEzyVetIntegration: EzyVetIntegration;
  updateOneEzyVetIntegration: EzyVetIntegration;
  deleteOneEzyVetIntegration?: Maybe<EzyVetIntegration>;
  upsertOneEzyVetIntegration?: Maybe<EzyVetIntegration>;
  deleteManyEzyVetIntegration?: Maybe<BatchPayload>;
  updateManyEzyVetIntegration?: Maybe<BatchPayload>;
  createOneFinancialTransaction: FinancialTransaction;
  updateOneFinancialTransaction: FinancialTransaction;
  deleteOneFinancialTransaction?: Maybe<FinancialTransaction>;
  upsertOneFinancialTransaction?: Maybe<FinancialTransaction>;
  deleteManyFinancialTransaction?: Maybe<BatchPayload>;
  updateManyFinancialTransaction?: Maybe<BatchPayload>;
  createOneFormSubmission: FormSubmission;
  updateOneFormSubmission: FormSubmission;
  deleteOneFormSubmission?: Maybe<FormSubmission>;
  upsertOneFormSubmission?: Maybe<FormSubmission>;
  deleteManyFormSubmission?: Maybe<BatchPayload>;
  updateManyFormSubmission?: Maybe<BatchPayload>;
  createOneFormTemplate: FormTemplate;
  updateOneFormTemplate: FormTemplate;
  deleteOneFormTemplate?: Maybe<FormTemplate>;
  upsertOneFormTemplate?: Maybe<FormTemplate>;
  deleteManyFormTemplate?: Maybe<BatchPayload>;
  updateManyFormTemplate?: Maybe<BatchPayload>;
  createOneInvoice: Invoice;
  updateOneInvoice: Invoice;
  deleteOneInvoice?: Maybe<Invoice>;
  upsertOneInvoice?: Maybe<Invoice>;
  deleteManyInvoice?: Maybe<BatchPayload>;
  updateManyInvoice?: Maybe<BatchPayload>;
  createOneInvoiceLineItem: InvoiceLineItem;
  updateOneInvoiceLineItem: InvoiceLineItem;
  deleteOneInvoiceLineItem?: Maybe<InvoiceLineItem>;
  upsertOneInvoiceLineItem?: Maybe<InvoiceLineItem>;
  deleteManyInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyInvoiceLineItem?: Maybe<BatchPayload>;
  createOneOrganization: Organization;
  updateOneOrganization: Organization;
  deleteOneOrganization?: Maybe<Organization>;
  upsertOneOrganization?: Maybe<Organization>;
  deleteManyOrganization?: Maybe<BatchPayload>;
  updateManyOrganization?: Maybe<BatchPayload>;
  createOnePermission: Permission;
  updateOnePermission: Permission;
  deleteOnePermission?: Maybe<Permission>;
  upsertOnePermission?: Maybe<Permission>;
  deleteManyPermission?: Maybe<BatchPayload>;
  updateManyPermission?: Maybe<BatchPayload>;
  createOnePetParentSetting: PetParentSetting;
  updateOnePetParentSetting: PetParentSetting;
  deleteOnePetParentSetting?: Maybe<PetParentSetting>;
  upsertOnePetParentSetting?: Maybe<PetParentSetting>;
  deleteManyPetParentSetting?: Maybe<BatchPayload>;
  updateManyPetParentSetting?: Maybe<BatchPayload>;
  createOnePimsInvoice: PimsInvoice;
  updateOnePimsInvoice: PimsInvoice;
  deleteOnePimsInvoice?: Maybe<PimsInvoice>;
  upsertOnePimsInvoice?: Maybe<PimsInvoice>;
  deleteManyPimsInvoice?: Maybe<BatchPayload>;
  updateManyPimsInvoice?: Maybe<BatchPayload>;
  createOnePimsInvoiceLineItem: PimsInvoiceLineItem;
  updateOnePimsInvoiceLineItem: PimsInvoiceLineItem;
  deleteOnePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  upsertOnePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  deleteManyPimsInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyPimsInvoiceLineItem?: Maybe<BatchPayload>;
  createOneService: Service;
  updateOneService: Service;
  deleteOneService?: Maybe<Service>;
  upsertOneService?: Maybe<Service>;
  deleteManyService?: Maybe<BatchPayload>;
  updateManyService?: Maybe<BatchPayload>;
  createOneServiceReminderTiming: ServiceReminderTiming;
  updateOneServiceReminderTiming: ServiceReminderTiming;
  deleteOneServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  upsertOneServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  deleteManyServiceReminderTiming?: Maybe<BatchPayload>;
  updateManyServiceReminderTiming?: Maybe<BatchPayload>;
  createOneServiceReminder: ServiceReminder;
  updateOneServiceReminder: ServiceReminder;
  deleteOneServiceReminder?: Maybe<ServiceReminder>;
  upsertOneServiceReminder?: Maybe<ServiceReminder>;
  deleteManyServiceReminder?: Maybe<BatchPayload>;
  updateManyServiceReminder?: Maybe<BatchPayload>;
  createOneServiceReminderNotification: ServiceReminderNotification;
  updateOneServiceReminderNotification: ServiceReminderNotification;
  deleteOneServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  upsertOneServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  deleteManyServiceReminderNotification?: Maybe<BatchPayload>;
  updateManyServiceReminderNotification?: Maybe<BatchPayload>;
  createOneStaffRole: StaffRole;
  updateOneStaffRole: StaffRole;
  deleteOneStaffRole?: Maybe<StaffRole>;
  upsertOneStaffRole?: Maybe<StaffRole>;
  deleteManyStaffRole?: Maybe<BatchPayload>;
  updateManyStaffRole?: Maybe<BatchPayload>;
  createOneStripeDispute: StripeDispute;
  updateOneStripeDispute: StripeDispute;
  deleteOneStripeDispute?: Maybe<StripeDispute>;
  upsertOneStripeDispute?: Maybe<StripeDispute>;
  deleteManyStripeDispute?: Maybe<BatchPayload>;
  updateManyStripeDispute?: Maybe<BatchPayload>;
  createOneStripePaymentIntent: StripePaymentIntent;
  updateOneStripePaymentIntent: StripePaymentIntent;
  deleteOneStripePaymentIntent?: Maybe<StripePaymentIntent>;
  upsertOneStripePaymentIntent?: Maybe<StripePaymentIntent>;
  deleteManyStripePaymentIntent?: Maybe<BatchPayload>;
  updateManyStripePaymentIntent?: Maybe<BatchPayload>;
  createOneStripePaymentMethod: StripePaymentMethod;
  updateOneStripePaymentMethod: StripePaymentMethod;
  deleteOneStripePaymentMethod?: Maybe<StripePaymentMethod>;
  upsertOneStripePaymentMethod?: Maybe<StripePaymentMethod>;
  deleteManyStripePaymentMethod?: Maybe<BatchPayload>;
  updateManyStripePaymentMethod?: Maybe<BatchPayload>;
  createOneStripePayout: StripePayout;
  updateOneStripePayout: StripePayout;
  deleteOneStripePayout?: Maybe<StripePayout>;
  upsertOneStripePayout?: Maybe<StripePayout>;
  deleteManyStripePayout?: Maybe<BatchPayload>;
  updateManyStripePayout?: Maybe<BatchPayload>;
  createOneStripePayoutAccount: StripePayoutAccount;
  updateOneStripePayoutAccount: StripePayoutAccount;
  deleteOneStripePayoutAccount?: Maybe<StripePayoutAccount>;
  upsertOneStripePayoutAccount?: Maybe<StripePayoutAccount>;
  deleteManyStripePayoutAccount?: Maybe<BatchPayload>;
  updateManyStripePayoutAccount?: Maybe<BatchPayload>;
  createOneStripeReceipt: StripeReceipt;
  updateOneStripeReceipt: StripeReceipt;
  deleteOneStripeReceipt?: Maybe<StripeReceipt>;
  upsertOneStripeReceipt?: Maybe<StripeReceipt>;
  deleteManyStripeReceipt?: Maybe<BatchPayload>;
  updateManyStripeReceipt?: Maybe<BatchPayload>;
  createOneStripeReceiptHistory: StripeReceiptHistory;
  updateOneStripeReceiptHistory: StripeReceiptHistory;
  deleteOneStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  upsertOneStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  deleteManyStripeReceiptHistory?: Maybe<BatchPayload>;
  updateManyStripeReceiptHistory?: Maybe<BatchPayload>;
  createOneStripeRefund: StripeRefund;
  updateOneStripeRefund: StripeRefund;
  deleteOneStripeRefund?: Maybe<StripeRefund>;
  upsertOneStripeRefund?: Maybe<StripeRefund>;
  deleteManyStripeRefund?: Maybe<BatchPayload>;
  updateManyStripeRefund?: Maybe<BatchPayload>;
  createOneStripeTerminal: StripeTerminal;
  updateOneStripeTerminal: StripeTerminal;
  deleteOneStripeTerminal?: Maybe<StripeTerminal>;
  upsertOneStripeTerminal?: Maybe<StripeTerminal>;
  deleteManyStripeTerminal?: Maybe<BatchPayload>;
  updateManyStripeTerminal?: Maybe<BatchPayload>;
  createOneStripeTerminalLocation: StripeTerminalLocation;
  updateOneStripeTerminalLocation: StripeTerminalLocation;
  deleteOneStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  upsertOneStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  deleteManyStripeTerminalLocation?: Maybe<BatchPayload>;
  updateManyStripeTerminalLocation?: Maybe<BatchPayload>;
  createOneTransaction: Transaction;
  updateOneTransaction: Transaction;
  deleteOneTransaction?: Maybe<Transaction>;
  upsertOneTransaction?: Maybe<Transaction>;
  deleteManyTransaction?: Maybe<BatchPayload>;
  updateManyTransaction?: Maybe<BatchPayload>;
  createOneUser: User;
  updateOneUser: User;
  deleteOneUser?: Maybe<User>;
  upsertOneUser?: Maybe<User>;
  deleteManyUser?: Maybe<BatchPayload>;
  updateManyUser?: Maybe<BatchPayload>;
  createOneUserAppointmentGrouping: UserAppointmentGrouping;
  updateOneUserAppointmentGrouping: UserAppointmentGrouping;
  deleteOneUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  upsertOneUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  deleteManyUserAppointmentGrouping?: Maybe<BatchPayload>;
  updateManyUserAppointmentGrouping?: Maybe<BatchPayload>;
  createOneUserNotificationSetting: UserNotificationSetting;
  updateOneUserNotificationSetting: UserNotificationSetting;
  deleteOneUserNotificationSetting?: Maybe<UserNotificationSetting>;
  upsertOneUserNotificationSetting?: Maybe<UserNotificationSetting>;
  deleteManyUserNotificationSetting?: Maybe<BatchPayload>;
  updateManyUserNotificationSetting?: Maybe<BatchPayload>;
  createOneUserSetting: UserSetting;
  updateOneUserSetting: UserSetting;
  deleteOneUserSetting?: Maybe<UserSetting>;
  upsertOneUserSetting?: Maybe<UserSetting>;
  deleteManyUserSetting?: Maybe<BatchPayload>;
  updateManyUserSetting?: Maybe<BatchPayload>;
  createOneVetInfo: VetInfo;
  updateOneVetInfo: VetInfo;
  deleteOneVetInfo?: Maybe<VetInfo>;
  upsertOneVetInfo?: Maybe<VetInfo>;
  deleteManyVetInfo?: Maybe<BatchPayload>;
  updateManyVetInfo?: Maybe<BatchPayload>;
  createOneWorkflowEventSetting: WorkflowEventSetting;
  updateOneWorkflowEventSetting: WorkflowEventSetting;
  deleteOneWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  upsertOneWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  deleteManyWorkflowEventSetting?: Maybe<BatchPayload>;
  updateManyWorkflowEventSetting?: Maybe<BatchPayload>;
  createOneClinicEntityPhoneNumber: ClinicEntityPhoneNumber;
  updateOneClinicEntityPhoneNumber: ClinicEntityPhoneNumber;
  deleteOneClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  upsertOneClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  deleteManyClinicEntityPhoneNumber?: Maybe<BatchPayload>;
  updateManyClinicEntityPhoneNumber?: Maybe<BatchPayload>;
  createOneClinicOfficeHour: ClinicOfficeHour;
  updateOneClinicOfficeHour: ClinicOfficeHour;
  deleteOneClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  upsertOneClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  deleteManyClinicOfficeHour?: Maybe<BatchPayload>;
  updateManyClinicOfficeHour?: Maybe<BatchPayload>;
  createOneEmailProviderLog: EmailProviderLog;
  updateOneEmailProviderLog: EmailProviderLog;
  deleteOneEmailProviderLog?: Maybe<EmailProviderLog>;
  upsertOneEmailProviderLog?: Maybe<EmailProviderLog>;
  deleteManyEmailProviderLog?: Maybe<BatchPayload>;
  updateManyEmailProviderLog?: Maybe<BatchPayload>;
  createOneSmsNotificationStatus: SmsNotificationStatus;
  updateOneSmsNotificationStatus: SmsNotificationStatus;
  deleteOneSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  upsertOneSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  deleteManySmsNotificationStatus?: Maybe<BatchPayload>;
  updateManySmsNotificationStatus?: Maybe<BatchPayload>;
  createOneFeatureFlag: FeatureFlag;
  updateOneFeatureFlag: FeatureFlag;
  deleteOneFeatureFlag?: Maybe<FeatureFlag>;
  upsertOneFeatureFlag?: Maybe<FeatureFlag>;
  deleteManyFeatureFlag?: Maybe<BatchPayload>;
  updateManyFeatureFlag?: Maybe<BatchPayload>;
  createOneCallHistory: CallHistory;
  updateOneCallHistory: CallHistory;
  deleteOneCallHistory?: Maybe<CallHistory>;
  upsertOneCallHistory?: Maybe<CallHistory>;
  deleteManyCallHistory?: Maybe<BatchPayload>;
  updateManyCallHistory?: Maybe<BatchPayload>;
  createOneCallParticipant: CallParticipant;
  updateOneCallParticipant: CallParticipant;
  deleteOneCallParticipant?: Maybe<CallParticipant>;
  upsertOneCallParticipant?: Maybe<CallParticipant>;
  deleteManyCallParticipant?: Maybe<BatchPayload>;
  updateManyCallParticipant?: Maybe<BatchPayload>;
  createOneChannelSelection: ChannelSelection;
  updateOneChannelSelection: ChannelSelection;
  deleteOneChannelSelection?: Maybe<ChannelSelection>;
  upsertOneChannelSelection?: Maybe<ChannelSelection>;
  deleteManyChannelSelection?: Maybe<BatchPayload>;
  updateManyChannelSelection?: Maybe<BatchPayload>;
  createOneClinicEmailCampaignSetting: ClinicEmailCampaignSetting;
  updateOneClinicEmailCampaignSetting: ClinicEmailCampaignSetting;
  deleteOneClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  upsertOneClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  deleteManyClinicEmailCampaignSetting?: Maybe<BatchPayload>;
  updateManyClinicEmailCampaignSetting?: Maybe<BatchPayload>;
  createOneOrganizationPet: OrganizationPet;
  updateOneOrganizationPet: OrganizationPet;
  deleteOneOrganizationPet?: Maybe<OrganizationPet>;
  upsertOneOrganizationPet?: Maybe<OrganizationPet>;
  deleteManyOrganizationPet?: Maybe<BatchPayload>;
  updateManyOrganizationPet?: Maybe<BatchPayload>;
  createOneOrganizationPetParent: OrganizationPetParent;
  updateOneOrganizationPetParent: OrganizationPetParent;
  deleteOneOrganizationPetParent?: Maybe<OrganizationPetParent>;
  upsertOneOrganizationPetParent?: Maybe<OrganizationPetParent>;
  deleteManyOrganizationPetParent?: Maybe<BatchPayload>;
  updateManyOrganizationPetParent?: Maybe<BatchPayload>;
  createOneOrganizationPetToOrganizationPetParent: OrganizationPetToOrganizationPetParent;
  updateOneOrganizationPetToOrganizationPetParent: OrganizationPetToOrganizationPetParent;
  deleteOneOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  upsertOneOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  deleteManyOrganizationPetToOrganizationPetParent?: Maybe<BatchPayload>;
  createOneCareAuthorizationRequest: CareAuthorizationRequest;
  updateOneCareAuthorizationRequest: CareAuthorizationRequest;
  deleteOneCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  upsertOneCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  deleteManyCareAuthorizationRequest?: Maybe<BatchPayload>;
  updateManyCareAuthorizationRequest?: Maybe<BatchPayload>;
  createOneCareBenefit: CareBenefit;
  updateOneCareBenefit: CareBenefit;
  deleteOneCareBenefit?: Maybe<CareBenefit>;
  upsertOneCareBenefit?: Maybe<CareBenefit>;
  deleteManyCareBenefit?: Maybe<BatchPayload>;
  updateManyCareBenefit?: Maybe<BatchPayload>;
  createOneCarePlan: CarePlan;
  updateOneCarePlan: CarePlan;
  deleteOneCarePlan?: Maybe<CarePlan>;
  upsertOneCarePlan?: Maybe<CarePlan>;
  deleteManyCarePlan?: Maybe<BatchPayload>;
  updateManyCarePlan?: Maybe<BatchPayload>;
  createOneCareAddonPackage: CareAddonPackage;
  updateOneCareAddonPackage: CareAddonPackage;
  deleteOneCareAddonPackage?: Maybe<CareAddonPackage>;
  upsertOneCareAddonPackage?: Maybe<CareAddonPackage>;
  deleteManyCareAddonPackage?: Maybe<BatchPayload>;
  updateManyCareAddonPackage?: Maybe<BatchPayload>;
  createOneCarePlanBenefit: CarePlanBenefit;
  updateOneCarePlanBenefit: CarePlanBenefit;
  deleteOneCarePlanBenefit?: Maybe<CarePlanBenefit>;
  upsertOneCarePlanBenefit?: Maybe<CarePlanBenefit>;
  deleteManyCarePlanBenefit?: Maybe<BatchPayload>;
  updateManyCarePlanBenefit?: Maybe<BatchPayload>;
  createOneCareAddonPackageBenefit: CareAddonPackageBenefit;
  updateOneCareAddonPackageBenefit: CareAddonPackageBenefit;
  deleteOneCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  upsertOneCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  deleteManyCareAddonPackageBenefit?: Maybe<BatchPayload>;
  updateManyCareAddonPackageBenefit?: Maybe<BatchPayload>;
  createOneCarePlanEnrollment: CarePlanEnrollment;
  updateOneCarePlanEnrollment: CarePlanEnrollment;
  deleteOneCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  upsertOneCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  deleteManyCarePlanEnrollment?: Maybe<BatchPayload>;
  updateManyCarePlanEnrollment?: Maybe<BatchPayload>;
  createOneCareAddonEnrollment: CareAddonEnrollment;
  updateOneCareAddonEnrollment: CareAddonEnrollment;
  deleteOneCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  upsertOneCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  deleteManyCareAddonEnrollment?: Maybe<BatchPayload>;
  updateManyCareAddonEnrollment?: Maybe<BatchPayload>;
  createOneCareBenefitUsage: CareBenefitUsage;
  updateOneCareBenefitUsage: CareBenefitUsage;
  deleteOneCareBenefitUsage?: Maybe<CareBenefitUsage>;
  upsertOneCareBenefitUsage?: Maybe<CareBenefitUsage>;
  deleteManyCareBenefitUsage?: Maybe<BatchPayload>;
  updateManyCareBenefitUsage?: Maybe<BatchPayload>;
  createOneCareStripeSubscription: CareStripeSubscription;
  updateOneCareStripeSubscription: CareStripeSubscription;
  deleteOneCareStripeSubscription?: Maybe<CareStripeSubscription>;
  upsertOneCareStripeSubscription?: Maybe<CareStripeSubscription>;
  deleteManyCareStripeSubscription?: Maybe<BatchPayload>;
  updateManyCareStripeSubscription?: Maybe<BatchPayload>;
  createOneStripeCustomer: StripeCustomer;
  updateOneStripeCustomer: StripeCustomer;
  deleteOneStripeCustomer?: Maybe<StripeCustomer>;
  upsertOneStripeCustomer?: Maybe<StripeCustomer>;
  deleteManyStripeCustomer?: Maybe<BatchPayload>;
  updateManyStripeCustomer?: Maybe<BatchPayload>;
  createOnePimsWritebackLogEntry: PimsWritebackLogEntry;
  updateOnePimsWritebackLogEntry: PimsWritebackLogEntry;
  deleteOnePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  upsertOnePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  deleteManyPimsWritebackLogEntry?: Maybe<BatchPayload>;
  updateManyPimsWritebackLogEntry?: Maybe<BatchPayload>;
  createOneChannelAutomationStatus: ChannelAutomationStatus;
  updateOneChannelAutomationStatus: ChannelAutomationStatus;
  deleteOneChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  upsertOneChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  deleteManyChannelAutomationStatus?: Maybe<BatchPayload>;
  updateManyChannelAutomationStatus?: Maybe<BatchPayload>;
  createOneChannelCreationSource: ChannelCreationSource;
  updateOneChannelCreationSource: ChannelCreationSource;
  deleteOneChannelCreationSource?: Maybe<ChannelCreationSource>;
  upsertOneChannelCreationSource?: Maybe<ChannelCreationSource>;
  deleteManyChannelCreationSource?: Maybe<BatchPayload>;
  updateManyChannelCreationSource?: Maybe<BatchPayload>;
  createOneInstinctIntegration: InstinctIntegration;
  updateOneInstinctIntegration: InstinctIntegration;
  deleteOneInstinctIntegration?: Maybe<InstinctIntegration>;
  upsertOneInstinctIntegration?: Maybe<InstinctIntegration>;
  deleteManyInstinctIntegration?: Maybe<BatchPayload>;
  updateManyInstinctIntegration?: Maybe<BatchPayload>;
  createOneClinicDirectBookingSetting: ClinicDirectBookingSetting;
  updateOneClinicDirectBookingSetting: ClinicDirectBookingSetting;
  deleteOneClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  upsertOneClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  deleteManyClinicDirectBookingSetting?: Maybe<BatchPayload>;
  updateManyClinicDirectBookingSetting?: Maybe<BatchPayload>;
  createOneVetAvailability: VetAvailability;
  updateOneVetAvailability: VetAvailability;
  deleteOneVetAvailability?: Maybe<VetAvailability>;
  upsertOneVetAvailability?: Maybe<VetAvailability>;
  deleteManyVetAvailability?: Maybe<BatchPayload>;
  updateManyVetAvailability?: Maybe<BatchPayload>;
  createOneVetAvailabilityMap: VetAvailabilityMap;
  updateOneVetAvailabilityMap: VetAvailabilityMap;
  deleteOneVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  upsertOneVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  deleteManyVetAvailabilityMap?: Maybe<BatchPayload>;
  updateManyVetAvailabilityMap?: Maybe<BatchPayload>;
  createOneStripeInvoice: StripeInvoice;
  updateOneStripeInvoice: StripeInvoice;
  deleteOneStripeInvoice?: Maybe<StripeInvoice>;
  upsertOneStripeInvoice?: Maybe<StripeInvoice>;
  deleteManyStripeInvoice?: Maybe<BatchPayload>;
  updateManyStripeInvoice?: Maybe<BatchPayload>;
  createOneOrganizationCareBenefitToClinicService: OrganizationCareBenefitToClinicService;
  updateOneOrganizationCareBenefitToClinicService: OrganizationCareBenefitToClinicService;
  deleteOneOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  upsertOneOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  deleteManyOrganizationCareBenefitToClinicService?: Maybe<BatchPayload>;
  createOneClinicPetParentAddress: ClinicPetParentAddress;
  updateOneClinicPetParentAddress: ClinicPetParentAddress;
  deleteOneClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  upsertOneClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  deleteManyClinicPetParentAddress?: Maybe<BatchPayload>;
  updateManyClinicPetParentAddress?: Maybe<BatchPayload>;
  createOneCareBenefitUsageToInvoiceLineItem: CareBenefitUsageToInvoiceLineItem;
  updateOneCareBenefitUsageToInvoiceLineItem: CareBenefitUsageToInvoiceLineItem;
  deleteOneCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  upsertOneCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  deleteManyCareBenefitUsageToInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyCareBenefitUsageToInvoiceLineItem?: Maybe<BatchPayload>;
  createOneChannelFilterSelection: ChannelFilterSelection;
  updateOneChannelFilterSelection: ChannelFilterSelection;
  deleteOneChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  upsertOneChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  deleteManyChannelFilterSelection?: Maybe<BatchPayload>;
  updateManyChannelFilterSelection?: Maybe<BatchPayload>;
  createOneUserChannelFilterSelection: UserChannelFilterSelection;
  updateOneUserChannelFilterSelection: UserChannelFilterSelection;
  deleteOneUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  upsertOneUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  deleteManyUserChannelFilterSelection?: Maybe<BatchPayload>;
  updateManyUserChannelFilterSelection?: Maybe<BatchPayload>;
  createOneMessageTemplate: MessageTemplate;
  updateOneMessageTemplate: MessageTemplate;
  deleteOneMessageTemplate?: Maybe<MessageTemplate>;
  upsertOneMessageTemplate?: Maybe<MessageTemplate>;
  deleteManyMessageTemplate?: Maybe<BatchPayload>;
  updateManyMessageTemplate?: Maybe<BatchPayload>;
  createOneRules: Rules;
  updateOneRules: Rules;
  deleteOneRules?: Maybe<Rules>;
  upsertOneRules?: Maybe<Rules>;
  deleteManyRules?: Maybe<BatchPayload>;
  updateManyRules?: Maybe<BatchPayload>;
  createOneCareEnrollmentToStripeInvoiceItem: CareEnrollmentToStripeInvoiceItem;
  updateOneCareEnrollmentToStripeInvoiceItem: CareEnrollmentToStripeInvoiceItem;
  deleteOneCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  upsertOneCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  deleteManyCareEnrollmentToStripeInvoiceItem?: Maybe<BatchPayload>;
  updateManyCareEnrollmentToStripeInvoiceItem?: Maybe<BatchPayload>;
  createOneStripeInvoiceItem: StripeInvoiceItem;
  updateOneStripeInvoiceItem: StripeInvoiceItem;
  deleteOneStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  upsertOneStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  deleteManyStripeInvoiceItem?: Maybe<BatchPayload>;
  updateManyStripeInvoiceItem?: Maybe<BatchPayload>;
  createOneClinicEmployeeAppointmentTypeSetting: ClinicEmployeeAppointmentTypeSetting;
  updateOneClinicEmployeeAppointmentTypeSetting: ClinicEmployeeAppointmentTypeSetting;
  deleteOneClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  upsertOneClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  deleteManyClinicEmployeeAppointmentTypeSetting?: Maybe<BatchPayload>;
  updateManyClinicEmployeeAppointmentTypeSetting?: Maybe<BatchPayload>;
  createOneClinicPostcardSetting: ClinicPostcardSetting;
  updateOneClinicPostcardSetting: ClinicPostcardSetting;
  deleteOneClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  upsertOneClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  deleteManyClinicPostcardSetting?: Maybe<BatchPayload>;
  updateManyClinicPostcardSetting?: Maybe<BatchPayload>;
  createOneInvoicePimsWritebackLogEntry: InvoicePimsWritebackLogEntry;
  updateOneInvoicePimsWritebackLogEntry: InvoicePimsWritebackLogEntry;
  deleteOneInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  upsertOneInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  deleteManyInvoicePimsWritebackLogEntry?: Maybe<BatchPayload>;
  updateManyInvoicePimsWritebackLogEntry?: Maybe<BatchPayload>;
  createOneNotificationEventToMessage: NotificationEventToMessage;
  updateOneNotificationEventToMessage: NotificationEventToMessage;
  deleteOneNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  upsertOneNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  deleteManyNotificationEventToMessage?: Maybe<BatchPayload>;
  updateManyNotificationEventToMessage?: Maybe<BatchPayload>;
  createOneEmergencyClinics: EmergencyClinics;
  updateOneEmergencyClinics: EmergencyClinics;
  deleteOneEmergencyClinics?: Maybe<EmergencyClinics>;
  upsertOneEmergencyClinics?: Maybe<EmergencyClinics>;
  deleteManyEmergencyClinics?: Maybe<BatchPayload>;
  updateManyEmergencyClinics?: Maybe<BatchPayload>;
  createOneCareEnrollmentPayment: CareEnrollmentPayment;
  updateOneCareEnrollmentPayment: CareEnrollmentPayment;
  deleteOneCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  upsertOneCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  deleteManyCareEnrollmentPayment?: Maybe<BatchPayload>;
  updateManyCareEnrollmentPayment?: Maybe<BatchPayload>;
  createOneAutomationRun: AutomationRun;
  updateOneAutomationRun: AutomationRun;
  deleteOneAutomationRun?: Maybe<AutomationRun>;
  upsertOneAutomationRun?: Maybe<AutomationRun>;
  deleteManyAutomationRun?: Maybe<BatchPayload>;
  updateManyAutomationRun?: Maybe<BatchPayload>;
  createOneAutomationRunAction: AutomationRunAction;
  updateOneAutomationRunAction: AutomationRunAction;
  deleteOneAutomationRunAction?: Maybe<AutomationRunAction>;
  upsertOneAutomationRunAction?: Maybe<AutomationRunAction>;
  deleteManyAutomationRunAction?: Maybe<BatchPayload>;
  updateManyAutomationRunAction?: Maybe<BatchPayload>;
  createOneChannelMessageReaction: ChannelMessageReaction;
  updateOneChannelMessageReaction: ChannelMessageReaction;
  deleteOneChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  upsertOneChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  deleteManyChannelMessageReaction?: Maybe<BatchPayload>;
  updateManyChannelMessageReaction?: Maybe<BatchPayload>;
  createOneVaccination: Vaccination;
  updateOneVaccination: Vaccination;
  deleteOneVaccination?: Maybe<Vaccination>;
  upsertOneVaccination?: Maybe<Vaccination>;
  deleteManyVaccination?: Maybe<BatchPayload>;
  updateManyVaccination?: Maybe<BatchPayload>;
  createOneMassTextAlert: MassTextAlert;
  updateOneMassTextAlert: MassTextAlert;
  deleteOneMassTextAlert?: Maybe<MassTextAlert>;
  upsertOneMassTextAlert?: Maybe<MassTextAlert>;
  deleteManyMassTextAlert?: Maybe<BatchPayload>;
  updateManyMassTextAlert?: Maybe<BatchPayload>;
  createOneMassTextAlertEntry: MassTextAlertEntry;
  updateOneMassTextAlertEntry: MassTextAlertEntry;
  deleteOneMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  upsertOneMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  deleteManyMassTextAlertEntry?: Maybe<BatchPayload>;
  updateManyMassTextAlertEntry?: Maybe<BatchPayload>;
  createOneMassTextAlertEntryAppointment: MassTextAlertEntryAppointment;
  updateOneMassTextAlertEntryAppointment: MassTextAlertEntryAppointment;
  deleteOneMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  upsertOneMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  deleteManyMassTextAlertEntryAppointment?: Maybe<BatchPayload>;
  updateManyMassTextAlertEntryAppointment?: Maybe<BatchPayload>;
  createOnePPCIntegration: PpcIntegration;
  updateOnePPCIntegration: PpcIntegration;
  deleteOnePPCIntegration?: Maybe<PpcIntegration>;
  upsertOnePPCIntegration?: Maybe<PpcIntegration>;
  deleteManyPPCIntegration?: Maybe<BatchPayload>;
  updateManyPPCIntegration?: Maybe<BatchPayload>;
  createOnePetPortalSetting: PetPortalSetting;
  updateOnePetPortalSetting: PetPortalSetting;
  deleteOnePetPortalSetting?: Maybe<PetPortalSetting>;
  upsertOnePetPortalSetting?: Maybe<PetPortalSetting>;
  deleteManyPetPortalSetting?: Maybe<BatchPayload>;
  updateManyPetPortalSetting?: Maybe<BatchPayload>;
  createOnePrescription: Prescription;
  updateOnePrescription: Prescription;
  deleteOnePrescription?: Maybe<Prescription>;
  upsertOnePrescription?: Maybe<Prescription>;
  deleteManyPrescription?: Maybe<BatchPayload>;
  updateManyPrescription?: Maybe<BatchPayload>;
  createOneClinicPhoneNumber: ClinicPhoneNumber;
  updateOneClinicPhoneNumber: ClinicPhoneNumber;
  deleteOneClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  upsertOneClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  deleteManyClinicPhoneNumber?: Maybe<BatchPayload>;
  updateManyClinicPhoneNumber?: Maybe<BatchPayload>;
  configureEmailCampaignsForClinic?: Maybe<Scalars['String']>;
  addEmailCampaignsCareUrlField?: Maybe<Scalars['Boolean']>;
  triggerEmailCampaignsDataSync?: Maybe<Scalars['Boolean']>;
  confirmAppointment2: Appointment;
  updateClinicAppointmentTypeSettings?: Maybe<AppointmentType>;
  resetAutomationRun?: Maybe<AutomationRun>;
  getSignedUploadUrl: GetSignedUploadUrlOutput;
  getSignedDownloadUrl?: Maybe<Scalars['String']>;
  createOneCallRecording?: Maybe<CallRecording>;
  updateOneCallRecording?: Maybe<CallRecording>;
  /** Deletes a CallRecording and its associated TwilioCallRecording */
  deleteOneCallRecording?: Maybe<CallRecording>;
  toggleTwilioVideoCallRecordingStatus?: Maybe<CallRecording>;
  requestCareLoginLink: AuthLinkRequestResult;
  sendDirectedCareLoginLink: DirectedAuthLinkResult;
  getDirectedCareLoginLink: GetDirectedAuthLinkResult;
  redeemOneTimeCode: RedeemOneTimeCodeResult;
  requestOneTimeCode: RequestOneTimeCodeResult;
  createCareSubscription?: Maybe<CreateCareSubscriptionResult>;
  updateCareSubscription?: Maybe<UpdateCareSubscriptionResult>;
  cancelCareSubscription?: Maybe<CancelCareSubscriptionResult>;
  changeCareSubscriptionPaymentMethod?: Maybe<ChangeCareSubscriptionPaymentMethodOutput>;
  createPaymentMethodSetup: CreatePaymentMethodSetupInputResult;
  sendEnrollmentEmail?: Maybe<SendEnrollmentEmailOutput>;
  formSubmissionExportToPims?: Maybe<FormSubmissionExportResponse>;
  channelExportToPims?: Maybe<GenericResponse>;
  addChannelTag?: Maybe<Channel>;
  removeChannelTag?: Maybe<Channel>;
  createTeamChannel2?: Maybe<Channel>;
  updateTeamChannel2?: Maybe<Channel>;
  getOrCreateActiveClientChannel?: Maybe<Channel>;
  getOrCreateActiveChannel?: Maybe<Channel>;
  createChannelFilterSelection: ChannelFilterSelection;
  updateChannelFilterSelection: ChannelFilterSelection;
  deleteChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  addChannelMember2?: Maybe<ChannelMember>;
  removeChannelMember2?: Maybe<ChannelMember>;
  createChannelMessage2: ChannelMessage;
  createSystemChannelMessage2?: Maybe<ChannelMessage>;
  clearUnreadMessages2: GenericResponse;
  cancelClinicSubscription?: Maybe<GenericResponse>;
  updateClinicPetWithoutPims?: Maybe<ClinicPet>;
  ensurePetsPromoted?: Maybe<EnsurePetsPromotedResult>;
  sendClinicPetParentAuthLink?: Maybe<ClinicPetParentAuthLinkResponse>;
  redeemClinicPetParentAuthCode?: Maybe<Scalars['Json']>;
  updateClinicPostcardSetting: ClinicPostcardSetting;
  /** Merge the information from the deleteId petparent into the keepId pet parent and soft delete deleteId */
  mergePetParent: MergePetParentResult;
  updateClinicPetParentContactInfoFromPims: ClinicPetParent;
  updateClinicSettingTransactionColumn: ClinicSetting;
  verifyClinicWidgetRequest2?: Maybe<ClinicWidgetRequest>;
  createClinicWidgetRequest2: ClinicWidgetRequest;
  sendWidgetCodeToDeveloper?: Maybe<GenericResponse>;
  sendConsultationReceipt?: Maybe<Scalars['Json']>;
  createDirectBookingAppointment?: Maybe<DirectBookingAppointmentResponse>;
  submitForm2?: Maybe<SubmitFormResponse>;
  submitPetParentRequest?: Maybe<SubmitFormResponse>;
  postCareInvoiceAccountCreditToPims: WritebackCareInvoiceAccountCreditToPimsResponse;
  syncPimsIntegrationModel: ClinicPimsIntegrationCapability;
  syncManyPimsIntegrationModel?: Maybe<Array<ClinicPimsIntegrationCapability>>;
  syncClinic?: Maybe<Scalars['Json']>;
  buildSignedLookerUrl: Scalars['String'];
  updateManyServicesAndTimings: Array<Service>;
  setPaymentIntentClinicPetParent2?: Maybe<StripePaymentIntent>;
  createStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  cancelStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  refundStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  clearTerminalDisplay?: Maybe<ClearTerminalDisplayResponse>;
  sendPaymentToTerminal?: Maybe<SendPaymentToTerminalResponse>;
  sendUiRefreshEvent?: Maybe<UiRefresh>;
  addUserToClinic: User;
  removeUserFromClinic: User;
  resendUserInvitation: User;
  acceptUserInvitation2?: Maybe<AcceptUserInvitationResponse>;
  generateCustomToken?: Maybe<GenerateCustomTokenResponse>;
  exportPatientVaccinesToPDF: ExportPatientVaccinesToPdfResponse;
  generateWidgetAuthenticationRequest?: Maybe<GenerateWidgetAuthenticationRequestResponse>;
  verifyWidgetAuthenticationRequest?: Maybe<VerifyWidgetAuthenticationRequestResponse>;
  createClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  verifyClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  stripeCreateConnectedAccount?: Maybe<StripeCreateAccountOutput>;
  stripeConnectConnectedAccount?: Maybe<StripeAccountOutput>;
  generateTinyUrlAndToken?: Maybe<Scalars['Json']>;
  createTwilioToken: CreateTwilioTokenResponse;
  transferTwilioNumber?: Maybe<GenericResponse>;
  twilioVerifyCallerId?: Maybe<TwilioCallerIdVerification>;
  createImpersonation: TokenOutput;
  triggerWorkflowEvent?: Maybe<GenericResponse>;
  triggerMessageEvent?: Maybe<GenericResponse>;
};

export type MutationCreateOneAppointmentArgs = {
  data: AppointmentCreateInput;
};

export type MutationUpdateOneAppointmentArgs = {
  data: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};

export type MutationDeleteOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};

export type MutationUpsertOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateInput;
  update: AppointmentUpdateInput;
};

export type MutationDeleteManyAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
};

export type MutationUpdateManyAppointmentArgs = {
  data: AppointmentUpdateManyMutationInput;
  where?: Maybe<AppointmentWhereInput>;
};

export type MutationCreateOneAppointmentTypeArgs = {
  data: AppointmentTypeCreateInput;
};

export type MutationUpdateOneAppointmentTypeArgs = {
  data: AppointmentTypeUpdateInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type MutationDeleteOneAppointmentTypeArgs = {
  where: AppointmentTypeWhereUniqueInput;
};

export type MutationUpsertOneAppointmentTypeArgs = {
  where: AppointmentTypeWhereUniqueInput;
  create: AppointmentTypeCreateInput;
  update: AppointmentTypeUpdateInput;
};

export type MutationDeleteManyAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
};

export type MutationUpdateManyAppointmentTypeArgs = {
  data: AppointmentTypeUpdateManyMutationInput;
  where?: Maybe<AppointmentTypeWhereInput>;
};

export type MutationCreateOneBitwerxIntegrationArgs = {
  data: BitwerxIntegrationCreateInput;
};

export type MutationUpdateOneBitwerxIntegrationArgs = {
  data: BitwerxIntegrationUpdateInput;
  where: BitwerxIntegrationWhereUniqueInput;
};

export type MutationDeleteOneBitwerxIntegrationArgs = {
  where: BitwerxIntegrationWhereUniqueInput;
};

export type MutationUpsertOneBitwerxIntegrationArgs = {
  where: BitwerxIntegrationWhereUniqueInput;
  create: BitwerxIntegrationCreateInput;
  update: BitwerxIntegrationUpdateInput;
};

export type MutationDeleteManyBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
};

export type MutationUpdateManyBitwerxIntegrationArgs = {
  data: BitwerxIntegrationUpdateManyMutationInput;
  where?: Maybe<BitwerxIntegrationWhereInput>;
};

export type MutationCreateOneChannelArgs = {
  data: ChannelCreateInput;
};

export type MutationUpdateOneChannelArgs = {
  data: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};

export type MutationDeleteOneChannelArgs = {
  where: ChannelWhereUniqueInput;
};

export type MutationUpsertOneChannelArgs = {
  where: ChannelWhereUniqueInput;
  create: ChannelCreateInput;
  update: ChannelUpdateInput;
};

export type MutationDeleteManyChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
};

export type MutationUpdateManyChannelArgs = {
  data: ChannelUpdateManyMutationInput;
  where?: Maybe<ChannelWhereInput>;
};

export type MutationCreateOneChannelMemberArgs = {
  data: ChannelMemberCreateInput;
};

export type MutationUpdateOneChannelMemberArgs = {
  data: ChannelMemberUpdateInput;
  where: ChannelMemberWhereUniqueInput;
};

export type MutationDeleteOneChannelMemberArgs = {
  where: ChannelMemberWhereUniqueInput;
};

export type MutationUpsertOneChannelMemberArgs = {
  where: ChannelMemberWhereUniqueInput;
  create: ChannelMemberCreateInput;
  update: ChannelMemberUpdateInput;
};

export type MutationDeleteManyChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
};

export type MutationUpdateManyChannelMemberArgs = {
  data: ChannelMemberUpdateManyMutationInput;
  where?: Maybe<ChannelMemberWhereInput>;
};

export type MutationCreateOneChannelMessageArgs = {
  data: ChannelMessageCreateInput;
};

export type MutationUpdateOneChannelMessageArgs = {
  data: ChannelMessageUpdateInput;
  where: ChannelMessageWhereUniqueInput;
};

export type MutationDeleteOneChannelMessageArgs = {
  where: ChannelMessageWhereUniqueInput;
};

export type MutationUpsertOneChannelMessageArgs = {
  where: ChannelMessageWhereUniqueInput;
  create: ChannelMessageCreateInput;
  update: ChannelMessageUpdateInput;
};

export type MutationDeleteManyChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
};

export type MutationUpdateManyChannelMessageArgs = {
  data: ChannelMessageUpdateManyMutationInput;
  where?: Maybe<ChannelMessageWhereInput>;
};

export type MutationCreateOneChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentCreateInput;
};

export type MutationUpdateOneChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentUpdateInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type MutationDeleteOneChannelMessageAttachmentArgs = {
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type MutationUpsertOneChannelMessageAttachmentArgs = {
  where: ChannelMessageAttachmentWhereUniqueInput;
  create: ChannelMessageAttachmentCreateInput;
  update: ChannelMessageAttachmentUpdateInput;
};

export type MutationDeleteManyChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};

export type MutationUpdateManyChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentUpdateManyMutationInput;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};

export type MutationCreateOneChannelStatusArgs = {
  data: ChannelStatusCreateInput;
};

export type MutationUpdateOneChannelStatusArgs = {
  data: ChannelStatusUpdateInput;
  where: ChannelStatusWhereUniqueInput;
};

export type MutationDeleteOneChannelStatusArgs = {
  where: ChannelStatusWhereUniqueInput;
};

export type MutationUpsertOneChannelStatusArgs = {
  where: ChannelStatusWhereUniqueInput;
  create: ChannelStatusCreateInput;
  update: ChannelStatusUpdateInput;
};

export type MutationDeleteManyChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
};

export type MutationUpdateManyChannelStatusArgs = {
  data: ChannelStatusUpdateManyMutationInput;
  where?: Maybe<ChannelStatusWhereInput>;
};

export type MutationCreateOneChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeCreateInput;
};

export type MutationUpdateOneChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeUpdateInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type MutationDeleteOneChannelStatusChannelAssigneeArgs = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type MutationUpsertOneChannelStatusChannelAssigneeArgs = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
  create: ChannelStatusChannelAssigneeCreateInput;
  update: ChannelStatusChannelAssigneeUpdateInput;
};

export type MutationDeleteManyChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};

export type MutationUpdateManyChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeUpdateManyMutationInput;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};

export type MutationCreateOneClinicArgs = {
  data: ClinicCreateInput;
};

export type MutationUpdateOneClinicArgs = {
  data: ClinicUpdateInput;
  where: ClinicWhereUniqueInput;
};

export type MutationDeleteOneClinicArgs = {
  where: ClinicWhereUniqueInput;
};

export type MutationUpsertOneClinicArgs = {
  where: ClinicWhereUniqueInput;
  create: ClinicCreateInput;
  update: ClinicUpdateInput;
};

export type MutationDeleteManyClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
};

export type MutationUpdateManyClinicArgs = {
  data: ClinicUpdateManyMutationInput;
  where?: Maybe<ClinicWhereInput>;
};

export type MutationCreateOneClinicEmployeeArgs = {
  data: ClinicEmployeeCreateInput;
};

export type MutationUpdateOneClinicEmployeeArgs = {
  data: ClinicEmployeeUpdateInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type MutationDeleteOneClinicEmployeeArgs = {
  where: ClinicEmployeeWhereUniqueInput;
};

export type MutationUpsertOneClinicEmployeeArgs = {
  where: ClinicEmployeeWhereUniqueInput;
  create: ClinicEmployeeCreateInput;
  update: ClinicEmployeeUpdateInput;
};

export type MutationDeleteManyClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
};

export type MutationUpdateManyClinicEmployeeArgs = {
  data: ClinicEmployeeUpdateManyMutationInput;
  where?: Maybe<ClinicEmployeeWhereInput>;
};

export type MutationCreateOneClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationCreateInput;
};

export type MutationUpdateOneClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationUpdateInput;
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};

export type MutationDeleteOneClinicPaymentFeeConfigurationArgs = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};

export type MutationUpsertOneClinicPaymentFeeConfigurationArgs = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
  create: ClinicPaymentFeeConfigurationCreateInput;
  update: ClinicPaymentFeeConfigurationUpdateInput;
};

export type MutationDeleteManyClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};

export type MutationUpdateManyClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationUpdateManyMutationInput;
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};

export type MutationCreateOneClinicPetArgs = {
  data: ClinicPetCreateInput;
};

export type MutationUpdateOneClinicPetArgs = {
  data: ClinicPetUpdateInput;
  where: ClinicPetWhereUniqueInput;
};

export type MutationDeleteOneClinicPetArgs = {
  where: ClinicPetWhereUniqueInput;
};

export type MutationUpsertOneClinicPetArgs = {
  where: ClinicPetWhereUniqueInput;
  create: ClinicPetCreateInput;
  update: ClinicPetUpdateInput;
};

export type MutationDeleteManyClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
};

export type MutationUpdateManyClinicPetArgs = {
  data: ClinicPetUpdateManyMutationInput;
  where?: Maybe<ClinicPetWhereInput>;
};

export type MutationCreateOneClinicPetAlertArgs = {
  data: ClinicPetAlertCreateInput;
};

export type MutationUpdateOneClinicPetAlertArgs = {
  data: ClinicPetAlertUpdateInput;
  where: ClinicPetAlertWhereUniqueInput;
};

export type MutationDeleteOneClinicPetAlertArgs = {
  where: ClinicPetAlertWhereUniqueInput;
};

export type MutationUpsertOneClinicPetAlertArgs = {
  where: ClinicPetAlertWhereUniqueInput;
  create: ClinicPetAlertCreateInput;
  update: ClinicPetAlertUpdateInput;
};

export type MutationDeleteManyClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
};

export type MutationUpdateManyClinicPetAlertArgs = {
  data: ClinicPetAlertUpdateManyMutationInput;
  where?: Maybe<ClinicPetAlertWhereInput>;
};

export type MutationCreateOneClinicPetParentArgs = {
  data: ClinicPetParentCreateInput;
};

export type MutationUpdateOneClinicPetParentArgs = {
  data: ClinicPetParentUpdateInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type MutationDeleteOneClinicPetParentArgs = {
  where: ClinicPetParentWhereUniqueInput;
};

export type MutationUpsertOneClinicPetParentArgs = {
  where: ClinicPetParentWhereUniqueInput;
  create: ClinicPetParentCreateInput;
  update: ClinicPetParentUpdateInput;
};

export type MutationDeleteManyClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
};

export type MutationUpdateManyClinicPetParentArgs = {
  data: ClinicPetParentUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentWhereInput>;
};

export type MutationCreateOneClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationCreateInput;
};

export type MutationUpdateOneClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationUpdateInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type MutationDeleteOneClinicPimsIntegrationArgs = {
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type MutationUpsertOneClinicPimsIntegrationArgs = {
  where: ClinicPimsIntegrationWhereUniqueInput;
  create: ClinicPimsIntegrationCreateInput;
  update: ClinicPimsIntegrationUpdateInput;
};

export type MutationDeleteManyClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type MutationUpdateManyClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationUpdateManyMutationInput;
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type MutationCreateOneClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityCreateInput;
};

export type MutationUpdateOneClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityUpdateInput;
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};

export type MutationDeleteOneClinicPimsIntegrationCapabilityArgs = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};

export type MutationUpsertOneClinicPimsIntegrationCapabilityArgs = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
  create: ClinicPimsIntegrationCapabilityCreateInput;
  update: ClinicPimsIntegrationCapabilityUpdateInput;
};

export type MutationDeleteManyClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};

export type MutationUpdateManyClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityUpdateManyMutationInput;
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};

export type MutationCreateOneClinicRoomArgs = {
  data: ClinicRoomCreateInput;
};

export type MutationUpdateOneClinicRoomArgs = {
  data: ClinicRoomUpdateInput;
  where: ClinicRoomWhereUniqueInput;
};

export type MutationDeleteOneClinicRoomArgs = {
  where: ClinicRoomWhereUniqueInput;
};

export type MutationUpsertOneClinicRoomArgs = {
  where: ClinicRoomWhereUniqueInput;
  create: ClinicRoomCreateInput;
  update: ClinicRoomUpdateInput;
};

export type MutationDeleteManyClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
};

export type MutationUpdateManyClinicRoomArgs = {
  data: ClinicRoomUpdateManyMutationInput;
  where?: Maybe<ClinicRoomWhereInput>;
};

export type MutationCreateOneClinicSettingArgs = {
  data: ClinicSettingCreateInput;
};

export type MutationUpdateOneClinicSettingArgs = {
  data: ClinicSettingUpdateInput;
  where: ClinicSettingWhereUniqueInput;
};

export type MutationDeleteOneClinicSettingArgs = {
  where: ClinicSettingWhereUniqueInput;
};

export type MutationUpsertOneClinicSettingArgs = {
  where: ClinicSettingWhereUniqueInput;
  create: ClinicSettingCreateInput;
  update: ClinicSettingUpdateInput;
};

export type MutationDeleteManyClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
};

export type MutationUpdateManyClinicSettingArgs = {
  data: ClinicSettingUpdateManyMutationInput;
  where?: Maybe<ClinicSettingWhereInput>;
};

export type MutationCreateOneClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestCreateInput;
};

export type MutationUpdateOneClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestUpdateInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type MutationDeleteOneClinicWidgetRequestArgs = {
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type MutationUpsertOneClinicWidgetRequestArgs = {
  where: ClinicWidgetRequestWhereUniqueInput;
  create: ClinicWidgetRequestCreateInput;
  update: ClinicWidgetRequestUpdateInput;
};

export type MutationDeleteManyClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};

export type MutationUpdateManyClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};

export type MutationCreateOneClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeCreateInput;
};

export type MutationUpdateOneClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeUpdateInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type MutationDeleteOneClinicWidgetRequestTypeArgs = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type MutationUpsertOneClinicWidgetRequestTypeArgs = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
  create: ClinicWidgetRequestTypeCreateInput;
  update: ClinicWidgetRequestTypeUpdateInput;
};

export type MutationDeleteManyClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export type MutationUpdateManyClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export type MutationCreateOneClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingCreateInput;
};

export type MutationUpdateOneClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingUpdateInput;
  where: ClinicWidgetSettingWhereUniqueInput;
};

export type MutationDeleteOneClinicWidgetSettingArgs = {
  where: ClinicWidgetSettingWhereUniqueInput;
};

export type MutationUpsertOneClinicWidgetSettingArgs = {
  where: ClinicWidgetSettingWhereUniqueInput;
  create: ClinicWidgetSettingCreateInput;
  update: ClinicWidgetSettingUpdateInput;
};

export type MutationDeleteManyClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};

export type MutationUpdateManyClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};

export type MutationCreateOneConsultationRequestArgs = {
  data: ConsultationRequestCreateInput;
};

export type MutationUpdateOneConsultationRequestArgs = {
  data: ConsultationRequestUpdateInput;
  where: ConsultationRequestWhereUniqueInput;
};

export type MutationDeleteOneConsultationRequestArgs = {
  where: ConsultationRequestWhereUniqueInput;
};

export type MutationUpsertOneConsultationRequestArgs = {
  where: ConsultationRequestWhereUniqueInput;
  create: ConsultationRequestCreateInput;
  update: ConsultationRequestUpdateInput;
};

export type MutationDeleteManyConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
};

export type MutationUpdateManyConsultationRequestArgs = {
  data: ConsultationRequestUpdateManyMutationInput;
  where?: Maybe<ConsultationRequestWhereInput>;
};

export type MutationCreateOneDisbursementArgs = {
  data: DisbursementCreateInput;
};

export type MutationUpdateOneDisbursementArgs = {
  data: DisbursementUpdateInput;
  where: DisbursementWhereUniqueInput;
};

export type MutationDeleteOneDisbursementArgs = {
  where: DisbursementWhereUniqueInput;
};

export type MutationUpsertOneDisbursementArgs = {
  where: DisbursementWhereUniqueInput;
  create: DisbursementCreateInput;
  update: DisbursementUpdateInput;
};

export type MutationDeleteManyDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
};

export type MutationUpdateManyDisbursementArgs = {
  data: DisbursementUpdateManyMutationInput;
  where?: Maybe<DisbursementWhereInput>;
};

export type MutationCreateOneEzyVetIntegrationArgs = {
  data: EzyVetIntegrationCreateInput;
};

export type MutationUpdateOneEzyVetIntegrationArgs = {
  data: EzyVetIntegrationUpdateInput;
  where: EzyVetIntegrationWhereUniqueInput;
};

export type MutationDeleteOneEzyVetIntegrationArgs = {
  where: EzyVetIntegrationWhereUniqueInput;
};

export type MutationUpsertOneEzyVetIntegrationArgs = {
  where: EzyVetIntegrationWhereUniqueInput;
  create: EzyVetIntegrationCreateInput;
  update: EzyVetIntegrationUpdateInput;
};

export type MutationDeleteManyEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
};

export type MutationUpdateManyEzyVetIntegrationArgs = {
  data: EzyVetIntegrationUpdateManyMutationInput;
  where?: Maybe<EzyVetIntegrationWhereInput>;
};

export type MutationCreateOneFinancialTransactionArgs = {
  data: FinancialTransactionCreateInput;
};

export type MutationUpdateOneFinancialTransactionArgs = {
  data: FinancialTransactionUpdateInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type MutationDeleteOneFinancialTransactionArgs = {
  where: FinancialTransactionWhereUniqueInput;
};

export type MutationUpsertOneFinancialTransactionArgs = {
  where: FinancialTransactionWhereUniqueInput;
  create: FinancialTransactionCreateInput;
  update: FinancialTransactionUpdateInput;
};

export type MutationDeleteManyFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
};

export type MutationUpdateManyFinancialTransactionArgs = {
  data: FinancialTransactionUpdateManyMutationInput;
  where?: Maybe<FinancialTransactionWhereInput>;
};

export type MutationCreateOneFormSubmissionArgs = {
  data: FormSubmissionCreateInput;
};

export type MutationUpdateOneFormSubmissionArgs = {
  data: FormSubmissionUpdateInput;
  where: FormSubmissionWhereUniqueInput;
};

export type MutationDeleteOneFormSubmissionArgs = {
  where: FormSubmissionWhereUniqueInput;
};

export type MutationUpsertOneFormSubmissionArgs = {
  where: FormSubmissionWhereUniqueInput;
  create: FormSubmissionCreateInput;
  update: FormSubmissionUpdateInput;
};

export type MutationDeleteManyFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
};

export type MutationUpdateManyFormSubmissionArgs = {
  data: FormSubmissionUpdateManyMutationInput;
  where?: Maybe<FormSubmissionWhereInput>;
};

export type MutationCreateOneFormTemplateArgs = {
  data: FormTemplateCreateInput;
};

export type MutationUpdateOneFormTemplateArgs = {
  data: FormTemplateUpdateInput;
  where: FormTemplateWhereUniqueInput;
};

export type MutationDeleteOneFormTemplateArgs = {
  where: FormTemplateWhereUniqueInput;
};

export type MutationUpsertOneFormTemplateArgs = {
  where: FormTemplateWhereUniqueInput;
  create: FormTemplateCreateInput;
  update: FormTemplateUpdateInput;
};

export type MutationDeleteManyFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
};

export type MutationUpdateManyFormTemplateArgs = {
  data: FormTemplateUpdateManyMutationInput;
  where?: Maybe<FormTemplateWhereInput>;
};

export type MutationCreateOneInvoiceArgs = {
  data: InvoiceCreateInput;
};

export type MutationUpdateOneInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};

export type MutationDeleteOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};

export type MutationUpsertOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceCreateInput;
  update: InvoiceUpdateInput;
};

export type MutationDeleteManyInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
};

export type MutationUpdateManyInvoiceArgs = {
  data: InvoiceUpdateManyMutationInput;
  where?: Maybe<InvoiceWhereInput>;
};

export type MutationCreateOneInvoiceLineItemArgs = {
  data: InvoiceLineItemCreateInput;
};

export type MutationUpdateOneInvoiceLineItemArgs = {
  data: InvoiceLineItemUpdateInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type MutationDeleteOneInvoiceLineItemArgs = {
  where: InvoiceLineItemWhereUniqueInput;
};

export type MutationUpsertOneInvoiceLineItemArgs = {
  where: InvoiceLineItemWhereUniqueInput;
  create: InvoiceLineItemCreateInput;
  update: InvoiceLineItemUpdateInput;
};

export type MutationDeleteManyInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
};

export type MutationUpdateManyInvoiceLineItemArgs = {
  data: InvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<InvoiceLineItemWhereInput>;
};

export type MutationCreateOneOrganizationArgs = {
  data: OrganizationCreateInput;
};

export type MutationUpdateOneOrganizationArgs = {
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};

export type MutationDeleteOneOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};

export type MutationUpsertOneOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateInput;
  update: OrganizationUpdateInput;
};

export type MutationDeleteManyOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
};

export type MutationUpdateManyOrganizationArgs = {
  data: OrganizationUpdateManyMutationInput;
  where?: Maybe<OrganizationWhereInput>;
};

export type MutationCreateOnePermissionArgs = {
  data: PermissionCreateInput;
};

export type MutationUpdateOnePermissionArgs = {
  data: PermissionUpdateInput;
  where: PermissionWhereUniqueInput;
};

export type MutationDeleteOnePermissionArgs = {
  where: PermissionWhereUniqueInput;
};

export type MutationUpsertOnePermissionArgs = {
  where: PermissionWhereUniqueInput;
  create: PermissionCreateInput;
  update: PermissionUpdateInput;
};

export type MutationDeleteManyPermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
};

export type MutationUpdateManyPermissionArgs = {
  data: PermissionUpdateManyMutationInput;
  where?: Maybe<PermissionWhereInput>;
};

export type MutationCreateOnePetParentSettingArgs = {
  data: PetParentSettingCreateInput;
};

export type MutationUpdateOnePetParentSettingArgs = {
  data: PetParentSettingUpdateInput;
  where: PetParentSettingWhereUniqueInput;
};

export type MutationDeleteOnePetParentSettingArgs = {
  where: PetParentSettingWhereUniqueInput;
};

export type MutationUpsertOnePetParentSettingArgs = {
  where: PetParentSettingWhereUniqueInput;
  create: PetParentSettingCreateInput;
  update: PetParentSettingUpdateInput;
};

export type MutationDeleteManyPetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
};

export type MutationUpdateManyPetParentSettingArgs = {
  data: PetParentSettingUpdateManyMutationInput;
  where?: Maybe<PetParentSettingWhereInput>;
};

export type MutationCreateOnePimsInvoiceArgs = {
  data: PimsInvoiceCreateInput;
};

export type MutationUpdateOnePimsInvoiceArgs = {
  data: PimsInvoiceUpdateInput;
  where: PimsInvoiceWhereUniqueInput;
};

export type MutationDeleteOnePimsInvoiceArgs = {
  where: PimsInvoiceWhereUniqueInput;
};

export type MutationUpsertOnePimsInvoiceArgs = {
  where: PimsInvoiceWhereUniqueInput;
  create: PimsInvoiceCreateInput;
  update: PimsInvoiceUpdateInput;
};

export type MutationDeleteManyPimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
};

export type MutationUpdateManyPimsInvoiceArgs = {
  data: PimsInvoiceUpdateManyMutationInput;
  where?: Maybe<PimsInvoiceWhereInput>;
};

export type MutationCreateOnePimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemCreateInput;
};

export type MutationUpdateOnePimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemUpdateInput;
  where: PimsInvoiceLineItemWhereUniqueInput;
};

export type MutationDeleteOnePimsInvoiceLineItemArgs = {
  where: PimsInvoiceLineItemWhereUniqueInput;
};

export type MutationUpsertOnePimsInvoiceLineItemArgs = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  create: PimsInvoiceLineItemCreateInput;
  update: PimsInvoiceLineItemUpdateInput;
};

export type MutationDeleteManyPimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};

export type MutationUpdateManyPimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};

export type MutationCreateOneServiceArgs = {
  data: ServiceCreateInput;
};

export type MutationUpdateOneServiceArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};

export type MutationDeleteOneServiceArgs = {
  where: ServiceWhereUniqueInput;
};

export type MutationUpsertOneServiceArgs = {
  where: ServiceWhereUniqueInput;
  create: ServiceCreateInput;
  update: ServiceUpdateInput;
};

export type MutationDeleteManyServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
};

export type MutationUpdateManyServiceArgs = {
  data: ServiceUpdateManyMutationInput;
  where?: Maybe<ServiceWhereInput>;
};

export type MutationCreateOneServiceReminderTimingArgs = {
  data: ServiceReminderTimingCreateInput;
};

export type MutationUpdateOneServiceReminderTimingArgs = {
  data: ServiceReminderTimingUpdateInput;
  where: ServiceReminderTimingWhereUniqueInput;
};

export type MutationDeleteOneServiceReminderTimingArgs = {
  where: ServiceReminderTimingWhereUniqueInput;
};

export type MutationUpsertOneServiceReminderTimingArgs = {
  where: ServiceReminderTimingWhereUniqueInput;
  create: ServiceReminderTimingCreateInput;
  update: ServiceReminderTimingUpdateInput;
};

export type MutationDeleteManyServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
};

export type MutationUpdateManyServiceReminderTimingArgs = {
  data: ServiceReminderTimingUpdateManyMutationInput;
  where?: Maybe<ServiceReminderTimingWhereInput>;
};

export type MutationCreateOneServiceReminderArgs = {
  data: ServiceReminderCreateInput;
};

export type MutationUpdateOneServiceReminderArgs = {
  data: ServiceReminderUpdateInput;
  where: ServiceReminderWhereUniqueInput;
};

export type MutationDeleteOneServiceReminderArgs = {
  where: ServiceReminderWhereUniqueInput;
};

export type MutationUpsertOneServiceReminderArgs = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderCreateInput;
  update: ServiceReminderUpdateInput;
};

export type MutationDeleteManyServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
};

export type MutationUpdateManyServiceReminderArgs = {
  data: ServiceReminderUpdateManyMutationInput;
  where?: Maybe<ServiceReminderWhereInput>;
};

export type MutationCreateOneServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationCreateInput;
};

export type MutationUpdateOneServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationUpdateInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type MutationDeleteOneServiceReminderNotificationArgs = {
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type MutationUpsertOneServiceReminderNotificationArgs = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationCreateInput;
  update: ServiceReminderNotificationUpdateInput;
};

export type MutationDeleteManyServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type MutationUpdateManyServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationUpdateManyMutationInput;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type MutationCreateOneStaffRoleArgs = {
  data: StaffRoleCreateInput;
};

export type MutationUpdateOneStaffRoleArgs = {
  data: StaffRoleUpdateInput;
  where: StaffRoleWhereUniqueInput;
};

export type MutationDeleteOneStaffRoleArgs = {
  where: StaffRoleWhereUniqueInput;
};

export type MutationUpsertOneStaffRoleArgs = {
  where: StaffRoleWhereUniqueInput;
  create: StaffRoleCreateInput;
  update: StaffRoleUpdateInput;
};

export type MutationDeleteManyStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
};

export type MutationUpdateManyStaffRoleArgs = {
  data: StaffRoleUpdateManyMutationInput;
  where?: Maybe<StaffRoleWhereInput>;
};

export type MutationCreateOneStripeDisputeArgs = {
  data: StripeDisputeCreateInput;
};

export type MutationUpdateOneStripeDisputeArgs = {
  data: StripeDisputeUpdateInput;
  where: StripeDisputeWhereUniqueInput;
};

export type MutationDeleteOneStripeDisputeArgs = {
  where: StripeDisputeWhereUniqueInput;
};

export type MutationUpsertOneStripeDisputeArgs = {
  where: StripeDisputeWhereUniqueInput;
  create: StripeDisputeCreateInput;
  update: StripeDisputeUpdateInput;
};

export type MutationDeleteManyStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
};

export type MutationUpdateManyStripeDisputeArgs = {
  data: StripeDisputeUpdateManyMutationInput;
  where?: Maybe<StripeDisputeWhereInput>;
};

export type MutationCreateOneStripePaymentIntentArgs = {
  data: StripePaymentIntentCreateInput;
};

export type MutationUpdateOneStripePaymentIntentArgs = {
  data: StripePaymentIntentUpdateInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type MutationDeleteOneStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};

export type MutationUpsertOneStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentCreateInput;
  update: StripePaymentIntentUpdateInput;
};

export type MutationDeleteManyStripePaymentIntentArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
};

export type MutationUpdateManyStripePaymentIntentArgs = {
  data: StripePaymentIntentUpdateManyMutationInput;
  where?: Maybe<StripePaymentIntentWhereInput>;
};

export type MutationCreateOneStripePaymentMethodArgs = {
  data: StripePaymentMethodCreateInput;
};

export type MutationUpdateOneStripePaymentMethodArgs = {
  data: StripePaymentMethodUpdateInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type MutationDeleteOneStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};

export type MutationUpsertOneStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodCreateInput;
  update: StripePaymentMethodUpdateInput;
};

export type MutationDeleteManyStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
};

export type MutationUpdateManyStripePaymentMethodArgs = {
  data: StripePaymentMethodUpdateManyMutationInput;
  where?: Maybe<StripePaymentMethodWhereInput>;
};

export type MutationCreateOneStripePayoutArgs = {
  data: StripePayoutCreateInput;
};

export type MutationUpdateOneStripePayoutArgs = {
  data: StripePayoutUpdateInput;
  where: StripePayoutWhereUniqueInput;
};

export type MutationDeleteOneStripePayoutArgs = {
  where: StripePayoutWhereUniqueInput;
};

export type MutationUpsertOneStripePayoutArgs = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutCreateInput;
  update: StripePayoutUpdateInput;
};

export type MutationDeleteManyStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
};

export type MutationUpdateManyStripePayoutArgs = {
  data: StripePayoutUpdateManyMutationInput;
  where?: Maybe<StripePayoutWhereInput>;
};

export type MutationCreateOneStripePayoutAccountArgs = {
  data: StripePayoutAccountCreateInput;
};

export type MutationUpdateOneStripePayoutAccountArgs = {
  data: StripePayoutAccountUpdateInput;
  where: StripePayoutAccountWhereUniqueInput;
};

export type MutationDeleteOneStripePayoutAccountArgs = {
  where: StripePayoutAccountWhereUniqueInput;
};

export type MutationUpsertOneStripePayoutAccountArgs = {
  where: StripePayoutAccountWhereUniqueInput;
  create: StripePayoutAccountCreateInput;
  update: StripePayoutAccountUpdateInput;
};

export type MutationDeleteManyStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
};

export type MutationUpdateManyStripePayoutAccountArgs = {
  data: StripePayoutAccountUpdateManyMutationInput;
  where?: Maybe<StripePayoutAccountWhereInput>;
};

export type MutationCreateOneStripeReceiptArgs = {
  data: StripeReceiptCreateInput;
};

export type MutationUpdateOneStripeReceiptArgs = {
  data: StripeReceiptUpdateInput;
  where: StripeReceiptWhereUniqueInput;
};

export type MutationDeleteOneStripeReceiptArgs = {
  where: StripeReceiptWhereUniqueInput;
};

export type MutationUpsertOneStripeReceiptArgs = {
  where: StripeReceiptWhereUniqueInput;
  create: StripeReceiptCreateInput;
  update: StripeReceiptUpdateInput;
};

export type MutationDeleteManyStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
};

export type MutationUpdateManyStripeReceiptArgs = {
  data: StripeReceiptUpdateManyMutationInput;
  where?: Maybe<StripeReceiptWhereInput>;
};

export type MutationCreateOneStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryCreateInput;
};

export type MutationUpdateOneStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryUpdateInput;
  where: StripeReceiptHistoryWhereUniqueInput;
};

export type MutationDeleteOneStripeReceiptHistoryArgs = {
  where: StripeReceiptHistoryWhereUniqueInput;
};

export type MutationUpsertOneStripeReceiptHistoryArgs = {
  where: StripeReceiptHistoryWhereUniqueInput;
  create: StripeReceiptHistoryCreateInput;
  update: StripeReceiptHistoryUpdateInput;
};

export type MutationDeleteManyStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};

export type MutationUpdateManyStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryUpdateManyMutationInput;
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};

export type MutationCreateOneStripeRefundArgs = {
  data: StripeRefundCreateInput;
};

export type MutationUpdateOneStripeRefundArgs = {
  data: StripeRefundUpdateInput;
  where: StripeRefundWhereUniqueInput;
};

export type MutationDeleteOneStripeRefundArgs = {
  where: StripeRefundWhereUniqueInput;
};

export type MutationUpsertOneStripeRefundArgs = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundCreateInput;
  update: StripeRefundUpdateInput;
};

export type MutationDeleteManyStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
};

export type MutationUpdateManyStripeRefundArgs = {
  data: StripeRefundUpdateManyMutationInput;
  where?: Maybe<StripeRefundWhereInput>;
};

export type MutationCreateOneStripeTerminalArgs = {
  data: StripeTerminalCreateInput;
};

export type MutationUpdateOneStripeTerminalArgs = {
  data: StripeTerminalUpdateInput;
  where: StripeTerminalWhereUniqueInput;
};

export type MutationDeleteOneStripeTerminalArgs = {
  where: StripeTerminalWhereUniqueInput;
};

export type MutationUpsertOneStripeTerminalArgs = {
  where: StripeTerminalWhereUniqueInput;
  create: StripeTerminalCreateInput;
  update: StripeTerminalUpdateInput;
};

export type MutationDeleteManyStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
};

export type MutationUpdateManyStripeTerminalArgs = {
  data: StripeTerminalUpdateManyMutationInput;
  where?: Maybe<StripeTerminalWhereInput>;
};

export type MutationCreateOneStripeTerminalLocationArgs = {
  data: StripeTerminalLocationCreateInput;
};

export type MutationUpdateOneStripeTerminalLocationArgs = {
  data: StripeTerminalLocationUpdateInput;
  where: StripeTerminalLocationWhereUniqueInput;
};

export type MutationDeleteOneStripeTerminalLocationArgs = {
  where: StripeTerminalLocationWhereUniqueInput;
};

export type MutationUpsertOneStripeTerminalLocationArgs = {
  where: StripeTerminalLocationWhereUniqueInput;
  create: StripeTerminalLocationCreateInput;
  update: StripeTerminalLocationUpdateInput;
};

export type MutationDeleteManyStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
};

export type MutationUpdateManyStripeTerminalLocationArgs = {
  data: StripeTerminalLocationUpdateManyMutationInput;
  where?: Maybe<StripeTerminalLocationWhereInput>;
};

export type MutationCreateOneTransactionArgs = {
  data: TransactionCreateInput;
};

export type MutationUpdateOneTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};

export type MutationDeleteOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type MutationUpsertOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
};

export type MutationDeleteManyTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
};

export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: Maybe<TransactionWhereInput>;
};

export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};

export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationUpsertOneUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};

export type MutationDeleteManyUserArgs = {
  where?: Maybe<UserWhereInput>;
};

export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};

export type MutationCreateOneUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingCreateInput;
};

export type MutationUpdateOneUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingUpdateInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type MutationDeleteOneUserAppointmentGroupingArgs = {
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type MutationUpsertOneUserAppointmentGroupingArgs = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingCreateInput;
  update: UserAppointmentGroupingUpdateInput;
};

export type MutationDeleteManyUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};

export type MutationUpdateManyUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingUpdateManyMutationInput;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};

export type MutationCreateOneUserNotificationSettingArgs = {
  data: UserNotificationSettingCreateInput;
};

export type MutationUpdateOneUserNotificationSettingArgs = {
  data: UserNotificationSettingUpdateInput;
  where: UserNotificationSettingWhereUniqueInput;
};

export type MutationDeleteOneUserNotificationSettingArgs = {
  where: UserNotificationSettingWhereUniqueInput;
};

export type MutationUpsertOneUserNotificationSettingArgs = {
  where: UserNotificationSettingWhereUniqueInput;
  create: UserNotificationSettingCreateInput;
  update: UserNotificationSettingUpdateInput;
};

export type MutationDeleteManyUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
};

export type MutationUpdateManyUserNotificationSettingArgs = {
  data: UserNotificationSettingUpdateManyMutationInput;
  where?: Maybe<UserNotificationSettingWhereInput>;
};

export type MutationCreateOneUserSettingArgs = {
  data: UserSettingCreateInput;
};

export type MutationUpdateOneUserSettingArgs = {
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
};

export type MutationDeleteOneUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};

export type MutationUpsertOneUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingCreateInput;
  update: UserSettingUpdateInput;
};

export type MutationDeleteManyUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
};

export type MutationUpdateManyUserSettingArgs = {
  data: UserSettingUpdateManyMutationInput;
  where?: Maybe<UserSettingWhereInput>;
};

export type MutationCreateOneVetInfoArgs = {
  data: VetInfoCreateInput;
};

export type MutationUpdateOneVetInfoArgs = {
  data: VetInfoUpdateInput;
  where: VetInfoWhereUniqueInput;
};

export type MutationDeleteOneVetInfoArgs = {
  where: VetInfoWhereUniqueInput;
};

export type MutationUpsertOneVetInfoArgs = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoCreateInput;
  update: VetInfoUpdateInput;
};

export type MutationDeleteManyVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
};

export type MutationUpdateManyVetInfoArgs = {
  data: VetInfoUpdateManyMutationInput;
  where?: Maybe<VetInfoWhereInput>;
};

export type MutationCreateOneWorkflowEventSettingArgs = {
  data: WorkflowEventSettingCreateInput;
};

export type MutationUpdateOneWorkflowEventSettingArgs = {
  data: WorkflowEventSettingUpdateInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type MutationDeleteOneWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
};

export type MutationUpsertOneWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingCreateInput;
  update: WorkflowEventSettingUpdateInput;
};

export type MutationDeleteManyWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type MutationUpdateManyWorkflowEventSettingArgs = {
  data: WorkflowEventSettingUpdateManyMutationInput;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type MutationCreateOneClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberCreateInput;
};

export type MutationUpdateOneClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberUpdateInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type MutationDeleteOneClinicEntityPhoneNumberArgs = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type MutationUpsertOneClinicEntityPhoneNumberArgs = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
  create: ClinicEntityPhoneNumberCreateInput;
  update: ClinicEntityPhoneNumberUpdateInput;
};

export type MutationDeleteManyClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};

export type MutationUpdateManyClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberUpdateManyMutationInput;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};

export type MutationCreateOneClinicOfficeHourArgs = {
  data: ClinicOfficeHourCreateInput;
};

export type MutationUpdateOneClinicOfficeHourArgs = {
  data: ClinicOfficeHourUpdateInput;
  where: ClinicOfficeHourWhereUniqueInput;
};

export type MutationDeleteOneClinicOfficeHourArgs = {
  where: ClinicOfficeHourWhereUniqueInput;
};

export type MutationUpsertOneClinicOfficeHourArgs = {
  where: ClinicOfficeHourWhereUniqueInput;
  create: ClinicOfficeHourCreateInput;
  update: ClinicOfficeHourUpdateInput;
};

export type MutationDeleteManyClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
};

export type MutationUpdateManyClinicOfficeHourArgs = {
  data: ClinicOfficeHourUpdateManyMutationInput;
  where?: Maybe<ClinicOfficeHourWhereInput>;
};

export type MutationCreateOneEmailProviderLogArgs = {
  data: EmailProviderLogCreateInput;
};

export type MutationUpdateOneEmailProviderLogArgs = {
  data: EmailProviderLogUpdateInput;
  where: EmailProviderLogWhereUniqueInput;
};

export type MutationDeleteOneEmailProviderLogArgs = {
  where: EmailProviderLogWhereUniqueInput;
};

export type MutationUpsertOneEmailProviderLogArgs = {
  where: EmailProviderLogWhereUniqueInput;
  create: EmailProviderLogCreateInput;
  update: EmailProviderLogUpdateInput;
};

export type MutationDeleteManyEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
};

export type MutationUpdateManyEmailProviderLogArgs = {
  data: EmailProviderLogUpdateManyMutationInput;
  where?: Maybe<EmailProviderLogWhereInput>;
};

export type MutationCreateOneSmsNotificationStatusArgs = {
  data: SmsNotificationStatusCreateInput;
};

export type MutationUpdateOneSmsNotificationStatusArgs = {
  data: SmsNotificationStatusUpdateInput;
  where: SmsNotificationStatusWhereUniqueInput;
};

export type MutationDeleteOneSmsNotificationStatusArgs = {
  where: SmsNotificationStatusWhereUniqueInput;
};

export type MutationUpsertOneSmsNotificationStatusArgs = {
  where: SmsNotificationStatusWhereUniqueInput;
  create: SmsNotificationStatusCreateInput;
  update: SmsNotificationStatusUpdateInput;
};

export type MutationDeleteManySmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
};

export type MutationUpdateManySmsNotificationStatusArgs = {
  data: SmsNotificationStatusUpdateManyMutationInput;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};

export type MutationCreateOneFeatureFlagArgs = {
  data: FeatureFlagCreateInput;
};

export type MutationUpdateOneFeatureFlagArgs = {
  data: FeatureFlagUpdateInput;
  where: FeatureFlagWhereUniqueInput;
};

export type MutationDeleteOneFeatureFlagArgs = {
  where: FeatureFlagWhereUniqueInput;
};

export type MutationUpsertOneFeatureFlagArgs = {
  where: FeatureFlagWhereUniqueInput;
  create: FeatureFlagCreateInput;
  update: FeatureFlagUpdateInput;
};

export type MutationDeleteManyFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
};

export type MutationUpdateManyFeatureFlagArgs = {
  data: FeatureFlagUpdateManyMutationInput;
  where?: Maybe<FeatureFlagWhereInput>;
};

export type MutationCreateOneCallHistoryArgs = {
  data: CallHistoryCreateInput;
};

export type MutationUpdateOneCallHistoryArgs = {
  data: CallHistoryUpdateInput;
  where: CallHistoryWhereUniqueInput;
};

export type MutationDeleteOneCallHistoryArgs = {
  where: CallHistoryWhereUniqueInput;
};

export type MutationUpsertOneCallHistoryArgs = {
  where: CallHistoryWhereUniqueInput;
  create: CallHistoryCreateInput;
  update: CallHistoryUpdateInput;
};

export type MutationDeleteManyCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
};

export type MutationUpdateManyCallHistoryArgs = {
  data: CallHistoryUpdateManyMutationInput;
  where?: Maybe<CallHistoryWhereInput>;
};

export type MutationCreateOneCallParticipantArgs = {
  data: CallParticipantCreateInput;
};

export type MutationUpdateOneCallParticipantArgs = {
  data: CallParticipantUpdateInput;
  where: CallParticipantWhereUniqueInput;
};

export type MutationDeleteOneCallParticipantArgs = {
  where: CallParticipantWhereUniqueInput;
};

export type MutationUpsertOneCallParticipantArgs = {
  where: CallParticipantWhereUniqueInput;
  create: CallParticipantCreateInput;
  update: CallParticipantUpdateInput;
};

export type MutationDeleteManyCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
};

export type MutationUpdateManyCallParticipantArgs = {
  data: CallParticipantUpdateManyMutationInput;
  where?: Maybe<CallParticipantWhereInput>;
};

export type MutationCreateOneChannelSelectionArgs = {
  data: ChannelSelectionCreateInput;
};

export type MutationUpdateOneChannelSelectionArgs = {
  data: ChannelSelectionUpdateInput;
  where: ChannelSelectionWhereUniqueInput;
};

export type MutationDeleteOneChannelSelectionArgs = {
  where: ChannelSelectionWhereUniqueInput;
};

export type MutationUpsertOneChannelSelectionArgs = {
  where: ChannelSelectionWhereUniqueInput;
  create: ChannelSelectionCreateInput;
  update: ChannelSelectionUpdateInput;
};

export type MutationDeleteManyChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
};

export type MutationUpdateManyChannelSelectionArgs = {
  data: ChannelSelectionUpdateManyMutationInput;
  where?: Maybe<ChannelSelectionWhereInput>;
};

export type MutationCreateOneClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingCreateInput;
};

export type MutationUpdateOneClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingUpdateInput;
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};

export type MutationDeleteOneClinicEmailCampaignSettingArgs = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};

export type MutationUpsertOneClinicEmailCampaignSettingArgs = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
  create: ClinicEmailCampaignSettingCreateInput;
  update: ClinicEmailCampaignSettingUpdateInput;
};

export type MutationDeleteManyClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};

export type MutationUpdateManyClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingUpdateManyMutationInput;
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};

export type MutationCreateOneOrganizationPetArgs = {
  data: OrganizationPetCreateInput;
};

export type MutationUpdateOneOrganizationPetArgs = {
  data: OrganizationPetUpdateInput;
  where: OrganizationPetWhereUniqueInput;
};

export type MutationDeleteOneOrganizationPetArgs = {
  where: OrganizationPetWhereUniqueInput;
};

export type MutationUpsertOneOrganizationPetArgs = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetCreateInput;
  update: OrganizationPetUpdateInput;
};

export type MutationDeleteManyOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
};

export type MutationUpdateManyOrganizationPetArgs = {
  data: OrganizationPetUpdateManyMutationInput;
  where?: Maybe<OrganizationPetWhereInput>;
};

export type MutationCreateOneOrganizationPetParentArgs = {
  data: OrganizationPetParentCreateInput;
};

export type MutationUpdateOneOrganizationPetParentArgs = {
  data: OrganizationPetParentUpdateInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type MutationDeleteOneOrganizationPetParentArgs = {
  where: OrganizationPetParentWhereUniqueInput;
};

export type MutationUpsertOneOrganizationPetParentArgs = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentCreateInput;
  update: OrganizationPetParentUpdateInput;
};

export type MutationDeleteManyOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
};

export type MutationUpdateManyOrganizationPetParentArgs = {
  data: OrganizationPetParentUpdateManyMutationInput;
  where?: Maybe<OrganizationPetParentWhereInput>;
};

export type MutationCreateOneOrganizationPetToOrganizationPetParentArgs = {
  data: OrganizationPetToOrganizationPetParentCreateInput;
};

export type MutationUpdateOneOrganizationPetToOrganizationPetParentArgs = {
  data: OrganizationPetToOrganizationPetParentUpdateInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type MutationDeleteOneOrganizationPetToOrganizationPetParentArgs = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type MutationUpsertOneOrganizationPetToOrganizationPetParentArgs = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  create: OrganizationPetToOrganizationPetParentCreateInput;
  update: OrganizationPetToOrganizationPetParentUpdateInput;
};

export type MutationDeleteManyOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};

export type MutationCreateOneCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestCreateInput;
};

export type MutationUpdateOneCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestUpdateInput;
  where: CareAuthorizationRequestWhereUniqueInput;
};

export type MutationDeleteOneCareAuthorizationRequestArgs = {
  where: CareAuthorizationRequestWhereUniqueInput;
};

export type MutationUpsertOneCareAuthorizationRequestArgs = {
  where: CareAuthorizationRequestWhereUniqueInput;
  create: CareAuthorizationRequestCreateInput;
  update: CareAuthorizationRequestUpdateInput;
};

export type MutationDeleteManyCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};

export type MutationUpdateManyCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestUpdateManyMutationInput;
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};

export type MutationCreateOneCareBenefitArgs = {
  data: CareBenefitCreateInput;
};

export type MutationUpdateOneCareBenefitArgs = {
  data: CareBenefitUpdateInput;
  where: CareBenefitWhereUniqueInput;
};

export type MutationDeleteOneCareBenefitArgs = {
  where: CareBenefitWhereUniqueInput;
};

export type MutationUpsertOneCareBenefitArgs = {
  where: CareBenefitWhereUniqueInput;
  create: CareBenefitCreateInput;
  update: CareBenefitUpdateInput;
};

export type MutationDeleteManyCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
};

export type MutationUpdateManyCareBenefitArgs = {
  data: CareBenefitUpdateManyMutationInput;
  where?: Maybe<CareBenefitWhereInput>;
};

export type MutationCreateOneCarePlanArgs = {
  data: CarePlanCreateInput;
};

export type MutationUpdateOneCarePlanArgs = {
  data: CarePlanUpdateInput;
  where: CarePlanWhereUniqueInput;
};

export type MutationDeleteOneCarePlanArgs = {
  where: CarePlanWhereUniqueInput;
};

export type MutationUpsertOneCarePlanArgs = {
  where: CarePlanWhereUniqueInput;
  create: CarePlanCreateInput;
  update: CarePlanUpdateInput;
};

export type MutationDeleteManyCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
};

export type MutationUpdateManyCarePlanArgs = {
  data: CarePlanUpdateManyMutationInput;
  where?: Maybe<CarePlanWhereInput>;
};

export type MutationCreateOneCareAddonPackageArgs = {
  data: CareAddonPackageCreateInput;
};

export type MutationUpdateOneCareAddonPackageArgs = {
  data: CareAddonPackageUpdateInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type MutationDeleteOneCareAddonPackageArgs = {
  where: CareAddonPackageWhereUniqueInput;
};

export type MutationUpsertOneCareAddonPackageArgs = {
  where: CareAddonPackageWhereUniqueInput;
  create: CareAddonPackageCreateInput;
  update: CareAddonPackageUpdateInput;
};

export type MutationDeleteManyCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
};

export type MutationUpdateManyCareAddonPackageArgs = {
  data: CareAddonPackageUpdateManyMutationInput;
  where?: Maybe<CareAddonPackageWhereInput>;
};

export type MutationCreateOneCarePlanBenefitArgs = {
  data: CarePlanBenefitCreateInput;
};

export type MutationUpdateOneCarePlanBenefitArgs = {
  data: CarePlanBenefitUpdateInput;
  where: CarePlanBenefitWhereUniqueInput;
};

export type MutationDeleteOneCarePlanBenefitArgs = {
  where: CarePlanBenefitWhereUniqueInput;
};

export type MutationUpsertOneCarePlanBenefitArgs = {
  where: CarePlanBenefitWhereUniqueInput;
  create: CarePlanBenefitCreateInput;
  update: CarePlanBenefitUpdateInput;
};

export type MutationDeleteManyCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
};

export type MutationUpdateManyCarePlanBenefitArgs = {
  data: CarePlanBenefitUpdateManyMutationInput;
  where?: Maybe<CarePlanBenefitWhereInput>;
};

export type MutationCreateOneCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitCreateInput;
};

export type MutationUpdateOneCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitUpdateInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type MutationDeleteOneCareAddonPackageBenefitArgs = {
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type MutationUpsertOneCareAddonPackageBenefitArgs = {
  where: CareAddonPackageBenefitWhereUniqueInput;
  create: CareAddonPackageBenefitCreateInput;
  update: CareAddonPackageBenefitUpdateInput;
};

export type MutationDeleteManyCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};

export type MutationUpdateManyCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitUpdateManyMutationInput;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};

export type MutationCreateOneCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentCreateInput;
};

export type MutationUpdateOneCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentUpdateInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type MutationDeleteOneCarePlanEnrollmentArgs = {
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type MutationUpsertOneCarePlanEnrollmentArgs = {
  where: CarePlanEnrollmentWhereUniqueInput;
  create: CarePlanEnrollmentCreateInput;
  update: CarePlanEnrollmentUpdateInput;
};

export type MutationDeleteManyCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};

export type MutationUpdateManyCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentUpdateManyMutationInput;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};

export type MutationCreateOneCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentCreateInput;
};

export type MutationUpdateOneCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentUpdateInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type MutationDeleteOneCareAddonEnrollmentArgs = {
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type MutationUpsertOneCareAddonEnrollmentArgs = {
  where: CareAddonEnrollmentWhereUniqueInput;
  create: CareAddonEnrollmentCreateInput;
  update: CareAddonEnrollmentUpdateInput;
};

export type MutationDeleteManyCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};

export type MutationUpdateManyCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentUpdateManyMutationInput;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};

export type MutationCreateOneCareBenefitUsageArgs = {
  data: CareBenefitUsageCreateInput;
};

export type MutationUpdateOneCareBenefitUsageArgs = {
  data: CareBenefitUsageUpdateInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type MutationDeleteOneCareBenefitUsageArgs = {
  where: CareBenefitUsageWhereUniqueInput;
};

export type MutationUpsertOneCareBenefitUsageArgs = {
  where: CareBenefitUsageWhereUniqueInput;
  create: CareBenefitUsageCreateInput;
  update: CareBenefitUsageUpdateInput;
};

export type MutationDeleteManyCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
};

export type MutationUpdateManyCareBenefitUsageArgs = {
  data: CareBenefitUsageUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageWhereInput>;
};

export type MutationCreateOneCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionCreateInput;
};

export type MutationUpdateOneCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionUpdateInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type MutationDeleteOneCareStripeSubscriptionArgs = {
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type MutationUpsertOneCareStripeSubscriptionArgs = {
  where: CareStripeSubscriptionWhereUniqueInput;
  create: CareStripeSubscriptionCreateInput;
  update: CareStripeSubscriptionUpdateInput;
};

export type MutationDeleteManyCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};

export type MutationUpdateManyCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionUpdateManyMutationInput;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};

export type MutationCreateOneStripeCustomerArgs = {
  data: StripeCustomerCreateInput;
};

export type MutationUpdateOneStripeCustomerArgs = {
  data: StripeCustomerUpdateInput;
  where: StripeCustomerWhereUniqueInput;
};

export type MutationDeleteOneStripeCustomerArgs = {
  where: StripeCustomerWhereUniqueInput;
};

export type MutationUpsertOneStripeCustomerArgs = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerCreateInput;
  update: StripeCustomerUpdateInput;
};

export type MutationDeleteManyStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
};

export type MutationUpdateManyStripeCustomerArgs = {
  data: StripeCustomerUpdateManyMutationInput;
  where?: Maybe<StripeCustomerWhereInput>;
};

export type MutationCreateOnePimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryCreateInput;
};

export type MutationUpdateOnePimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryUpdateInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type MutationDeleteOnePimsWritebackLogEntryArgs = {
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type MutationUpsertOnePimsWritebackLogEntryArgs = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryCreateInput;
  update: PimsWritebackLogEntryUpdateInput;
};

export type MutationDeleteManyPimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export type MutationUpdateManyPimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryUpdateManyMutationInput;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export type MutationCreateOneChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusCreateInput;
};

export type MutationUpdateOneChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusUpdateInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type MutationDeleteOneChannelAutomationStatusArgs = {
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type MutationUpsertOneChannelAutomationStatusArgs = {
  where: ChannelAutomationStatusWhereUniqueInput;
  create: ChannelAutomationStatusCreateInput;
  update: ChannelAutomationStatusUpdateInput;
};

export type MutationDeleteManyChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};

export type MutationUpdateManyChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusUpdateManyMutationInput;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};

export type MutationCreateOneChannelCreationSourceArgs = {
  data: ChannelCreationSourceCreateInput;
};

export type MutationUpdateOneChannelCreationSourceArgs = {
  data: ChannelCreationSourceUpdateInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type MutationDeleteOneChannelCreationSourceArgs = {
  where: ChannelCreationSourceWhereUniqueInput;
};

export type MutationUpsertOneChannelCreationSourceArgs = {
  where: ChannelCreationSourceWhereUniqueInput;
  create: ChannelCreationSourceCreateInput;
  update: ChannelCreationSourceUpdateInput;
};

export type MutationDeleteManyChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
};

export type MutationUpdateManyChannelCreationSourceArgs = {
  data: ChannelCreationSourceUpdateManyMutationInput;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};

export type MutationCreateOneInstinctIntegrationArgs = {
  data: InstinctIntegrationCreateInput;
};

export type MutationUpdateOneInstinctIntegrationArgs = {
  data: InstinctIntegrationUpdateInput;
  where: InstinctIntegrationWhereUniqueInput;
};

export type MutationDeleteOneInstinctIntegrationArgs = {
  where: InstinctIntegrationWhereUniqueInput;
};

export type MutationUpsertOneInstinctIntegrationArgs = {
  where: InstinctIntegrationWhereUniqueInput;
  create: InstinctIntegrationCreateInput;
  update: InstinctIntegrationUpdateInput;
};

export type MutationDeleteManyInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
};

export type MutationUpdateManyInstinctIntegrationArgs = {
  data: InstinctIntegrationUpdateManyMutationInput;
  where?: Maybe<InstinctIntegrationWhereInput>;
};

export type MutationCreateOneClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingCreateInput;
};

export type MutationUpdateOneClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingUpdateInput;
  where: ClinicDirectBookingSettingWhereUniqueInput;
};

export type MutationDeleteOneClinicDirectBookingSettingArgs = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
};

export type MutationUpsertOneClinicDirectBookingSettingArgs = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
  create: ClinicDirectBookingSettingCreateInput;
  update: ClinicDirectBookingSettingUpdateInput;
};

export type MutationDeleteManyClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};

export type MutationUpdateManyClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingUpdateManyMutationInput;
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};

export type MutationCreateOneVetAvailabilityArgs = {
  data: VetAvailabilityCreateInput;
};

export type MutationUpdateOneVetAvailabilityArgs = {
  data: VetAvailabilityUpdateInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type MutationDeleteOneVetAvailabilityArgs = {
  where: VetAvailabilityWhereUniqueInput;
};

export type MutationUpsertOneVetAvailabilityArgs = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityCreateInput;
  update: VetAvailabilityUpdateInput;
};

export type MutationDeleteManyVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
};

export type MutationUpdateManyVetAvailabilityArgs = {
  data: VetAvailabilityUpdateManyMutationInput;
  where?: Maybe<VetAvailabilityWhereInput>;
};

export type MutationCreateOneVetAvailabilityMapArgs = {
  data: VetAvailabilityMapCreateInput;
};

export type MutationUpdateOneVetAvailabilityMapArgs = {
  data: VetAvailabilityMapUpdateInput;
  where: VetAvailabilityMapWhereUniqueInput;
};

export type MutationDeleteOneVetAvailabilityMapArgs = {
  where: VetAvailabilityMapWhereUniqueInput;
};

export type MutationUpsertOneVetAvailabilityMapArgs = {
  where: VetAvailabilityMapWhereUniqueInput;
  create: VetAvailabilityMapCreateInput;
  update: VetAvailabilityMapUpdateInput;
};

export type MutationDeleteManyVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
};

export type MutationUpdateManyVetAvailabilityMapArgs = {
  data: VetAvailabilityMapUpdateManyMutationInput;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};

export type MutationCreateOneStripeInvoiceArgs = {
  data: StripeInvoiceCreateInput;
};

export type MutationUpdateOneStripeInvoiceArgs = {
  data: StripeInvoiceUpdateInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type MutationDeleteOneStripeInvoiceArgs = {
  where: StripeInvoiceWhereUniqueInput;
};

export type MutationUpsertOneStripeInvoiceArgs = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceCreateInput;
  update: StripeInvoiceUpdateInput;
};

export type MutationDeleteManyStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
};

export type MutationUpdateManyStripeInvoiceArgs = {
  data: StripeInvoiceUpdateManyMutationInput;
  where?: Maybe<StripeInvoiceWhereInput>;
};

export type MutationCreateOneOrganizationCareBenefitToClinicServiceArgs = {
  data: OrganizationCareBenefitToClinicServiceCreateInput;
};

export type MutationUpdateOneOrganizationCareBenefitToClinicServiceArgs = {
  data: OrganizationCareBenefitToClinicServiceUpdateInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type MutationDeleteOneOrganizationCareBenefitToClinicServiceArgs = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type MutationUpsertOneOrganizationCareBenefitToClinicServiceArgs = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceCreateInput;
  update: OrganizationCareBenefitToClinicServiceUpdateInput;
};

export type MutationDeleteManyOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};

export type MutationCreateOneClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressCreateInput;
};

export type MutationUpdateOneClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressUpdateInput;
  where: ClinicPetParentAddressWhereUniqueInput;
};

export type MutationDeleteOneClinicPetParentAddressArgs = {
  where: ClinicPetParentAddressWhereUniqueInput;
};

export type MutationUpsertOneClinicPetParentAddressArgs = {
  where: ClinicPetParentAddressWhereUniqueInput;
  create: ClinicPetParentAddressCreateInput;
  update: ClinicPetParentAddressUpdateInput;
};

export type MutationDeleteManyClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};

export type MutationUpdateManyClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};

export type MutationCreateOneCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemCreateInput;
};

export type MutationUpdateOneCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemUpdateInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type MutationDeleteOneCareBenefitUsageToInvoiceLineItemArgs = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type MutationUpsertOneCareBenefitUsageToInvoiceLineItemArgs = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  create: CareBenefitUsageToInvoiceLineItemCreateInput;
  update: CareBenefitUsageToInvoiceLineItemUpdateInput;
};

export type MutationDeleteManyCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};

export type MutationUpdateManyCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};

export type MutationCreateOneChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionCreateInput;
};

export type MutationUpdateOneChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionUpdateInput;
  where: ChannelFilterSelectionWhereUniqueInput;
};

export type MutationDeleteOneChannelFilterSelectionArgs = {
  where: ChannelFilterSelectionWhereUniqueInput;
};

export type MutationUpsertOneChannelFilterSelectionArgs = {
  where: ChannelFilterSelectionWhereUniqueInput;
  create: ChannelFilterSelectionCreateInput;
  update: ChannelFilterSelectionUpdateInput;
};

export type MutationDeleteManyChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};

export type MutationUpdateManyChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionUpdateManyMutationInput;
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};

export type MutationCreateOneUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionCreateInput;
};

export type MutationUpdateOneUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionUpdateInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type MutationDeleteOneUserChannelFilterSelectionArgs = {
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type MutationUpsertOneUserChannelFilterSelectionArgs = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  create: UserChannelFilterSelectionCreateInput;
  update: UserChannelFilterSelectionUpdateInput;
};

export type MutationDeleteManyUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};

export type MutationUpdateManyUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionUpdateManyMutationInput;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};

export type MutationCreateOneMessageTemplateArgs = {
  data: MessageTemplateCreateInput;
};

export type MutationUpdateOneMessageTemplateArgs = {
  data: MessageTemplateUpdateInput;
  where: MessageTemplateWhereUniqueInput;
};

export type MutationDeleteOneMessageTemplateArgs = {
  where: MessageTemplateWhereUniqueInput;
};

export type MutationUpsertOneMessageTemplateArgs = {
  where: MessageTemplateWhereUniqueInput;
  create: MessageTemplateCreateInput;
  update: MessageTemplateUpdateInput;
};

export type MutationDeleteManyMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
};

export type MutationUpdateManyMessageTemplateArgs = {
  data: MessageTemplateUpdateManyMutationInput;
  where?: Maybe<MessageTemplateWhereInput>;
};

export type MutationCreateOneRulesArgs = {
  data: RulesCreateInput;
};

export type MutationUpdateOneRulesArgs = {
  data: RulesUpdateInput;
  where: RulesWhereUniqueInput;
};

export type MutationDeleteOneRulesArgs = {
  where: RulesWhereUniqueInput;
};

export type MutationUpsertOneRulesArgs = {
  where: RulesWhereUniqueInput;
  create: RulesCreateInput;
  update: RulesUpdateInput;
};

export type MutationDeleteManyRulesArgs = {
  where?: Maybe<RulesWhereInput>;
};

export type MutationUpdateManyRulesArgs = {
  data: RulesUpdateManyMutationInput;
  where?: Maybe<RulesWhereInput>;
};

export type MutationCreateOneCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemCreateInput;
};

export type MutationUpdateOneCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemUpdateInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type MutationDeleteOneCareEnrollmentToStripeInvoiceItemArgs = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type MutationUpsertOneCareEnrollmentToStripeInvoiceItemArgs = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
  create: CareEnrollmentToStripeInvoiceItemCreateInput;
  update: CareEnrollmentToStripeInvoiceItemUpdateInput;
};

export type MutationDeleteManyCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};

export type MutationUpdateManyCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemUpdateManyMutationInput;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};

export type MutationCreateOneStripeInvoiceItemArgs = {
  data: StripeInvoiceItemCreateInput;
};

export type MutationUpdateOneStripeInvoiceItemArgs = {
  data: StripeInvoiceItemUpdateInput;
  where: StripeInvoiceItemWhereUniqueInput;
};

export type MutationDeleteOneStripeInvoiceItemArgs = {
  where: StripeInvoiceItemWhereUniqueInput;
};

export type MutationUpsertOneStripeInvoiceItemArgs = {
  where: StripeInvoiceItemWhereUniqueInput;
  create: StripeInvoiceItemCreateInput;
  update: StripeInvoiceItemUpdateInput;
};

export type MutationDeleteManyStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
};

export type MutationUpdateManyStripeInvoiceItemArgs = {
  data: StripeInvoiceItemUpdateManyMutationInput;
  where?: Maybe<StripeInvoiceItemWhereInput>;
};

export type MutationCreateOneClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingCreateInput;
};

export type MutationUpdateOneClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingUpdateInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type MutationDeleteOneClinicEmployeeAppointmentTypeSettingArgs = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type MutationUpsertOneClinicEmployeeAppointmentTypeSettingArgs = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
  create: ClinicEmployeeAppointmentTypeSettingCreateInput;
  update: ClinicEmployeeAppointmentTypeSettingUpdateInput;
};

export type MutationDeleteManyClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};

export type MutationUpdateManyClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingUpdateManyMutationInput;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};

export type MutationCreateOneClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingCreateInput;
};

export type MutationUpdateOneClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingUpdateInput;
  where: ClinicPostcardSettingWhereUniqueInput;
};

export type MutationDeleteOneClinicPostcardSettingArgs = {
  where: ClinicPostcardSettingWhereUniqueInput;
};

export type MutationUpsertOneClinicPostcardSettingArgs = {
  where: ClinicPostcardSettingWhereUniqueInput;
  create: ClinicPostcardSettingCreateInput;
  update: ClinicPostcardSettingUpdateInput;
};

export type MutationDeleteManyClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};

export type MutationUpdateManyClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingUpdateManyMutationInput;
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};

export type MutationCreateOneInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryCreateInput;
};

export type MutationUpdateOneInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryUpdateInput;
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};

export type MutationDeleteOneInvoicePimsWritebackLogEntryArgs = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};

export type MutationUpsertOneInvoicePimsWritebackLogEntryArgs = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
  create: InvoicePimsWritebackLogEntryCreateInput;
  update: InvoicePimsWritebackLogEntryUpdateInput;
};

export type MutationDeleteManyInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export type MutationUpdateManyInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryUpdateManyMutationInput;
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export type MutationCreateOneNotificationEventToMessageArgs = {
  data: NotificationEventToMessageCreateInput;
};

export type MutationUpdateOneNotificationEventToMessageArgs = {
  data: NotificationEventToMessageUpdateInput;
  where: NotificationEventToMessageWhereUniqueInput;
};

export type MutationDeleteOneNotificationEventToMessageArgs = {
  where: NotificationEventToMessageWhereUniqueInput;
};

export type MutationUpsertOneNotificationEventToMessageArgs = {
  where: NotificationEventToMessageWhereUniqueInput;
  create: NotificationEventToMessageCreateInput;
  update: NotificationEventToMessageUpdateInput;
};

export type MutationDeleteManyNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
};

export type MutationUpdateManyNotificationEventToMessageArgs = {
  data: NotificationEventToMessageUpdateManyMutationInput;
  where?: Maybe<NotificationEventToMessageWhereInput>;
};

export type MutationCreateOneEmergencyClinicsArgs = {
  data: EmergencyClinicsCreateInput;
};

export type MutationUpdateOneEmergencyClinicsArgs = {
  data: EmergencyClinicsUpdateInput;
  where: EmergencyClinicsWhereUniqueInput;
};

export type MutationDeleteOneEmergencyClinicsArgs = {
  where: EmergencyClinicsWhereUniqueInput;
};

export type MutationUpsertOneEmergencyClinicsArgs = {
  where: EmergencyClinicsWhereUniqueInput;
  create: EmergencyClinicsCreateInput;
  update: EmergencyClinicsUpdateInput;
};

export type MutationDeleteManyEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
};

export type MutationUpdateManyEmergencyClinicsArgs = {
  data: EmergencyClinicsUpdateManyMutationInput;
  where?: Maybe<EmergencyClinicsWhereInput>;
};

export type MutationCreateOneCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentCreateInput;
};

export type MutationUpdateOneCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentUpdateInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type MutationDeleteOneCareEnrollmentPaymentArgs = {
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type MutationUpsertOneCareEnrollmentPaymentArgs = {
  where: CareEnrollmentPaymentWhereUniqueInput;
  create: CareEnrollmentPaymentCreateInput;
  update: CareEnrollmentPaymentUpdateInput;
};

export type MutationDeleteManyCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};

export type MutationUpdateManyCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentUpdateManyMutationInput;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};

export type MutationCreateOneAutomationRunArgs = {
  data: AutomationRunCreateInput;
};

export type MutationUpdateOneAutomationRunArgs = {
  data: AutomationRunUpdateInput;
  where: AutomationRunWhereUniqueInput;
};

export type MutationDeleteOneAutomationRunArgs = {
  where: AutomationRunWhereUniqueInput;
};

export type MutationUpsertOneAutomationRunArgs = {
  where: AutomationRunWhereUniqueInput;
  create: AutomationRunCreateInput;
  update: AutomationRunUpdateInput;
};

export type MutationDeleteManyAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
};

export type MutationUpdateManyAutomationRunArgs = {
  data: AutomationRunUpdateManyMutationInput;
  where?: Maybe<AutomationRunWhereInput>;
};

export type MutationCreateOneAutomationRunActionArgs = {
  data: AutomationRunActionCreateInput;
};

export type MutationUpdateOneAutomationRunActionArgs = {
  data: AutomationRunActionUpdateInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type MutationDeleteOneAutomationRunActionArgs = {
  where: AutomationRunActionWhereUniqueInput;
};

export type MutationUpsertOneAutomationRunActionArgs = {
  where: AutomationRunActionWhereUniqueInput;
  create: AutomationRunActionCreateInput;
  update: AutomationRunActionUpdateInput;
};

export type MutationDeleteManyAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
};

export type MutationUpdateManyAutomationRunActionArgs = {
  data: AutomationRunActionUpdateManyMutationInput;
  where?: Maybe<AutomationRunActionWhereInput>;
};

export type MutationCreateOneChannelMessageReactionArgs = {
  data: ChannelMessageReactionCreateInput;
};

export type MutationUpdateOneChannelMessageReactionArgs = {
  data: ChannelMessageReactionUpdateInput;
  where: ChannelMessageReactionWhereUniqueInput;
};

export type MutationDeleteOneChannelMessageReactionArgs = {
  where: ChannelMessageReactionWhereUniqueInput;
};

export type MutationUpsertOneChannelMessageReactionArgs = {
  where: ChannelMessageReactionWhereUniqueInput;
  create: ChannelMessageReactionCreateInput;
  update: ChannelMessageReactionUpdateInput;
};

export type MutationDeleteManyChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
};

export type MutationUpdateManyChannelMessageReactionArgs = {
  data: ChannelMessageReactionUpdateManyMutationInput;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};

export type MutationCreateOneVaccinationArgs = {
  data: VaccinationCreateInput;
};

export type MutationUpdateOneVaccinationArgs = {
  data: VaccinationUpdateInput;
  where: VaccinationWhereUniqueInput;
};

export type MutationDeleteOneVaccinationArgs = {
  where: VaccinationWhereUniqueInput;
};

export type MutationUpsertOneVaccinationArgs = {
  where: VaccinationWhereUniqueInput;
  create: VaccinationCreateInput;
  update: VaccinationUpdateInput;
};

export type MutationDeleteManyVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
};

export type MutationUpdateManyVaccinationArgs = {
  data: VaccinationUpdateManyMutationInput;
  where?: Maybe<VaccinationWhereInput>;
};

export type MutationCreateOneMassTextAlertArgs = {
  data: MassTextAlertCreateInput;
};

export type MutationUpdateOneMassTextAlertArgs = {
  data: MassTextAlertUpdateInput;
  where: MassTextAlertWhereUniqueInput;
};

export type MutationDeleteOneMassTextAlertArgs = {
  where: MassTextAlertWhereUniqueInput;
};

export type MutationUpsertOneMassTextAlertArgs = {
  where: MassTextAlertWhereUniqueInput;
  create: MassTextAlertCreateInput;
  update: MassTextAlertUpdateInput;
};

export type MutationDeleteManyMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
};

export type MutationUpdateManyMassTextAlertArgs = {
  data: MassTextAlertUpdateManyMutationInput;
  where?: Maybe<MassTextAlertWhereInput>;
};

export type MutationCreateOneMassTextAlertEntryArgs = {
  data: MassTextAlertEntryCreateInput;
};

export type MutationUpdateOneMassTextAlertEntryArgs = {
  data: MassTextAlertEntryUpdateInput;
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MutationDeleteOneMassTextAlertEntryArgs = {
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MutationUpsertOneMassTextAlertEntryArgs = {
  where: MassTextAlertEntryWhereUniqueInput;
  create: MassTextAlertEntryCreateInput;
  update: MassTextAlertEntryUpdateInput;
};

export type MutationDeleteManyMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
};

export type MutationUpdateManyMassTextAlertEntryArgs = {
  data: MassTextAlertEntryUpdateManyMutationInput;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};

export type MutationCreateOneMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentCreateInput;
};

export type MutationUpdateOneMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentUpdateInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type MutationDeleteOneMassTextAlertEntryAppointmentArgs = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type MutationUpsertOneMassTextAlertEntryAppointmentArgs = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
  create: MassTextAlertEntryAppointmentCreateInput;
  update: MassTextAlertEntryAppointmentUpdateInput;
};

export type MutationDeleteManyMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};

export type MutationUpdateManyMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentUpdateManyMutationInput;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};

export type MutationCreateOnePpcIntegrationArgs = {
  data: PpcIntegrationCreateInput;
};

export type MutationUpdateOnePpcIntegrationArgs = {
  data: PpcIntegrationUpdateInput;
  where: PpcIntegrationWhereUniqueInput;
};

export type MutationDeleteOnePpcIntegrationArgs = {
  where: PpcIntegrationWhereUniqueInput;
};

export type MutationUpsertOnePpcIntegrationArgs = {
  where: PpcIntegrationWhereUniqueInput;
  create: PpcIntegrationCreateInput;
  update: PpcIntegrationUpdateInput;
};

export type MutationDeleteManyPpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
};

export type MutationUpdateManyPpcIntegrationArgs = {
  data: PpcIntegrationUpdateManyMutationInput;
  where?: Maybe<PpcIntegrationWhereInput>;
};

export type MutationCreateOnePetPortalSettingArgs = {
  data: PetPortalSettingCreateInput;
};

export type MutationUpdateOnePetPortalSettingArgs = {
  data: PetPortalSettingUpdateInput;
  where: PetPortalSettingWhereUniqueInput;
};

export type MutationDeleteOnePetPortalSettingArgs = {
  where: PetPortalSettingWhereUniqueInput;
};

export type MutationUpsertOnePetPortalSettingArgs = {
  where: PetPortalSettingWhereUniqueInput;
  create: PetPortalSettingCreateInput;
  update: PetPortalSettingUpdateInput;
};

export type MutationDeleteManyPetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
};

export type MutationUpdateManyPetPortalSettingArgs = {
  data: PetPortalSettingUpdateManyMutationInput;
  where?: Maybe<PetPortalSettingWhereInput>;
};

export type MutationCreateOnePrescriptionArgs = {
  data: PrescriptionCreateInput;
};

export type MutationUpdateOnePrescriptionArgs = {
  data: PrescriptionUpdateInput;
  where: PrescriptionWhereUniqueInput;
};

export type MutationDeleteOnePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
};

export type MutationUpsertOnePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionCreateInput;
  update: PrescriptionUpdateInput;
};

export type MutationDeleteManyPrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
};

export type MutationUpdateManyPrescriptionArgs = {
  data: PrescriptionUpdateManyMutationInput;
  where?: Maybe<PrescriptionWhereInput>;
};

export type MutationCreateOneClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberCreateInput;
};

export type MutationUpdateOneClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberUpdateInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type MutationDeleteOneClinicPhoneNumberArgs = {
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type MutationUpsertOneClinicPhoneNumberArgs = {
  where: ClinicPhoneNumberWhereUniqueInput;
  create: ClinicPhoneNumberCreateInput;
  update: ClinicPhoneNumberUpdateInput;
};

export type MutationDeleteManyClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};

export type MutationUpdateManyClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberUpdateManyMutationInput;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};

export type MutationConfigureEmailCampaignsForClinicArgs = {
  data?: Maybe<ConfigureEmailCampaignsInput>;
};

export type MutationAddEmailCampaignsCareUrlFieldArgs = {
  clinicId: Scalars['String'];
};

export type MutationTriggerEmailCampaignsDataSyncArgs = {
  clinicId: Scalars['String'];
};

export type MutationConfirmAppointment2Args = {
  where?: Maybe<AppointmentWhereUniqueInput>;
};

export type MutationUpdateClinicAppointmentTypeSettingsArgs = {
  appointmentTypeId: Scalars['String'];
  displayName: Scalars['String'];
  showInPetPortal: Scalars['Boolean'];
};

export type MutationResetAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereUniqueInput>;
};

export type MutationGetSignedUploadUrlArgs = {
  data: GetSignedUploadInput;
};

export type MutationGetSignedDownloadUrlArgs = {
  assetUrl: Scalars['String'];
};

export type MutationCreateOneCallRecordingArgs = {
  callSid: Scalars['String'];
};

export type MutationUpdateOneCallRecordingArgs = {
  where: CallRecordingWhereUniqueInput;
  data: CallRecordingUpdateInput;
};

export type MutationDeleteOneCallRecordingArgs = {
  where: CallRecordingWhereUniqueInput;
};

export type MutationToggleTwilioVideoCallRecordingStatusArgs = {
  data: VideoCallRecordingToggleInput;
};

export type MutationRequestCareLoginLinkArgs = {
  data: AuthLinkRequestInput;
};

export type MutationSendDirectedCareLoginLinkArgs = {
  data: DirectedAuthLinkInput;
};

export type MutationGetDirectedCareLoginLinkArgs = {
  data: DirectedAuthLinkInput;
};

export type MutationRedeemOneTimeCodeArgs = {
  data: RedeemOneTimeCodeInput;
};

export type MutationRequestOneTimeCodeArgs = {
  data?: Maybe<RequestOneTimeCodeInput>;
};

export type MutationCreateCareSubscriptionArgs = {
  data?: Maybe<CreateCareSubscriptionInput>;
};

export type MutationUpdateCareSubscriptionArgs = {
  data?: Maybe<UpdateCareSubscriptionInput>;
};

export type MutationCancelCareSubscriptionArgs = {
  data?: Maybe<CancelCareSubscriptionInput>;
};

export type MutationChangeCareSubscriptionPaymentMethodArgs = {
  data?: Maybe<ChangeCareSubscriptionPaymentMethodInput>;
};

export type MutationSendEnrollmentEmailArgs = {
  data?: Maybe<SendEnrollmentEmailInput>;
};

export type MutationFormSubmissionExportToPimsArgs = {
  data?: Maybe<FormSubmissionExportInput>;
};

export type MutationChannelExportToPimsArgs = {
  where: ChannelExportWhereInput;
};

export type MutationAddChannelTagArgs = {
  channelId: Scalars['String'];
  tag?: Maybe<TagInput>;
};

export type MutationRemoveChannelTagArgs = {
  channelId: Scalars['String'];
  tagName?: Maybe<Scalars['String']>;
  tagType?: Maybe<TagType>;
};

export type MutationCreateTeamChannel2Args = {
  data: CreateTeamChannelInput;
};

export type MutationUpdateTeamChannel2Args = {
  data: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};

export type MutationGetOrCreateActiveClientChannelArgs = {
  data: GetOrCreateActiveClientChannelInput;
  creationSource?: Maybe<ChannelCreationSourceInput>;
};

export type MutationGetOrCreateActiveChannelArgs = {
  data: GetOrCreateActiveChannelInput;
};

export type MutationCreateChannelFilterSelectionArgs = {
  data?: Maybe<CreateChannelFilterSelectionInput>;
};

export type MutationUpdateChannelFilterSelectionArgs = {
  data?: Maybe<UpdateChannelFilterSelectionInput>;
};

export type MutationDeleteChannelFilterSelectionArgs = {
  id: Scalars['String'];
};

export type MutationAddChannelMember2Args = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MutationRemoveChannelMember2Args = {
  channelMemberId: Scalars['String'];
};

export type MutationCreateChannelMessage2Args = {
  data: ChannelMessageCreateInput;
};

export type MutationCreateSystemChannelMessage2Args = {
  data: SystemMessageCreateInput;
};

export type MutationClearUnreadMessages2Args = {
  data: ClearUnreadMessagesInput;
};

export type MutationCancelClinicSubscriptionArgs = {
  data: ClinicCancellationInput;
  where: ClinicWhereUniqueInput;
};

export type MutationUpdateClinicPetWithoutPimsArgs = {
  data: ClinicPetUpdateInput;
  where: ClinicPetWhereUniqueInput;
};

export type MutationSendClinicPetParentAuthLinkArgs = {
  data: SendClinicPetParentAuthLinkRequest;
};

export type MutationRedeemClinicPetParentAuthCodeArgs = {
  authCode: Scalars['String'];
};

export type MutationUpdateClinicPostcardSettingArgs = {
  where?: Maybe<UpdateClinicPostcardSettingWhereInput>;
  data: UpdateClinicPostcardSettingDataInput;
};

export type MutationMergePetParentArgs = {
  data?: Maybe<MergePetParentInput>;
};

export type MutationUpdateClinicPetParentContactInfoFromPimsArgs = {
  where?: Maybe<ClinicPetParentWhereUniqueInput>;
};

export type MutationUpdateClinicSettingTransactionColumnArgs = {
  where: ClinicSettingWhereUniqueInput;
  data: ClinicSettingUpdateInput;
};

export type MutationVerifyClinicWidgetRequest2Args = {
  where: VerifyClinicWidgetRequestInput;
};

export type MutationCreateClinicWidgetRequest2Args = {
  data: ClinicWidgetRequestCreateInput;
};

export type MutationSendWidgetCodeToDeveloperArgs = {
  data: SendWidgetCodeToDeveloperInput;
};

export type MutationSendConsultationReceiptArgs = {
  data: SendConsultationReceiptInput;
};

export type MutationCreateDirectBookingAppointmentArgs = {
  data?: Maybe<AppointmentCreateInput>;
};

export type MutationSubmitForm2Args = {
  data: FormSubmissionUpdateInput;
  where: FormSubmissionWhereUniqueInput;
};

export type MutationSubmitPetParentRequestArgs = {
  data: SubmitPetParentRequestInput;
};

export type MutationPostCareInvoiceAccountCreditToPimsArgs = {
  data: WritebackCareInvoiceAccountCreditToPimsInput;
};

export type MutationSyncPimsIntegrationModelArgs = {
  data: SyncPimsIntegrationModelInput;
};

export type MutationSyncManyPimsIntegrationModelArgs = {
  data: SyncManyPimsIntegrationModelInput;
};

export type MutationSyncClinicArgs = {
  clinicId: Scalars['String'];
};

export type MutationBuildSignedLookerUrlArgs = {
  reportId: Scalars['String'];
};

export type MutationUpdateManyServicesAndTimingsArgs = {
  where: ServiceWhereInput;
  data: Array<ServiceReminderTimingInput>;
};

export type MutationSetPaymentIntentClinicPetParent2Args = {
  where: StripePaymentIntentWhereUniqueInput;
};

export type MutationCreateStripePaymentIntent2Args = {
  data: StripeCreatePaymentInput;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  stripeTerminalStripeId?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type MutationCancelStripePaymentIntent2Args = {
  where: StripePaymentIntentWhereUniqueInput;
};

export type MutationRefundStripePaymentIntent2Args = {
  where: StripePaymentIntentWhereUniqueInput;
  data: EnhancedStripeRefundPaymentInput;
};

export type MutationClearTerminalDisplayArgs = {
  stripeTerminalId: Scalars['String'];
};

export type MutationSendPaymentToTerminalArgs = {
  stripePaymentIntentId: Scalars['String'];
  stripeTerminalId: Scalars['String'];
};

export type MutationSendUiRefreshEventArgs = {
  data: UiRefreshInput;
};

export type MutationAddUserToClinicArgs = {
  data: UserCreateInput;
};

export type MutationRemoveUserFromClinicArgs = {
  data: RemoveUserFromClinicInput;
};

export type MutationResendUserInvitationArgs = {
  data: ResendUserInvitationInput;
};

export type MutationAcceptUserInvitation2Args = {
  data: AcceptUserInvitationInput;
};

export type MutationExportPatientVaccinesToPdfArgs = {
  data: ExportPatientVaccinesToPdfInput;
};

export type MutationGenerateWidgetAuthenticationRequestArgs = {
  where: WidgetClinicPetParentLookupInput;
};

export type MutationVerifyWidgetAuthenticationRequestArgs = {
  data: VerifyWidgetAuthenticationRequestInput;
};

export type MutationCreateClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestCreateInput;
};

export type MutationVerifyClinicWidgetRequestArgs = {
  where: VerifyClinicWidgetRequestInput;
};

export type MutationStripeCreateConnectedAccountArgs = {
  data: StripeCreateAccountInput;
};

export type MutationStripeConnectConnectedAccountArgs = {
  data: StripeConnectOauthInput;
};

export type MutationGenerateTinyUrlAndTokenArgs = {
  data: GenerateTinyUrlAndTokenInput;
};

export type MutationCreateTwilioTokenArgs = {
  data: CreateTwilioTokenInput;
};

export type MutationTransferTwilioNumberArgs = {
  data: TransferTwilioNumberInput;
};

export type MutationTwilioVerifyCallerIdArgs = {
  data: TwilioVerifyCallerIdInput;
};

export type MutationCreateImpersonationArgs = {
  data: IdInput;
};

export type MutationTriggerWorkflowEventArgs = {
  data: TriggerWorkflowEventInput;
};

export type MutationTriggerMessageEventArgs = {
  data: TriggerMessageEventInput;
};

export enum MutationType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Upsert = 'upsert',
}

export type NeoIntegration = {
  __typename?: 'NeoIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type NeoIntegrationCountAggregateOutputType = {
  __typename?: 'NeoIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  authToken: Scalars['Int'];
  branchId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type NeoIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
};

export type NeoIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutNeoInput>;
};

export type NeoIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<NeoIntegrationWhereUniqueInput>;
};

export type NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: NeoIntegrationWhereUniqueInput;
  create: NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type NeoIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationMaxAggregateOutputType = {
  __typename?: 'NeoIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
};

export type NeoIntegrationMinAggregateOutputType = {
  __typename?: 'NeoIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
};

export enum NeoIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  AuthToken = 'authToken',
  BranchId = 'branchId',
}

export type NeoIntegrationOrderByRelevanceInput = {
  fields: Array<NeoIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type NeoIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  _count?: Maybe<NeoIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<NeoIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<NeoIntegrationMinOrderByAggregateInput>;
};

export type NeoIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<NeoIntegrationOrderByRelevanceInput>;
};

export type NeoIntegrationRelationFilter = {
  is?: Maybe<NeoIntegrationWhereInput>;
  isNot?: Maybe<NeoIntegrationWhereInput>;
};

export enum NeoIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AuthToken = 'authToken',
  BranchId = 'branchId',
}

export type NeoIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  authToken?: Maybe<StringWithAggregatesFilter>;
  branchId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type NeoIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutNeoInput>;
};

export type NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutNeoInput>;
};

export type NeoIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutNeoInput>;
};

export type NeoIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUpdateOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<NeoIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<NeoIntegrationWhereUniqueInput>;
  update?: Maybe<NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type NeoIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
};

export type NeoIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type NeoIntegrationWhereInput = {
  AND?: Maybe<Array<NeoIntegrationWhereInput>>;
  OR?: Maybe<Array<NeoIntegrationWhereInput>>;
  NOT?: Maybe<Array<NeoIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  authToken?: Maybe<StringFilter>;
  branchId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type NeoIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type NestedEnumAppointmentDateRangeTypeFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
};

export type NestedEnumAppointmentDateRangeTypeWithAggregatesFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  _max?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
};

export type NestedEnumAppointmentStatusFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  notIn?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusFilter>;
};

export type NestedEnumAppointmentStatusWithAggregatesFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  notIn?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentStatusFilter>;
  _max?: Maybe<NestedEnumAppointmentStatusFilter>;
};

export type NestedEnumAppointmentTypeVisibilityFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
};

export type NestedEnumAppointmentTypeVisibilityWithAggregatesFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  _max?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
};

export type NestedEnumAutomatedTriggerTypeNullableFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
};

export type NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  _max?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
};

export type NestedEnumAutomationRunActionPromptTypeFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
};

export type NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
};

export type NestedEnumAutomationRunActionTypeFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
};

export type NestedEnumAutomationRunActionTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
};

export type NestedEnumAutomationRunStatusFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusFilter>;
};

export type NestedEnumAutomationRunStatusWithAggregatesFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunStatusFilter>;
  _max?: Maybe<NestedEnumAutomationRunStatusFilter>;
};

export type NestedEnumAutomationRunTypeFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  notIn?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeFilter>;
};

export type NestedEnumAutomationRunTypeWithAggregatesFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  notIn?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationRunTypeFilter>;
  _max?: Maybe<NestedEnumAutomationRunTypeFilter>;
};

export type NestedEnumAutomationStatusFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  notIn?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusFilter>;
};

export type NestedEnumAutomationStatusWithAggregatesFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  notIn?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAutomationStatusFilter>;
  _max?: Maybe<NestedEnumAutomationStatusFilter>;
};

export type NestedEnumBitwerxSystemFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  notIn?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemFilter>;
};

export type NestedEnumBitwerxSystemWithAggregatesFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  notIn?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBitwerxSystemFilter>;
  _max?: Maybe<NestedEnumBitwerxSystemFilter>;
};

export type NestedEnumCallHistoryTypeFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  notIn?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeFilter>;
};

export type NestedEnumCallHistoryTypeWithAggregatesFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  notIn?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallHistoryTypeFilter>;
  _max?: Maybe<NestedEnumCallHistoryTypeFilter>;
};

export type NestedEnumCallProviderFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  notIn?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderFilter>;
};

export type NestedEnumCallProviderWithAggregatesFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  notIn?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallProviderFilter>;
  _max?: Maybe<NestedEnumCallProviderFilter>;
};

export type NestedEnumCallStatusFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  notIn?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusFilter>;
};

export type NestedEnumCallStatusWithAggregatesFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  notIn?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCallStatusFilter>;
  _max?: Maybe<NestedEnumCallStatusFilter>;
};

export type NestedEnumCardBrandFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  notIn?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandFilter>;
};

export type NestedEnumCardBrandWithAggregatesFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  notIn?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCardBrandFilter>;
  _max?: Maybe<NestedEnumCardBrandFilter>;
};

export type NestedEnumCardFundingNullableFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  notIn?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableFilter>;
};

export type NestedEnumCardFundingNullableWithAggregatesFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  notIn?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCardFundingNullableFilter>;
  _max?: Maybe<NestedEnumCardFundingNullableFilter>;
};

export type NestedEnumCareBenefitUnitOfMeasureNullableFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  _max?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type NestedEnumCarePlanCycleTypeFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
};

export type NestedEnumCarePlanCycleTypeWithAggregatesFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  _max?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
};

export type NestedEnumCareSubscriptionPaymentStatusNullableFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
};

export type NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
};

export type NestedEnumChannelCreationSourceTypeFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
};

export type NestedEnumChannelCreationSourceTypeWithAggregatesFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  _max?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
};

export type NestedEnumChannelMessageAttachmentEntityTypeNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
};

export type NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
};

export type NestedEnumChannelMessageAttachmentTypeFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
};

export type NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
};

export type NestedEnumChannelMessageAttachmentUploadStatusNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
};

export type NestedEnumChannelStatusActionFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionFilter>;
};

export type NestedEnumChannelStatusActionWithAggregatesFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChannelStatusActionFilter>;
  _max?: Maybe<NestedEnumChannelStatusActionFilter>;
};

export type NestedEnumChannelTypeNullableFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  notIn?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableFilter>;
};

export type NestedEnumChannelTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  notIn?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelTypeNullableFilter>;
  _max?: Maybe<NestedEnumChannelTypeNullableFilter>;
};

export type NestedEnumChannelVisibilityNullableFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  notIn?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
};

export type NestedEnumChannelVisibilityNullableWithAggregatesFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  notIn?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  _max?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
};

export type NestedEnumChargeCaptureTypeFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeFilter>;
};

export type NestedEnumChargeCaptureTypeWithAggregatesFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  _max?: Maybe<NestedEnumChargeCaptureTypeFilter>;
};

export type NestedEnumClinicEntityCreationSourceNullableFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
};

export type NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
};

export type NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
};

export type NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
};

export type NestedEnumClinicOfficeHoursTypeFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
};

export type NestedEnumClinicOfficeHoursTypeWithAggregatesFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  _max?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
};

export type NestedEnumClinicPetParentAddressTypeNullableFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
};

export type NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  _max?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
};

export type NestedEnumClinicPimsIntegrationTypeFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
};

export type NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  _max?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
};

export type NestedEnumContactEntityTypeFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  notIn?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeFilter>;
};

export type NestedEnumContactEntityTypeWithAggregatesFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  notIn?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumContactEntityTypeFilter>;
  _max?: Maybe<NestedEnumContactEntityTypeFilter>;
};

export type NestedEnumContactVerificationMethodNullableFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
};

export type NestedEnumContactVerificationMethodNullableWithAggregatesFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  _max?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
};

export type NestedEnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
};

export type NestedEnumCurrencyWithAggregatesFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  notIn?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumCurrencyFilter>;
  _max?: Maybe<NestedEnumCurrencyFilter>;
};

export type NestedEnumEmailProviderFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  notIn?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderFilter>;
};

export type NestedEnumEmailProviderLogEntityTypeFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
};

export type NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  _max?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
};

export type NestedEnumEmailProviderWithAggregatesFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  notIn?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEmailProviderFilter>;
  _max?: Maybe<NestedEnumEmailProviderFilter>;
};

export type NestedEnumEnrollmentStatusFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusFilter>;
};

export type NestedEnumEnrollmentStatusWithAggregatesFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumEnrollmentStatusFilter>;
  _max?: Maybe<NestedEnumEnrollmentStatusFilter>;
};

export type NestedEnumFeatureFlagStateFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  notIn?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateFilter>;
};

export type NestedEnumFeatureFlagStateWithAggregatesFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  notIn?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeatureFlagStateFilter>;
  _max?: Maybe<NestedEnumFeatureFlagStateFilter>;
};

export type NestedEnumFeatureFlagTypeFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  notIn?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeFilter>;
};

export type NestedEnumFeatureFlagTypeWithAggregatesFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  notIn?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  _max?: Maybe<NestedEnumFeatureFlagTypeFilter>;
};

export type NestedEnumFilterSelectionTypeFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  notIn?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeFilter>;
};

export type NestedEnumFilterSelectionTypeWithAggregatesFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  notIn?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  _max?: Maybe<NestedEnumFilterSelectionTypeFilter>;
};

export type NestedEnumFinancialTransactionTypeFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
};

export type NestedEnumFinancialTransactionTypeWithAggregatesFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  _max?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
};

export type NestedEnumFormTemplateTypeFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  notIn?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeFilter>;
};

export type NestedEnumFormTemplateTypeWithAggregatesFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  notIn?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumFormTemplateTypeFilter>;
  _max?: Maybe<NestedEnumFormTemplateTypeFilter>;
};

export type NestedEnumIntegrationTypeNullableFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  notIn?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
};

export type NestedEnumIntegrationTypeNullableWithAggregatesFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  notIn?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
};

export type NestedEnumInvoiceLineItemTypeFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
};

export type NestedEnumInvoiceLineItemTypeWithAggregatesFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  _max?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
};

export type NestedEnumInvoicePimsWritebackActionFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
};

export type NestedEnumInvoicePimsWritebackActionWithAggregatesFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  _max?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
};

export type NestedEnumInvoiceStatusFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type NestedEnumInvoiceStatusWithAggregatesFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInvoiceStatusFilter>;
  _max?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type NestedEnumMassTextAlertStatusFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusFilter>;
};

export type NestedEnumMassTextAlertStatusWithAggregatesFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  _max?: Maybe<NestedEnumMassTextAlertStatusFilter>;
};

export type NestedEnumMessageSourceFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  notIn?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceFilter>;
};

export type NestedEnumMessageSourceWithAggregatesFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  notIn?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageSourceFilter>;
  _max?: Maybe<NestedEnumMessageSourceFilter>;
};

export type NestedEnumMessageTypeFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeFilter>;
};

export type NestedEnumMessageTypeWithAggregatesFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageTypeFilter>;
  _max?: Maybe<NestedEnumMessageTypeFilter>;
};

export type NestedEnumNotificationEventFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  notIn?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventFilter>;
};

export type NestedEnumNotificationEventWithAggregatesFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  notIn?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumNotificationEventFilter>;
  _max?: Maybe<NestedEnumNotificationEventFilter>;
};

export type NestedEnumNotificationSettingTypeFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  notIn?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeFilter>;
};

export type NestedEnumNotificationSettingTypeWithAggregatesFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  notIn?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  _max?: Maybe<NestedEnumNotificationSettingTypeFilter>;
};

export type NestedEnumPaymentMediumNullableFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  notIn?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableFilter>;
};

export type NestedEnumPaymentMediumNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  notIn?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  _max?: Maybe<NestedEnumPaymentMediumNullableFilter>;
};

export type NestedEnumPaymentPimsWritebackActionFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
};

export type NestedEnumPaymentPimsWritebackActionWithAggregatesFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  _max?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
};

export type NestedEnumPaymentRelationshipNullableFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  notIn?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
};

export type NestedEnumPaymentRelationshipNullableWithAggregatesFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  notIn?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  _max?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
};

export type NestedEnumPaymentSourceFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  notIn?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceFilter>;
};

export type NestedEnumPaymentSourceWithAggregatesFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  notIn?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPaymentSourceFilter>;
  _max?: Maybe<NestedEnumPaymentSourceFilter>;
};

export type NestedEnumPermissionTypeFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  notIn?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeFilter>;
};

export type NestedEnumPermissionTypeWithAggregatesFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  notIn?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPermissionTypeFilter>;
  _max?: Maybe<NestedEnumPermissionTypeFilter>;
};

export type NestedEnumPhoneNumberStatusFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusFilter>;
};

export type NestedEnumPhoneNumberStatusWithAggregatesFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  _max?: Maybe<NestedEnumPhoneNumberStatusFilter>;
};

export type NestedEnumPimsIntegrationCapabilityFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
};

export type NestedEnumPimsIntegrationCapabilityWithAggregatesFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  _max?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
};

export type NestedEnumPimsInvoiceLineItemTypeFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
};

export type NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  _max?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
};

export type NestedEnumPimsSyncFrequencyFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
};

export type NestedEnumPimsSyncFrequencyWithAggregatesFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  _max?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
};

export type NestedEnumPimsSyncStatusNullableFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
};

export type NestedEnumPimsSyncStatusNullableWithAggregatesFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  _max?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
};

export type NestedEnumPimsWritebackStatusFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusFilter>;
};

export type NestedEnumPimsWritebackStatusWithAggregatesFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  _max?: Maybe<NestedEnumPimsWritebackStatusFilter>;
};

export type NestedEnumPimsWritebackTypeFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  notIn?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeFilter>;
};

export type NestedEnumPimsWritebackTypeWithAggregatesFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  notIn?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  _max?: Maybe<NestedEnumPimsWritebackTypeFilter>;
};

export type NestedEnumRecordingDownloadStatusFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
};

export type NestedEnumRecordingDownloadStatusWithAggregatesFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  _max?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
};

export type NestedEnumRefundReasonFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  notIn?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonFilter>;
};

export type NestedEnumRefundReasonWithAggregatesFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  notIn?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRefundReasonFilter>;
  _max?: Maybe<NestedEnumRefundReasonFilter>;
};

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type NestedEnumRoleWithAggregatesFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRoleFilter>;
  _max?: Maybe<NestedEnumRoleFilter>;
};

export type NestedEnumRuleActionsFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  notIn?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsFilter>;
};

export type NestedEnumRuleActionsWithAggregatesFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  notIn?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRuleActionsFilter>;
  _max?: Maybe<NestedEnumRuleActionsFilter>;
};

export type NestedEnumServiceCapabilityFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  notIn?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityFilter>;
};

export type NestedEnumServiceCapabilityWithAggregatesFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  notIn?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceCapabilityFilter>;
  _max?: Maybe<NestedEnumServiceCapabilityFilter>;
};

export type NestedEnumServiceProviderFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  notIn?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderFilter>;
};

export type NestedEnumServiceProviderWithAggregatesFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  notIn?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceProviderFilter>;
  _max?: Maybe<NestedEnumServiceProviderFilter>;
};

export type NestedEnumServiceReminderNotificationTypeFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
};

export type NestedEnumServiceReminderNotificationTypeWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  _max?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
};

export type NestedEnumServiceReminderTimingOffsetUnitFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
};

export type NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
};

export type NestedEnumServiceReminderTimingTypeFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
};

export type NestedEnumServiceReminderTimingTypeWithAggregatesFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
};

export type NestedEnumSmsEntityTypeNullableFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  notIn?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
};

export type NestedEnumSmsEntityTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  notIn?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  _max?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
};

export type NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
};

export type NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  _max?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
};

export type NestedEnumSmsNotificationProviderFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderFilter>;
};

export type NestedEnumSmsNotificationProviderWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  _max?: Maybe<NestedEnumSmsNotificationProviderFilter>;
};

export type NestedEnumSmsNotificationStatusTypeFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
};

export type NestedEnumSmsNotificationStatusTypeWithAggregatesFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  _max?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
};

export type NestedEnumSpeciesTypeFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeFilter>;
};

export type NestedEnumSpeciesTypeNullableFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
};

export type NestedEnumSpeciesTypeNullableWithAggregatesFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
};

export type NestedEnumSpeciesTypeWithAggregatesFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  notIn?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeFilter>;
};

export type NestedEnumStateFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  notIn?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateFilter>;
};

export type NestedEnumStateWithAggregatesFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  notIn?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStateFilter>;
  _max?: Maybe<NestedEnumStateFilter>;
};

export type NestedEnumStripeCollectionMethodFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodFilter>;
};

export type NestedEnumStripeCollectionMethodWithAggregatesFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  _max?: Maybe<NestedEnumStripeCollectionMethodFilter>;
};

export type NestedEnumStripeDisputeStatusFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusFilter>;
};

export type NestedEnumStripeDisputeStatusWithAggregatesFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  _max?: Maybe<NestedEnumStripeDisputeStatusFilter>;
};

export type NestedEnumStripeInvoiceStatusFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
};

export type NestedEnumStripeInvoiceStatusWithAggregatesFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  _max?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
};

export type NestedEnumStripePaymentErrorNullableFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  notIn?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
};

export type NestedEnumStripePaymentErrorNullableWithAggregatesFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  notIn?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  _max?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
};

export type NestedEnumStripePaymentIntentStatusFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
};

export type NestedEnumStripePaymentIntentStatusWithAggregatesFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  _max?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
};

export type NestedEnumStripePaymentMethodTypeFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
};

export type NestedEnumStripePaymentMethodTypeWithAggregatesFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  _max?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
};

export type NestedEnumStripePaymentTransferTypeFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
};

export type NestedEnumStripePaymentTransferTypeWithAggregatesFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  _max?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
};

export type NestedEnumStripePayoutStatusFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusFilter>;
};

export type NestedEnumStripePayoutStatusWithAggregatesFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripePayoutStatusFilter>;
  _max?: Maybe<NestedEnumStripePayoutStatusFilter>;
};

export type NestedEnumStripeReceiptMediumFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumFilter>;
};

export type NestedEnumStripeReceiptMediumWithAggregatesFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  _max?: Maybe<NestedEnumStripeReceiptMediumFilter>;
};

export type NestedEnumStripeTerminalModelFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelFilter>;
};

export type NestedEnumStripeTerminalModelWithAggregatesFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumStripeTerminalModelFilter>;
  _max?: Maybe<NestedEnumStripeTerminalModelFilter>;
};

export type NestedEnumTwilioRecordingStatusFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
};

export type NestedEnumTwilioRecordingStatusWithAggregatesFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  _max?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
};

export type NestedEnumTwilioVerificationStatusFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
};

export type NestedEnumTwilioVerificationStatusWithAggregatesFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  _max?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
};

export type NestedEnumUserTypeFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeFilter>;
};

export type NestedEnumUserTypeWithAggregatesFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  notIn?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumUserTypeFilter>;
  _max?: Maybe<NestedEnumUserTypeFilter>;
};

export type NestedEnumVetDataIntegrationSystemFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
};

export type NestedEnumVetDataIntegrationSystemWithAggregatesFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  _max?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
};

export type NestedEnumVetLicenseTypeFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  notIn?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeFilter>;
};

export type NestedEnumVetLicenseTypeWithAggregatesFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  notIn?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVetLicenseTypeFilter>;
  _max?: Maybe<NestedEnumVetLicenseTypeFilter>;
};

export type NestedEnumWidgetRequestTypeFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  notIn?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeFilter>;
};

export type NestedEnumWidgetRequestTypeWithAggregatesFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  notIn?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  _max?: Maybe<NestedEnumWidgetRequestTypeFilter>;
};

export type NestedEnumWorkflowActionTypeFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  notIn?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeFilter>;
};

export type NestedEnumWorkflowActionTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  notIn?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowActionTypeFilter>;
};

export type NestedEnumWorkflowEventFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  notIn?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventFilter>;
};

export type NestedEnumWorkflowEventTriggerTypeFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
};

export type NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
};

export type NestedEnumWorkflowEventWithAggregatesFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  notIn?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowEventFilter>;
  _max?: Maybe<NestedEnumWorkflowEventFilter>;
};

export type NestedEnumWorkflowSendingHoursFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
};

export type NestedEnumWorkflowSendingHoursWithAggregatesFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  _max?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
};

export type NestedEnumWorkflowTypeFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeFilter>;
};

export type NestedEnumWorkflowTypeNullableFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
};

export type NestedEnumWorkflowTypeNullableWithAggregatesFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
};

export type NestedEnumWorkflowTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  notIn?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeFilter>;
};

export type NestedEnumWorkflowVisitTypeFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
};

export type NestedEnumWorkflowVisitTypeWithAggregatesFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  _max?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export type NestedJsonFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type NestedJsonNullableFilter = {
  equals?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export enum NotificationEvent {
  AppointmentScheduled = 'AppointmentScheduled',
  AppointmentConfirmation = 'AppointmentConfirmation',
  AppointmentReminder = 'AppointmentReminder',
  AppointmentRescheduleRequested = 'AppointmentRescheduleRequested',
  AppointmentConfirmed = 'AppointmentConfirmed',
  AppointmentCancelation = 'AppointmentCancelation',
  AppointmentArrivalConfirmation = 'AppointmentArrivalConfirmation',
  AppointmentFollowup = 'AppointmentFollowup',
  AppointmentPimsSync = 'AppointmentPimsSync',
  ServiceReminderFormSubmitted = 'ServiceReminderFormSubmitted',
  WidgetRequestFormSubmitted = 'WidgetRequestFormSubmitted',
  CallDeflectionFormSubmitted = 'CallDeflectionFormSubmitted',
}

export type NotificationEventToMessage = {
  __typename?: 'NotificationEventToMessage';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  event: NotificationEvent;
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailBody: Scalars['String'];
  smsMessage: Scalars['String'];
  emailCallToAction: Scalars['String'];
};

export type NotificationEventToMessageCountAggregateOutputType = {
  __typename?: 'NotificationEventToMessageCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  event: Scalars['Int'];
  emailSubject: Scalars['Int'];
  emailTitle: Scalars['Int'];
  emailSalutation: Scalars['Int'];
  emailBody: Scalars['Int'];
  smsMessage: Scalars['Int'];
  emailCallToAction: Scalars['Int'];
  _all: Scalars['Int'];
};

export type NotificationEventToMessageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
};

export type NotificationEventToMessageCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event: NotificationEvent;
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailBody: Scalars['String'];
  smsMessage: Scalars['String'];
  emailCallToAction: Scalars['String'];
};

export type NotificationEventToMessageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event: NotificationEvent;
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailBody: Scalars['String'];
  smsMessage: Scalars['String'];
  emailCallToAction: Scalars['String'];
};

export type NotificationEventToMessageMaxAggregateOutputType = {
  __typename?: 'NotificationEventToMessageMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
};

export type NotificationEventToMessageMinAggregateOutputType = {
  __typename?: 'NotificationEventToMessageMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
};

export enum NotificationEventToMessageOrderByRelevanceFieldEnum {
  Id = 'id',
  EmailSubject = 'emailSubject',
  EmailTitle = 'emailTitle',
  EmailSalutation = 'emailSalutation',
  EmailBody = 'emailBody',
  SmsMessage = 'smsMessage',
  EmailCallToAction = 'emailCallToAction',
}

export type NotificationEventToMessageOrderByRelevanceInput = {
  fields: Array<NotificationEventToMessageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type NotificationEventToMessageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  _count?: Maybe<NotificationEventToMessageCountOrderByAggregateInput>;
  _max?: Maybe<NotificationEventToMessageMaxOrderByAggregateInput>;
  _min?: Maybe<NotificationEventToMessageMinOrderByAggregateInput>;
};

export type NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  _relevance?: Maybe<NotificationEventToMessageOrderByRelevanceInput>;
};

export enum NotificationEventToMessageScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Event = 'event',
  EmailSubject = 'emailSubject',
  EmailTitle = 'emailTitle',
  EmailSalutation = 'emailSalutation',
  EmailBody = 'emailBody',
  SmsMessage = 'smsMessage',
  EmailCallToAction = 'emailCallToAction',
}

export type NotificationEventToMessageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  event?: Maybe<EnumNotificationEventWithAggregatesFilter>;
  emailSubject?: Maybe<StringWithAggregatesFilter>;
  emailTitle?: Maybe<StringWithAggregatesFilter>;
  emailSalutation?: Maybe<StringWithAggregatesFilter>;
  emailBody?: Maybe<StringWithAggregatesFilter>;
  smsMessage?: Maybe<StringWithAggregatesFilter>;
  emailCallToAction?: Maybe<StringWithAggregatesFilter>;
};

export type NotificationEventToMessageUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event: NotificationEvent;
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailBody: Scalars['String'];
  smsMessage: Scalars['String'];
  emailCallToAction: Scalars['String'];
};

export type NotificationEventToMessageUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<NotificationEvent>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
};

export type NotificationEventToMessageWhereInput = {
  AND?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  OR?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  NOT?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  event?: Maybe<EnumNotificationEventFilter>;
  emailSubject?: Maybe<StringFilter>;
  emailTitle?: Maybe<StringFilter>;
  emailSalutation?: Maybe<StringFilter>;
  emailBody?: Maybe<StringFilter>;
  smsMessage?: Maybe<StringFilter>;
  emailCallToAction?: Maybe<StringFilter>;
};

export type NotificationEventToMessageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  event?: Maybe<NotificationEvent>;
};

export enum NotificationSettingType {
  AppointmentConfirmed = 'Appointment_Confirmed',
  AppointmentRescheduleRequested = 'Appointment_RescheduleRequested',
  ConnectWidgetRequestSubmitted = 'ConnectWidget_RequestSubmitted',
  ConversationAssigned = 'Conversation_Assigned',
  ConversationNewMessage = 'Conversation_NewMessage',
  ConversationStatusChange = 'Conversation_StatusChange',
  FormSubmitted = 'Form_Submitted',
  PaymentReceived = 'Payment_Received',
}

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumAutomatedTriggerTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomatedTriggerType>;
};

export type NullableEnumCardFundingFieldUpdateOperationsInput = {
  set?: Maybe<CardFunding>;
};

export type NullableEnumCareBenefitUnitOfMeasureFieldUpdateOperationsInput = {
  set?: Maybe<CareBenefitUnitOfMeasure>;
};

export type NullableEnumCareSubscriptionPaymentStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareSubscriptionPaymentStatus>;
};

export type NullableEnumChannelMessageAttachmentEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentEntityType>;
};

export type NullableEnumChannelMessageAttachmentUploadStatusFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type NullableEnumChannelTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelType>;
};

export type NullableEnumChannelVisibilityFieldUpdateOperationsInput = {
  set?: Maybe<ChannelVisibility>;
};

export type NullableEnumClinicEntityCreationSourceFieldUpdateOperationsInput = {
  set?: Maybe<ClinicEntityCreationSource>;
};

export type NullableEnumClinicEntityPhoneNumberSmsStatusFieldUpdateOperationsInput = {
  set?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
};

export type NullableEnumClinicPetParentAddressTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicPetParentAddressType>;
};

export type NullableEnumContactVerificationMethodFieldUpdateOperationsInput = {
  set?: Maybe<ContactVerificationMethod>;
};

export type NullableEnumIntegrationTypeFieldUpdateOperationsInput = {
  set?: Maybe<IntegrationType>;
};

export type NullableEnumPaymentMediumFieldUpdateOperationsInput = {
  set?: Maybe<PaymentMedium>;
};

export type NullableEnumPaymentRelationshipFieldUpdateOperationsInput = {
  set?: Maybe<PaymentRelationship>;
};

export type NullableEnumPimsSyncStatusFieldUpdateOperationsInput = {
  set?: Maybe<PimsSyncStatus>;
};

export type NullableEnumSmsEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsEntityType>;
};

export type NullableEnumSmsNotificationDeliveryErrorTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationDeliveryErrorType>;
};

export type NullableEnumSpeciesTypeFieldUpdateOperationsInput = {
  set?: Maybe<SpeciesType>;
};

export type NullableEnumStripePaymentErrorFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentError>;
};

export type NullableEnumWorkflowTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export enum NullableJsonNullValueInput {
  DbNull = 'DbNull',
  JsonNull = 'JsonNull',
}

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type NumberOfPetParentsReached = {
  __typename?: 'NumberOfPetParentsReached';
  numberOfPetPerents?: Maybe<Scalars['Int']>;
  timeEstimateInSeconds?: Maybe<Scalars['Int']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  clinics: Array<Clinic>;
  organizationPets: Array<OrganizationPet>;
  organizationPetParents: Array<OrganizationPetParent>;
  carePlans: Array<CarePlan>;
  careBenefits: Array<CareBenefit>;
  careAddonPackage: Array<CareAddonPackage>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  paymentProcessorClinic?: Maybe<Clinic>;
  _count: OrganizationCountOutputType;
};

export type OrganizationClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type OrganizationOrganizationPetsArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetScalarFieldEnum>;
};

export type OrganizationOrganizationPetParentsArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetParentScalarFieldEnum>;
};

export type OrganizationCarePlansArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanScalarFieldEnum>;
};

export type OrganizationCareBenefitsArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitScalarFieldEnum>;
};

export type OrganizationCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonPackageScalarFieldEnum>;
};

export type OrganizationCareBenefitToClinicService = {
  __typename?: 'OrganizationCareBenefitToClinicService';
  benefitId: Scalars['String'];
  benefit: CareBenefit;
  serviceId: Scalars['String'];
  service: Service;
};

export type OrganizationCareBenefitToClinicServiceBenefitIdServiceIdCompoundUniqueInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCountAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCountAggregateOutputType';
  benefitId: Scalars['Int'];
  serviceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationCareBenefitToClinicServiceCountOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCreateInput = {
  benefit: CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
  service: ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceCreateManyBenefitInput = {
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCreateManyInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyServiceInput = {
  benefitId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput;
};

export type OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput = {
  service: ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput = {
  benefit: CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceListRelationFilter = {
  every?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  some?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  none?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};

export type OrganizationCareBenefitToClinicServiceMaxAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceMaxAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceMaxOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceMinAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceMinAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceMinOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationCareBenefitToClinicServiceOrderByRelevanceFieldEnum {
  BenefitId = 'benefitId',
  ServiceId = 'serviceId',
}

export type OrganizationCareBenefitToClinicServiceOrderByRelevanceInput = {
  fields: Array<OrganizationCareBenefitToClinicServiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceOrderByWithAggregationInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceMinOrderByAggregateInput>;
};

export type OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput = {
  benefitId?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  serviceId?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelevanceInput>;
};

export enum OrganizationCareBenefitToClinicServiceScalarFieldEnum {
  BenefitId = 'benefitId',
  ServiceId = 'serviceId',
}

export type OrganizationCareBenefitToClinicServiceScalarWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  serviceId?: Maybe<StringFilter>;
};

export type OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput = {
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput = {
  benefitId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateInput = {
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyInput = {
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput = {
  benefitId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput = {
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput = {
  benefitId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUpdateInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceInput>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceScalarWhereInput;
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput = {
  where: OrganizationCareBenefitToClinicServiceScalarWhereInput;
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithoutBenefitInput = {
  service?: Maybe<ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithoutServiceInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  update: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput;
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput;
};

export type OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
  update: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput;
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput;
};

export type OrganizationCareBenefitToClinicServiceWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  serviceId?: Maybe<StringFilter>;
  service?: Maybe<ServiceWhereInput>;
};

export type OrganizationCareBenefitToClinicServiceWhereUniqueInput = {
  benefitId_serviceId?: Maybe<OrganizationCareBenefitToClinicServiceBenefitIdServiceIdCompoundUniqueInput>;
};

export type OrganizationCountAggregateOutputType = {
  __typename?: 'OrganizationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
  paymentProcessorClinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
};

export type OrganizationCountOutputType = {
  __typename?: 'OrganizationCountOutputType';
  clinics: Scalars['Int'];
  organizationPets: Scalars['Int'];
  organizationPetParents: Scalars['Int'];
  carePlans: Scalars['Int'];
  careBenefits: Scalars['Int'];
  careAddonPackage: Scalars['Int'];
};

export type OrganizationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationCreateNestedOneWithoutCareAddonPackageInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCareAddonPackageInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareAddonPackageInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutCareBenefitsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCareBenefitsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareBenefitsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutCarePlansInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlansInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlansInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutClinicsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutClinicsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutClinicsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutOrganizationPetParentsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutOrganizationPetsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateOrConnectWithoutCareAddonPackageInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutCareAddonPackageInput;
};

export type OrganizationCreateOrConnectWithoutCareBenefitsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutCareBenefitsInput;
};

export type OrganizationCreateOrConnectWithoutCarePlansInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutCarePlansInput;
};

export type OrganizationCreateOrConnectWithoutClinicsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutClinicsInput;
};

export type OrganizationCreateOrConnectWithoutOrganizationPetParentsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutOrganizationPetParentsInput;
};

export type OrganizationCreateOrConnectWithoutOrganizationPetsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutOrganizationPetsInput;
};

export type OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput;
};

export type OrganizationCreateWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationCreateWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationCreateWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationCreateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationCreateWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationCreateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationMaxAggregateOutputType = {
  __typename?: 'OrganizationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
};

export type OrganizationMinAggregateOutputType = {
  __typename?: 'OrganizationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
};

export enum OrganizationOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  PaymentProcessorClinicId = 'paymentProcessorClinicId',
}

export type OrganizationOrderByRelevanceInput = {
  fields: Array<OrganizationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationMinOrderByAggregateInput>;
};

export type OrganizationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  organizationPets?: Maybe<OrganizationPetOrderByRelationAggregateInput>;
  organizationPetParents?: Maybe<OrganizationPetParentOrderByRelationAggregateInput>;
  carePlans?: Maybe<CarePlanOrderByRelationAggregateInput>;
  careBenefits?: Maybe<CareBenefitOrderByRelationAggregateInput>;
  careAddonPackage?: Maybe<CareAddonPackageOrderByRelationAggregateInput>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  paymentProcessorClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<OrganizationOrderByRelevanceInput>;
};

export type OrganizationPet = {
  __typename?: 'OrganizationPet';
  id: Scalars['String'];
  preferredName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organization: Organization;
  carePlanEnrollments: Array<CarePlanEnrollment>;
  careAddonEnrollments: Array<CareAddonEnrollment>;
  clinicPets: Array<ClinicPet>;
  organizationPetToOrganizationPetParents: Array<OrganizationPetToOrganizationPetParent>;
  _count: OrganizationPetCountOutputType;
};

export type OrganizationPetCarePlanEnrollmentsArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
};

export type OrganizationPetCareAddonEnrollmentsArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
};

export type OrganizationPetClinicPetsArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
};

export type OrganizationPetOrganizationPetToOrganizationPetParentsArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>;
};

export type OrganizationPetCountAggregateOutputType = {
  __typename?: 'OrganizationPetCountAggregateOutputType';
  id: Scalars['Int'];
  preferredName: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  profilePictureUrl: Scalars['Int'];
  organizationId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationPetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
};

export type OrganizationPetCountOutputType = {
  __typename?: 'OrganizationPetCountOutputType';
  carePlanEnrollments: Scalars['Int'];
  careAddonEnrollments: Scalars['Int'];
  clinicPets: Scalars['Int'];
  organizationPetToOrganizationPetParents: Scalars['Int'];
};

export type OrganizationPetCreateInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type OrganizationPetCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type OrganizationPetCreateManyOrganizationInputEnvelope = {
  data: Array<OrganizationPetCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
};

export type OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutClinicPetsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutClinicPetsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
};

export type OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput;
};

export type OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput;
};

export type OrganizationPetCreateOrConnectWithoutClinicPetsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutClinicPetsInput;
};

export type OrganizationPetCreateOrConnectWithoutOrganizationInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutOrganizationInput;
};

export type OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput = {
  where: OrganizationPetWhereUniqueInput;
  create: OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetCreateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetCreateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
};

export type OrganizationPetEnrollmentInput = {
  organizationPetId: Scalars['String'];
  carePlanIds: Array<Scalars['String']>;
  careAddonIds: Array<Scalars['String']>;
};

export type OrganizationPetListRelationFilter = {
  every?: Maybe<OrganizationPetWhereInput>;
  some?: Maybe<OrganizationPetWhereInput>;
  none?: Maybe<OrganizationPetWhereInput>;
};

export type OrganizationPetMaxAggregateOutputType = {
  __typename?: 'OrganizationPetMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationPetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
};

export type OrganizationPetMinAggregateOutputType = {
  __typename?: 'OrganizationPetMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationPetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
};

export type OrganizationPetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetOrderByRelevanceFieldEnum {
  Id = 'id',
  PreferredName = 'preferredName',
  ProfilePictureUrl = 'profilePictureUrl',
  OrganizationId = 'organizationId',
}

export type OrganizationPetOrderByRelevanceInput = {
  fields: Array<OrganizationPetOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationPetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationPetCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetMinOrderByAggregateInput>;
};

export type OrganizationPetOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput>;
  _relevance?: Maybe<OrganizationPetOrderByRelevanceInput>;
};

export type OrganizationPetParent = {
  __typename?: 'OrganizationPetParent';
  id: Scalars['String'];
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organizationId: Scalars['String'];
  organization: Organization;
  clinicPetParents: Array<ClinicPetParent>;
  organizationPetToOrganizationPetParents: Array<OrganizationPetToOrganizationPetParent>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscription>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomer>;
  allCareStripeSubscriptions: Array<CareStripeSubscription>;
  _count: OrganizationPetParentCountOutputType;
};

export type OrganizationPetParentClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type OrganizationPetParentOrganizationPetToOrganizationPetParentsArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>;
};

export type OrganizationPetParentAllCareStripeSubscriptionsArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
};

export type OrganizationPetParentCountAggregateOutputType = {
  __typename?: 'OrganizationPetParentCountAggregateOutputType';
  id: Scalars['Int'];
  preferredFirstName: Scalars['Int'];
  preferredLastName: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  organizationId: Scalars['Int'];
  activeCareStripeSubscriptionId: Scalars['Int'];
  stripeCustomerTableId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationPetParentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
};

export type OrganizationPetParentCountOutputType = {
  __typename?: 'OrganizationPetParentCountOutputType';
  clinicPetParents: Scalars['Int'];
  organizationPetToOrganizationPetParents: Scalars['Int'];
  allCareStripeSubscriptions: Scalars['Int'];
};

export type OrganizationPetParentCreateInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentCreateManyOrganizationInputEnvelope = {
  data: Array<OrganizationPetParentCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetParentCreateManyStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentCreateManyStripeCustomerInputEnvelope = {
  data: Array<OrganizationPetParentCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetParentCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput;
};

export type OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput;
};

export type OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput;
};

export type OrganizationPetParentCreateOrConnectWithoutOrganizationInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationInput;
};

export type OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput = {
  where: OrganizationPetParentWhereUniqueInput;
  create: OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput;
};

export type OrganizationPetParentCreateWithoutActiveCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput
  >;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
};

export type OrganizationPetParentCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentListRelationFilter = {
  every?: Maybe<OrganizationPetParentWhereInput>;
  some?: Maybe<OrganizationPetParentWhereInput>;
  none?: Maybe<OrganizationPetParentWhereInput>;
};

export type OrganizationPetParentMaxAggregateOutputType = {
  __typename?: 'OrganizationPetParentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
};

export type OrganizationPetParentMinAggregateOutputType = {
  __typename?: 'OrganizationPetParentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
};

export type OrganizationPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetParentOrderByRelevanceFieldEnum {
  Id = 'id',
  PreferredFirstName = 'preferredFirstName',
  PreferredLastName = 'preferredLastName',
  OrganizationId = 'organizationId',
  ActiveCareStripeSubscriptionId = 'activeCareStripeSubscriptionId',
  StripeCustomerTableId = 'stripeCustomerTableId',
}

export type OrganizationPetParentOrderByRelevanceInput = {
  fields: Array<OrganizationPetParentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationPetParentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationPetParentCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetParentMinOrderByAggregateInput>;
};

export type OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  _relevance?: Maybe<OrganizationPetParentOrderByRelevanceInput>;
};

export type OrganizationPetParentRelationFilter = {
  is?: Maybe<OrganizationPetParentWhereInput>;
  isNot?: Maybe<OrganizationPetParentWhereInput>;
};

export enum OrganizationPetParentScalarFieldEnum {
  Id = 'id',
  PreferredFirstName = 'preferredFirstName',
  PreferredLastName = 'preferredLastName',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  OrganizationId = 'organizationId',
  ActiveCareStripeSubscriptionId = 'activeCareStripeSubscriptionId',
  StripeCustomerTableId = 'stripeCustomerTableId',
}

export type OrganizationPetParentScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  preferredFirstName?: Maybe<StringFilter>;
  preferredLastName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringFilter>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeCustomerTableId?: Maybe<StringNullableFilter>;
};

export type OrganizationPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  preferredFirstName?: Maybe<StringWithAggregatesFilter>;
  preferredLastName?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerTableId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type OrganizationPetParentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput
  >;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput
  >;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
};

export type OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput = {
  where: OrganizationPetParentScalarWhereInput;
  data: OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput;
};

export type OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput = {
  where: OrganizationPetParentScalarWhereInput;
  data: OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput;
};

export type OrganizationPetParentUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetParentUpdateManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutAllCareStripeSubscriptionsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput>;
};

export type OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutOrganizationPetToOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput>;
};

export type OrganizationPetParentUpdateOneWithoutClinicPetParentsInput = {
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutClinicPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput>;
};

export type OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: OrganizationPetParentWhereUniqueInput;
  data: OrganizationPetParentUncheckedUpdateWithoutOrganizationInput;
};

export type OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  where: OrganizationPetParentWhereUniqueInput;
  data: OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput;
};

export type OrganizationPetParentUpdateWithoutActiveCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentInput
  >;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUpdateWithoutAllCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsInput>;
};

export type OrganizationPetParentUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentInput
  >;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerInput>;
};

export type OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: OrganizationPetParentWhereUniqueInput;
  update: OrganizationPetParentUncheckedUpdateWithoutOrganizationInput;
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationInput;
};

export type OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  where: OrganizationPetParentWhereUniqueInput;
  update: OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput;
  create: OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput;
};

export type OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput = {
  update: OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput;
  create: OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput;
};

export type OrganizationPetParentUpsertWithoutAllCareStripeSubscriptionsInput = {
  update: OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput;
  create: OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput;
};

export type OrganizationPetParentUpsertWithoutClinicPetParentsInput = {
  update: OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput;
  create: OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput;
};

export type OrganizationPetParentUpsertWithoutOrganizationPetToOrganizationPetParentsInput = {
  update: OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput;
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetParentWhereInput = {
  AND?: Maybe<Array<OrganizationPetParentWhereInput>>;
  OR?: Maybe<Array<OrganizationPetParentWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetParentWhereInput>>;
  id?: Maybe<StringFilter>;
  preferredFirstName?: Maybe<StringFilter>;
  preferredLastName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentListRelationFilter>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  stripeCustomerTableId?: Maybe<StringNullableFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
};

export type OrganizationPetParentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type OrganizationPetRelationFilter = {
  is?: Maybe<OrganizationPetWhereInput>;
  isNot?: Maybe<OrganizationPetWhereInput>;
};

export enum OrganizationPetScalarFieldEnum {
  Id = 'id',
  PreferredName = 'preferredName',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ProfilePictureUrl = 'profilePictureUrl',
  OrganizationId = 'organizationId',
}

export type OrganizationPetScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  preferredName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  profilePictureUrl?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringFilter>;
};

export type OrganizationPetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  preferredName?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  profilePictureUrl?: Maybe<StringNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationPetToOrganizationPetParent = {
  __typename?: 'OrganizationPetToOrganizationPetParent';
  organizationPetId: Scalars['String'];
  organizationPet: OrganizationPet;
  organizationPetParentId: Scalars['String'];
  organizationPetParent: OrganizationPetParent;
};

export type OrganizationPetToOrganizationPetParentCountAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentCountAggregateOutputType';
  organizationPetId: Scalars['Int'];
  organizationPetParentId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrganizationPetToOrganizationPetParentCountOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentCreateInput = {
  organizationPet: OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
  organizationPetParent: OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentCreateManyInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInput = {
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope = {
  data: Array<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInput = {
  organizationPetId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope = {
  data: Array<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<
    Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>
  >;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput;
};

export type OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput;
};

export type OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput = {
  organizationPetParent: OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput = {
  organizationPet: OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentListRelationFilter = {
  every?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  some?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  none?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};

export type OrganizationPetToOrganizationPetParentMaxAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentMaxAggregateOutputType';
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentMaxOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentMinAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentMinAggregateOutputType';
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentMinOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetToOrganizationPetParentOrderByRelevanceFieldEnum {
  OrganizationPetId = 'organizationPetId',
  OrganizationPetParentId = 'organizationPetParentId',
}

export type OrganizationPetToOrganizationPetParentOrderByRelevanceInput = {
  fields: Array<OrganizationPetToOrganizationPetParentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentOrderByWithAggregationInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  _count?: Maybe<OrganizationPetToOrganizationPetParentCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetToOrganizationPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetToOrganizationPetParentMinOrderByAggregateInput>;
};

export type OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetParentId?: Maybe<SortOrder>;
  organizationPetParent?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelevanceInput>;
};

export type OrganizationPetToOrganizationPetParentOrganizationPetIdOrganizationPetParentIdCompoundUniqueInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export enum OrganizationPetToOrganizationPetParentScalarFieldEnum {
  OrganizationPetId = 'organizationPetId',
  OrganizationPetParentId = 'organizationPetParentId',
}

export type OrganizationPetToOrganizationPetParentScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  organizationPetId?: Maybe<StringFilter>;
  organizationPetParentId?: Maybe<StringFilter>;
};

export type OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  organizationPetParentId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<
    Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>
  >;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput = {
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput = {
  organizationPetId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateInput = {
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyInput = {
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<
    Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>
  >;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput = {
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput = {
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput = {
  organizationPetId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUpdateInput = {
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsInput>;
  organizationPetParent?: Maybe<
    OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsInput
  >;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput = {
  where: OrganizationPetToOrganizationPetParentScalarWhereInput;
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput = {
  where: OrganizationPetToOrganizationPetParentScalarWhereInput;
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentInput = {
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  connectOrCreate?: Maybe<
    Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>
  >;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
};

export type OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput;
};

export type OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
};

export type OrganizationPetToOrganizationPetParentUpdateWithoutOrganizationPetInput = {
  organizationPetParent?: Maybe<
    OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsInput
  >;
};

export type OrganizationPetToOrganizationPetParentUpdateWithoutOrganizationPetParentInput = {
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  update: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput;
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput;
};

export type OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
  update: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput;
};

export type OrganizationPetToOrganizationPetParentWhereInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  organizationPetId?: Maybe<StringFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  organizationPetParentId?: Maybe<StringFilter>;
  organizationPetParent?: Maybe<OrganizationPetParentWhereInput>;
};

export type OrganizationPetToOrganizationPetParentWhereUniqueInput = {
  organizationPetId_organizationPetParentId?: Maybe<
    OrganizationPetToOrganizationPetParentOrganizationPetIdOrganizationPetParentIdCompoundUniqueInput
  >;
};

export type OrganizationPetUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
};

export type OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedCreateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
};

export type OrganizationPetUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationPetUncheckedUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<OrganizationPetScalarWhereInput>>;
};

export type OrganizationPetUncheckedUpdateManyWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetInput>;
};

export type OrganizationPetUpdateInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type OrganizationPetUpdateManyWithWhereWithoutOrganizationInput = {
  where: OrganizationPetScalarWhereInput;
  data: OrganizationPetUncheckedUpdateManyWithoutOrganizationPetsInput;
};

export type OrganizationPetUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  set?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<OrganizationPetScalarWhereInput>>;
};

export type OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutCareAddonEnrollmentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput>;
};

export type OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutCarePlanEnrollmentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput>;
};

export type OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutOrganizationPetToOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetUpdateOneWithoutClinicPetsInput = {
  create?: Maybe<OrganizationPetUncheckedCreateWithoutClinicPetsInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutClinicPetsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutClinicPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutClinicPetsInput>;
};

export type OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: OrganizationPetWhereUniqueInput;
  data: OrganizationPetUncheckedUpdateWithoutOrganizationInput;
};

export type OrganizationPetUpdateWithoutCareAddonEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUpdateWithoutCarePlanEnrollmentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUpdateWithoutClinicPetsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUpdateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetInput>;
  organizationPetToOrganizationPetParents?: Maybe<
    OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetInput
  >;
};

export type OrganizationPetUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetInput>;
};

export type OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: OrganizationPetWhereUniqueInput;
  update: OrganizationPetUncheckedUpdateWithoutOrganizationInput;
  create: OrganizationPetUncheckedCreateWithoutOrganizationInput;
};

export type OrganizationPetUpsertWithoutCareAddonEnrollmentsInput = {
  update: OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput;
  create: OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput;
};

export type OrganizationPetUpsertWithoutCarePlanEnrollmentsInput = {
  update: OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput;
  create: OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput;
};

export type OrganizationPetUpsertWithoutClinicPetsInput = {
  update: OrganizationPetUncheckedUpdateWithoutClinicPetsInput;
  create: OrganizationPetUncheckedCreateWithoutClinicPetsInput;
};

export type OrganizationPetUpsertWithoutOrganizationPetToOrganizationPetParentsInput = {
  update: OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput;
  create: OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetWhereInput = {
  AND?: Maybe<Array<OrganizationPetWhereInput>>;
  OR?: Maybe<Array<OrganizationPetWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetWhereInput>>;
  id?: Maybe<StringFilter>;
  preferredName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  profilePictureUrl?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentListRelationFilter>;
};

export type OrganizationPetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrganizationRelationFilter = {
  is?: Maybe<OrganizationWhereInput>;
  isNot?: Maybe<OrganizationWhereInput>;
};

export enum OrganizationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  PaymentProcessorClinicId = 'paymentProcessorClinicId',
}

export type OrganizationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type OrganizationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationUncheckedCreateWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedCreateWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedCreateWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedCreateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedCreateWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<OrganizationUpsertWithoutPaymentProcessorClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput>;
};

export type OrganizationUncheckedUpdateWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateOneRequiredWithoutCareBenefitsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCareBenefitsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareBenefitsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCareBenefitsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCareBenefitsInput>;
};

export type OrganizationUpdateOneRequiredWithoutCarePlansInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlansInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlansInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCarePlansInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCarePlansInput>;
};

export type OrganizationUpdateOneRequiredWithoutOrganizationPetParentsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutOrganizationPetParentsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput>;
};

export type OrganizationUpdateOneRequiredWithoutOrganizationPetsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutOrganizationPetsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutOrganizationPetsInput>;
};

export type OrganizationUpdateOneWithoutCareAddonPackageInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutCareAddonPackageInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareAddonPackageInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCareAddonPackageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCareAddonPackageInput>;
};

export type OrganizationUpdateOneWithoutClinicsInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutClinicsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutClinicsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutClinicsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutClinicsInput>;
};

export type OrganizationUpdateOneWithoutPaymentProcessorClinicInput = {
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<OrganizationUpsertWithoutPaymentProcessorClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput>;
};

export type OrganizationUpdateWithoutCareAddonPackageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationUpdateWithoutCareBenefitsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationUpdateWithoutCarePlansInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationUpdateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationUpdateWithoutOrganizationPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationInput>;
};

export type OrganizationUpdateWithoutPaymentProcessorClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpsertWithoutCareAddonPackageInput = {
  update: OrganizationUncheckedUpdateWithoutCareAddonPackageInput;
  create: OrganizationUncheckedCreateWithoutCareAddonPackageInput;
};

export type OrganizationUpsertWithoutCareBenefitsInput = {
  update: OrganizationUncheckedUpdateWithoutCareBenefitsInput;
  create: OrganizationUncheckedCreateWithoutCareBenefitsInput;
};

export type OrganizationUpsertWithoutCarePlansInput = {
  update: OrganizationUncheckedUpdateWithoutCarePlansInput;
  create: OrganizationUncheckedCreateWithoutCarePlansInput;
};

export type OrganizationUpsertWithoutClinicsInput = {
  update: OrganizationUncheckedUpdateWithoutClinicsInput;
  create: OrganizationUncheckedCreateWithoutClinicsInput;
};

export type OrganizationUpsertWithoutOrganizationPetParentsInput = {
  update: OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput;
  create: OrganizationUncheckedCreateWithoutOrganizationPetParentsInput;
};

export type OrganizationUpsertWithoutOrganizationPetsInput = {
  update: OrganizationUncheckedUpdateWithoutOrganizationPetsInput;
  create: OrganizationUncheckedCreateWithoutOrganizationPetsInput;
};

export type OrganizationUpsertWithoutPaymentProcessorClinicInput = {
  update: OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput;
  create: OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput;
};

export type OrganizationWhereInput = {
  AND?: Maybe<Array<OrganizationWhereInput>>;
  OR?: Maybe<Array<OrganizationWhereInput>>;
  NOT?: Maybe<Array<OrganizationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  organizationPets?: Maybe<OrganizationPetListRelationFilter>;
  organizationPetParents?: Maybe<OrganizationPetParentListRelationFilter>;
  carePlans?: Maybe<CarePlanListRelationFilter>;
  careBenefits?: Maybe<CareBenefitListRelationFilter>;
  careAddonPackage?: Maybe<CareAddonPackageListRelationFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableFilter>;
  paymentProcessorClinic?: Maybe<ClinicWhereInput>;
};

export type OrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

/** @deprecated - 1.0 */
export type OwnerInfo = {
  __typename?: 'OwnerInfo';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  consultations: Array<Consultation>;
  pets: Array<Pet>;
  user?: Maybe<User>;
  _count: OwnerInfoCountOutputType;
};

/** @deprecated - 1.0 */
export type OwnerInfoConsultationsArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};

/** @deprecated - 1.0 */
export type OwnerInfoPetsArgs = {
  where?: Maybe<PetWhereInput>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PetScalarFieldEnum>;
};

export type OwnerInfoCountAggregateOutputType = {
  __typename?: 'OwnerInfoCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  firebaseId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  customerId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OwnerInfoCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
};

export type OwnerInfoCountOutputType = {
  __typename?: 'OwnerInfoCountOutputType';
  consultations: Scalars['Int'];
  pets: Scalars['Int'];
};

export type OwnerInfoCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoCreateNestedOneWithoutConsultationsInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutConsultationsInput>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
};

export type OwnerInfoCreateNestedOneWithoutPetsInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutPetsInput>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
};

export type OwnerInfoCreateNestedOneWithoutUserInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutUserInput>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
};

export type OwnerInfoCreateOrConnectWithoutConsultationsInput = {
  where: OwnerInfoWhereUniqueInput;
  create: OwnerInfoUncheckedCreateWithoutConsultationsInput;
};

export type OwnerInfoCreateOrConnectWithoutPetsInput = {
  where: OwnerInfoWhereUniqueInput;
  create: OwnerInfoUncheckedCreateWithoutPetsInput;
};

export type OwnerInfoCreateOrConnectWithoutUserInput = {
  where: OwnerInfoWhereUniqueInput;
  create: OwnerInfoUncheckedCreateWithoutUserInput;
};

export type OwnerInfoCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
};

export type OwnerInfoMaxAggregateOutputType = {
  __typename?: 'OwnerInfoMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
};

export type OwnerInfoMinAggregateOutputType = {
  __typename?: 'OwnerInfoMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
};

export enum OwnerInfoOrderByRelevanceFieldEnum {
  Id = 'id',
  FirebaseId = 'firebaseId',
  CustomerId = 'customerId',
}

export type OwnerInfoOrderByRelevanceInput = {
  fields: Array<OwnerInfoOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type OwnerInfoOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  _count?: Maybe<OwnerInfoCountOrderByAggregateInput>;
  _max?: Maybe<OwnerInfoMaxOrderByAggregateInput>;
  _min?: Maybe<OwnerInfoMinOrderByAggregateInput>;
};

export type OwnerInfoOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  pets?: Maybe<PetOrderByRelationAggregateInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<OwnerInfoOrderByRelevanceInput>;
};

export type OwnerInfoRelationFilter = {
  is?: Maybe<OwnerInfoWhereInput>;
  isNot?: Maybe<OwnerInfoWhereInput>;
};

export enum OwnerInfoScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  FirebaseId = 'firebaseId',
  UpdatedAt = 'updatedAt',
  CustomerId = 'customerId',
}

export type OwnerInfoScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  customerId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type OwnerInfoUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
};

export type OwnerInfoUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoUncheckedUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentInput>;
};

export type OwnerInfoUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentInput>;
  pets?: Maybe<PetUpdateManyWithoutPetParentInput>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
};

export type OwnerInfoUpdateOneWithoutConsultationsInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutConsultationsInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutConsultationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutConsultationsInput>;
};

export type OwnerInfoUpdateOneWithoutPetsInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutPetsInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutPetsInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutPetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutPetsInput>;
};

export type OwnerInfoUpdateOneWithoutUserInput = {
  create?: Maybe<OwnerInfoUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutUserInput>;
};

export type OwnerInfoUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUpdateManyWithoutPetParentInput>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUpdateWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentInput>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentInput>;
  pets?: Maybe<PetUpdateManyWithoutPetParentInput>;
};

export type OwnerInfoUpsertWithoutConsultationsInput = {
  update: OwnerInfoUncheckedUpdateWithoutConsultationsInput;
  create: OwnerInfoUncheckedCreateWithoutConsultationsInput;
};

export type OwnerInfoUpsertWithoutPetsInput = {
  update: OwnerInfoUncheckedUpdateWithoutPetsInput;
  create: OwnerInfoUncheckedCreateWithoutPetsInput;
};

export type OwnerInfoUpsertWithoutUserInput = {
  update: OwnerInfoUncheckedUpdateWithoutUserInput;
  create: OwnerInfoUncheckedCreateWithoutUserInput;
};

export type OwnerInfoWhereInput = {
  AND?: Maybe<Array<OwnerInfoWhereInput>>;
  OR?: Maybe<Array<OwnerInfoWhereInput>>;
  NOT?: Maybe<Array<OwnerInfoWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringNullableFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  pets?: Maybe<PetListRelationFilter>;
  user?: Maybe<UserWhereInput>;
};

export type OwnerInfoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export type PpcIntegration = {
  __typename?: 'PPCIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type PpcIntegrationCountAggregateOutputType = {
  __typename?: 'PPCIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  ppcLocationId: Scalars['Int'];
  ppcClinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PpcIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
};

export type PpcIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPpcInput>;
};

export type PpcIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<PpcIntegrationWhereUniqueInput>;
};

export type PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: PpcIntegrationWhereUniqueInput;
  create: PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type PpcIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationMaxAggregateOutputType = {
  __typename?: 'PPCIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
};

export type PpcIntegrationMinAggregateOutputType = {
  __typename?: 'PPCIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
};

export enum PpcIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  PpcLocationId = 'ppcLocationId',
  PpcClinicId = 'ppcClinicId',
}

export type PpcIntegrationOrderByRelevanceInput = {
  fields: Array<PpcIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PpcIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  _count?: Maybe<PpcIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<PpcIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<PpcIntegrationMinOrderByAggregateInput>;
};

export type PpcIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PpcIntegrationOrderByRelevanceInput>;
};

export type PpcIntegrationRelationFilter = {
  is?: Maybe<PpcIntegrationWhereInput>;
  isNot?: Maybe<PpcIntegrationWhereInput>;
};

export enum PpcIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PpcLocationId = 'ppcLocationId',
  PpcClinicId = 'ppcClinicId',
}

export type PpcIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  ppcLocationId?: Maybe<StringNullableWithAggregatesFilter>;
  ppcClinicId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PpcIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutPpcInput>;
};

export type PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutPpcInput>;
};

export type PpcIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPpcInput>;
};

export type PpcIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUpdateOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<PpcIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PpcIntegrationWhereUniqueInput>;
  update?: Maybe<PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type PpcIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
};

export type PpcIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type PpcIntegrationWhereInput = {
  AND?: Maybe<Array<PpcIntegrationWhereInput>>;
  OR?: Maybe<Array<PpcIntegrationWhereInput>>;
  NOT?: Maybe<Array<PpcIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  ppcLocationId?: Maybe<StringNullableFilter>;
  ppcClinicId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type PpcIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PaymentMedium {
  StripeOnline = 'StripeOnline',
  StripeTerminal = 'StripeTerminal',
  StripeVirtualTerminal = 'StripeVirtualTerminal',
}

export enum PaymentPimsWritebackAction {
  PaymentCollected = 'Payment_Collected',
  CareDiscountApplied = 'Care_Discount_Applied',
}

export type PaymentPimsWritebackLogEntry = {
  __typename?: 'PaymentPimsWritebackLogEntry';
  writebackLogId: Scalars['String'];
  writebackLog: PimsWritebackLogEntry;
  paymentId: Scalars['String'];
  payment: StripePaymentIntent;
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryCountAggregateOutputType';
  writebackLogId: Scalars['Int'];
  paymentId: Scalars['Int'];
  action: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PaymentPimsWritebackLogEntryCountOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryCreateInput = {
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput;
  payment: StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput;
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryCreateManyInput = {
  writebackLogId: Scalars['String'];
  paymentId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryCreateManyPaymentInput = {
  writebackLogId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope = {
  data: Array<PaymentPimsWritebackLogEntryCreateManyPaymentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput = {
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
};

export type PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
};

export type PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput = {
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput;
};

export type PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type PaymentPimsWritebackLogEntryCreateWithoutPaymentInput = {
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput;
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  payment: StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput;
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  some?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  none?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
};

export type PaymentPimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryMaxAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryMaxOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryMinAggregateOutputType';
  writebackLogId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryMinOrderByAggregateInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PaymentPimsWritebackLogEntryOrderByRelevanceFieldEnum {
  WritebackLogId = 'writebackLogId',
  PaymentId = 'paymentId',
}

export type PaymentPimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<PaymentPimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PaymentPimsWritebackLogEntryOrderByWithAggregationInput = {
  writebackLogId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  _count?: Maybe<PaymentPimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<PaymentPimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<PaymentPimsWritebackLogEntryMinOrderByAggregateInput>;
};

export type PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  writebackLogId?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  paymentId?: Maybe<SortOrder>;
  payment?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  action?: Maybe<SortOrder>;
  _relevance?: Maybe<PaymentPimsWritebackLogEntryOrderByRelevanceInput>;
};

export type PaymentPimsWritebackLogEntryRelationFilter = {
  is?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
};

export enum PaymentPimsWritebackLogEntryScalarFieldEnum {
  WritebackLogId = 'writebackLogId',
  PaymentId = 'paymentId',
  Action = 'action',
}

export type PaymentPimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  paymentId?: Maybe<StringFilter>;
  action?: Maybe<EnumPaymentPimsWritebackActionFilter>;
};

export type PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
  paymentId?: Maybe<StringWithAggregatesFilter>;
  action?: Maybe<EnumPaymentPimsWritebackActionWithAggregatesFilter>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateInput = {
  writebackLogId: Scalars['String'];
  paymentId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput = {
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput = {
  writebackLogId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  paymentId: Scalars['String'];
  action: PaymentPimsWritebackAction;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput = {
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  upsert?: Maybe<Array<PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  set?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput>>;
  deleteMany?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput = {
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  paymentId?: Maybe<Scalars['String']>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUpdateInput = {
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogInput>;
  payment?: Maybe<StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesInput>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUpdateManyMutationInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput = {
  where: PaymentPimsWritebackLogEntryScalarWhereInput;
  data: PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput;
};

export type PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput = {
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  upsert?: Maybe<Array<PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  set?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput>>;
  deleteMany?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
};

export type PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogInput = {
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  upsert?: Maybe<PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
};

export type PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput = {
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
  data: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput;
};

export type PaymentPimsWritebackLogEntryUpdateWithoutPaymentInput = {
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogInput>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  payment?: Maybe<StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesInput>;
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput = {
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
  update: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput;
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput;
};

export type PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  update: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
};

export type PaymentPimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  writebackLogId?: Maybe<StringFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  paymentId?: Maybe<StringFilter>;
  payment?: Maybe<StripePaymentIntentWhereInput>;
  action?: Maybe<EnumPaymentPimsWritebackActionFilter>;
};

export type PaymentPimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_paymentId?: Maybe<PaymentPimsWritebackLogEntryWritebackLogIdPaymentIdCompoundUniqueInput>;
};

export type PaymentPimsWritebackLogEntryWritebackLogIdPaymentIdCompoundUniqueInput = {
  writebackLogId: Scalars['String'];
  paymentId: Scalars['String'];
};

export enum PaymentRelationship {
  AccountBalance = 'AccountBalance',
  CustomAmount = 'CustomAmount',
}

export enum PaymentSource {
  Braintree = 'Braintree',
  Stripe = 'Stripe',
}

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  type: PermissionType;
  updatedAt: Scalars['DateTime'];
  roles: Array<StaffRole>;
  _count: PermissionCountOutputType;
};

export type PermissionRolesArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
};

export type PermissionCountAggregateOutputType = {
  __typename?: 'PermissionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PermissionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionCountOutputType = {
  __typename?: 'PermissionCountOutputType';
  roles: Scalars['Int'];
};

export type PermissionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutPermissionsInput>;
};

export type PermissionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionCreateNestedManyWithoutRolesInput = {
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
};

export type PermissionCreateOrConnectWithoutRolesInput = {
  where: PermissionWhereUniqueInput;
  create: PermissionUncheckedCreateWithoutRolesInput;
};

export type PermissionCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionListRelationFilter = {
  every?: Maybe<PermissionWhereInput>;
  some?: Maybe<PermissionWhereInput>;
  none?: Maybe<PermissionWhereInput>;
};

export type PermissionMaxAggregateOutputType = {
  __typename?: 'PermissionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionMinAggregateOutputType = {
  __typename?: 'PermissionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PermissionOrderByRelevanceFieldEnum {
  Id = 'id',
}

export type PermissionOrderByRelevanceInput = {
  fields: Array<PermissionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PermissionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<PermissionCountOrderByAggregateInput>;
  _max?: Maybe<PermissionMaxOrderByAggregateInput>;
  _min?: Maybe<PermissionMinOrderByAggregateInput>;
};

export type PermissionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  _relevance?: Maybe<PermissionOrderByRelevanceInput>;
};

export enum PermissionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export type PermissionScalarWhereInput = {
  AND?: Maybe<Array<PermissionScalarWhereInput>>;
  OR?: Maybe<Array<PermissionScalarWhereInput>>;
  NOT?: Maybe<Array<PermissionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumPermissionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PermissionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumPermissionTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum PermissionType {
  ChangeClinicPricing = 'ChangeClinicPricing',
  ReadPet = 'ReadPet',
  WritePet = 'WritePet',
  WriteClinic = 'WriteClinic',
  ReadConsultation = 'ReadConsultation',
  WriteConsultation = 'WriteConsultation',
  ReadUser = 'ReadUser',
  WriteUser = 'WriteUser',
  ReadOrganization = 'ReadOrganization',
  WriteOrganization = 'WriteOrganization',
  ReadAppointment = 'ReadAppointment',
  WriteAppointment = 'WriteAppointment',
  ReadChannel = 'ReadChannel',
  WriteChannel = 'WriteChannel',
  ReadClinicPetParent = 'ReadClinicPetParent',
  WriteClinicPetParent = 'WriteClinicPetParent',
  ManageChannelMessage = 'ManageChannelMessage',
}

export type PermissionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutPermissionsInput>;
};

export type PermissionUncheckedCreateNestedManyWithoutRolesInput = {
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
};

export type PermissionUncheckedCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutPermissionsInput>;
};

export type PermissionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateManyWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateManyWithoutRolesInput = {
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  upsert?: Maybe<Array<PermissionUpsertWithWhereUniqueWithoutRolesInput>>;
  set?: Maybe<Array<PermissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PermissionWhereUniqueInput>>;
  delete?: Maybe<Array<PermissionWhereUniqueInput>>;
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
  update?: Maybe<Array<PermissionUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<PermissionUpdateManyWithWhereWithoutRolesInput>>;
  deleteMany?: Maybe<Array<PermissionScalarWhereInput>>;
};

export type PermissionUncheckedUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutPermissionsInput>;
};

export type PermissionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpdateManyWithWhereWithoutRolesInput = {
  where: PermissionScalarWhereInput;
  data: PermissionUncheckedUpdateManyWithoutPermissionsInput;
};

export type PermissionUpdateManyWithoutRolesInput = {
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  upsert?: Maybe<Array<PermissionUpsertWithWhereUniqueWithoutRolesInput>>;
  set?: Maybe<Array<PermissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PermissionWhereUniqueInput>>;
  delete?: Maybe<Array<PermissionWhereUniqueInput>>;
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
  update?: Maybe<Array<PermissionUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<PermissionUpdateManyWithWhereWithoutRolesInput>>;
  deleteMany?: Maybe<Array<PermissionScalarWhereInput>>;
};

export type PermissionUpdateWithWhereUniqueWithoutRolesInput = {
  where: PermissionWhereUniqueInput;
  data: PermissionUncheckedUpdateWithoutRolesInput;
};

export type PermissionUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpsertWithWhereUniqueWithoutRolesInput = {
  where: PermissionWhereUniqueInput;
  update: PermissionUncheckedUpdateWithoutRolesInput;
  create: PermissionUncheckedCreateWithoutRolesInput;
};

export type PermissionWhereInput = {
  AND?: Maybe<Array<PermissionWhereInput>>;
  OR?: Maybe<Array<PermissionWhereInput>>;
  NOT?: Maybe<Array<PermissionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumPermissionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
};

export type PermissionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
};

/** @deprecated - 1.0 */
export type Pet = {
  __typename?: 'Pet';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<Clinic>;
  petParent?: Maybe<OwnerInfo>;
  consultations: Array<Consultation>;
  previousClinics: Array<Clinic>;
  _count: PetCountOutputType;
};

/** @deprecated - 1.0 */
export type PetConsultationsArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};

/** @deprecated - 1.0 */
export type PetPreviousClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type PetAvgAggregateOutputType = {
  __typename?: 'PetAvgAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
  bornOnDay?: Maybe<Scalars['Float']>;
  bornOnMonth?: Maybe<Scalars['Float']>;
  bornOnYear?: Maybe<Scalars['Float']>;
};

export type PetAvgOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
};

export type PetBenefitUsage = {
  __typename?: 'PetBenefitUsage';
  organizationPetId: Scalars['String'];
  usages: Array<UsageByBenefit>;
};

export type PetCountAggregateOutputType = {
  __typename?: 'PetCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  name: Scalars['Int'];
  breed: Scalars['Int'];
  bornOn: Scalars['Int'];
  photo: Scalars['Int'];
  repoStatus: Scalars['Int'];
  environment: Scalars['Int'];
  weight: Scalars['Int'];
  firebaseId: Scalars['Int'];
  clinicId: Scalars['Int'];
  petParentId: Scalars['Int'];
  petType: Scalars['Int'];
  updatedAt: Scalars['Int'];
  gender: Scalars['Int'];
  bornOnDay: Scalars['Int'];
  bornOnMonth: Scalars['Int'];
  bornOnYear: Scalars['Int'];
  pimsId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
};

export type PetCountOutputType = {
  __typename?: 'PetCountOutputType';
  consultations: Scalars['Int'];
  previousClinics: Scalars['Int'];
};

export type PetCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
};

export type PetCreateManyCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetCreateManyCurrentClinicInputEnvelope = {
  data: Array<PetCreateManyCurrentClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetCreateManyPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetCreateManyPetParentInputEnvelope = {
  data: Array<PetCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PetCreateNestedManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetCreateNestedManyWithoutPreviousClinicsInput = {
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetCreateNestedOneWithoutConsultationsInput = {
  create?: Maybe<PetUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<PetCreateOrConnectWithoutConsultationsInput>;
  connect?: Maybe<PetWhereUniqueInput>;
};

export type PetCreateOrConnectWithoutConsultationsInput = {
  where: PetWhereUniqueInput;
  create: PetUncheckedCreateWithoutConsultationsInput;
};

export type PetCreateOrConnectWithoutCurrentClinicInput = {
  where: PetWhereUniqueInput;
  create: PetUncheckedCreateWithoutCurrentClinicInput;
};

export type PetCreateOrConnectWithoutPetParentInput = {
  where: PetWhereUniqueInput;
  create: PetUncheckedCreateWithoutPetParentInput;
};

export type PetCreateOrConnectWithoutPreviousClinicsInput = {
  where: PetWhereUniqueInput;
  create: PetUncheckedCreateWithoutPreviousClinicsInput;
};

export type PetCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
};

export type PetCreateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
};

export type PetCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
};

export type PetCreateWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
};

export type PetListRelationFilter = {
  every?: Maybe<PetWhereInput>;
  some?: Maybe<PetWhereInput>;
  none?: Maybe<PetWhereInput>;
};

export type PetMaxAggregateOutputType = {
  __typename?: 'PetMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
};

export type PetMinAggregateOutputType = {
  __typename?: 'PetMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
};

export type PetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PetOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Breed = 'breed',
  Photo = 'photo',
  RepoStatus = 'repoStatus',
  Environment = 'environment',
  FirebaseId = 'firebaseId',
  ClinicId = 'clinicId',
  PetParentId = 'petParentId',
  PetType = 'petType',
  Gender = 'gender',
  PimsId = 'pimsId',
}

export type PetOrderByRelevanceInput = {
  fields: Array<PetOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  _count?: Maybe<PetCountOrderByAggregateInput>;
  _avg?: Maybe<PetAvgOrderByAggregateInput>;
  _max?: Maybe<PetMaxOrderByAggregateInput>;
  _min?: Maybe<PetMinOrderByAggregateInput>;
  _sum?: Maybe<PetSumOrderByAggregateInput>;
};

export type PetOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  bornOn?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  currentClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  petParent?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  previousClinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  _relevance?: Maybe<PetOrderByRelevanceInput>;
};

export type PetParentSetting = {
  __typename?: 'PetParentSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  emailNotifications: Scalars['Boolean'];
  emailOptedOutByPetParent: Scalars['Boolean'];
  smsNotifications: Scalars['Boolean'];
  smsOptedOutByPetParent: Scalars['Boolean'];
  isSubscribedToReminders: Scalars['Boolean'];
  petParent: Array<ClinicPetParent>;
  _count: PetParentSettingCountOutputType;
};

export type PetParentSettingPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type PetParentSettingCountAggregateOutputType = {
  __typename?: 'PetParentSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  emailNotifications: Scalars['Int'];
  emailOptedOutByPetParent: Scalars['Int'];
  smsNotifications: Scalars['Int'];
  smsOptedOutByPetParent: Scalars['Int'];
  isSubscribedToReminders: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PetParentSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
};

export type PetParentSettingCountOutputType = {
  __typename?: 'PetParentSettingCountOutputType';
  petParent: Scalars['Int'];
};

export type PetParentSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentCreateNestedManyWithoutPetParentSettingInput>;
};

export type PetParentSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingCreateNestedOneWithoutPetParentInput = {
  create?: Maybe<PetParentSettingUncheckedCreateWithoutPetParentInput>;
  connectOrCreate?: Maybe<PetParentSettingCreateOrConnectWithoutPetParentInput>;
  connect?: Maybe<PetParentSettingWhereUniqueInput>;
};

export type PetParentSettingCreateOrConnectWithoutPetParentInput = {
  where: PetParentSettingWhereUniqueInput;
  create: PetParentSettingUncheckedCreateWithoutPetParentInput;
};

export type PetParentSettingCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingMaxAggregateOutputType = {
  __typename?: 'PetParentSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
};

export type PetParentSettingMinAggregateOutputType = {
  __typename?: 'PetParentSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
};

export enum PetParentSettingOrderByRelevanceFieldEnum {
  Id = 'id',
}

export type PetParentSettingOrderByRelevanceInput = {
  fields: Array<PetParentSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PetParentSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  _count?: Maybe<PetParentSettingCountOrderByAggregateInput>;
  _max?: Maybe<PetParentSettingMaxOrderByAggregateInput>;
  _min?: Maybe<PetParentSettingMinOrderByAggregateInput>;
};

export type PetParentSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  petParent?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  _relevance?: Maybe<PetParentSettingOrderByRelevanceInput>;
};

export type PetParentSettingRelationFilter = {
  is?: Maybe<PetParentSettingWhereInput>;
  isNot?: Maybe<PetParentSettingWhereInput>;
};

export enum PetParentSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  EmailNotifications = 'emailNotifications',
  EmailOptedOutByPetParent = 'emailOptedOutByPetParent',
  SmsNotifications = 'smsNotifications',
  SmsOptedOutByPetParent = 'smsOptedOutByPetParent',
  IsSubscribedToReminders = 'isSubscribedToReminders',
}

export type PetParentSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailNotifications?: Maybe<BoolWithAggregatesFilter>;
  emailOptedOutByPetParent?: Maybe<BoolWithAggregatesFilter>;
  smsNotifications?: Maybe<BoolWithAggregatesFilter>;
  smsOptedOutByPetParent?: Maybe<BoolWithAggregatesFilter>;
  isSubscribedToReminders?: Maybe<BoolWithAggregatesFilter>;
};

export type PetParentSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetParentSettingInput>;
};

export type PetParentSettingUncheckedCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetParentSettingInput>;
};

export type PetParentSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUncheckedUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUpdateManyWithoutPetParentSettingInput>;
};

export type PetParentSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUpdateOneWithoutPetParentInput = {
  create?: Maybe<PetParentSettingUncheckedCreateWithoutPetParentInput>;
  connectOrCreate?: Maybe<PetParentSettingCreateOrConnectWithoutPetParentInput>;
  upsert?: Maybe<PetParentSettingUpsertWithoutPetParentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PetParentSettingWhereUniqueInput>;
  update?: Maybe<PetParentSettingUncheckedUpdateWithoutPetParentInput>;
};

export type PetParentSettingUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
};

export type PetParentSettingUpsertWithoutPetParentInput = {
  update: PetParentSettingUncheckedUpdateWithoutPetParentInput;
  create: PetParentSettingUncheckedCreateWithoutPetParentInput;
};

export type PetParentSettingWhereInput = {
  AND?: Maybe<Array<PetParentSettingWhereInput>>;
  OR?: Maybe<Array<PetParentSettingWhereInput>>;
  NOT?: Maybe<Array<PetParentSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  emailNotifications?: Maybe<BoolFilter>;
  emailOptedOutByPetParent?: Maybe<BoolFilter>;
  smsNotifications?: Maybe<BoolFilter>;
  smsOptedOutByPetParent?: Maybe<BoolFilter>;
  isSubscribedToReminders?: Maybe<BoolFilter>;
  petParent?: Maybe<ClinicPetParentListRelationFilter>;
};

export type PetParentSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PetPortalSetting = {
  __typename?: 'PetPortalSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  showAppointments: Scalars['Boolean'];
  clinicSettingId: Scalars['String'];
  clinicSetting: ClinicSetting;
};

export type PetPortalSettingCountAggregateOutputType = {
  __typename?: 'PetPortalSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  showAppointments: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PetPortalSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
};

export type PetPortalSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutPetPortalSettingInput;
};

export type PetPortalSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId: Scalars['String'];
};

export type PetPortalSettingCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
};

export type PetPortalSettingCreateOrConnectWithoutClinicSettingInput = {
  where: PetPortalSettingWhereUniqueInput;
  create: PetPortalSettingUncheckedCreateWithoutClinicSettingInput;
};

export type PetPortalSettingCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingMaxAggregateOutputType = {
  __typename?: 'PetPortalSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetPortalSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
};

export type PetPortalSettingMinAggregateOutputType = {
  __typename?: 'PetPortalSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetPortalSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
};

export enum PetPortalSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicSettingId = 'clinicSettingId',
}

export type PetPortalSettingOrderByRelevanceInput = {
  fields: Array<PetPortalSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PetPortalSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  _count?: Maybe<PetPortalSettingCountOrderByAggregateInput>;
  _max?: Maybe<PetPortalSettingMaxOrderByAggregateInput>;
  _min?: Maybe<PetPortalSettingMinOrderByAggregateInput>;
};

export type PetPortalSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PetPortalSettingOrderByRelevanceInput>;
};

export type PetPortalSettingRelationFilter = {
  is?: Maybe<PetPortalSettingWhereInput>;
  isNot?: Maybe<PetPortalSettingWhereInput>;
};

export enum PetPortalSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ShowAppointments = 'showAppointments',
  ClinicSettingId = 'clinicSettingId',
}

export type PetPortalSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  showAppointments?: Maybe<BoolWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
};

export type PetPortalSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId: Scalars['String'];
};

export type PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput = {
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
};

export type PetPortalSettingUncheckedCreateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetPortalSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetPortalSettingUncheckedUpdateOneWithoutClinicSettingInput = {
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<PetPortalSettingUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
  update?: Maybe<PetPortalSettingUncheckedUpdateWithoutClinicSettingInput>;
};

export type PetPortalSettingUncheckedUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutPetPortalSettingInput>;
};

export type PetPortalSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingUpdateOneWithoutClinicSettingInput = {
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  upsert?: Maybe<PetPortalSettingUpsertWithoutClinicSettingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
  update?: Maybe<PetPortalSettingUncheckedUpdateWithoutClinicSettingInput>;
};

export type PetPortalSettingUpdateWithoutClinicSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
};

export type PetPortalSettingUpsertWithoutClinicSettingInput = {
  update: PetPortalSettingUncheckedUpdateWithoutClinicSettingInput;
  create: PetPortalSettingUncheckedCreateWithoutClinicSettingInput;
};

export type PetPortalSettingWhereInput = {
  AND?: Maybe<Array<PetPortalSettingWhereInput>>;
  OR?: Maybe<Array<PetPortalSettingWhereInput>>;
  NOT?: Maybe<Array<PetPortalSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  showAppointments?: Maybe<BoolFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
};

export type PetPortalSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
};

export type PetRelationFilter = {
  is?: Maybe<PetWhereInput>;
  isNot?: Maybe<PetWhereInput>;
};

export enum PetScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Name = 'name',
  Breed = 'breed',
  BornOn = 'bornOn',
  Photo = 'photo',
  RepoStatus = 'repoStatus',
  Environment = 'environment',
  Weight = 'weight',
  FirebaseId = 'firebaseId',
  ClinicId = 'clinicId',
  PetParentId = 'petParentId',
  PetType = 'petType',
  UpdatedAt = 'updatedAt',
  Gender = 'gender',
  BornOnDay = 'bornOnDay',
  BornOnMonth = 'bornOnMonth',
  BornOnYear = 'bornOnYear',
  PimsId = 'pimsId',
}

export type PetScalarWhereInput = {
  AND?: Maybe<Array<PetScalarWhereInput>>;
  OR?: Maybe<Array<PetScalarWhereInput>>;
  NOT?: Maybe<Array<PetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  breed?: Maybe<StringNullableFilter>;
  bornOn?: Maybe<DateTimeNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  repoStatus?: Maybe<StringNullableFilter>;
  environment?: Maybe<StringFilter>;
  weight?: Maybe<FloatFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petType?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  gender?: Maybe<StringFilter>;
  bornOnDay?: Maybe<IntNullableFilter>;
  bornOnMonth?: Maybe<IntNullableFilter>;
  bornOnYear?: Maybe<IntNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
};

export type PetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  breed?: Maybe<StringNullableWithAggregatesFilter>;
  bornOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  photo?: Maybe<StringNullableWithAggregatesFilter>;
  repoStatus?: Maybe<StringNullableWithAggregatesFilter>;
  environment?: Maybe<StringWithAggregatesFilter>;
  weight?: Maybe<FloatWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  petType?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  gender?: Maybe<StringWithAggregatesFilter>;
  bornOnDay?: Maybe<IntNullableWithAggregatesFilter>;
  bornOnMonth?: Maybe<IntNullableWithAggregatesFilter>;
  bornOnYear?: Maybe<IntNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PetSumAggregateOutputType = {
  __typename?: 'PetSumAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
};

export type PetSumOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
};

export type PetUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
};

export type PetUncheckedCreateNestedManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetUncheckedCreateNestedManyWithoutPetParentInput = {
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetUncheckedCreateNestedManyWithoutPreviousClinicsInput = {
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
};

export type PetUncheckedCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
};

export type PetUncheckedCreateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
};

export type PetUncheckedCreateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
};

export type PetUncheckedCreateWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
};

export type PetUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsInput>;
};

export type PetUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetUncheckedUpdateManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutCurrentClinicInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUncheckedUpdateManyWithoutPetParentInput = {
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUncheckedUpdateManyWithoutPetsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetUncheckedUpdateManyWithoutPetsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetUncheckedUpdateManyWithoutPreviousClinicsInput = {
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPreviousClinicsInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPreviousClinicsInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPreviousClinicsInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUncheckedUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsInput>;
};

export type PetUncheckedUpdateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsInput>;
};

export type PetUncheckedUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsInput>;
};

export type PetUncheckedUpdateWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetInput>;
};

export type PetUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsInput>;
};

export type PetUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PetUpdateManyWithWhereWithoutCurrentClinicInput = {
  where: PetScalarWhereInput;
  data: PetUncheckedUpdateManyWithoutPetsIdInput;
};

export type PetUpdateManyWithWhereWithoutPetParentInput = {
  where: PetScalarWhereInput;
  data: PetUncheckedUpdateManyWithoutPetsInput;
};

export type PetUpdateManyWithWhereWithoutPreviousClinicsInput = {
  where: PetScalarWhereInput;
  data: PetUncheckedUpdateManyWithoutPetsInput;
};

export type PetUpdateManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutCurrentClinicInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUpdateManyWithoutPetParentInput = {
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPetParentInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUpdateManyWithoutPreviousClinicsInput = {
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPreviousClinicsInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPreviousClinicsInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPreviousClinicsInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
};

export type PetUpdateOneWithoutConsultationsInput = {
  create?: Maybe<PetUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<PetCreateOrConnectWithoutConsultationsInput>;
  upsert?: Maybe<PetUpsertWithoutConsultationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PetWhereUniqueInput>;
  update?: Maybe<PetUncheckedUpdateWithoutConsultationsInput>;
};

export type PetUpdateWithWhereUniqueWithoutCurrentClinicInput = {
  where: PetWhereUniqueInput;
  data: PetUncheckedUpdateWithoutCurrentClinicInput;
};

export type PetUpdateWithWhereUniqueWithoutPetParentInput = {
  where: PetWhereUniqueInput;
  data: PetUncheckedUpdateWithoutPetParentInput;
};

export type PetUpdateWithWhereUniqueWithoutPreviousClinicsInput = {
  where: PetWhereUniqueInput;
  data: PetUncheckedUpdateWithoutPreviousClinicsInput;
};

export type PetUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsInput>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsInput>;
};

export type PetUpdateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsInput>;
};

export type PetUpdateWithoutPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetInput>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsInput>;
};

export type PetUpdateWithoutPreviousClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  breed?: Maybe<Scalars['String']>;
  bornOn?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  pimsId?: Maybe<Scalars['String']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetInput>;
};

export type PetUpsertWithWhereUniqueWithoutCurrentClinicInput = {
  where: PetWhereUniqueInput;
  update: PetUncheckedUpdateWithoutCurrentClinicInput;
  create: PetUncheckedCreateWithoutCurrentClinicInput;
};

export type PetUpsertWithWhereUniqueWithoutPetParentInput = {
  where: PetWhereUniqueInput;
  update: PetUncheckedUpdateWithoutPetParentInput;
  create: PetUncheckedCreateWithoutPetParentInput;
};

export type PetUpsertWithWhereUniqueWithoutPreviousClinicsInput = {
  where: PetWhereUniqueInput;
  update: PetUncheckedUpdateWithoutPreviousClinicsInput;
  create: PetUncheckedCreateWithoutPreviousClinicsInput;
};

export type PetUpsertWithoutConsultationsInput = {
  update: PetUncheckedUpdateWithoutConsultationsInput;
  create: PetUncheckedCreateWithoutConsultationsInput;
};

export type PetWhereInput = {
  AND?: Maybe<Array<PetWhereInput>>;
  OR?: Maybe<Array<PetWhereInput>>;
  NOT?: Maybe<Array<PetWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  breed?: Maybe<StringNullableFilter>;
  bornOn?: Maybe<DateTimeNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  repoStatus?: Maybe<StringNullableFilter>;
  environment?: Maybe<StringFilter>;
  weight?: Maybe<FloatFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petType?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  gender?: Maybe<StringFilter>;
  bornOnDay?: Maybe<IntNullableFilter>;
  bornOnMonth?: Maybe<IntNullableFilter>;
  bornOnYear?: Maybe<IntNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  currentClinic?: Maybe<ClinicWhereInput>;
  petParent?: Maybe<OwnerInfoWhereInput>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  previousClinics?: Maybe<ClinicListRelationFilter>;
};

export type PetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export enum PhoneNumberStatus {
  New = 'New',
  Hosted = 'Hosted',
  Failed = 'Failed',
}

export enum PimsIntegrationCapability {
  Appointment = 'Appointment',
  AppointmentType = 'AppointmentType',
  Availability = 'Availability',
  AvailabilityResources = 'AvailabilityResources',
  ClinicEmployee = 'ClinicEmployee',
  ClinicPet = 'ClinicPet',
  ClinicPetParent = 'ClinicPetParent',
  ClinicPetParentAddress = 'ClinicPetParentAddress',
  ClinicPetToClinicPetParent = 'ClinicPetToClinicPetParent',
  ClinicPetAlert = 'ClinicPetAlert',
  ClinicRoom = 'ClinicRoom',
  Invoice = 'Invoice',
  InvoiceLineItem = 'InvoiceLineItem',
  Service = 'Service',
  ServiceReminder = 'ServiceReminder',
  Vaccination = 'Vaccination',
  Prescription = 'Prescription',
}

export type PimsInvoice = {
  __typename?: 'PimsInvoice';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invoiceId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  /** The status from PIMS for this invoice, since these are specific to a PIMS or a clinic config */
  status?: Maybe<Scalars['String']>;
  invoice: Invoice;
  integration: ClinicPimsIntegration;
};

export type PimsInvoiceCountAggregateOutputType = {
  __typename?: 'PimsInvoiceCountAggregateOutputType';
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  invoiceId: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  status: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PimsInvoiceCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type PimsInvoiceCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutPimsInvoiceInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput;
};

export type PimsInvoiceCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceCreateManyIntegrationInputEnvelope = {
  data: Array<PimsInvoiceCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsInvoiceCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
};

export type PimsInvoiceCreateNestedOneWithoutInvoiceInput = {
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
};

export type PimsInvoiceCreateOrConnectWithoutIntegrationInput = {
  where: PimsInvoiceWhereUniqueInput;
  create: PimsInvoiceUncheckedCreateWithoutIntegrationInput;
};

export type PimsInvoiceCreateOrConnectWithoutInvoiceInput = {
  where: PimsInvoiceWhereUniqueInput;
  create: PimsInvoiceUncheckedCreateWithoutInvoiceInput;
};

export type PimsInvoiceCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutPimsInvoiceInput;
};

export type PimsInvoiceCreateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput;
};

export type PimsInvoiceInvoiceIdIntegrationIdPimsIdCompoundUniqueInput = {
  invoiceId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItem = {
  __typename?: 'PimsInvoiceLineItem';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invoiceLineItemId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  invoiceLineItem: InvoiceLineItem;
  integration: ClinicPimsIntegration;
};

export type PimsInvoiceLineItemCountAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemCountAggregateOutputType';
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  invoiceLineItemId: Scalars['Int'];
  integrationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  type: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PimsInvoiceLineItemCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  invoiceLineItem: InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput;
};

export type PimsInvoiceLineItemCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemCreateManyIntegrationInputEnvelope = {
  data: Array<PimsInvoiceLineItemCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
};

export type PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput = {
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
};

export type PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  create: PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput;
};

export type PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  create: PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
};

export type PimsInvoiceLineItemCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  invoiceLineItem: InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput;
};

export type PimsInvoiceLineItemCreateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput;
};

export type PimsInvoiceLineItemInvoiceLineItemIdIntegrationIdPimsIdCompoundUniqueInput = {
  invoiceLineItemId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItemListRelationFilter = {
  every?: Maybe<PimsInvoiceLineItemWhereInput>;
  some?: Maybe<PimsInvoiceLineItemWhereInput>;
  none?: Maybe<PimsInvoiceLineItemWhereInput>;
};

export type PimsInvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemMinAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsInvoiceLineItemOrderByRelevanceFieldEnum {
  InvoiceLineItemId = 'invoiceLineItemId',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
}

export type PimsInvoiceLineItemOrderByRelevanceInput = {
  fields: Array<PimsInvoiceLineItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PimsInvoiceLineItemOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  _count?: Maybe<PimsInvoiceLineItemCountOrderByAggregateInput>;
  _max?: Maybe<PimsInvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<PimsInvoiceLineItemMinOrderByAggregateInput>;
};

export type PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  invoiceLineItem?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PimsInvoiceLineItemOrderByRelevanceInput>;
};

export type PimsInvoiceLineItemPimsInvoiceLineItemUniqueIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItemRelationFilter = {
  is?: Maybe<PimsInvoiceLineItemWhereInput>;
  isNot?: Maybe<PimsInvoiceLineItemWhereInput>;
};

export enum PimsInvoiceLineItemScalarFieldEnum {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InvoiceLineItemId = 'invoiceLineItemId',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Type = 'type',
}

export type PimsInvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceLineItemId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeFilter>;
};

export type PimsInvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  invoiceLineItemId?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
};

export enum PimsInvoiceLineItemType {
  BitwerxAdjustment = 'Bitwerx_Adjustment',
  BitwerxDiscount = 'Bitwerx_Discount',
  BitwerxCode = 'Bitwerx_Code',
  BitwerxReturn = 'Bitwerx_Return',
  BitwerxPayment = 'Bitwerx_Payment',
  BitwerxNote = 'Bitwerx_Note',
  BitwerxFee = 'Bitwerx_Fee',
  BitwerxEstimate = 'Bitwerx_Estimate',
  BitwerxWriteOff = 'Bitwerx_WriteOff',
  BitwerxTax = 'Bitwerx_Tax',
}

export type PimsInvoiceLineItemUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
};

export type PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput = {
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
};

export type PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
};

export type PimsInvoiceLineItemUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyWithoutPimsInvoiceLineItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemInput = {
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  upsert?: Maybe<PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  update?: Maybe<PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsInput>;
};

export type PimsInvoiceLineItemUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
};

export type PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput = {
  where: PimsInvoiceLineItemScalarWhereInput;
  data: PimsInvoiceLineItemUncheckedUpdateManyWithoutPimsInvoiceLineItemsInput;
};

export type PimsInvoiceLineItemUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  delete?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
};

export type PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemInput = {
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  upsert?: Maybe<PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  update?: Maybe<PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  data: PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput;
};

export type PimsInvoiceLineItemUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemUpdateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsInput>;
};

export type PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PimsInvoiceLineItemWhereUniqueInput;
  update: PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput;
  create: PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput;
};

export type PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput = {
  update: PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
  create: PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
};

export type PimsInvoiceLineItemWhereInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceLineItemId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeFilter>;
  invoiceLineItem?: Maybe<InvoiceLineItemWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type PimsInvoiceLineItemWhereUniqueInput = {
  invoiceLineItemId?: Maybe<Scalars['String']>;
  invoiceLineItemId_integrationId_pimsId?: Maybe<
    PimsInvoiceLineItemInvoiceLineItemIdIntegrationIdPimsIdCompoundUniqueInput
  >;
  pimsInvoiceLineItemUniqueIntegrationIdPimsId?: Maybe<
    PimsInvoiceLineItemPimsInvoiceLineItemUniqueIntegrationIdPimsIdCompoundUniqueInput
  >;
};

export type PimsInvoiceListRelationFilter = {
  every?: Maybe<PimsInvoiceWhereInput>;
  some?: Maybe<PimsInvoiceWhereInput>;
  none?: Maybe<PimsInvoiceWhereInput>;
};

export type PimsInvoiceMaxAggregateOutputType = {
  __typename?: 'PimsInvoiceMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type PimsInvoiceMinAggregateOutputType = {
  __typename?: 'PimsInvoiceMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type PimsInvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsInvoiceOrderByRelevanceFieldEnum {
  InvoiceId = 'invoiceId',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Status = 'status',
}

export type PimsInvoiceOrderByRelevanceInput = {
  fields: Array<PimsInvoiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PimsInvoiceOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  _count?: Maybe<PimsInvoiceCountOrderByAggregateInput>;
  _max?: Maybe<PimsInvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<PimsInvoiceMinOrderByAggregateInput>;
};

export type PimsInvoiceOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PimsInvoiceOrderByRelevanceInput>;
};

export type PimsInvoicePimsInvoiceUniqueIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceRelationFilter = {
  is?: Maybe<PimsInvoiceWhereInput>;
  isNot?: Maybe<PimsInvoiceWhereInput>;
};

export enum PimsInvoiceScalarFieldEnum {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InvoiceId = 'invoiceId',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Status = 'status',
}

export type PimsInvoiceScalarWhereInput = {
  AND?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  status?: Maybe<StringNullableFilter>;
};

export type PimsInvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PimsInvoiceUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
};

export type PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput = {
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
};

export type PimsInvoiceUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedCreateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
};

export type PimsInvoiceUncheckedUpdateManyWithoutPimsInvoicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateOneWithoutInvoiceInput = {
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  upsert?: Maybe<PimsInvoiceUpsertWithoutInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
  update?: Maybe<PimsInvoiceUncheckedUpdateWithoutInvoiceInput>;
};

export type PimsInvoiceUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUncheckedUpdateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutPimsInvoiceInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesInput>;
};

export type PimsInvoiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
};

export type PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput = {
  where: PimsInvoiceScalarWhereInput;
  data: PimsInvoiceUncheckedUpdateManyWithoutPimsInvoicesInput;
};

export type PimsInvoiceUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
};

export type PimsInvoiceUpdateOneWithoutInvoiceInput = {
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  upsert?: Maybe<PimsInvoiceUpsertWithoutInvoiceInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
  update?: Maybe<PimsInvoiceUncheckedUpdateWithoutInvoiceInput>;
};

export type PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PimsInvoiceWhereUniqueInput;
  data: PimsInvoiceUncheckedUpdateWithoutIntegrationInput;
};

export type PimsInvoiceUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutPimsInvoiceInput>;
};

export type PimsInvoiceUpdateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesInput>;
};

export type PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PimsInvoiceWhereUniqueInput;
  update: PimsInvoiceUncheckedUpdateWithoutIntegrationInput;
  create: PimsInvoiceUncheckedCreateWithoutIntegrationInput;
};

export type PimsInvoiceUpsertWithoutInvoiceInput = {
  update: PimsInvoiceUncheckedUpdateWithoutInvoiceInput;
  create: PimsInvoiceUncheckedCreateWithoutInvoiceInput;
};

export type PimsInvoiceWhereInput = {
  AND?: Maybe<Array<PimsInvoiceWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  invoiceId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  status?: Maybe<StringNullableFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type PimsInvoiceWhereUniqueInput = {
  invoiceId?: Maybe<Scalars['String']>;
  invoiceId_integrationId_pimsId?: Maybe<PimsInvoiceInvoiceIdIntegrationIdPimsIdCompoundUniqueInput>;
  pimsInvoiceUniqueIntegrationIdPimsId?: Maybe<PimsInvoicePimsInvoiceUniqueIntegrationIdPimsIdCompoundUniqueInput>;
};

export enum PimsSyncFrequency {
  Sync_30Sec = 'Sync_30_Sec',
  Sync_5Min = 'Sync_5_Min',
  Sync_24Hour = 'Sync_24_Hour',
}

export enum PimsSyncStatus {
  Created = 'Created',
  Started = 'Started',
  Completed = 'Completed',
  Error = 'Error',
}

export type PimsWritebackLogEntry = {
  __typename?: 'PimsWritebackLogEntry';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  /** Only set this when there were errors */
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  integration: ClinicPimsIntegration;
  triggeredByUserId?: Maybe<Scalars['String']>;
  triggeredBy?: Maybe<User>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntry>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntry>;
};

export type PimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  pimsError: Scalars['Int'];
  pimsId: Scalars['Int'];
  requestBody: Scalars['Int'];
  requestId: Scalars['Int'];
  integrationId: Scalars['Int'];
  triggeredByUserId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PimsWritebackLogEntryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryCreateManyIntegrationInputEnvelope = {
  data: Array<PimsWritebackLogEntryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsWritebackLogEntryCreateManyTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
};

export type PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope = {
  data: Array<PimsWritebackLogEntryCreateManyTriggeredByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
};

export type PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput;
};

export type PimsWritebackLogEntryCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateWithoutInvoiceLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateWithoutPaymentLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryCreateWithoutTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<PimsWritebackLogEntryWhereInput>;
  some?: Maybe<PimsWritebackLogEntryWhereInput>;
  none?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export type PimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsWritebackLogEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsError = 'pimsError',
  PimsId = 'pimsId',
  RequestId = 'requestId',
  IntegrationId = 'integrationId',
  TriggeredByUserId = 'triggeredByUserId',
}

export type PimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<PimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PimsWritebackLogEntryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
  _count?: Maybe<PimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<PimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<PimsWritebackLogEntryMinOrderByAggregateInput>;
};

export type PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  triggeredByUserId?: Maybe<SortOrder>;
  triggeredBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PimsWritebackLogEntryOrderByRelevanceInput>;
};

export type PimsWritebackLogEntryRelationFilter = {
  is?: Maybe<PimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export enum PimsWritebackLogEntryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Type = 'type',
  PimsError = 'pimsError',
  PimsId = 'pimsId',
  RequestBody = 'requestBody',
  RequestId = 'requestId',
  IntegrationId = 'integrationId',
  TriggeredByUserId = 'triggeredByUserId',
}

export type PimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumPimsWritebackStatusFilter>;
  type?: Maybe<EnumPimsWritebackTypeFilter>;
  pimsError?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  requestBody?: Maybe<JsonNullableFilter>;
  requestId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  triggeredByUserId?: Maybe<StringNullableFilter>;
};

export type PimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumPimsWritebackStatusWithAggregatesFilter>;
  type?: Maybe<EnumPimsWritebackTypeWithAggregatesFilter>;
  pimsError?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  requestBody?: Maybe<JsonNullableWithAggregatesFilter>;
  requestId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  triggeredByUserId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PimsWritebackLogEntryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
};

export type PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  triggeredByUserId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesInput>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
};

export type PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput = {
  where: PimsWritebackLogEntryScalarWhereInput;
  data: PimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput;
};

export type PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput = {
  where: PimsWritebackLogEntryScalarWhereInput;
  data: PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredWritebacksInput;
};

export type PimsWritebackLogEntryUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
};

export type PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput = {
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutInvoiceLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogInput = {
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutPaymentLogInput>;
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput>;
};

export type PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  data: PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput;
};

export type PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  data: PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput;
};

export type PimsWritebackLogEntryUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUpdateWithoutInvoiceLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesInput>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUpdateWithoutPaymentLogInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesInput>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUpdateWithoutTriggeredByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogInput>;
};

export type PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput;
};

export type PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput = {
  where: PimsWritebackLogEntryWhereUniqueInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput;
};

export type PimsWritebackLogEntryUpsertWithoutInvoiceLogInput = {
  update: PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput;
};

export type PimsWritebackLogEntryUpsertWithoutPaymentLogInput = {
  update: PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput;
  create: PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput;
};

export type PimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumPimsWritebackStatusFilter>;
  type?: Maybe<EnumPimsWritebackTypeFilter>;
  pimsError?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  requestBody?: Maybe<JsonNullableFilter>;
  requestId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  triggeredByUserId?: Maybe<StringNullableFilter>;
  triggeredBy?: Maybe<UserWhereInput>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export type PimsWritebackLogEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PimsWritebackStatus {
  Success = 'Success',
  Failure = 'Failure',
  Pending = 'Pending',
}

export enum PimsWritebackType {
  Payment = 'Payment',
}

export type Prescription = {
  __typename?: 'Prescription';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployee>;
  Clinic: Clinic;
  clinicPet: ClinicPet;
  integration: ClinicPimsIntegration;
  service?: Maybe<Service>;
};

export type PrescriptionAvgAggregateOutputType = {
  __typename?: 'PrescriptionAvgAggregateOutputType';
  quantity?: Maybe<Scalars['Float']>;
};

export type PrescriptionAvgOrderByAggregateInput = {
  quantity?: Maybe<SortOrder>;
};

export type PrescriptionCountAggregateOutputType = {
  __typename?: 'PrescriptionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  pimsId: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  serviceId: Scalars['Int'];
  quantity: Scalars['Int'];
  prescribingDoctorId: Scalars['Int'];
  description: Scalars['Int'];
  instructions: Scalars['Int'];
  comments: Scalars['Int'];
  issuedAt: Scalars['Int'];
  expiredAt: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  lastRequestedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PrescriptionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
};

export type PrescriptionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  Clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyClinicInputEnvelope = {
  data: Array<PrescriptionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyClinicPetInputEnvelope = {
  data: Array<PrescriptionCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyIntegrationInputEnvelope = {
  data: Array<PrescriptionCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyPrescribingDoctorInputEnvelope = {
  data: Array<PrescriptionCreateManyPrescribingDoctorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyServiceInputEnvelope = {
  data: Array<PrescriptionCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateNestedManyWithoutPrescribingDoctorInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionCreateOrConnectWithoutClinicInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutClinicInput;
};

export type PrescriptionCreateOrConnectWithoutClinicPetInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutClinicPetInput;
};

export type PrescriptionCreateOrConnectWithoutIntegrationInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutIntegrationInput;
};

export type PrescriptionCreateOrConnectWithoutPrescribingDoctorInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutPrescribingDoctorInput;
};

export type PrescriptionCreateOrConnectWithoutServiceInput = {
  where: PrescriptionWhereUniqueInput;
  create: PrescriptionUncheckedCreateWithoutServiceInput;
};

export type PrescriptionCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  Clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  Clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateWithoutPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  Clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
};

export type PrescriptionCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  Clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
};

export type PrescriptionListRelationFilter = {
  every?: Maybe<PrescriptionWhereInput>;
  some?: Maybe<PrescriptionWhereInput>;
  none?: Maybe<PrescriptionWhereInput>;
};

export type PrescriptionMaxAggregateOutputType = {
  __typename?: 'PrescriptionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
};

export type PrescriptionMinAggregateOutputType = {
  __typename?: 'PrescriptionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
};

export type PrescriptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PrescriptionOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  ServiceId = 'serviceId',
  PrescribingDoctorId = 'prescribingDoctorId',
  Description = 'description',
  Instructions = 'instructions',
  Comments = 'comments',
}

export type PrescriptionOrderByRelevanceInput = {
  fields: Array<PrescriptionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PrescriptionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  _count?: Maybe<PrescriptionCountOrderByAggregateInput>;
  _avg?: Maybe<PrescriptionAvgOrderByAggregateInput>;
  _max?: Maybe<PrescriptionMaxOrderByAggregateInput>;
  _min?: Maybe<PrescriptionMinOrderByAggregateInput>;
  _sum?: Maybe<PrescriptionSumOrderByAggregateInput>;
};

export type PrescriptionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  prescribingDoctor?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  Clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PrescriptionOrderByRelevanceInput>;
};

export type PrescriptionPimsIdAndIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export enum PrescriptionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  ServiceId = 'serviceId',
  Quantity = 'quantity',
  PrescribingDoctorId = 'prescribingDoctorId',
  Description = 'description',
  Instructions = 'instructions',
  Comments = 'comments',
  IssuedAt = 'issuedAt',
  ExpiredAt = 'expiredAt',
  RawPimsValue = 'rawPimsValue',
  LastRequestedAt = 'lastRequestedAt',
}

export type PrescriptionScalarWhereInput = {
  AND?: Maybe<Array<PrescriptionScalarWhereInput>>;
  OR?: Maybe<Array<PrescriptionScalarWhereInput>>;
  NOT?: Maybe<Array<PrescriptionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatNullableFilter>;
  prescribingDoctorId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  instructions?: Maybe<StringNullableFilter>;
  comments?: Maybe<StringNullableFilter>;
  issuedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableFilter>;
};

export type PrescriptionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  serviceId?: Maybe<StringNullableWithAggregatesFilter>;
  quantity?: Maybe<FloatNullableWithAggregatesFilter>;
  prescribingDoctorId?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  instructions?: Maybe<StringNullableWithAggregatesFilter>;
  comments?: Maybe<StringNullableWithAggregatesFilter>;
  issuedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type PrescriptionSumAggregateOutputType = {
  __typename?: 'PrescriptionSumAggregateOutputType';
  quantity?: Maybe<Scalars['Float']>;
};

export type PrescriptionSumOrderByAggregateInput = {
  quantity?: Maybe<SortOrder>;
};

export type PrescriptionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
};

export type PrescriptionUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateWithoutPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  serviceId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsInput>;
  Clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsInput>;
};

export type PrescriptionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateManyWithWhereWithoutClinicInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithWhereWithoutClinicPetInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithWhereWithoutIntegrationInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithWhereWithoutServiceInput = {
  where: PrescriptionScalarWhereInput;
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
};

export type PrescriptionUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateManyWithoutPrescribingDoctorInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
};

export type PrescriptionUpdateWithWhereUniqueWithoutClinicInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutClinicInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutClinicPetInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutIntegrationInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutServiceInput = {
  where: PrescriptionWhereUniqueInput;
  data: PrescriptionUncheckedUpdateWithoutServiceInput;
};

export type PrescriptionUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsInput>;
};

export type PrescriptionUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsInput>;
  Clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsInput>;
};

export type PrescriptionUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsInput>;
  Clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsInput>;
};

export type PrescriptionUpdateWithoutPrescribingDoctorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  Clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsInput>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsInput>;
};

export type PrescriptionUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsInput>;
  Clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsInput>;
};

export type PrescriptionUpsertWithWhereUniqueWithoutClinicInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutClinicInput;
  create: PrescriptionUncheckedCreateWithoutClinicInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutClinicPetInput;
  create: PrescriptionUncheckedCreateWithoutClinicPetInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutIntegrationInput;
  create: PrescriptionUncheckedCreateWithoutIntegrationInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput;
  create: PrescriptionUncheckedCreateWithoutPrescribingDoctorInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutServiceInput = {
  where: PrescriptionWhereUniqueInput;
  update: PrescriptionUncheckedUpdateWithoutServiceInput;
  create: PrescriptionUncheckedCreateWithoutServiceInput;
};

export type PrescriptionWhereInput = {
  AND?: Maybe<Array<PrescriptionWhereInput>>;
  OR?: Maybe<Array<PrescriptionWhereInput>>;
  NOT?: Maybe<Array<PrescriptionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatNullableFilter>;
  prescribingDoctorId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  instructions?: Maybe<StringNullableFilter>;
  comments?: Maybe<StringNullableFilter>;
  issuedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableFilter>;
  prescribingDoctor?: Maybe<ClinicEmployeeWhereInput>;
  Clinic?: Maybe<ClinicWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  service?: Maybe<ServiceWhereInput>;
};

export type PrescriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsIdAndIntegrationId?: Maybe<PrescriptionPimsIdAndIntegrationIdCompoundUniqueInput>;
};

/** @deprecated - 1.0 */
export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
  clinic?: Maybe<Clinic>;
};

export type PromoCodeAvgAggregateOutputType = {
  __typename?: 'PromoCodeAvgAggregateOutputType';
  discount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  amountAvailable?: Maybe<Scalars['Float']>;
};

export type PromoCodeAvgOrderByAggregateInput = {
  discount?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeCountAggregateOutputType = {
  __typename?: 'PromoCodeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  code: Scalars['Int'];
  created: Scalars['Int'];
  discount: Scalars['Int'];
  clinicId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  expiresAt: Scalars['Int'];
  amountUsed: Scalars['Int'];
  amountAvailable: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PromoCodeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutPromoCodesInput>;
};

export type PromoCodeCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeCreateManyClinicInputEnvelope = {
  data: Array<PromoCodeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoCodeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
};

export type PromoCodeCreateOrConnectWithoutClinicInput = {
  where: PromoCodeWhereUniqueInput;
  create: PromoCodeUncheckedCreateWithoutClinicInput;
};

export type PromoCodeCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeListRelationFilter = {
  every?: Maybe<PromoCodeWhereInput>;
  some?: Maybe<PromoCodeWhereInput>;
  none?: Maybe<PromoCodeWhereInput>;
};

export type PromoCodeMaxAggregateOutputType = {
  __typename?: 'PromoCodeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeMinAggregateOutputType = {
  __typename?: 'PromoCodeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PromoCodeOrderByRelevanceFieldEnum {
  Id = 'id',
  Code = 'code',
  ClinicId = 'clinicId',
  FirebaseId = 'firebaseId',
}

export type PromoCodeOrderByRelevanceInput = {
  fields: Array<PromoCodeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type PromoCodeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
  _count?: Maybe<PromoCodeCountOrderByAggregateInput>;
  _avg?: Maybe<PromoCodeAvgOrderByAggregateInput>;
  _max?: Maybe<PromoCodeMaxOrderByAggregateInput>;
  _min?: Maybe<PromoCodeMinOrderByAggregateInput>;
  _sum?: Maybe<PromoCodeSumOrderByAggregateInput>;
};

export type PromoCodeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<PromoCodeOrderByRelevanceInput>;
};

export enum PromoCodeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Code = 'code',
  Created = 'created',
  Discount = 'discount',
  ClinicId = 'clinicId',
  FirebaseId = 'firebaseId',
  ExpiresAt = 'expiresAt',
  AmountUsed = 'amountUsed',
  AmountAvailable = 'amountAvailable',
}

export type PromoCodeScalarWhereInput = {
  AND?: Maybe<Array<PromoCodeScalarWhereInput>>;
  OR?: Maybe<Array<PromoCodeScalarWhereInput>>;
  NOT?: Maybe<Array<PromoCodeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  code?: Maybe<StringFilter>;
  created?: Maybe<DateTimeFilter>;
  discount?: Maybe<FloatFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  amountUsed?: Maybe<IntNullableFilter>;
  amountAvailable?: Maybe<IntNullableFilter>;
};

export type PromoCodeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  code?: Maybe<StringWithAggregatesFilter>;
  created?: Maybe<DateTimeWithAggregatesFilter>;
  discount?: Maybe<FloatWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  expiresAt?: Maybe<DateTimeWithAggregatesFilter>;
  amountUsed?: Maybe<IntNullableWithAggregatesFilter>;
  amountAvailable?: Maybe<IntNullableWithAggregatesFilter>;
};

export type PromoCodeSumAggregateOutputType = {
  __typename?: 'PromoCodeSumAggregateOutputType';
  discount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeSumOrderByAggregateInput = {
  discount?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  amountAvailable?: Maybe<SortOrder>;
};

export type PromoCodeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
};

export type PromoCodeUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  discount: Scalars['Float'];
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PromoCodeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  delete?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  update?: Maybe<Array<PromoCodeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PromoCodeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PromoCodeScalarWhereInput>>;
};

export type PromoCodeUncheckedUpdateManyWithoutPromoCodesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutPromoCodesInput>;
};

export type PromoCodeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUpdateManyWithWhereWithoutClinicInput = {
  where: PromoCodeScalarWhereInput;
  data: PromoCodeUncheckedUpdateManyWithoutPromoCodesInput;
};

export type PromoCodeUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<PromoCodeUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  delete?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  update?: Maybe<Array<PromoCodeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PromoCodeUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<PromoCodeScalarWhereInput>>;
};

export type PromoCodeUpdateWithWhereUniqueWithoutClinicInput = {
  where: PromoCodeWhereUniqueInput;
  data: PromoCodeUncheckedUpdateWithoutClinicInput;
};

export type PromoCodeUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  firebaseId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  amountUsed?: Maybe<Scalars['Int']>;
  amountAvailable?: Maybe<Scalars['Int']>;
};

export type PromoCodeUpsertWithWhereUniqueWithoutClinicInput = {
  where: PromoCodeWhereUniqueInput;
  update: PromoCodeUncheckedUpdateWithoutClinicInput;
  create: PromoCodeUncheckedCreateWithoutClinicInput;
};

export type PromoCodeWhereInput = {
  AND?: Maybe<Array<PromoCodeWhereInput>>;
  OR?: Maybe<Array<PromoCodeWhereInput>>;
  NOT?: Maybe<Array<PromoCodeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  code?: Maybe<StringFilter>;
  created?: Maybe<DateTimeFilter>;
  discount?: Maybe<FloatFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  amountUsed?: Maybe<IntNullableFilter>;
  amountAvailable?: Maybe<IntNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type PromoCodeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  findUniqueAppointment?: Maybe<Appointment>;
  findFirstAppointment?: Maybe<Appointment>;
  findManyAppointment?: Maybe<Array<Appointment>>;
  findManyAppointmentCount: Scalars['Int'];
  aggregateAppointment?: Maybe<AggregateAppointment>;
  findUniqueAppointmentType?: Maybe<AppointmentType>;
  findFirstAppointmentType?: Maybe<AppointmentType>;
  findManyAppointmentType?: Maybe<Array<AppointmentType>>;
  findManyAppointmentTypeCount: Scalars['Int'];
  aggregateAppointmentType?: Maybe<AggregateAppointmentType>;
  findUniqueBitwerxIntegration?: Maybe<BitwerxIntegration>;
  findFirstBitwerxIntegration?: Maybe<BitwerxIntegration>;
  findManyBitwerxIntegration?: Maybe<Array<BitwerxIntegration>>;
  findManyBitwerxIntegrationCount: Scalars['Int'];
  aggregateBitwerxIntegration?: Maybe<AggregateBitwerxIntegration>;
  findUniqueChannel?: Maybe<Channel>;
  findFirstChannel?: Maybe<Channel>;
  findManyChannel?: Maybe<Array<Channel>>;
  findManyChannelCount: Scalars['Int'];
  aggregateChannel?: Maybe<AggregateChannel>;
  findUniqueChannelMember?: Maybe<ChannelMember>;
  findFirstChannelMember?: Maybe<ChannelMember>;
  findManyChannelMember?: Maybe<Array<ChannelMember>>;
  findManyChannelMemberCount: Scalars['Int'];
  aggregateChannelMember?: Maybe<AggregateChannelMember>;
  findUniqueChannelMessage?: Maybe<ChannelMessage>;
  findFirstChannelMessage?: Maybe<ChannelMessage>;
  findManyChannelMessage?: Maybe<Array<ChannelMessage>>;
  findManyChannelMessageCount: Scalars['Int'];
  aggregateChannelMessage?: Maybe<AggregateChannelMessage>;
  findUniqueChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  findFirstChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  findManyChannelMessageAttachment?: Maybe<Array<ChannelMessageAttachment>>;
  findManyChannelMessageAttachmentCount: Scalars['Int'];
  aggregateChannelMessageAttachment?: Maybe<AggregateChannelMessageAttachment>;
  findUniqueChannelStatus?: Maybe<ChannelStatus>;
  findFirstChannelStatus?: Maybe<ChannelStatus>;
  findManyChannelStatus?: Maybe<Array<ChannelStatus>>;
  findManyChannelStatusCount: Scalars['Int'];
  aggregateChannelStatus?: Maybe<AggregateChannelStatus>;
  findUniqueChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  findFirstChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  findManyChannelStatusChannelAssignee?: Maybe<Array<ChannelStatusChannelAssignee>>;
  findManyChannelStatusChannelAssigneeCount: Scalars['Int'];
  aggregateChannelStatusChannelAssignee?: Maybe<AggregateChannelStatusChannelAssignee>;
  findUniqueClinic?: Maybe<Clinic>;
  findFirstClinic?: Maybe<Clinic>;
  findManyClinic?: Maybe<Array<Clinic>>;
  findManyClinicCount: Scalars['Int'];
  aggregateClinic?: Maybe<AggregateClinic>;
  findUniqueClinicEmployee?: Maybe<ClinicEmployee>;
  findFirstClinicEmployee?: Maybe<ClinicEmployee>;
  findManyClinicEmployee?: Maybe<Array<ClinicEmployee>>;
  findManyClinicEmployeeCount: Scalars['Int'];
  aggregateClinicEmployee?: Maybe<AggregateClinicEmployee>;
  findUniqueClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  findFirstClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  findManyClinicPaymentFeeConfiguration?: Maybe<Array<ClinicPaymentFeeConfiguration>>;
  findManyClinicPaymentFeeConfigurationCount: Scalars['Int'];
  aggregateClinicPaymentFeeConfiguration?: Maybe<AggregateClinicPaymentFeeConfiguration>;
  findUniqueClinicPet?: Maybe<ClinicPet>;
  findFirstClinicPet?: Maybe<ClinicPet>;
  findManyClinicPet?: Maybe<Array<ClinicPet>>;
  findManyClinicPetCount: Scalars['Int'];
  aggregateClinicPet?: Maybe<AggregateClinicPet>;
  findUniqueClinicPetAlert?: Maybe<ClinicPetAlert>;
  findFirstClinicPetAlert?: Maybe<ClinicPetAlert>;
  findManyClinicPetAlert?: Maybe<Array<ClinicPetAlert>>;
  findManyClinicPetAlertCount: Scalars['Int'];
  aggregateClinicPetAlert?: Maybe<AggregateClinicPetAlert>;
  findUniqueClinicPetParent?: Maybe<ClinicPetParent>;
  findFirstClinicPetParent?: Maybe<ClinicPetParent>;
  findManyClinicPetParent?: Maybe<Array<ClinicPetParent>>;
  findManyClinicPetParentCount: Scalars['Int'];
  aggregateClinicPetParent?: Maybe<AggregateClinicPetParent>;
  findUniqueClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  findFirstClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  findManyClinicPimsIntegration?: Maybe<Array<ClinicPimsIntegration>>;
  findManyClinicPimsIntegrationCount: Scalars['Int'];
  aggregateClinicPimsIntegration?: Maybe<AggregateClinicPimsIntegration>;
  findUniqueClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  findFirstClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  findManyClinicPimsIntegrationCapability?: Maybe<Array<ClinicPimsIntegrationCapability>>;
  findManyClinicPimsIntegrationCapabilityCount: Scalars['Int'];
  aggregateClinicPimsIntegrationCapability?: Maybe<AggregateClinicPimsIntegrationCapability>;
  findUniqueClinicRoom?: Maybe<ClinicRoom>;
  findFirstClinicRoom?: Maybe<ClinicRoom>;
  findManyClinicRoom?: Maybe<Array<ClinicRoom>>;
  findManyClinicRoomCount: Scalars['Int'];
  aggregateClinicRoom?: Maybe<AggregateClinicRoom>;
  findUniqueClinicSetting?: Maybe<ClinicSetting>;
  findFirstClinicSetting?: Maybe<ClinicSetting>;
  findManyClinicSetting?: Maybe<Array<ClinicSetting>>;
  findManyClinicSettingCount: Scalars['Int'];
  aggregateClinicSetting?: Maybe<AggregateClinicSetting>;
  findUniqueClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  findFirstClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  findManyClinicWidgetRequest?: Maybe<Array<ClinicWidgetRequest>>;
  findManyClinicWidgetRequestCount: Scalars['Int'];
  aggregateClinicWidgetRequest?: Maybe<AggregateClinicWidgetRequest>;
  findUniqueClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  findFirstClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  findManyClinicWidgetRequestType?: Maybe<Array<ClinicWidgetRequestType>>;
  findManyClinicWidgetRequestTypeCount: Scalars['Int'];
  aggregateClinicWidgetRequestType?: Maybe<AggregateClinicWidgetRequestType>;
  findUniqueClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  findFirstClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  findManyClinicWidgetSetting?: Maybe<Array<ClinicWidgetSetting>>;
  findManyClinicWidgetSettingCount: Scalars['Int'];
  aggregateClinicWidgetSetting?: Maybe<AggregateClinicWidgetSetting>;
  findUniqueConsultation?: Maybe<Consultation>;
  findFirstConsultation?: Maybe<Consultation>;
  findManyConsultation?: Maybe<Array<Consultation>>;
  findManyConsultationCount: Scalars['Int'];
  aggregateConsultation?: Maybe<AggregateConsultation>;
  findUniqueConsultationRequest?: Maybe<ConsultationRequest>;
  findFirstConsultationRequest?: Maybe<ConsultationRequest>;
  findManyConsultationRequest?: Maybe<Array<ConsultationRequest>>;
  findManyConsultationRequestCount: Scalars['Int'];
  aggregateConsultationRequest?: Maybe<AggregateConsultationRequest>;
  findUniqueDisbursement?: Maybe<Disbursement>;
  findFirstDisbursement?: Maybe<Disbursement>;
  findManyDisbursement?: Maybe<Array<Disbursement>>;
  findManyDisbursementCount: Scalars['Int'];
  aggregateDisbursement?: Maybe<AggregateDisbursement>;
  findUniqueEzyVetIntegration?: Maybe<EzyVetIntegration>;
  findFirstEzyVetIntegration?: Maybe<EzyVetIntegration>;
  findManyEzyVetIntegration?: Maybe<Array<EzyVetIntegration>>;
  findManyEzyVetIntegrationCount: Scalars['Int'];
  aggregateEzyVetIntegration?: Maybe<AggregateEzyVetIntegration>;
  findUniqueFinancialTransaction?: Maybe<FinancialTransaction>;
  findFirstFinancialTransaction?: Maybe<FinancialTransaction>;
  findManyFinancialTransaction?: Maybe<Array<FinancialTransaction>>;
  findManyFinancialTransactionCount: Scalars['Int'];
  aggregateFinancialTransaction?: Maybe<AggregateFinancialTransaction>;
  findUniqueFormSubmission?: Maybe<FormSubmission>;
  findFirstFormSubmission?: Maybe<FormSubmission>;
  findManyFormSubmission?: Maybe<Array<FormSubmission>>;
  findManyFormSubmissionCount: Scalars['Int'];
  aggregateFormSubmission?: Maybe<AggregateFormSubmission>;
  findUniqueFormTemplate?: Maybe<FormTemplate>;
  findFirstFormTemplate?: Maybe<FormTemplate>;
  findManyFormTemplate?: Maybe<Array<FormTemplate>>;
  findManyFormTemplateCount: Scalars['Int'];
  aggregateFormTemplate?: Maybe<AggregateFormTemplate>;
  findUniqueInvoice?: Maybe<Invoice>;
  findFirstInvoice?: Maybe<Invoice>;
  findManyInvoice?: Maybe<Array<Invoice>>;
  findManyInvoiceCount: Scalars['Int'];
  aggregateInvoice?: Maybe<AggregateInvoice>;
  findUniqueInvoiceLineItem?: Maybe<InvoiceLineItem>;
  findFirstInvoiceLineItem?: Maybe<InvoiceLineItem>;
  findManyInvoiceLineItem?: Maybe<Array<InvoiceLineItem>>;
  findManyInvoiceLineItemCount: Scalars['Int'];
  aggregateInvoiceLineItem?: Maybe<AggregateInvoiceLineItem>;
  findUniqueOrganization?: Maybe<Organization>;
  findFirstOrganization?: Maybe<Organization>;
  findManyOrganization?: Maybe<Array<Organization>>;
  findManyOrganizationCount: Scalars['Int'];
  aggregateOrganization?: Maybe<AggregateOrganization>;
  findUniquePermission?: Maybe<Permission>;
  findFirstPermission?: Maybe<Permission>;
  findManyPermission?: Maybe<Array<Permission>>;
  findManyPermissionCount: Scalars['Int'];
  aggregatePermission?: Maybe<AggregatePermission>;
  findUniquePetParentSetting?: Maybe<PetParentSetting>;
  findFirstPetParentSetting?: Maybe<PetParentSetting>;
  findManyPetParentSetting?: Maybe<Array<PetParentSetting>>;
  findManyPetParentSettingCount: Scalars['Int'];
  aggregatePetParentSetting?: Maybe<AggregatePetParentSetting>;
  findUniquePimsInvoice?: Maybe<PimsInvoice>;
  findFirstPimsInvoice?: Maybe<PimsInvoice>;
  findManyPimsInvoice?: Maybe<Array<PimsInvoice>>;
  findManyPimsInvoiceCount: Scalars['Int'];
  aggregatePimsInvoice?: Maybe<AggregatePimsInvoice>;
  findUniquePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  findFirstPimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  findManyPimsInvoiceLineItem?: Maybe<Array<PimsInvoiceLineItem>>;
  findManyPimsInvoiceLineItemCount: Scalars['Int'];
  aggregatePimsInvoiceLineItem?: Maybe<AggregatePimsInvoiceLineItem>;
  findUniqueService?: Maybe<Service>;
  findFirstService?: Maybe<Service>;
  findManyService?: Maybe<Array<Service>>;
  findManyServiceCount: Scalars['Int'];
  aggregateService?: Maybe<AggregateService>;
  findUniqueServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  findFirstServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  findManyServiceReminderTiming?: Maybe<Array<ServiceReminderTiming>>;
  findManyServiceReminderTimingCount: Scalars['Int'];
  aggregateServiceReminderTiming?: Maybe<AggregateServiceReminderTiming>;
  findUniqueServiceReminder?: Maybe<ServiceReminder>;
  findFirstServiceReminder?: Maybe<ServiceReminder>;
  findManyServiceReminder?: Maybe<Array<ServiceReminder>>;
  findManyServiceReminderCount: Scalars['Int'];
  aggregateServiceReminder?: Maybe<AggregateServiceReminder>;
  findUniqueServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  findFirstServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  findManyServiceReminderNotification?: Maybe<Array<ServiceReminderNotification>>;
  findManyServiceReminderNotificationCount: Scalars['Int'];
  aggregateServiceReminderNotification?: Maybe<AggregateServiceReminderNotification>;
  findUniqueStaffRole?: Maybe<StaffRole>;
  findFirstStaffRole?: Maybe<StaffRole>;
  findManyStaffRole?: Maybe<Array<StaffRole>>;
  findManyStaffRoleCount: Scalars['Int'];
  aggregateStaffRole?: Maybe<AggregateStaffRole>;
  findUniqueStripeDispute?: Maybe<StripeDispute>;
  findFirstStripeDispute?: Maybe<StripeDispute>;
  findManyStripeDispute?: Maybe<Array<StripeDispute>>;
  findManyStripeDisputeCount: Scalars['Int'];
  aggregateStripeDispute?: Maybe<AggregateStripeDispute>;
  findUniqueStripePaymentIntent?: Maybe<StripePaymentIntent>;
  findFirstStripePaymentIntent?: Maybe<StripePaymentIntent>;
  findManyStripePaymentIntent?: Maybe<Array<StripePaymentIntent>>;
  findManyStripePaymentIntentCount: Scalars['Int'];
  aggregateStripePaymentIntent?: Maybe<AggregateStripePaymentIntent>;
  findUniqueStripePaymentMethod?: Maybe<StripePaymentMethod>;
  findFirstStripePaymentMethod?: Maybe<StripePaymentMethod>;
  findManyStripePaymentMethod?: Maybe<Array<StripePaymentMethod>>;
  findManyStripePaymentMethodCount: Scalars['Int'];
  aggregateStripePaymentMethod?: Maybe<AggregateStripePaymentMethod>;
  findUniqueStripePayout?: Maybe<StripePayout>;
  findFirstStripePayout?: Maybe<StripePayout>;
  findManyStripePayout?: Maybe<Array<StripePayout>>;
  findManyStripePayoutCount: Scalars['Int'];
  aggregateStripePayout?: Maybe<AggregateStripePayout>;
  findUniqueStripePayoutAccount?: Maybe<StripePayoutAccount>;
  findFirstStripePayoutAccount?: Maybe<StripePayoutAccount>;
  findManyStripePayoutAccount?: Maybe<Array<StripePayoutAccount>>;
  findManyStripePayoutAccountCount: Scalars['Int'];
  aggregateStripePayoutAccount?: Maybe<AggregateStripePayoutAccount>;
  findUniqueStripeReceipt?: Maybe<StripeReceipt>;
  findFirstStripeReceipt?: Maybe<StripeReceipt>;
  findManyStripeReceipt?: Maybe<Array<StripeReceipt>>;
  findManyStripeReceiptCount: Scalars['Int'];
  aggregateStripeReceipt?: Maybe<AggregateStripeReceipt>;
  findUniqueStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  findFirstStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  findManyStripeReceiptHistory?: Maybe<Array<StripeReceiptHistory>>;
  findManyStripeReceiptHistoryCount: Scalars['Int'];
  aggregateStripeReceiptHistory?: Maybe<AggregateStripeReceiptHistory>;
  findUniqueStripeRefund?: Maybe<StripeRefund>;
  findFirstStripeRefund?: Maybe<StripeRefund>;
  findManyStripeRefund?: Maybe<Array<StripeRefund>>;
  findManyStripeRefundCount: Scalars['Int'];
  aggregateStripeRefund?: Maybe<AggregateStripeRefund>;
  findUniqueStripeTerminal?: Maybe<StripeTerminal>;
  findFirstStripeTerminal?: Maybe<StripeTerminal>;
  findManyStripeTerminal?: Maybe<Array<StripeTerminal>>;
  findManyStripeTerminalCount: Scalars['Int'];
  aggregateStripeTerminal?: Maybe<AggregateStripeTerminal>;
  findUniqueStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  findFirstStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  findManyStripeTerminalLocation?: Maybe<Array<StripeTerminalLocation>>;
  findManyStripeTerminalLocationCount: Scalars['Int'];
  aggregateStripeTerminalLocation?: Maybe<AggregateStripeTerminalLocation>;
  findUniqueTransaction?: Maybe<Transaction>;
  findFirstTransaction?: Maybe<Transaction>;
  findManyTransaction?: Maybe<Array<Transaction>>;
  findManyTransactionCount: Scalars['Int'];
  aggregateTransaction?: Maybe<AggregateTransaction>;
  findUniqueUser?: Maybe<User>;
  findFirstUser?: Maybe<User>;
  findManyUser?: Maybe<Array<User>>;
  findManyUserCount: Scalars['Int'];
  aggregateUser?: Maybe<AggregateUser>;
  findUniqueUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  findFirstUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  findManyUserAppointmentGrouping?: Maybe<Array<UserAppointmentGrouping>>;
  findManyUserAppointmentGroupingCount: Scalars['Int'];
  aggregateUserAppointmentGrouping?: Maybe<AggregateUserAppointmentGrouping>;
  findUniqueUserNotificationSetting?: Maybe<UserNotificationSetting>;
  findFirstUserNotificationSetting?: Maybe<UserNotificationSetting>;
  findManyUserNotificationSetting?: Maybe<Array<UserNotificationSetting>>;
  findManyUserNotificationSettingCount: Scalars['Int'];
  aggregateUserNotificationSetting?: Maybe<AggregateUserNotificationSetting>;
  findUniqueUserSetting?: Maybe<UserSetting>;
  findFirstUserSetting?: Maybe<UserSetting>;
  findManyUserSetting?: Maybe<Array<UserSetting>>;
  findManyUserSettingCount: Scalars['Int'];
  aggregateUserSetting?: Maybe<AggregateUserSetting>;
  findUniqueVetInfo?: Maybe<VetInfo>;
  findFirstVetInfo?: Maybe<VetInfo>;
  findManyVetInfo?: Maybe<Array<VetInfo>>;
  findManyVetInfoCount: Scalars['Int'];
  aggregateVetInfo?: Maybe<AggregateVetInfo>;
  findUniqueWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  findFirstWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  findManyWorkflowEventSetting?: Maybe<Array<WorkflowEventSetting>>;
  findManyWorkflowEventSettingCount: Scalars['Int'];
  aggregateWorkflowEventSetting?: Maybe<AggregateWorkflowEventSetting>;
  findUniqueClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  findFirstClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  findManyClinicEntityPhoneNumber?: Maybe<Array<ClinicEntityPhoneNumber>>;
  findManyClinicEntityPhoneNumberCount: Scalars['Int'];
  aggregateClinicEntityPhoneNumber?: Maybe<AggregateClinicEntityPhoneNumber>;
  findUniqueClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  findFirstClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  findManyClinicOfficeHour?: Maybe<Array<ClinicOfficeHour>>;
  findManyClinicOfficeHourCount: Scalars['Int'];
  aggregateClinicOfficeHour?: Maybe<AggregateClinicOfficeHour>;
  findUniqueEmailProviderLog?: Maybe<EmailProviderLog>;
  findFirstEmailProviderLog?: Maybe<EmailProviderLog>;
  findManyEmailProviderLog?: Maybe<Array<EmailProviderLog>>;
  findManyEmailProviderLogCount: Scalars['Int'];
  aggregateEmailProviderLog?: Maybe<AggregateEmailProviderLog>;
  findUniqueSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  findFirstSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  findManySmsNotificationStatus?: Maybe<Array<SmsNotificationStatus>>;
  findManySmsNotificationStatusCount: Scalars['Int'];
  aggregateSmsNotificationStatus?: Maybe<AggregateSmsNotificationStatus>;
  findUniqueFeatureFlag?: Maybe<FeatureFlag>;
  findFirstFeatureFlag?: Maybe<FeatureFlag>;
  findManyFeatureFlag?: Maybe<Array<FeatureFlag>>;
  findManyFeatureFlagCount: Scalars['Int'];
  aggregateFeatureFlag?: Maybe<AggregateFeatureFlag>;
  findUniqueTwilioCallRecording?: Maybe<TwilioCallRecording>;
  findFirstTwilioCallRecording?: Maybe<TwilioCallRecording>;
  findManyTwilioCallRecording?: Maybe<Array<TwilioCallRecording>>;
  findManyTwilioCallRecordingCount: Scalars['Int'];
  aggregateTwilioCallRecording?: Maybe<AggregateTwilioCallRecording>;
  findUniqueCallRecording?: Maybe<CallRecording>;
  findFirstCallRecording?: Maybe<CallRecording>;
  findManyCallRecording?: Maybe<Array<CallRecording>>;
  findManyCallRecordingCount: Scalars['Int'];
  aggregateCallRecording?: Maybe<AggregateCallRecording>;
  findUniqueCallHistory?: Maybe<CallHistory>;
  findFirstCallHistory?: Maybe<CallHistory>;
  findManyCallHistory?: Maybe<Array<CallHistory>>;
  findManyCallHistoryCount: Scalars['Int'];
  aggregateCallHistory?: Maybe<AggregateCallHistory>;
  findUniqueCallParticipant?: Maybe<CallParticipant>;
  findFirstCallParticipant?: Maybe<CallParticipant>;
  findManyCallParticipant?: Maybe<Array<CallParticipant>>;
  findManyCallParticipantCount: Scalars['Int'];
  aggregateCallParticipant?: Maybe<AggregateCallParticipant>;
  findUniqueChannelSelection?: Maybe<ChannelSelection>;
  findFirstChannelSelection?: Maybe<ChannelSelection>;
  findManyChannelSelection?: Maybe<Array<ChannelSelection>>;
  findManyChannelSelectionCount: Scalars['Int'];
  aggregateChannelSelection?: Maybe<AggregateChannelSelection>;
  findUniqueClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  findFirstClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  findManyClinicEmailCampaignSetting?: Maybe<Array<ClinicEmailCampaignSetting>>;
  findManyClinicEmailCampaignSettingCount: Scalars['Int'];
  aggregateClinicEmailCampaignSetting?: Maybe<AggregateClinicEmailCampaignSetting>;
  findUniqueOrganizationPet?: Maybe<OrganizationPet>;
  findFirstOrganizationPet?: Maybe<OrganizationPet>;
  findManyOrganizationPet?: Maybe<Array<OrganizationPet>>;
  findManyOrganizationPetCount: Scalars['Int'];
  aggregateOrganizationPet?: Maybe<AggregateOrganizationPet>;
  findUniqueOrganizationPetParent?: Maybe<OrganizationPetParent>;
  findFirstOrganizationPetParent?: Maybe<OrganizationPetParent>;
  findManyOrganizationPetParent?: Maybe<Array<OrganizationPetParent>>;
  findManyOrganizationPetParentCount: Scalars['Int'];
  aggregateOrganizationPetParent?: Maybe<AggregateOrganizationPetParent>;
  findUniqueOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  findFirstOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  findManyOrganizationPetToOrganizationPetParent?: Maybe<Array<OrganizationPetToOrganizationPetParent>>;
  findManyOrganizationPetToOrganizationPetParentCount: Scalars['Int'];
  aggregateOrganizationPetToOrganizationPetParent?: Maybe<AggregateOrganizationPetToOrganizationPetParent>;
  findUniqueCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  findFirstCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  findManyCareAuthorizationRequest?: Maybe<Array<CareAuthorizationRequest>>;
  findManyCareAuthorizationRequestCount: Scalars['Int'];
  aggregateCareAuthorizationRequest?: Maybe<AggregateCareAuthorizationRequest>;
  findUniqueCareBenefit?: Maybe<CareBenefit>;
  findFirstCareBenefit?: Maybe<CareBenefit>;
  findManyCareBenefit?: Maybe<Array<CareBenefit>>;
  findManyCareBenefitCount: Scalars['Int'];
  aggregateCareBenefit?: Maybe<AggregateCareBenefit>;
  findUniqueCarePlan?: Maybe<CarePlan>;
  findFirstCarePlan?: Maybe<CarePlan>;
  findManyCarePlan?: Maybe<Array<CarePlan>>;
  findManyCarePlanCount: Scalars['Int'];
  aggregateCarePlan?: Maybe<AggregateCarePlan>;
  findUniqueCareAddonPackage?: Maybe<CareAddonPackage>;
  findFirstCareAddonPackage?: Maybe<CareAddonPackage>;
  findManyCareAddonPackage?: Maybe<Array<CareAddonPackage>>;
  findManyCareAddonPackageCount: Scalars['Int'];
  aggregateCareAddonPackage?: Maybe<AggregateCareAddonPackage>;
  findUniqueCarePlanBenefit?: Maybe<CarePlanBenefit>;
  findFirstCarePlanBenefit?: Maybe<CarePlanBenefit>;
  findManyCarePlanBenefit?: Maybe<Array<CarePlanBenefit>>;
  findManyCarePlanBenefitCount: Scalars['Int'];
  aggregateCarePlanBenefit?: Maybe<AggregateCarePlanBenefit>;
  findUniqueCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  findFirstCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  findManyCareAddonPackageBenefit?: Maybe<Array<CareAddonPackageBenefit>>;
  findManyCareAddonPackageBenefitCount: Scalars['Int'];
  aggregateCareAddonPackageBenefit?: Maybe<AggregateCareAddonPackageBenefit>;
  findUniqueCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  findFirstCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  findManyCarePlanEnrollment?: Maybe<Array<CarePlanEnrollment>>;
  findManyCarePlanEnrollmentCount: Scalars['Int'];
  aggregateCarePlanEnrollment?: Maybe<AggregateCarePlanEnrollment>;
  findUniqueCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  findFirstCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  findManyCareAddonEnrollment?: Maybe<Array<CareAddonEnrollment>>;
  findManyCareAddonEnrollmentCount: Scalars['Int'];
  aggregateCareAddonEnrollment?: Maybe<AggregateCareAddonEnrollment>;
  findUniqueCareBenefitUsage?: Maybe<CareBenefitUsage>;
  findFirstCareBenefitUsage?: Maybe<CareBenefitUsage>;
  findManyCareBenefitUsage?: Maybe<Array<CareBenefitUsage>>;
  findManyCareBenefitUsageCount: Scalars['Int'];
  aggregateCareBenefitUsage?: Maybe<AggregateCareBenefitUsage>;
  findUniqueCareStripeSubscription?: Maybe<CareStripeSubscription>;
  findFirstCareStripeSubscription?: Maybe<CareStripeSubscription>;
  findManyCareStripeSubscription?: Maybe<Array<CareStripeSubscription>>;
  findManyCareStripeSubscriptionCount: Scalars['Int'];
  aggregateCareStripeSubscription?: Maybe<AggregateCareStripeSubscription>;
  findUniqueStripeCustomer?: Maybe<StripeCustomer>;
  findFirstStripeCustomer?: Maybe<StripeCustomer>;
  findManyStripeCustomer?: Maybe<Array<StripeCustomer>>;
  findManyStripeCustomerCount: Scalars['Int'];
  aggregateStripeCustomer?: Maybe<AggregateStripeCustomer>;
  findUniquePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  findFirstPimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  findManyPimsWritebackLogEntry?: Maybe<Array<PimsWritebackLogEntry>>;
  findManyPimsWritebackLogEntryCount: Scalars['Int'];
  aggregatePimsWritebackLogEntry?: Maybe<AggregatePimsWritebackLogEntry>;
  findUniqueChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  findFirstChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  findManyChannelAutomationStatus?: Maybe<Array<ChannelAutomationStatus>>;
  findManyChannelAutomationStatusCount: Scalars['Int'];
  aggregateChannelAutomationStatus?: Maybe<AggregateChannelAutomationStatus>;
  findUniqueChannelCreationSource?: Maybe<ChannelCreationSource>;
  findFirstChannelCreationSource?: Maybe<ChannelCreationSource>;
  findManyChannelCreationSource?: Maybe<Array<ChannelCreationSource>>;
  findManyChannelCreationSourceCount: Scalars['Int'];
  aggregateChannelCreationSource?: Maybe<AggregateChannelCreationSource>;
  findUniqueInstinctIntegration?: Maybe<InstinctIntegration>;
  findFirstInstinctIntegration?: Maybe<InstinctIntegration>;
  findManyInstinctIntegration?: Maybe<Array<InstinctIntegration>>;
  findManyInstinctIntegrationCount: Scalars['Int'];
  aggregateInstinctIntegration?: Maybe<AggregateInstinctIntegration>;
  findUniqueClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  findFirstClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  findManyClinicDirectBookingSetting?: Maybe<Array<ClinicDirectBookingSetting>>;
  findManyClinicDirectBookingSettingCount: Scalars['Int'];
  aggregateClinicDirectBookingSetting?: Maybe<AggregateClinicDirectBookingSetting>;
  findUniqueVetAvailability?: Maybe<VetAvailability>;
  findFirstVetAvailability?: Maybe<VetAvailability>;
  findManyVetAvailability?: Maybe<Array<VetAvailability>>;
  findManyVetAvailabilityCount: Scalars['Int'];
  aggregateVetAvailability?: Maybe<AggregateVetAvailability>;
  findUniqueVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  findFirstVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  findManyVetAvailabilityMap?: Maybe<Array<VetAvailabilityMap>>;
  findManyVetAvailabilityMapCount: Scalars['Int'];
  aggregateVetAvailabilityMap?: Maybe<AggregateVetAvailabilityMap>;
  findUniqueStripeInvoice?: Maybe<StripeInvoice>;
  findFirstStripeInvoice?: Maybe<StripeInvoice>;
  findManyStripeInvoice?: Maybe<Array<StripeInvoice>>;
  findManyStripeInvoiceCount: Scalars['Int'];
  aggregateStripeInvoice?: Maybe<AggregateStripeInvoice>;
  findUniqueOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  findFirstOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  findManyOrganizationCareBenefitToClinicService?: Maybe<Array<OrganizationCareBenefitToClinicService>>;
  findManyOrganizationCareBenefitToClinicServiceCount: Scalars['Int'];
  aggregateOrganizationCareBenefitToClinicService?: Maybe<AggregateOrganizationCareBenefitToClinicService>;
  findUniqueClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  findFirstClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  findManyClinicPetParentAddress?: Maybe<Array<ClinicPetParentAddress>>;
  findManyClinicPetParentAddressCount: Scalars['Int'];
  aggregateClinicPetParentAddress?: Maybe<AggregateClinicPetParentAddress>;
  findUniqueCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  findFirstCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  findManyCareBenefitUsageToInvoiceLineItem?: Maybe<Array<CareBenefitUsageToInvoiceLineItem>>;
  findManyCareBenefitUsageToInvoiceLineItemCount: Scalars['Int'];
  aggregateCareBenefitUsageToInvoiceLineItem?: Maybe<AggregateCareBenefitUsageToInvoiceLineItem>;
  findUniqueChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  findFirstChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  findManyChannelFilterSelection?: Maybe<Array<ChannelFilterSelection>>;
  findManyChannelFilterSelectionCount: Scalars['Int'];
  aggregateChannelFilterSelection?: Maybe<AggregateChannelFilterSelection>;
  findUniqueUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  findFirstUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  findManyUserChannelFilterSelection?: Maybe<Array<UserChannelFilterSelection>>;
  findManyUserChannelFilterSelectionCount: Scalars['Int'];
  aggregateUserChannelFilterSelection?: Maybe<AggregateUserChannelFilterSelection>;
  findUniqueMessageTemplate?: Maybe<MessageTemplate>;
  findFirstMessageTemplate?: Maybe<MessageTemplate>;
  findManyMessageTemplate?: Maybe<Array<MessageTemplate>>;
  findManyMessageTemplateCount: Scalars['Int'];
  aggregateMessageTemplate?: Maybe<AggregateMessageTemplate>;
  findUniqueRules?: Maybe<Rules>;
  findFirstRules?: Maybe<Rules>;
  findManyRules?: Maybe<Array<Rules>>;
  findManyRulesCount: Scalars['Int'];
  aggregateRules?: Maybe<AggregateRules>;
  findUniqueCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  findFirstCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  findManyCareEnrollmentToStripeInvoiceItem?: Maybe<Array<CareEnrollmentToStripeInvoiceItem>>;
  findManyCareEnrollmentToStripeInvoiceItemCount: Scalars['Int'];
  aggregateCareEnrollmentToStripeInvoiceItem?: Maybe<AggregateCareEnrollmentToStripeInvoiceItem>;
  findUniqueStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  findFirstStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  findManyStripeInvoiceItem?: Maybe<Array<StripeInvoiceItem>>;
  findManyStripeInvoiceItemCount: Scalars['Int'];
  aggregateStripeInvoiceItem?: Maybe<AggregateStripeInvoiceItem>;
  findUniqueClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  findFirstClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  findManyClinicEmployeeAppointmentTypeSetting?: Maybe<Array<ClinicEmployeeAppointmentTypeSetting>>;
  findManyClinicEmployeeAppointmentTypeSettingCount: Scalars['Int'];
  aggregateClinicEmployeeAppointmentTypeSetting?: Maybe<AggregateClinicEmployeeAppointmentTypeSetting>;
  findUniqueClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  findFirstClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  findManyClinicPostcardSetting?: Maybe<Array<ClinicPostcardSetting>>;
  findManyClinicPostcardSettingCount: Scalars['Int'];
  aggregateClinicPostcardSetting?: Maybe<AggregateClinicPostcardSetting>;
  findUniqueInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  findFirstInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  findManyInvoicePimsWritebackLogEntry?: Maybe<Array<InvoicePimsWritebackLogEntry>>;
  findManyInvoicePimsWritebackLogEntryCount: Scalars['Int'];
  aggregateInvoicePimsWritebackLogEntry?: Maybe<AggregateInvoicePimsWritebackLogEntry>;
  findUniqueNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  findFirstNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  findManyNotificationEventToMessage?: Maybe<Array<NotificationEventToMessage>>;
  findManyNotificationEventToMessageCount: Scalars['Int'];
  aggregateNotificationEventToMessage?: Maybe<AggregateNotificationEventToMessage>;
  findUniqueEmergencyClinics?: Maybe<EmergencyClinics>;
  findFirstEmergencyClinics?: Maybe<EmergencyClinics>;
  findManyEmergencyClinics?: Maybe<Array<EmergencyClinics>>;
  findManyEmergencyClinicsCount: Scalars['Int'];
  aggregateEmergencyClinics?: Maybe<AggregateEmergencyClinics>;
  findUniqueCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  findFirstCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  findManyCareEnrollmentPayment?: Maybe<Array<CareEnrollmentPayment>>;
  findManyCareEnrollmentPaymentCount: Scalars['Int'];
  aggregateCareEnrollmentPayment?: Maybe<AggregateCareEnrollmentPayment>;
  findUniqueAutomationRun?: Maybe<AutomationRun>;
  findFirstAutomationRun?: Maybe<AutomationRun>;
  findManyAutomationRun?: Maybe<Array<AutomationRun>>;
  findManyAutomationRunCount: Scalars['Int'];
  aggregateAutomationRun?: Maybe<AggregateAutomationRun>;
  findUniqueAutomationRunAction?: Maybe<AutomationRunAction>;
  findFirstAutomationRunAction?: Maybe<AutomationRunAction>;
  findManyAutomationRunAction?: Maybe<Array<AutomationRunAction>>;
  findManyAutomationRunActionCount: Scalars['Int'];
  aggregateAutomationRunAction?: Maybe<AggregateAutomationRunAction>;
  findUniqueChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  findFirstChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  findManyChannelMessageReaction?: Maybe<Array<ChannelMessageReaction>>;
  findManyChannelMessageReactionCount: Scalars['Int'];
  aggregateChannelMessageReaction?: Maybe<AggregateChannelMessageReaction>;
  findUniqueVaccination?: Maybe<Vaccination>;
  findFirstVaccination?: Maybe<Vaccination>;
  findManyVaccination?: Maybe<Array<Vaccination>>;
  findManyVaccinationCount: Scalars['Int'];
  aggregateVaccination?: Maybe<AggregateVaccination>;
  findUniqueMassTextAlert?: Maybe<MassTextAlert>;
  findFirstMassTextAlert?: Maybe<MassTextAlert>;
  findManyMassTextAlert?: Maybe<Array<MassTextAlert>>;
  findManyMassTextAlertCount: Scalars['Int'];
  aggregateMassTextAlert?: Maybe<AggregateMassTextAlert>;
  findUniqueMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  findFirstMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  findManyMassTextAlertEntry?: Maybe<Array<MassTextAlertEntry>>;
  findManyMassTextAlertEntryCount: Scalars['Int'];
  aggregateMassTextAlertEntry?: Maybe<AggregateMassTextAlertEntry>;
  findUniqueMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  findFirstMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  findManyMassTextAlertEntryAppointment?: Maybe<Array<MassTextAlertEntryAppointment>>;
  findManyMassTextAlertEntryAppointmentCount: Scalars['Int'];
  aggregateMassTextAlertEntryAppointment?: Maybe<AggregateMassTextAlertEntryAppointment>;
  findUniquePPCIntegration?: Maybe<PpcIntegration>;
  findFirstPPCIntegration?: Maybe<PpcIntegration>;
  findManyPPCIntegration?: Maybe<Array<PpcIntegration>>;
  findManyPPCIntegrationCount: Scalars['Int'];
  aggregatePPCIntegration?: Maybe<AggregatePpcIntegration>;
  findUniquePetPortalSetting?: Maybe<PetPortalSetting>;
  findFirstPetPortalSetting?: Maybe<PetPortalSetting>;
  findManyPetPortalSetting?: Maybe<Array<PetPortalSetting>>;
  findManyPetPortalSettingCount: Scalars['Int'];
  aggregatePetPortalSetting?: Maybe<AggregatePetPortalSetting>;
  findUniquePrescription?: Maybe<Prescription>;
  findFirstPrescription?: Maybe<Prescription>;
  findManyPrescription?: Maybe<Array<Prescription>>;
  findManyPrescriptionCount: Scalars['Int'];
  aggregatePrescription?: Maybe<AggregatePrescription>;
  findUniqueClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  findFirstClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  findManyClinicPhoneNumber?: Maybe<Array<ClinicPhoneNumber>>;
  findManyClinicPhoneNumberCount: Scalars['Int'];
  aggregateClinicPhoneNumber?: Maybe<AggregateClinicPhoneNumber>;
  getEmailCampaignLoginUrl?: Maybe<Scalars['String']>;
  getAvailableFormTemplatesForWorkflows: Array<Maybe<FormTemplate>>;
  getPossiblyDeflectedFormSubmission?: Maybe<FormSubmission>;
  checkClinicCareStatus: CheckClinicCareStatusResult;
  clientReadTest?: Maybe<Scalars['Boolean']>;
  clientWriteTest?: Maybe<Scalars['Boolean']>;
  getLatestActiveSubscriptionInvoice?: Maybe<GetLatestActiveSubscriptionInvoiceOutput>;
  calculatePetBenefitUsage?: Maybe<CalculatePetBenefitUsageOutput>;
  calculateMultiplePetsBenefitUsage?: Maybe<CalculateMultiplePetsBenefitUsageOutput>;
  channelUnreadMessageCount?: Maybe<ChannelUnreadMessageCountResult>;
  channelExport?: Maybe<FileExportData>;
  formSubmissionExport?: Maybe<FileExportData>;
  savedChannelFilterSelections: Array<ChannelFilterSelection>;
  channelMessageUnreadCount: ChannelMessageUnreadCountResult;
  findClinicContactInfo?: Maybe<ClinicContactInfo>;
  findClinicPetParentByToken?: Maybe<ClinicPetParent>;
  widgetClinicPetParentLookup2: Array<WidgetClinicPetParentLookupResponse>;
  getOrCreateActiveChannel?: Maybe<Channel>;
  getDirectBookingAppointmentTypes: Array<Maybe<DirectBookingAppointmentTypes>>;
  getDirectBookingOpenTimeSlots: Array<Maybe<DirectBookingOpenTimeSlotResponse>>;
  getClinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingsResponse>;
  getInvoiceById?: Maybe<Invoice>;
  getInvoicesForClient: Array<Invoice>;
  getInvoicesForClients: Array<Invoice>;
  estimateNumberOfPetParentsReached?: Maybe<NumberOfPetParentsReached>;
  massTextAlertReport?: Maybe<Array<Maybe<MassTextAlertReportEntry>>>;
  findOrganizationPetParentByToken?: Maybe<OrganizationPetParent>;
  getReports: Array<Report>;
  clinicPetSearch: Array<ClinicPet>;
  clinicPetParentSearch: Array<ClinicPetParent>;
  channelSearch: ChannelSearchOutput;
  getStripePaymentIntentData?: Maybe<StripePaymentOutput>;
  getCurrentUser?: Maybe<User>;
  getBalanceForClient?: Maybe<BitwerxClientBalance>;
  sendPetParentReceipt?: Maybe<Scalars['Json']>;
  sendConsultationReceipt?: Maybe<Scalars['Json']>;
  sendVetSubscriptionReceipt?: Maybe<Scalars['Json']>;
  sendDailyCronJobs?: Maybe<Scalars['Json']>;
  sendDailyFinancialReport?: Maybe<Scalars['Json']>;
  stripeCreateLoginLink?: Maybe<LoginLinkOutput>;
  stripeRetrieveConnectedAccount?: Maybe<StripeAccountOutput>;
  getTerminalToken?: Maybe<StripeTerminalTokenOutput>;
  healthCheck?: Maybe<GenericResponse>;
  tokenValidation?: Maybe<Scalars['Json']>;
  getTwilioNumbers?: Maybe<GenericResponse>;
  searchTwilioNumbers?: Maybe<GenericResponse>;
  twilioCapabilityToken?: Maybe<TwilioCapabilityToken>;
  verificationRequest?: Maybe<GenericResponse>;
  verificationCheck?: Maybe<GenericResponse>;
  checkPhoneVerification?: Maybe<Scalars['Json']>;
};

export type QueryFindUniqueAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};

export type QueryFindFirstAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
};

export type QueryFindManyAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
};

export type QueryFindManyAppointmentCountArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
};

export type QueryAggregateAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueAppointmentTypeArgs = {
  where: AppointmentTypeWhereUniqueInput;
};

export type QueryFindFirstAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
};

export type QueryFindManyAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
};

export type QueryFindManyAppointmentTypeCountArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
};

export type QueryAggregateAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueBitwerxIntegrationArgs = {
  where: BitwerxIntegrationWhereUniqueInput;
};

export type QueryFindFirstBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyBitwerxIntegrationCountArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
};

export type QueryAggregateBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelArgs = {
  where: ChannelWhereUniqueInput;
};

export type QueryFindFirstChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
};

export type QueryFindManyChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
};

export type QueryFindManyChannelCountArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
};

export type QueryAggregateChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelMemberArgs = {
  where: ChannelMemberWhereUniqueInput;
};

export type QueryFindFirstChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
};

export type QueryFindManyChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
};

export type QueryFindManyChannelMemberCountArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
};

export type QueryAggregateChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelMessageArgs = {
  where: ChannelMessageWhereUniqueInput;
};

export type QueryFindFirstChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
};

export type QueryFindManyChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
};

export type QueryFindManyChannelMessageCountArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
};

export type QueryAggregateChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelMessageAttachmentArgs = {
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type QueryFindFirstChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
};

export type QueryFindManyChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
};

export type QueryFindManyChannelMessageAttachmentCountArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
};

export type QueryAggregateChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelStatusArgs = {
  where: ChannelStatusWhereUniqueInput;
};

export type QueryFindFirstChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
};

export type QueryFindManyChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
};

export type QueryFindManyChannelStatusCountArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
};

export type QueryAggregateChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelStatusChannelAssigneeArgs = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type QueryFindFirstChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
};

export type QueryFindManyChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
};

export type QueryFindManyChannelStatusChannelAssigneeCountArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
};

export type QueryAggregateChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicArgs = {
  where: ClinicWhereUniqueInput;
};

export type QueryFindFirstClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
};

export type QueryFindManyClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
};

export type QueryFindManyClinicCountArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
};

export type QueryAggregateClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicEmployeeArgs = {
  where: ClinicEmployeeWhereUniqueInput;
};

export type QueryFindFirstClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
};

export type QueryFindManyClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
};

export type QueryFindManyClinicEmployeeCountArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
};

export type QueryAggregateClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPaymentFeeConfigurationArgs = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};

export type QueryFindFirstClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
};

export type QueryFindManyClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
};

export type QueryFindManyClinicPaymentFeeConfigurationCountArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
};

export type QueryAggregateClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPetArgs = {
  where: ClinicPetWhereUniqueInput;
};

export type QueryFindFirstClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
};

export type QueryFindManyClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
};

export type QueryFindManyClinicPetCountArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
};

export type QueryAggregateClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPetAlertArgs = {
  where: ClinicPetAlertWhereUniqueInput;
};

export type QueryFindFirstClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
};

export type QueryFindManyClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
};

export type QueryFindManyClinicPetAlertCountArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
};

export type QueryAggregateClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPetParentArgs = {
  where: ClinicPetParentWhereUniqueInput;
};

export type QueryFindFirstClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
};

export type QueryFindManyClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
};

export type QueryFindManyClinicPetParentCountArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
};

export type QueryAggregateClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPimsIntegrationArgs = {
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type QueryFindFirstClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyClinicPimsIntegrationCountArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
};

export type QueryAggregateClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPimsIntegrationCapabilityArgs = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};

export type QueryFindFirstClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
};

export type QueryFindManyClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
};

export type QueryFindManyClinicPimsIntegrationCapabilityCountArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
};

export type QueryAggregateClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicRoomArgs = {
  where: ClinicRoomWhereUniqueInput;
};

export type QueryFindFirstClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
};

export type QueryFindManyClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
};

export type QueryFindManyClinicRoomCountArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
};

export type QueryAggregateClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicSettingArgs = {
  where: ClinicSettingWhereUniqueInput;
};

export type QueryFindFirstClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicSettingCountArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
};

export type QueryAggregateClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicWidgetRequestArgs = {
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type QueryFindFirstClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
};

export type QueryFindManyClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
};

export type QueryFindManyClinicWidgetRequestCountArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
};

export type QueryAggregateClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicWidgetRequestTypeArgs = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type QueryFindFirstClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
};

export type QueryFindManyClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
};

export type QueryFindManyClinicWidgetRequestTypeCountArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
};

export type QueryAggregateClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicWidgetSettingArgs = {
  where: ClinicWidgetSettingWhereUniqueInput;
};

export type QueryFindFirstClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicWidgetSettingCountArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
};

export type QueryAggregateClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueConsultationArgs = {
  where: ConsultationWhereUniqueInput;
};

export type QueryFindFirstConsultationArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
};

export type QueryFindManyConsultationArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
};

export type QueryFindManyConsultationCountArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
};

export type QueryAggregateConsultationArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueConsultationRequestArgs = {
  where: ConsultationRequestWhereUniqueInput;
};

export type QueryFindFirstConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
};

export type QueryFindManyConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
};

export type QueryFindManyConsultationRequestCountArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
};

export type QueryAggregateConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueDisbursementArgs = {
  where: DisbursementWhereUniqueInput;
};

export type QueryFindFirstDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
};

export type QueryFindManyDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
};

export type QueryFindManyDisbursementCountArgs = {
  where?: Maybe<DisbursementWhereInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
};

export type QueryAggregateDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueEzyVetIntegrationArgs = {
  where: EzyVetIntegrationWhereUniqueInput;
};

export type QueryFindFirstEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyEzyVetIntegrationCountArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
};

export type QueryAggregateEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueFinancialTransactionArgs = {
  where: FinancialTransactionWhereUniqueInput;
};

export type QueryFindFirstFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
};

export type QueryFindManyFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
};

export type QueryFindManyFinancialTransactionCountArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
};

export type QueryAggregateFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueFormSubmissionArgs = {
  where: FormSubmissionWhereUniqueInput;
};

export type QueryFindFirstFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
};

export type QueryFindManyFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
};

export type QueryFindManyFormSubmissionCountArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
};

export type QueryAggregateFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueFormTemplateArgs = {
  where: FormTemplateWhereUniqueInput;
};

export type QueryFindFirstFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
};

export type QueryFindManyFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
};

export type QueryFindManyFormTemplateCountArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
};

export type QueryAggregateFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};

export type QueryFindFirstInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
};

export type QueryFindManyInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
};

export type QueryFindManyInvoiceCountArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
};

export type QueryAggregateInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueInvoiceLineItemArgs = {
  where: InvoiceLineItemWhereUniqueInput;
};

export type QueryFindFirstInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
};

export type QueryFindManyInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
};

export type QueryFindManyInvoiceLineItemCountArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
};

export type QueryAggregateInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};

export type QueryFindFirstOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationCountArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
};

export type QueryAggregateOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniquePermissionArgs = {
  where: PermissionWhereUniqueInput;
};

export type QueryFindFirstPermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
};

export type QueryFindManyPermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
};

export type QueryFindManyPermissionCountArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
};

export type QueryAggregatePermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniquePetParentSettingArgs = {
  where: PetParentSettingWhereUniqueInput;
};

export type QueryFindFirstPetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
};

export type QueryFindManyPetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
};

export type QueryFindManyPetParentSettingCountArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
};

export type QueryAggregatePetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniquePimsInvoiceArgs = {
  where: PimsInvoiceWhereUniqueInput;
};

export type QueryFindFirstPimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
};

export type QueryFindManyPimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
};

export type QueryFindManyPimsInvoiceCountArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
};

export type QueryAggregatePimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniquePimsInvoiceLineItemArgs = {
  where: PimsInvoiceLineItemWhereUniqueInput;
};

export type QueryFindFirstPimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
};

export type QueryFindManyPimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
};

export type QueryFindManyPimsInvoiceLineItemCountArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
};

export type QueryAggregatePimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueServiceArgs = {
  where: ServiceWhereUniqueInput;
};

export type QueryFindFirstServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
};

export type QueryFindManyServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
};

export type QueryFindManyServiceCountArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
};

export type QueryAggregateServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueServiceReminderTimingArgs = {
  where: ServiceReminderTimingWhereUniqueInput;
};

export type QueryFindFirstServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
};

export type QueryFindManyServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
};

export type QueryFindManyServiceReminderTimingCountArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
};

export type QueryAggregateServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueServiceReminderArgs = {
  where: ServiceReminderWhereUniqueInput;
};

export type QueryFindFirstServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
};

export type QueryFindManyServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
};

export type QueryFindManyServiceReminderCountArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
};

export type QueryAggregateServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueServiceReminderNotificationArgs = {
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type QueryFindFirstServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
};

export type QueryFindManyServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
};

export type QueryFindManyServiceReminderNotificationCountArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
};

export type QueryAggregateServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStaffRoleArgs = {
  where: StaffRoleWhereUniqueInput;
};

export type QueryFindFirstStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
};

export type QueryFindManyStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
};

export type QueryFindManyStaffRoleCountArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
};

export type QueryAggregateStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeDisputeArgs = {
  where: StripeDisputeWhereUniqueInput;
};

export type QueryFindFirstStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
};

export type QueryFindManyStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
};

export type QueryFindManyStripeDisputeCountArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
};

export type QueryAggregateStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};

export type QueryFindFirstStripePaymentIntentArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
};

export type QueryFindManyStripePaymentIntentArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
};

export type QueryFindManyStripePaymentIntentCountArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
};

export type QueryAggregateStripePaymentIntentArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};

export type QueryFindFirstStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
};

export type QueryFindManyStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
};

export type QueryFindManyStripePaymentMethodCountArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
};

export type QueryAggregateStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripePayoutArgs = {
  where: StripePayoutWhereUniqueInput;
};

export type QueryFindFirstStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
};

export type QueryFindManyStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
};

export type QueryFindManyStripePayoutCountArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
};

export type QueryAggregateStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripePayoutAccountArgs = {
  where: StripePayoutAccountWhereUniqueInput;
};

export type QueryFindFirstStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
};

export type QueryFindManyStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
};

export type QueryFindManyStripePayoutAccountCountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
};

export type QueryAggregateStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeReceiptArgs = {
  where: StripeReceiptWhereUniqueInput;
};

export type QueryFindFirstStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
};

export type QueryFindManyStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
};

export type QueryFindManyStripeReceiptCountArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
};

export type QueryAggregateStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeReceiptHistoryArgs = {
  where: StripeReceiptHistoryWhereUniqueInput;
};

export type QueryFindFirstStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
};

export type QueryFindManyStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
};

export type QueryFindManyStripeReceiptHistoryCountArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
};

export type QueryAggregateStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeRefundArgs = {
  where: StripeRefundWhereUniqueInput;
};

export type QueryFindFirstStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
};

export type QueryFindManyStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
};

export type QueryFindManyStripeRefundCountArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
};

export type QueryAggregateStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeTerminalArgs = {
  where: StripeTerminalWhereUniqueInput;
};

export type QueryFindFirstStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
};

export type QueryFindManyStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
};

export type QueryFindManyStripeTerminalCountArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
};

export type QueryAggregateStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeTerminalLocationArgs = {
  where: StripeTerminalLocationWhereUniqueInput;
};

export type QueryFindFirstStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
};

export type QueryFindManyStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
};

export type QueryFindManyStripeTerminalLocationCountArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
};

export type QueryAggregateStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type QueryFindFirstTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
};

export type QueryFindManyTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
};

export type QueryFindManyTransactionCountArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
};

export type QueryAggregateTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryFindFirstUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
};

export type QueryFindManyUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
};

export type QueryFindManyUserCountArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
};

export type QueryAggregateUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueUserAppointmentGroupingArgs = {
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type QueryFindFirstUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
};

export type QueryFindManyUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
};

export type QueryFindManyUserAppointmentGroupingCountArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
};

export type QueryAggregateUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueUserNotificationSettingArgs = {
  where: UserNotificationSettingWhereUniqueInput;
};

export type QueryFindFirstUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
};

export type QueryFindManyUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
};

export type QueryFindManyUserNotificationSettingCountArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
};

export type QueryAggregateUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};

export type QueryFindFirstUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
};

export type QueryFindManyUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
};

export type QueryFindManyUserSettingCountArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
};

export type QueryAggregateUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueVetInfoArgs = {
  where: VetInfoWhereUniqueInput;
};

export type QueryFindFirstVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
};

export type QueryFindManyVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
};

export type QueryFindManyVetInfoCountArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
};

export type QueryAggregateVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
};

export type QueryFindFirstWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
};

export type QueryFindManyWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
};

export type QueryFindManyWorkflowEventSettingCountArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
};

export type QueryAggregateWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicEntityPhoneNumberArgs = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type QueryFindFirstClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
};

export type QueryFindManyClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
};

export type QueryFindManyClinicEntityPhoneNumberCountArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
};

export type QueryAggregateClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicOfficeHourArgs = {
  where: ClinicOfficeHourWhereUniqueInput;
};

export type QueryFindFirstClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
};

export type QueryFindManyClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
};

export type QueryFindManyClinicOfficeHourCountArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
};

export type QueryAggregateClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueEmailProviderLogArgs = {
  where: EmailProviderLogWhereUniqueInput;
};

export type QueryFindFirstEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
};

export type QueryFindManyEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
};

export type QueryFindManyEmailProviderLogCountArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
};

export type QueryAggregateEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueSmsNotificationStatusArgs = {
  where: SmsNotificationStatusWhereUniqueInput;
};

export type QueryFindFirstSmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
};

export type QueryFindManySmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
};

export type QueryFindManySmsNotificationStatusCountArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
};

export type QueryAggregateSmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueFeatureFlagArgs = {
  where: FeatureFlagWhereUniqueInput;
};

export type QueryFindFirstFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
};

export type QueryFindManyFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
};

export type QueryFindManyFeatureFlagCountArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
};

export type QueryAggregateFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueTwilioCallRecordingArgs = {
  where: TwilioCallRecordingWhereUniqueInput;
};

export type QueryFindFirstTwilioCallRecordingArgs = {
  where?: Maybe<TwilioCallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
};

export type QueryFindManyTwilioCallRecordingArgs = {
  where?: Maybe<TwilioCallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
};

export type QueryFindManyTwilioCallRecordingCountArgs = {
  where?: Maybe<TwilioCallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
};

export type QueryAggregateTwilioCallRecordingArgs = {
  where?: Maybe<TwilioCallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCallRecordingArgs = {
  where: CallRecordingWhereUniqueInput;
};

export type QueryFindFirstCallRecordingArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
};

export type QueryFindManyCallRecordingArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
};

export type QueryFindManyCallRecordingCountArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
};

export type QueryAggregateCallRecordingArgs = {
  where?: Maybe<CallRecordingWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCallHistoryArgs = {
  where: CallHistoryWhereUniqueInput;
};

export type QueryFindFirstCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
};

export type QueryFindManyCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
};

export type QueryFindManyCallHistoryCountArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
};

export type QueryAggregateCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCallParticipantArgs = {
  where: CallParticipantWhereUniqueInput;
};

export type QueryFindFirstCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
};

export type QueryFindManyCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
};

export type QueryFindManyCallParticipantCountArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
};

export type QueryAggregateCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelSelectionArgs = {
  where: ChannelSelectionWhereUniqueInput;
};

export type QueryFindFirstChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
};

export type QueryFindManyChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
};

export type QueryFindManyChannelSelectionCountArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
};

export type QueryAggregateChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicEmailCampaignSettingArgs = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};

export type QueryFindFirstClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicEmailCampaignSettingCountArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
};

export type QueryAggregateClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueOrganizationPetArgs = {
  where: OrganizationPetWhereUniqueInput;
};

export type QueryFindFirstOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationPetCountArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
};

export type QueryAggregateOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueOrganizationPetParentArgs = {
  where: OrganizationPetParentWhereUniqueInput;
};

export type QueryFindFirstOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationPetParentCountArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
};

export type QueryAggregateOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueOrganizationPetToOrganizationPetParentArgs = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type QueryFindFirstOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationPetToOrganizationPetParentCountArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
};

export type QueryAggregateOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareAuthorizationRequestArgs = {
  where: CareAuthorizationRequestWhereUniqueInput;
};

export type QueryFindFirstCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
};

export type QueryFindManyCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
};

export type QueryFindManyCareAuthorizationRequestCountArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
};

export type QueryAggregateCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareBenefitArgs = {
  where: CareBenefitWhereUniqueInput;
};

export type QueryFindFirstCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
};

export type QueryFindManyCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
};

export type QueryFindManyCareBenefitCountArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
};

export type QueryAggregateCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCarePlanArgs = {
  where: CarePlanWhereUniqueInput;
};

export type QueryFindFirstCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
};

export type QueryFindManyCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
};

export type QueryFindManyCarePlanCountArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
};

export type QueryAggregateCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareAddonPackageArgs = {
  where: CareAddonPackageWhereUniqueInput;
};

export type QueryFindFirstCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
};

export type QueryFindManyCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
};

export type QueryFindManyCareAddonPackageCountArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
};

export type QueryAggregateCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCarePlanBenefitArgs = {
  where: CarePlanBenefitWhereUniqueInput;
};

export type QueryFindFirstCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
};

export type QueryFindManyCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
};

export type QueryFindManyCarePlanBenefitCountArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
};

export type QueryAggregateCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareAddonPackageBenefitArgs = {
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type QueryFindFirstCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
};

export type QueryFindManyCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
};

export type QueryFindManyCareAddonPackageBenefitCountArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
};

export type QueryAggregateCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCarePlanEnrollmentArgs = {
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type QueryFindFirstCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
};

export type QueryFindManyCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
};

export type QueryFindManyCarePlanEnrollmentCountArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
};

export type QueryAggregateCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareAddonEnrollmentArgs = {
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type QueryFindFirstCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
};

export type QueryFindManyCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
};

export type QueryFindManyCareAddonEnrollmentCountArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
};

export type QueryAggregateCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareBenefitUsageArgs = {
  where: CareBenefitUsageWhereUniqueInput;
};

export type QueryFindFirstCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
};

export type QueryFindManyCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
};

export type QueryFindManyCareBenefitUsageCountArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
};

export type QueryAggregateCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareStripeSubscriptionArgs = {
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type QueryFindFirstCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
};

export type QueryFindManyCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
};

export type QueryFindManyCareStripeSubscriptionCountArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
};

export type QueryAggregateCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeCustomerArgs = {
  where: StripeCustomerWhereUniqueInput;
};

export type QueryFindFirstStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
};

export type QueryFindManyStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
};

export type QueryFindManyStripeCustomerCountArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
};

export type QueryAggregateStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniquePimsWritebackLogEntryArgs = {
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type QueryFindFirstPimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
};

export type QueryFindManyPimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
};

export type QueryFindManyPimsWritebackLogEntryCountArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
};

export type QueryAggregatePimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelAutomationStatusArgs = {
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type QueryFindFirstChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
};

export type QueryFindManyChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
};

export type QueryFindManyChannelAutomationStatusCountArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
};

export type QueryAggregateChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelCreationSourceArgs = {
  where: ChannelCreationSourceWhereUniqueInput;
};

export type QueryFindFirstChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
};

export type QueryFindManyChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
};

export type QueryFindManyChannelCreationSourceCountArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
};

export type QueryAggregateChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueInstinctIntegrationArgs = {
  where: InstinctIntegrationWhereUniqueInput;
};

export type QueryFindFirstInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyInstinctIntegrationCountArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
};

export type QueryAggregateInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicDirectBookingSettingArgs = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
};

export type QueryFindFirstClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicDirectBookingSettingCountArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
};

export type QueryAggregateClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueVetAvailabilityArgs = {
  where: VetAvailabilityWhereUniqueInput;
};

export type QueryFindFirstVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
};

export type QueryFindManyVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
};

export type QueryFindManyVetAvailabilityCountArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
};

export type QueryAggregateVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueVetAvailabilityMapArgs = {
  where: VetAvailabilityMapWhereUniqueInput;
};

export type QueryFindFirstVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
};

export type QueryFindManyVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
};

export type QueryFindManyVetAvailabilityMapCountArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
};

export type QueryAggregateVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeInvoiceArgs = {
  where: StripeInvoiceWhereUniqueInput;
};

export type QueryFindFirstStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
};

export type QueryFindManyStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
};

export type QueryFindManyStripeInvoiceCountArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
};

export type QueryAggregateStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueOrganizationCareBenefitToClinicServiceArgs = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type QueryFindFirstOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
};

export type QueryFindManyOrganizationCareBenefitToClinicServiceCountArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
};

export type QueryAggregateOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPetParentAddressArgs = {
  where: ClinicPetParentAddressWhereUniqueInput;
};

export type QueryFindFirstClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
};

export type QueryFindManyClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
};

export type QueryFindManyClinicPetParentAddressCountArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
};

export type QueryAggregateClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareBenefitUsageToInvoiceLineItemArgs = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type QueryFindFirstCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
};

export type QueryFindManyCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
};

export type QueryFindManyCareBenefitUsageToInvoiceLineItemCountArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
};

export type QueryAggregateCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelFilterSelectionArgs = {
  where: ChannelFilterSelectionWhereUniqueInput;
};

export type QueryFindFirstChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
};

export type QueryFindManyChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
};

export type QueryFindManyChannelFilterSelectionCountArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
};

export type QueryAggregateChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueUserChannelFilterSelectionArgs = {
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type QueryFindFirstUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
};

export type QueryFindManyUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
};

export type QueryFindManyUserChannelFilterSelectionCountArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
};

export type QueryAggregateUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueMessageTemplateArgs = {
  where: MessageTemplateWhereUniqueInput;
};

export type QueryFindFirstMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
};

export type QueryFindManyMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
};

export type QueryFindManyMessageTemplateCountArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
};

export type QueryAggregateMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueRulesArgs = {
  where: RulesWhereUniqueInput;
};

export type QueryFindFirstRulesArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
};

export type QueryFindManyRulesArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
};

export type QueryFindManyRulesCountArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
};

export type QueryAggregateRulesArgs = {
  where?: Maybe<RulesWhereInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<RulesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareEnrollmentToStripeInvoiceItemArgs = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type QueryFindFirstCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
};

export type QueryFindManyCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
};

export type QueryFindManyCareEnrollmentToStripeInvoiceItemCountArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
};

export type QueryAggregateCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueStripeInvoiceItemArgs = {
  where: StripeInvoiceItemWhereUniqueInput;
};

export type QueryFindFirstStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
};

export type QueryFindManyStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
};

export type QueryFindManyStripeInvoiceItemCountArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
};

export type QueryAggregateStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicEmployeeAppointmentTypeSettingArgs = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type QueryFindFirstClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicEmployeeAppointmentTypeSettingCountArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
};

export type QueryAggregateClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPostcardSettingArgs = {
  where: ClinicPostcardSettingWhereUniqueInput;
};

export type QueryFindFirstClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
};

export type QueryFindManyClinicPostcardSettingCountArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
};

export type QueryAggregateClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueInvoicePimsWritebackLogEntryArgs = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};

export type QueryFindFirstInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
};

export type QueryFindManyInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
};

export type QueryFindManyInvoicePimsWritebackLogEntryCountArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
};

export type QueryAggregateInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueNotificationEventToMessageArgs = {
  where: NotificationEventToMessageWhereUniqueInput;
};

export type QueryFindFirstNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
};

export type QueryFindManyNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
};

export type QueryFindManyNotificationEventToMessageCountArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
};

export type QueryAggregateNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueEmergencyClinicsArgs = {
  where: EmergencyClinicsWhereUniqueInput;
};

export type QueryFindFirstEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
};

export type QueryFindManyEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
};

export type QueryFindManyEmergencyClinicsCountArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
};

export type QueryAggregateEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueCareEnrollmentPaymentArgs = {
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type QueryFindFirstCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
};

export type QueryFindManyCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
};

export type QueryFindManyCareEnrollmentPaymentCountArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
};

export type QueryAggregateCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueAutomationRunArgs = {
  where: AutomationRunWhereUniqueInput;
};

export type QueryFindFirstAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
};

export type QueryFindManyAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
};

export type QueryFindManyAutomationRunCountArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
};

export type QueryAggregateAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueAutomationRunActionArgs = {
  where: AutomationRunActionWhereUniqueInput;
};

export type QueryFindFirstAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
};

export type QueryFindManyAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
};

export type QueryFindManyAutomationRunActionCountArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
};

export type QueryAggregateAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueChannelMessageReactionArgs = {
  where: ChannelMessageReactionWhereUniqueInput;
};

export type QueryFindFirstChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
};

export type QueryFindManyChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
};

export type QueryFindManyChannelMessageReactionCountArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
};

export type QueryAggregateChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueVaccinationArgs = {
  where: VaccinationWhereUniqueInput;
};

export type QueryFindFirstVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
};

export type QueryFindManyVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
};

export type QueryFindManyVaccinationCountArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
};

export type QueryAggregateVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueMassTextAlertArgs = {
  where: MassTextAlertWhereUniqueInput;
};

export type QueryFindFirstMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
};

export type QueryFindManyMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
};

export type QueryFindManyMassTextAlertCountArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
};

export type QueryAggregateMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueMassTextAlertEntryArgs = {
  where: MassTextAlertEntryWhereUniqueInput;
};

export type QueryFindFirstMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
};

export type QueryFindManyMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
};

export type QueryFindManyMassTextAlertEntryCountArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
};

export type QueryAggregateMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueMassTextAlertEntryAppointmentArgs = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type QueryFindFirstMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
};

export type QueryFindManyMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
};

export type QueryFindManyMassTextAlertEntryAppointmentCountArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
};

export type QueryAggregateMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniquePpcIntegrationArgs = {
  where: PpcIntegrationWhereUniqueInput;
};

export type QueryFindFirstPpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyPpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
};

export type QueryFindManyPpcIntegrationCountArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
};

export type QueryAggregatePpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniquePetPortalSettingArgs = {
  where: PetPortalSettingWhereUniqueInput;
};

export type QueryFindFirstPetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
};

export type QueryFindManyPetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
};

export type QueryFindManyPetPortalSettingCountArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
};

export type QueryAggregatePetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniquePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
};

export type QueryFindFirstPrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
};

export type QueryFindManyPrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
};

export type QueryFindManyPrescriptionCountArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
};

export type QueryAggregatePrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueClinicPhoneNumberArgs = {
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type QueryFindFirstClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
};

export type QueryFindManyClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
};

export type QueryFindManyClinicPhoneNumberCountArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
};

export type QueryAggregateClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGetEmailCampaignLoginUrlArgs = {
  data?: Maybe<ConfigureEmailCampaignsInput>;
};

export type QueryGetAvailableFormTemplatesForWorkflowsArgs = {
  where?: Maybe<FormTemplateWhereInput>;
};

export type QueryGetPossiblyDeflectedFormSubmissionArgs = {
  data?: Maybe<FormSubmissionWhereInput>;
};

export type QueryCheckClinicCareStatusArgs = {
  data: CheckClinicCareInput;
};

export type QueryCalculatePetBenefitUsageArgs = {
  where?: Maybe<CalculatePetBenefitUsageInput>;
};

export type QueryCalculateMultiplePetsBenefitUsageArgs = {
  where?: Maybe<CalculateMultiplePetsBenefitUsageInput>;
};

export type QueryChannelExportArgs = {
  where: ChannelExportWhereInput;
};

export type QueryFormSubmissionExportArgs = {
  where: FormSubmissionWhereUniqueInput;
};

export type QuerySavedChannelFilterSelectionsArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindClinicContactInfoArgs = {
  clinicId: Scalars['String'];
};

export type QueryWidgetClinicPetParentLookup2Args = {
  where: WidgetClinicPetParentLookupInput;
};

export type QueryGetOrCreateActiveChannelArgs = {
  data: GetOrCreateActiveChannelInput;
};

export type QueryGetDirectBookingAppointmentTypesArgs = {
  data: DirectBookingAppointmentTypeInput;
};

export type QueryGetDirectBookingOpenTimeSlotsArgs = {
  data: DirectBookingOpenTimeSlotInput;
};

export type QueryGetClinicDirectBookingSettingsArgs = {
  data?: Maybe<ClinicDirectBookingSettingsInput>;
};

export type QueryGetInvoiceByIdArgs = {
  where: InvoiceWhereUniqueInput;
};

export type QueryGetInvoicesForClientArgs = {
  where: InvoiceWhereInput;
  orderBy?: Maybe<Array<InvoiceOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryGetInvoicesForClientsArgs = {
  where: InvoiceWhereInput;
  orderBy?: Maybe<Array<InvoiceOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryEstimateNumberOfPetParentsReachedArgs = {
  clinicId: Scalars['String'];
  appointmentDateTimeFrom: Scalars['String'];
  appointmentDateTimeTo: Scalars['String'];
  alertBody: Scalars['String'];
};

export type QueryMassTextAlertReportArgs = {
  massTextAlertId: Scalars['String'];
};

export type QueryClinicPetSearchArgs = {
  where?: Maybe<SearchClinicPetInput>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryClinicPetParentSearchArgs = {
  where?: Maybe<SearchClinicPetParentInput>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryChannelSearchArgs = {
  where?: Maybe<SearchChannelInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
};

export type QueryGetStripePaymentIntentDataArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};

export type QueryGetBalanceForClientArgs = {
  where: GetBalanceForClientInput;
};

export type QuerySendPetParentReceiptArgs = {
  data: SendPetParentReceiptInput;
};

export type QuerySendConsultationReceiptArgs = {
  data: SendConsultationReceiptInput;
};

export type QuerySendVetSubscriptionReceiptArgs = {
  data: SendVetSubscriptionReceiptInput;
};

export type QueryGetTerminalTokenArgs = {
  where: StripeTerminalLocationUniqueInput;
};

export type QueryTokenValidationArgs = {
  data: TokenValidationInput;
};

export type QueryGetTwilioNumbersArgs = {
  data?: Maybe<GetTwilioNumbersInput>;
};

export type QuerySearchTwilioNumbersArgs = {
  data: SearchTwilioNumbersInput;
};

export type QueryTwilioCapabilityTokenArgs = {
  type?: Maybe<TwilioCapabilityType>;
};

export type QueryVerificationRequestArgs = {
  data?: Maybe<VerificationRequestInput>;
};

export type QueryVerificationCheckArgs = {
  data?: Maybe<VerificationCheckInput>;
};

export type QueryCheckPhoneVerificationArgs = {
  data: CheckPhoneVerificationInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export enum RecordingControlStatus {
  Paused = 'Paused',
  InProgress = 'InProgress',
  Stopped = 'Stopped',
}

export enum RecordingDownloadStatus {
  NotStarted = 'NotStarted',
  Pending = 'Pending',
  InProgress = 'InProgress',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type RedeemOneTimeCodeInput = {
  code: Scalars['String'];
  trusted: Scalars['Boolean'];
};

export type RedeemOneTimeCodeResult = {
  __typename?: 'RedeemOneTimeCodeResult';
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export enum RefundReason {
  RequestedByCustomer = 'Requested_By_Customer',
  Fraudulent = 'Fraudulent',
  Duplicate = 'Duplicate',
  PartialRefund = 'Partial_Refund',
  Other = 'Other',
}

export type RemoveUserFromClinicInput = {
  userId: Scalars['String'];
  clinicId: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  reportId: Scalars['String'];
  reportName: Scalars['String'];
};

export type RequestOneTimeCodeInput = {
  method: RequestOneTimeCodeMethod;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export enum RequestOneTimeCodeMethod {
  Email = 'EMAIL',
  Text = 'TEXT',
}

export type RequestOneTimeCodeResult = {
  __typename?: 'RequestOneTimeCodeResult';
  success: Scalars['Boolean'];
};

export type ResendUserInvitationInput = {
  email: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Review = {
  __typename?: 'Review';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  reviewTags: Array<ReviewTag>;
  _count: ReviewCountOutputType;
};

/** @deprecated - 1.0 */
export type ReviewReviewTagsArgs = {
  where?: Maybe<ReviewTagWhereInput>;
  orderBy?: Maybe<ReviewTagOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ReviewTagWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ReviewTagScalarFieldEnum>;
};

export type ReviewAvgAggregateOutputType = {
  __typename?: 'ReviewAvgAggregateOutputType';
  rating?: Maybe<Scalars['Float']>;
  ratingScale?: Maybe<Scalars['Float']>;
};

export type ReviewAvgOrderByAggregateInput = {
  rating?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
};

export type ReviewCountAggregateOutputType = {
  __typename?: 'ReviewCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  rating: Scalars['Int'];
  ratingDisplay: Scalars['Int'];
  ratingScale: Scalars['Int'];
  relationshipId: Scalars['Int'];
  reviewType: Scalars['Int'];
  feedbackMessage: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ReviewCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewCountOutputType = {
  __typename?: 'ReviewCountOutputType';
  reviewTags: Scalars['Int'];
};

export type ReviewCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutReviewsInput>;
  reviewTags?: Maybe<ReviewTagCreateNestedManyWithoutReviewsInput>;
};

export type ReviewCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
};

export type ReviewCreateManyUserInputEnvelope = {
  data: Array<ReviewCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReviewCreateNestedManyWithoutReviewTagsInput = {
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewCreateOrConnectWithoutReviewTagsInput = {
  where: ReviewWhereUniqueInput;
  create: ReviewUncheckedCreateWithoutReviewTagsInput;
};

export type ReviewCreateOrConnectWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  create: ReviewUncheckedCreateWithoutUserInput;
};

export type ReviewCreateWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutReviewsInput>;
};

export type ReviewCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagCreateNestedManyWithoutReviewsInput>;
};

export type ReviewListRelationFilter = {
  every?: Maybe<ReviewWhereInput>;
  some?: Maybe<ReviewWhereInput>;
  none?: Maybe<ReviewWhereInput>;
};

export type ReviewMaxAggregateOutputType = {
  __typename?: 'ReviewMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewMinAggregateOutputType = {
  __typename?: 'ReviewMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ReviewOrderByRelevanceFieldEnum {
  Id = 'id',
  RatingDisplay = 'ratingDisplay',
  RelationshipId = 'relationshipId',
  ReviewType = 'reviewType',
  FeedbackMessage = 'feedbackMessage',
  UserId = 'userId',
}

export type ReviewOrderByRelevanceInput = {
  fields: Array<ReviewOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ReviewOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<ReviewCountOrderByAggregateInput>;
  _avg?: Maybe<ReviewAvgOrderByAggregateInput>;
  _max?: Maybe<ReviewMaxOrderByAggregateInput>;
  _min?: Maybe<ReviewMinOrderByAggregateInput>;
  _sum?: Maybe<ReviewSumOrderByAggregateInput>;
};

export type ReviewOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  reviewTags?: Maybe<ReviewTagOrderByRelationAggregateInput>;
  _relevance?: Maybe<ReviewOrderByRelevanceInput>;
};

export enum ReviewScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Rating = 'rating',
  RatingDisplay = 'ratingDisplay',
  RatingScale = 'ratingScale',
  RelationshipId = 'relationshipId',
  ReviewType = 'reviewType',
  FeedbackMessage = 'feedbackMessage',
  UserId = 'userId',
}

export type ReviewScalarWhereInput = {
  AND?: Maybe<Array<ReviewScalarWhereInput>>;
  OR?: Maybe<Array<ReviewScalarWhereInput>>;
  NOT?: Maybe<Array<ReviewScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  rating?: Maybe<IntFilter>;
  ratingDisplay?: Maybe<StringFilter>;
  ratingScale?: Maybe<IntFilter>;
  relationshipId?: Maybe<StringNullableFilter>;
  reviewType?: Maybe<StringFilter>;
  feedbackMessage?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type ReviewScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  rating?: Maybe<IntWithAggregatesFilter>;
  ratingDisplay?: Maybe<StringWithAggregatesFilter>;
  ratingScale?: Maybe<IntWithAggregatesFilter>;
  relationshipId?: Maybe<StringNullableWithAggregatesFilter>;
  reviewType?: Maybe<StringWithAggregatesFilter>;
  feedbackMessage?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ReviewSumAggregateOutputType = {
  __typename?: 'ReviewSumAggregateOutputType';
  rating?: Maybe<Scalars['Int']>;
  ratingScale?: Maybe<Scalars['Int']>;
};

export type ReviewSumOrderByAggregateInput = {
  rating?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
};

/** @deprecated - 1.0 */
export type ReviewTag = {
  __typename?: 'ReviewTag';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews: Array<Review>;
  _count: ReviewTagCountOutputType;
};

/** @deprecated - 1.0 */
export type ReviewTagReviewsArgs = {
  where?: Maybe<ReviewWhereInput>;
  orderBy?: Maybe<ReviewOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ReviewWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ReviewScalarFieldEnum>;
};

export type ReviewTagCountAggregateOutputType = {
  __typename?: 'ReviewTagCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ReviewTagCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ReviewTagCountOutputType = {
  __typename?: 'ReviewTagCountOutputType';
  reviews: Scalars['Int'];
};

export type ReviewTagCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews?: Maybe<ReviewCreateNestedManyWithoutReviewTagsInput>;
};

export type ReviewTagCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type ReviewTagCreateNestedManyWithoutReviewsInput = {
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
};

export type ReviewTagCreateOrConnectWithoutReviewsInput = {
  where: ReviewTagWhereUniqueInput;
  create: ReviewTagUncheckedCreateWithoutReviewsInput;
};

export type ReviewTagCreateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type ReviewTagListRelationFilter = {
  every?: Maybe<ReviewTagWhereInput>;
  some?: Maybe<ReviewTagWhereInput>;
  none?: Maybe<ReviewTagWhereInput>;
};

export type ReviewTagMaxAggregateOutputType = {
  __typename?: 'ReviewTagMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ReviewTagMinAggregateOutputType = {
  __typename?: 'ReviewTagMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type ReviewTagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ReviewTagOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
}

export type ReviewTagOrderByRelevanceInput = {
  fields: Array<ReviewTagOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ReviewTagOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<ReviewTagCountOrderByAggregateInput>;
  _max?: Maybe<ReviewTagMaxOrderByAggregateInput>;
  _min?: Maybe<ReviewTagMinOrderByAggregateInput>;
};

export type ReviewTagOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  reviews?: Maybe<ReviewOrderByRelationAggregateInput>;
  _relevance?: Maybe<ReviewTagOrderByRelevanceInput>;
};

export enum ReviewTagScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
  Name = 'name',
}

export type ReviewTagScalarWhereInput = {
  AND?: Maybe<Array<ReviewTagScalarWhereInput>>;
  OR?: Maybe<Array<ReviewTagScalarWhereInput>>;
  NOT?: Maybe<Array<ReviewTagScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
};

export type ReviewTagScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export type ReviewTagUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutReviewTagsInput>;
};

export type ReviewTagUncheckedCreateNestedManyWithoutReviewsInput = {
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
};

export type ReviewTagUncheckedCreateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type ReviewTagUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutReviewTagsInput>;
};

export type ReviewTagUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUncheckedUpdateManyWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUncheckedUpdateManyWithoutReviewsInput = {
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  upsert?: Maybe<Array<ReviewTagUpsertWithWhereUniqueWithoutReviewsInput>>;
  set?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  update?: Maybe<Array<ReviewTagUpdateWithWhereUniqueWithoutReviewsInput>>;
  updateMany?: Maybe<Array<ReviewTagUpdateManyWithWhereWithoutReviewsInput>>;
  deleteMany?: Maybe<Array<ReviewTagScalarWhereInput>>;
};

export type ReviewTagUncheckedUpdateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reviews?: Maybe<ReviewUpdateManyWithoutReviewTagsInput>;
};

export type ReviewTagUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUpdateManyWithWhereWithoutReviewsInput = {
  where: ReviewTagScalarWhereInput;
  data: ReviewTagUncheckedUpdateManyWithoutReviewTagsInput;
};

export type ReviewTagUpdateManyWithoutReviewsInput = {
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  upsert?: Maybe<Array<ReviewTagUpsertWithWhereUniqueWithoutReviewsInput>>;
  set?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  update?: Maybe<Array<ReviewTagUpdateWithWhereUniqueWithoutReviewsInput>>;
  updateMany?: Maybe<Array<ReviewTagUpdateManyWithWhereWithoutReviewsInput>>;
  deleteMany?: Maybe<Array<ReviewTagScalarWhereInput>>;
};

export type ReviewTagUpdateWithWhereUniqueWithoutReviewsInput = {
  where: ReviewTagWhereUniqueInput;
  data: ReviewTagUncheckedUpdateWithoutReviewsInput;
};

export type ReviewTagUpdateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewTagUpsertWithWhereUniqueWithoutReviewsInput = {
  where: ReviewTagWhereUniqueInput;
  update: ReviewTagUncheckedUpdateWithoutReviewsInput;
  create: ReviewTagUncheckedCreateWithoutReviewsInput;
};

export type ReviewTagWhereInput = {
  AND?: Maybe<Array<ReviewTagWhereInput>>;
  OR?: Maybe<Array<ReviewTagWhereInput>>;
  NOT?: Maybe<Array<ReviewTagWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
};

export type ReviewTagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedCreateNestedManyWithoutReviewsInput>;
};

export type ReviewUncheckedCreateNestedManyWithoutReviewTagsInput = {
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewUncheckedCreateWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  feedbackMessage?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedCreateNestedManyWithoutReviewsInput>;
};

export type ReviewUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedUpdateManyWithoutReviewsInput>;
};

export type ReviewUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateManyWithoutReviewTagsInput = {
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutReviewTagsInput>>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutReviewTagsInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutReviewTagsInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUncheckedUpdateManyWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUncheckedUpdateWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedUpdateManyWithoutReviewsInput>;
};

export type ReviewUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneWithoutReviewsInput>;
  reviewTags?: Maybe<ReviewTagUpdateManyWithoutReviewsInput>;
};

export type ReviewUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
};

export type ReviewUpdateManyWithWhereWithoutReviewTagsInput = {
  where: ReviewScalarWhereInput;
  data: ReviewUncheckedUpdateManyWithoutReviewsInput;
};

export type ReviewUpdateManyWithWhereWithoutUserInput = {
  where: ReviewScalarWhereInput;
  data: ReviewUncheckedUpdateManyWithoutReviewsInput;
};

export type ReviewUpdateManyWithoutReviewTagsInput = {
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutReviewTagsInput>>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutReviewTagsInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutReviewTagsInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUpdateWithWhereUniqueWithoutReviewTagsInput = {
  where: ReviewWhereUniqueInput;
  data: ReviewUncheckedUpdateWithoutReviewTagsInput;
};

export type ReviewUpdateWithWhereUniqueWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  data: ReviewUncheckedUpdateWithoutUserInput;
};

export type ReviewUpdateWithoutReviewTagsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneWithoutReviewsInput>;
};

export type ReviewUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUpdateManyWithoutReviewsInput>;
};

export type ReviewUpsertWithWhereUniqueWithoutReviewTagsInput = {
  where: ReviewWhereUniqueInput;
  update: ReviewUncheckedUpdateWithoutReviewTagsInput;
  create: ReviewUncheckedCreateWithoutReviewTagsInput;
};

export type ReviewUpsertWithWhereUniqueWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  update: ReviewUncheckedUpdateWithoutUserInput;
  create: ReviewUncheckedCreateWithoutUserInput;
};

export type ReviewWhereInput = {
  AND?: Maybe<Array<ReviewWhereInput>>;
  OR?: Maybe<Array<ReviewWhereInput>>;
  NOT?: Maybe<Array<ReviewWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  rating?: Maybe<IntFilter>;
  ratingDisplay?: Maybe<StringFilter>;
  ratingScale?: Maybe<IntFilter>;
  relationshipId?: Maybe<StringNullableFilter>;
  reviewType?: Maybe<StringFilter>;
  feedbackMessage?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserWhereInput>;
  reviewTags?: Maybe<ReviewTagListRelationFilter>;
};

export type ReviewWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Vet = 'VET',
  Head = 'HEAD',
  Rec = 'REC',
  Pm = 'PM',
  Nurse = 'NURSE',
  Technician = 'TECHNICIAN',
}

export enum RuleActions {
  UpdateInactiveChannelStatus = 'UpdateInactiveChannelStatus',
}

export type Rules = {
  __typename?: 'Rules';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  action: RuleActions;
  options: Scalars['Json'];
  clinicId: Scalars['String'];
  clinic: Clinic;
};

export type RulesCountAggregateOutputType = {
  __typename?: 'RulesCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  action: Scalars['Int'];
  options: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type RulesCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type RulesCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutRulesInput;
};

export type RulesCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesCreateManyClinicInputEnvelope = {
  data: Array<RulesCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RulesCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
};

export type RulesCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
};

export type RulesCreateOrConnectWithoutClinicInput = {
  where: RulesWhereUniqueInput;
  create: RulesUncheckedCreateWithoutClinicInput;
};

export type RulesCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesListRelationFilter = {
  every?: Maybe<RulesWhereInput>;
  some?: Maybe<RulesWhereInput>;
  none?: Maybe<RulesWhereInput>;
};

export type RulesMaxAggregateOutputType = {
  __typename?: 'RulesMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  clinicId?: Maybe<Scalars['String']>;
};

export type RulesMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type RulesMinAggregateOutputType = {
  __typename?: 'RulesMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  clinicId?: Maybe<Scalars['String']>;
};

export type RulesMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type RulesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum RulesOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
}

export type RulesOrderByRelevanceInput = {
  fields: Array<RulesOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type RulesOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<RulesCountOrderByAggregateInput>;
  _max?: Maybe<RulesMaxOrderByAggregateInput>;
  _min?: Maybe<RulesMinOrderByAggregateInput>;
};

export type RulesOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<RulesOrderByRelevanceInput>;
};

export enum RulesScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Action = 'action',
  Options = 'options',
  ClinicId = 'clinicId',
}

export type RulesScalarWhereInput = {
  AND?: Maybe<Array<RulesScalarWhereInput>>;
  OR?: Maybe<Array<RulesScalarWhereInput>>;
  NOT?: Maybe<Array<RulesScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  action?: Maybe<EnumRuleActionsFilter>;
  options?: Maybe<JsonFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type RulesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  action?: Maybe<EnumRuleActionsWithAggregatesFilter>;
  options?: Maybe<JsonWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export type RulesUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinicId: Scalars['String'];
};

export type RulesUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
};

export type RulesUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type RulesUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type RulesUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<RulesUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<RulesWhereUniqueInput>>;
  disconnect?: Maybe<Array<RulesWhereUniqueInput>>;
  delete?: Maybe<Array<RulesWhereUniqueInput>>;
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
  update?: Maybe<Array<RulesUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<RulesUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<RulesScalarWhereInput>>;
};

export type RulesUncheckedUpdateManyWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutRulesInput>;
};

export type RulesUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUpdateManyWithWhereWithoutClinicInput = {
  where: RulesScalarWhereInput;
  data: RulesUncheckedUpdateManyWithoutRulesInput;
};

export type RulesUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<RulesUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<RulesWhereUniqueInput>>;
  disconnect?: Maybe<Array<RulesWhereUniqueInput>>;
  delete?: Maybe<Array<RulesWhereUniqueInput>>;
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
  update?: Maybe<Array<RulesUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<RulesUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<RulesScalarWhereInput>>;
};

export type RulesUpdateWithWhereUniqueWithoutClinicInput = {
  where: RulesWhereUniqueInput;
  data: RulesUncheckedUpdateWithoutClinicInput;
};

export type RulesUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  action?: Maybe<RuleActions>;
  options?: Maybe<Scalars['Json']>;
};

export type RulesUpsertWithWhereUniqueWithoutClinicInput = {
  where: RulesWhereUniqueInput;
  update: RulesUncheckedUpdateWithoutClinicInput;
  create: RulesUncheckedCreateWithoutClinicInput;
};

export type RulesWhereInput = {
  AND?: Maybe<Array<RulesWhereInput>>;
  OR?: Maybe<Array<RulesWhereInput>>;
  NOT?: Maybe<Array<RulesWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  action?: Maybe<EnumRuleActionsFilter>;
  options?: Maybe<JsonFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type RulesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SearchChannelInput = {
  id?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Scalars['String']>>;
  clinicId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isUnassigned?: Maybe<Scalars['Boolean']>;
  petNames?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  petParentNames?: Maybe<Scalars['String']>;
  userNames?: Maybe<Scalars['String']>;
  assigneeIds?: Maybe<Array<Scalars['String']>>;
  assigneeNames?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  messageBodies?: Maybe<Scalars['String']>;
  channelStatusIds?: Maybe<Array<Scalars['String']>>;
  channelStatusName?: Maybe<Scalars['String']>;
  channelStatusAction?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['String']>;
  appointmentTypeIds?: Maybe<Array<Scalars['String']>>;
  appointmentRoomIds?: Maybe<Array<Scalars['String']>>;
  appointmentClinicEmployeeIds?: Maybe<Array<Scalars['String']>>;
  tagNames?: Maybe<Array<Scalars['String']>>;
};

export type SearchClinicPetInput = {
  name?: Maybe<Scalars['String']>;
  petParentNames?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type SearchClinicPetParentInput = {
  fullName?: Maybe<Scalars['String']>;
  petNames?: Maybe<Scalars['String']>;
  petPimsIds?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  and?: Maybe<Array<Maybe<SearchClinicPetParentInput>>>;
  or?: Maybe<Array<Maybe<SearchClinicPetParentInput>>>;
};

export type SearchTwilioNumbersInput = {
  areaCode?: Maybe<Scalars['String']>;
};

export type SendClinicPetParentAuthLinkRequest = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  isDeepLink?: Maybe<Scalars['Boolean']>;
};

export type SendConsultationReceiptInput = {
  consultationId: Scalars['String'];
  firebaseId: Scalars['String'];
  emails: Array<Maybe<Scalars['String']>>;
};

export type SendEnrollmentEmailInput = {
  careStripeSubscriptionId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type SendEnrollmentEmailOutput = {
  __typename?: 'SendEnrollmentEmailOutput';
  success: Scalars['Boolean'];
};

export type SendPaymentToTerminalResponse = {
  __typename?: 'SendPaymentToTerminalResponse';
  terminal: StripeTerminal;
  success: Scalars['Boolean'];
};

export type SendPetParentReceiptInput = {
  consultationId: Scalars['String'];
  firebaseId: Scalars['String'];
};

export type SendWidgetCodeToDeveloperInput = {
  emailAddress: Scalars['String'];
  ccEmailAddresses?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings: Array<ServiceReminderTiming>;
  reminders: Array<ServiceReminder>;
  isReminderActive: Scalars['Boolean'];
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegration;
  formTemplate?: Maybe<FormTemplate>;
  isDeleted: Scalars['Boolean'];
  hasConfiguredReminders: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems: Array<InvoiceLineItem>;
  organizationCareBenefitToClinicService: Array<OrganizationCareBenefitToClinicService>;
  draftCareBenefitUsageToInvoiceLineItems: Array<CareBenefitUsageToInvoiceLineItem>;
  prescriptions: Array<Prescription>;
  _count: ServiceCountOutputType;
};

export type ServiceReminderTimingsArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
  orderBy?: Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderTimingScalarFieldEnum>;
};

export type ServiceRemindersArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
};

export type ServiceInvoiceLineItemsArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
};

export type ServiceOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>;
};

export type ServiceDraftCareBenefitUsageToInvoiceLineItemsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};

export type ServicePrescriptionsArgs = {
  where?: Maybe<PrescriptionWhereInput>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
};

export enum ServiceCapability {
  Sms = 'SMS',
  Voice = 'Voice',
  Mms = 'MMS',
}

export type ServiceCodeIntegrationIdCompoundUniqueInput = {
  code: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ServiceCountAggregateOutputType = {
  __typename?: 'ServiceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  code: Scalars['Int'];
  pimsName: Scalars['Int'];
  pimsId: Scalars['Int'];
  isReminderActive: Scalars['Int'];
  integrationId: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  hasConfiguredReminders: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ServiceCountOutputType = {
  __typename?: 'ServiceCountOutputType';
  reminderTimings: Scalars['Int'];
  reminders: Scalars['Int'];
  invoiceLineItems: Scalars['Int'];
  organizationCareBenefitToClinicService: Scalars['Int'];
  draftCareBenefitUsageToInvoiceLineItems: Scalars['Int'];
  prescriptions: Scalars['Int'];
};

export type ServiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateManyFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceCreateManyFormTemplateInputEnvelope = {
  data: Array<ServiceCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  isReminderActive?: Maybe<Scalars['Boolean']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceCreateManyIntegrationInputEnvelope = {
  data: Array<ServiceCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutInvoiceLineItemsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutInvoiceLineItemsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutPrescriptionsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutPrescriptionsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutReminderTimingsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutReminderTimingsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutReminderTimingsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateNestedOneWithoutRemindersInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutRemindersInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutRemindersInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
};

export type ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
};

export type ServiceCreateOrConnectWithoutFormTemplateInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutFormTemplateInput;
};

export type ServiceCreateOrConnectWithoutIntegrationInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutIntegrationInput;
};

export type ServiceCreateOrConnectWithoutInvoiceLineItemsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutInvoiceLineItemsInput;
};

export type ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type ServiceCreateOrConnectWithoutPrescriptionsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutPrescriptionsInput;
};

export type ServiceCreateOrConnectWithoutReminderTimingsInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutReminderTimingsInput;
};

export type ServiceCreateOrConnectWithoutRemindersInput = {
  where: ServiceWhereUniqueInput;
  create: ServiceUncheckedCreateWithoutRemindersInput;
};

export type ServiceCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput
  >;
};

export type ServiceCreateWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
};

export type ServiceCreateWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
};

export type ServiceListRelationFilter = {
  every?: Maybe<ServiceWhereInput>;
  some?: Maybe<ServiceWhereInput>;
  none?: Maybe<ServiceWhereInput>;
};

export type ServiceMaxAggregateOutputType = {
  __typename?: 'ServiceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
};

export type ServiceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
};

export type ServiceMinAggregateOutputType = {
  __typename?: 'ServiceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
};

export type ServiceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
};

export type ServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  Code = 'code',
  PimsName = 'pimsName',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  FormTemplateId = 'formTemplateId',
}

export type ServiceOrderByRelevanceInput = {
  fields: Array<ServiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ServiceCountOrderByAggregateInput>;
  _max?: Maybe<ServiceMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceMinOrderByAggregateInput>;
};

export type ServiceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  reminderTimings?: Maybe<ServiceReminderTimingOrderByRelationAggregateInput>;
  reminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  isReminderActive?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  isDeleted?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  invoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  _relevance?: Maybe<ServiceOrderByRelevanceInput>;
};

export type ServicePimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export enum ServiceProvider {
  Bandwidth = 'Bandwidth',
  Twilio = 'Twilio',
}

export type ServiceRelationFilter = {
  is?: Maybe<ServiceWhereInput>;
  isNot?: Maybe<ServiceWhereInput>;
};

export type ServiceReminder = {
  __typename?: 'ServiceReminder';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  serviceId: Scalars['String'];
  service: Service;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicPet: ClinicPet;
  clinicPetParent: ClinicPetParent;
  notifications: Array<ServiceReminderNotification>;
  hasResponded: Scalars['Boolean'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  _count: ServiceReminderCountOutputType;
};

export type ServiceReminderNotificationsArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
};

export type ServiceReminderChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};

export type ServiceReminderChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};

export type ServiceReminderAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type ServiceReminderCountAggregateOutputType = {
  __typename?: 'ServiceReminderCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  serviceId: Scalars['Int'];
  dueDate: Scalars['Int'];
  pimsId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  hasResponded: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceReminderCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type ServiceReminderCountOutputType = {
  __typename?: 'ServiceReminderCountOutputType';
  notifications: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
};

export type ServiceReminderCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet: ClinicPetCreateNestedOneWithoutServiceRemindersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParentId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyClinicPetInputEnvelope = {
  data: Array<ServiceReminderCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyClinicPetParentInputEnvelope = {
  data: Array<ServiceReminderCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderCreateManyServiceInputEnvelope = {
  data: Array<ServiceReminderCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
};

export type ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
};

export type ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
};

export type ServiceReminderCreateNestedOneWithoutNotificationsInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutNotificationsInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
};

export type ServiceReminderCreateOrConnectWithoutAutomationRunsInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutAutomationRunsInput;
};

export type ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ServiceReminderCreateOrConnectWithoutClinicPetInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutClinicPetInput;
};

export type ServiceReminderCreateOrConnectWithoutClinicPetParentInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutClinicPetParentInput;
};

export type ServiceReminderCreateOrConnectWithoutNotificationsInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutNotificationsInput;
};

export type ServiceReminderCreateOrConnectWithoutServiceInput = {
  where: ServiceReminderWhereUniqueInput;
  create: ServiceReminderUncheckedCreateWithoutServiceInput;
};

export type ServiceReminderCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet: ClinicPetCreateNestedOneWithoutServiceRemindersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet: ClinicPetCreateNestedOneWithoutServiceRemindersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet: ClinicPetCreateNestedOneWithoutServiceRemindersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet: ClinicPetCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet: ClinicPetCreateNestedOneWithoutServiceRemindersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutServiceRemindersInput;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet: ClinicPetCreateNestedOneWithoutServiceRemindersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutServiceRemindersInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderListRelationFilter = {
  every?: Maybe<ServiceReminderWhereInput>;
  some?: Maybe<ServiceReminderWhereInput>;
  none?: Maybe<ServiceReminderWhereInput>;
};

export type ServiceReminderMaxAggregateOutputType = {
  __typename?: 'ServiceReminderMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
};

export type ServiceReminderMinAggregateOutputType = {
  __typename?: 'ServiceReminderMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
};

export type ServiceReminderNotification = {
  __typename?: 'ServiceReminderNotification';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  serviceReminderId: Scalars['String'];
  serviceReminder: ServiceReminder;
  timingId?: Maybe<Scalars['String']>;
  timing?: Maybe<ServiceReminderTiming>;
  formSubmissionId?: Maybe<Scalars['String']>;
  formSubmission?: Maybe<FormSubmission>;
  hasResponded: Scalars['Boolean'];
  queueAt: Scalars['DateTime'];
  queued: Scalars['Boolean'];
  notificationType: ServiceReminderNotificationType;
  bundledNotifications: Array<ServiceReminderNotification>;
  parentNotificationId?: Maybe<Scalars['String']>;
  parentNotification?: Maybe<ServiceReminderNotification>;
  _count: ServiceReminderNotificationCountOutputType;
};

export type ServiceReminderNotificationBundledNotificationsArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
};

export type ServiceReminderNotificationCountAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  timingId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  hasResponded: Scalars['Int'];
  queueAt: Scalars['Int'];
  queued: Scalars['Int'];
  notificationType: Scalars['Int'];
  parentNotificationId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceReminderNotificationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationCountOutputType = {
  __typename?: 'ServiceReminderNotificationCountOutputType';
  bundledNotifications: Scalars['Int'];
};

export type ServiceReminderNotificationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateManyFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationCreateManyParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
};

export type ServiceReminderNotificationCreateManyParentNotificationInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyParentNotificationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationCreateManyServiceReminderInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationCreateManyTimingInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyTimingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutTimingInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput = {
  create?: Maybe<ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput>;
  connect?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
};

export type ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutTimingInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutTimingInput;
};

export type ServiceReminderNotificationCreateWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateWithoutParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateWithoutTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationListRelationFilter = {
  every?: Maybe<ServiceReminderNotificationWhereInput>;
  some?: Maybe<ServiceReminderNotificationWhereInput>;
  none?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type ServiceReminderNotificationMaxAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationMinAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderNotificationOrderByRelevanceFieldEnum {
  Id = 'id',
  ServiceReminderId = 'serviceReminderId',
  TimingId = 'timingId',
  FormSubmissionId = 'formSubmissionId',
  ParentNotificationId = 'parentNotificationId',
}

export type ServiceReminderNotificationOrderByRelevanceInput = {
  fields: Array<ServiceReminderNotificationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceReminderNotificationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
  _count?: Maybe<ServiceReminderNotificationCountOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderNotificationMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderNotificationMinOrderByAggregateInput>;
};

export type ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  timingId?: Maybe<SortOrder>;
  timing?: Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissionId?: Maybe<SortOrder>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  hasResponded?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  bundledNotifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  parentNotificationId?: Maybe<SortOrder>;
  parentNotification?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<ServiceReminderNotificationOrderByRelevanceInput>;
};

export type ServiceReminderNotificationRelationFilter = {
  is?: Maybe<ServiceReminderNotificationWhereInput>;
  isNot?: Maybe<ServiceReminderNotificationWhereInput>;
};

export enum ServiceReminderNotificationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ServiceReminderId = 'serviceReminderId',
  TimingId = 'timingId',
  FormSubmissionId = 'formSubmissionId',
  HasResponded = 'hasResponded',
  QueueAt = 'queueAt',
  Queued = 'queued',
  NotificationType = 'notificationType',
  ParentNotificationId = 'parentNotificationId',
}

export type ServiceReminderNotificationScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceReminderId?: Maybe<StringFilter>;
  timingId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  hasResponded?: Maybe<BoolFilter>;
  queueAt?: Maybe<DateTimeFilter>;
  queued?: Maybe<BoolFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeFilter>;
  parentNotificationId?: Maybe<StringNullableFilter>;
};

export type ServiceReminderNotificationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringWithAggregatesFilter>;
  timingId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  hasResponded?: Maybe<BoolWithAggregatesFilter>;
  queueAt?: Maybe<DateTimeWithAggregatesFilter>;
  queued?: Maybe<BoolWithAggregatesFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeWithAggregatesFilter>;
  parentNotificationId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum ServiceReminderNotificationType {
  Digital = 'Digital',
  Postcard = 'Postcard',
}

export type ServiceReminderNotificationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId: Scalars['String'];
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationInput>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutTimingInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationInput>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationInput>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationInput>;
  parentNotificationId?: Maybe<Scalars['String']>;
};

export type ServiceReminderNotificationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput = {
  where: ServiceReminderNotificationScalarWhereInput;
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNotificationsInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput = {
  where: ServiceReminderNotificationScalarWhereInput;
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutBundledNotificationsInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput = {
  where: ServiceReminderNotificationScalarWhereInput;
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput = {
  where: ServiceReminderNotificationScalarWhereInput;
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput;
};

export type ServiceReminderNotificationUpdateManyWithoutFormSubmissionInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutParentNotificationInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutServiceReminderInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutTimingInput = {
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
};

export type ServiceReminderNotificationUpdateOneWithoutBundledNotificationsInput = {
  create?: Maybe<ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput>;
  upsert?: Maybe<ServiceReminderNotificationUpsertWithoutBundledNotificationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  update?: Maybe<ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  data: ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  data: ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  data: ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  data: ServiceReminderNotificationUncheckedUpdateWithoutTimingInput;
};

export type ServiceReminderNotificationUpdateWithoutBundledNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationUpdateWithoutFormSubmissionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationUpdateWithoutParentNotificationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationInput>;
};

export type ServiceReminderNotificationUpdateWithoutServiceReminderInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationUpdateWithoutTimingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationInput>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput = {
  where: ServiceReminderNotificationWhereUniqueInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutTimingInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutTimingInput;
};

export type ServiceReminderNotificationUpsertWithoutBundledNotificationsInput = {
  update: ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput;
  create: ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput;
};

export type ServiceReminderNotificationWhereInput = {
  AND?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceReminderId?: Maybe<StringFilter>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  timingId?: Maybe<StringNullableFilter>;
  timing?: Maybe<ServiceReminderTimingWhereInput>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  hasResponded?: Maybe<BoolFilter>;
  queueAt?: Maybe<DateTimeFilter>;
  queued?: Maybe<BoolFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeFilter>;
  bundledNotifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  parentNotificationId?: Maybe<StringNullableFilter>;
  parentNotification?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type ServiceReminderNotificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ServiceReminderOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderOrderByRelevanceFieldEnum {
  Id = 'id',
  ServiceId = 'serviceId',
  PimsId = 'pimsId',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
}

export type ServiceReminderOrderByRelevanceInput = {
  fields: Array<ServiceReminderOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceReminderOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<ServiceReminderCountOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderMinOrderByAggregateInput>;
};

export type ServiceReminderOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  dueDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  notifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  hasResponded?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  _relevance?: Maybe<ServiceReminderOrderByRelevanceInput>;
};

export type ServiceReminderRelationFilter = {
  is?: Maybe<ServiceReminderWhereInput>;
  isNot?: Maybe<ServiceReminderWhereInput>;
};

export enum ServiceReminderScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ServiceId = 'serviceId',
  DueDate = 'dueDate',
  PimsId = 'pimsId',
  IsDeleted = 'isDeleted',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  HasResponded = 'hasResponded',
  RawPimsValue = 'rawPimsValue',
}

export type ServiceReminderScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  dueDate?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicPetId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  hasResponded?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ServiceReminderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
  dueDate?: Maybe<DateTimeWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  hasResponded?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ServiceReminderTiming = {
  __typename?: 'ServiceReminderTiming';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  serviceId: Scalars['String'];
  service: Service;
  notifications: Array<ServiceReminderNotification>;
  timingType: ServiceReminderTimingType;
  offset: Scalars['Float'];
  offsetUnit: ServiceReminderTimingOffsetUnit;
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  isEmail: Scalars['Boolean'];
  isSms: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  _count: ServiceReminderTimingCountOutputType;
};

export type ServiceReminderTimingNotificationsArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
};

export type ServiceReminderTimingAvgAggregateOutputType = {
  __typename?: 'ServiceReminderTimingAvgAggregateOutputType';
  offset?: Maybe<Scalars['Float']>;
  hourOfDay?: Maybe<Scalars['Float']>;
  minuteOfHour?: Maybe<Scalars['Float']>;
};

export type ServiceReminderTimingAvgOrderByAggregateInput = {
  offset?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
};

export type ServiceReminderTimingCountAggregateOutputType = {
  __typename?: 'ServiceReminderTimingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  serviceId: Scalars['Int'];
  timingType: Scalars['Int'];
  offset: Scalars['Int'];
  offsetUnit: Scalars['Int'];
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  isEmail: Scalars['Int'];
  isSms: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ServiceReminderTimingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ServiceReminderTimingCountOutputType = {
  __typename?: 'ServiceReminderTimingCountOutputType';
  notifications: Scalars['Int'];
};

export type ServiceReminderTimingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutReminderTimingsInput;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutTimingInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateManyServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateManyServiceInputEnvelope = {
  data: Array<ServiceReminderTimingCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
};

export type ServiceReminderTimingCreateNestedOneWithoutNotificationsInput = {
  create?: Maybe<ServiceReminderTimingUncheckedCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderTimingCreateOrConnectWithoutNotificationsInput>;
  connect?: Maybe<ServiceReminderTimingWhereUniqueInput>;
};

export type ServiceReminderTimingCreateOrConnectWithoutNotificationsInput = {
  where: ServiceReminderTimingWhereUniqueInput;
  create: ServiceReminderTimingUncheckedCreateWithoutNotificationsInput;
};

export type ServiceReminderTimingCreateOrConnectWithoutServiceInput = {
  where: ServiceReminderTimingWhereUniqueInput;
  create: ServiceReminderTimingUncheckedCreateWithoutServiceInput;
};

export type ServiceReminderTimingCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutReminderTimingsInput;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutTimingInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingInput = {
  hourOfDay: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  offset: Scalars['Float'];
  offsetUnit: Scalars['String'];
  timingType: ServiceReminderTimingType;
};

export type ServiceReminderTimingListRelationFilter = {
  every?: Maybe<ServiceReminderTimingWhereInput>;
  some?: Maybe<ServiceReminderTimingWhereInput>;
  none?: Maybe<ServiceReminderTimingWhereInput>;
};

export type ServiceReminderTimingMaxAggregateOutputType = {
  __typename?: 'ServiceReminderTimingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type ServiceReminderTimingMinAggregateOutputType = {
  __typename?: 'ServiceReminderTimingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingOffsetUnit {
  Months = 'Months',
  Weeks = 'Weeks',
  Days = 'Days',
}

export type ServiceReminderTimingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingOrderByRelevanceFieldEnum {
  Id = 'id',
  ServiceId = 'serviceId',
}

export type ServiceReminderTimingOrderByRelevanceInput = {
  fields: Array<ServiceReminderTimingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type ServiceReminderTimingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<ServiceReminderTimingCountOrderByAggregateInput>;
  _avg?: Maybe<ServiceReminderTimingAvgOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderTimingMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderTimingMinOrderByAggregateInput>;
  _sum?: Maybe<ServiceReminderTimingSumOrderByAggregateInput>;
};

export type ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  notifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  timingType?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _relevance?: Maybe<ServiceReminderTimingOrderByRelevanceInput>;
};

export type ServiceReminderTimingRelationFilter = {
  is?: Maybe<ServiceReminderTimingWhereInput>;
  isNot?: Maybe<ServiceReminderTimingWhereInput>;
};

export enum ServiceReminderTimingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ServiceId = 'serviceId',
  TimingType = 'timingType',
  Offset = 'offset',
  OffsetUnit = 'offsetUnit',
  HourOfDay = 'hourOfDay',
  MinuteOfHour = 'minuteOfHour',
  IsEmail = 'isEmail',
  IsSms = 'isSms',
  IsDeleted = 'isDeleted',
}

export type ServiceReminderTimingScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeFilter>;
  offset?: Maybe<FloatFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitFilter>;
  hourOfDay?: Maybe<IntFilter>;
  minuteOfHour?: Maybe<IntFilter>;
  isEmail?: Maybe<BoolFilter>;
  isSms?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type ServiceReminderTimingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeWithAggregatesFilter>;
  offset?: Maybe<FloatWithAggregatesFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  hourOfDay?: Maybe<IntWithAggregatesFilter>;
  minuteOfHour?: Maybe<IntWithAggregatesFilter>;
  isEmail?: Maybe<BoolWithAggregatesFilter>;
  isSms?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type ServiceReminderTimingSumAggregateOutputType = {
  __typename?: 'ServiceReminderTimingSumAggregateOutputType';
  offset?: Maybe<Scalars['Float']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
};

export type ServiceReminderTimingSumOrderByAggregateInput = {
  offset?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingType {
  BeforeDue = 'BeforeDue',
  PastDue = 'PastDue',
}

export type ServiceReminderTimingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
};

export type ServiceReminderTimingUncheckedCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay: Scalars['Int'];
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutTimingInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedUpdateManyWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
};

export type ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutTimingInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutReminderTimingsInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutTimingInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput = {
  where: ServiceReminderTimingScalarWhereInput;
  data: ServiceReminderTimingUncheckedUpdateManyWithoutReminderTimingsInput;
};

export type ServiceReminderTimingUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
};

export type ServiceReminderTimingUpdateOneWithoutNotificationsInput = {
  create?: Maybe<ServiceReminderTimingUncheckedCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderTimingCreateOrConnectWithoutNotificationsInput>;
  upsert?: Maybe<ServiceReminderTimingUpsertWithoutNotificationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  update?: Maybe<ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput>;
};

export type ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput = {
  where: ServiceReminderTimingWhereUniqueInput;
  data: ServiceReminderTimingUncheckedUpdateWithoutServiceInput;
};

export type ServiceReminderTimingUpdateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutReminderTimingsInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutTimingInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput = {
  where: ServiceReminderTimingWhereUniqueInput;
  update: ServiceReminderTimingUncheckedUpdateWithoutServiceInput;
  create: ServiceReminderTimingUncheckedCreateWithoutServiceInput;
};

export type ServiceReminderTimingUpsertWithoutNotificationsInput = {
  update: ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput;
  create: ServiceReminderTimingUncheckedCreateWithoutNotificationsInput;
};

export type ServiceReminderTimingWhereInput = {
  AND?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  OR?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  service?: Maybe<ServiceWhereInput>;
  notifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeFilter>;
  offset?: Maybe<FloatFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitFilter>;
  hourOfDay?: Maybe<IntFilter>;
  minuteOfHour?: Maybe<IntFilter>;
  isEmail?: Maybe<BoolFilter>;
  isSms?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type ServiceReminderTimingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ServiceReminderUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
};

export type ServiceReminderUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParentId: Scalars['String'];
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedCreateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  pimsId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderUncheckedUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderUncheckedUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUncheckedUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersInput>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutServiceRemindersInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceReminderUpdateManyWithWhereWithoutClinicPetInput = {
  where: ServiceReminderScalarWhereInput;
  data: ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput;
};

export type ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: ServiceReminderScalarWhereInput;
  data: ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput;
};

export type ServiceReminderUpdateManyWithWhereWithoutServiceInput = {
  where: ServiceReminderScalarWhereInput;
  data: ServiceReminderUncheckedUpdateManyWithoutRemindersInput;
};

export type ServiceReminderUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUpdateManyWithoutServiceInput = {
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutServiceInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
};

export type ServiceReminderUpdateOneRequiredWithoutNotificationsInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutNotificationsInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutNotificationsInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutNotificationsInput>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutNotificationsInput>;
};

export type ServiceReminderUpdateOneWithoutAutomationRunsInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutAutomationRunsInput>;
};

export type ServiceReminderUpdateOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type ServiceReminderUpdateOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: ServiceReminderWhereUniqueInput;
  data: ServiceReminderUncheckedUpdateWithoutClinicPetInput;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: ServiceReminderWhereUniqueInput;
  data: ServiceReminderUncheckedUpdateWithoutClinicPetParentInput;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutServiceInput = {
  where: ServiceReminderWhereUniqueInput;
  data: ServiceReminderUncheckedUpdateWithoutServiceInput;
};

export type ServiceReminderUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersInput>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutServiceRemindersInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersInput>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutServiceRemindersInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersInput>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutServiceRemindersInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersInput>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutServiceRemindersInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersInput>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutServiceRemindersInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpdateWithoutNotificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersInput>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutServiceRemindersInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpdateWithoutServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutServiceRemindersInput>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderInput>;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: ServiceReminderWhereUniqueInput;
  update: ServiceReminderUncheckedUpdateWithoutClinicPetInput;
  create: ServiceReminderUncheckedCreateWithoutClinicPetInput;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: ServiceReminderWhereUniqueInput;
  update: ServiceReminderUncheckedUpdateWithoutClinicPetParentInput;
  create: ServiceReminderUncheckedCreateWithoutClinicPetParentInput;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutServiceInput = {
  where: ServiceReminderWhereUniqueInput;
  update: ServiceReminderUncheckedUpdateWithoutServiceInput;
  create: ServiceReminderUncheckedCreateWithoutServiceInput;
};

export type ServiceReminderUpsertWithoutAutomationRunsInput = {
  update: ServiceReminderUncheckedUpdateWithoutAutomationRunsInput;
  create: ServiceReminderUncheckedCreateWithoutAutomationRunsInput;
};

export type ServiceReminderUpsertWithoutChannelAutomationStatusesInput = {
  update: ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type ServiceReminderUpsertWithoutChannelCreationSourcesInput = {
  update: ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type ServiceReminderUpsertWithoutNotificationsInput = {
  update: ServiceReminderUncheckedUpdateWithoutNotificationsInput;
  create: ServiceReminderUncheckedCreateWithoutNotificationsInput;
};

export type ServiceReminderWhereInput = {
  AND?: Maybe<Array<ServiceReminderWhereInput>>;
  OR?: Maybe<Array<ServiceReminderWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  serviceId?: Maybe<StringFilter>;
  service?: Maybe<ServiceWhereInput>;
  dueDate?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  clinicPetId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  notifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  hasResponded?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
};

export type ServiceReminderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ServiceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Name = 'name',
  Code = 'code',
  PimsName = 'pimsName',
  PimsId = 'pimsId',
  IsReminderActive = 'isReminderActive',
  IntegrationId = 'integrationId',
  FormTemplateId = 'formTemplateId',
  IsDeleted = 'isDeleted',
  HasConfiguredReminders = 'hasConfiguredReminders',
  RawPimsValue = 'rawPimsValue',
}

export type ServiceScalarWhereInput = {
  AND?: Maybe<Array<ServiceScalarWhereInput>>;
  OR?: Maybe<Array<ServiceScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  code?: Maybe<StringFilter>;
  pimsName?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  isReminderActive?: Maybe<BoolFilter>;
  integrationId?: Maybe<StringFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  hasConfiguredReminders?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type ServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  code?: Maybe<StringWithAggregatesFilter>;
  pimsName?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  isReminderActive?: Maybe<BoolWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  hasConfiguredReminders?: Maybe<BoolWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ServiceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateNestedManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
};

export type ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput
  >;
};

export type ServiceUncheckedCreateWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedCreateWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  code: Scalars['String'];
  pimsName: Scalars['String'];
  pimsId: Scalars['String'];
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId: Scalars['String'];
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceUncheckedUpdateManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUncheckedUpdateManyWithoutServicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput
  >;
};

export type ServiceUncheckedUpdateWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<
    OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceInput
  >;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceInput
  >;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceInput>;
};

export type ServiceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceInput>;
};

export type ServiceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type ServiceUpdateManyWithWhereWithoutFormTemplateInput = {
  where: ServiceScalarWhereInput;
  data: ServiceUncheckedUpdateManyWithoutServicesInput;
};

export type ServiceUpdateManyWithWhereWithoutIntegrationInput = {
  where: ServiceScalarWhereInput;
  data: ServiceUncheckedUpdateManyWithoutServicesInput;
};

export type ServiceUpdateManyWithoutFormTemplateInput = {
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutFormTemplateInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
};

export type ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  upsert?: Maybe<ServiceUpsertWithoutOrganizationCareBenefitToClinicServiceInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type ServiceUpdateOneRequiredWithoutReminderTimingsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutReminderTimingsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutReminderTimingsInput>;
  upsert?: Maybe<ServiceUpsertWithoutReminderTimingsInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutReminderTimingsInput>;
};

export type ServiceUpdateOneRequiredWithoutRemindersInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutRemindersInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutRemindersInput>;
  upsert?: Maybe<ServiceUpsertWithoutRemindersInput>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutRemindersInput>;
};

export type ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  upsert?: Maybe<ServiceUpsertWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type ServiceUpdateOneWithoutInvoiceLineItemsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutInvoiceLineItemsInput>;
  upsert?: Maybe<ServiceUpsertWithoutInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutInvoiceLineItemsInput>;
};

export type ServiceUpdateOneWithoutPrescriptionsInput = {
  create?: Maybe<ServiceUncheckedCreateWithoutPrescriptionsInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutPrescriptionsInput>;
  upsert?: Maybe<ServiceUpsertWithoutPrescriptionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ServiceWhereUniqueInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutPrescriptionsInput>;
};

export type ServiceUpdateWithWhereUniqueWithoutFormTemplateInput = {
  where: ServiceWhereUniqueInput;
  data: ServiceUncheckedUpdateWithoutFormTemplateInput;
};

export type ServiceUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: ServiceWhereUniqueInput;
  data: ServiceUncheckedUpdateWithoutIntegrationInput;
};

export type ServiceUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceInput>;
};

export type ServiceUpdateWithoutFormTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceInput>;
};

export type ServiceUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceInput>;
};

export type ServiceUpdateWithoutInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceInput>;
};

export type ServiceUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceInput>;
};

export type ServiceUpdateWithoutPrescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput>;
};

export type ServiceUpdateWithoutReminderTimingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceInput>;
};

export type ServiceUpdateWithoutRemindersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceInput>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceInput>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceInput>;
};

export type ServiceUpsertWithWhereUniqueWithoutFormTemplateInput = {
  where: ServiceWhereUniqueInput;
  update: ServiceUncheckedUpdateWithoutFormTemplateInput;
  create: ServiceUncheckedCreateWithoutFormTemplateInput;
};

export type ServiceUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: ServiceWhereUniqueInput;
  update: ServiceUncheckedUpdateWithoutIntegrationInput;
  create: ServiceUncheckedCreateWithoutIntegrationInput;
};

export type ServiceUpsertWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  update: ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
  create: ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
};

export type ServiceUpsertWithoutInvoiceLineItemsInput = {
  update: ServiceUncheckedUpdateWithoutInvoiceLineItemsInput;
  create: ServiceUncheckedCreateWithoutInvoiceLineItemsInput;
};

export type ServiceUpsertWithoutOrganizationCareBenefitToClinicServiceInput = {
  update: ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput;
  create: ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type ServiceUpsertWithoutPrescriptionsInput = {
  update: ServiceUncheckedUpdateWithoutPrescriptionsInput;
  create: ServiceUncheckedCreateWithoutPrescriptionsInput;
};

export type ServiceUpsertWithoutReminderTimingsInput = {
  update: ServiceUncheckedUpdateWithoutReminderTimingsInput;
  create: ServiceUncheckedCreateWithoutReminderTimingsInput;
};

export type ServiceUpsertWithoutRemindersInput = {
  update: ServiceUncheckedUpdateWithoutRemindersInput;
  create: ServiceUncheckedCreateWithoutRemindersInput;
};

export type ServiceWhereInput = {
  AND?: Maybe<Array<ServiceWhereInput>>;
  OR?: Maybe<Array<ServiceWhereInput>>;
  NOT?: Maybe<Array<ServiceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  code?: Maybe<StringFilter>;
  pimsName?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  reminderTimings?: Maybe<ServiceReminderTimingListRelationFilter>;
  reminders?: Maybe<ServiceReminderListRelationFilter>;
  isReminderActive?: Maybe<BoolFilter>;
  integrationId?: Maybe<StringFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
  isDeleted?: Maybe<BoolFilter>;
  hasConfiguredReminders?: Maybe<BoolFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  invoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceListRelationFilter>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
};

export type ServiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  code_integrationId?: Maybe<ServiceCodeIntegrationIdCompoundUniqueInput>;
  pimsId_integrationId?: Maybe<ServicePimsIdIntegrationIdCompoundUniqueInput>;
};

export enum SmsEntityType {
  ChannelMessage = 'ChannelMessage',
  ServiceReminderNotification = 'ServiceReminderNotification',
  MassTextAlertEntry = 'MassTextAlertEntry',
}

export enum SmsNotificationDeliveryErrorType {
  Unknown = 'Unknown',
  InvalidContactInfo = 'InvalidContactInfo',
  ConnectionError = 'ConnectionError',
  NotificationRefused = 'NotificationRefused',
  CannotReceiveMessageType = 'CannotReceiveMessageType',
  MessageFilteredByCarrier = 'MessageFilteredByCarrier',
}

export enum SmsNotificationProvider {
  Twilio = 'Twilio',
  Bandwidth = 'Bandwidth',
}

export type SmsNotificationStatus = {
  __typename?: 'SmsNotificationStatus';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** (e.g. the phone number or email of the message) */
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  /** the kind of entity the sourceContactEntityId references */
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  /** optionally included: the channel associated with this notification */
  sentChannelMessageId?: Maybe<Scalars['String']>;
  /** who is delivering our message (e.g. Twilio) */
  provider: SmsNotificationProvider;
  /** the unique id the deliverer gives us to track the message (e.g. message_sId) */
  providerMessageId: Scalars['String'];
  /** Our categorization of the error */
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  /** the error message given by the provider */
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessage>;
};

export type SmsNotificationStatusCountAggregateOutputType = {
  __typename?: 'SmsNotificationStatusCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  receivingNumber: Scalars['Int'];
  sourceContactEntityId: Scalars['Int'];
  sourceContactEntityType: Scalars['Int'];
  status: Scalars['Int'];
  sentChannelMessageId: Scalars['Int'];
  provider: Scalars['Int'];
  providerMessageId: Scalars['Int'];
  errorType: Scalars['Int'];
  providerErrorData: Scalars['Int'];
  clinicPhoneNumberId: Scalars['Int'];
  entityType: Scalars['Int'];
  entityId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SmsNotificationStatusCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type SmsNotificationStatusCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput>;
};

export type SmsNotificationStatusCreateManyClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope = {
  data: Array<SmsNotificationStatusCreateManyClinicPhoneNumberInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SmsNotificationStatusCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusCreateManySentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusCreateManySentChannelMessageInputEnvelope = {
  data: Array<SmsNotificationStatusCreateManySentChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
};

export type SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
};

export type SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  create: SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput;
};

export type SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  create: SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput;
};

export type SmsNotificationStatusCreateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput>;
};

export type SmsNotificationStatusCreateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusListRelationFilter = {
  every?: Maybe<SmsNotificationStatusWhereInput>;
  some?: Maybe<SmsNotificationStatusWhereInput>;
  none?: Maybe<SmsNotificationStatusWhereInput>;
};

export type SmsNotificationStatusMaxAggregateOutputType = {
  __typename?: 'SmsNotificationStatusMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type SmsNotificationStatusMinAggregateOutputType = {
  __typename?: 'SmsNotificationStatusMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
};

export type SmsNotificationStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SmsNotificationStatusOrderByRelevanceFieldEnum {
  Id = 'id',
  ReceivingNumber = 'receivingNumber',
  SourceContactEntityId = 'sourceContactEntityId',
  SentChannelMessageId = 'sentChannelMessageId',
  ProviderMessageId = 'providerMessageId',
  ProviderErrorData = 'providerErrorData',
  ClinicPhoneNumberId = 'clinicPhoneNumberId',
  EntityId = 'entityId',
}

export type SmsNotificationStatusOrderByRelevanceInput = {
  fields: Array<SmsNotificationStatusOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type SmsNotificationStatusOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  _count?: Maybe<SmsNotificationStatusCountOrderByAggregateInput>;
  _max?: Maybe<SmsNotificationStatusMaxOrderByAggregateInput>;
  _min?: Maybe<SmsNotificationStatusMinOrderByAggregateInput>;
};

export type SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  entityType?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  sentChannelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<SmsNotificationStatusOrderByRelevanceInput>;
};

export enum SmsNotificationStatusScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ReceivingNumber = 'receivingNumber',
  SourceContactEntityId = 'sourceContactEntityId',
  SourceContactEntityType = 'sourceContactEntityType',
  Status = 'status',
  SentChannelMessageId = 'sentChannelMessageId',
  Provider = 'provider',
  ProviderMessageId = 'providerMessageId',
  ErrorType = 'errorType',
  ProviderErrorData = 'providerErrorData',
  ClinicPhoneNumberId = 'clinicPhoneNumberId',
  EntityType = 'entityType',
  EntityId = 'entityId',
}

export type SmsNotificationStatusScalarWhereInput = {
  AND?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  OR?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  receivingNumber?: Maybe<StringFilter>;
  sourceContactEntityId?: Maybe<StringFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeFilter>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  provider?: Maybe<EnumSmsNotificationProviderFilter>;
  providerMessageId?: Maybe<StringFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  clinicPhoneNumberId?: Maybe<StringNullableFilter>;
  entityType?: Maybe<EnumSmsEntityTypeNullableFilter>;
  entityId?: Maybe<StringNullableFilter>;
};

export type SmsNotificationStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  receivingNumber?: Maybe<StringWithAggregatesFilter>;
  sourceContactEntityId?: Maybe<StringWithAggregatesFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeWithAggregatesFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeWithAggregatesFilter>;
  sentChannelMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  provider?: Maybe<EnumSmsNotificationProviderWithAggregatesFilter>;
  providerMessageId?: Maybe<StringWithAggregatesFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  providerErrorData?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPhoneNumberId?: Maybe<StringNullableWithAggregatesFilter>;
  entityType?: Maybe<EnumSmsEntityTypeNullableWithAggregatesFilter>;
  entityId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SmsNotificationStatusSubscription = {
  __typename?: 'SmsNotificationStatusSubscription';
  node: SmsNotificationStatus;
  mutation: MutationType;
};

export enum SmsNotificationStatusType {
  Sent = 'Sent',
  Delivered = 'Delivered',
  Error = 'Error',
}

export type SmsNotificationStatusUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
};

export type SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
};

export type SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  provider: SmsNotificationProvider;
  providerMessageId: Scalars['String'];
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotifificationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesInput>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutSmsNotifificationStatusesInput>;
};

export type SmsNotificationStatusUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput = {
  where: SmsNotificationStatusScalarWhereInput;
  data: SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotificationStatusesInput;
};

export type SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput = {
  where: SmsNotificationStatusScalarWhereInput;
  data: SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotifificationStatusesInput;
};

export type SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
};

export type SmsNotificationStatusUpdateManyWithoutSentChannelMessageInput = {
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
};

export type SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  data: SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput;
};

export type SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  data: SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput;
};

export type SmsNotificationStatusUpdateWithoutClinicPhoneNumberInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutSmsNotifificationStatusesInput>;
};

export type SmsNotificationStatusUpdateWithoutSentChannelMessageInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  provider?: Maybe<SmsNotificationProvider>;
  providerMessageId?: Maybe<Scalars['String']>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  providerErrorData?: Maybe<Scalars['String']>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesInput>;
  entityType?: Maybe<SmsEntityType>;
  entityId?: Maybe<Scalars['String']>;
};

export type SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  update: SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput;
  create: SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput;
};

export type SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput = {
  where: SmsNotificationStatusWhereUniqueInput;
  update: SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput;
  create: SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput;
};

export type SmsNotificationStatusWhereInput = {
  AND?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  OR?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  receivingNumber?: Maybe<StringFilter>;
  sourceContactEntityId?: Maybe<StringFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeFilter>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  provider?: Maybe<EnumSmsNotificationProviderFilter>;
  providerMessageId?: Maybe<StringFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  clinicPhoneNumberId?: Maybe<StringNullableFilter>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberWhereInput>;
  entityType?: Maybe<EnumSmsEntityTypeNullableFilter>;
  entityId?: Maybe<StringNullableFilter>;
  sentChannelMessage?: Maybe<ChannelMessageWhereInput>;
};

export type SmsNotificationStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SpeciesType {
  Feline = 'Feline',
  Canine = 'Canine',
  Avian = 'Avian',
  Bovine = 'Bovine',
  Equine = 'Equine',
  Other = 'Other',
}

export type StaffRole = {
  __typename?: 'StaffRole';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  clinic?: Maybe<Clinic>;
  permissions: Array<Permission>;
  users: Array<VetInfo>;
  _count: StaffRoleCountOutputType;
};

export type StaffRolePermissionsArgs = {
  where?: Maybe<PermissionWhereInput>;
  orderBy?: Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PermissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PermissionScalarFieldEnum>;
};

export type StaffRoleUsersArgs = {
  where?: Maybe<VetInfoWhereInput>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
};

export type StaffRoleCountAggregateOutputType = {
  __typename?: 'StaffRoleCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  role: Scalars['Int'];
  clinicId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StaffRoleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleCountOutputType = {
  __typename?: 'StaffRoleCountOutputType';
  permissions: Scalars['Int'];
  users: Scalars['Int'];
};

export type StaffRoleCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleCreateManyClinicInputEnvelope = {
  data: Array<StaffRoleCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StaffRoleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleCreateNestedManyWithoutPermissionsInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleCreateOrConnectWithoutClinicInput = {
  where: StaffRoleWhereUniqueInput;
  create: StaffRoleUncheckedCreateWithoutClinicInput;
};

export type StaffRoleCreateOrConnectWithoutPermissionsInput = {
  where: StaffRoleWhereUniqueInput;
  create: StaffRoleUncheckedCreateWithoutPermissionsInput;
};

export type StaffRoleCreateOrConnectWithoutUsersInput = {
  where: StaffRoleWhereUniqueInput;
  create: StaffRoleUncheckedCreateWithoutUsersInput;
};

export type StaffRoleCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleListRelationFilter = {
  every?: Maybe<StaffRoleWhereInput>;
  some?: Maybe<StaffRoleWhereInput>;
  none?: Maybe<StaffRoleWhereInput>;
};

export type StaffRoleMaxAggregateOutputType = {
  __typename?: 'StaffRoleMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleMinAggregateOutputType = {
  __typename?: 'StaffRoleMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StaffRoleOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
}

export type StaffRoleOrderByRelevanceInput = {
  fields: Array<StaffRoleOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StaffRoleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<StaffRoleCountOrderByAggregateInput>;
  _max?: Maybe<StaffRoleMaxOrderByAggregateInput>;
  _min?: Maybe<StaffRoleMinOrderByAggregateInput>;
};

export type StaffRoleOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  permissions?: Maybe<PermissionOrderByRelationAggregateInput>;
  users?: Maybe<VetInfoOrderByRelationAggregateInput>;
  _relevance?: Maybe<StaffRoleOrderByRelevanceInput>;
};

export enum StaffRoleScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Role = 'role',
  ClinicId = 'clinicId',
  UpdatedAt = 'updatedAt',
}

export type StaffRoleScalarWhereInput = {
  AND?: Maybe<Array<StaffRoleScalarWhereInput>>;
  OR?: Maybe<Array<StaffRoleScalarWhereInput>>;
  NOT?: Maybe<Array<StaffRoleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  role?: Maybe<EnumRoleFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StaffRoleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  role?: Maybe<EnumRoleWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type StaffRoleUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutPermissionsInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
};

export type StaffRoleUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role: Role;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesInput>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesInput>;
};

export type StaffRoleUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUncheckedUpdateManyWithoutPermissionsInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutPermissionsInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUncheckedUpdateManyWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUncheckedUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesInput>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesInput>;
};

export type StaffRoleUncheckedUpdateWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesInput>;
};

export type StaffRoleUncheckedUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  clinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesInput>;
};

export type StaffRoleUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneWithoutRolesInput>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesInput>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesInput>;
};

export type StaffRoleUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUpdateManyWithWhereWithoutClinicInput = {
  where: StaffRoleScalarWhereInput;
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
};

export type StaffRoleUpdateManyWithWhereWithoutPermissionsInput = {
  where: StaffRoleScalarWhereInput;
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
};

export type StaffRoleUpdateManyWithWhereWithoutUsersInput = {
  where: StaffRoleScalarWhereInput;
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
};

export type StaffRoleUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUpdateManyWithoutPermissionsInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutPermissionsInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutUsersInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
};

export type StaffRoleUpdateWithWhereUniqueWithoutClinicInput = {
  where: StaffRoleWhereUniqueInput;
  data: StaffRoleUncheckedUpdateWithoutClinicInput;
};

export type StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput = {
  where: StaffRoleWhereUniqueInput;
  data: StaffRoleUncheckedUpdateWithoutPermissionsInput;
};

export type StaffRoleUpdateWithWhereUniqueWithoutUsersInput = {
  where: StaffRoleWhereUniqueInput;
  data: StaffRoleUncheckedUpdateWithoutUsersInput;
};

export type StaffRoleUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesInput>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesInput>;
};

export type StaffRoleUpdateWithoutPermissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneWithoutRolesInput>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesInput>;
};

export type StaffRoleUpdateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneWithoutRolesInput>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesInput>;
};

export type StaffRoleUpsertWithWhereUniqueWithoutClinicInput = {
  where: StaffRoleWhereUniqueInput;
  update: StaffRoleUncheckedUpdateWithoutClinicInput;
  create: StaffRoleUncheckedCreateWithoutClinicInput;
};

export type StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput = {
  where: StaffRoleWhereUniqueInput;
  update: StaffRoleUncheckedUpdateWithoutPermissionsInput;
  create: StaffRoleUncheckedCreateWithoutPermissionsInput;
};

export type StaffRoleUpsertWithWhereUniqueWithoutUsersInput = {
  where: StaffRoleWhereUniqueInput;
  update: StaffRoleUncheckedUpdateWithoutUsersInput;
  create: StaffRoleUncheckedCreateWithoutUsersInput;
};

export type StaffRoleWhereInput = {
  AND?: Maybe<Array<StaffRoleWhereInput>>;
  OR?: Maybe<Array<StaffRoleWhereInput>>;
  NOT?: Maybe<Array<StaffRoleWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  role?: Maybe<EnumRoleFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  permissions?: Maybe<PermissionListRelationFilter>;
  users?: Maybe<VetInfoListRelationFilter>;
};

export type StaffRoleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum State {
  Al = 'AL',
  Ak = 'AK',
  As = 'AS',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Mp = 'MP',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Vi = 'VI',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type StringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  id: Scalars['String'];
  chargesEnabled: Scalars['Boolean'];
  payoutsEnabled: Scalars['Boolean'];
  capabilities: StripeCapabilities;
  requirements: StripeRequirements;
  payoutSchedule: StripePayoutSchedule;
  payoutAccounts?: Maybe<Array<StripePayoutAccount>>;
  defaultAccount?: Maybe<StripePayoutAccount>;
};

export type StripeAccountOutput = {
  __typename?: 'StripeAccountOutput';
  account: StripeAccount;
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  card_payments?: Maybe<StripeCapabilityState>;
  transfers: StripeCapabilityState;
};

export enum StripeCapabilityState {
  Active = 'active',
  Inactive = 'inactive',
}

export enum StripeCaptureType {
  Automatic = 'automatic',
  Manual = 'manual',
}

export enum StripeCollectionMethod {
  ChargeAutomatically = 'charge_automatically',
  SendInvoice = 'send_invoice',
}

export type StripeConnectOauthInput = {
  oauthCode: Scalars['String'];
};

export type StripeCreateAccountInput = {
  redirectUrl: Scalars['String'];
};

export type StripeCreateAccountOutput = {
  __typename?: 'StripeCreateAccountOutput';
  account: StripeAccount;
  onboarding: LoginLinkOutput;
};

export type StripeCreatePaymentInput = {
  amount: Scalars['Int'];
  customer?: Maybe<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  captureType?: Maybe<StripeCaptureType>;
  description?: Maybe<Scalars['String']>;
  descriptorSuffix?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  paymentMethodType?: Maybe<StripePaymentMethodType>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  id: Scalars['String'];
  stripeId: Scalars['String'];
  organizationPetParents: Array<OrganizationPetParent>;
  clinicPetParents: Array<ClinicPetParent>;
  paymentMethods: Array<StripePaymentMethod>;
  invoices: Array<StripeInvoice>;
  _count: StripeCustomerCountOutputType;
};

export type StripeCustomerOrganizationPetParentsArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
  orderBy?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrganizationPetParentScalarFieldEnum>;
};

export type StripeCustomerClinicPetParentsArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
};

export type StripeCustomerPaymentMethodsArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
  orderBy?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentMethodScalarFieldEnum>;
};

export type StripeCustomerInvoicesArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
};

export type StripeCustomerCountAggregateOutputType = {
  __typename?: 'StripeCustomerCountAggregateOutputType';
  id: Scalars['Int'];
  stripeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeCustomerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export type StripeCustomerCountOutputType = {
  __typename?: 'StripeCustomerCountOutputType';
  organizationPetParents: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  paymentMethods: Scalars['Int'];
  invoices: Scalars['Int'];
};

export type StripeCustomerCreateInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
};

export type StripeCustomerCreateNestedOneWithoutClinicPetParentsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutClinicPetParentsInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
};

export type StripeCustomerCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
};

export type StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
};

export type StripeCustomerCreateNestedOneWithoutPaymentMethodsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutPaymentMethodsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutPaymentMethodsInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
};

export type StripeCustomerCreateOrConnectWithoutClinicPetParentsInput = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerUncheckedCreateWithoutClinicPetParentsInput;
};

export type StripeCustomerCreateOrConnectWithoutInvoicesInput = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerUncheckedCreateWithoutInvoicesInput;
};

export type StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput;
};

export type StripeCustomerCreateOrConnectWithoutPaymentMethodsInput = {
  where: StripeCustomerWhereUniqueInput;
  create: StripeCustomerUncheckedCreateWithoutPaymentMethodsInput;
};

export type StripeCustomerCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerCreateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerCreateWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerMaxAggregateOutputType = {
  __typename?: 'StripeCustomerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export type StripeCustomerMinAggregateOutputType = {
  __typename?: 'StripeCustomerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export enum StripeCustomerOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
}

export type StripeCustomerOrderByRelevanceInput = {
  fields: Array<StripeCustomerOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeCustomerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  _count?: Maybe<StripeCustomerCountOrderByAggregateInput>;
  _max?: Maybe<StripeCustomerMaxOrderByAggregateInput>;
  _min?: Maybe<StripeCustomerMinOrderByAggregateInput>;
};

export type StripeCustomerOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  organizationPetParents?: Maybe<OrganizationPetParentOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  paymentMethods?: Maybe<StripePaymentMethodOrderByRelationAggregateInput>;
  invoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeCustomerOrderByRelevanceInput>;
};

export type StripeCustomerRelationFilter = {
  is?: Maybe<StripeCustomerWhereInput>;
  isNot?: Maybe<StripeCustomerWhereInput>;
};

export enum StripeCustomerScalarFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
}

export type StripeCustomerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
};

export type StripeCustomerUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedCreateWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUpdateInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpdateOneRequiredWithoutInvoicesInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutInvoicesInput>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutInvoicesInput>;
};

export type StripeCustomerUpdateOneWithoutClinicPetParentsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutClinicPetParentsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutClinicPetParentsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutClinicPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput>;
};

export type StripeCustomerUpdateOneWithoutOrganizationPetParentsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutOrganizationPetParentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput>;
};

export type StripeCustomerUpdateOneWithoutPaymentMethodsInput = {
  create?: Maybe<StripeCustomerUncheckedCreateWithoutPaymentMethodsInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutPaymentMethodsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutPaymentMethodsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput>;
};

export type StripeCustomerUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUpdateWithoutOrganizationPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUpdateWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerInput>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerInput>;
};

export type StripeCustomerUpsertWithoutClinicPetParentsInput = {
  update: StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput;
  create: StripeCustomerUncheckedCreateWithoutClinicPetParentsInput;
};

export type StripeCustomerUpsertWithoutInvoicesInput = {
  update: StripeCustomerUncheckedUpdateWithoutInvoicesInput;
  create: StripeCustomerUncheckedCreateWithoutInvoicesInput;
};

export type StripeCustomerUpsertWithoutOrganizationPetParentsInput = {
  update: StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput;
  create: StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput;
};

export type StripeCustomerUpsertWithoutPaymentMethodsInput = {
  update: StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput;
  create: StripeCustomerUncheckedCreateWithoutPaymentMethodsInput;
};

export type StripeCustomerWhereInput = {
  AND?: Maybe<Array<StripeCustomerWhereInput>>;
  OR?: Maybe<Array<StripeCustomerWhereInput>>;
  NOT?: Maybe<Array<StripeCustomerWhereInput>>;
  id?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  organizationPetParents?: Maybe<OrganizationPetParentListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  paymentMethods?: Maybe<StripePaymentMethodListRelationFilter>;
  invoices?: Maybe<StripeInvoiceListRelationFilter>;
};

export type StripeCustomerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StripeDispute = {
  __typename?: 'StripeDispute';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripePayout?: Maybe<StripePayout>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransaction>;
};

export type StripeDisputeAvgAggregateOutputType = {
  __typename?: 'StripeDisputeAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type StripeDisputeAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type StripeDisputeCountAggregateOutputType = {
  __typename?: 'StripeDisputeCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  amount: Scalars['Int'];
  status: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripeTransferReversalId: Scalars['Int'];
  stripeDisputeFeeChargeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeDisputeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
};

export type StripeDisputeCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeCreateManyStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeCreateManyStripePaymentIntentInputEnvelope = {
  data: Array<StripeDisputeCreateManyStripePaymentIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeDisputeCreateManyStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeCreateManyStripePayoutInputEnvelope = {
  data: Array<StripeDisputeCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
};

export type StripeDisputeCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
};

export type StripeDisputeCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeDisputeUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeDisputeCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<StripeDisputeWhereUniqueInput>;
};

export type StripeDisputeCreateOrConnectWithoutFinancialTransactionInput = {
  where: StripeDisputeWhereUniqueInput;
  create: StripeDisputeUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput = {
  where: StripeDisputeWhereUniqueInput;
  create: StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput;
};

export type StripeDisputeCreateOrConnectWithoutStripePayoutInput = {
  where: StripeDisputeWhereUniqueInput;
  create: StripeDisputeUncheckedCreateWithoutStripePayoutInput;
};

export type StripeDisputeCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeListRelationFilter = {
  every?: Maybe<StripeDisputeWhereInput>;
  some?: Maybe<StripeDisputeWhereInput>;
  none?: Maybe<StripeDisputeWhereInput>;
};

export type StripeDisputeMaxAggregateOutputType = {
  __typename?: 'StripeDisputeMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
};

export type StripeDisputeMinAggregateOutputType = {
  __typename?: 'StripeDisputeMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
};

export type StripeDisputeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeDisputeOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeTransferReversalId = 'stripeTransferReversalId',
  StripeDisputeFeeChargeId = 'stripeDisputeFeeChargeId',
}

export type StripeDisputeOrderByRelevanceInput = {
  fields: Array<StripeDisputeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeDisputeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  _count?: Maybe<StripeDisputeCountOrderByAggregateInput>;
  _avg?: Maybe<StripeDisputeAvgOrderByAggregateInput>;
  _max?: Maybe<StripeDisputeMaxOrderByAggregateInput>;
  _min?: Maybe<StripeDisputeMinOrderByAggregateInput>;
  _sum?: Maybe<StripeDisputeSumOrderByAggregateInput>;
};

export type StripeDisputeOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripeDisputeOrderByRelevanceInput>;
};

export type StripeDisputeRelationFilter = {
  is?: Maybe<StripeDisputeWhereInput>;
  isNot?: Maybe<StripeDisputeWhereInput>;
};

export enum StripeDisputeScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  Amount = 'amount',
  Status = 'status',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeTransferReversalId = 'stripeTransferReversalId',
  StripeDisputeFeeChargeId = 'stripeDisputeFeeChargeId',
}

export type StripeDisputeScalarWhereInput = {
  AND?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  OR?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  NOT?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  status?: Maybe<EnumStripeDisputeStatusFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeTransferReversalId?: Maybe<StringNullableFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableFilter>;
};

export type StripeDisputeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumStripeDisputeStatusWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeTransferReversalId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum StripeDisputeStatus {
  WarningUnderReview = 'Warning_Under_Review',
  WarningClosed = 'Warning_Closed',
  NeedsResponse = 'Needs_Response',
  UnderReview = 'Under_Review',
  ChargeRefunded = 'Charge_Refunded',
  Won = 'Won',
  Lost = 'Lost',
}

export type StripeDisputeSumAggregateOutputType = {
  __typename?: 'StripeDisputeSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type StripeDisputeSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type StripeDisputeUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
};

export type StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
};

export type StripeDisputeUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  status: StripeDisputeStatus;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
};

export type StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput = {
  where: StripeDisputeScalarWhereInput;
  data: StripeDisputeUncheckedUpdateManyWithoutStripeDisputeInput;
};

export type StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput = {
  where: StripeDisputeScalarWhereInput;
  data: StripeDisputeUncheckedUpdateManyWithoutStripeDisputesInput;
};

export type StripeDisputeUpdateManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
};

export type StripeDisputeUpdateManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
};

export type StripeDisputeUpdateOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeDisputeUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeDisputeCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeDisputeUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeDisputeWhereUniqueInput>;
  update?: Maybe<StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput = {
  where: StripeDisputeWhereUniqueInput;
  data: StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput;
};

export type StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput = {
  where: StripeDisputeWhereUniqueInput;
  data: StripeDisputeUncheckedUpdateWithoutStripePayoutInput;
};

export type StripeDisputeUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeDisputeUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeInput>;
};

export type StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput = {
  where: StripeDisputeWhereUniqueInput;
  update: StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput;
  create: StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput;
};

export type StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput = {
  where: StripeDisputeWhereUniqueInput;
  update: StripeDisputeUncheckedUpdateWithoutStripePayoutInput;
  create: StripeDisputeUncheckedCreateWithoutStripePayoutInput;
};

export type StripeDisputeUpsertWithoutFinancialTransactionInput = {
  update: StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput;
  create: StripeDisputeUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeDisputeWhereInput = {
  AND?: Maybe<Array<StripeDisputeWhereInput>>;
  OR?: Maybe<Array<StripeDisputeWhereInput>>;
  NOT?: Maybe<Array<StripeDisputeWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  status?: Maybe<EnumStripeDisputeStatusFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  stripeTransferReversalId?: Maybe<StringNullableFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableFilter>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
};

export type StripeDisputeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  stripeCustomer: StripeCustomer;
  careStripeSubscriptionId: Scalars['String'];
  careStripeSubscription: CareStripeSubscription;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  financialTransaction?: Maybe<FinancialTransaction>;
  relatedStripeInvoiceItems: Array<StripeInvoiceItem>;
  enrollmentPayment: Array<CareEnrollmentPayment>;
  _count: StripeInvoiceCountOutputType;
};

export type StripeInvoiceRelatedStripeInvoiceItemsArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
  orderBy?: Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeInvoiceItemScalarFieldEnum>;
};

export type StripeInvoiceEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
};

export type StripeInvoiceAvgAggregateOutputType = {
  __typename?: 'StripeInvoiceAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type StripeInvoiceAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeInvoiceCountAggregateOutputType = {
  __typename?: 'StripeInvoiceCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  paidAt: Scalars['Int'];
  dueAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: Scalars['Int'];
  currency: Scalars['Int'];
  collectionMethod: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeInvoiceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
};

export type StripeInvoiceCountOutputType = {
  __typename?: 'StripeInvoiceCountOutputType';
  relatedStripeInvoiceItems: Scalars['Int'];
  enrollmentPayment: Scalars['Int'];
};

export type StripeInvoiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceCreateManyCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<StripeInvoiceCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceCreateManyStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceCreateManyStripeCustomerInputEnvelope = {
  data: Array<StripeInvoiceCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateManyStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
};

export type StripeInvoiceCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<StripeInvoiceCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
};

export type StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput;
};

export type StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput;
};

export type StripeInvoiceCreateOrConnectWithoutStripeCustomerInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutStripeCustomerInput;
};

export type StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput = {
  where: StripeInvoiceWhereUniqueInput;
  create: StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput;
};

export type StripeInvoiceCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceCreateWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceCreateWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceItem = {
  __typename?: 'StripeInvoiceItem';
  id: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
  stripeInvoice: StripeInvoice;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  _count: StripeInvoiceItemCountOutputType;
};

export type StripeInvoiceItemEnrollmentToStripeInvoiceItemsArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
};

export type StripeInvoiceItemAvgAggregateOutputType = {
  __typename?: 'StripeInvoiceItemAvgAggregateOutputType';
  unitAmount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type StripeInvoiceItemAvgOrderByAggregateInput = {
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
};

export type StripeInvoiceItemCountAggregateOutputType = {
  __typename?: 'StripeInvoiceItemCountAggregateOutputType';
  id: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  description: Scalars['Int'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['Int'];
  periodStart: Scalars['Int'];
  periodEnd: Scalars['Int'];
  stripeId: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeInvoiceItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type StripeInvoiceItemCountOutputType = {
  __typename?: 'StripeInvoiceItemCountOutputType';
  enrollmentToStripeInvoiceItems: Scalars['Int'];
};

export type StripeInvoiceItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoice: StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput
  >;
};

export type StripeInvoiceItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId: Scalars['String'];
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemCreateManyStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope = {
  data: Array<StripeInvoiceItemCreateManyStripeInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
};

export type StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  connect?: Maybe<StripeInvoiceItemWhereUniqueInput>;
};

export type StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  where: StripeInvoiceItemWhereUniqueInput;
  create: StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput = {
  where: StripeInvoiceItemWhereUniqueInput;
  create: StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput;
};

export type StripeInvoiceItemCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoice: StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput
  >;
};

export type StripeInvoiceItemListRelationFilter = {
  every?: Maybe<StripeInvoiceItemWhereInput>;
  some?: Maybe<StripeInvoiceItemWhereInput>;
  none?: Maybe<StripeInvoiceItemWhereInput>;
};

export type StripeInvoiceItemMaxAggregateOutputType = {
  __typename?: 'StripeInvoiceItemMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type StripeInvoiceItemMinAggregateOutputType = {
  __typename?: 'StripeInvoiceItemMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
};

export type StripeInvoiceItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeInvoiceItemOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeInvoiceId = 'stripeInvoiceId',
  Description = 'description',
  StripeId = 'stripeId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
}

export type StripeInvoiceItemOrderByRelevanceInput = {
  fields: Array<StripeInvoiceItemOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeInvoiceItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  _count?: Maybe<StripeInvoiceItemCountOrderByAggregateInput>;
  _avg?: Maybe<StripeInvoiceItemAvgOrderByAggregateInput>;
  _max?: Maybe<StripeInvoiceItemMaxOrderByAggregateInput>;
  _min?: Maybe<StripeInvoiceItemMinOrderByAggregateInput>;
  _sum?: Maybe<StripeInvoiceItemSumOrderByAggregateInput>;
};

export type StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  description?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeInvoiceItemOrderByRelevanceInput>;
};

export type StripeInvoiceItemRelationFilter = {
  is?: Maybe<StripeInvoiceItemWhereInput>;
  isNot?: Maybe<StripeInvoiceItemWhereInput>;
};

export enum StripeInvoiceItemScalarFieldEnum {
  Id = 'id',
  StripeInvoiceId = 'stripeInvoiceId',
  Description = 'description',
  UnitAmount = 'unitAmount',
  Amount = 'amount',
  Quantity = 'quantity',
  Date = 'date',
  PeriodStart = 'periodStart',
  PeriodEnd = 'periodEnd',
  StripeId = 'stripeId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
}

export type StripeInvoiceItemScalarWhereInput = {
  AND?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  stripeInvoiceId?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  unitAmount?: Maybe<IntFilter>;
  amount?: Maybe<IntFilter>;
  quantity?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  periodStart?: Maybe<DateTimeFilter>;
  periodEnd?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
};

export type StripeInvoiceItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  unitAmount?: Maybe<IntWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  quantity?: Maybe<IntWithAggregatesFilter>;
  date?: Maybe<DateTimeWithAggregatesFilter>;
  periodStart?: Maybe<DateTimeWithAggregatesFilter>;
  periodEnd?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripeInvoiceItemSumAggregateOutputType = {
  __typename?: 'StripeInvoiceItemSumAggregateOutputType';
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemSumOrderByAggregateInput = {
  unitAmount?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
};

export type StripeInvoiceItemUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId: Scalars['String'];
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput
  >;
};

export type StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
};

export type StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId: Scalars['String'];
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  unitAmount: Scalars['Int'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  date: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput
  >;
};

export type StripeInvoiceItemUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemInput
  >;
};

export type StripeInvoiceItemUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUncheckedUpdateManyWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
};

export type StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<
    CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemInput
  >;
};

export type StripeInvoiceItemUpdateInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsInput>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemInput>;
};

export type StripeInvoiceItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput = {
  where: StripeInvoiceItemScalarWhereInput;
  data: StripeInvoiceItemUncheckedUpdateManyWithoutRelatedStripeInvoiceItemsInput;
};

export type StripeInvoiceItemUpdateManyWithoutStripeInvoiceInput = {
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
};

export type StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsInput = {
  create?: Maybe<StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<StripeInvoiceItemUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
  connect?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  update?: Maybe<StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput = {
  where: StripeInvoiceItemWhereUniqueInput;
  data: StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput;
};

export type StripeInvoiceItemUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsInput>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceItemUpdateWithoutStripeInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemInput>;
};

export type StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput = {
  where: StripeInvoiceItemWhereUniqueInput;
  update: StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput;
  create: StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput;
};

export type StripeInvoiceItemUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  update: StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
  create: StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type StripeInvoiceItemWhereInput = {
  AND?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  id?: Maybe<StringFilter>;
  stripeInvoiceId?: Maybe<StringFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  description?: Maybe<StringFilter>;
  unitAmount?: Maybe<IntFilter>;
  amount?: Maybe<IntFilter>;
  quantity?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  periodStart?: Maybe<DateTimeFilter>;
  periodEnd?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
};

export type StripeInvoiceItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceListRelationFilter = {
  every?: Maybe<StripeInvoiceWhereInput>;
  some?: Maybe<StripeInvoiceWhereInput>;
  none?: Maybe<StripeInvoiceWhereInput>;
};

export type StripeInvoiceMaxAggregateOutputType = {
  __typename?: 'StripeInvoiceMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
};

export type StripeInvoiceMinAggregateOutputType = {
  __typename?: 'StripeInvoiceMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
};

export type StripeInvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeInvoiceOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  StripeCustomerId = 'stripeCustomerId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripePaymentMethodId = 'stripePaymentMethodId',
}

export type StripeInvoiceOrderByRelevanceInput = {
  fields: Array<StripeInvoiceOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeInvoiceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  _count?: Maybe<StripeInvoiceCountOrderByAggregateInput>;
  _avg?: Maybe<StripeInvoiceAvgOrderByAggregateInput>;
  _max?: Maybe<StripeInvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<StripeInvoiceMinOrderByAggregateInput>;
  _sum?: Maybe<StripeInvoiceSumOrderByAggregateInput>;
};

export type StripeInvoiceOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemOrderByRelationAggregateInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeInvoiceOrderByRelevanceInput>;
};

export type StripeInvoiceRelationFilter = {
  is?: Maybe<StripeInvoiceWhereInput>;
  isNot?: Maybe<StripeInvoiceWhereInput>;
};

export enum StripeInvoiceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PaidAt = 'paidAt',
  DueAt = 'dueAt',
  StripeId = 'stripeId',
  Amount = 'amount',
  Total = 'total',
  Status = 'status',
  Currency = 'currency',
  CollectionMethod = 'collectionMethod',
  StripeCustomerId = 'stripeCustomerId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  StripePaymentMethodId = 'stripePaymentMethodId',
}

export type StripeInvoiceScalarWhereInput = {
  AND?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  dueAt?: Maybe<DateTimeNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  status?: Maybe<EnumStripeInvoiceStatusFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
};

export type StripeInvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  dueAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumStripeInvoiceStatusWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum StripeInvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

export type StripeInvoiceSumAggregateOutputType = {
  __typename?: 'StripeInvoiceSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeInvoiceUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
};

export type StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  careStripeSubscriptionId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  status: StripeInvoiceStatus;
  currency: Currency;
  collectionMethod: StripeCollectionMethod;
  stripeCustomerId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
};

export type StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  where: StripeInvoiceScalarWhereInput;
  data: StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput = {
  where: StripeInvoiceScalarWhereInput;
  data: StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  where: StripeInvoiceScalarWhereInput;
  data: StripeInvoiceUncheckedUpdateManyWithoutStripeInvoicesInput;
};

export type StripeInvoiceUpdateManyWithoutCareStripeSubscriptionInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUpdateManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUpdateManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
};

export type StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutRelatedStripeInvoiceItemsInput>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput>;
};

export type StripeInvoiceUpdateOneWithoutEnrollmentPaymentInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutEnrollmentPaymentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput>;
};

export type StripeInvoiceUpdateOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: StripeInvoiceWhereUniqueInput;
  data: StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  where: StripeInvoiceWhereUniqueInput;
  data: StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: StripeInvoiceWhereUniqueInput;
  data: StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput;
};

export type StripeInvoiceUpdateWithoutCareStripeSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUpdateWithoutEnrollmentPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUpdateWithoutRelatedStripeInvoiceItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeInvoiceStatus>;
  currency?: Maybe<Currency>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceInput>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceInput>;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  where: StripeInvoiceWhereUniqueInput;
  update: StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput;
  create: StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  where: StripeInvoiceWhereUniqueInput;
  update: StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput;
  create: StripeInvoiceUncheckedCreateWithoutStripeCustomerInput;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: StripeInvoiceWhereUniqueInput;
  update: StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput;
  create: StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput;
};

export type StripeInvoiceUpsertWithoutEnrollmentPaymentInput = {
  update: StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput;
  create: StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput;
};

export type StripeInvoiceUpsertWithoutFinancialTransactionInput = {
  update: StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput;
  create: StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeInvoiceUpsertWithoutRelatedStripeInvoiceItemsInput = {
  update: StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput;
  create: StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput;
};

export type StripeInvoiceWhereInput = {
  AND?: Maybe<Array<StripeInvoiceWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  dueAt?: Maybe<DateTimeNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  status?: Maybe<EnumStripeInvoiceStatusFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemListRelationFilter>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentListRelationFilter>;
};

export type StripeInvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripePayment = {
  __typename?: 'StripePayment';
  id: Scalars['String'];
  status: Scalars['String'];
  amount: Scalars['Int'];
  total?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  amountRefunded?: Maybe<Scalars['Int']>;
  fees?: Maybe<StripePaymentFees>;
  transferType?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
};

export enum StripePaymentError {
  GenericDecline = 'GenericDecline',
  Blocked = 'Blocked',
  CardNotSupported = 'CardNotSupported',
  CurrencyNotSupported = 'CurrencyNotSupported',
  DuplicateTransaction = 'DuplicateTransaction',
  ExpiredCard = 'ExpiredCard',
  Fraudulent = 'Fraudulent',
  IncorrectNumber = 'IncorrectNumber',
  IncorrectPin = 'IncorrectPIN',
  IncorrectCvc = 'IncorrectCVC',
  IncorrectPostalCode = 'IncorrectPostalCode',
  InsufficientFunds = 'InsufficientFunds',
  InvalidAccount = 'InvalidAccount',
  InvalidExpiry = 'InvalidExpiry',
  LostStolenCard = 'LostStolenCard',
}

export type StripePaymentFees = {
  __typename?: 'StripePaymentFees';
  clinicServiceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFeeAmount: Scalars['Int'];
  clientServiceFeePercent: Scalars['Float'];
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  status: StripePaymentIntentStatus;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType: StripePaymentTransferType;
  captureType: ChargeCaptureType;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayout>;
  StripeDispute: Array<StripeDispute>;
  stripeRefunds: Array<StripeRefund>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeTerminal?: Maybe<StripeTerminal>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeReceipt?: Maybe<StripeReceipt>;
  financialTransaction?: Maybe<FinancialTransaction>;
  sentByAutomation: Scalars['Boolean'];
  sentByUserId?: Maybe<Scalars['String']>;
  sentBy?: Maybe<User>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices: Array<Invoice>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries: Array<PaymentPimsWritebackLogEntry>;
  automationRunAction?: Maybe<AutomationRunAction>;
  _count: StripePaymentIntentCountOutputType;
};

export type StripePaymentIntentStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeDisputeScalarFieldEnum>;
};

export type StripePaymentIntentStripeRefundsArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
};

export type StripePaymentIntentInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
};

export type StripePaymentIntentWritebackLogEntriesArgs = {
  where?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PaymentPimsWritebackLogEntryScalarFieldEnum>;
};

export type StripePaymentIntentAvgAggregateOutputType = {
  __typename?: 'StripePaymentIntentAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountCaptured?: Maybe<Scalars['Float']>;
  totalCaptured?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Float']>;
  clientServiceFee?: Maybe<Scalars['Float']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type StripePaymentIntentAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripePaymentIntentCountAggregateOutputType = {
  __typename?: 'StripePaymentIntentCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  status: Scalars['Int'];
  paidAt: Scalars['Int'];
  settledAt: Scalars['Int'];
  transferType: Scalars['Int'];
  captureType: Scalars['Int'];
  paymentMedium: Scalars['Int'];
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured: Scalars['Int'];
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent: Scalars['Int'];
  clinicServiceFeeFlat: Scalars['Int'];
  clientServiceFee: Scalars['Int'];
  clientServiceFeePercent: Scalars['Int'];
  total: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripeTerminalId: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
  sentByAutomation: Scalars['Int'];
  sentByUserId: Scalars['Int'];
  lastPaymentError: Scalars['Int'];
  paymentRelationship: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripePaymentIntentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
};

export type StripePaymentIntentCountOutputType = {
  __typename?: 'StripePaymentIntentCountOutputType';
  StripeDispute: Scalars['Int'];
  stripeRefunds: Scalars['Int'];
  invoices: Scalars['Int'];
  writebackLogEntries: Scalars['Int'];
};

export type StripePaymentIntentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentCreateManyClinicInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentCreateManyClinicPetParentInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentCreateManySentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentCreateManySentByInputEnvelope = {
  data: Array<StripePaymentIntentCreateManySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentCreateManyStripePayoutInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentCreateManyStripeTerminalInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripeTerminalInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutInvoicesInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutSentByInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput;
};

export type StripePaymentIntentCreateOrConnectWithoutClinicInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutClinicInput;
};

export type StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput;
};

export type StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripePaymentIntentCreateOrConnectWithoutInvoicesInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutInvoicesInput;
};

export type StripePaymentIntentCreateOrConnectWithoutSentByInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutSentByInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripePayoutInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripePayoutInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput;
};

export type StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput = {
  where: StripePaymentIntentWhereUniqueInput;
  create: StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput;
};

export type StripePaymentIntentCreateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentCreateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentListRelationFilter = {
  every?: Maybe<StripePaymentIntentWhereInput>;
  some?: Maybe<StripePaymentIntentWhereInput>;
  none?: Maybe<StripePaymentIntentWhereInput>;
};

export type StripePaymentIntentMaxAggregateOutputType = {
  __typename?: 'StripePaymentIntentMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
};

export type StripePaymentIntentMinAggregateOutputType = {
  __typename?: 'StripePaymentIntentMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
};

export type StripePaymentIntentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePaymentIntentOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  StripePayoutId = 'stripePayoutId',
  StripeTerminalId = 'stripeTerminalId',
  StripeReceiptId = 'stripeReceiptId',
  SentByUserId = 'sentByUserId',
}

export type StripePaymentIntentOrderByRelevanceInput = {
  fields: Array<StripePaymentIntentOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripePaymentIntentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  _count?: Maybe<StripePaymentIntentCountOrderByAggregateInput>;
  _avg?: Maybe<StripePaymentIntentAvgOrderByAggregateInput>;
  _max?: Maybe<StripePaymentIntentMaxOrderByAggregateInput>;
  _min?: Maybe<StripePaymentIntentMinOrderByAggregateInput>;
  _sum?: Maybe<StripePaymentIntentSumOrderByAggregateInput>;
};

export type StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  StripeDispute?: Maybe<StripeDisputeOrderByRelationAggregateInput>;
  stripeRefunds?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  stripeTerminalId?: Maybe<SortOrder>;
  stripeTerminal?: Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>;
  stripeReceiptId?: Maybe<SortOrder>;
  stripeReceipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  sentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  lastPaymentError?: Maybe<SortOrder>;
  invoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  paymentRelationship?: Maybe<SortOrder>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryOrderByRelationAggregateInput>;
  automationRunAction?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripePaymentIntentOrderByRelevanceInput>;
};

export type StripePaymentIntentRelationFilter = {
  is?: Maybe<StripePaymentIntentWhereInput>;
  isNot?: Maybe<StripePaymentIntentWhereInput>;
};

export enum StripePaymentIntentScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  Status = 'status',
  PaidAt = 'paidAt',
  SettledAt = 'settledAt',
  TransferType = 'transferType',
  CaptureType = 'captureType',
  PaymentMedium = 'paymentMedium',
  Amount = 'amount',
  AmountCaptured = 'amountCaptured',
  TotalCaptured = 'totalCaptured',
  ServiceFeeAmount = 'serviceFeeAmount',
  ClinicServiceFeePercent = 'clinicServiceFeePercent',
  ClinicServiceFeeFlat = 'clinicServiceFeeFlat',
  ClientServiceFee = 'clientServiceFee',
  ClientServiceFeePercent = 'clientServiceFeePercent',
  Total = 'total',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  StripePayoutId = 'stripePayoutId',
  StripeTerminalId = 'stripeTerminalId',
  StripeReceiptId = 'stripeReceiptId',
  SentByAutomation = 'sentByAutomation',
  SentByUserId = 'sentByUserId',
  LastPaymentError = 'lastPaymentError',
  PaymentRelationship = 'paymentRelationship',
}

export type StripePaymentIntentScalarWhereInput = {
  AND?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  OR?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  NOT?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  settledAt?: Maybe<DateTimeNullableFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeFilter>;
  captureType?: Maybe<EnumChargeCaptureTypeFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableFilter>;
  amount?: Maybe<IntFilter>;
  amountCaptured?: Maybe<IntFilter>;
  totalCaptured?: Maybe<IntNullableFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableFilter>;
  clientServiceFee?: Maybe<IntNullableFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableFilter>;
  total?: Maybe<IntFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeTerminalId?: Maybe<StringNullableFilter>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  sentByAutomation?: Maybe<BoolFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableFilter>;
};

export type StripePaymentIntentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  settledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeWithAggregatesFilter>;
  captureType?: Maybe<EnumChargeCaptureTypeWithAggregatesFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  amountCaptured?: Maybe<IntWithAggregatesFilter>;
  totalCaptured?: Maybe<IntNullableWithAggregatesFilter>;
  serviceFeeAmount?: Maybe<IntWithAggregatesFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableWithAggregatesFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableWithAggregatesFilter>;
  clientServiceFee?: Maybe<IntNullableWithAggregatesFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeTerminalId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringNullableWithAggregatesFilter>;
  sentByAutomation?: Maybe<BoolWithAggregatesFilter>;
  sentByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableWithAggregatesFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableWithAggregatesFilter>;
};

export enum StripePaymentIntentStatus {
  Created = 'Created',
  Succeeded = 'Succeeded',
  Canceled = 'Canceled',
  RequiresPaymentMethod = 'Requires_Payment_Method',
  RequiresConfirmation = 'Requires_Confirmation',
  RequiresAction = 'Requires_Action',
  Processing = 'Processing',
  RequiresCapture = 'Requires_Capture',
}

export type StripePaymentIntentSubscription = {
  __typename?: 'StripePaymentIntentSubscription';
  node: StripePaymentIntent;
  model: Scalars['String'];
  mutation: MutationType;
};

export type StripePaymentIntentSumAggregateOutputType = {
  __typename?: 'StripePaymentIntentSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripePaymentIntentSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripePaymentIntentUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount: Scalars['Int'];
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutInvoicesInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutSentByInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutSentByInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeReceiptInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutClinicInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutSentByInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutSentStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput = {
  where: StripePaymentIntentScalarWhereInput;
  data: StripePaymentIntentUncheckedUpdateManyWithoutPaymentsInput;
};

export type StripePaymentIntentUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutInvoicesInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutSentByInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutSentByInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripePaymentMethodInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripeTerminalInput = {
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
};

export type StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutWritebackLogEntriesInput>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput>;
};

export type StripePaymentIntentUpdateOneWithoutAutomationRunActionInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutAutomationRunActionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput>;
};

export type StripePaymentIntentUpdateOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeDisputeInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeDisputeInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeReceiptInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeReceiptInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeRefundsInput = {
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeRefundsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput>;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutClinicInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutInvoicesInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutSentByInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput = {
  where: StripePaymentIntentWhereUniqueInput;
  data: StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput;
};

export type StripePaymentIntentUpdateWithoutAutomationRunActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
};

export type StripePaymentIntentUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutSentByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutStripeDisputeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutStripePaymentMethodInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutStripeTerminalInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpdateWithoutWritebackLogEntriesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  status?: Maybe<StripePaymentIntentStatus>;
  paidAt?: Maybe<Scalars['DateTime']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  transferType?: Maybe<StripePaymentTransferType>;
  captureType?: Maybe<ChargeCaptureType>;
  paymentMedium?: Maybe<PaymentMedium>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsInput>;
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsInput>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentInput>;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutClinicInput;
  create: StripePaymentIntentUncheckedCreateWithoutClinicInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput;
  create: StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutInvoicesInput;
  create: StripePaymentIntentUncheckedCreateWithoutInvoicesInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutSentByInput;
  create: StripePaymentIntentUncheckedCreateWithoutSentByInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripePayoutInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput = {
  where: StripePaymentIntentWhereUniqueInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput;
};

export type StripePaymentIntentUpsertWithoutAutomationRunActionInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput;
  create: StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput;
};

export type StripePaymentIntentUpsertWithoutFinancialTransactionInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput;
  create: StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripePaymentIntentUpsertWithoutStripeDisputeInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput;
};

export type StripePaymentIntentUpsertWithoutStripeReceiptInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput;
};

export type StripePaymentIntentUpsertWithoutStripeRefundsInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput;
  create: StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput;
};

export type StripePaymentIntentUpsertWithoutWritebackLogEntriesInput = {
  update: StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput;
  create: StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput;
};

export type StripePaymentIntentWhereInput = {
  AND?: Maybe<Array<StripePaymentIntentWhereInput>>;
  OR?: Maybe<Array<StripePaymentIntentWhereInput>>;
  NOT?: Maybe<Array<StripePaymentIntentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  settledAt?: Maybe<DateTimeNullableFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeFilter>;
  captureType?: Maybe<EnumChargeCaptureTypeFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableFilter>;
  amount?: Maybe<IntFilter>;
  amountCaptured?: Maybe<IntFilter>;
  totalCaptured?: Maybe<IntNullableFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableFilter>;
  clientServiceFee?: Maybe<IntNullableFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableFilter>;
  total?: Maybe<IntFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  StripeDispute?: Maybe<StripeDisputeListRelationFilter>;
  stripeRefunds?: Maybe<StripeRefundListRelationFilter>;
  stripeTerminalId?: Maybe<StringNullableFilter>;
  stripeTerminal?: Maybe<StripeTerminalWhereInput>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  stripeReceipt?: Maybe<StripeReceiptWhereInput>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  sentByAutomation?: Maybe<BoolFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  sentBy?: Maybe<UserWhereInput>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableFilter>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryListRelationFilter>;
  automationRunAction?: Maybe<AutomationRunActionWhereInput>;
};

export type StripePaymentIntentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  isReusable: Scalars['Boolean'];
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomer>;
  type: StripePaymentMethodType;
  permitsFutureUsage: Scalars['Boolean'];
  stripePaymentIntents: Array<StripePaymentIntent>;
  careStripeSubscriptions: Array<CareStripeSubscription>;
  stripeInvoices: Array<StripeInvoice>;
  _count: StripePaymentMethodCountOutputType;
};

export type StripePaymentMethodStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};

export type StripePaymentMethodCareStripeSubscriptionsArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
};

export type StripePaymentMethodStripeInvoicesArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
};

export type StripePaymentMethodAvgAggregateOutputType = {
  __typename?: 'StripePaymentMethodAvgAggregateOutputType';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

export type StripePaymentMethodAvgOrderByAggregateInput = {
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
};

export type StripePaymentMethodCountAggregateOutputType = {
  __typename?: 'StripePaymentMethodCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  stripeId: Scalars['Int'];
  last4: Scalars['Int'];
  fingerprint: Scalars['Int'];
  isDefault: Scalars['Int'];
  isExpired: Scalars['Int'];
  isReusable: Scalars['Int'];
  funding: Scalars['Int'];
  cardBrand: Scalars['Int'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  type: Scalars['Int'];
  permitsFutureUsage: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripePaymentMethodCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
};

export type StripePaymentMethodCountOutputType = {
  __typename?: 'StripePaymentMethodCountOutputType';
  stripePaymentIntents: Scalars['Int'];
  careStripeSubscriptions: Scalars['Int'];
  stripeInvoices: Scalars['Int'];
};

export type StripePaymentMethodCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateManyClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyClinicPetParentInputEnvelope = {
  data: Array<StripePaymentMethodCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyStripeCustomerInputEnvelope = {
  data: Array<StripePaymentMethodCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
};

export type StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
};

export type StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
};

export type StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
};

export type StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
};

export type StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput;
};

export type StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: StripePaymentMethodWhereUniqueInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type StripePaymentMethodCreateWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodFingerprintStripeCustomerIdCompoundUniqueInput = {
  fingerprint: Scalars['String'];
  stripeCustomerId: Scalars['String'];
};

export type StripePaymentMethodListRelationFilter = {
  every?: Maybe<StripePaymentMethodWhereInput>;
  some?: Maybe<StripePaymentMethodWhereInput>;
  none?: Maybe<StripePaymentMethodWhereInput>;
};

export type StripePaymentMethodMaxAggregateOutputType = {
  __typename?: 'StripePaymentMethodMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
};

export type StripePaymentMethodMinAggregateOutputType = {
  __typename?: 'StripePaymentMethodMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
};

export type StripePaymentMethodOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePaymentMethodOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  Last4 = 'last4',
  Fingerprint = 'fingerprint',
  ClinicPetParentId = 'clinicPetParentId',
  StripeCustomerId = 'stripeCustomerId',
}

export type StripePaymentMethodOrderByRelevanceInput = {
  fields: Array<StripePaymentMethodOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripePaymentMethodOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  _count?: Maybe<StripePaymentMethodCountOrderByAggregateInput>;
  _avg?: Maybe<StripePaymentMethodAvgOrderByAggregateInput>;
  _max?: Maybe<StripePaymentMethodMaxOrderByAggregateInput>;
  _min?: Maybe<StripePaymentMethodMinOrderByAggregateInput>;
  _sum?: Maybe<StripePaymentMethodSumOrderByAggregateInput>;
};

export type StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  type?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  stripeInvoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripePaymentMethodOrderByRelevanceInput>;
};

export type StripePaymentMethodRelationFilter = {
  is?: Maybe<StripePaymentMethodWhereInput>;
  isNot?: Maybe<StripePaymentMethodWhereInput>;
};

export enum StripePaymentMethodScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  StripeId = 'stripeId',
  Last4 = 'last4',
  Fingerprint = 'fingerprint',
  IsDefault = 'isDefault',
  IsExpired = 'isExpired',
  IsReusable = 'isReusable',
  Funding = 'funding',
  CardBrand = 'cardBrand',
  ExpirationMonth = 'expirationMonth',
  ExpirationYear = 'expirationYear',
  ClinicPetParentId = 'clinicPetParentId',
  StripeCustomerId = 'stripeCustomerId',
  Type = 'type',
  PermitsFutureUsage = 'permitsFutureUsage',
}

export type StripePaymentMethodScalarWhereInput = {
  AND?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  OR?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  NOT?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  stripeId?: Maybe<StringFilter>;
  last4?: Maybe<StringFilter>;
  fingerprint?: Maybe<StringNullableFilter>;
  isDefault?: Maybe<BoolFilter>;
  isExpired?: Maybe<BoolFilter>;
  isReusable?: Maybe<BoolFilter>;
  funding?: Maybe<EnumCardFundingNullableFilter>;
  cardBrand?: Maybe<EnumCardBrandFilter>;
  expirationMonth?: Maybe<IntFilter>;
  expirationYear?: Maybe<IntFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumStripePaymentMethodTypeFilter>;
  permitsFutureUsage?: Maybe<BoolFilter>;
};

export type StripePaymentMethodScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  last4?: Maybe<StringWithAggregatesFilter>;
  fingerprint?: Maybe<StringNullableWithAggregatesFilter>;
  isDefault?: Maybe<BoolWithAggregatesFilter>;
  isExpired?: Maybe<BoolWithAggregatesFilter>;
  isReusable?: Maybe<BoolWithAggregatesFilter>;
  funding?: Maybe<EnumCardFundingNullableWithAggregatesFilter>;
  cardBrand?: Maybe<EnumCardBrandWithAggregatesFilter>;
  expirationMonth?: Maybe<IntWithAggregatesFilter>;
  expirationYear?: Maybe<IntWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumStripePaymentMethodTypeWithAggregatesFilter>;
  permitsFutureUsage?: Maybe<BoolWithAggregatesFilter>;
};

export type StripePaymentMethodSumAggregateOutputType = {
  __typename?: 'StripePaymentMethodSumAggregateOutputType';
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
};

export type StripePaymentMethodSumOrderByAggregateInput = {
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
};

export enum StripePaymentMethodType {
  CardPresent = 'CardPresent',
  CardNotPresent = 'CardNotPresent',
}

export type StripePaymentMethodUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
};

export type StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
};

export type StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand: CardBrand;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutPaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutStripePaymentMethodsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput = {
  where: StripePaymentMethodScalarWhereInput;
  data: StripePaymentMethodUncheckedUpdateManyWithoutStripePaymentMethodsInput;
};

export type StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput = {
  where: StripePaymentMethodScalarWhereInput;
  data: StripePaymentMethodUncheckedUpdateManyWithoutPaymentMethodsInput;
};

export type StripePaymentMethodUpdateManyWithoutClinicPetParentInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
};

export type StripePaymentMethodUpdateManyWithoutStripeCustomerInput = {
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
};

export type StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutCareStripeSubscriptionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput>;
};

export type StripePaymentMethodUpdateOneWithoutStripeInvoicesInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutStripeInvoicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput>;
};

export type StripePaymentMethodUpdateOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  where: StripePaymentMethodWhereUniqueInput;
  data: StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput;
};

export type StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  where: StripePaymentMethodWhereUniqueInput;
  data: StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput;
};

export type StripePaymentMethodUpdateWithoutCareStripeSubscriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUpdateWithoutClinicPetParentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUpdateWithoutStripeCustomerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUpdateWithoutStripeInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodInput>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  funding?: Maybe<CardFunding>;
  cardBrand?: Maybe<CardBrand>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsInput>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsInput>;
  type?: Maybe<StripePaymentMethodType>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodInput>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodInput>;
};

export type StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  where: StripePaymentMethodWhereUniqueInput;
  update: StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput;
  create: StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput;
};

export type StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  where: StripePaymentMethodWhereUniqueInput;
  update: StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput;
};

export type StripePaymentMethodUpsertWithoutCareStripeSubscriptionsInput = {
  update: StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput;
  create: StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput;
};

export type StripePaymentMethodUpsertWithoutStripeInvoicesInput = {
  update: StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput;
};

export type StripePaymentMethodUpsertWithoutStripePaymentIntentsInput = {
  update: StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type StripePaymentMethodWhereInput = {
  AND?: Maybe<Array<StripePaymentMethodWhereInput>>;
  OR?: Maybe<Array<StripePaymentMethodWhereInput>>;
  NOT?: Maybe<Array<StripePaymentMethodWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  stripeId?: Maybe<StringFilter>;
  last4?: Maybe<StringFilter>;
  fingerprint?: Maybe<StringNullableFilter>;
  isDefault?: Maybe<BoolFilter>;
  isExpired?: Maybe<BoolFilter>;
  isReusable?: Maybe<BoolFilter>;
  funding?: Maybe<EnumCardFundingNullableFilter>;
  cardBrand?: Maybe<EnumCardBrandFilter>;
  expirationMonth?: Maybe<IntFilter>;
  expirationYear?: Maybe<IntFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  type?: Maybe<EnumStripePaymentMethodTypeFilter>;
  permitsFutureUsage?: Maybe<BoolFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
  stripeInvoices?: Maybe<StripeInvoiceListRelationFilter>;
};

export type StripePaymentMethodWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  fingerprint_stripeCustomerId?: Maybe<StripePaymentMethodFingerprintStripeCustomerIdCompoundUniqueInput>;
};

export type StripePaymentOutput = {
  __typename?: 'StripePaymentOutput';
  payment: StripePayment;
  paymentIntentClientSecret?: Maybe<Scalars['String']>;
  requiresAction?: Maybe<Scalars['Boolean']>;
};

export enum StripePaymentTransferType {
  TransferData = 'Transfer_Data',
  ApplicationFee = 'Application_Fee',
}

export type StripePayout = {
  __typename?: 'StripePayout';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccount>;
  stripeDisputes: Array<StripeDispute>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  stripeRefunds: Array<StripeRefund>;
  financialTransactions: Array<FinancialTransaction>;
  _count: StripePayoutCountOutputType;
};

export type StripePayoutStripeDisputesArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
  orderBy?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeDisputeScalarFieldEnum>;
};

export type StripePayoutStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};

export type StripePayoutStripeRefundsArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
};

export type StripePayoutFinancialTransactionsArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
};

export type StripePayoutAccount = {
  __typename?: 'StripePayoutAccount';
  id: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  default: Scalars['Boolean'];
  type: StripePayoutAccountType;
  currency: Scalars['String'];
};

export type StripePayoutAccountCountAggregateOutputType = {
  __typename?: 'StripePayoutAccountCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  stripeId: Scalars['Int'];
  last4: Scalars['Int'];
  bankName: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripePayoutAccountCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
};

export type StripePayoutAccountCountOutputType = {
  __typename?: 'StripePayoutAccountCountOutputType';
  stripePayouts: Scalars['Int'];
  clinics: Scalars['Int'];
};

export type StripePayoutAccountCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutCreateNestedManyWithoutStripePayoutAccountInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
};

export type StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput = {
  create?: Maybe<StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput>;
  connectOrCreate?: Maybe<StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput>;
  connect?: Maybe<StripePayoutAccountWhereUniqueInput>;
};

export type StripePayoutAccountCreateOrConnectWithoutClinicsInput = {
  where: StripePayoutAccountWhereUniqueInput;
  create: StripePayoutAccountUncheckedCreateWithoutClinicsInput;
};

export type StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput = {
  where: StripePayoutAccountWhereUniqueInput;
  create: StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput;
};

export type StripePayoutAccountCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutCreateNestedManyWithoutStripePayoutAccountInput>;
};

export type StripePayoutAccountCreateWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountListRelationFilter = {
  every?: Maybe<StripePayoutAccountWhereInput>;
  some?: Maybe<StripePayoutAccountWhereInput>;
  none?: Maybe<StripePayoutAccountWhereInput>;
};

export type StripePayoutAccountMaxAggregateOutputType = {
  __typename?: 'StripePayoutAccountMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
};

export type StripePayoutAccountMinAggregateOutputType = {
  __typename?: 'StripePayoutAccountMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
};

export type StripePayoutAccountOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePayoutAccountOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  Last4 = 'last4',
  BankName = 'bankName',
}

export type StripePayoutAccountOrderByRelevanceInput = {
  fields: Array<StripePayoutAccountOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripePayoutAccountOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
  _count?: Maybe<StripePayoutAccountCountOrderByAggregateInput>;
  _max?: Maybe<StripePayoutAccountMaxOrderByAggregateInput>;
  _min?: Maybe<StripePayoutAccountMinOrderByAggregateInput>;
};

export type StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  bankName?: Maybe<SortOrder>;
  stripePayouts?: Maybe<StripePayoutOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripePayoutAccountOrderByRelevanceInput>;
};

export type StripePayoutAccountRelationFilter = {
  is?: Maybe<StripePayoutAccountWhereInput>;
  isNot?: Maybe<StripePayoutAccountWhereInput>;
};

export enum StripePayoutAccountScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IsDeleted = 'isDeleted',
  StripeId = 'stripeId',
  Last4 = 'last4',
  BankName = 'bankName',
}

export type StripePayoutAccountScalarWhereInput = {
  AND?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  OR?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  NOT?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  stripeId?: Maybe<StringFilter>;
  last4?: Maybe<StringFilter>;
  bankName?: Maybe<StringNullableFilter>;
};

export type StripePayoutAccountScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  last4?: Maybe<StringWithAggregatesFilter>;
  bankName?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum StripePayoutAccountType {
  BankAccount = 'bank_account',
  Card = 'card',
}

export type StripePayoutAccountUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
};

export type StripePayoutAccountUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput>;
};

export type StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  last4: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountUncheckedUpdateManyWithoutClinicsInput = {
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  delete?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
};

export type StripePayoutAccountUncheckedUpdateManyWithoutStripePayoutAccountsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountInput>;
};

export type StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUpdateManyWithoutStripePayoutAccountInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput = {
  where: StripePayoutAccountScalarWhereInput;
  data: StripePayoutAccountUncheckedUpdateManyWithoutStripePayoutAccountsInput;
};

export type StripePayoutAccountUpdateManyWithoutClinicsInput = {
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  delete?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
};

export type StripePayoutAccountUpdateOneWithoutStripePayoutsInput = {
  create?: Maybe<StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput>;
  connectOrCreate?: Maybe<StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput>;
  upsert?: Maybe<StripePayoutAccountUpsertWithoutStripePayoutsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutAccountWhereUniqueInput>;
  update?: Maybe<StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput>;
};

export type StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput = {
  where: StripePayoutAccountWhereUniqueInput;
  data: StripePayoutAccountUncheckedUpdateWithoutClinicsInput;
};

export type StripePayoutAccountUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUpdateManyWithoutStripePayoutAccountInput>;
};

export type StripePayoutAccountUpdateWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUpdateManyWithoutStripePayoutAccountsInput>;
};

export type StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput = {
  where: StripePayoutAccountWhereUniqueInput;
  update: StripePayoutAccountUncheckedUpdateWithoutClinicsInput;
  create: StripePayoutAccountUncheckedCreateWithoutClinicsInput;
};

export type StripePayoutAccountUpsertWithoutStripePayoutsInput = {
  update: StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput;
  create: StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput;
};

export type StripePayoutAccountWhereInput = {
  AND?: Maybe<Array<StripePayoutAccountWhereInput>>;
  OR?: Maybe<Array<StripePayoutAccountWhereInput>>;
  NOT?: Maybe<Array<StripePayoutAccountWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  isDeleted?: Maybe<BoolFilter>;
  stripeId?: Maybe<StringFilter>;
  last4?: Maybe<StringFilter>;
  bankName?: Maybe<StringNullableFilter>;
  stripePayouts?: Maybe<StripePayoutListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
};

export type StripePayoutAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripePayoutAvgAggregateOutputType = {
  __typename?: 'StripePayoutAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type StripePayoutAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type StripePayoutCountAggregateOutputType = {
  __typename?: 'StripePayoutCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  amount: Scalars['Int'];
  arrivalDate: Scalars['Int'];
  status: Scalars['Int'];
  initiatedAt: Scalars['Int'];
  stripePayoutAccountId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripePayoutCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
};

export type StripePayoutCountOutputType = {
  __typename?: 'StripePayoutCountOutputType';
  stripeDisputes: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  stripeRefunds: Scalars['Int'];
  financialTransactions: Scalars['Int'];
};

export type StripePayoutCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
};

export type StripePayoutCreateManyStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateManyStripePayoutAccountInputEnvelope = {
  data: Array<StripePayoutCreateManyStripePayoutAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePayoutCreateNestedManyWithoutStripePayoutAccountInput = {
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
};

export type StripePayoutCreateNestedOneWithoutFinancialTransactionsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutFinancialTransactionsInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateNestedOneWithoutStripeDisputesInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeDisputesInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeDisputesInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateNestedOneWithoutStripeRefundsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeRefundsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeRefundsInput>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
};

export type StripePayoutCreateOrConnectWithoutFinancialTransactionsInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutFinancialTransactionsInput;
};

export type StripePayoutCreateOrConnectWithoutStripeDisputesInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutStripeDisputesInput;
};

export type StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type StripePayoutCreateOrConnectWithoutStripePayoutAccountInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutStripePayoutAccountInput;
};

export type StripePayoutCreateOrConnectWithoutStripeRefundsInput = {
  where: StripePayoutWhereUniqueInput;
  create: StripePayoutUncheckedCreateWithoutStripeRefundsInput;
};

export type StripePayoutCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutCreateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutListRelationFilter = {
  every?: Maybe<StripePayoutWhereInput>;
  some?: Maybe<StripePayoutWhereInput>;
  none?: Maybe<StripePayoutWhereInput>;
};

export type StripePayoutMaxAggregateOutputType = {
  __typename?: 'StripePayoutMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
};

export type StripePayoutMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
};

export type StripePayoutMinAggregateOutputType = {
  __typename?: 'StripePayoutMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
};

export type StripePayoutMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
};

export type StripePayoutOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePayoutOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  StripePayoutAccountId = 'stripePayoutAccountId',
}

export type StripePayoutOrderByRelevanceInput = {
  fields: Array<StripePayoutOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripePayoutOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  _count?: Maybe<StripePayoutCountOrderByAggregateInput>;
  _avg?: Maybe<StripePayoutAvgOrderByAggregateInput>;
  _max?: Maybe<StripePayoutMaxOrderByAggregateInput>;
  _min?: Maybe<StripePayoutMinOrderByAggregateInput>;
  _sum?: Maybe<StripePayoutSumOrderByAggregateInput>;
};

export type StripePayoutOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  stripePayoutAccount?: Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>;
  stripeDisputes?: Maybe<StripeDisputeOrderByRelationAggregateInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  stripeRefunds?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripePayoutOrderByRelevanceInput>;
};

export type StripePayoutRelationFilter = {
  is?: Maybe<StripePayoutWhereInput>;
  isNot?: Maybe<StripePayoutWhereInput>;
};

export enum StripePayoutScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  Amount = 'amount',
  ArrivalDate = 'arrivalDate',
  Status = 'status',
  InitiatedAt = 'initiatedAt',
  StripePayoutAccountId = 'stripePayoutAccountId',
}

export type StripePayoutScalarWhereInput = {
  AND?: Maybe<Array<StripePayoutScalarWhereInput>>;
  OR?: Maybe<Array<StripePayoutScalarWhereInput>>;
  NOT?: Maybe<Array<StripePayoutScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  arrivalDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumStripePayoutStatusFilter>;
  initiatedAt?: Maybe<DateTimeNullableFilter>;
  stripePayoutAccountId?: Maybe<StringNullableFilter>;
};

export type StripePayoutScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  arrivalDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumStripePayoutStatusWithAggregatesFilter>;
  initiatedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripePayoutAccountId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripePayoutSchedule = {
  __typename?: 'StripePayoutSchedule';
  daysDelayed: Scalars['Int'];
  interval: Scalars['String'];
  automatic: Scalars['Boolean'];
};

export enum StripePayoutStatus {
  Paid = 'Paid',
  Pending = 'Pending',
  InTransit = 'In_Transit',
  Canceled = 'Canceled',
  Failed = 'Failed',
}

export type StripePayoutSumAggregateOutputType = {
  __typename?: 'StripePayoutSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type StripePayoutSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type StripePayoutUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput = {
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
};

export type StripePayoutUncheckedCreateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedCreateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status: StripePayoutStatus;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
};

export type StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountInput = {
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  upsert?: Maybe<Array<StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  set?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  delete?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput>>;
  updateMany?: Maybe<Array<StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput>>;
  deleteMany?: Maybe<Array<StripePayoutScalarWhereInput>>;
};

export type StripePayoutUncheckedUpdateManyWithoutStripePayoutsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedUpdateWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUncheckedUpdateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput = {
  where: StripePayoutScalarWhereInput;
  data: StripePayoutUncheckedUpdateManyWithoutStripePayoutsInput;
};

export type StripePayoutUpdateManyWithoutStripePayoutAccountInput = {
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  upsert?: Maybe<Array<StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  set?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  delete?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput>>;
  updateMany?: Maybe<Array<StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput>>;
  deleteMany?: Maybe<Array<StripePayoutScalarWhereInput>>;
};

export type StripePayoutUpdateOneWithoutFinancialTransactionsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutFinancialTransactionsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutFinancialTransactionsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutFinancialTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput>;
};

export type StripePayoutUpdateOneWithoutStripeDisputesInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeDisputesInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeDisputesInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripeDisputesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripeDisputesInput>;
};

export type StripePayoutUpdateOneWithoutStripePaymentIntentsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput>;
};

export type StripePayoutUpdateOneWithoutStripeRefundsInput = {
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeRefundsInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeRefundsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripeRefundsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripeRefundsInput>;
};

export type StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput = {
  where: StripePayoutWhereUniqueInput;
  data: StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput;
};

export type StripePayoutUpdateWithoutFinancialTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUpdateWithoutStripeDisputesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUpdateWithoutStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUpdateWithoutStripePayoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUpdateWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePayoutStatus>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsInput>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutInput>;
};

export type StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput = {
  where: StripePayoutWhereUniqueInput;
  update: StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput;
  create: StripePayoutUncheckedCreateWithoutStripePayoutAccountInput;
};

export type StripePayoutUpsertWithoutFinancialTransactionsInput = {
  update: StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput;
  create: StripePayoutUncheckedCreateWithoutFinancialTransactionsInput;
};

export type StripePayoutUpsertWithoutStripeDisputesInput = {
  update: StripePayoutUncheckedUpdateWithoutStripeDisputesInput;
  create: StripePayoutUncheckedCreateWithoutStripeDisputesInput;
};

export type StripePayoutUpsertWithoutStripePaymentIntentsInput = {
  update: StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput;
  create: StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput;
};

export type StripePayoutUpsertWithoutStripeRefundsInput = {
  update: StripePayoutUncheckedUpdateWithoutStripeRefundsInput;
  create: StripePayoutUncheckedCreateWithoutStripeRefundsInput;
};

export type StripePayoutWhereInput = {
  AND?: Maybe<Array<StripePayoutWhereInput>>;
  OR?: Maybe<Array<StripePayoutWhereInput>>;
  NOT?: Maybe<Array<StripePayoutWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  amount?: Maybe<IntFilter>;
  arrivalDate?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumStripePayoutStatusFilter>;
  initiatedAt?: Maybe<DateTimeNullableFilter>;
  stripePayoutAccountId?: Maybe<StringNullableFilter>;
  stripePayoutAccount?: Maybe<StripePayoutAccountWhereInput>;
  stripeDisputes?: Maybe<StripeDisputeListRelationFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  stripeRefunds?: Maybe<StripeRefundListRelationFilter>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
};

export type StripePayoutWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeReceipt = {
  __typename?: 'StripeReceipt';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  stripeId?: Maybe<Scalars['String']>;
  history: Array<StripeReceiptHistory>;
  payment?: Maybe<StripePaymentIntent>;
  refund?: Maybe<StripeRefund>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
  _count: StripeReceiptCountOutputType;
};

export type StripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
  orderBy?: Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeReceiptHistoryScalarFieldEnum>;
};

export type StripeReceiptCountAggregateOutputType = {
  __typename?: 'StripeReceiptCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  applicationName: Scalars['Int'];
  dedicatedFileName: Scalars['Int'];
  accountType: Scalars['Int'];
  authorizationCode: Scalars['Int'];
  applicationCryptogram: Scalars['Int'];
  terminalVerificationResults: Scalars['Int'];
  transactionStatusInformation: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeReceiptCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export type StripeReceiptCountOutputType = {
  __typename?: 'StripeReceiptCountOutputType';
  history: Scalars['Int'];
};

export type StripeReceiptCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateNestedOneWithoutHistoryInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutHistoryInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutHistoryInput>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
};

export type StripeReceiptCreateNestedOneWithoutPaymentInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutPaymentInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutPaymentInput>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
};

export type StripeReceiptCreateNestedOneWithoutRefundInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutRefundInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutRefundInput>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
};

export type StripeReceiptCreateOrConnectWithoutHistoryInput = {
  where: StripeReceiptWhereUniqueInput;
  create: StripeReceiptUncheckedCreateWithoutHistoryInput;
};

export type StripeReceiptCreateOrConnectWithoutPaymentInput = {
  where: StripeReceiptWhereUniqueInput;
  create: StripeReceiptUncheckedCreateWithoutPaymentInput;
};

export type StripeReceiptCreateOrConnectWithoutRefundInput = {
  where: StripeReceiptWhereUniqueInput;
  create: StripeReceiptUncheckedCreateWithoutRefundInput;
};

export type StripeReceiptCreateWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateWithoutPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateWithoutRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptHistory = {
  __typename?: 'StripeReceiptHistory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  stripeReceiptId: Scalars['String'];
  receipt: StripeReceipt;
  medium: StripeReceiptMedium;
};

export type StripeReceiptHistoryCountAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
  medium: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeReceiptHistoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  receipt: StripeReceiptCreateNestedOneWithoutHistoryInput;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId: Scalars['String'];
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryCreateManyReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryCreateManyReceiptInputEnvelope = {
  data: Array<StripeReceiptHistoryCreateManyReceiptInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeReceiptHistoryCreateNestedManyWithoutReceiptInput = {
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
};

export type StripeReceiptHistoryCreateOrConnectWithoutReceiptInput = {
  where: StripeReceiptHistoryWhereUniqueInput;
  create: StripeReceiptHistoryUncheckedCreateWithoutReceiptInput;
};

export type StripeReceiptHistoryCreateWithoutReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryListRelationFilter = {
  every?: Maybe<StripeReceiptHistoryWhereInput>;
  some?: Maybe<StripeReceiptHistoryWhereInput>;
  none?: Maybe<StripeReceiptHistoryWhereInput>;
};

export type StripeReceiptHistoryMaxAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryMinAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeReceiptHistoryOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeReceiptId = 'stripeReceiptId',
}

export type StripeReceiptHistoryOrderByRelevanceInput = {
  fields: Array<StripeReceiptHistoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeReceiptHistoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
  _count?: Maybe<StripeReceiptHistoryCountOrderByAggregateInput>;
  _max?: Maybe<StripeReceiptHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<StripeReceiptHistoryMinOrderByAggregateInput>;
};

export type StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  receipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  medium?: Maybe<SortOrder>;
  _relevance?: Maybe<StripeReceiptHistoryOrderByRelevanceInput>;
};

export enum StripeReceiptHistoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  StripeReceiptId = 'stripeReceiptId',
  Medium = 'medium',
}

export type StripeReceiptHistoryScalarWhereInput = {
  AND?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  stripeReceiptId?: Maybe<StringFilter>;
  medium?: Maybe<EnumStripeReceiptMediumFilter>;
};

export type StripeReceiptHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringWithAggregatesFilter>;
  medium?: Maybe<EnumStripeReceiptMediumWithAggregatesFilter>;
};

export type StripeReceiptHistoryUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId: Scalars['String'];
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput = {
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
};

export type StripeReceiptHistoryUncheckedCreateWithoutReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateManyWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptInput = {
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  upsert?: Maybe<Array<StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  set?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  update?: Maybe<Array<StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput>>;
  updateMany?: Maybe<Array<StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput>>;
  deleteMany?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
};

export type StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  receipt?: Maybe<StripeReceiptUpdateOneRequiredWithoutHistoryInput>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput = {
  where: StripeReceiptHistoryScalarWhereInput;
  data: StripeReceiptHistoryUncheckedUpdateManyWithoutHistoryInput;
};

export type StripeReceiptHistoryUpdateManyWithoutReceiptInput = {
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  upsert?: Maybe<Array<StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  set?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  update?: Maybe<Array<StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput>>;
  updateMany?: Maybe<Array<StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput>>;
  deleteMany?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
};

export type StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput = {
  where: StripeReceiptHistoryWhereUniqueInput;
  data: StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput;
};

export type StripeReceiptHistoryUpdateWithoutReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput = {
  where: StripeReceiptHistoryWhereUniqueInput;
  update: StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput;
  create: StripeReceiptHistoryUncheckedCreateWithoutReceiptInput;
};

export type StripeReceiptHistoryWhereInput = {
  AND?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  stripeReceiptId?: Maybe<StringFilter>;
  receipt?: Maybe<StripeReceiptWhereInput>;
  medium?: Maybe<EnumStripeReceiptMediumFilter>;
};

export type StripeReceiptHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StripeReceiptMaxAggregateOutputType = {
  __typename?: 'StripeReceiptMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export enum StripeReceiptMedium {
  Email = 'Email',
  Printed = 'Printed',
}

export type StripeReceiptMinAggregateOutputType = {
  __typename?: 'StripeReceiptMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export enum StripeReceiptOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  ApplicationName = 'applicationName',
  DedicatedFileName = 'dedicatedFileName',
  AccountType = 'accountType',
  AuthorizationCode = 'authorizationCode',
  ApplicationCryptogram = 'applicationCryptogram',
  TerminalVerificationResults = 'terminalVerificationResults',
  TransactionStatusInformation = 'transactionStatusInformation',
}

export type StripeReceiptOrderByRelevanceInput = {
  fields: Array<StripeReceiptOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeReceiptOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
  _count?: Maybe<StripeReceiptCountOrderByAggregateInput>;
  _max?: Maybe<StripeReceiptMaxOrderByAggregateInput>;
  _min?: Maybe<StripeReceiptMinOrderByAggregateInput>;
};

export type StripeReceiptOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  history?: Maybe<StripeReceiptHistoryOrderByRelationAggregateInput>;
  payment?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  refund?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  applicationName?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  accountType?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
  _relevance?: Maybe<StripeReceiptOrderByRelevanceInput>;
};

export type StripeReceiptRelationFilter = {
  is?: Maybe<StripeReceiptWhereInput>;
  isNot?: Maybe<StripeReceiptWhereInput>;
};

export enum StripeReceiptScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  StripeId = 'stripeId',
  ApplicationName = 'applicationName',
  DedicatedFileName = 'dedicatedFileName',
  AccountType = 'accountType',
  AuthorizationCode = 'authorizationCode',
  ApplicationCryptogram = 'applicationCryptogram',
  TerminalVerificationResults = 'terminalVerificationResults',
  TransactionStatusInformation = 'transactionStatusInformation',
}

export type StripeReceiptScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringNullableWithAggregatesFilter>;
  applicationName?: Maybe<StringNullableWithAggregatesFilter>;
  dedicatedFileName?: Maybe<StringNullableWithAggregatesFilter>;
  accountType?: Maybe<StringNullableWithAggregatesFilter>;
  authorizationCode?: Maybe<StringNullableWithAggregatesFilter>;
  applicationCryptogram?: Maybe<StringNullableWithAggregatesFilter>;
  terminalVerificationResults?: Maybe<StringNullableWithAggregatesFilter>;
  transactionStatusInformation?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripeReceiptUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedCreateWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedCreateWithoutPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedCreateWithoutRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateWithoutPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateWithoutRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateOneRequiredWithoutHistoryInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutHistoryInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutHistoryInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutHistoryInput>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutHistoryInput>;
};

export type StripeReceiptUpdateOneWithoutPaymentInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutPaymentInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutPaymentInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutPaymentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutPaymentInput>;
};

export type StripeReceiptUpdateOneWithoutRefundInput = {
  create?: Maybe<StripeReceiptUncheckedCreateWithoutRefundInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutRefundInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutRefundInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutRefundInput>;
};

export type StripeReceiptUpdateWithoutHistoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateWithoutPaymentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptInput>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateWithoutRefundInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptInput>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptInput>;
  applicationName?: Maybe<Scalars['String']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpsertWithoutHistoryInput = {
  update: StripeReceiptUncheckedUpdateWithoutHistoryInput;
  create: StripeReceiptUncheckedCreateWithoutHistoryInput;
};

export type StripeReceiptUpsertWithoutPaymentInput = {
  update: StripeReceiptUncheckedUpdateWithoutPaymentInput;
  create: StripeReceiptUncheckedCreateWithoutPaymentInput;
};

export type StripeReceiptUpsertWithoutRefundInput = {
  update: StripeReceiptUncheckedUpdateWithoutRefundInput;
  create: StripeReceiptUncheckedCreateWithoutRefundInput;
};

export type StripeReceiptWhereInput = {
  AND?: Maybe<Array<StripeReceiptWhereInput>>;
  OR?: Maybe<Array<StripeReceiptWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringNullableFilter>;
  history?: Maybe<StripeReceiptHistoryListRelationFilter>;
  payment?: Maybe<StripePaymentIntentWhereInput>;
  refund?: Maybe<StripeRefundWhereInput>;
  applicationName?: Maybe<StringNullableFilter>;
  dedicatedFileName?: Maybe<StringNullableFilter>;
  accountType?: Maybe<StringNullableFilter>;
  authorizationCode?: Maybe<StringNullableFilter>;
  applicationCryptogram?: Maybe<StringNullableFilter>;
  terminalVerificationResults?: Maybe<StringNullableFilter>;
  transactionStatusInformation?: Maybe<StringNullableFilter>;
};

export type StripeReceiptWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeRefund = {
  __typename?: 'StripeRefund';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  reason: RefundReason;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripePayout?: Maybe<StripePayout>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeReceipt?: Maybe<StripeReceipt>;
  issuedByUserId?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<User>;
  financialTransaction?: Maybe<FinancialTransaction>;
};

export type StripeRefundAvgAggregateOutputType = {
  __typename?: 'StripeRefundAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  clientFeeRefunded?: Maybe<Scalars['Float']>;
  clinicFeeRefunded?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type StripeRefundAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeRefundCountAggregateOutputType = {
  __typename?: 'StripeRefundCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  reason: Scalars['Int'];
  reasonOther: Scalars['Int'];
  amount: Scalars['Int'];
  clientFeeRefunded: Scalars['Int'];
  clinicFeeRefunded: Scalars['Int'];
  total: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
  issuedByUserId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeRefundCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
};

export type StripeRefundCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundCreateManyIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeRefundCreateManyIssuedByInputEnvelope = {
  data: Array<StripeRefundCreateManyIssuedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateManyStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundCreateManyStripePaymentIntentInputEnvelope = {
  data: Array<StripeRefundCreateManyStripePaymentIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateManyStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundCreateManyStripePayoutInputEnvelope = {
  data: Array<StripeRefundCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateNestedManyWithoutIssuedByInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundCreateNestedManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundCreateNestedOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutFinancialTransactionInput>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
};

export type StripeRefundCreateNestedOneWithoutStripeReceiptInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
};

export type StripeRefundCreateOrConnectWithoutFinancialTransactionInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeRefundCreateOrConnectWithoutIssuedByInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutIssuedByInput;
};

export type StripeRefundCreateOrConnectWithoutStripePaymentIntentInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutStripePaymentIntentInput;
};

export type StripeRefundCreateOrConnectWithoutStripePayoutInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutStripePayoutInput;
};

export type StripeRefundCreateOrConnectWithoutStripeReceiptInput = {
  where: StripeRefundWhereUniqueInput;
  create: StripeRefundUncheckedCreateWithoutStripeReceiptInput;
};

export type StripeRefundCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
};

export type StripeRefundCreateWithoutIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundCreateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundListRelationFilter = {
  every?: Maybe<StripeRefundWhereInput>;
  some?: Maybe<StripeRefundWhereInput>;
  none?: Maybe<StripeRefundWhereInput>;
};

export type StripeRefundMaxAggregateOutputType = {
  __typename?: 'StripeRefundMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
};

export type StripeRefundMinAggregateOutputType = {
  __typename?: 'StripeRefundMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
};

export type StripeRefundOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeRefundOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  ReasonOther = 'reasonOther',
  StripePayoutId = 'stripePayoutId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripeReceiptId = 'stripeReceiptId',
  IssuedByUserId = 'issuedByUserId',
}

export type StripeRefundOrderByRelevanceInput = {
  fields: Array<StripeRefundOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeRefundOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  _count?: Maybe<StripeRefundCountOrderByAggregateInput>;
  _avg?: Maybe<StripeRefundAvgOrderByAggregateInput>;
  _max?: Maybe<StripeRefundMaxOrderByAggregateInput>;
  _min?: Maybe<StripeRefundMinOrderByAggregateInput>;
  _sum?: Maybe<StripeRefundSumOrderByAggregateInput>;
};

export type StripeRefundOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  stripeReceiptId?: Maybe<SortOrder>;
  stripeReceipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  issuedByUserId?: Maybe<SortOrder>;
  issuedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripeRefundOrderByRelevanceInput>;
};

export enum StripeRefundReason {
  Duplicate = 'Duplicate',
  Fraudulent = 'Fraudulent',
  RequestedByCustomer = 'Requested_By_Customer',
  PartialRefund = 'Partial_Refund',
  Other = 'Other',
}

export type StripeRefundRelationFilter = {
  is?: Maybe<StripeRefundWhereInput>;
  isNot?: Maybe<StripeRefundWhereInput>;
};

export enum StripeRefundScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  Reason = 'reason',
  ReasonOther = 'reasonOther',
  Amount = 'amount',
  ClientFeeRefunded = 'clientFeeRefunded',
  ClinicFeeRefunded = 'clinicFeeRefunded',
  Total = 'total',
  StripePayoutId = 'stripePayoutId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripeReceiptId = 'stripeReceiptId',
  IssuedByUserId = 'issuedByUserId',
}

export type StripeRefundScalarWhereInput = {
  AND?: Maybe<Array<StripeRefundScalarWhereInput>>;
  OR?: Maybe<Array<StripeRefundScalarWhereInput>>;
  NOT?: Maybe<Array<StripeRefundScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  reason?: Maybe<EnumRefundReasonFilter>;
  reasonOther?: Maybe<StringNullableFilter>;
  amount?: Maybe<IntFilter>;
  clientFeeRefunded?: Maybe<IntNullableFilter>;
  clinicFeeRefunded?: Maybe<IntNullableFilter>;
  total?: Maybe<IntNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  issuedByUserId?: Maybe<StringNullableFilter>;
};

export type StripeRefundScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  reason?: Maybe<EnumRefundReasonWithAggregatesFilter>;
  reasonOther?: Maybe<StringNullableWithAggregatesFilter>;
  amount?: Maybe<IntWithAggregatesFilter>;
  clientFeeRefunded?: Maybe<IntNullableWithAggregatesFilter>;
  clinicFeeRefunded?: Maybe<IntNullableWithAggregatesFilter>;
  total?: Maybe<IntNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringNullableWithAggregatesFilter>;
  issuedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripeRefundSumAggregateOutputType = {
  __typename?: 'StripeRefundSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripeRefundSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeRefundUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
};

export type StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
};

export type StripeRefundUncheckedCreateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundUncheckedCreateWithoutIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedCreateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundUncheckedUpdateManyWithoutIssuedByInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutIssuedByInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundUncheckedUpdateOneWithoutStripeReceiptInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutStripeReceiptInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutStripeReceiptInput>;
};

export type StripeRefundUncheckedUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
};

export type StripeRefundUncheckedUpdateWithoutIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUncheckedUpdateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripeRefundUpdateManyWithWhereWithoutIssuedByInput = {
  where: StripeRefundScalarWhereInput;
  data: StripeRefundUncheckedUpdateManyWithoutRefundsIssuedInput;
};

export type StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput = {
  where: StripeRefundScalarWhereInput;
  data: StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput;
};

export type StripeRefundUpdateManyWithWhereWithoutStripePayoutInput = {
  where: StripeRefundScalarWhereInput;
  data: StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput;
};

export type StripeRefundUpdateManyWithoutIssuedByInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutIssuedByInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUpdateManyWithoutStripePaymentIntentInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUpdateManyWithoutStripePayoutInput = {
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePayoutInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
};

export type StripeRefundUpdateOneWithoutFinancialTransactionInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutFinancialTransactionInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutFinancialTransactionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutFinancialTransactionInput>;
};

export type StripeRefundUpdateOneWithoutStripeReceiptInput = {
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutStripeReceiptInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutStripeReceiptInput>;
};

export type StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput = {
  where: StripeRefundWhereUniqueInput;
  data: StripeRefundUncheckedUpdateWithoutIssuedByInput;
};

export type StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput = {
  where: StripeRefundWhereUniqueInput;
  data: StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput;
};

export type StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput = {
  where: StripeRefundWhereUniqueInput;
  data: StripeRefundUncheckedUpdateWithoutStripePayoutInput;
};

export type StripeRefundUpdateWithoutFinancialTransactionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedInput>;
};

export type StripeRefundUpdateWithoutIssuedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUpdateWithoutStripePaymentIntentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUpdateWithoutStripePayoutInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUpdateWithoutStripeReceiptInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsInput>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundInput>;
};

export type StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput = {
  where: StripeRefundWhereUniqueInput;
  update: StripeRefundUncheckedUpdateWithoutIssuedByInput;
  create: StripeRefundUncheckedCreateWithoutIssuedByInput;
};

export type StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput = {
  where: StripeRefundWhereUniqueInput;
  update: StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput;
  create: StripeRefundUncheckedCreateWithoutStripePaymentIntentInput;
};

export type StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput = {
  where: StripeRefundWhereUniqueInput;
  update: StripeRefundUncheckedUpdateWithoutStripePayoutInput;
  create: StripeRefundUncheckedCreateWithoutStripePayoutInput;
};

export type StripeRefundUpsertWithoutFinancialTransactionInput = {
  update: StripeRefundUncheckedUpdateWithoutFinancialTransactionInput;
  create: StripeRefundUncheckedCreateWithoutFinancialTransactionInput;
};

export type StripeRefundUpsertWithoutStripeReceiptInput = {
  update: StripeRefundUncheckedUpdateWithoutStripeReceiptInput;
  create: StripeRefundUncheckedCreateWithoutStripeReceiptInput;
};

export type StripeRefundWhereInput = {
  AND?: Maybe<Array<StripeRefundWhereInput>>;
  OR?: Maybe<Array<StripeRefundWhereInput>>;
  NOT?: Maybe<Array<StripeRefundWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  reason?: Maybe<EnumRefundReasonFilter>;
  reasonOther?: Maybe<StringNullableFilter>;
  amount?: Maybe<IntFilter>;
  clientFeeRefunded?: Maybe<IntNullableFilter>;
  clinicFeeRefunded?: Maybe<IntNullableFilter>;
  total?: Maybe<IntNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  stripeReceipt?: Maybe<StripeReceiptWhereInput>;
  issuedByUserId?: Maybe<StringNullableFilter>;
  issuedBy?: Maybe<UserWhereInput>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
};

export type StripeRefundWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeRequirements = {
  __typename?: 'StripeRequirements';
  deadline?: Maybe<Scalars['Date']>;
  currentlyDue?: Maybe<Array<Scalars['String']>>;
  eventuallyDue?: Maybe<Array<Scalars['String']>>;
  pastDue?: Maybe<Array<Scalars['String']>>;
  disabledReason?: Maybe<Scalars['String']>;
  pendingVerification?: Maybe<Array<Scalars['String']>>;
};

export type StripeTerminal = {
  __typename?: 'StripeTerminal';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  location: StripeTerminalLocation;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments: Array<StripePaymentIntent>;
  _count: StripeTerminalCountOutputType;
};

export type StripeTerminalPaymentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};

export type StripeTerminalCountAggregateOutputType = {
  __typename?: 'StripeTerminalCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  locationId: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  model: Scalars['Int'];
  serialNumber: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeTerminalCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export type StripeTerminalCountOutputType = {
  __typename?: 'StripeTerminalCountOutputType';
  payments: Scalars['Int'];
};

export type StripeTerminalCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  location: StripeTerminalLocationCreateNestedOneWithoutTerminalsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput>;
};

export type StripeTerminalCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
};

export type StripeTerminalCreateManyLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
};

export type StripeTerminalCreateManyLocationInputEnvelope = {
  data: Array<StripeTerminalCreateManyLocationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeTerminalCreateNestedManyWithoutLocationInput = {
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
};

export type StripeTerminalCreateNestedOneWithoutPaymentsInput = {
  create?: Maybe<StripeTerminalUncheckedCreateWithoutPaymentsInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutPaymentsInput>;
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
};

export type StripeTerminalCreateOrConnectWithoutLocationInput = {
  where: StripeTerminalWhereUniqueInput;
  create: StripeTerminalUncheckedCreateWithoutLocationInput;
};

export type StripeTerminalCreateOrConnectWithoutPaymentsInput = {
  where: StripeTerminalWhereUniqueInput;
  create: StripeTerminalUncheckedCreateWithoutPaymentsInput;
};

export type StripeTerminalCreateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput>;
};

export type StripeTerminalCreateWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  location: StripeTerminalLocationCreateNestedOneWithoutTerminalsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
};

export type StripeTerminalListRelationFilter = {
  every?: Maybe<StripeTerminalWhereInput>;
  some?: Maybe<StripeTerminalWhereInput>;
  none?: Maybe<StripeTerminalWhereInput>;
};

export type StripeTerminalLocation = {
  __typename?: 'StripeTerminalLocation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeId: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  terminals: Array<StripeTerminal>;
  clinicId: Scalars['String'];
  clinic: Clinic;
  _count: StripeTerminalLocationCountOutputType;
};

export type StripeTerminalLocationTerminalsArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
  orderBy?: Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeTerminalScalarFieldEnum>;
};

export type StripeTerminalLocationCountAggregateOutputType = {
  __typename?: 'StripeTerminalLocationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  stripeId: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type StripeTerminalLocationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type StripeTerminalLocationCountOutputType = {
  __typename?: 'StripeTerminalLocationCountOutputType';
  terminals: Scalars['Int'];
};

export type StripeTerminalLocationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  terminals?: Maybe<StripeTerminalCreateNestedManyWithoutLocationInput>;
  clinic: ClinicCreateNestedOneWithoutStripeTerminalLocationsInput;
};

export type StripeTerminalLocationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type StripeTerminalLocationCreateManyClinicInputEnvelope = {
  data: Array<StripeTerminalLocationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeTerminalLocationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
};

export type StripeTerminalLocationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
};

export type StripeTerminalLocationCreateNestedOneWithoutTerminalsInput = {
  create?: Maybe<StripeTerminalLocationUncheckedCreateWithoutTerminalsInput>;
  connectOrCreate?: Maybe<StripeTerminalLocationCreateOrConnectWithoutTerminalsInput>;
  connect?: Maybe<StripeTerminalLocationWhereUniqueInput>;
};

export type StripeTerminalLocationCreateOrConnectWithoutClinicInput = {
  where: StripeTerminalLocationWhereUniqueInput;
  create: StripeTerminalLocationUncheckedCreateWithoutClinicInput;
};

export type StripeTerminalLocationCreateOrConnectWithoutTerminalsInput = {
  where: StripeTerminalLocationWhereUniqueInput;
  create: StripeTerminalLocationUncheckedCreateWithoutTerminalsInput;
};

export type StripeTerminalLocationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  terminals?: Maybe<StripeTerminalCreateNestedManyWithoutLocationInput>;
};

export type StripeTerminalLocationCreateWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  clinic: ClinicCreateNestedOneWithoutStripeTerminalLocationsInput;
};

export type StripeTerminalLocationListRelationFilter = {
  every?: Maybe<StripeTerminalLocationWhereInput>;
  some?: Maybe<StripeTerminalLocationWhereInput>;
  none?: Maybe<StripeTerminalLocationWhereInput>;
};

export type StripeTerminalLocationMaxAggregateOutputType = {
  __typename?: 'StripeTerminalLocationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type StripeTerminalLocationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type StripeTerminalLocationMinAggregateOutputType = {
  __typename?: 'StripeTerminalLocationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type StripeTerminalLocationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type StripeTerminalLocationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeTerminalLocationOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  Name = 'name',
  ClinicId = 'clinicId',
}

export type StripeTerminalLocationOrderByRelevanceInput = {
  fields: Array<StripeTerminalLocationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeTerminalLocationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<StripeTerminalLocationCountOrderByAggregateInput>;
  _max?: Maybe<StripeTerminalLocationMaxOrderByAggregateInput>;
  _min?: Maybe<StripeTerminalLocationMinOrderByAggregateInput>;
};

export type StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  terminals?: Maybe<StripeTerminalOrderByRelationAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StripeTerminalLocationOrderByRelevanceInput>;
};

export type StripeTerminalLocationRelationFilter = {
  is?: Maybe<StripeTerminalLocationWhereInput>;
  isNot?: Maybe<StripeTerminalLocationWhereInput>;
};

export enum StripeTerminalLocationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  IsActive = 'isActive',
  Name = 'name',
  ClinicId = 'clinicId',
}

export type StripeTerminalLocationScalarWhereInput = {
  AND?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  OR?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type StripeTerminalLocationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export type StripeTerminalLocationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  terminals?: Maybe<StripeTerminalUncheckedCreateNestedManyWithoutLocationInput>;
  clinicId: Scalars['String'];
};

export type StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
};

export type StripeTerminalLocationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  terminals?: Maybe<StripeTerminalUncheckedCreateNestedManyWithoutLocationInput>;
};

export type StripeTerminalLocationUncheckedCreateWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  clinicId: Scalars['String'];
};

export type StripeTerminalLocationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  terminals?: Maybe<StripeTerminalUncheckedUpdateManyWithoutLocationInput>;
  clinicId?: Maybe<Scalars['String']>;
};

export type StripeTerminalLocationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type StripeTerminalLocationUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
};

export type StripeTerminalLocationUncheckedUpdateManyWithoutStripeTerminalLocationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type StripeTerminalLocationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  terminals?: Maybe<StripeTerminalUncheckedUpdateManyWithoutLocationInput>;
};

export type StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type StripeTerminalLocationUniqueInput = {
  locationId: Scalars['String'];
};

export type StripeTerminalLocationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  terminals?: Maybe<StripeTerminalUpdateManyWithoutLocationInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutStripeTerminalLocationsInput>;
};

export type StripeTerminalLocationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput = {
  where: StripeTerminalLocationScalarWhereInput;
  data: StripeTerminalLocationUncheckedUpdateManyWithoutStripeTerminalLocationsInput;
};

export type StripeTerminalLocationUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
};

export type StripeTerminalLocationUpdateOneRequiredWithoutTerminalsInput = {
  create?: Maybe<StripeTerminalLocationUncheckedCreateWithoutTerminalsInput>;
  connectOrCreate?: Maybe<StripeTerminalLocationCreateOrConnectWithoutTerminalsInput>;
  upsert?: Maybe<StripeTerminalLocationUpsertWithoutTerminalsInput>;
  connect?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  update?: Maybe<StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput>;
};

export type StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput = {
  where: StripeTerminalLocationWhereUniqueInput;
  data: StripeTerminalLocationUncheckedUpdateWithoutClinicInput;
};

export type StripeTerminalLocationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  terminals?: Maybe<StripeTerminalUpdateManyWithoutLocationInput>;
};

export type StripeTerminalLocationUpdateWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutStripeTerminalLocationsInput>;
};

export type StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput = {
  where: StripeTerminalLocationWhereUniqueInput;
  update: StripeTerminalLocationUncheckedUpdateWithoutClinicInput;
  create: StripeTerminalLocationUncheckedCreateWithoutClinicInput;
};

export type StripeTerminalLocationUpsertWithoutTerminalsInput = {
  update: StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput;
  create: StripeTerminalLocationUncheckedCreateWithoutTerminalsInput;
};

export type StripeTerminalLocationWhereInput = {
  AND?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  OR?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  terminals?: Maybe<StripeTerminalListRelationFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type StripeTerminalLocationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeTerminalMaxAggregateOutputType = {
  __typename?: 'StripeTerminalMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export type StripeTerminalMinAggregateOutputType = {
  __typename?: 'StripeTerminalMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export enum StripeTerminalModel {
  VerifoneP400 = 'Verifone_P400',
  BbposWisePosE = 'BBPOS_WisePOS_E',
}

export type StripeTerminalOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeTerminalOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId',
  LocationId = 'locationId',
  Name = 'name',
  SerialNumber = 'serialNumber',
}

export type StripeTerminalOrderByRelevanceInput = {
  fields: Array<StripeTerminalOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type StripeTerminalOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  _count?: Maybe<StripeTerminalCountOrderByAggregateInput>;
  _max?: Maybe<StripeTerminalMaxOrderByAggregateInput>;
  _min?: Maybe<StripeTerminalMinOrderByAggregateInput>;
};

export type StripeTerminalOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  location?: Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  payments?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripeTerminalOrderByRelevanceInput>;
};

export type StripeTerminalRelationFilter = {
  is?: Maybe<StripeTerminalWhereInput>;
  isNot?: Maybe<StripeTerminalWhereInput>;
};

export enum StripeTerminalScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  StripeId = 'stripeId',
  LocationId = 'locationId',
  IsActive = 'isActive',
  Name = 'name',
  Model = 'model',
  SerialNumber = 'serialNumber',
}

export type StripeTerminalScalarWhereInput = {
  AND?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  OR?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  locationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  model?: Maybe<EnumStripeTerminalModelFilter>;
  serialNumber?: Maybe<StringFilter>;
};

export type StripeTerminalScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  locationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  model?: Maybe<EnumStripeTerminalModelWithAggregatesFilter>;
  serialNumber?: Maybe<StringWithAggregatesFilter>;
};

export type StripeTerminalTokenOutput = {
  __typename?: 'StripeTerminalTokenOutput';
  secret: Scalars['String'];
};

export type StripeTerminalUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput>;
};

export type StripeTerminalUncheckedCreateNestedManyWithoutLocationInput = {
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
};

export type StripeTerminalUncheckedCreateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
  payments?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput>;
};

export type StripeTerminalUncheckedCreateWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId: Scalars['String'];
  locationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  model: StripeTerminalModel;
  serialNumber: Scalars['String'];
};

export type StripeTerminalUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalInput>;
};

export type StripeTerminalUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalUncheckedUpdateManyWithoutLocationInput = {
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  upsert?: Maybe<Array<StripeTerminalUpsertWithWhereUniqueWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  set?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: Maybe<Array<StripeTerminalUpdateManyWithWhereWithoutLocationInput>>;
  deleteMany?: Maybe<Array<StripeTerminalScalarWhereInput>>;
};

export type StripeTerminalUncheckedUpdateManyWithoutTerminalsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalUncheckedUpdateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalInput>;
};

export type StripeTerminalUncheckedUpdateWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  location?: Maybe<StripeTerminalLocationUpdateOneRequiredWithoutTerminalsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUpdateManyWithoutStripeTerminalInput>;
};

export type StripeTerminalUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalUpdateManyWithWhereWithoutLocationInput = {
  where: StripeTerminalScalarWhereInput;
  data: StripeTerminalUncheckedUpdateManyWithoutTerminalsInput;
};

export type StripeTerminalUpdateManyWithoutLocationInput = {
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  upsert?: Maybe<Array<StripeTerminalUpsertWithWhereUniqueWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  set?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  disconnect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  delete?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: Maybe<Array<StripeTerminalUpdateManyWithWhereWithoutLocationInput>>;
  deleteMany?: Maybe<Array<StripeTerminalScalarWhereInput>>;
};

export type StripeTerminalUpdateOneWithoutPaymentsInput = {
  create?: Maybe<StripeTerminalUncheckedCreateWithoutPaymentsInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutPaymentsInput>;
  upsert?: Maybe<StripeTerminalUpsertWithoutPaymentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
  update?: Maybe<StripeTerminalUncheckedUpdateWithoutPaymentsInput>;
};

export type StripeTerminalUpdateWithWhereUniqueWithoutLocationInput = {
  where: StripeTerminalWhereUniqueInput;
  data: StripeTerminalUncheckedUpdateWithoutLocationInput;
};

export type StripeTerminalUpdateWithoutLocationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUpdateManyWithoutStripeTerminalInput>;
};

export type StripeTerminalUpdateWithoutPaymentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  location?: Maybe<StripeTerminalLocationUpdateOneRequiredWithoutTerminalsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type StripeTerminalUpsertWithWhereUniqueWithoutLocationInput = {
  where: StripeTerminalWhereUniqueInput;
  update: StripeTerminalUncheckedUpdateWithoutLocationInput;
  create: StripeTerminalUncheckedCreateWithoutLocationInput;
};

export type StripeTerminalUpsertWithoutPaymentsInput = {
  update: StripeTerminalUncheckedUpdateWithoutPaymentsInput;
  create: StripeTerminalUncheckedCreateWithoutPaymentsInput;
};

export type StripeTerminalWhereInput = {
  AND?: Maybe<Array<StripeTerminalWhereInput>>;
  OR?: Maybe<Array<StripeTerminalWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  stripeId?: Maybe<StringFilter>;
  locationId?: Maybe<StringFilter>;
  location?: Maybe<StripeTerminalLocationWhereInput>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  model?: Maybe<EnumStripeTerminalModelFilter>;
  serialNumber?: Maybe<StringFilter>;
  payments?: Maybe<StripePaymentIntentListRelationFilter>;
};

export type StripeTerminalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type SubmitFormResponse = {
  __typename?: 'SubmitFormResponse';
  formSubmission: FormSubmission;
  channelId?: Maybe<Scalars['String']>;
};

export type SubmitPetParentRequestInput = {
  clinicPetParentId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId: Scalars['String'];
  formTemplateId: Scalars['String'];
  completedContent: Scalars['Json'];
};

export type Subscription = {
  __typename?: 'Subscription';
  automationRunActionChanged: AutomationRunActionSubscription;
  recordingStatusChanged: TwilioCallRecordingSubscription;
  channelChanged: ChannelSubscription;
  unreadMessageCountChanged: ChannelSubscription;
  channelMemberChanged: ChannelMemberSubscription;
  channelMessageChanged: ChannelMessageSubscription;
  channelMessageAttachmentChanged: ChannelMessageAttachmentSubscription;
  clinicChanged: ClinicSubscription;
  clinicWidgetRequestChanged: ClinicWidgetRequestSubscription;
  formSubmissionChanged?: Maybe<FormSubmissionSubscription>;
  massTextAlertChanged: MassTextAlertSubscription;
  smsNotificationStatusChanged?: Maybe<SmsNotificationStatusSubscription>;
  stripePaymentIntentChanged: StripePaymentIntentSubscription;
  twilioVerificationChanged: TwilioVerificationSubscription;
  uiRefresh?: Maybe<UiRefresh>;
};

export type SubscriptionAutomationRunActionChangedArgs = {
  where: SubscriptionAutomationRunActionWhereInput;
};

export type SubscriptionRecordingStatusChangedArgs = {
  where: SubscriptionTwilioCallRecordingWhereInput;
};

export type SubscriptionChannelChangedArgs = {
  where: SubscriptionChannelWhereInput;
};

export type SubscriptionUnreadMessageCountChangedArgs = {
  where: SubscriptionChannelWhereInput;
};

export type SubscriptionChannelMemberChangedArgs = {
  where: SubscriptionChannelMemberWhereInput;
};

export type SubscriptionChannelMessageChangedArgs = {
  where: SubscriptionChannelMessageWhereInput;
};

export type SubscriptionChannelMessageAttachmentChangedArgs = {
  where: SubscriptionChannelMessageAttachmentWhereInput;
};

export type SubscriptionClinicChangedArgs = {
  where: SubscriptionClinicWhereInput;
};

export type SubscriptionClinicWidgetRequestChangedArgs = {
  where: SubscriptionClinicWidgetRequestWhereInput;
};

export type SubscriptionFormSubmissionChangedArgs = {
  where?: Maybe<SubscriptionFormSubmissionWhereInput>;
};

export type SubscriptionMassTextAlertChangedArgs = {
  where: SubscriptionMassTextAlertWhereInput;
};

export type SubscriptionSmsNotificationStatusChangedArgs = {
  where: SubscriptionSmsNotificationStatusWhereInput;
};

export type SubscriptionStripePaymentIntentChangedArgs = {
  where: SubscriptionStripePaymentIntentWhereInput;
};

export type SubscriptionTwilioVerificationChangedArgs = {
  where: SubscriptionTwilioVerificationWhereInput;
};

export type SubscriptionAutomationRunActionWhereInput = {
  node: AutomationRunActionWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionChannelMemberWhereInput = {
  node: ChannelMemberWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionChannelMessageAttachmentWhereInput = {
  node: ChannelMessageAttachmentWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionChannelMessageWhereInput = {
  node: ChannelMessageWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionChannelWhereInput = {
  node: ChannelWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionClinicWhereInput = {
  node: ClinicWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionClinicWidgetRequestWhereInput = {
  node: ClinicWidgetRequestWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionFormSubmissionWhereInput = {
  node: FormSubmissionWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionMassTextAlertWhereInput = {
  node: MassTextAlertWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionSmsNotificationStatusWhereInput = {
  node: SmsNotificationStatusWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionStripePaymentIntentWhereInput = {
  node: StripePaymentIntentWhereUniqueInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionTwilioCallRecordingWhereInput = {
  node: TwilioCallRecordingWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SubscriptionTwilioVerificationWhereInput = {
  node: TwilioVerificationWhereInput;
  mutation?: Maybe<Array<MutationType>>;
};

export type SyncManyPimsIntegrationModelInput = {
  clinicId: Scalars['String'];
  models?: Maybe<Array<PimsIntegrationCapability>>;
  syncFrom?: Maybe<Scalars['String']>;
};

export type SyncPimsIntegrationModelInput = {
  clinicId: Scalars['String'];
  model: PimsIntegrationCapability;
  syncFrom?: Maybe<Scalars['String']>;
};

export type SystemMessageCreateInput = {
  body?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  petParentIds?: Maybe<Array<Scalars['String']>>;
};

export type TagEntity = {
  id: Scalars['String'];
  type: TagEntityType;
  label: Scalars['String'];
};

export enum TagEntityType {
  FormSubmission = 'FormSubmission',
}

export type TagInput = {
  name: Scalars['String'];
  tagType?: Maybe<TagType>;
  colorBackground?: Maybe<Scalars['String']>;
  colorText?: Maybe<Scalars['String']>;
  tagEntities?: Maybe<Array<TagEntity>>;
};

export enum TagType {
  Custom = 'Custom',
  PaymentRequested = 'PaymentRequested',
  PaymentReceived = 'PaymentReceived',
  FormSubmitted = 'FormSubmitted',
  FormRequested = 'FormRequested',
}

export type TinyUrl = {
  __typename?: 'TinyUrl';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  code: Scalars['String'];
  url: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type TinyUrlCountAggregateOutputType = {
  __typename?: 'TinyUrlCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  code: Scalars['Int'];
  url: Scalars['Int'];
  isActive: Scalars['Int'];
  token: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TinyUrlCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
};

export type TinyUrlCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  url: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type TinyUrlCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  url: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type TinyUrlMaxAggregateOutputType = {
  __typename?: 'TinyUrlMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
};

export type TinyUrlMinAggregateOutputType = {
  __typename?: 'TinyUrlMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
};

export enum TinyUrlOrderByRelevanceFieldEnum {
  Id = 'id',
  Code = 'code',
  Url = 'url',
  Token = 'token',
}

export type TinyUrlOrderByRelevanceInput = {
  fields: Array<TinyUrlOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TinyUrlOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  _count?: Maybe<TinyUrlCountOrderByAggregateInput>;
  _max?: Maybe<TinyUrlMaxOrderByAggregateInput>;
  _min?: Maybe<TinyUrlMinOrderByAggregateInput>;
};

export type TinyUrlOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  _relevance?: Maybe<TinyUrlOrderByRelevanceInput>;
};

export enum TinyUrlScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Code = 'code',
  Url = 'url',
  IsActive = 'isActive',
  Token = 'token',
}

export type TinyUrlScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  code?: Maybe<StringWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  token?: Maybe<StringWithAggregatesFilter>;
};

export type TinyUrlUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  url: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type TinyUrlUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type TinyUrlWhereInput = {
  AND?: Maybe<Array<TinyUrlWhereInput>>;
  OR?: Maybe<Array<TinyUrlWhereInput>>;
  NOT?: Maybe<Array<TinyUrlWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  code?: Maybe<StringFilter>;
  url?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  token?: Maybe<StringFilter>;
};

export type TinyUrlWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type TokenOutput = {
  __typename?: 'TokenOutput';
  token: Scalars['String'];
};

export type TokenValidationInput = {
  clinicPetParentId: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source: PaymentSource;
  consultation?: Maybe<Consultation>;
  disbursement?: Maybe<Disbursement>;
};

export type TransactionAvgAggregateOutputType = {
  __typename?: 'TransactionAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
};

export type TransactionAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
};

export type TransactionCountAggregateOutputType = {
  __typename?: 'TransactionCountAggregateOutputType';
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  transactionId: Scalars['Int'];
  status: Scalars['Int'];
  createdAt: Scalars['Int'];
  settlementDate: Scalars['Int'];
  amount: Scalars['Int'];
  amountForSettlement: Scalars['Int'];
  serviceFeeAmount: Scalars['Int'];
  cardType: Scalars['Int'];
  cardLast4: Scalars['Int'];
  amountSettled: Scalars['Int'];
  clinicEarnings: Scalars['Int'];
  consultationId: Scalars['Int'];
  disbursementId: Scalars['Int'];
  braintreeGraphqlId: Scalars['Int'];
  disbursementDate: Scalars['Int'];
  source: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type TransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutTransactionsInput>;
  disbursement?: Maybe<DisbursementCreateNestedOneWithoutTransactionsInput>;
};

export type TransactionCreateManyConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionCreateManyConsultationInputEnvelope = {
  data: Array<TransactionCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionCreateManyDisbursementInputEnvelope = {
  data: Array<TransactionCreateManyDisbursementInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionCreateNestedManyWithoutDisbursementInput = {
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionCreateOrConnectWithoutConsultationInput = {
  where: TransactionWhereUniqueInput;
  create: TransactionUncheckedCreateWithoutConsultationInput;
};

export type TransactionCreateOrConnectWithoutDisbursementInput = {
  where: TransactionWhereUniqueInput;
  create: TransactionUncheckedCreateWithoutDisbursementInput;
};

export type TransactionCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  disbursement?: Maybe<DisbursementCreateNestedOneWithoutTransactionsInput>;
};

export type TransactionCreateWithoutDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutTransactionsInput>;
};

export type TransactionListRelationFilter = {
  every?: Maybe<TransactionWhereInput>;
  some?: Maybe<TransactionWhereInput>;
  none?: Maybe<TransactionWhereInput>;
};

export type TransactionMaxAggregateOutputType = {
  __typename?: 'TransactionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type TransactionMinAggregateOutputType = {
  __typename?: 'TransactionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TransactionOrderByRelevanceFieldEnum {
  Id = 'id',
  TransactionId = 'transactionId',
  Status = 'status',
  CardType = 'cardType',
  CardLast4 = 'cardLast4',
  ConsultationId = 'consultationId',
  DisbursementId = 'disbursementId',
  BraintreeGraphqlId = 'braintreeGraphqlId',
  DisbursementDate = 'disbursementDate',
}

export type TransactionOrderByRelevanceInput = {
  fields: Array<TransactionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  _count?: Maybe<TransactionCountOrderByAggregateInput>;
  _avg?: Maybe<TransactionAvgOrderByAggregateInput>;
  _max?: Maybe<TransactionMaxOrderByAggregateInput>;
  _min?: Maybe<TransactionMinOrderByAggregateInput>;
  _sum?: Maybe<TransactionSumOrderByAggregateInput>;
};

export type TransactionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  disbursement?: Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TransactionOrderByRelevanceInput>;
};

export enum TransactionScalarFieldEnum {
  Id = 'id',
  UpdatedAt = 'updatedAt',
  TransactionId = 'transactionId',
  Status = 'status',
  CreatedAt = 'createdAt',
  SettlementDate = 'settlementDate',
  Amount = 'amount',
  AmountForSettlement = 'amountForSettlement',
  ServiceFeeAmount = 'serviceFeeAmount',
  CardType = 'cardType',
  CardLast4 = 'cardLast4',
  AmountSettled = 'amountSettled',
  ClinicEarnings = 'clinicEarnings',
  ConsultationId = 'consultationId',
  DisbursementId = 'disbursementId',
  BraintreeGraphqlId = 'braintreeGraphqlId',
  DisbursementDate = 'disbursementDate',
  Source = 'source',
}

export type TransactionScalarWhereInput = {
  AND?: Maybe<Array<TransactionScalarWhereInput>>;
  OR?: Maybe<Array<TransactionScalarWhereInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  transactionId?: Maybe<StringFilter>;
  status?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  settlementDate?: Maybe<DateTimeNullableFilter>;
  amount?: Maybe<FloatFilter>;
  amountForSettlement?: Maybe<FloatNullableFilter>;
  serviceFeeAmount?: Maybe<FloatNullableFilter>;
  cardType?: Maybe<StringFilter>;
  cardLast4?: Maybe<StringFilter>;
  amountSettled?: Maybe<FloatNullableFilter>;
  clinicEarnings?: Maybe<FloatNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  disbursementId?: Maybe<StringNullableFilter>;
  braintreeGraphqlId?: Maybe<StringNullableFilter>;
  disbursementDate?: Maybe<StringNullableFilter>;
  source?: Maybe<EnumPaymentSourceFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  transactionId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  settlementDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  amountForSettlement?: Maybe<FloatNullableWithAggregatesFilter>;
  serviceFeeAmount?: Maybe<FloatNullableWithAggregatesFilter>;
  cardType?: Maybe<StringWithAggregatesFilter>;
  cardLast4?: Maybe<StringWithAggregatesFilter>;
  amountSettled?: Maybe<FloatNullableWithAggregatesFilter>;
  clinicEarnings?: Maybe<FloatNullableWithAggregatesFilter>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  disbursementId?: Maybe<StringNullableWithAggregatesFilter>;
  braintreeGraphqlId?: Maybe<StringNullableWithAggregatesFilter>;
  disbursementDate?: Maybe<StringNullableWithAggregatesFilter>;
  source?: Maybe<EnumPaymentSourceWithAggregatesFilter>;
};

export type TransactionSumAggregateOutputType = {
  __typename?: 'TransactionSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
};

export type TransactionSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
};

export type TransactionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedCreateNestedManyWithoutConsultationInput = {
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionUncheckedCreateNestedManyWithoutDisbursementInput = {
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionUncheckedCreateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedCreateWithoutDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId: Scalars['String'];
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType: Scalars['String'];
  cardLast4: Scalars['String'];
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateManyWithoutConsultationInput = {
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUncheckedUpdateManyWithoutDisbursementInput = {
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutDisbursementInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutDisbursementInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUncheckedUpdateManyWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  disbursementId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUncheckedUpdateWithoutDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  consultation?: Maybe<ConsultationUpdateOneWithoutTransactionsInput>;
  disbursement?: Maybe<DisbursementUpdateOneWithoutTransactionsInput>;
};

export type TransactionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
};

export type TransactionUpdateManyWithWhereWithoutConsultationInput = {
  where: TransactionScalarWhereInput;
  data: TransactionUncheckedUpdateManyWithoutTransactionsInput;
};

export type TransactionUpdateManyWithWhereWithoutDisbursementInput = {
  where: TransactionScalarWhereInput;
  data: TransactionUncheckedUpdateManyWithoutTransactionsInput;
};

export type TransactionUpdateManyWithoutConsultationInput = {
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutConsultationInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUpdateManyWithoutDisbursementInput = {
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutDisbursementInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutDisbursementInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
};

export type TransactionUpdateWithWhereUniqueWithoutConsultationInput = {
  where: TransactionWhereUniqueInput;
  data: TransactionUncheckedUpdateWithoutConsultationInput;
};

export type TransactionUpdateWithWhereUniqueWithoutDisbursementInput = {
  where: TransactionWhereUniqueInput;
  data: TransactionUncheckedUpdateWithoutDisbursementInput;
};

export type TransactionUpdateWithoutConsultationInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  disbursement?: Maybe<DisbursementUpdateOneWithoutTransactionsInput>;
};

export type TransactionUpdateWithoutDisbursementInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  cardType?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  disbursementDate?: Maybe<Scalars['String']>;
  source?: Maybe<PaymentSource>;
  consultation?: Maybe<ConsultationUpdateOneWithoutTransactionsInput>;
};

export type TransactionUpsertWithWhereUniqueWithoutConsultationInput = {
  where: TransactionWhereUniqueInput;
  update: TransactionUncheckedUpdateWithoutConsultationInput;
  create: TransactionUncheckedCreateWithoutConsultationInput;
};

export type TransactionUpsertWithWhereUniqueWithoutDisbursementInput = {
  where: TransactionWhereUniqueInput;
  update: TransactionUncheckedUpdateWithoutDisbursementInput;
  create: TransactionUncheckedCreateWithoutDisbursementInput;
};

export type TransactionWhereInput = {
  AND?: Maybe<Array<TransactionWhereInput>>;
  OR?: Maybe<Array<TransactionWhereInput>>;
  NOT?: Maybe<Array<TransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  transactionId?: Maybe<StringFilter>;
  status?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  settlementDate?: Maybe<DateTimeNullableFilter>;
  amount?: Maybe<FloatFilter>;
  amountForSettlement?: Maybe<FloatNullableFilter>;
  serviceFeeAmount?: Maybe<FloatNullableFilter>;
  cardType?: Maybe<StringFilter>;
  cardLast4?: Maybe<StringFilter>;
  amountSettled?: Maybe<FloatNullableFilter>;
  clinicEarnings?: Maybe<FloatNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  disbursementId?: Maybe<StringNullableFilter>;
  braintreeGraphqlId?: Maybe<StringNullableFilter>;
  disbursementDate?: Maybe<StringNullableFilter>;
  source?: Maybe<EnumPaymentSourceFilter>;
  consultation?: Maybe<ConsultationWhereInput>;
  disbursement?: Maybe<DisbursementWhereInput>;
};

export type TransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
};

export type TransferTwilioNumberInput = {
  phoneNumberSid: Scalars['String'];
  fromSubaccountSid?: Maybe<Scalars['String']>;
};

export type TriggerMessageEventInput = {
  data?: Maybe<Scalars['Json']>;
  event?: Maybe<Scalars['String']>;
};

export type TriggerWorkflowEventInput = {
  entityId: Scalars['String'];
  event: Scalars['String'];
  actionNumber?: Maybe<Scalars['Int']>;
  actionId?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
  workflowId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecording = {
  __typename?: 'TwilioCallRecording';
  sid: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: TwilioRecordingStatus;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
  callRecording: CallRecording;
  callRecordingId: Scalars['String'];
};

export type TwilioCallRecordingAvgAggregateOutputType = {
  __typename?: 'TwilioCallRecordingAvgAggregateOutputType';
  errorCode?: Maybe<Scalars['Float']>;
};

export type TwilioCallRecordingAvgOrderByAggregateInput = {
  errorCode?: Maybe<SortOrder>;
};

export type TwilioCallRecordingCountAggregateOutputType = {
  __typename?: 'TwilioCallRecordingCountAggregateOutputType';
  sid: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  uri: Scalars['Int'];
  errorCode: Scalars['Int'];
  callRecordingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TwilioCallRecordingCountOrderByAggregateInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
};

export type TwilioCallRecordingCreateInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
  callRecording: CallRecordingCreateNestedOneWithoutTwilioCallRecordingInput;
};

export type TwilioCallRecordingCreateManyInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId: Scalars['String'];
};

export type TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput = {
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
};

export type TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput = {
  where: TwilioCallRecordingWhereUniqueInput;
  create: TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput;
};

export type TwilioCallRecordingCreateWithoutCallRecordingInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingMaxAggregateOutputType = {
  __typename?: 'TwilioCallRecordingMaxAggregateOutputType';
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingMaxOrderByAggregateInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
};

export type TwilioCallRecordingMinAggregateOutputType = {
  __typename?: 'TwilioCallRecordingMinAggregateOutputType';
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingMinOrderByAggregateInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
};

export enum TwilioCallRecordingOrderByRelevanceFieldEnum {
  Sid = 'sid',
  Uri = 'uri',
  CallRecordingId = 'callRecordingId',
}

export type TwilioCallRecordingOrderByRelevanceInput = {
  fields: Array<TwilioCallRecordingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TwilioCallRecordingOrderByWithAggregationInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecordingId?: Maybe<SortOrder>;
  _count?: Maybe<TwilioCallRecordingCountOrderByAggregateInput>;
  _avg?: Maybe<TwilioCallRecordingAvgOrderByAggregateInput>;
  _max?: Maybe<TwilioCallRecordingMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioCallRecordingMinOrderByAggregateInput>;
  _sum?: Maybe<TwilioCallRecordingSumOrderByAggregateInput>;
};

export type TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput = {
  sid?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  callRecording?: Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  callRecordingId?: Maybe<SortOrder>;
  _relevance?: Maybe<TwilioCallRecordingOrderByRelevanceInput>;
};

export type TwilioCallRecordingRelationFilter = {
  is?: Maybe<TwilioCallRecordingWhereInput>;
  isNot?: Maybe<TwilioCallRecordingWhereInput>;
};

export enum TwilioCallRecordingScalarFieldEnum {
  Sid = 'sid',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  Uri = 'uri',
  ErrorCode = 'errorCode',
  CallRecordingId = 'callRecordingId',
}

export type TwilioCallRecordingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  sid?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumTwilioRecordingStatusWithAggregatesFilter>;
  uri?: Maybe<StringWithAggregatesFilter>;
  errorCode?: Maybe<IntNullableWithAggregatesFilter>;
  callRecordingId?: Maybe<StringWithAggregatesFilter>;
};

export type TwilioCallRecordingSubscription = {
  __typename?: 'TwilioCallRecordingSubscription';
  node: TwilioCallRecording;
  mutation: MutationType;
};

export type TwilioCallRecordingSumAggregateOutputType = {
  __typename?: 'TwilioCallRecordingSumAggregateOutputType';
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingSumOrderByAggregateInput = {
  errorCode?: Maybe<SortOrder>;
};

export type TwilioCallRecordingUncheckedCreateInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId: Scalars['String'];
};

export type TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput = {
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
};

export type TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput = {
  sid: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri: Scalars['String'];
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingUncheckedUpdateInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUncheckedUpdateManyInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingInput = {
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  upsert?: Maybe<TwilioCallRecordingUpsertWithoutCallRecordingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  update?: Maybe<TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput>;
};

export type TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingUpdateInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
  callRecording?: Maybe<CallRecordingUpdateOneRequiredWithoutTwilioCallRecordingInput>;
};

export type TwilioCallRecordingUpdateManyMutationInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingUpdateOneWithoutCallRecordingInput = {
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  upsert?: Maybe<TwilioCallRecordingUpsertWithoutCallRecordingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  update?: Maybe<TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput>;
};

export type TwilioCallRecordingUpdateWithoutCallRecordingInput = {
  sid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioRecordingStatus>;
  uri?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingUpsertWithoutCallRecordingInput = {
  update: TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput;
  create: TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput;
};

export type TwilioCallRecordingWhereInput = {
  AND?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  OR?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  NOT?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  sid?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumTwilioRecordingStatusFilter>;
  uri?: Maybe<StringFilter>;
  errorCode?: Maybe<IntNullableFilter>;
  callRecording?: Maybe<CallRecordingWhereInput>;
  callRecordingId?: Maybe<StringFilter>;
};

export type TwilioCallRecordingWhereUniqueInput = {
  sid?: Maybe<Scalars['String']>;
  callRecordingId?: Maybe<Scalars['String']>;
};

export type TwilioCallerIdVerification = {
  __typename?: 'TwilioCallerIdVerification';
  callSid: Scalars['String'];
  validationCode: Scalars['String'];
};

export type TwilioCapabilityToken = {
  __typename?: 'TwilioCapabilityToken';
  token: Scalars['String'];
};

export enum TwilioCapabilityType {
  Phone = 'Phone',
  Video = 'Video',
}

export type TwilioConfiguration = {
  __typename?: 'TwilioConfiguration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationCountAggregateOutputType = {
  __typename?: 'TwilioConfigurationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  subaccountSid: Scalars['Int'];
  messagingServiceSid: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TwilioConfigurationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
};

export type TwilioConfigurationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutTwilioConfigurationInput;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationCreateNestedOneWithoutClinicInput = {
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
};

export type TwilioConfigurationCreateOrConnectWithoutClinicInput = {
  where: TwilioConfigurationWhereUniqueInput;
  create: TwilioConfigurationUncheckedCreateWithoutClinicInput;
};

export type TwilioConfigurationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationMaxAggregateOutputType = {
  __typename?: 'TwilioConfigurationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
};

export type TwilioConfigurationMinAggregateOutputType = {
  __typename?: 'TwilioConfigurationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
};

export enum TwilioConfigurationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  SubaccountSid = 'subaccountSid',
  MessagingServiceSid = 'messagingServiceSid',
}

export type TwilioConfigurationOrderByRelevanceInput = {
  fields: Array<TwilioConfigurationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TwilioConfigurationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  _count?: Maybe<TwilioConfigurationCountOrderByAggregateInput>;
  _max?: Maybe<TwilioConfigurationMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioConfigurationMinOrderByAggregateInput>;
};

export type TwilioConfigurationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  _relevance?: Maybe<TwilioConfigurationOrderByRelevanceInput>;
};

export type TwilioConfigurationRelationFilter = {
  is?: Maybe<TwilioConfigurationWhereInput>;
  isNot?: Maybe<TwilioConfigurationWhereInput>;
};

export enum TwilioConfigurationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  SubaccountSid = 'subaccountSid',
  MessagingServiceSid = 'messagingServiceSid',
}

export type TwilioConfigurationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  subaccountSid?: Maybe<StringNullableWithAggregatesFilter>;
  messagingServiceSid?: Maybe<StringNullableWithAggregatesFilter>;
};

export type TwilioConfigurationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput = {
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
};

export type TwilioConfigurationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUncheckedUpdateOneWithoutClinicInput = {
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<TwilioConfigurationUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
  update?: Maybe<TwilioConfigurationUncheckedUpdateWithoutClinicInput>;
};

export type TwilioConfigurationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutTwilioConfigurationInput>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUpdateOneWithoutClinicInput = {
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  upsert?: Maybe<TwilioConfigurationUpsertWithoutClinicInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
  update?: Maybe<TwilioConfigurationUncheckedUpdateWithoutClinicInput>;
};

export type TwilioConfigurationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  subaccountSid?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
};

export type TwilioConfigurationUpsertWithoutClinicInput = {
  update: TwilioConfigurationUncheckedUpdateWithoutClinicInput;
  create: TwilioConfigurationUncheckedCreateWithoutClinicInput;
};

export type TwilioConfigurationWhereInput = {
  AND?: Maybe<Array<TwilioConfigurationWhereInput>>;
  OR?: Maybe<Array<TwilioConfigurationWhereInput>>;
  NOT?: Maybe<Array<TwilioConfigurationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  subaccountSid?: Maybe<StringNullableFilter>;
  messagingServiceSid?: Maybe<StringNullableFilter>;
};

export type TwilioConfigurationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export enum TwilioRecordingStatus {
  Completed = 'Completed',
  Processing = 'Processing',
  Absent = 'Absent',
  Paused = 'Paused',
  InProgress = 'InProgress',
  Stopped = 'Stopped',
}

export type TwilioVerification = {
  __typename?: 'TwilioVerification';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: TwilioVerificationStatus;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
  clinicId: Scalars['String'];
  clinic: Clinic;
};

export type TwilioVerificationCountAggregateOutputType = {
  __typename?: 'TwilioVerificationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  status: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  twilioCallSid: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TwilioVerificationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type TwilioVerificationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
  clinic: ClinicCreateNestedOneWithoutTwilioVerificationsInput;
};

export type TwilioVerificationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
};

export type TwilioVerificationCreateManyClinicInputEnvelope = {
  data: Array<TwilioVerificationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TwilioVerificationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
  clinicId: Scalars['String'];
};

export type TwilioVerificationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
};

export type TwilioVerificationCreateOrConnectWithoutClinicInput = {
  where: TwilioVerificationWhereUniqueInput;
  create: TwilioVerificationUncheckedCreateWithoutClinicInput;
};

export type TwilioVerificationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
};

export type TwilioVerificationListRelationFilter = {
  every?: Maybe<TwilioVerificationWhereInput>;
  some?: Maybe<TwilioVerificationWhereInput>;
  none?: Maybe<TwilioVerificationWhereInput>;
};

export type TwilioVerificationMaxAggregateOutputType = {
  __typename?: 'TwilioVerificationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type TwilioVerificationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type TwilioVerificationMinAggregateOutputType = {
  __typename?: 'TwilioVerificationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type TwilioVerificationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type TwilioVerificationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TwilioVerificationOrderByRelevanceFieldEnum {
  Id = 'id',
  PhoneNumber = 'phoneNumber',
  TwilioCallSid = 'twilioCallSid',
  ClinicId = 'clinicId',
}

export type TwilioVerificationOrderByRelevanceInput = {
  fields: Array<TwilioVerificationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type TwilioVerificationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<TwilioVerificationCountOrderByAggregateInput>;
  _max?: Maybe<TwilioVerificationMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioVerificationMinOrderByAggregateInput>;
};

export type TwilioVerificationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TwilioVerificationOrderByRelevanceInput>;
};

export enum TwilioVerificationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Status = 'status',
  PhoneNumber = 'phoneNumber',
  TwilioCallSid = 'twilioCallSid',
  ClinicId = 'clinicId',
}

export type TwilioVerificationScalarWhereInput = {
  AND?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  OR?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  NOT?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumTwilioVerificationStatusFilter>;
  phoneNumber?: Maybe<StringFilter>;
  twilioCallSid?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
};

export type TwilioVerificationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumTwilioVerificationStatusWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  twilioCallSid?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
};

export enum TwilioVerificationStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

export type TwilioVerificationSubscription = {
  __typename?: 'TwilioVerificationSubscription';
  node: TwilioVerification;
  mutation: MutationType;
};

export type TwilioVerificationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
  clinicId: Scalars['String'];
};

export type TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
};

export type TwilioVerificationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber: Scalars['String'];
  twilioCallSid: Scalars['String'];
};

export type TwilioVerificationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  delete?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  update?: Maybe<Array<TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<TwilioVerificationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
};

export type TwilioVerificationUncheckedUpdateManyWithoutTwilioVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutTwilioVerificationsInput>;
};

export type TwilioVerificationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUpdateManyWithWhereWithoutClinicInput = {
  where: TwilioVerificationScalarWhereInput;
  data: TwilioVerificationUncheckedUpdateManyWithoutTwilioVerificationsInput;
};

export type TwilioVerificationUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  delete?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  update?: Maybe<Array<TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<TwilioVerificationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
};

export type TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput = {
  where: TwilioVerificationWhereUniqueInput;
  data: TwilioVerificationUncheckedUpdateWithoutClinicInput;
};

export type TwilioVerificationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TwilioVerificationStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput = {
  where: TwilioVerificationWhereUniqueInput;
  update: TwilioVerificationUncheckedUpdateWithoutClinicInput;
  create: TwilioVerificationUncheckedCreateWithoutClinicInput;
};

export type TwilioVerificationWhereInput = {
  AND?: Maybe<Array<TwilioVerificationWhereInput>>;
  OR?: Maybe<Array<TwilioVerificationWhereInput>>;
  NOT?: Maybe<Array<TwilioVerificationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumTwilioVerificationStatusFilter>;
  phoneNumber?: Maybe<StringFilter>;
  twilioCallSid?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
};

export type TwilioVerificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerifyCallerIdInput = {
  phoneNumber: Scalars['String'];
  friendlyName: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  callDelay?: Maybe<Scalars['Int']>;
  isCallerId?: Maybe<Scalars['Boolean']>;
};

export type UiRefresh = {
  __typename?: 'UiRefresh';
  refreshType: UiRefreshType;
  message: Scalars['String'];
  metadata?: Maybe<Scalars['Json']>;
};

export type UiRefreshInput = {
  refreshType: UiRefreshType;
  message: Scalars['String'];
  metadata?: Maybe<Scalars['Json']>;
};

export enum UiRefreshType {
  HardRefresh = 'HardRefresh',
  SuggestRefresh = 'SuggestRefresh',
}

export type UnreadMessageChannel = {
  __typename?: 'UnreadMessageChannel';
  channelId: Scalars['String'];
  channelLastMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  unreadMessageCount: Scalars['Int'];
  channelType?: Maybe<ChannelType>;
};

export type UpdateCareSubscriptionInput = {
  addedEnrollments: Array<Maybe<OrganizationPetEnrollmentInput>>;
  cancelledPlanEnrollmentIds?: Maybe<Array<Scalars['String']>>;
  cancelledAddonEnrollmentIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateCareSubscriptionResult = {
  __typename?: 'UpdateCareSubscriptionResult';
  success: Scalars['Boolean'];
};

export type UpdateChannelFilterSelectionInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
};

export type UpdateClinicPostcardSettingDataInput = {
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateClinicPostcardSettingWhereInput = {
  id: Scalars['String'];
};

export type UsageByBenefit = {
  __typename?: 'UsageByBenefit';
  provided: Scalars['Int'];
  used: Scalars['Int'];
  displayOrder?: Maybe<Scalars['Int']>;
  benefit: CareBenefit;
  recentActivity: Array<CareBenefitUsage>;
  sources: BenefitSource;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type: UserType;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired: Scalars['Boolean'];
  invitedById?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfo>;
  invitedBy?: Maybe<User>;
  userSetting?: Maybe<UserSetting>;
  vetInfo?: Maybe<VetInfo>;
  channels: Array<Channel>;
  channelMembers: Array<ChannelMember>;
  channelStatuses: Array<ChannelStatus>;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  clinics: Array<Clinic>;
  /** @deprecated - 1.0 */
  clinicsId: Array<Clinic>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  /** @deprecated - 1.0 */
  consultationChats: Array<ConsultationChat>;
  formSubmissions: Array<FormSubmission>;
  invitations: Array<User>;
  refundsIssued: Array<StripeRefund>;
  /** @deprecated - 1.0 */
  reviews: Array<Review>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id: Array<UserImpersonation>;
  UserImpersonation_UserToUserImpersonation_user_id: Array<UserImpersonation>;
  sentStripePaymentIntents: Array<StripePaymentIntent>;
  sentInvoices: Array<Invoice>;
  calls: Array<CallParticipant>;
  channelSelections: Array<ChannelSelection>;
  triggeredWritebacks: Array<PimsWritebackLogEntry>;
  channelCreationSources: Array<ChannelCreationSource>;
  usagesRecorded: Array<CareBenefitUsage>;
  careBenefitUsageToInvoiceLineItems: Array<CareBenefitUsageToInvoiceLineItem>;
  userChannelFilterSelections: Array<UserChannelFilterSelection>;
  messageTemplates: Array<MessageTemplate>;
  channelMessagesWithMentions: Array<ChannelMessage>;
  manuallySentAutomationRuns: Array<AutomationRun>;
  channelMessageReactions: Array<ChannelMessageReaction>;
  massTextAlerts: Array<MassTextAlert>;
  _count: UserCountOutputType;
};

export type UserChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};

export type UserChannelMembersArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
};

export type UserChannelStatusesArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
};

export type UserChannelStatusChannelAssigneesArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
};

export type UserClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type UserClinicsIdArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type UserClinicWidgetRequestTypesArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};

export type UserConsultationChatsArgs = {
  where?: Maybe<ConsultationChatWhereInput>;
  orderBy?: Maybe<ConsultationChatOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationChatWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationChatScalarFieldEnum>;
};

export type UserFormSubmissionsArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
};

export type UserInvitationsArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};

export type UserRefundsIssuedArgs = {
  where?: Maybe<StripeRefundWhereInput>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
};

export type UserReviewsArgs = {
  where?: Maybe<ReviewWhereInput>;
  orderBy?: Maybe<ReviewOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ReviewWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ReviewScalarFieldEnum>;
};

export type UserUserAppointmentGroupingsArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
};

export type UserUserImpersonation_UserToUserImpersonation_Impersonated_User_IdArgs = {
  where?: Maybe<UserImpersonationWhereInput>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
};

export type UserUserImpersonation_UserToUserImpersonation_User_IdArgs = {
  where?: Maybe<UserImpersonationWhereInput>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
};

export type UserSentStripePaymentIntentsArgs = {
  where?: Maybe<StripePaymentIntentWhereInput>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
};

export type UserSentInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
};

export type UserCallsArgs = {
  where?: Maybe<CallParticipantWhereInput>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
};

export type UserChannelSelectionsArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
  orderBy?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelSelectionScalarFieldEnum>;
};

export type UserTriggeredWritebacksArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
  orderBy?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PimsWritebackLogEntryScalarFieldEnum>;
};

export type UserChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};

export type UserUsagesRecordedArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
};

export type UserCareBenefitUsageToInvoiceLineItemsArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
};

export type UserUserChannelFilterSelectionsArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
  orderBy?: Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserChannelFilterSelectionScalarFieldEnum>;
};

export type UserMessageTemplatesArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MessageTemplateScalarFieldEnum>;
};

export type UserChannelMessagesWithMentionsArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
};

export type UserManuallySentAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type UserChannelMessageReactionsArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
  orderBy?: Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelMessageReactionScalarFieldEnum>;
};

export type UserMassTextAlertsArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<MassTextAlertScalarFieldEnum>;
};

export type UserAppointmentGrouping = {
  __typename?: 'UserAppointmentGrouping';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  user?: Maybe<User>;
  appointmentTypes: Array<AppointmentType>;
  clinicEmployees: Array<ClinicEmployee>;
  rooms: Array<ClinicRoom>;
  _count: UserAppointmentGroupingCountOutputType;
};

export type UserAppointmentGroupingAppointmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};

export type UserAppointmentGroupingClinicEmployeesArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
};

export type UserAppointmentGroupingRoomsArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
};

export type UserAppointmentGroupingCountAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  label: Scalars['Int'];
  userId: Scalars['Int'];
  clinicId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserAppointmentGroupingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingCountOutputType = {
  __typename?: 'UserAppointmentGroupingCountOutputType';
  appointmentTypes: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  rooms: Scalars['Int'];
};

export type UserAppointmentGroupingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingCreateManyClinicInputEnvelope = {
  data: Array<UserAppointmentGroupingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserAppointmentGroupingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingCreateManyUserInputEnvelope = {
  data: Array<UserAppointmentGroupingCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutRoomsInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutClinicInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutRoomsInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutRoomsInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutUserInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutUserInput;
};

export type UserAppointmentGroupingCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingListRelationFilter = {
  every?: Maybe<UserAppointmentGroupingWhereInput>;
  some?: Maybe<UserAppointmentGroupingWhereInput>;
  none?: Maybe<UserAppointmentGroupingWhereInput>;
};

export type UserAppointmentGroupingMaxAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingMinAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserAppointmentGroupingOrderByRelevanceFieldEnum {
  Id = 'id',
  Label = 'label',
  UserId = 'userId',
  ClinicId = 'clinicId',
}

export type UserAppointmentGroupingOrderByRelevanceInput = {
  fields: Array<UserAppointmentGroupingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserAppointmentGroupingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  _count?: Maybe<UserAppointmentGroupingCountOrderByAggregateInput>;
  _max?: Maybe<UserAppointmentGroupingMaxOrderByAggregateInput>;
  _min?: Maybe<UserAppointmentGroupingMinOrderByAggregateInput>;
};

export type UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  rooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  _relevance?: Maybe<UserAppointmentGroupingOrderByRelevanceInput>;
};

export enum UserAppointmentGroupingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Label = 'label',
  UserId = 'userId',
  ClinicId = 'clinicId',
}

export type UserAppointmentGroupingScalarWhereInput = {
  AND?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  label?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
};

export type UserAppointmentGroupingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  label?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserAppointmentGroupingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput = {
  where: UserAppointmentGroupingScalarWhereInput;
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
};

export type UserAppointmentGroupingUpdateManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutClinicEmployeesInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutRoomsInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutUserInput = {
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutClinicInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  data: UserAppointmentGroupingUncheckedUpdateWithoutUserInput;
};

export type UserAppointmentGroupingUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUpdateWithoutRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsInput>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutClinicInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutRoomsInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput = {
  where: UserAppointmentGroupingWhereUniqueInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutUserInput;
  create: UserAppointmentGroupingUncheckedCreateWithoutUserInput;
};

export type UserAppointmentGroupingWhereInput = {
  AND?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  label?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  user?: Maybe<UserWhereInput>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  rooms?: Maybe<ClinicRoomListRelationFilter>;
};

export type UserAppointmentGroupingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserChannelFilterSelection = {
  __typename?: 'UserChannelFilterSelection';
  id: Scalars['String'];
  userId: Scalars['String'];
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
  user: User;
  filterSelection: ChannelFilterSelection;
};

export type UserChannelFilterSelectionCountAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionCountAggregateOutputType';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  filterSelectionId: Scalars['Int'];
  isFilterOwner: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserChannelFilterSelectionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionCreateInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  user: UserCreateNestedOneWithoutUserChannelFilterSelectionsInput;
  filterSelection: ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionCreateManyFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope = {
  data: Array<UserChannelFilterSelectionCreateManyFilterSelectionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionCreateManyUserInputEnvelope = {
  data: Array<UserChannelFilterSelectionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
};

export type UserChannelFilterSelectionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
};

export type UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  create: UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput;
};

export type UserChannelFilterSelectionCreateOrConnectWithoutUserInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  create: UserChannelFilterSelectionUncheckedCreateWithoutUserInput;
};

export type UserChannelFilterSelectionCreateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  user: UserCreateNestedOneWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  filterSelection: ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionListRelationFilter = {
  every?: Maybe<UserChannelFilterSelectionWhereInput>;
  some?: Maybe<UserChannelFilterSelectionWhereInput>;
  none?: Maybe<UserChannelFilterSelectionWhereInput>;
};

export type UserChannelFilterSelectionMaxAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionMinAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserChannelFilterSelectionOrderByRelevanceFieldEnum {
  Id = 'id',
  UserId = 'userId',
  FilterSelectionId = 'filterSelectionId',
}

export type UserChannelFilterSelectionOrderByRelevanceInput = {
  fields: Array<UserChannelFilterSelectionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserChannelFilterSelectionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  _count?: Maybe<UserChannelFilterSelectionCountOrderByAggregateInput>;
  _max?: Maybe<UserChannelFilterSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<UserChannelFilterSelectionMinOrderByAggregateInput>;
};

export type UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  filterSelectionId?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  filterSelection?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<UserChannelFilterSelectionOrderByRelevanceInput>;
};

export enum UserChannelFilterSelectionScalarFieldEnum {
  Id = 'id',
  UserId = 'userId',
  FilterSelectionId = 'filterSelectionId',
  IsFilterOwner = 'isFilterOwner',
}

export type UserChannelFilterSelectionScalarWhereInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  filterSelectionId?: Maybe<StringFilter>;
  isFilterOwner?: Maybe<BoolFilter>;
};

export type UserChannelFilterSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  filterSelectionId?: Maybe<StringWithAggregatesFilter>;
  isFilterOwner?: Maybe<BoolWithAggregatesFilter>;
};

export type UserChannelFilterSelectionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
};

export type UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
};

export type UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  filterSelectionId: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
};

export type UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  filterSelectionId?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateOneRequiredWithoutUserChannelFilterSelectionsInput>;
  filterSelection?: Maybe<ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsInput>;
};

export type UserChannelFilterSelectionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput = {
  where: UserChannelFilterSelectionScalarWhereInput;
  data: UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput = {
  where: UserChannelFilterSelectionScalarWhereInput;
  data: UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionUpdateManyWithoutFilterSelectionInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
};

export type UserChannelFilterSelectionUpdateManyWithoutUserInput = {
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
};

export type UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  data: UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput;
};

export type UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  data: UserChannelFilterSelectionUncheckedUpdateWithoutUserInput;
};

export type UserChannelFilterSelectionUpdateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateOneRequiredWithoutUserChannelFilterSelectionsInput>;
};

export type UserChannelFilterSelectionUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  filterSelection?: Maybe<ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsInput>;
};

export type UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  update: UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput;
  create: UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput;
};

export type UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput = {
  where: UserChannelFilterSelectionWhereUniqueInput;
  update: UserChannelFilterSelectionUncheckedUpdateWithoutUserInput;
  create: UserChannelFilterSelectionUncheckedCreateWithoutUserInput;
};

export type UserChannelFilterSelectionUserIdFilterSelectionIdCompoundUniqueInput = {
  userId: Scalars['String'];
  filterSelectionId: Scalars['String'];
};

export type UserChannelFilterSelectionWhereInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  filterSelectionId?: Maybe<StringFilter>;
  isFilterOwner?: Maybe<BoolFilter>;
  user?: Maybe<UserWhereInput>;
  filterSelection?: Maybe<ChannelFilterSelectionWhereInput>;
};

export type UserChannelFilterSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  userId_filterSelectionId?: Maybe<UserChannelFilterSelectionUserIdFilterSelectionIdCompoundUniqueInput>;
};

export type UserCountAggregateOutputType = {
  __typename?: 'UserCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  phone: Scalars['Int'];
  signUpDate: Scalars['Int'];
  type: Scalars['Int'];
  firebaseId: Scalars['Int'];
  profilePic: Scalars['Int'];
  ownerInfoId: Scalars['Int'];
  vetInfoId: Scalars['Int'];
  isAdmin: Scalars['Int'];
  updatedAt: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  isActive: Scalars['Int'];
  userSettingId: Scalars['Int'];
  nameDisplay: Scalars['Int'];
  namePrefix: Scalars['Int'];
  nameSuffix: Scalars['Int'];
  invitedAt: Scalars['Int'];
  invitationCode: Scalars['Int'];
  isNewPasswordRequired: Scalars['Int'];
  invitedById: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
};

export type UserCountOutputType = {
  __typename?: 'UserCountOutputType';
  channels: Scalars['Int'];
  channelMembers: Scalars['Int'];
  channelStatuses: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  clinics: Scalars['Int'];
  clinicsId: Scalars['Int'];
  clinicWidgetRequestTypes: Scalars['Int'];
  consultationChats: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  invitations: Scalars['Int'];
  refundsIssued: Scalars['Int'];
  reviews: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  UserImpersonation_UserToUserImpersonation_impersonated_user_id: Scalars['Int'];
  UserImpersonation_UserToUserImpersonation_user_id: Scalars['Int'];
  sentStripePaymentIntents: Scalars['Int'];
  sentInvoices: Scalars['Int'];
  calls: Scalars['Int'];
  channelSelections: Scalars['Int'];
  triggeredWritebacks: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  usagesRecorded: Scalars['Int'];
  careBenefitUsageToInvoiceLineItems: Scalars['Int'];
  userChannelFilterSelections: Scalars['Int'];
  messageTemplates: Scalars['Int'];
  channelMessagesWithMentions: Scalars['Int'];
  manuallySentAutomationRuns: Scalars['Int'];
  channelMessageReactions: Scalars['Int'];
  massTextAlerts: Scalars['Int'];
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserCreateManyInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyInvitedByInputEnvelope = {
  data: Array<UserCreateManyInvitedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserCreateManyUserSettingInputEnvelope = {
  data: Array<UserCreateManyUserSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutChannelMessagesWithMentionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutChannelsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutFormSubmissionsInput = {
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutInvitedByInput = {
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedOneWithoutCallsInput = {
  create?: Maybe<UserUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCallsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  create?: Maybe<UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelMembersInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMembersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelMessageReactionsInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelMessageReactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMessageReactionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChannelStatusesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutClinicsIdInput = {
  create?: Maybe<UserUncheckedCreateWithoutClinicsIdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicsIdInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutConsultationChatsInput = {
  create?: Maybe<UserUncheckedCreateWithoutConsultationChatsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutConsultationChatsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutInvitationsInput = {
  create?: Maybe<UserUncheckedCreateWithoutInvitationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInvitationsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutManuallySentAutomationRunsInput = {
  create?: Maybe<UserUncheckedCreateWithoutManuallySentAutomationRunsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutManuallySentAutomationRunsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMassTextAlertsInput = {
  create?: Maybe<UserUncheckedCreateWithoutMassTextAlertsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMassTextAlertsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMessageTemplatesInput = {
  create?: Maybe<UserUncheckedCreateWithoutMessageTemplatesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMessageTemplatesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutOwnerInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutRefundsIssuedInput = {
  create?: Maybe<UserUncheckedCreateWithoutRefundsIssuedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRefundsIssuedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutReviewsInput = {
  create?: Maybe<UserUncheckedCreateWithoutReviewsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutReviewsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSentInvoicesInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentInvoicesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentInvoicesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSentStripePaymentIntentsInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentStripePaymentIntentsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutTriggeredWritebacksInput = {
  create?: Maybe<UserUncheckedCreateWithoutTriggeredWritebacksInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTriggeredWritebacksInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUsagesRecordedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUsagesRecordedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUsagesRecordedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUserChannelFilterSelectionsInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  connectOrCreate?: Maybe<
    UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput
  >;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutVetInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutCallsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutCallsInput;
};

export type UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput;
};

export type UserCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type UserCreateOrConnectWithoutChannelMembersInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelMembersInput;
};

export type UserCreateOrConnectWithoutChannelMessageReactionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelMessageReactionsInput;
};

export type UserCreateOrConnectWithoutChannelMessagesWithMentionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelMessagesWithMentionsInput;
};

export type UserCreateOrConnectWithoutChannelSelectionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelSelectionsInput;
};

export type UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type UserCreateOrConnectWithoutChannelStatusesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelStatusesInput;
};

export type UserCreateOrConnectWithoutChannelsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutChannelsInput;
};

export type UserCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type UserCreateOrConnectWithoutClinicsIdInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutClinicsIdInput;
};

export type UserCreateOrConnectWithoutClinicsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutClinicsInput;
};

export type UserCreateOrConnectWithoutConsultationChatsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutConsultationChatsInput;
};

export type UserCreateOrConnectWithoutFormSubmissionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutFormSubmissionsInput;
};

export type UserCreateOrConnectWithoutInvitationsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutInvitationsInput;
};

export type UserCreateOrConnectWithoutInvitedByInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutInvitedByInput;
};

export type UserCreateOrConnectWithoutManuallySentAutomationRunsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutManuallySentAutomationRunsInput;
};

export type UserCreateOrConnectWithoutMassTextAlertsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutMassTextAlertsInput;
};

export type UserCreateOrConnectWithoutMessageTemplatesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutMessageTemplatesInput;
};

export type UserCreateOrConnectWithoutOwnerInfoInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutOwnerInfoInput;
};

export type UserCreateOrConnectWithoutRefundsIssuedInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutRefundsIssuedInput;
};

export type UserCreateOrConnectWithoutReviewsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutReviewsInput;
};

export type UserCreateOrConnectWithoutSentInvoicesInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutSentInvoicesInput;
};

export type UserCreateOrConnectWithoutSentStripePaymentIntentsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutSentStripePaymentIntentsInput;
};

export type UserCreateOrConnectWithoutTriggeredWritebacksInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutTriggeredWritebacksInput;
};

export type UserCreateOrConnectWithoutUsagesRecordedInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUsagesRecordedInput;
};

export type UserCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type UserCreateOrConnectWithoutUserChannelFilterSelectionsInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserChannelFilterSelectionsInput;
};

export type UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
};

export type UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
};

export type UserCreateOrConnectWithoutUserSettingInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutUserSettingInput;
};

export type UserCreateOrConnectWithoutVetInfoInput = {
  where: UserWhereUniqueInput;
  create: UserUncheckedCreateWithoutVetInfoInput;
};

export type UserCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutOwnerInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserCreateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserImpersonation = {
  __typename?: 'UserImpersonation';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  impersonatedUser?: Maybe<User>;
  user?: Maybe<User>;
};

export type UserImpersonationCountAggregateOutputType = {
  __typename?: 'UserImpersonationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  impersonatedUserId: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserImpersonationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserImpersonationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<
    UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput
  >;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationCreateManyImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyImpersonatedUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyImpersonatedUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateNestedManyWithoutImpersonatedUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationCreateOrConnectWithoutImpersonatedUserInput = {
  where: UserImpersonationWhereUniqueInput;
  create: UserImpersonationUncheckedCreateWithoutImpersonatedUserInput;
};

export type UserImpersonationCreateOrConnectWithoutUserInput = {
  where: UserImpersonationWhereUniqueInput;
  create: UserImpersonationUncheckedCreateWithoutUserInput;
};

export type UserImpersonationCreateWithoutImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<
    UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput
  >;
};

export type UserImpersonationListRelationFilter = {
  every?: Maybe<UserImpersonationWhereInput>;
  some?: Maybe<UserImpersonationWhereInput>;
  none?: Maybe<UserImpersonationWhereInput>;
};

export type UserImpersonationMaxAggregateOutputType = {
  __typename?: 'UserImpersonationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserImpersonationMinAggregateOutputType = {
  __typename?: 'UserImpersonationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserImpersonationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserImpersonationOrderByRelevanceFieldEnum {
  Id = 'id',
  ImpersonatedUserId = 'impersonatedUserId',
  UserId = 'userId',
}

export type UserImpersonationOrderByRelevanceInput = {
  fields: Array<UserImpersonationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserImpersonationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<UserImpersonationCountOrderByAggregateInput>;
  _max?: Maybe<UserImpersonationMaxOrderByAggregateInput>;
  _min?: Maybe<UserImpersonationMinOrderByAggregateInput>;
};

export type UserImpersonationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  impersonatedUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<UserImpersonationOrderByRelevanceInput>;
};

export enum UserImpersonationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ImpersonatedUserId = 'impersonatedUserId',
  UserId = 'userId',
}

export type UserImpersonationScalarWhereInput = {
  AND?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  OR?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  NOT?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  impersonatedUserId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type UserImpersonationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  impersonatedUserId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserImpersonationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
};

export type UserImpersonationUncheckedCreateWithoutImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput = {
  where: UserImpersonationScalarWhereInput;
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
};

export type UserImpersonationUpdateManyWithWhereWithoutUserInput = {
  where: UserImpersonationScalarWhereInput;
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
};

export type UserImpersonationUpdateManyWithoutImpersonatedUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUpdateManyWithoutUserInput = {
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput = {
  where: UserImpersonationWhereUniqueInput;
  data: UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutUserInput = {
  where: UserImpersonationWhereUniqueInput;
  data: UserImpersonationUncheckedUpdateWithoutUserInput;
};

export type UserImpersonationUpdateWithoutImpersonatedUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput = {
  where: UserImpersonationWhereUniqueInput;
  update: UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput;
  create: UserImpersonationUncheckedCreateWithoutImpersonatedUserInput;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutUserInput = {
  where: UserImpersonationWhereUniqueInput;
  update: UserImpersonationUncheckedUpdateWithoutUserInput;
  create: UserImpersonationUncheckedCreateWithoutUserInput;
};

export type UserImpersonationWhereInput = {
  AND?: Maybe<Array<UserImpersonationWhereInput>>;
  OR?: Maybe<Array<UserImpersonationWhereInput>>;
  NOT?: Maybe<Array<UserImpersonationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  impersonatedUserId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  impersonatedUser?: Maybe<UserWhereInput>;
  user?: Maybe<UserWhereInput>;
};

export type UserImpersonationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type UserMaxAggregateOutputType = {
  __typename?: 'UserMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
};

export type UserMinAggregateOutputType = {
  __typename?: 'UserMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  settingType: NotificationSettingType;
  smsEnabled: Scalars['Boolean'];
  emailEnabled: Scalars['Boolean'];
  pushNotificationEnabled: Scalars['Boolean'];
  userSettingId?: Maybe<Scalars['String']>;
  userSetting?: Maybe<UserSetting>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCountAggregateOutputType = {
  __typename?: 'UserNotificationSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  settingType: Scalars['Int'];
  smsEnabled: Scalars['Int'];
  emailEnabled: Scalars['Int'];
  pushNotificationEnabled: Scalars['Int'];
  userSettingId: Scalars['Int'];
  soundNotificationUrl: Scalars['Int'];
  browserNotificationEnabled: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserNotificationSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
};

export type UserNotificationSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutNotificationSettingsInput>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCreateManyUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCreateManyUserSettingInputEnvelope = {
  data: Array<UserNotificationSettingCreateManyUserSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCreateNestedManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
};

export type UserNotificationSettingCreateOrConnectWithoutUserSettingInput = {
  where: UserNotificationSettingWhereUniqueInput;
  create: UserNotificationSettingUncheckedCreateWithoutUserSettingInput;
};

export type UserNotificationSettingCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingListRelationFilter = {
  every?: Maybe<UserNotificationSettingWhereInput>;
  some?: Maybe<UserNotificationSettingWhereInput>;
  none?: Maybe<UserNotificationSettingWhereInput>;
};

export type UserNotificationSettingMaxAggregateOutputType = {
  __typename?: 'UserNotificationSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
};

export type UserNotificationSettingMinAggregateOutputType = {
  __typename?: 'UserNotificationSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
};

export type UserNotificationSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserNotificationSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  UserSettingId = 'userSettingId',
  SoundNotificationUrl = 'soundNotificationUrl',
}

export type UserNotificationSettingOrderByRelevanceInput = {
  fields: Array<UserNotificationSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserNotificationSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
  _count?: Maybe<UserNotificationSettingCountOrderByAggregateInput>;
  _max?: Maybe<UserNotificationSettingMaxOrderByAggregateInput>;
  _min?: Maybe<UserNotificationSettingMinOrderByAggregateInput>;
};

export type UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
  soundNotificationUrl?: Maybe<SortOrder>;
  browserNotificationEnabled?: Maybe<SortOrder>;
  _relevance?: Maybe<UserNotificationSettingOrderByRelevanceInput>;
};

export enum UserNotificationSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  SettingType = 'settingType',
  SmsEnabled = 'smsEnabled',
  EmailEnabled = 'emailEnabled',
  PushNotificationEnabled = 'pushNotificationEnabled',
  UserSettingId = 'userSettingId',
  SoundNotificationUrl = 'soundNotificationUrl',
  BrowserNotificationEnabled = 'browserNotificationEnabled',
}

export type UserNotificationSettingScalarWhereInput = {
  AND?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  OR?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  NOT?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeFilter>;
  smsEnabled?: Maybe<BoolFilter>;
  emailEnabled?: Maybe<BoolFilter>;
  pushNotificationEnabled?: Maybe<BoolFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  soundNotificationUrl?: Maybe<StringNullableFilter>;
  browserNotificationEnabled?: Maybe<BoolNullableFilter>;
};

export type UserNotificationSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeWithAggregatesFilter>;
  smsEnabled?: Maybe<BoolWithAggregatesFilter>;
  emailEnabled?: Maybe<BoolWithAggregatesFilter>;
  pushNotificationEnabled?: Maybe<BoolWithAggregatesFilter>;
  userSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  soundNotificationUrl?: Maybe<StringNullableWithAggregatesFilter>;
  browserNotificationEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type UserNotificationSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
};

export type UserNotificationSettingUncheckedCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedUpdateManyWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUncheckedUpdateManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  set?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  delete?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  update?: Maybe<Array<UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput>>;
  deleteMany?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
};

export type UserNotificationSettingUncheckedUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutNotificationSettingsInput>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput = {
  where: UserNotificationSettingScalarWhereInput;
  data: UserNotificationSettingUncheckedUpdateManyWithoutNotificationSettingsInput;
};

export type UserNotificationSettingUpdateManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  set?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  delete?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  update?: Maybe<Array<UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput>>;
  deleteMany?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
};

export type UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput = {
  where: UserNotificationSettingWhereUniqueInput;
  data: UserNotificationSettingUncheckedUpdateWithoutUserSettingInput;
};

export type UserNotificationSettingUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput = {
  where: UserNotificationSettingWhereUniqueInput;
  update: UserNotificationSettingUncheckedUpdateWithoutUserSettingInput;
  create: UserNotificationSettingUncheckedCreateWithoutUserSettingInput;
};

export type UserNotificationSettingWhereInput = {
  AND?: Maybe<Array<UserNotificationSettingWhereInput>>;
  OR?: Maybe<Array<UserNotificationSettingWhereInput>>;
  NOT?: Maybe<Array<UserNotificationSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeFilter>;
  smsEnabled?: Maybe<BoolFilter>;
  emailEnabled?: Maybe<BoolFilter>;
  pushNotificationEnabled?: Maybe<BoolFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  userSetting?: Maybe<UserSettingWhereInput>;
  soundNotificationUrl?: Maybe<StringNullableFilter>;
  browserNotificationEnabled?: Maybe<BoolNullableFilter>;
};

export type UserNotificationSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserOrderByRelevanceFieldEnum {
  Id = 'id',
  Email = 'email',
  Phone = 'phone',
  FirebaseId = 'firebaseId',
  ProfilePic = 'profilePic',
  OwnerInfoId = 'ownerInfoId',
  VetInfoId = 'vetInfoId',
  FirstName = 'firstName',
  LastName = 'lastName',
  UserSettingId = 'userSettingId',
  NameDisplay = 'nameDisplay',
  NamePrefix = 'namePrefix',
  NameSuffix = 'nameSuffix',
  InvitationCode = 'invitationCode',
  InvitedById = 'invitedById',
}

export type UserOrderByRelevanceInput = {
  fields: Array<UserOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  _count?: Maybe<UserCountOrderByAggregateInput>;
  _max?: Maybe<UserMaxOrderByAggregateInput>;
  _min?: Maybe<UserMinOrderByAggregateInput>;
};

export type UserOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  ownerInfo?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  invitedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
  vetInfo?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  channelStatuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  clinicsId?: Maybe<ClinicOrderByRelationAggregateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  consultationChats?: Maybe<ConsultationChatOrderByRelationAggregateInput>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  invitations?: Maybe<UserOrderByRelationAggregateInput>;
  refundsIssued?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  reviews?: Maybe<ReviewOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationOrderByRelationAggregateInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  sentInvoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  calls?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  channelSelections?: Maybe<ChannelSelectionOrderByRelationAggregateInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  usagesRecorded?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionOrderByRelationAggregateInput>;
  messageTemplates?: Maybe<MessageTemplateOrderByRelationAggregateInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionOrderByRelationAggregateInput>;
  massTextAlerts?: Maybe<MassTextAlertOrderByRelationAggregateInput>;
  _relevance?: Maybe<UserOrderByRelevanceInput>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Email = 'email',
  Phone = 'phone',
  SignUpDate = 'signUpDate',
  Type = 'type',
  FirebaseId = 'firebaseId',
  ProfilePic = 'profilePic',
  OwnerInfoId = 'ownerInfoId',
  VetInfoId = 'vetInfoId',
  IsAdmin = 'isAdmin',
  UpdatedAt = 'updatedAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  IsActive = 'isActive',
  UserSettingId = 'userSettingId',
  NameDisplay = 'nameDisplay',
  NamePrefix = 'namePrefix',
  NameSuffix = 'nameSuffix',
  InvitedAt = 'invitedAt',
  InvitationCode = 'invitationCode',
  IsNewPasswordRequired = 'isNewPasswordRequired',
  InvitedById = 'invitedById',
}

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  phone?: Maybe<StringNullableFilter>;
  signUpDate?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumUserTypeFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  profilePic?: Maybe<StringNullableFilter>;
  ownerInfoId?: Maybe<StringNullableFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
  isAdmin?: Maybe<BoolNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  nameDisplay?: Maybe<StringNullableFilter>;
  namePrefix?: Maybe<StringNullableFilter>;
  nameSuffix?: Maybe<StringNullableFilter>;
  invitedAt?: Maybe<DateTimeNullableFilter>;
  invitationCode?: Maybe<StringNullableFilter>;
  isNewPasswordRequired?: Maybe<BoolFilter>;
  invitedById?: Maybe<StringNullableFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  signUpDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  type?: Maybe<EnumUserTypeWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  profilePic?: Maybe<StringNullableWithAggregatesFilter>;
  ownerInfoId?: Maybe<StringNullableWithAggregatesFilter>;
  vetInfoId?: Maybe<StringNullableWithAggregatesFilter>;
  isAdmin?: Maybe<BoolNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  userSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  nameDisplay?: Maybe<StringNullableWithAggregatesFilter>;
  namePrefix?: Maybe<StringNullableWithAggregatesFilter>;
  nameSuffix?: Maybe<StringNullableWithAggregatesFilter>;
  invitedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  invitationCode?: Maybe<StringNullableWithAggregatesFilter>;
  isNewPasswordRequired?: Maybe<BoolWithAggregatesFilter>;
  invitedById?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pushNotifications: Scalars['Boolean'];
  emailNotifications: Scalars['Boolean'];
  smsNotifications: Scalars['Boolean'];
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user: Array<User>;
  notificationSettings: Array<UserNotificationSetting>;
  browserNotificationFilter?: Maybe<ChannelSelection>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  _count: UserSettingCountOutputType;
};

export type UserSettingUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserScalarFieldEnum>;
};

export type UserSettingNotificationSettingsArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
  orderBy?: Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<UserNotificationSettingScalarFieldEnum>;
};

export type UserSettingCountAggregateOutputType = {
  __typename?: 'UserSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  pushNotifications: Scalars['Int'];
  emailNotifications: Scalars['Int'];
  smsNotifications: Scalars['Int'];
  snoozeNotificationsUntil: Scalars['Int'];
  browserNotificationFilterId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
};

export type UserSettingCountOutputType = {
  __typename?: 'UserSettingCountOutputType';
  user: Scalars['Int'];
  notificationSettings: Scalars['Int'];
};

export type UserSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
};

export type UserSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
};

export type UserSettingCreateNestedOneWithoutNotificationSettingsInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutNotificationSettingsInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutNotificationSettingsInput>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
};

export type UserSettingCreateNestedOneWithoutUserInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutUserInput>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
};

export type UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput;
};

export type UserSettingCreateOrConnectWithoutNotificationSettingsInput = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingUncheckedCreateWithoutNotificationSettingsInput;
};

export type UserSettingCreateOrConnectWithoutUserInput = {
  where: UserSettingWhereUniqueInput;
  create: UserSettingUncheckedCreateWithoutUserInput;
};

export type UserSettingCreateWithoutBrowserNotificationFilterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingCreateWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
};

export type UserSettingCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
};

export type UserSettingMaxAggregateOutputType = {
  __typename?: 'UserSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
};

export type UserSettingMinAggregateOutputType = {
  __typename?: 'UserSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
};

export enum UserSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  BrowserNotificationFilterId = 'browserNotificationFilterId',
}

export type UserSettingOrderByRelevanceInput = {
  fields: Array<UserSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type UserSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  _count?: Maybe<UserSettingCountOrderByAggregateInput>;
  _max?: Maybe<UserSettingMaxOrderByAggregateInput>;
  _min?: Maybe<UserSettingMinOrderByAggregateInput>;
};

export type UserSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByRelationAggregateInput>;
  notificationSettings?: Maybe<UserNotificationSettingOrderByRelationAggregateInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  _relevance?: Maybe<UserSettingOrderByRelevanceInput>;
};

export type UserSettingRelationFilter = {
  is?: Maybe<UserSettingWhereInput>;
  isNot?: Maybe<UserSettingWhereInput>;
};

export enum UserSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  PushNotifications = 'pushNotifications',
  EmailNotifications = 'emailNotifications',
  SmsNotifications = 'smsNotifications',
  SnoozeNotificationsUntil = 'snoozeNotificationsUntil',
  BrowserNotificationFilterId = 'browserNotificationFilterId',
}

export type UserSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  pushNotifications?: Maybe<BoolWithAggregatesFilter>;
  emailNotifications?: Maybe<BoolWithAggregatesFilter>;
  smsNotifications?: Maybe<BoolWithAggregatesFilter>;
  snoozeNotificationsUntil?: Maybe<DateTimeNullableWithAggregatesFilter>;
  browserNotificationFilterId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
};

export type UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedCreateWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingInput>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  upsert?: Maybe<UserSettingUpsertWithoutBrowserNotificationFilterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput>;
};

export type UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedUpdateWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingInput>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingInput>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export type UserSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingInput>;
};

export type UserSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
};

export type UserSettingUpdateOneWithoutBrowserNotificationFilterInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  upsert?: Maybe<UserSettingUpsertWithoutBrowserNotificationFilterInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput>;
};

export type UserSettingUpdateOneWithoutNotificationSettingsInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutNotificationSettingsInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutNotificationSettingsInput>;
  upsert?: Maybe<UserSettingUpsertWithoutNotificationSettingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutNotificationSettingsInput>;
};

export type UserSettingUpdateOneWithoutUserInput = {
  create?: Maybe<UserSettingUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<UserSettingUpsertWithoutUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserSettingWhereUniqueInput>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutUserInput>;
};

export type UserSettingUpdateWithoutBrowserNotificationFilterInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingInput>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingInput>;
};

export type UserSettingUpdateWithoutNotificationSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingInput>;
};

export type UserSettingUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingInput>;
};

export type UserSettingUpsertWithoutBrowserNotificationFilterInput = {
  update: UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput;
  create: UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput;
};

export type UserSettingUpsertWithoutNotificationSettingsInput = {
  update: UserSettingUncheckedUpdateWithoutNotificationSettingsInput;
  create: UserSettingUncheckedCreateWithoutNotificationSettingsInput;
};

export type UserSettingUpsertWithoutUserInput = {
  update: UserSettingUncheckedUpdateWithoutUserInput;
  create: UserSettingUncheckedCreateWithoutUserInput;
};

export type UserSettingWhereInput = {
  AND?: Maybe<Array<UserSettingWhereInput>>;
  OR?: Maybe<Array<UserSettingWhereInput>>;
  NOT?: Maybe<Array<UserSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  pushNotifications?: Maybe<BoolFilter>;
  emailNotifications?: Maybe<BoolFilter>;
  smsNotifications?: Maybe<BoolFilter>;
  snoozeNotificationsUntil?: Maybe<DateTimeNullableFilter>;
  user?: Maybe<UserListRelationFilter>;
  notificationSettings?: Maybe<UserNotificationSettingListRelationFilter>;
  browserNotificationFilter?: Maybe<ChannelSelectionWhereInput>;
  browserNotificationFilterId?: Maybe<StringNullableFilter>;
};

export type UserSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
};

export enum UserType {
  Owner = 'OWNER',
  Vet = 'VET',
}

export type UserUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutChannelsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutFormSubmissionsInput = {
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutInvitedByInput = {
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserUncheckedCreateNestedOneWithoutOwnerInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUncheckedCreateNestedOneWithoutVetInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUncheckedCreateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
};

export type UserUncheckedCreateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutOwnerInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedCreateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutChannelsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutClinicsInput = {
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutFormSubmissionsInput = {
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutFormSubmissionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutFormSubmissionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutFormSubmissionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
};

export type UserUncheckedUpdateManyWithoutInvitedByInput = {
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutInvitedByInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutInvitedByInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutMentionedUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutUserSettingInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUncheckedUpdateManyWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateOneWithoutOwnerInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  upsert?: Maybe<UserUpsertWithoutOwnerInfoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutOwnerInfoInput>;
};

export type UserUncheckedUpdateOneWithoutVetInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  upsert?: Maybe<UserUpsertWithoutVetInfoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutVetInfoInput>;
};

export type UserUncheckedUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
};

export type UserUncheckedUpdateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutOwnerInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUncheckedUpdateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userSettingId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedById?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<
    CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByInput
  >;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutMentionedUsersInput;
};

export type UserUpdateManyWithWhereWithoutChannelSelectionsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
};

export type UserUpdateManyWithWhereWithoutChannelsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
};

export type UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
};

export type UserUpdateManyWithWhereWithoutClinicsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutUsersInput;
};

export type UserUpdateManyWithWhereWithoutFormSubmissionsInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutUsersInput;
};

export type UserUpdateManyWithWhereWithoutInvitedByInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutInvitationsInput;
};

export type UserUpdateManyWithWhereWithoutUserSettingInput = {
  where: UserScalarWhereInput;
  data: UserUncheckedUpdateManyWithoutUserInput;
};

export type UserUpdateManyWithoutChannelMessagesWithMentionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutChannelSelectionsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutChannelsInput = {
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutClinicWidgetRequestTypesInput = {
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutClinicsInput = {
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutFormSubmissionsInput = {
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutFormSubmissionsInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutFormSubmissionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutFormSubmissionsInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutInvitedByInput = {
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutInvitedByInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutInvitedByInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutUserSettingInput = {
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutUserSettingInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateOneRequiredWithoutChannelMessageReactionsInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelMessageReactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMessageReactionsInput>;
  upsert?: Maybe<UserUpsertWithoutChannelMessageReactionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelMessageReactionsInput>;
};

export type UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelStatusChannelAssigneesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
};

export type UserUpdateOneRequiredWithoutMassTextAlertsInput = {
  create?: Maybe<UserUncheckedCreateWithoutMassTextAlertsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMassTextAlertsInput>;
  upsert?: Maybe<UserUpsertWithoutMassTextAlertsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutMassTextAlertsInput>;
};

export type UserUpdateOneRequiredWithoutMessageTemplatesInput = {
  create?: Maybe<UserUncheckedCreateWithoutMessageTemplatesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMessageTemplatesInput>;
  upsert?: Maybe<UserUpsertWithoutMessageTemplatesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutMessageTemplatesInput>;
};

export type UserUpdateOneRequiredWithoutUserChannelFilterSelectionsInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  upsert?: Maybe<UserUpsertWithoutUserChannelFilterSelectionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput>;
};

export type UserUpdateOneWithoutCallsInput = {
  create?: Maybe<UserUncheckedCreateWithoutCallsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCallsInput>;
  upsert?: Maybe<UserUpsertWithoutCallsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutCallsInput>;
};

export type UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  create?: Maybe<UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  upsert?: Maybe<UserUpsertWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
};

export type UserUpdateOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type UserUpdateOneWithoutChannelMembersInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelMembersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMembersInput>;
  upsert?: Maybe<UserUpsertWithoutChannelMembersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelMembersInput>;
};

export type UserUpdateOneWithoutChannelStatusesInput = {
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelStatusesInput>;
};

export type UserUpdateOneWithoutClinicsIdInput = {
  create?: Maybe<UserUncheckedCreateWithoutClinicsIdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicsIdInput>;
  upsert?: Maybe<UserUpsertWithoutClinicsIdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutClinicsIdInput>;
};

export type UserUpdateOneWithoutConsultationChatsInput = {
  create?: Maybe<UserUncheckedCreateWithoutConsultationChatsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutConsultationChatsInput>;
  upsert?: Maybe<UserUpsertWithoutConsultationChatsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutConsultationChatsInput>;
};

export type UserUpdateOneWithoutInvitationsInput = {
  create?: Maybe<UserUncheckedCreateWithoutInvitationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInvitationsInput>;
  upsert?: Maybe<UserUpsertWithoutInvitationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutInvitationsInput>;
};

export type UserUpdateOneWithoutManuallySentAutomationRunsInput = {
  create?: Maybe<UserUncheckedCreateWithoutManuallySentAutomationRunsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutManuallySentAutomationRunsInput>;
  upsert?: Maybe<UserUpsertWithoutManuallySentAutomationRunsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutManuallySentAutomationRunsInput>;
};

export type UserUpdateOneWithoutOwnerInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  upsert?: Maybe<UserUpsertWithoutOwnerInfoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutOwnerInfoInput>;
};

export type UserUpdateOneWithoutRefundsIssuedInput = {
  create?: Maybe<UserUncheckedCreateWithoutRefundsIssuedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRefundsIssuedInput>;
  upsert?: Maybe<UserUpsertWithoutRefundsIssuedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutRefundsIssuedInput>;
};

export type UserUpdateOneWithoutReviewsInput = {
  create?: Maybe<UserUncheckedCreateWithoutReviewsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutReviewsInput>;
  upsert?: Maybe<UserUpsertWithoutReviewsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutReviewsInput>;
};

export type UserUpdateOneWithoutSentInvoicesInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentInvoicesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentInvoicesInput>;
  upsert?: Maybe<UserUpsertWithoutSentInvoicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutSentInvoicesInput>;
};

export type UserUpdateOneWithoutSentStripePaymentIntentsInput = {
  create?: Maybe<UserUncheckedCreateWithoutSentStripePaymentIntentsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentStripePaymentIntentsInput>;
  upsert?: Maybe<UserUpsertWithoutSentStripePaymentIntentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutSentStripePaymentIntentsInput>;
};

export type UserUpdateOneWithoutTriggeredWritebacksInput = {
  create?: Maybe<UserUncheckedCreateWithoutTriggeredWritebacksInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTriggeredWritebacksInput>;
  upsert?: Maybe<UserUpsertWithoutTriggeredWritebacksInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutTriggeredWritebacksInput>;
};

export type UserUpdateOneWithoutUsagesRecordedInput = {
  create?: Maybe<UserUncheckedCreateWithoutUsagesRecordedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUsagesRecordedInput>;
  upsert?: Maybe<UserUpsertWithoutUsagesRecordedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUsagesRecordedInput>;
};

export type UserUpdateOneWithoutUserAppointmentGroupingsInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  upsert?: Maybe<UserUpsertWithoutUserAppointmentGroupingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserAppointmentGroupingsInput>;
};

export type UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  connectOrCreate?: Maybe<
    UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput
  >;
  upsert?: Maybe<UserUpsertWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
};

export type UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  upsert?: Maybe<UserUpsertWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserUpdateOneWithoutVetInfoInput = {
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  upsert?: Maybe<UserUpsertWithoutVetInfoInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUncheckedUpdateWithoutVetInfoInput>;
};

export type UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput;
};

export type UserUpdateWithWhereUniqueWithoutChannelSelectionsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutChannelSelectionsInput;
};

export type UserUpdateWithWhereUniqueWithoutChannelsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutChannelsInput;
};

export type UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
};

export type UserUpdateWithWhereUniqueWithoutClinicsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutClinicsInput;
};

export type UserUpdateWithWhereUniqueWithoutFormSubmissionsInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutFormSubmissionsInput;
};

export type UserUpdateWithWhereUniqueWithoutInvitedByInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutInvitedByInput;
};

export type UserUpdateWithWhereUniqueWithoutUserSettingInput = {
  where: UserWhereUniqueInput;
  data: UserUncheckedUpdateWithoutUserSettingInput;
};

export type UserUpdateWithoutCallsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutChannelMembersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutChannelMessageReactionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutChannelMessagesWithMentionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutChannelSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutChannelStatusChannelAssigneesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutChannelStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutClinicWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutClinicsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutConsultationChatsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutFormSubmissionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutInvitationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutInvitedByInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutManuallySentAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutMassTextAlertsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutMessageTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutOwnerInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutRefundsIssuedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutReviewsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutSentInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutSentStripePaymentIntentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutTriggeredWritebacksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutUsagesRecordedInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutUserAppointmentGroupingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutUserChannelFilterSelectionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutUserSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpdateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  firebaseId?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitationCode?: Maybe<Scalars['String']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserInput>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorInput>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersInput>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByInput>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<
    UserImpersonationUpdateManyWithoutImpersonatedUserInput
  >;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesInput>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserInput>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserInput>;
};

export type UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput;
  create: UserUncheckedCreateWithoutChannelMessagesWithMentionsInput;
};

export type UserUpsertWithWhereUniqueWithoutChannelSelectionsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutChannelSelectionsInput;
  create: UserUncheckedCreateWithoutChannelSelectionsInput;
};

export type UserUpsertWithWhereUniqueWithoutChannelsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutChannelsInput;
  create: UserUncheckedCreateWithoutChannelsInput;
};

export type UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
  create: UserUncheckedCreateWithoutClinicWidgetRequestTypesInput;
};

export type UserUpsertWithWhereUniqueWithoutClinicsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutClinicsInput;
  create: UserUncheckedCreateWithoutClinicsInput;
};

export type UserUpsertWithWhereUniqueWithoutFormSubmissionsInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutFormSubmissionsInput;
  create: UserUncheckedCreateWithoutFormSubmissionsInput;
};

export type UserUpsertWithWhereUniqueWithoutInvitedByInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutInvitedByInput;
  create: UserUncheckedCreateWithoutInvitedByInput;
};

export type UserUpsertWithWhereUniqueWithoutUserSettingInput = {
  where: UserWhereUniqueInput;
  update: UserUncheckedUpdateWithoutUserSettingInput;
  create: UserUncheckedCreateWithoutUserSettingInput;
};

export type UserUpsertWithoutCallsInput = {
  update: UserUncheckedUpdateWithoutCallsInput;
  create: UserUncheckedCreateWithoutCallsInput;
};

export type UserUpsertWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  update: UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput;
  create: UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput;
};

export type UserUpsertWithoutChannelCreationSourcesInput = {
  update: UserUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: UserUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type UserUpsertWithoutChannelMembersInput = {
  update: UserUncheckedUpdateWithoutChannelMembersInput;
  create: UserUncheckedCreateWithoutChannelMembersInput;
};

export type UserUpsertWithoutChannelMessageReactionsInput = {
  update: UserUncheckedUpdateWithoutChannelMessageReactionsInput;
  create: UserUncheckedCreateWithoutChannelMessageReactionsInput;
};

export type UserUpsertWithoutChannelStatusChannelAssigneesInput = {
  update: UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
  create: UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
};

export type UserUpsertWithoutChannelStatusesInput = {
  update: UserUncheckedUpdateWithoutChannelStatusesInput;
  create: UserUncheckedCreateWithoutChannelStatusesInput;
};

export type UserUpsertWithoutClinicsIdInput = {
  update: UserUncheckedUpdateWithoutClinicsIdInput;
  create: UserUncheckedCreateWithoutClinicsIdInput;
};

export type UserUpsertWithoutConsultationChatsInput = {
  update: UserUncheckedUpdateWithoutConsultationChatsInput;
  create: UserUncheckedCreateWithoutConsultationChatsInput;
};

export type UserUpsertWithoutInvitationsInput = {
  update: UserUncheckedUpdateWithoutInvitationsInput;
  create: UserUncheckedCreateWithoutInvitationsInput;
};

export type UserUpsertWithoutManuallySentAutomationRunsInput = {
  update: UserUncheckedUpdateWithoutManuallySentAutomationRunsInput;
  create: UserUncheckedCreateWithoutManuallySentAutomationRunsInput;
};

export type UserUpsertWithoutMassTextAlertsInput = {
  update: UserUncheckedUpdateWithoutMassTextAlertsInput;
  create: UserUncheckedCreateWithoutMassTextAlertsInput;
};

export type UserUpsertWithoutMessageTemplatesInput = {
  update: UserUncheckedUpdateWithoutMessageTemplatesInput;
  create: UserUncheckedCreateWithoutMessageTemplatesInput;
};

export type UserUpsertWithoutOwnerInfoInput = {
  update: UserUncheckedUpdateWithoutOwnerInfoInput;
  create: UserUncheckedCreateWithoutOwnerInfoInput;
};

export type UserUpsertWithoutRefundsIssuedInput = {
  update: UserUncheckedUpdateWithoutRefundsIssuedInput;
  create: UserUncheckedCreateWithoutRefundsIssuedInput;
};

export type UserUpsertWithoutReviewsInput = {
  update: UserUncheckedUpdateWithoutReviewsInput;
  create: UserUncheckedCreateWithoutReviewsInput;
};

export type UserUpsertWithoutSentInvoicesInput = {
  update: UserUncheckedUpdateWithoutSentInvoicesInput;
  create: UserUncheckedCreateWithoutSentInvoicesInput;
};

export type UserUpsertWithoutSentStripePaymentIntentsInput = {
  update: UserUncheckedUpdateWithoutSentStripePaymentIntentsInput;
  create: UserUncheckedCreateWithoutSentStripePaymentIntentsInput;
};

export type UserUpsertWithoutTriggeredWritebacksInput = {
  update: UserUncheckedUpdateWithoutTriggeredWritebacksInput;
  create: UserUncheckedCreateWithoutTriggeredWritebacksInput;
};

export type UserUpsertWithoutUsagesRecordedInput = {
  update: UserUncheckedUpdateWithoutUsagesRecordedInput;
  create: UserUncheckedCreateWithoutUsagesRecordedInput;
};

export type UserUpsertWithoutUserAppointmentGroupingsInput = {
  update: UserUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  create: UserUncheckedCreateWithoutUserAppointmentGroupingsInput;
};

export type UserUpsertWithoutUserChannelFilterSelectionsInput = {
  update: UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput;
  create: UserUncheckedCreateWithoutUserChannelFilterSelectionsInput;
};

export type UserUpsertWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  update: UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
};

export type UserUpsertWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  update: UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
};

export type UserUpsertWithoutVetInfoInput = {
  update: UserUncheckedUpdateWithoutVetInfoInput;
  create: UserUncheckedCreateWithoutVetInfoInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  phone?: Maybe<StringNullableFilter>;
  signUpDate?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumUserTypeFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  profilePic?: Maybe<StringNullableFilter>;
  ownerInfoId?: Maybe<StringNullableFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
  isAdmin?: Maybe<BoolNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  nameDisplay?: Maybe<StringNullableFilter>;
  namePrefix?: Maybe<StringNullableFilter>;
  nameSuffix?: Maybe<StringNullableFilter>;
  invitedAt?: Maybe<DateTimeNullableFilter>;
  invitationCode?: Maybe<StringNullableFilter>;
  isNewPasswordRequired?: Maybe<BoolFilter>;
  invitedById?: Maybe<StringNullableFilter>;
  ownerInfo?: Maybe<OwnerInfoWhereInput>;
  invitedBy?: Maybe<UserWhereInput>;
  userSetting?: Maybe<UserSettingWhereInput>;
  vetInfo?: Maybe<VetInfoWhereInput>;
  channels?: Maybe<ChannelListRelationFilter>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  channelStatuses?: Maybe<ChannelStatusListRelationFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  clinicsId?: Maybe<ClinicListRelationFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  consultationChats?: Maybe<ConsultationChatListRelationFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  invitations?: Maybe<UserListRelationFilter>;
  refundsIssued?: Maybe<StripeRefundListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationListRelationFilter>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationListRelationFilter>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  sentInvoices?: Maybe<InvoiceListRelationFilter>;
  calls?: Maybe<CallParticipantListRelationFilter>;
  channelSelections?: Maybe<ChannelSelectionListRelationFilter>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  usagesRecorded?: Maybe<CareBenefitUsageListRelationFilter>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionListRelationFilter>;
  messageTemplates?: Maybe<MessageTemplateListRelationFilter>;
  channelMessagesWithMentions?: Maybe<ChannelMessageListRelationFilter>;
  manuallySentAutomationRuns?: Maybe<AutomationRunListRelationFilter>;
  channelMessageReactions?: Maybe<ChannelMessageReactionListRelationFilter>;
  massTextAlerts?: Maybe<MassTextAlertListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
  userSettingId?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
};

export type Vaccination = {
  __typename?: 'Vaccination';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: Clinic;
  clinicPet: ClinicPet;
  integration?: Maybe<ClinicPimsIntegration>;
};

export type VaccinationCountAggregateOutputType = {
  __typename?: 'VaccinationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  pimsId: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  description: Scalars['Int'];
  siteId: Scalars['Int'];
  administeredAt: Scalars['Int'];
  expiredAt: Scalars['Int'];
  manufacturer: Scalars['Int'];
  virusType: Scalars['Int'];
  serialNumber: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VaccinationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type VaccinationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
};

export type VaccinationCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationCreateManyClinicInputEnvelope = {
  data: Array<VaccinationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateManyClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationCreateManyClinicPetInputEnvelope = {
  data: Array<VaccinationCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationCreateManyIntegrationInputEnvelope = {
  data: Array<VaccinationCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationCreateOrConnectWithoutClinicInput = {
  where: VaccinationWhereUniqueInput;
  create: VaccinationUncheckedCreateWithoutClinicInput;
};

export type VaccinationCreateOrConnectWithoutClinicPetInput = {
  where: VaccinationWhereUniqueInput;
  create: VaccinationUncheckedCreateWithoutClinicPetInput;
};

export type VaccinationCreateOrConnectWithoutIntegrationInput = {
  where: VaccinationWhereUniqueInput;
  create: VaccinationUncheckedCreateWithoutIntegrationInput;
};

export type VaccinationCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
};

export type VaccinationCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
};

export type VaccinationCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
};

export type VaccinationListRelationFilter = {
  every?: Maybe<VaccinationWhereInput>;
  some?: Maybe<VaccinationWhereInput>;
  none?: Maybe<VaccinationWhereInput>;
};

export type VaccinationMaxAggregateOutputType = {
  __typename?: 'VaccinationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type VaccinationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export type VaccinationMinAggregateOutputType = {
  __typename?: 'VaccinationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type VaccinationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
};

export type VaccinationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VaccinationOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  Description = 'description',
  SiteId = 'siteId',
  Manufacturer = 'manufacturer',
  VirusType = 'virusType',
  SerialNumber = 'serialNumber',
}

export type VaccinationOrderByRelevanceInput = {
  fields: Array<VaccinationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VaccinationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<VaccinationCountOrderByAggregateInput>;
  _max?: Maybe<VaccinationMaxOrderByAggregateInput>;
  _min?: Maybe<VaccinationMinOrderByAggregateInput>;
};

export type VaccinationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  administeredAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VaccinationOrderByRelevanceInput>;
};

export type VaccinationPimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export enum VaccinationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Description = 'description',
  SiteId = 'siteId',
  AdministeredAt = 'administeredAt',
  ExpiredAt = 'expiredAt',
  Manufacturer = 'manufacturer',
  VirusType = 'virusType',
  SerialNumber = 'serialNumber',
  RawPimsValue = 'rawPimsValue',
}

export type VaccinationScalarWhereInput = {
  AND?: Maybe<Array<VaccinationScalarWhereInput>>;
  OR?: Maybe<Array<VaccinationScalarWhereInput>>;
  NOT?: Maybe<Array<VaccinationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  description?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  administeredAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  virusType?: Maybe<StringNullableFilter>;
  serialNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type VaccinationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  administeredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  manufacturer?: Maybe<StringNullableWithAggregatesFilter>;
  virusType?: Maybe<StringNullableWithAggregatesFilter>;
  serialNumber?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type VaccinationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationUncheckedCreateNestedManyWithoutClinicPetInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
};

export type VaccinationUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedCreateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutVaccinationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsInput>;
};

export type VaccinationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VaccinationUpdateManyWithWhereWithoutClinicInput = {
  where: VaccinationScalarWhereInput;
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
};

export type VaccinationUpdateManyWithWhereWithoutClinicPetInput = {
  where: VaccinationScalarWhereInput;
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
};

export type VaccinationUpdateManyWithWhereWithoutIntegrationInput = {
  where: VaccinationScalarWhereInput;
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
};

export type VaccinationUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUpdateManyWithoutClinicPetInput = {
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicPetInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
};

export type VaccinationUpdateWithWhereUniqueWithoutClinicInput = {
  where: VaccinationWhereUniqueInput;
  data: VaccinationUncheckedUpdateWithoutClinicInput;
};

export type VaccinationUpdateWithWhereUniqueWithoutClinicPetInput = {
  where: VaccinationWhereUniqueInput;
  data: VaccinationUncheckedUpdateWithoutClinicPetInput;
};

export type VaccinationUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: VaccinationWhereUniqueInput;
  data: VaccinationUncheckedUpdateWithoutIntegrationInput;
};

export type VaccinationUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsInput>;
};

export type VaccinationUpdateWithoutClinicPetInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsInput>;
};

export type VaccinationUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  administeredAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  virusType?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsInput>;
};

export type VaccinationUpsertWithWhereUniqueWithoutClinicInput = {
  where: VaccinationWhereUniqueInput;
  update: VaccinationUncheckedUpdateWithoutClinicInput;
  create: VaccinationUncheckedCreateWithoutClinicInput;
};

export type VaccinationUpsertWithWhereUniqueWithoutClinicPetInput = {
  where: VaccinationWhereUniqueInput;
  update: VaccinationUncheckedUpdateWithoutClinicPetInput;
  create: VaccinationUncheckedCreateWithoutClinicPetInput;
};

export type VaccinationUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: VaccinationWhereUniqueInput;
  update: VaccinationUncheckedUpdateWithoutIntegrationInput;
  create: VaccinationUncheckedCreateWithoutIntegrationInput;
};

export type VaccinationWhereInput = {
  AND?: Maybe<Array<VaccinationWhereInput>>;
  OR?: Maybe<Array<VaccinationWhereInput>>;
  NOT?: Maybe<Array<VaccinationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  description?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  administeredAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  virusType?: Maybe<StringNullableFilter>;
  serialNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type VaccinationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<VaccinationPimsIdIntegrationIdCompoundUniqueInput>;
};

export type VerificationCheckInput = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  type: VerificationType;
};

export type VerificationRequestInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type: VerificationType;
  clinicId?: Maybe<Scalars['String']>;
};

export enum VerificationType {
  Sms = 'SMS',
  Email = 'EMAIL',
}

export type VerifyClinicWidgetRequestInput = {
  clinicWidgetRequestId: Scalars['ID'];
};

export type VerifyWidgetAuthenticationRequestInput = {
  code: Scalars['String'];
};

export type VerifyWidgetAuthenticationRequestResponse = {
  __typename?: 'VerifyWidgetAuthenticationRequestResponse';
  success?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type VetAvailability = {
  __typename?: 'VetAvailability';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  clinicId: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isRecurring: Scalars['Boolean'];
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds: Array<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: Clinic;
  integration?: Maybe<ClinicPimsIntegration>;
  vetAvailabilityMaps: Array<VetAvailabilityMap>;
  _count: VetAvailabilityCountOutputType;
};

export type VetAvailabilityVetAvailabilityMapsArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
  orderBy?: Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetAvailabilityMapScalarFieldEnum>;
};

export type VetAvailabilityCountAggregateOutputType = {
  __typename?: 'VetAvailabilityCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  isAvailable: Scalars['Int'];
  isActive: Scalars['Int'];
  isRecurring: Scalars['Int'];
  recurringRules: Scalars['Int'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  pimsId: Scalars['Int'];
  integrationId: Scalars['Int'];
  availabilityType: Scalars['Int'];
  availabilityPimsType: Scalars['Int'];
  reason: Scalars['Int'];
  siteId: Scalars['Int'];
  resourceIds: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetAvailabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type VetAvailabilityCountOutputType = {
  __typename?: 'VetAvailabilityCountOutputType';
  vetAvailabilityMaps: Scalars['Int'];
};

export type VetAvailabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityCreateManyClinicInputEnvelope = {
  data: Array<VetAvailabilityCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityCreateManyIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityCreateManyIntegrationInputEnvelope = {
  data: Array<VetAvailabilityCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
};

export type VetAvailabilityCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
};

export type VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput = {
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput>;
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
};

export type VetAvailabilityCreateOrConnectWithoutClinicInput = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityUncheckedCreateWithoutClinicInput;
};

export type VetAvailabilityCreateOrConnectWithoutIntegrationInput = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityUncheckedCreateWithoutIntegrationInput;
};

export type VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput = {
  where: VetAvailabilityWhereUniqueInput;
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
};

export type VetAvailabilityCreateresourceIdsInput = {
  set: Array<Scalars['String']>;
};

export type VetAvailabilityListRelationFilter = {
  every?: Maybe<VetAvailabilityWhereInput>;
  some?: Maybe<VetAvailabilityWhereInput>;
  none?: Maybe<VetAvailabilityWhereInput>;
};

export type VetAvailabilityMap = {
  __typename?: 'VetAvailabilityMap';
  createdAt: Scalars['DateTime'];
  vetAvailabilityId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
  clinicEmployee: ClinicEmployee;
  vetAvailability: VetAvailability;
};

export type VetAvailabilityMapClinicEmployeeIdVetAvailabilityIdCompoundUniqueInput = {
  clinicEmployeeId: Scalars['String'];
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapCountAggregateOutputType = {
  __typename?: 'VetAvailabilityMapCountAggregateOutputType';
  createdAt: Scalars['Int'];
  vetAvailabilityId: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetAvailabilityMapCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapCreateManyClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope = {
  data: Array<VetAvailabilityMapCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityMapCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type VetAvailabilityMapCreateManyVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope = {
  data: Array<VetAvailabilityMapCreateManyVetAvailabilityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
};

export type VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
};

export type VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  create: VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput;
};

export type VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  create: VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput;
};

export type VetAvailabilityMapCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapCreateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapListRelationFilter = {
  every?: Maybe<VetAvailabilityMapWhereInput>;
  some?: Maybe<VetAvailabilityMapWhereInput>;
  none?: Maybe<VetAvailabilityMapWhereInput>;
};

export type VetAvailabilityMapMaxAggregateOutputType = {
  __typename?: 'VetAvailabilityMapMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapMinAggregateOutputType = {
  __typename?: 'VetAvailabilityMapMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetAvailabilityMapOrderByRelevanceFieldEnum {
  VetAvailabilityId = 'vetAvailabilityId',
  ClinicEmployeeId = 'clinicEmployeeId',
}

export type VetAvailabilityMapOrderByRelevanceInput = {
  fields: Array<VetAvailabilityMapOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetAvailabilityMapOrderByWithAggregationInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  _count?: Maybe<VetAvailabilityMapCountOrderByAggregateInput>;
  _max?: Maybe<VetAvailabilityMapMaxOrderByAggregateInput>;
  _min?: Maybe<VetAvailabilityMapMinOrderByAggregateInput>;
};

export type VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput = {
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailability?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetAvailabilityMapOrderByRelevanceInput>;
};

export enum VetAvailabilityMapScalarFieldEnum {
  CreatedAt = 'createdAt',
  VetAvailabilityId = 'vetAvailabilityId',
  ClinicEmployeeId = 'clinicEmployeeId',
}

export type VetAvailabilityMapScalarWhereInput = {
  AND?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailabilityId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
};

export type VetAvailabilityMapScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  vetAvailabilityId?: Maybe<StringWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
};

export type VetAvailabilityMapUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
};

export type VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
};

export type VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
};

export type VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId: Scalars['String'];
};

export type VetAvailabilityMapUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsInput>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsInput>;
};

export type VetAvailabilityMapUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput = {
  where: VetAvailabilityMapScalarWhereInput;
  data: VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput = {
  where: VetAvailabilityMapScalarWhereInput;
  data: VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapUpdateManyWithoutClinicEmployeeInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
};

export type VetAvailabilityMapUpdateManyWithoutVetAvailabilityInput = {
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
};

export type VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  data: VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput;
};

export type VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  data: VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput;
};

export type VetAvailabilityMapUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsInput>;
};

export type VetAvailabilityMapUpdateWithoutVetAvailabilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsInput>;
};

export type VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  update: VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput;
  create: VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput;
};

export type VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput = {
  where: VetAvailabilityMapWhereUniqueInput;
  update: VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput;
  create: VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput;
};

export type VetAvailabilityMapWhereInput = {
  AND?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailabilityId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  vetAvailability?: Maybe<VetAvailabilityWhereInput>;
};

export type VetAvailabilityMapWhereUniqueInput = {
  clinicEmployeeId_vetAvailabilityId?: Maybe<VetAvailabilityMapClinicEmployeeIdVetAvailabilityIdCompoundUniqueInput>;
};

export type VetAvailabilityMaxAggregateOutputType = {
  __typename?: 'VetAvailabilityMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
};

export type VetAvailabilityMinAggregateOutputType = {
  __typename?: 'VetAvailabilityMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
};

export type VetAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetAvailabilityOrderByRelevanceFieldEnum {
  Id = 'id',
  ClinicId = 'clinicId',
  RecurringRules = 'recurringRules',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  AvailabilityType = 'availabilityType',
  AvailabilityPimsType = 'availabilityPimsType',
  Reason = 'reason',
  SiteId = 'siteId',
  ResourceIds = 'resourceIds',
}

export type VetAvailabilityOrderByRelevanceInput = {
  fields: Array<VetAvailabilityOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetAvailabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  _count?: Maybe<VetAvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<VetAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<VetAvailabilityMinOrderByAggregateInput>;
};

export type VetAvailabilityOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  availabilityPimsType?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapOrderByRelationAggregateInput>;
  _relevance?: Maybe<VetAvailabilityOrderByRelevanceInput>;
};

export type VetAvailabilityPimsIdIntegrationIdCompoundUniqueInput = {
  pimsId: Scalars['String'];
  integrationId: Scalars['String'];
};

export type VetAvailabilityRelationFilter = {
  is?: Maybe<VetAvailabilityWhereInput>;
  isNot?: Maybe<VetAvailabilityWhereInput>;
};

export enum VetAvailabilityScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClinicId = 'clinicId',
  IsAvailable = 'isAvailable',
  IsActive = 'isActive',
  IsRecurring = 'isRecurring',
  RecurringRules = 'recurringRules',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PimsId = 'pimsId',
  IntegrationId = 'integrationId',
  AvailabilityType = 'availabilityType',
  AvailabilityPimsType = 'availabilityPimsType',
  Reason = 'reason',
  SiteId = 'siteId',
  ResourceIds = 'resourceIds',
  RawPimsValue = 'rawPimsValue',
}

export type VetAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isAvailable?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  isRecurring?: Maybe<BoolFilter>;
  recurringRules?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  availabilityType?: Maybe<StringNullableFilter>;
  availabilityPimsType?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
};

export type VetAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  isAvailable?: Maybe<BoolWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isRecurring?: Maybe<BoolWithAggregatesFilter>;
  recurringRules?: Maybe<StringNullableWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  endDate?: Maybe<DateTimeWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  availabilityType?: Maybe<StringNullableWithAggregatesFilter>;
  availabilityPimsType?: Maybe<StringNullableWithAggregatesFilter>;
  reason?: Maybe<StringNullableWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type VetAvailabilityUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
};

export type VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
};

export type VetAvailabilityUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type VetAvailabilityUpdateManyWithWhereWithoutClinicInput = {
  where: VetAvailabilityScalarWhereInput;
  data: VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput;
};

export type VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput = {
  where: VetAvailabilityScalarWhereInput;
  data: VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput;
};

export type VetAvailabilityUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
};

export type VetAvailabilityUpdateManyWithoutIntegrationInput = {
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
};

export type VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsInput = {
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput>;
  upsert?: Maybe<VetAvailabilityUpsertWithoutVetAvailabilityMapsInput>;
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
  update?: Maybe<VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput>;
};

export type VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput = {
  where: VetAvailabilityWhereUniqueInput;
  data: VetAvailabilityUncheckedUpdateWithoutClinicInput;
};

export type VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput = {
  where: VetAvailabilityWhereUniqueInput;
  data: VetAvailabilityUncheckedUpdateWithoutIntegrationInput;
};

export type VetAvailabilityUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUpdateWithoutIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUpdateWithoutVetAvailabilityMapsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  recurringRules?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  availabilityPimsType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesInput>;
};

export type VetAvailabilityUpdateresourceIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Array<Scalars['String']>>;
};

export type VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput = {
  where: VetAvailabilityWhereUniqueInput;
  update: VetAvailabilityUncheckedUpdateWithoutClinicInput;
  create: VetAvailabilityUncheckedCreateWithoutClinicInput;
};

export type VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput = {
  where: VetAvailabilityWhereUniqueInput;
  update: VetAvailabilityUncheckedUpdateWithoutIntegrationInput;
  create: VetAvailabilityUncheckedCreateWithoutIntegrationInput;
};

export type VetAvailabilityUpsertWithoutVetAvailabilityMapsInput = {
  update: VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput;
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityWhereInput = {
  AND?: Maybe<Array<VetAvailabilityWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  isAvailable?: Maybe<BoolFilter>;
  isActive?: Maybe<BoolFilter>;
  isRecurring?: Maybe<BoolFilter>;
  recurringRules?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  availabilityType?: Maybe<StringNullableFilter>;
  availabilityPimsType?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapListRelationFilter>;
};

export type VetAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<VetAvailabilityPimsIdIntegrationIdCompoundUniqueInput>;
};

export type VetDataIntegration = {
  __typename?: 'VetDataIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type VetDataIntegrationCountAggregateOutputType = {
  __typename?: 'VetDataIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  installationId: Scalars['Int'];
  siteId: Scalars['Int'];
  system: Scalars['Int'];
  invoiceCodeId: Scalars['Int'];
  defaultProviderId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetDataIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
};

export type VetDataIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetdataInput>;
};

export type VetDataIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<VetDataIntegrationWhereUniqueInput>;
};

export type VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: VetDataIntegrationWhereUniqueInput;
  create: VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type VetDataIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationMaxAggregateOutputType = {
  __typename?: 'VetDataIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
};

export type VetDataIntegrationMinAggregateOutputType = {
  __typename?: 'VetDataIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
};

export enum VetDataIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  InstallationId = 'installationId',
  SiteId = 'siteId',
  InvoiceCodeId = 'invoiceCodeId',
  DefaultProviderId = 'defaultProviderId',
}

export type VetDataIntegrationOrderByRelevanceInput = {
  fields: Array<VetDataIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetDataIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  _count?: Maybe<VetDataIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<VetDataIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<VetDataIntegrationMinOrderByAggregateInput>;
};

export type VetDataIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetDataIntegrationOrderByRelevanceInput>;
};

export type VetDataIntegrationRelationFilter = {
  is?: Maybe<VetDataIntegrationWhereInput>;
  isNot?: Maybe<VetDataIntegrationWhereInput>;
};

export enum VetDataIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  InstallationId = 'installationId',
  SiteId = 'siteId',
  System = 'system',
  InvoiceCodeId = 'invoiceCodeId',
  DefaultProviderId = 'defaultProviderId',
}

export type VetDataIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  installationId?: Maybe<StringWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  system?: Maybe<EnumVetDataIntegrationSystemWithAggregatesFilter>;
  invoiceCodeId?: Maybe<StringNullableWithAggregatesFilter>;
  defaultProviderId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum VetDataIntegrationSystem {
  Avimark = 'AVIMARK',
  Clientrax = 'CLIENTRAX',
  Cornerstone = 'CORNERSTONE',
  DvmManager = 'DVM_MANAGER',
  DvmaxSql = 'DVMAX_SQL',
  Evetpractice = 'EVETPRACTICE',
  HippoManager = 'HIPPO_MANAGER',
  Impromed = 'IMPROMED',
  Intravet = 'INTRAVET',
  Via = 'VIA',
  Vtp = 'VTP',
}

export type VetDataIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetdataInput>;
};

export type VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutVetdataInput>;
};

export type VetDataIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetdataInput>;
};

export type VetDataIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<VetDataIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetDataIntegrationWhereUniqueInput>;
  update?: Maybe<VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type VetDataIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  installationId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  defaultProviderId?: Maybe<Scalars['String']>;
};

export type VetDataIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type VetDataIntegrationWhereInput = {
  AND?: Maybe<Array<VetDataIntegrationWhereInput>>;
  OR?: Maybe<Array<VetDataIntegrationWhereInput>>;
  NOT?: Maybe<Array<VetDataIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  installationId?: Maybe<StringFilter>;
  siteId?: Maybe<StringNullableFilter>;
  system?: Maybe<EnumVetDataIntegrationSystemFilter>;
  invoiceCodeId?: Maybe<StringNullableFilter>;
  defaultProviderId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type VetDataIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VetInfo = {
  __typename?: 'VetInfo';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  currentClinic?: Maybe<Clinic>;
  consultationsId: Array<Consultation>;
  consultations: Array<Consultation>;
  user?: Maybe<User>;
  vetLicenses: Array<VetLicense>;
  clinics: Array<Clinic>;
  roles: Array<StaffRole>;
  _count: VetInfoCountOutputType;
};

export type VetInfoConsultationsIdArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};

export type VetInfoConsultationsArgs = {
  where?: Maybe<ConsultationWhereInput>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
};

export type VetInfoVetLicensesArgs = {
  where?: Maybe<VetLicenseWhereInput>;
  orderBy?: Maybe<VetLicenseOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<VetLicenseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<VetLicenseScalarFieldEnum>;
};

export type VetInfoClinicsArgs = {
  where?: Maybe<ClinicWhereInput>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
};

export type VetInfoRolesArgs = {
  where?: Maybe<StaffRoleWhereInput>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
};

export type VetInfoCountAggregateOutputType = {
  __typename?: 'VetInfoCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  currentClinicId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetInfoCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoCountOutputType = {
  __typename?: 'VetInfoCountOutputType';
  consultationsId: Scalars['Int'];
  consultations: Scalars['Int'];
  vetLicenses: Scalars['Int'];
  clinics: Scalars['Int'];
  roles: Scalars['Int'];
};

export type VetInfoCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateManyCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoCreateManyCurrentClinicInputEnvelope = {
  data: Array<VetInfoCreateManyCurrentClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetInfoCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoCreateNestedManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoCreateNestedManyWithoutRolesInput = {
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoCreateNestedOneWithoutConsultationsIdInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsIdInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsIdInput>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
};

export type VetInfoCreateNestedOneWithoutConsultationsInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsInput>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
};

export type VetInfoCreateNestedOneWithoutUserInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutUserInput>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
};

export type VetInfoCreateNestedOneWithoutVetLicensesInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutVetLicensesInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutVetLicensesInput>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
};

export type VetInfoCreateOrConnectWithoutClinicsInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutClinicsInput;
};

export type VetInfoCreateOrConnectWithoutConsultationsIdInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutConsultationsIdInput;
};

export type VetInfoCreateOrConnectWithoutConsultationsInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutConsultationsInput;
};

export type VetInfoCreateOrConnectWithoutCurrentClinicInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutCurrentClinicInput;
};

export type VetInfoCreateOrConnectWithoutRolesInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutRolesInput;
};

export type VetInfoCreateOrConnectWithoutUserInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutUserInput;
};

export type VetInfoCreateOrConnectWithoutVetLicensesInput = {
  where: VetInfoWhereUniqueInput;
  create: VetInfoUncheckedCreateWithoutVetLicensesInput;
};

export type VetInfoCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
};

export type VetInfoCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoCreateWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
};

export type VetInfoListRelationFilter = {
  every?: Maybe<VetInfoWhereInput>;
  some?: Maybe<VetInfoWhereInput>;
  none?: Maybe<VetInfoWhereInput>;
};

export type VetInfoMaxAggregateOutputType = {
  __typename?: 'VetInfoMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoMinAggregateOutputType = {
  __typename?: 'VetInfoMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetInfoOrderByRelevanceFieldEnum {
  Id = 'id',
  CurrentClinicId = 'currentClinicId',
  FirebaseId = 'firebaseId',
}

export type VetInfoOrderByRelevanceInput = {
  fields: Array<VetInfoOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetInfoOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<VetInfoCountOrderByAggregateInput>;
  _max?: Maybe<VetInfoMaxOrderByAggregateInput>;
  _min?: Maybe<VetInfoMinOrderByAggregateInput>;
};

export type VetInfoOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  currentClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  consultationsId?: Maybe<ConsultationOrderByRelationAggregateInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  vetLicenses?: Maybe<VetLicenseOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  _relevance?: Maybe<VetInfoOrderByRelevanceInput>;
};

export type VetInfoRelationFilter = {
  is?: Maybe<VetInfoWhereInput>;
  isNot?: Maybe<VetInfoWhereInput>;
};

export enum VetInfoScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  CurrentClinicId = 'currentClinicId',
  FirebaseId = 'firebaseId',
  UpdatedAt = 'updatedAt',
}

export type VetInfoScalarWhereInput = {
  AND?: Maybe<Array<VetInfoScalarWhereInput>>;
  OR?: Maybe<Array<VetInfoScalarWhereInput>>;
  NOT?: Maybe<Array<VetInfoScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentClinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetInfoScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currentClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VetInfoUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateNestedManyWithoutClinicsInput = {
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoUncheckedCreateNestedManyWithoutRolesInput = {
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
};

export type VetInfoUncheckedCreateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedCreateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
};

export type VetInfoUncheckedCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
};

export type VetInfoUncheckedCreateWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
};

export type VetInfoUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersInput>;
};

export type VetInfoUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutClinicsInput = {
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutCurrentClinicInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutRolesInput = {
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutRolesInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutRolesInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutStaffIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutStaffInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersInput>;
};

export type VetInfoUncheckedUpdateWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersInput>;
};

export type VetInfoUncheckedUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersInput>;
};

export type VetInfoUncheckedUpdateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersInput>;
};

export type VetInfoUncheckedUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffInput>;
};

export type VetInfoUncheckedUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersInput>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdInput>;
};

export type VetInfoUncheckedUpdateWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetInput>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersInput>;
};

export type VetInfoUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersInput>;
};

export type VetInfoUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUpdateManyWithWhereWithoutClinicsInput = {
  where: VetInfoScalarWhereInput;
  data: VetInfoUncheckedUpdateManyWithoutStaffInput;
};

export type VetInfoUpdateManyWithWhereWithoutCurrentClinicInput = {
  where: VetInfoScalarWhereInput;
  data: VetInfoUncheckedUpdateManyWithoutStaffIdInput;
};

export type VetInfoUpdateManyWithWhereWithoutRolesInput = {
  where: VetInfoScalarWhereInput;
  data: VetInfoUncheckedUpdateManyWithoutUsersInput;
};

export type VetInfoUpdateManyWithoutClinicsInput = {
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutClinicsInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutClinicsInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUpdateManyWithoutCurrentClinicInput = {
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutCurrentClinicInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUpdateManyWithoutRolesInput = {
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutRolesInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutRolesInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
};

export type VetInfoUpdateOneWithoutConsultationsIdInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsIdInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsIdInput>;
  upsert?: Maybe<VetInfoUpsertWithoutConsultationsIdInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutConsultationsIdInput>;
};

export type VetInfoUpdateOneWithoutConsultationsInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsInput>;
  upsert?: Maybe<VetInfoUpsertWithoutConsultationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutConsultationsInput>;
};

export type VetInfoUpdateOneWithoutUserInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutUserInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<VetInfoUpsertWithoutUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutUserInput>;
};

export type VetInfoUpdateOneWithoutVetLicensesInput = {
  create?: Maybe<VetInfoUncheckedCreateWithoutVetLicensesInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutVetLicensesInput>;
  upsert?: Maybe<VetInfoUpsertWithoutVetLicensesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetInfoWhereUniqueInput>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutVetLicensesInput>;
};

export type VetInfoUpdateWithWhereUniqueWithoutClinicsInput = {
  where: VetInfoWhereUniqueInput;
  data: VetInfoUncheckedUpdateWithoutClinicsInput;
};

export type VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput = {
  where: VetInfoWhereUniqueInput;
  data: VetInfoUncheckedUpdateWithoutCurrentClinicInput;
};

export type VetInfoUpdateWithWhereUniqueWithoutRolesInput = {
  where: VetInfoWhereUniqueInput;
  data: VetInfoUncheckedUpdateWithoutRolesInput;
};

export type VetInfoUpdateWithoutClinicsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersInput>;
};

export type VetInfoUpdateWithoutConsultationsIdInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersInput>;
};

export type VetInfoUpdateWithoutConsultationsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersInput>;
};

export type VetInfoUpdateWithoutCurrentClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersInput>;
};

export type VetInfoUpdateWithoutRolesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffInput>;
};

export type VetInfoUpdateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersInput>;
};

export type VetInfoUpdateWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetInput>;
  user?: Maybe<UserUpdateOneWithoutVetInfoInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutStaffInput>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersInput>;
};

export type VetInfoUpsertWithWhereUniqueWithoutClinicsInput = {
  where: VetInfoWhereUniqueInput;
  update: VetInfoUncheckedUpdateWithoutClinicsInput;
  create: VetInfoUncheckedCreateWithoutClinicsInput;
};

export type VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput = {
  where: VetInfoWhereUniqueInput;
  update: VetInfoUncheckedUpdateWithoutCurrentClinicInput;
  create: VetInfoUncheckedCreateWithoutCurrentClinicInput;
};

export type VetInfoUpsertWithWhereUniqueWithoutRolesInput = {
  where: VetInfoWhereUniqueInput;
  update: VetInfoUncheckedUpdateWithoutRolesInput;
  create: VetInfoUncheckedCreateWithoutRolesInput;
};

export type VetInfoUpsertWithoutConsultationsIdInput = {
  update: VetInfoUncheckedUpdateWithoutConsultationsIdInput;
  create: VetInfoUncheckedCreateWithoutConsultationsIdInput;
};

export type VetInfoUpsertWithoutConsultationsInput = {
  update: VetInfoUncheckedUpdateWithoutConsultationsInput;
  create: VetInfoUncheckedCreateWithoutConsultationsInput;
};

export type VetInfoUpsertWithoutUserInput = {
  update: VetInfoUncheckedUpdateWithoutUserInput;
  create: VetInfoUncheckedCreateWithoutUserInput;
};

export type VetInfoUpsertWithoutVetLicensesInput = {
  update: VetInfoUncheckedUpdateWithoutVetLicensesInput;
  create: VetInfoUncheckedCreateWithoutVetLicensesInput;
};

export type VetInfoWhereInput = {
  AND?: Maybe<Array<VetInfoWhereInput>>;
  OR?: Maybe<Array<VetInfoWhereInput>>;
  NOT?: Maybe<Array<VetInfoWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentClinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  currentClinic?: Maybe<ClinicWhereInput>;
  consultationsId?: Maybe<ConsultationListRelationFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  user?: Maybe<UserWhereInput>;
  vetLicenses?: Maybe<VetLicenseListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
};

export type VetInfoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
};

export type VetLicense = {
  __typename?: 'VetLicense';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType: VetLicenseType;
  vetInfo?: Maybe<VetInfo>;
};

export type VetLicenseCountAggregateOutputType = {
  __typename?: 'VetLicenseCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  licenseNumber: Scalars['Int'];
  issuingAuthority: Scalars['Int'];
  expirationDate: Scalars['Int'];
  vetInfoId: Scalars['Int'];
  licenseType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetLicenseCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
};

export type VetLicenseCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  licenseType: VetLicenseType;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutVetLicensesInput>;
};

export type VetLicenseCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType: VetLicenseType;
};

export type VetLicenseCreateManyVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  licenseType: VetLicenseType;
};

export type VetLicenseCreateManyVetInfoInputEnvelope = {
  data: Array<VetLicenseCreateManyVetInfoInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetLicenseCreateNestedManyWithoutVetInfoInput = {
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
};

export type VetLicenseCreateOrConnectWithoutVetInfoInput = {
  where: VetLicenseWhereUniqueInput;
  create: VetLicenseUncheckedCreateWithoutVetInfoInput;
};

export type VetLicenseCreateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  licenseType: VetLicenseType;
};

export type VetLicenseListRelationFilter = {
  every?: Maybe<VetLicenseWhereInput>;
  some?: Maybe<VetLicenseWhereInput>;
  none?: Maybe<VetLicenseWhereInput>;
};

export type VetLicenseMaxAggregateOutputType = {
  __typename?: 'VetLicenseMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
};

export type VetLicenseMinAggregateOutputType = {
  __typename?: 'VetLicenseMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
};

export type VetLicenseOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetLicenseOrderByRelevanceFieldEnum {
  Id = 'id',
  LicenseNumber = 'licenseNumber',
  VetInfoId = 'vetInfoId',
}

export type VetLicenseOrderByRelevanceInput = {
  fields: Array<VetLicenseOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetLicenseOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  _count?: Maybe<VetLicenseCountOrderByAggregateInput>;
  _max?: Maybe<VetLicenseMaxOrderByAggregateInput>;
  _min?: Maybe<VetLicenseMinOrderByAggregateInput>;
};

export type VetLicenseOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  vetInfo?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetLicenseOrderByRelevanceInput>;
};

export enum VetLicenseScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LicenseNumber = 'licenseNumber',
  IssuingAuthority = 'issuingAuthority',
  ExpirationDate = 'expirationDate',
  VetInfoId = 'vetInfoId',
  LicenseType = 'licenseType',
}

export type VetLicenseScalarWhereInput = {
  AND?: Maybe<Array<VetLicenseScalarWhereInput>>;
  OR?: Maybe<Array<VetLicenseScalarWhereInput>>;
  NOT?: Maybe<Array<VetLicenseScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  licenseNumber?: Maybe<StringFilter>;
  issuingAuthority?: Maybe<EnumStateFilter>;
  expirationDate?: Maybe<DateTimeFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeFilter>;
};

export type VetLicenseScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  licenseNumber?: Maybe<StringWithAggregatesFilter>;
  issuingAuthority?: Maybe<EnumStateWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeWithAggregatesFilter>;
  vetInfoId?: Maybe<StringNullableWithAggregatesFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeWithAggregatesFilter>;
};

export enum VetLicenseType {
  Veterinary = 'Veterinary',
  Technician = 'Technician',
  Dea = 'DEA',
}

export type VetLicenseUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType: VetLicenseType;
};

export type VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput = {
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
};

export type VetLicenseUncheckedCreateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber: Scalars['String'];
  issuingAuthority: State;
  expirationDate: Scalars['DateTime'];
  licenseType: VetLicenseType;
};

export type VetLicenseUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUncheckedUpdateManyWithoutVetInfoInput = {
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  upsert?: Maybe<Array<VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  set?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  delete?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  update?: Maybe<Array<VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput>>;
  updateMany?: Maybe<Array<VetLicenseUpdateManyWithWhereWithoutVetInfoInput>>;
  deleteMany?: Maybe<Array<VetLicenseScalarWhereInput>>;
};

export type VetLicenseUncheckedUpdateManyWithoutVetLicensesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUncheckedUpdateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutVetLicensesInput>;
};

export type VetLicenseUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUpdateManyWithWhereWithoutVetInfoInput = {
  where: VetLicenseScalarWhereInput;
  data: VetLicenseUncheckedUpdateManyWithoutVetLicensesInput;
};

export type VetLicenseUpdateManyWithoutVetInfoInput = {
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  upsert?: Maybe<Array<VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  set?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  disconnect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  delete?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  update?: Maybe<Array<VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput>>;
  updateMany?: Maybe<Array<VetLicenseUpdateManyWithWhereWithoutVetInfoInput>>;
  deleteMany?: Maybe<Array<VetLicenseScalarWhereInput>>;
};

export type VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput = {
  where: VetLicenseWhereUniqueInput;
  data: VetLicenseUncheckedUpdateWithoutVetInfoInput;
};

export type VetLicenseUpdateWithoutVetInfoInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  licenseType?: Maybe<VetLicenseType>;
};

export type VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput = {
  where: VetLicenseWhereUniqueInput;
  update: VetLicenseUncheckedUpdateWithoutVetInfoInput;
  create: VetLicenseUncheckedCreateWithoutVetInfoInput;
};

export type VetLicenseWhereInput = {
  AND?: Maybe<Array<VetLicenseWhereInput>>;
  OR?: Maybe<Array<VetLicenseWhereInput>>;
  NOT?: Maybe<Array<VetLicenseWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  licenseNumber?: Maybe<StringFilter>;
  issuingAuthority?: Maybe<EnumStateFilter>;
  expirationDate?: Maybe<DateTimeFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeFilter>;
  vetInfo?: Maybe<VetInfoWhereInput>;
};

export type VetLicenseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VetterIntegration = {
  __typename?: 'VetterIntegration';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
};

export type VetterIntegrationCountAggregateOutputType = {
  __typename?: 'VetterIntegrationCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  apiKey: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VetterIntegrationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
};

export type VetterIntegrationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetterInput>;
};

export type VetterIntegrationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
};

export type VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  connect?: Maybe<VetterIntegrationWhereUniqueInput>;
};

export type VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  where: VetterIntegrationWhereUniqueInput;
  create: VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type VetterIntegrationCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
};

export type VetterIntegrationMaxAggregateOutputType = {
  __typename?: 'VetterIntegrationMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
};

export type VetterIntegrationMinAggregateOutputType = {
  __typename?: 'VetterIntegrationMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
};

export enum VetterIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  ApiKey = 'apiKey',
}

export type VetterIntegrationOrderByRelevanceInput = {
  fields: Array<VetterIntegrationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type VetterIntegrationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  _count?: Maybe<VetterIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<VetterIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<VetterIntegrationMinOrderByAggregateInput>;
};

export type VetterIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  apiKey?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<VetterIntegrationOrderByRelevanceInput>;
};

export type VetterIntegrationRelationFilter = {
  is?: Maybe<VetterIntegrationWhereInput>;
  isNot?: Maybe<VetterIntegrationWhereInput>;
};

export enum VetterIntegrationScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ApiKey = 'apiKey',
}

export type VetterIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  apiKey?: Maybe<StringWithAggregatesFilter>;
};

export type VetterIntegrationUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetterInput>;
};

export type VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey: Scalars['String'];
};

export type VetterIntegrationUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutVetterInput>;
};

export type VetterIntegrationUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetterInput>;
};

export type VetterIntegrationUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationUpdateOneWithoutClinicPimsIntegrationInput = {
  create?: Maybe<VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  connectOrCreate?: Maybe<VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<VetterIntegrationUpsertWithoutClinicPimsIntegrationInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VetterIntegrationWhereUniqueInput>;
  update?: Maybe<VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
};

export type VetterIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  apiKey?: Maybe<Scalars['String']>;
};

export type VetterIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  update: VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
  create: VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
};

export type VetterIntegrationWhereInput = {
  AND?: Maybe<Array<VetterIntegrationWhereInput>>;
  OR?: Maybe<Array<VetterIntegrationWhereInput>>;
  NOT?: Maybe<Array<VetterIntegrationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  apiKey?: Maybe<StringFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type VetterIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VideoCallRecordingToggleInput = {
  status: RecordingControlStatus;
  roomSid: Scalars['String'];
};

export type WidgetClinicPetParentLookupInput = {
  clinicId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  petName?: Maybe<Scalars['String']>;
  petParentFirstName?: Maybe<Scalars['String']>;
  petParentLastName?: Maybe<Scalars['String']>;
};

export type WidgetClinicPetParentLookupResponse = {
  __typename?: 'WidgetClinicPetParentLookupResponse';
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNumbers: Array<ClinicEntityPhoneNumber>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pets: Array<ClinicPet>;
};

export enum WidgetRequestType {
  RequestAppointment = 'RequestAppointment',
  RequestRxRefill = 'RequestRxRefill',
  AdvancedRequestRxRefill = 'AdvancedRequestRxRefill',
  RequestGroomingAppoinment = 'RequestGroomingAppoinment',
  RequestBoarding = 'RequestBoarding',
  TalkToStaff = 'TalkToStaff',
  RequestMedicalRecords = 'RequestMedicalRecords',
  RequestVirtualConsult = 'RequestVirtualConsult',
  InboundSms = 'InboundSms',
  DirectBooking = 'DirectBooking',
}

export enum WorkflowActionType {
  ConversationMessageTemplate = 'Conversation_MessageTemplate',
  ConversationStatusChange = 'Conversation_StatusChange',
  ConversationSystemMessage = 'Conversation_SystemMessage',
  WorkflowEventTrigger = 'WorkflowEvent_Trigger',
}

export enum WorkflowEvent {
  AppointmentScheduled = 'Appointment_Scheduled',
  AppointmentConfirmReminder = 'Appointment_ConfirmReminder',
  AppointmentReminder = 'Appointment_Reminder',
  AppointmentClientArrival = 'Appointment_ClientArrival',
  AppointmentPimsSync = 'Appointment_Pims_Sync',
  AppointmentFollowup = 'Appointment_Followup',
  ServiceReminderFormSubmitted = 'ServiceReminder_FormSubmitted',
  WidgetRequestFormSubmitted = 'WidgetRequest_FormSubmitted',
  CallDeflectionFormSubmitted = 'CallDeflection_FormSubmitted',
}

export type WorkflowEventAction = {
  __typename?: 'WorkflowEventAction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channelStatusChange?: Maybe<ChannelStatus>;
  workflowEventSetting?: Maybe<WorkflowEventSetting>;
  triggerWorkflow?: Maybe<WorkflowEventSetting>;
  channels: Array<Channel>;
  automationRunActions: Array<AutomationRunAction>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  _count: WorkflowEventActionCountOutputType;
};

export type WorkflowEventActionChannelsArgs = {
  where?: Maybe<ChannelWhereInput>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
};

export type WorkflowEventActionAutomationRunActionsArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
};

export type WorkflowEventActionChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};

export type WorkflowEventActionAvgAggregateOutputType = {
  __typename?: 'WorkflowEventActionAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Float']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Float']>;
};

export type WorkflowEventActionAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionCountAggregateOutputType = {
  __typename?: 'WorkflowEventActionCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  actionType: Scalars['Int'];
  config: Scalars['Int'];
  order: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
  triggerWorkflowId: Scalars['Int'];
  workflowEventTrigger: Scalars['Int'];
  workflowEventTriggerActionNumber: Scalars['Int'];
  workflowEventTriggerDelayInMilliseconds: Scalars['Int'];
  channelStatusChangeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type WorkflowEventActionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  config?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
};

export type WorkflowEventActionCountOutputType = {
  __typename?: 'WorkflowEventActionCountOutputType';
  channels: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
};

export type WorkflowEventActionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionCreateManyChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
};

export type WorkflowEventActionCreateManyTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
};

export type WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyTriggerWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateManyWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
};

export type WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
};

export type WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
};

export type WorkflowEventActionCreateNestedOneWithoutChannelsInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelsInput>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
};

export type WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelsInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelsInput;
};

export type WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput;
};

export type WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput = {
  where: WorkflowEventActionWhereUniqueInput;
  create: WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type WorkflowEventActionCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
};

export type WorkflowEventActionCreateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionCreateWithoutTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionListRelationFilter = {
  every?: Maybe<WorkflowEventActionWhereInput>;
  some?: Maybe<WorkflowEventActionWhereInput>;
  none?: Maybe<WorkflowEventActionWhereInput>;
};

export type WorkflowEventActionMaxAggregateOutputType = {
  __typename?: 'WorkflowEventActionMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
};

export type WorkflowEventActionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
};

export type WorkflowEventActionMinAggregateOutputType = {
  __typename?: 'WorkflowEventActionMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
};

export type WorkflowEventActionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
};

export type WorkflowEventActionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowEventActionOrderByRelevanceFieldEnum {
  Id = 'id',
  WorkflowEventSettingId = 'workflowEventSettingId',
  TriggerWorkflowId = 'triggerWorkflowId',
  WorkflowEventTrigger = 'workflowEventTrigger',
  ChannelStatusChangeId = 'channelStatusChangeId',
}

export type WorkflowEventActionOrderByRelevanceInput = {
  fields: Array<WorkflowEventActionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type WorkflowEventActionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  config?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  _count?: Maybe<WorkflowEventActionCountOrderByAggregateInput>;
  _avg?: Maybe<WorkflowEventActionAvgOrderByAggregateInput>;
  _max?: Maybe<WorkflowEventActionMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowEventActionMinOrderByAggregateInput>;
  _sum?: Maybe<WorkflowEventActionSumOrderByAggregateInput>;
};

export type WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  actionType?: Maybe<SortOrder>;
  config?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  channelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  _relevance?: Maybe<WorkflowEventActionOrderByRelevanceInput>;
};

export type WorkflowEventActionRelationFilter = {
  is?: Maybe<WorkflowEventActionWhereInput>;
  isNot?: Maybe<WorkflowEventActionWhereInput>;
};

export enum WorkflowEventActionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ActionType = 'actionType',
  Config = 'config',
  Order = 'order',
  WorkflowEventSettingId = 'workflowEventSettingId',
  TriggerWorkflowId = 'triggerWorkflowId',
  WorkflowEventTrigger = 'workflowEventTrigger',
  WorkflowEventTriggerActionNumber = 'workflowEventTriggerActionNumber',
  WorkflowEventTriggerDelayInMilliseconds = 'workflowEventTriggerDelayInMilliseconds',
  ChannelStatusChangeId = 'channelStatusChangeId',
}

export type WorkflowEventActionScalarWhereInput = {
  AND?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  actionType?: Maybe<EnumWorkflowActionTypeFilter>;
  config?: Maybe<JsonFilter>;
  order?: Maybe<IntFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  triggerWorkflowId?: Maybe<StringNullableFilter>;
  workflowEventTrigger?: Maybe<StringNullableFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
};

export type WorkflowEventActionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  actionType?: Maybe<EnumWorkflowActionTypeWithAggregatesFilter>;
  config?: Maybe<JsonWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  triggerWorkflowId?: Maybe<StringNullableWithAggregatesFilter>;
  workflowEventTrigger?: Maybe<StringNullableWithAggregatesFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableWithAggregatesFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableWithAggregatesFilter>;
  channelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type WorkflowEventActionSumAggregateOutputType = {
  __typename?: 'WorkflowEventActionSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<
    ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput
  >;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
};

export type WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  channelAutomationStatuses?: Maybe<
    ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput
  >;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<
    ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput
  >;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<
    ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput
  >;
};

export type WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<
    ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput
  >;
};

export type WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  order: Scalars['Int'];
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<
    ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput
  >;
};

export type WorkflowEventActionUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput = {
  where: WorkflowEventActionScalarWhereInput;
  data: WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionInput;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput = {
  where: WorkflowEventActionScalarWhereInput;
  data: WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionsInput;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  where: WorkflowEventActionScalarWhereInput;
  data: WorkflowEventActionUncheckedUpdateManyWithoutActionsInput;
};

export type WorkflowEventActionUpdateManyWithoutChannelStatusChangeInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput = {
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
};

export type WorkflowEventActionUpdateOneWithoutAutomationRunActionsInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutAutomationRunActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput>;
};

export type WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutChannelAutomationStatusesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type WorkflowEventActionUpdateOneWithoutChannelsInput = {
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelsInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelsInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutChannelsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutChannelsInput>;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  data: WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput = {
  where: WorkflowEventActionWhereUniqueInput;
  data: WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: WorkflowEventActionWhereUniqueInput;
  data: WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput;
};

export type WorkflowEventActionUpdateWithoutAutomationRunActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionInput>;
};

export type WorkflowEventActionUpdateWithoutChannelStatusChangeInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUpdateWithoutChannelsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUpdateWithoutTriggerWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  order?: Maybe<Scalars['Int']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionInput>;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput = {
  where: WorkflowEventActionWhereUniqueInput;
  update: WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput = {
  where: WorkflowEventActionWhereUniqueInput;
  update: WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput;
  create: WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  where: WorkflowEventActionWhereUniqueInput;
  update: WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput;
  create: WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput;
};

export type WorkflowEventActionUpsertWithoutAutomationRunActionsInput = {
  update: WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput;
  create: WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput;
};

export type WorkflowEventActionUpsertWithoutChannelAutomationStatusesInput = {
  update: WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventActionUpsertWithoutChannelsInput = {
  update: WorkflowEventActionUncheckedUpdateWithoutChannelsInput;
  create: WorkflowEventActionUncheckedCreateWithoutChannelsInput;
};

export type WorkflowEventActionWhereInput = {
  AND?: Maybe<Array<WorkflowEventActionWhereInput>>;
  OR?: Maybe<Array<WorkflowEventActionWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventActionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  actionType?: Maybe<EnumWorkflowActionTypeFilter>;
  config?: Maybe<JsonFilter>;
  order?: Maybe<IntFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  triggerWorkflowId?: Maybe<StringNullableFilter>;
  workflowEventTrigger?: Maybe<StringNullableFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  channelStatusChange?: Maybe<ChannelStatusWhereInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingWhereInput>;
  channels?: Maybe<ChannelListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
};

export type WorkflowEventActionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type WorkflowEventSetting = {
  __typename?: 'WorkflowEventSetting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType: WorkflowVisitType;
  sendingHours: WorkflowSendingHours;
  name: Scalars['String'];
  workflowType: WorkflowType;
  triggerType: WorkflowEventTriggerType;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours: Scalars['Int'];
  clinic?: Maybe<Clinic>;
  actions: Array<WorkflowEventAction>;
  appointmentTypes: Array<AppointmentType>;
  widgetRequestTypes: Array<ClinicWidgetRequestType>;
  appointments: Array<Appointment>;
  workflowEventActions: Array<WorkflowEventAction>;
  workflowTriggers: Array<WorkflowTrigger>;
  triggeringFormTemplates: Array<FormTemplate>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms: Array<ClinicRoomToWorkflowEventSetting>;
  clinicEmployees: Array<ClinicEmployeeToWorkflowEventSetting>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  automationRuns: Array<AutomationRun>;
  _count: WorkflowEventSettingCountOutputType;
};

export type WorkflowEventSettingActionsArgs = {
  where?: Maybe<WorkflowEventActionWhereInput>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
};

export type WorkflowEventSettingAppointmentTypesArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
};

export type WorkflowEventSettingWidgetRequestTypesArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
};

export type WorkflowEventSettingAppointmentsArgs = {
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
};

export type WorkflowEventSettingWorkflowEventActionsArgs = {
  where?: Maybe<WorkflowEventActionWhereInput>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
};

export type WorkflowEventSettingWorkflowTriggersArgs = {
  where?: Maybe<WorkflowTriggerWhereInput>;
  orderBy?: Maybe<WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<WorkflowTriggerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<WorkflowTriggerScalarFieldEnum>;
};

export type WorkflowEventSettingTriggeringFormTemplatesArgs = {
  where?: Maybe<FormTemplateWhereInput>;
  orderBy?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<FormTemplateScalarFieldEnum>;
};

export type WorkflowEventSettingClinicRoomsArgs = {
  where?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  orderBy?: Maybe<ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicRoomToWorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicRoomToWorkflowEventSettingScalarFieldEnum>;
};

export type WorkflowEventSettingClinicEmployeesArgs = {
  where?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  orderBy?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ClinicEmployeeToWorkflowEventSettingScalarFieldEnum>;
};

export type WorkflowEventSettingChannelAutomationStatusesArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
};

export type WorkflowEventSettingChannelCreationSourcesArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
};

export type WorkflowEventSettingAutomationRunsArgs = {
  where?: Maybe<AutomationRunWhereInput>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
};

export type WorkflowEventSettingAvgAggregateOutputType = {
  __typename?: 'WorkflowEventSettingAvgAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Float']>;
  groupByWindowInHours?: Maybe<Scalars['Float']>;
};

export type WorkflowEventSettingAvgOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
};

export type WorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'WorkflowEventSettingCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  automatedTriggerType: Scalars['Int'];
  automatedTriggerInMinutes: Scalars['Int'];
  event: Scalars['Int'];
  visitType: Scalars['Int'];
  sendingHours: Scalars['Int'];
  name: Scalars['Int'];
  workflowType: Scalars['Int'];
  triggerType: Scalars['Int'];
  clinicId: Scalars['Int'];
  isPublished: Scalars['Int'];
  notificationBody: Scalars['Int'];
  groupByWindowInHours: Scalars['Int'];
  timeFormat: Scalars['Int'];
  _all: Scalars['Int'];
};

export type WorkflowEventSettingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
};

export type WorkflowEventSettingCountOutputType = {
  __typename?: 'WorkflowEventSettingCountOutputType';
  actions: Scalars['Int'];
  appointmentTypes: Scalars['Int'];
  widgetRequestTypes: Scalars['Int'];
  appointments: Scalars['Int'];
  workflowEventActions: Scalars['Int'];
  workflowTriggers: Scalars['Int'];
  triggeringFormTemplates: Scalars['Int'];
  clinicRooms: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  automationRuns: Scalars['Int'];
};

export type WorkflowEventSettingCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateManyClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingCreateManyClinicInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingCreateNestedOneWithoutActionsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutActionsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
};

export type WorkflowEventSettingCreateOrConnectWithoutActionsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutActionsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput;
};

export type WorkflowEventSettingCreateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingCreateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingListRelationFilter = {
  every?: Maybe<WorkflowEventSettingWhereInput>;
  some?: Maybe<WorkflowEventSettingWhereInput>;
  none?: Maybe<WorkflowEventSettingWhereInput>;
};

export type WorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'WorkflowEventSettingMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
};

export type WorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'WorkflowEventSettingMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
};

export type WorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowEventSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  ClinicId = 'clinicId',
  NotificationBody = 'notificationBody',
  TimeFormat = 'timeFormat',
}

export type WorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<WorkflowEventSettingOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type WorkflowEventSettingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  _count?: Maybe<WorkflowEventSettingCountOrderByAggregateInput>;
  _avg?: Maybe<WorkflowEventSettingAvgOrderByAggregateInput>;
  _max?: Maybe<WorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowEventSettingMinOrderByAggregateInput>;
  _sum?: Maybe<WorkflowEventSettingSumOrderByAggregateInput>;
};

export type WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  actions?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  workflowEventActions?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  workflowTriggers?: Maybe<WorkflowTriggerOrderByRelationAggregateInput>;
  triggeringFormTemplates?: Maybe<FormTemplateOrderByRelationAggregateInput>;
  timeFormat?: Maybe<SortOrder>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  _relevance?: Maybe<WorkflowEventSettingOrderByRelevanceInput>;
};

export type WorkflowEventSettingRelationFilter = {
  is?: Maybe<WorkflowEventSettingWhereInput>;
  isNot?: Maybe<WorkflowEventSettingWhereInput>;
};

export enum WorkflowEventSettingScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AutomatedTriggerType = 'automatedTriggerType',
  AutomatedTriggerInMinutes = 'automatedTriggerInMinutes',
  Event = 'event',
  VisitType = 'visitType',
  SendingHours = 'sendingHours',
  Name = 'name',
  WorkflowType = 'workflowType',
  TriggerType = 'triggerType',
  ClinicId = 'clinicId',
  IsPublished = 'isPublished',
  NotificationBody = 'notificationBody',
  GroupByWindowInHours = 'groupByWindowInHours',
  TimeFormat = 'timeFormat',
}

export type WorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableFilter>;
  automatedTriggerInMinutes?: Maybe<IntNullableFilter>;
  event?: Maybe<EnumWorkflowEventFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursFilter>;
  name?: Maybe<StringFilter>;
  workflowType?: Maybe<EnumWorkflowTypeFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isPublished?: Maybe<BoolNullableFilter>;
  notificationBody?: Maybe<StringNullableFilter>;
  groupByWindowInHours?: Maybe<IntFilter>;
  timeFormat?: Maybe<StringNullableFilter>;
};

export type WorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  automatedTriggerInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  event?: Maybe<EnumWorkflowEventWithAggregatesFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeWithAggregatesFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  workflowType?: Maybe<EnumWorkflowTypeWithAggregatesFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  isPublished?: Maybe<BoolNullableWithAggregatesFilter>;
  notificationBody?: Maybe<StringNullableWithAggregatesFilter>;
  groupByWindowInHours?: Maybe<IntWithAggregatesFilter>;
  timeFormat?: Maybe<StringNullableWithAggregatesFilter>;
};

export type WorkflowEventSettingSumAggregateOutputType = {
  __typename?: 'WorkflowEventSettingSumAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
};

export type WorkflowEventSettingSumOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
};

export type WorkflowEventSettingUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
};

export type WorkflowEventSettingUncheckedCreateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event: WorkflowEvent;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name: Scalars['String'];
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutTriggeredWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  clinicId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutTriggeredWorkflowsInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput = {
  where: WorkflowEventSettingScalarWhereInput;
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
};

export type WorkflowEventSettingUpdateManyWithoutAppointmentTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutAppointmentsInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutClinicInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesInput = {
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutAutomationRunsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutChannelAutomationStatusesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutClinicEmployeesInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutClinicRoomsInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutWorkflowTriggersInput>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput>;
};

export type WorkflowEventSettingUpdateOneWithoutActionsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutActionsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutActionsInput>;
};

export type WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutChannelCreationSourcesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput>;
};

export type WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsInput = {
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutWorkflowEventActionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput>;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutClinicInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput;
};

export type WorkflowEventSettingUpdateWithoutActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutAppointmentTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutAutomationRunsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
};

export type WorkflowEventSettingUpdateWithoutChannelAutomationStatusesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutChannelCreationSourcesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutClinicEmployeesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutClinicInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutClinicRoomsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutTriggeringFormTemplatesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutWidgetRequestTypesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutWorkflowEventActionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpdateWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  event?: Maybe<WorkflowEvent>;
  visitType?: Maybe<WorkflowVisitType>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  name?: Maybe<Scalars['String']>;
  workflowType?: Maybe<WorkflowType>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  isPublished?: Maybe<Scalars['Boolean']>;
  notificationBody?: Maybe<Scalars['String']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsInput>;
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsInput>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowInput>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsInput>;
  timeFormat?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationInput>;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput = {
  where: WorkflowEventSettingWhereUniqueInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput;
};

export type WorkflowEventSettingUpsertWithoutActionsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutActionsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutActionsInput;
};

export type WorkflowEventSettingUpsertWithoutAutomationRunsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput;
};

export type WorkflowEventSettingUpsertWithoutChannelAutomationStatusesInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventSettingUpsertWithoutChannelCreationSourcesInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput;
};

export type WorkflowEventSettingUpsertWithoutClinicEmployeesInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput;
};

export type WorkflowEventSettingUpsertWithoutClinicRoomsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput;
};

export type WorkflowEventSettingUpsertWithoutWorkflowEventActionsInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput;
};

export type WorkflowEventSettingUpsertWithoutWorkflowTriggersInput = {
  update: WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput;
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput;
};

export type WorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableFilter>;
  automatedTriggerInMinutes?: Maybe<IntNullableFilter>;
  event?: Maybe<EnumWorkflowEventFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursFilter>;
  name?: Maybe<StringFilter>;
  workflowType?: Maybe<EnumWorkflowTypeFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  isPublished?: Maybe<BoolNullableFilter>;
  notificationBody?: Maybe<StringNullableFilter>;
  groupByWindowInHours?: Maybe<IntFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  actions?: Maybe<WorkflowEventActionListRelationFilter>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  workflowEventActions?: Maybe<WorkflowEventActionListRelationFilter>;
  workflowTriggers?: Maybe<WorkflowTriggerListRelationFilter>;
  triggeringFormTemplates?: Maybe<FormTemplateListRelationFilter>;
  timeFormat?: Maybe<StringNullableFilter>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
};

export type WorkflowEventSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum WorkflowEventTriggerType {
  Manual = 'Manual',
  Automated = 'Automated',
}

export enum WorkflowSendingHours {
  AutomationHours = 'AutomationHours',
  BusinessHours = 'BusinessHours',
  All24Hours = 'All24Hours',
}

export type WorkflowTrigger = {
  __typename?: 'WorkflowTrigger';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  workflowId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  workflow: WorkflowEventSetting;
  appointment?: Maybe<Appointment>;
};

export type WorkflowTriggerAppointmentIdWorkflowIdCompoundUniqueInput = {
  appointmentId: Scalars['String'];
  workflowId: Scalars['String'];
};

export type WorkflowTriggerCountAggregateOutputType = {
  __typename?: 'WorkflowTriggerCountAggregateOutputType';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  workflowId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  triggerAt: Scalars['Int'];
  hasTriggered: Scalars['Int'];
  isDeleted: Scalars['Int'];
  _all: Scalars['Int'];
};

export type WorkflowTriggerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type WorkflowTriggerCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutWorkflowTriggersInput>;
};

export type WorkflowTriggerCreateManyAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyAppointmentInputEnvelope = {
  data: Array<WorkflowTriggerCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyWorkflowInputEnvelope = {
  data: Array<WorkflowTriggerCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
};

export type WorkflowTriggerCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
};

export type WorkflowTriggerCreateOrConnectWithoutAppointmentInput = {
  where: WorkflowTriggerWhereUniqueInput;
  create: WorkflowTriggerUncheckedCreateWithoutAppointmentInput;
};

export type WorkflowTriggerCreateOrConnectWithoutWorkflowInput = {
  where: WorkflowTriggerWhereUniqueInput;
  create: WorkflowTriggerUncheckedCreateWithoutWorkflowInput;
};

export type WorkflowTriggerCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput;
};

export type WorkflowTriggerCreateWithoutWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutWorkflowTriggersInput>;
};

export type WorkflowTriggerListRelationFilter = {
  every?: Maybe<WorkflowTriggerWhereInput>;
  some?: Maybe<WorkflowTriggerWhereInput>;
  none?: Maybe<WorkflowTriggerWhereInput>;
};

export type WorkflowTriggerMaxAggregateOutputType = {
  __typename?: 'WorkflowTriggerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type WorkflowTriggerMinAggregateOutputType = {
  __typename?: 'WorkflowTriggerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
};

export type WorkflowTriggerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowTriggerOrderByRelevanceFieldEnum {
  Id = 'id',
  WorkflowId = 'workflowId',
  AppointmentId = 'appointmentId',
}

export type WorkflowTriggerOrderByRelevanceInput = {
  fields: Array<WorkflowTriggerOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars['String'];
};

export type WorkflowTriggerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  _count?: Maybe<WorkflowTriggerCountOrderByAggregateInput>;
  _max?: Maybe<WorkflowTriggerMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowTriggerMinOrderByAggregateInput>;
};

export type WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<WorkflowTriggerOrderByRelevanceInput>;
};

export enum WorkflowTriggerScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  WorkflowId = 'workflowId',
  AppointmentId = 'appointmentId',
  TriggerAt = 'triggerAt',
  HasTriggered = 'hasTriggered',
  IsDeleted = 'isDeleted',
}

export type WorkflowTriggerScalarWhereInput = {
  AND?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  hasTriggered?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
};

export type WorkflowTriggerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  triggerAt?: Maybe<DateTimeWithAggregatesFilter>;
  hasTriggered?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
};

export type WorkflowTriggerUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
};

export type WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
};

export type WorkflowTriggerUncheckedCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedCreateWithoutWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt: Scalars['DateTime'];
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutWorkflowInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUncheckedUpdateWithoutWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  appointmentId?: Maybe<Scalars['String']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUpdateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutWorkflowTriggersInput>;
};

export type WorkflowTriggerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput = {
  where: WorkflowTriggerScalarWhereInput;
  data: WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput;
};

export type WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput = {
  where: WorkflowTriggerScalarWhereInput;
  data: WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput;
};

export type WorkflowTriggerUpdateManyWithoutAppointmentInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
};

export type WorkflowTriggerUpdateManyWithoutWorkflowInput = {
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
};

export type WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput = {
  where: WorkflowTriggerWhereUniqueInput;
  data: WorkflowTriggerUncheckedUpdateWithoutAppointmentInput;
};

export type WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput = {
  where: WorkflowTriggerWhereUniqueInput;
  data: WorkflowTriggerUncheckedUpdateWithoutWorkflowInput;
};

export type WorkflowTriggerUpdateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersInput>;
};

export type WorkflowTriggerUpdateWithoutWorkflowInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentUpdateOneWithoutWorkflowTriggersInput>;
};

export type WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput = {
  where: WorkflowTriggerWhereUniqueInput;
  update: WorkflowTriggerUncheckedUpdateWithoutAppointmentInput;
  create: WorkflowTriggerUncheckedCreateWithoutAppointmentInput;
};

export type WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput = {
  where: WorkflowTriggerWhereUniqueInput;
  update: WorkflowTriggerUncheckedUpdateWithoutWorkflowInput;
  create: WorkflowTriggerUncheckedCreateWithoutWorkflowInput;
};

export type WorkflowTriggerWhereInput = {
  AND?: Maybe<Array<WorkflowTriggerWhereInput>>;
  OR?: Maybe<Array<WorkflowTriggerWhereInput>>;
  NOT?: Maybe<Array<WorkflowTriggerWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
  appointmentId?: Maybe<StringNullableFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  hasTriggered?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  appointment?: Maybe<AppointmentWhereInput>;
};

export type WorkflowTriggerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  appointmentId_workflowId?: Maybe<WorkflowTriggerAppointmentIdWorkflowIdCompoundUniqueInput>;
};

export enum WorkflowType {
  NoWorkflow = 'NoWorkflow',
  Curbside = 'Curbside',
  Telemedicine = 'Telemedicine',
  InHouse = 'InHouse',
  Emergency = 'Emergency',
  Surgery = 'Surgery',
  Wellness = 'Wellness',
  Boarding = 'Boarding',
  Specialty = 'Specialty',
  Diagnostics = 'Diagnostics',
  MobileVisit = 'MobileVisit',
}

export enum WorkflowVisitType {
  Client = 'Client',
  Patient = 'Patient',
}

export type WritebackCareInvoiceAccountCreditToPimsInput = {
  invoiceId: Scalars['String'];
  paymentAmount: Scalars['Int'];
};

export type WritebackCareInvoiceAccountCreditToPimsResponse = {
  __typename?: 'WritebackCareInvoiceAccountCreditToPimsResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type SendVetSubscriptionReceiptInput = {
  clinicId: Scalars['String'];
  firebaseId: Scalars['String'];
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ToggleTwilioVideoCallRecordingStatusMutationVariables = Exact<{
  data: VideoCallRecordingToggleInput;
}>;

export type ToggleTwilioVideoCallRecordingStatusMutation = { __typename?: 'Mutation' } & {
  toggleTwilioVideoCallRecordingStatus?: Maybe<
    { __typename?: 'CallRecording' } & {
      twilioCallRecording?: Maybe<{ __typename?: 'TwilioCallRecording' } & Pick<TwilioCallRecording, 'sid' | 'status'>>;
    }
  >;
};

export type GetCurrentRecordingStatusQueryVariables = Exact<{
  where: TwilioCallRecordingWhereUniqueInput;
}>;

export type GetCurrentRecordingStatusQuery = { __typename?: 'Query' } & {
  findUniqueTwilioCallRecording?: Maybe<{ __typename?: 'TwilioCallRecording' } & Pick<TwilioCallRecording, 'status'>>;
};

export type SubscribeToTwilioCallRecordingStatusSubscriptionVariables = Exact<{
  where: SubscriptionTwilioCallRecordingWhereInput;
}>;

export type SubscribeToTwilioCallRecordingStatusSubscription = { __typename?: 'Subscription' } & {
  recordingStatusChanged: { __typename?: 'TwilioCallRecordingSubscription' } & {
    node: { __typename?: 'TwilioCallRecording' } & Pick<TwilioCallRecording, 'status'>;
  };
};

export const ToggleTwilioVideoCallRecordingStatusDocument = gql`
  mutation toggleTwilioVideoCallRecordingStatus($data: VideoCallRecordingToggleInput!) {
    toggleTwilioVideoCallRecordingStatus(data: $data) {
      twilioCallRecording {
        sid
        status
      }
    }
  }
`;
export type ToggleTwilioVideoCallRecordingStatusMutationFn = Apollo.MutationFunction<
  ToggleTwilioVideoCallRecordingStatusMutation,
  ToggleTwilioVideoCallRecordingStatusMutationVariables
>;

/**
 * __useToggleTwilioVideoCallRecordingStatusMutation__
 *
 * To run a mutation, you first call `useToggleTwilioVideoCallRecordingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTwilioVideoCallRecordingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTwilioVideoCallRecordingStatusMutation, { data, loading, error }] = useToggleTwilioVideoCallRecordingStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useToggleTwilioVideoCallRecordingStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleTwilioVideoCallRecordingStatusMutation,
    ToggleTwilioVideoCallRecordingStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleTwilioVideoCallRecordingStatusMutation,
    ToggleTwilioVideoCallRecordingStatusMutationVariables
  >(ToggleTwilioVideoCallRecordingStatusDocument, options);
}
export type ToggleTwilioVideoCallRecordingStatusMutationHookResult = ReturnType<
  typeof useToggleTwilioVideoCallRecordingStatusMutation
>;
export type ToggleTwilioVideoCallRecordingStatusMutationResult = Apollo.MutationResult<
  ToggleTwilioVideoCallRecordingStatusMutation
>;
export type ToggleTwilioVideoCallRecordingStatusMutationOptions = Apollo.BaseMutationOptions<
  ToggleTwilioVideoCallRecordingStatusMutation,
  ToggleTwilioVideoCallRecordingStatusMutationVariables
>;
export const GetCurrentRecordingStatusDocument = gql`
  query getCurrentRecordingStatus($where: TwilioCallRecordingWhereUniqueInput!) {
    findUniqueTwilioCallRecording(where: $where) {
      status
    }
  }
`;

/**
 * __useGetCurrentRecordingStatusQuery__
 *
 * To run a query within a React component, call `useGetCurrentRecordingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRecordingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRecordingStatusQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCurrentRecordingStatusQuery(
  baseOptions: Apollo.QueryHookOptions<GetCurrentRecordingStatusQuery, GetCurrentRecordingStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentRecordingStatusQuery, GetCurrentRecordingStatusQueryVariables>(
    GetCurrentRecordingStatusDocument,
    options
  );
}
export function useGetCurrentRecordingStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRecordingStatusQuery, GetCurrentRecordingStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentRecordingStatusQuery, GetCurrentRecordingStatusQueryVariables>(
    GetCurrentRecordingStatusDocument,
    options
  );
}
export type GetCurrentRecordingStatusQueryHookResult = ReturnType<typeof useGetCurrentRecordingStatusQuery>;
export type GetCurrentRecordingStatusLazyQueryHookResult = ReturnType<typeof useGetCurrentRecordingStatusLazyQuery>;
export type GetCurrentRecordingStatusQueryResult = Apollo.QueryResult<
  GetCurrentRecordingStatusQuery,
  GetCurrentRecordingStatusQueryVariables
>;
export const SubscribeToTwilioCallRecordingStatusDocument = gql`
  subscription subscribeToTwilioCallRecordingStatus($where: SubscriptionTwilioCallRecordingWhereInput!) {
    recordingStatusChanged(where: $where) {
      node {
        status
      }
    }
  }
`;

/**
 * __useSubscribeToTwilioCallRecordingStatusSubscription__
 *
 * To run a query within a React component, call `useSubscribeToTwilioCallRecordingStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTwilioCallRecordingStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToTwilioCallRecordingStatusSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToTwilioCallRecordingStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToTwilioCallRecordingStatusSubscription,
    SubscribeToTwilioCallRecordingStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToTwilioCallRecordingStatusSubscription,
    SubscribeToTwilioCallRecordingStatusSubscriptionVariables
  >(SubscribeToTwilioCallRecordingStatusDocument, options);
}
export type SubscribeToTwilioCallRecordingStatusSubscriptionHookResult = ReturnType<
  typeof useSubscribeToTwilioCallRecordingStatusSubscription
>;
export type SubscribeToTwilioCallRecordingStatusSubscriptionResult = Apollo.SubscriptionResult<
  SubscribeToTwilioCallRecordingStatusSubscription
>;
