import React, { useCallback, useMemo } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useParentConnectionContext from '../../../hooks/useParentConnectionContext/useParentConnectionContext';
import UAParser from 'ua-parser-js';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import CallQualityScreens from '../../CallQualityScreens/CallQualityScreens';
import { supportedBrowserMap } from '../../UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import OSErrorSnackbar, { IOSErrorSnackbarProps } from '../OSErrorSnackbar/OSErrorSnackbar';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  roomType: string;
  userInfo: any;
  clientName?: string;
  appName: string;
  clinicName: string;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  userInfo,
  clientName,
  appName,
  clinicName,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting, isRatingCall, setIsRatingCall, room } = useVideoContext();
  const { parent } = useParentConnectionContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const history = useHistory();
  const { getBrowser, getOS } = new UAParser();

  const browser: string = getBrowser().name || '';
  const OS: string = getOS().name || '';
  const OSVersion: string = getOS().version || '';

  const handleJoin = () => {
    getToken(name, roomName).then(token => connect(token));
    setTimeout(() => {
      setIsRatingCall(true);
    }, 10000);
    parent?.mixpanelTrack('Video call started');
  };

  const cancelCall = useCallback((): void => {
    parent?.onCallEnd();
    parent?.mixpanelTrack('Video call exited');
  }, [parent]);

  const OSErrorProps = useMemo((): IOSErrorSnackbarProps => {
    if (OS === 'iOS' && OSVersion.startsWith('15.1')) {
      const message =
        "It looks like you're using iOS 15.1. Due to a bug that Apple fixed in iOS 15.2, you may experience audio quality issues on this video call. Please update your device to the latest iOS version, or try joining with another device.";
      console.warn(message);

      return {
        isBadOS: true,
        message: message,
      };
    }
    return {
      isBadOS: false,
    };
  }, [OS, OSVersion]);

  const isBrowserSupported = useMemo(() => {
    if (!OS || !browser) return false;
    console.info({ OS, browser });

    if (!supportedBrowserMap[OS]) return false;

    return supportedBrowserMap[OS][browser];
  }, [browser, OS]);

  const handleCopyUrl = async (): Promise<void> => {
    if (!window.parent) return;
    const roomData = await parent?.getRoomData();
    try {
      navigator.clipboard.writeText(roomData?.conversationUrl || '').then(
        () => {
          /* success */
          console.info('Copied to clipboard');
        },
        e => {
          /* failure */
          console.warn(e);
        }
      );
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <>
      {!isBrowserSupported ? (
        <Incompatible>
          {Object.keys(supportedBrowserMap[OS] || []).length ? (
            <div>
              Unfortunately, video calling is not supported with {browser ? `the ${browser}` : 'your current browser'}{' '}
              and {OS && `${OS} `} operating system combination. <b>Please copy the link below</b> and paste it into one
              of the following browsers on your {OS || ''} device to join the video call:
              <ul>
                {Object.keys(supportedBrowserMap[OS]).map(browserName => {
                  return (
                    <li key={browserName}>
                      <b>{browserName}</b>
                    </li>
                  );
                })}
              </ul>
              <CopyLink onClick={handleCopyUrl}>Copy Conversation URL</CopyLink>
            </div>
          ) : (
            <div>
              There are no supported browsers for this operating system. The supported operating systems include
              Windows, Android, Mac OS, iOS, and Linux.
            </div>
          )}

          <BackButton onClick={(): void => history.goBack()}>Back </BackButton>
        </Incompatible>
      ) : (
        <>
          {!isRatingCall ? (
            <>
              <OSErrorSnackbar {...OSErrorProps} />
              <Typography
                variant="h5"
                style={{ wordBreak: 'break-word', textAlign: 'center', marginBottom: 8 }}
                className={classes.gutterBottom}
              >
                Join call{clientName ? ` with ${clientName}` : ''}
              </Typography>

              <Grid container justify="center">
                <Grid item md={7} sm={12} xs={12}>
                  <PreviewContainer>
                    <LocalVideoPreview identity={name} />
                  </PreviewContainer>
                  <div className={classes.mobileButtonBar}>
                    <Hidden mdUp>
                      <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
                      <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
                    </Hidden>
                    <SettingsMenu mobileButtonClass={classes.mobileButton} />
                  </div>
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                  <div style={{ height: '80%', marginLeft: 12 }}>
                    <div>
                      <Hidden smDown>
                        <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                        <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
                      </Hidden>
                    </div>
                    <JoinButtons>
                      <Button variant="outlined" color="primary" onClick={cancelCall}>
                        {' '}
                        Leave Call
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        data-cy-join-now
                        onClick={handleJoin}
                        disabled={disableButtons}
                      >
                        Join Now
                      </Button>
                    </JoinButtons>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <CallQualityScreens
              userInfo={userInfo}
              userName={name}
              roomName={roomName}
              appName={appName}
              clinicName={clinicName}
              room={room}
              endCall={(): Promise<void> | undefined => parent?.onCallEnd()}
            />
          )}
        </>
      )}
    </>
  );
}

const Incompatible = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
`;

const BackButton = styled.div`
  width: 180px;
  background-color: hsl(192 54% 35%);
  border-radius: 4px;
  color: #ffe;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  cursor: pointer;
`;

const CopyLink = styled.div`
  color: #228aa4;
  text-decoration: underline;
`;

const PreviewContainer = styled.div``;

const JoinButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
